/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spinner,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import IconBox from "components/icons/IconBox";
import {
  IconAdd,
  IconArrowDown,
  IconDownload,
  IconDownloadReceipt,
} from "components/icons/Icons";
import { ScrollPanel } from "components/scrollpanel";
import _ from "lodash";
import { axiosService } from "network/axios";
import { pad_with_zeroes } from "network/helper";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { delBankRequest, getInvoicesRequest } from "store/user/reducer";
import {
  bankAccountsSelector,
  invoicesSelector,
  isGettingInvoicesSelector,
  userSelector,
} from "store/user/selector";
import moment from "moment-timezone";
import BankInputForm from "views/customer/order/components/payment/BankInputForm";
const pdfMake = require("pdfmake");

export default function Invoice() {
  pdfMake.fonts = {
    Roboto: {
      normal:
        "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
      bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
      italics:
        "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
      bolditalics:
        "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
    },
  };
  const user = useSelector(userSelector);
  const [openAddressForm, setOepnAddressForm] = useState(false);
  const banks = useSelector(bankAccountsSelector);

  const months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const years = [2020, 2021, 2022, 2023];
  const shadow = useColorModeValue(
    "0px 4px 50px rgba(0, 0, 0, 0.1)",
    "0px 4px 50px rgba(0, 0, 0, 0.1)"
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [invoiceResults, setInvoiceResults] = useState([]);
  const dispatch = useDispatch();
  const invoices = useSelector(invoicesSelector);
  const isGettingInvoices = useSelector(isGettingInvoicesSelector);
  const onClickOpenInvoices = () => {
    onOpen();

    if (user) {
      const user_id = user.user_id;
      dispatch(
        getInvoicesRequest({
          user_id: user_id,
        })
      );
    }
  };

  const availableYears = useMemo(() => {
    if (invoices?.length > 0) {
      return _.uniq(
        invoices?.map((it: any) =>
          moment(new Date(it?.updated_at)).format("YYYY")
        )
      );
    } else {
      return [];
    }
  }, [invoices]);

  const fullInvoices = useMemo(() => {
    const years =
      invoices?.length > 0
        ? _.uniq(
            invoices?.map((it: any) =>
              moment(new Date(it?.updated_at)).format("YYYY")
            )
          )
        : [];
    let results: any[] = [];
    years?.forEach((year: string) => {
      let yearInvoices = invoices?.filter(
        (it: any) => moment(new Date(it?.updated_at)).format("YYYY") === year
      );
      let availableMonths = _.uniq(
        yearInvoices?.map((it: any) =>
          moment(new Date(it?.updated_at)).format("MM")
        )
      );
      availableMonths?.forEach((month: string) => {
        const matchedInvoices = yearInvoices?.filter(
          (it: any) => moment(new Date(it?.updated_at)).format("MM") === month
        );

        const order_ids = _.uniq(
          matchedInvoices?.map((it: any) => Number(it.order_id))
        );
        const invoiceReports: any[] = [];
        order_ids?.forEach((order_id) => {
          let orderDocuments = (
            matchedInvoices?.filter(
              (it: any) => Number(it.order_id) === Number(order_id)
            ) || []
          )?.map((it: any) => ({
            ...it,
            page_count: Number(it?.page_count || 0),
          }));

          const totalPages = _.sumBy(orderDocuments, "page_count");
          invoiceReports.push({
            order_id: order_id,
            document_count: orderDocuments.length,
            translated_from:
              orderDocuments?.length > 0
                ? orderDocuments[0].translated_from
                : "",
            translated_to:
              orderDocuments?.length > 0 ? orderDocuments[0].translated_to : "",
            page_count: totalPages,
          });
        });
        let monthIndex = months?.indexOf(month);
        results.push({
          year: year,
          month: month,
          month_name: monthNames[monthIndex],
          invoiceReports: invoiceReports,
        });
      });
    });
    return results;
  }, [invoices]);
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const onDownloadInvoice = (
    reports: any[] | null,
    year: string | null,
    month: string | null
  ) => {
    let downloadResults: any[] = [];
    let downloadYear: string = "";
    let downloadMonth: string = "";
    if (reports) {
      downloadResults = reports;
      downloadYear = year;
      downloadMonth = month;
    } else {
      downloadResults = invoiceResults;
      downloadYear = selectedYear;
      downloadMonth = selectedMonth;
    }

    const docRows = downloadResults?.map((it: any) => [
      { text: pad_with_zeroes(it.order_id, 6), margin: [0, 10, 0, 10] },
      { text: it.document_count, margin: [0, 10, 0, 10] },
      { text: it.translated_from, margin: [0, 10, 0, 10] },
      { text: it.translated_to, margin: [0, 10, 0, 10] },
      { text: it.page_count, margin: [0, 10, 0, 10] },
      {
        text: `$${Number(it.page_count * 5).toFixed(2)}`,
        margin: [0, 10, 0, 10],
      },
    ]);

    const docDefinition = {
      header: function (
        currentPage: number,
        pageCount: number,
        pageSize: number
      ) {
        return [
          {
            text: `Invoice - (${downloadYear}-${downloadMonth})`,
            alignment: "center",
            margin: [0, 5, 0, 40],
            fontSize: 16,
          },
        ];
      },
      content: [
        {
          layout: "lightHorizontalLines",
          table: {
            headerRows: 1,
            body: [
              [
                { text: "Order ID", margin: [0, 5, 0, 5] },
                { text: "Documents", margin: [0, 5, 0, 5] },
                { text: "Source Language (s)", margin: [0, 5, 0, 5] },
                { text: "Target Language (s)", margin: [0, 5, 0, 5] },
                { text: "Total Pages", margin: [0, 5, 0, 5] },
                { text: "Price", margin: [0, 5, 0, 5] },
              ],
              ...docRows,
            ],
          },
        },
        {
          margin: [0, 15, 0, 0],
          text: `Price Total: $${(
            _.sumBy(downloadResults, "page_count") * 5
          ).toFixed(2)}`,
        },
      ],
    };
    const fileName = `invoice-${downloadYear}-${downloadMonth}.pdf`;
    pdfMake.createPdf(docDefinition).download(fileName);
  };

  useEffect(() => {
    const user_id = user?.user_id;
    if (selectedYear && selectedMonth && user_id) {
      setIsGenerating(true);
      setInvoiceResults([]);
      axiosService
        .get(`/get/invoices/${selectedYear}/${selectedMonth}/${user_id}`)
        .then((res: any) => {
          const results = (res?.data?.results ?? [])?.map((it: any) => ({
            ...it,
            page_count: Number(it.page_count),
          }));
          const order_ids = _.uniq(
            results?.map((it: any) => Number(it.order_id))
          );
          const invoiceReports: any[] = [];
          order_ids?.forEach((order_id) => {
            let orderDocuments =
              results?.filter(
                (it: any) => Number(it.order_id) === Number(order_id)
              ) || [];
            const totalPages = _.sumBy(orderDocuments, "page_count");
            invoiceReports.push({
              order_id: order_id,
              document_count: orderDocuments.length,
              translated_from:
                orderDocuments?.length > 0
                  ? orderDocuments[0].translated_from
                  : "",
              translated_to:
                orderDocuments?.length > 0
                  ? orderDocuments[0].translated_to
                  : "",
              page_count: totalPages,
            });
          });
          // console.log(invoiceReports);
          setInvoiceResults(invoiceReports);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsGenerating(false);
        });
    }
  }, [selectedYear, selectedMonth]);
  const onRemoveBank = (bank: any) => {
    // console.log(bank);
    dispatch(
      delBankRequest({
        user_id: user.user_id,
        bank_id: bank.id,
      })
    );
  };
  return (
    <Flex direction="column">
      <Box>
        <Text fontSize="17px" lineHeight="24px" color="#000" fontWeight="700">
          Address
        </Text>
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          columnGap="10px"
          rowGap="5px"
          marginTop="15px"
        >
          <Box>
            <FormLabel
              fontSize="12px"
              lineHeight="15px"
              fontWeight="400"
              color="#8E8C8C"
              textTransform="capitalize"
              padding="5px"
              bgColor="#FFF"
              mr="0px"
              width="fit-content"
              position="relative"
              zIndex="10"
              ml="30px"
              mb="-13px"
            >
              Street
            </FormLabel>
            <Input
              type="text"
              name="street"
              padding="15px 30px"
              fontSize="14px"
              lineHeight="17px"
              color="#000"
              fontWeight="400"
              textTransform="capitalize"
              h="60px"
              borderRadius="50px"
              placeholder="Street"
              _placeholder={{
                fontSize: "14px",
                lineHeight: "17px",
                color: "#000",
                fontWeight: "400",
                textTransform: "capitalize",
              }}
            />
          </Box>
          <Box>
            <FormLabel
              fontSize="12px"
              lineHeight="15px"
              fontWeight="400"
              color="#8E8C8C"
              textTransform="capitalize"
              padding="5px"
              bgColor="#FFF"
              mr="0px"
              width="fit-content"
              position="relative"
              zIndex="10"
              ml="30px"
              mb="-13px"
            >
              Apt, Suite
            </FormLabel>
            <Input
              type="text"
              name="apt"
              padding="15px 30px"
              fontSize="14px"
              lineHeight="17px"
              color="#000"
              fontWeight="400"
              textTransform="capitalize"
              h="60px"
              borderRadius="50px"
              placeholder="Apt, Suite"
              _placeholder={{
                fontSize: "14px",
                lineHeight: "17px",
                color: "#000",
                fontWeight: "400",
                textTransform: "capitalize",
              }}
            />
          </Box>

          <Box>
            <FormLabel
              fontSize="12px"
              lineHeight="15px"
              fontWeight="400"
              color="#8E8C8C"
              textTransform="capitalize"
              padding="5px"
              bgColor="#FFF"
              mr="0px"
              width="fit-content"
              position="relative"
              zIndex="10"
              ml="30px"
              mb="-13px"
            >
              City
            </FormLabel>
            <Input
              type="text"
              name="city"
              padding="15px 30px"
              fontSize="14px"
              lineHeight="17px"
              color="#000"
              fontWeight="400"
              textTransform="capitalize"
              h="60px"
              borderRadius="50px"
              placeholder="City"
              _placeholder={{
                fontSize: "14px",
                lineHeight: "17px",
                color: "#000",
                fontWeight: "400",
                textTransform: "capitalize",
              }}
            />
          </Box>
          <Box>
            <FormLabel
              fontSize="12px"
              lineHeight="15px"
              fontWeight="400"
              color="#8E8C8C"
              textTransform="capitalize"
              padding="5px"
              bgColor="#FFF"
              mr="0px"
              width="fit-content"
              position="relative"
              zIndex="10"
              ml="30px"
              mb="-13px"
            >
              State & Zipcode
            </FormLabel>
            <Input
              type="text"
              name="state"
              padding="15px 30px"
              fontSize="14px"
              lineHeight="17px"
              color="#000"
              fontWeight="400"
              textTransform="capitalize"
              h="60px"
              borderRadius="50px"
              placeholder="State & Zipcode"
              _placeholder={{
                fontSize: "14px",
                lineHeight: "17px",
                color: "#000",
                fontWeight: "400",
                textTransform: "capitalize",
              }}
            />
          </Box>
          <Box>
            <FormLabel
              fontSize="12px"
              lineHeight="15px"
              fontWeight="400"
              color="#8E8C8C"
              textTransform="capitalize"
              padding="5px"
              bgColor="#FFF"
              mr="0px"
              width="fit-content"
              position="relative"
              zIndex="10"
              ml="30px"
              mb="-13px"
            >
              Country
            </FormLabel>
            <Input
              type="text"
              name="country"
              padding="15px 30px"
              fontSize="14px"
              lineHeight="17px"
              color="#000"
              fontWeight="400"
              textTransform="capitalize"
              h="60px"
              borderRadius="50px"
              placeholder="Country"
              _placeholder={{
                fontSize: "14px",
                lineHeight: "17px",
                color: "#000",
                fontWeight: "400",
                textTransform: "capitalize",
              }}
            />
          </Box>
        </SimpleGrid>
      </Box>
      <Box
        marginTop="45px"
        padding={{ md: "45px 0px 80px", base: "30px 0px" }}
        borderTop="1px solid #E3E3E3"
      >
        <Flex
          columnGap="60px"
          width="100%"
          rowGap="20px"
          flexDirection={{ md: "row", base: "column" }}
          mb="20px"
        >
          <Text
            fontSize="17px"
            lineHeight="24px"
            color="#000"
            fontWeight="700"
            whiteSpace="nowrap"
          >
            Bank Account
          </Text>
          <Text fontSize="14px" lineHeight="135%" fontWeight="400" color="#000">
            Add your bank account information to receive payment from a US-based
            bank.
          </Text>
        </Flex>

        {banks?.length > 0 &&
          banks.map((bank: any, index: number) => (
            <Flex
              key={index}
              padding="15px 27px"
              alignItems="center"
              border="1px solid #DCDCDC"
              borderRadius="10px"
              mb={"12px"}
            >
              <Text
                fontSize="14px"
                lineHeight="24px"
                fontWeight="400"
                color="#000"
              >
                {bank.account_number}
              </Text>
              <Button
                padding={"5px 20px"}
                backgroundColor="#F3F5F5"
                borderRadius="100px"
                minW="fit-content"
                height="34px"
                color="#000"
                fontSize="12px"
                lineHeight="24px"
                fontWeight="400"
                ml="auto"
                onClick={() => onRemoveBank(bank)}
              >
                Remove
              </Button>
            </Flex>
          ))}
        {openAddressForm && (
          <BankInputForm
            mt="20px"
            onCancel={() => {
              setOepnAddressForm(false);
            }}
          />
        )}
        <Flex
          columnGap="35px"
          alignItems="center"
          mt="20px"
          cursor="pointer"
          w="fit-content"
          onClick={() => setOepnAddressForm(true)}
        >
          <IconBox w="28px" h="28px" icon={<IconAdd w="28px" h="28px" />} />
          <Text
            fontSize="14px"
            lineHeight="135%"
            color="#000"
            fontWeight="400"
            textDecoration="underline"
          >
            {banks?.length > 0
              ? "Add another Bank Account"
              : "Add a Bank Account"}
          </Text>
        </Flex>
      </Box>
      <Box
        padding={{ md: "45px 0px", base: "30px 0px" }}
        borderTop="1px solid #E3E3E3"
      >
        <Flex
          columnGap="60px"
          width="100%"
          rowGap="20px"
          flexDirection={{ md: "row", base: "column" }}
          alignItems={{ md: "center", base: "flex-start" }}
        >
          <Text
            fontSize="17px"
            lineHeight="24px"
            color="#000"
            fontWeight="700"
            whiteSpace="nowrap"
          >
            Invoice
          </Text>
          <Text fontSize="14px" lineHeight="135%" fontWeight="400" color="#000">
            Download your invoice and project report.
          </Text>
        </Flex>
      </Box>
      <Flex
        direction={{
          md: "row",
          base: "column",
        }}
        rowGap="15px"
      >
        <Menu>
          <MenuButton
            padding={"10px 20px"}
            backgroundColor="#FFF"
            borderRadius="100px"
            minW="120px"
            height="46px"
            border="1px solid #E9E9E9"
          >
            <Flex alignItems={"center"}>
              <Text
                fontSize="14px"
                lineHeight="24px"
                fontWeight="400"
                color="black"
              >
                {selectedMonth ? selectedMonth : "Month"}
              </Text>
              <IconBox
                marginLeft="auto"
                icon={<IconArrowDown width="24px" height="24px" />}
              ></IconBox>
            </Flex>
          </MenuButton>
          <MenuList
            boxShadow={shadow}
            p="0px"
            mt="10px"
            borderRadius="20px"
            bg={"#FFF"}
            border="none"
            zIndex="22"
          >
            <Flex
              flexDirection="column"
              p="10px 20px"
              fontSize={"14px"}
              lineHeight="24px"
              fontWeight={"400"}
              color="black"
            >
              {months.map((month, index: number) => (
                <MenuItem
                  key={index}
                  _hover={{ bg: "none" }}
                  _focus={{ bg: "none" }}
                  borderRadius="8px"
                  padding="15px 0px"
                  borderBottom={
                    index < months.length - 1 ? "1px solid #F3F5F5" : ""
                  }
                  onClick={() => {
                    setSelectedMonth(month);
                  }}
                >
                  <Text fontSize="sm">{month}</Text>
                </MenuItem>
              ))}
            </Flex>
          </MenuList>
        </Menu>
        <Menu>
          <MenuButton
            marginLeft={{ md: "15px", base: "0px" }}
            padding={"10px 20px"}
            backgroundColor="#FFF"
            borderRadius="100px"
            minW="120px"
            height="46px"
            border="1px solid #E9E9E9"
          >
            <Flex alignItems={"center"}>
              <Text
                fontSize="14px"
                lineHeight="24px"
                fontWeight="400"
                color="black"
              >
                {selectedYear ? selectedYear : "Year"}
              </Text>
              <IconBox
                marginLeft="auto"
                icon={<IconArrowDown width="24px" height="24px" />}
              ></IconBox>
            </Flex>
          </MenuButton>
          <MenuList
            boxShadow={shadow}
            p="0px"
            mt="10px"
            borderRadius="20px"
            bg={"#FFF"}
            border="none"
            zIndex="2"
          >
            <Flex
              flexDirection="column"
              p="10px 20px"
              fontSize={"14px"}
              lineHeight="24px"
              fontWeight={"400"}
              color="black"
            >
              {years.map((year, index: number) => (
                <MenuItem
                  key={index}
                  _hover={{ bg: "none" }}
                  _focus={{ bg: "none" }}
                  borderRadius="8px"
                  padding="15px 0px"
                  onClick={() => {
                    setSelectedYear(year);
                  }}
                  borderBottom={
                    index < years.length - 1 ? "1px solid #F3F5F5" : ""
                  }
                >
                  <Text fontSize="sm">{year}</Text>
                </MenuItem>
              ))}
            </Flex>
          </MenuList>
        </Menu>
        <Box marginLeft={{ md: "auto", base: "0px" }}>
          <Button
            height="46px"
            padding="15px 35px"
            borderRadius="100px"
            bgColor="#2F80ED"
            color="#FFF"
            fontSize="14px"
            lineHeight="17px"
            fontWeight="bold"
            onClick={() => {
              onClickOpenInvoices();
            }}
          >
            View past Invoices
          </Button>
        </Box>
      </Flex>

      {selectedYear && selectedMonth && (
        <Box h="fit-content" w="100%" overflow="hidden">
          {isGenerating && (
            <Flex py={3} pt="40px" justifyContent="center">
              <Spinner color="#2F80ED" width={"50px"} height="50px"></Spinner>
            </Flex>
          )}
          {!isGenerating && invoiceResults?.length < 1 && (
            <Flex py={3} pt="40px">
              No Reported Invoices
            </Flex>
          )}
          {!isGenerating && invoiceResults?.length > 0 && (
            <Flex direction="column" py={3} pt="40px" maxWidth="100%">
              <TableContainer>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>No</Th>
                      <Th>Order Id</Th>
                      <Th>Document (s)</Th>
                      <Th>Source Language (s)</Th>
                      <Th>Target Language (s)</Th>
                      <Th>Total Pages</Th>
                      <Th>Price</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {invoiceResults?.map((it: any, index: number) => (
                      <Tr key={index}>
                        <Td>{index + 1}</Td>
                        <Td>{pad_with_zeroes(it?.order_id, 6)}</Td>
                        <Td>{it?.document_count}</Td>
                        <Td>{it?.translated_from}</Td>
                        <Td>{it?.translated_to}</Td>
                        <Td>{it?.page_count}</Td>
                        <Td>${(it?.page_count * 5).toFixed(2)}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                  <Tfoot>
                    <Tr>
                      <Td
                        colSpan={3}
                        position="sticky"
                        sx={{
                          left: "0",
                        }}
                      >
                        Price Total: $
                        {(_.sumBy(invoiceResults, "page_count") * 5).toFixed(2)}
                      </Td>
                    </Tr>
                  </Tfoot>
                </Table>
              </TableContainer>
              <Flex
                direction="row"
                alignItems="center"
                mt="15px"
                color="#2F80ED"
                _hover={{
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() => {
                  onDownloadInvoice(null, null, null);
                }}
              >
                <IconBox
                  w="40px"
                  h="40px"
                  bg={boxBg}
                  icon={<IconDownloadReceipt w="24px" h="24px" />}
                />
                <Text
                  fontSize="14px"
                  lineHeight="18px"
                  color="#2F80ED"
                  fontWeight="500"
                  ml="10px"
                >
                  Download Invoice
                </Text>
              </Flex>
            </Flex>
          )}
        </Box>
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            fontSize="17px"
            lineHeight="24px"
            fontWeight="700"
            color="#000"
          >
            Invoices
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb="35px">
            {!isGettingInvoices &&
              (availableYears?.length > 0 ? (
                <Tabs variant="unstyled">
                  <TabList borderBottom={"1px solid #E9E9E9"}>
                    {availableYears?.map((year) => (
                      <Tab
                        padding={{
                          md: "8px 20px 8px 0px",
                          sm: "8px 10px 8px 0px",
                        }}
                        fontSize={{ md: "17px", sm: "14px" }}
                        lineHeight={{ md: "24px", sm: "22px" }}
                        fontWeight="400"
                        alignItems="center"
                        _selected={{ color: "blue.500" }}
                        _focus={{ boxShadow: "none" }}
                      >
                        {year}
                      </Tab>
                    ))}
                  </TabList>
                  <TabPanels marginTop="25px">
                    {availableYears?.map((year: string) => {
                      return (
                        <TabPanel p="0">
                          <ScrollPanel
                            height="170px"
                            paddingRight="10px"
                            children={
                              <>
                                {fullInvoices
                                  ?.filter((it) => it.year === year)
                                  .map((invoice, index: number) => (
                                    <Flex
                                      key={index}
                                      padding="13px 26px"
                                      border="1px solid #DCDCDC"
                                      borderRadius="10px"
                                      marginBottom="10px"
                                      height="50px"
                                    >
                                      <Text
                                        fontSize="17px"
                                        lineHeight="24px"
                                        color="#000"
                                        fontWeight="400"
                                      >
                                        {invoice.month_name}
                                      </Text>
                                      <IconBox
                                        w="24px"
                                        h="24px"
                                        marginLeft="auto"
                                        cursor="pointer"
                                        onClick={() => {
                                          onDownloadInvoice(
                                            invoice.invoiceReports,
                                            invoice.year,
                                            invoice.month
                                          );
                                        }}
                                        icon={
                                          <IconDownload w="24px" h="24px" />
                                        }
                                      ></IconBox>
                                    </Flex>
                                  ))}
                              </>
                            }
                          ></ScrollPanel>
                        </TabPanel>
                      );
                    })}
                  </TabPanels>
                </Tabs>
              ) : (
                <>No Invoices</>
              ))}
            {isGettingInvoices && (
              <Flex p={3} justifyContent="center" alignItems="center">
                <Spinner color="#2F80ED" width={"50px"} height="50px"></Spinner>
              </Flex>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
