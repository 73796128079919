/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import IconBox from "components/icons/IconBox";
import {
  IconDeliveryDate,
  IconLock,
  IconPostOrderSupport,
} from "components/icons/Icons";
import { ORDER_STEPS } from "types/data-types";
import { useDispatch, useSelector } from "react-redux";
import { uploadProjectSelector } from "store/customerProject/selector";
import _ from "lodash";
import { updateProjectStatus } from "store/customerProject/reducer";
import { fetchPerPagePrice } from "network/helper";
import { useMemo } from "react";
import moment from "moment-timezone";
import { userSelector } from "store/user/selector";

export default function OrderDetails(props: { step?: ORDER_STEPS }) {
  const { step } = props;
  const uploadProject = useSelector(uploadProjectSelector);
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const isRedeemEnabled = localStorage.getItem("redeem") ? true : false;

  const orderSummary = useMemo(() => {
    let totalPrice = 0;
    let basePrice = 0;
    let discountPrice = 0;
    const discountType =
      Number(user?.dicount?.discount_type ?? 0) === 0 ? "fixed" : "percent";

    const discountValue = user?.is_business
      ? Number(user?.discount?.discount ?? 0)
      : 0;

    const targetLanguage = uploadProject.targetLanguage;

    const perPagePrice =
      discountType === "fixed"
        ? fetchPerPagePrice(targetLanguage) - discountValue
        : (fetchPerPagePrice(targetLanguage) * (100 - discountValue)) / 100;

    const discountedPricePerPage =
      discountType === "fixed"
        ? discountValue
        : (fetchPerPagePrice(targetLanguage) * discountValue) / 100;

    totalPrice =
      uploadProject.transitionType === "certified"
        ? uploadProject.totalPage * perPagePrice
        : uploadProject.totalWords * 0.1;

    if (isRedeemEnabled && uploadProject.transitionType === "certified") {
      totalPrice =
        (uploadProject.totalPage - 1 >= 0 ? uploadProject.totalPage - 1 : 0) *
        perPagePrice;
    }

    discountPrice =
      uploadProject.transitionType === "certified"
        ? uploadProject.totalPage * discountedPricePerPage
        : 0;

    basePrice =
      uploadProject.transitionType === "certified"
        ? (isRedeemEnabled
            ? uploadProject.totalPage - 1 >= 0
              ? uploadProject.totalPage - 1
              : 0
            : uploadProject.totalPage) * fetchPerPagePrice(targetLanguage)
        : uploadProject.totalWords * 0.1;

    if (
      uploadProject.transitionType === "standard" &&
      uploadProject.totalWords < 250 &&
      uploadProject.totalWords > 0
    ) {
      basePrice = 20;
      totalPrice = 20;
    }

    if (
      step === ORDER_STEPS.SET_TRANSITION_DETAILS_STEP ||
      step === ORDER_STEPS.SET_PAYMENT_STEP
    ) {
      if (uploadProject.isNotarized) {
        totalPrice += 19.95;
        basePrice += 19.95;
      }

      if (uploadProject.isMailHardCopy) {
        totalPrice += 15;
        basePrice += 15;
      }

      if (uploadProject.transitionSpeed === "express") {
        if (uploadProject.transitionType === "certified") {
          if (isRedeemEnabled) {
            totalPrice +=
              10 *
              (uploadProject.totalPage - 1 >= 0
                ? uploadProject.totalPage - 1
                : 0) *
              targetLanguage.length;
            basePrice +=
              10 *
              (uploadProject.totalPage - 1 >= 0
                ? uploadProject.totalPage - 1
                : 0) *
              targetLanguage.length;
          } else {
            totalPrice += 10 * uploadProject.totalPage * targetLanguage.length;
            basePrice += 10 * uploadProject.totalPage * targetLanguage.length;
          }
        } else {
          if (uploadProject.totalWords < 250) {
            totalPrice += 10 * targetLanguage.length;
            basePrice += 10 * targetLanguage.length;
          } else {
            totalPrice +=
              0.004 * uploadProject.totalWords * targetLanguage.length;
            basePrice +=
              0.004 * uploadProject.totalWords * targetLanguage.length;
          }
        }
      }
    }

    let estimatedDeliveryMessage = `Receive your translations by <b>${moment()
      .add(1, "days")
      .format("YYYY-MM-DD")}</b>.`;

    if (uploadProject.transitionType === "certified") {
      if (uploadProject.totalPage >= 1 && uploadProject.totalPage <= 4) {
        estimatedDeliveryMessage = `Receive your translations within <b>24hrs</b>.`;
        if (uploadProject.transitionSpeed === "express") {
          estimatedDeliveryMessage = `Receive your translations within <b>12hrs</b>.`;
        }
      } else if (
        uploadProject.totalPage >= 5 &&
        uploadProject.totalPage <= 10
      ) {
        estimatedDeliveryMessage = `Receive your translations within <b>2-3 business days</b>.`;
        if (uploadProject.transitionSpeed === "express") {
          estimatedDeliveryMessage = `Receive your translations within <b>1-2 business days</b>.`;
        }
      } else if (
        uploadProject.totalPage >= 11 &&
        uploadProject.totalPage <= 30
      ) {
        estimatedDeliveryMessage = `Receive your translations within <b>3-4 business days</b>.`;
        if (uploadProject.transitionSpeed === "express") {
          estimatedDeliveryMessage = `Receive your translations within <b>2 business days</b>.`;
        }
      } else if (
        uploadProject.totalPage >= 31 &&
        uploadProject.totalPage <= 50
      ) {
        estimatedDeliveryMessage = `Receive your translations within <b>4-5 business days</b>.`;
        if (uploadProject.transitionSpeed === "express") {
          estimatedDeliveryMessage = `Receive your translations within <b>2-3 business days</b>.`;
        }
      } else if (
        uploadProject.totalPage >= 51 &&
        uploadProject.totalPage <= 100
      ) {
        estimatedDeliveryMessage = `Receive your translations within <b>7 to 10 business days</b>.`;
        if (uploadProject.transitionSpeed === "express") {
          estimatedDeliveryMessage = `Receive your translations within <b>3-5 business days</b>.`;
        }
      } else if (uploadProject.totalPage >= 101) {
        estimatedDeliveryMessage = `Delivery timeline will be provided by our team.`;
      }
    } else if (uploadProject.transitionType === "standard") {
      if (uploadProject.totalWords > 0 && uploadProject.totalWords <= 2000) {
        estimatedDeliveryMessage = `Receive your translations within <b>2 business days</b>.`;
        if (uploadProject.transitionSpeed === "express") {
          estimatedDeliveryMessage = `Receive your translations within <b>24hrs</b>.`;
        }
      } else if (
        uploadProject.totalWords >= 2001 &&
        uploadProject.totalWords <= 5000
      ) {
        estimatedDeliveryMessage = `Receive your translations within <b>2-4 business days</b>.`;
        if (uploadProject.transitionSpeed === "express") {
          estimatedDeliveryMessage = `Receive your translations within <b>1-2 business days</b>.`;
        }
      } else if (
        uploadProject.totalWords >= 5001 &&
        uploadProject.totalWords <= 12500
      ) {
        estimatedDeliveryMessage = `Receive your translations within <b>4-6 business days</b>.`;
        if (uploadProject.transitionSpeed === "express") {
          estimatedDeliveryMessage = `Receive your translations within <b>2-3 business days</b>.`;
        }
      } else if (
        uploadProject.totalWords >= 12501 &&
        uploadProject.totalWords <= 25000
      ) {
        estimatedDeliveryMessage = `Receive your translations within <b>6-10 business days</b>.`;
        if (uploadProject.transitionSpeed === "express") {
          estimatedDeliveryMessage = `Receive your translations within <b>3-5 business days</b>.`;
        }
      } else if (uploadProject.totalWords >= 25001) {
        estimatedDeliveryMessage = `Delivery timeline will be provided by our team.`;
      }
    }

    return {
      words: uploadProject.totalWords,
      pages: uploadProject.totalPage,
      totalPrice: totalPrice,
      langCnt: targetLanguage.length + 1,
      basePrice: basePrice,
      discountPrice: discountPrice,
      targetLanguage: targetLanguage,
      estimatedDeliveryMessage: estimatedDeliveryMessage,
    };
  }, [uploadProject, step, user, isRedeemEnabled]);

  return (
    <>
      <Flex
        flexDirection="column"
        padding="24px"
        rowGap="32px"
        sx={{
          borderRadius: "9px",
          backgroundColor: "#FFF",
          boxShadow: {
            md: "0px 3.60347px 45.0434px rgba(0, 0, 0, 0.1)",
            base: "none",
          },
        }}
      >
        <Box>
          <Heading
            fontSize="20px"
            fontWeight="500"
            letterSpacing="0.3px"
            textTransform="uppercase"
            fontFamily="Inter"
            color="#000"
            lineHeight="normal"
            mb="16px"
          >
            Order Summary
          </Heading>
          {isRedeemEnabled && uploadProject.transitionType === "certified" && (
            <Box
              className="tag"
              padding="4px 8px"
              borderRadius="8px"
              backgroundColor="#00B67A"
              fontSize="12px"
              fontWeight="600"
              lineHeight="normal"
              color="#FFF"
              my="10px"
            >
              Price is considered with Redeem. So 1 page is included as free.
            </Box>
          )}
          <Flex alignItems="center">
            <IconBox w="28px" h="28px" icon={<IconLock w="28px" h="28px" />} />
            <Text
              fontFamily="Inter"
              fontSize="12px"
              fontWeight="400"
              lineHeight="normal"
              color="rgba(0,0,0,0.6)"
              ml="10px"
            >
              Your order is 100% secure and uploaded files are encrypted &
              confidential.
            </Text>
          </Flex>
        </Box>
        <Box>
          <>
            {uploadProject.transitionType === "certified" && (
              <Box>
                <Flex
                  columnGap="10px"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text
                    fontSize="16px"
                    lineHeight="normal"
                    fontWeight="500"
                    color="#000"
                  >
                    Certified Translation
                  </Text>
                  <Text
                    fontSize="16px"
                    lineHeight="normal"
                    fontWeight="500"
                    color="#000"
                  >
                    ${orderSummary.basePrice?.toFixed(2)}
                  </Text>
                </Flex>
                <Text
                  fontSize="14px"
                  lineHeight="1.4"
                  fontWeight="400"
                  color="#666"
                  mt="8px"
                >
                  {uploadProject.totalPage > 0 ? (
                    <>
                      {uploadProject.totalPage} <i>pages</i>
                    </>
                  ) : (
                    <>Charged per price</>
                  )}
                  <br />
                  <Text fontWeight="200">(250 words max per page)</Text>
                </Text>
              </Box>
            )}
            {uploadProject.transitionType === "standard" && (
              <Box>
                <Flex
                  columnGap="10px"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text
                    fontSize="16px"
                    lineHeight="normal"
                    fontWeight="500"
                    color="#000"
                  >
                    General Translation
                  </Text>
                  <Text
                    fontSize="16px"
                    lineHeight="normal"
                    fontWeight="500"
                    color="#000"
                  >
                    ${orderSummary.basePrice?.toFixed(2)}
                  </Text>
                </Flex>
                <Text
                  fontSize="14px"
                  lineHeight="normal"
                  fontWeight="400"
                  color="#666"
                  mt="8px"
                >
                  Charged per word
                </Text>
              </Box>
            )}
          </>
        </Box>

        <>
          {!_.isEmpty(uploadProject.sourceLanguage) &&
            !_.isEmpty(uploadProject.targetLanguage) && (
              <Flex>
                <Box width="fit-content" mr="15px">
                  {uploadProject.targetLanguage?.map((it, index) => (
                    <Text
                      key={index}
                      fontSize="14px"
                      lineHeight="1.4"
                      fontWeight="400"
                      color="#000"
                      height="20px"
                      verticalAlign="middle"
                      mb={0.5}
                    >
                      {uploadProject.sourceLanguage} to {it}
                    </Text>
                  ))}
                </Box>
                <Box>
                  {uploadProject.targetLanguage?.map((it, index) => (
                    <Text
                      height="20px"
                      key={index}
                      fontSize="13px"
                      lineHeight="1.4"
                      fontWeight="300"
                      color="#000"
                      verticalAlign="middle"
                      display="flex"
                      alignItems="center"
                      mb={0.5}
                    >
                      {uploadProject.transitionType === "certified"
                        ? `$${fetchPerPagePrice([it])?.toFixed(2)}/page`
                        : `$0.10/word`}
                    </Text>
                  ))}
                </Box>
              </Flex>
            )}
          {(step === ORDER_STEPS.SET_TRANSITION_DETAILS_STEP ||
            step === ORDER_STEPS.SET_PAYMENT_STEP) && (
            <Box>
              <Flex mb="8px" alignItems="center">
                <Text
                  fontSize="16px"
                  lineHeight="normal"
                  fontWeight="500"
                  color="#000"
                >
                  Add-Ons
                </Text>

                {step === ORDER_STEPS.SET_PAYMENT_STEP && (
                  <Box
                    className="tag"
                    padding="4px 8px"
                    borderRadius="8px"
                    backgroundColor="#2F80ED"
                    fontSize="12px"
                    fontWeight="400"
                    lineHeight="normal"
                    color="#FFF"
                    ml="8px"
                  >
                    Recommended
                  </Box>
                )}

                {!uploadProject.isMailHardCopy &&
                  !uploadProject.isNotarized &&
                  uploadProject.transitionSpeed === "standard" && (
                    <Text
                      fontSize="15px"
                      fontWeight="700"
                      lineHeight="2px"
                      textAlign="right"
                      color="#000"
                      ml="auto"
                    >
                      -
                    </Text>
                  )}
              </Flex>
              <Flex
                flexDirection="column"
                rowGap="4px"
                fontSize="14px"
                fontWeight="400"
                color="#666"
                lineHeight="normal"
              >
                {uploadProject.transitionType === "certified" && (
                  <>
                    <Flex justifyContent="space-between">
                      <Flex>
                        <Text>Two-Day Shipping Delivery</Text>
                        {!uploadProject.isMailHardCopy &&
                          step === ORDER_STEPS.SET_PAYMENT_STEP && (
                            <Text
                              color="#2F80ED"
                              ml="8px"
                              cursor="pointer"
                              onClick={() => {
                                dispatch(
                                  updateProjectStatus({
                                    isMailHardCopy: true,
                                  })
                                );
                              }}
                            >
                              Add
                            </Text>
                          )}
                      </Flex>
                      {uploadProject.isMailHardCopy && (
                        <Text textAlign="right" color="#000">
                          $15.00
                        </Text>
                      )}
                    </Flex>
                    <Flex justifyContent="space-between">
                      <Flex>
                        <Text>Notarization</Text>
                        {!uploadProject.isNotarized &&
                          step === ORDER_STEPS.SET_PAYMENT_STEP && (
                            <Text
                              color="#2F80ED"
                              ml="8px"
                              cursor="pointer"
                              onClick={() => {
                                dispatch(
                                  updateProjectStatus({
                                    isNotarized: true,
                                  })
                                );
                              }}
                            >
                              Add
                            </Text>
                          )}
                      </Flex>
                      {uploadProject.isNotarized && (
                        <Text textAlign="right" color="#000">
                          $19.95
                        </Text>
                      )}
                    </Flex>
                  </>
                )}
                <Flex justifyContent="space-between">
                  <Flex>
                    <Text>Expedited Turnaround</Text>
                    {uploadProject.transitionSpeed !== "express" &&
                      step === ORDER_STEPS.SET_PAYMENT_STEP && (
                        <Text
                          color="#2F80ED"
                          ml="8px"
                          cursor="pointer"
                          onClick={() => {
                            dispatch(
                              updateProjectStatus({
                                transitionSpeed: "express",
                              })
                            );
                          }}
                        >
                          Add
                        </Text>
                      )}
                  </Flex>
                  {uploadProject.transitionSpeed === "express" && (
                    <Text textAlign="right" color="#000">
                      {uploadProject.transitionType === "certified"
                        ? `$${(
                            10 *
                            orderSummary.pages *
                            orderSummary.targetLanguage.length
                          ).toFixed(2)}`
                        : `$${
                            orderSummary.words >= 250
                              ? (
                                  0.004 *
                                  orderSummary.words *
                                  orderSummary.targetLanguage.length
                                ).toFixed(2)
                              : (
                                  10 * orderSummary.targetLanguage.length
                                ).toFixed(2)
                          }`}
                    </Text>
                  )}
                </Flex>
              </Flex>
            </Box>
          )}
        </>
        <hr />
        <Box>
          {user?.is_business && (
            <>
              <Flex columnGap="10px" justifyContent="space-between" mb="10px">
                <Text
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="normal"
                  color="#666"
                  fontFamily="Inter"
                >
                  Subtotal:
                </Text>
                <Text
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="normal"
                  color="#666"
                  fontFamily="Inter"
                >
                  ${orderSummary.basePrice?.toFixed(2)}
                </Text>
              </Flex>
              <Flex columnGap="10px" justifyContent="space-between" mb="10px">
                <Text
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="normal"
                  color="#666"
                  fontFamily="Inter"
                >
                  Discount:
                </Text>
                <Text
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="normal"
                  color="#666"
                  fontFamily="Inter"
                >
                  ${orderSummary.discountPrice?.toFixed(2)}
                </Text>
              </Flex>
            </>
          )}
          <Flex columnGap="10px" justifyContent="space-between">
            <Text
              fontSize="21.25px"
              fontWeight="700"
              lineHeight="normal"
              color="#000"
              fontFamily="Inter"
            >
              Order Total:
            </Text>
            <Text
              fontSize="21.25px"
              fontWeight="700"
              lineHeight="normal"
              color="#000"
              fontFamily="Inter"
            >
              ${orderSummary.totalPrice?.toFixed(2)}
            </Text>
          </Flex>
        </Box>
      </Flex>
      <Flex
        my="25px"
        alignItems="center"
        columnGap="10px"
        justifyContent="flex-end"
      >
        <Image
          h="35px"
          w="auto"
          src="https://www.languex.co/wp-content/uploads/2023/10/uscis.png"
          zIndex={1}
        />
        <Image
          h="auto"
          w="fit-content"
          src="https://www.languex.co/wp-content/uploads/2023/10/logo_checkout.png"
          zIndex={1}
        />
      </Flex>
      <Box>
        <Box id="intro-delivery-date">
          <Flex alignItems="center">
            <IconBox
              w="16px"
              h="16px"
              icon={<IconDeliveryDate w="16px" h="16px" />}
            />

            <Text
              fontSize="16px"
              fontWeight="700"
              color="#666"
              fontFamily="Inter"
              ml="8px"
            >
              Delivery Date
            </Text>
          </Flex>
          <Text
            fontSize="14px"
            fontWeight="400"
            lineHeight="normal"
            color="#666"
            mt="8px"
          >
            {orderSummary.estimatedDeliveryMessage ? (
              <Box
                sx={{ display: "inline" }}
                dangerouslySetInnerHTML={{
                  __html: orderSummary.estimatedDeliveryMessage,
                }}
              ></Box>
            ) : (
              "Receive your translations by [Estimated Delivery Date]"
            )}
            {step === ORDER_STEPS.SET_TRANSITION_DETAILS_STEP && (
              <Box display="inline">
                {uploadProject.transitionSpeed === "express" ? (
                  ""
                ) : (
                  <> Need it faster? Add Express delivery to your order.</>
                )}
              </Box>
            )}
            {step === ORDER_STEPS.SET_PAYMENT_STEP && (
              <Box display="inline">
                {uploadProject.transitionSpeed === "express" ? (
                  ""
                ) : (
                  <>
                    {" "}
                    Need it faster?{" "}
                    <Box
                      display={"inline"}
                      color="#2F80ED"
                      cursor="pointer"
                      onClick={() => {
                        dispatch(
                          updateProjectStatus({
                            transitionSpeed: "express",
                          })
                        );
                      }}
                    >
                      Add Express delivery
                    </Box>{" "}
                    to your order.
                  </>
                )}
              </Box>
            )}
          </Text>
        </Box>
        <Box id="intro-order-post-support" mt="16px">
          <Flex alignItems="center">
            <IconBox
              w="16px"
              h="16px"
              icon={<IconPostOrderSupport w="16px" h="16px" />}
            />

            <Text
              fontSize="16px"
              fontWeight="700"
              color="#666"
              fontFamily="Inter"
              ml="8px"
            >
              Post-Order Support
            </Text>
          </Flex>
          <Text
            fontSize="14px"
            fontWeight="400"
            lineHeight="normal"
            color="#666"
            mt="8px"
          >
            You can modify your order, add more documents, or include special
            instructions after placing your order. Our support team will help
            right away.
          </Text>
        </Box>
      </Box>
    </>
  );
}
