import { Box, Flex, Stack } from "@chakra-ui/react";
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";

function SidebarContent(props: {
  routes: RoutesType[];
  onMenuItemClicked?: Function;
}) {
  const { routes, onMenuItemClicked } = props;
  const activeColor = "blue.500";
  return (
    <Flex direction="column" height="100%">
      <Brand />
      <Stack direction="column">
        <Box
          sx={{
            a: {
              "&.active": {
                path: {
                  fill: activeColor,
                },
                p: {
                  color: activeColor,
                  fontWeight: 800,
                },
              },
            },
          }}
        >
          <Links routes={routes} onMenuItemClicked={onMenuItemClicked} />
        </Box>
      </Stack>
    </Flex>
  );
}

export default SidebarContent;
