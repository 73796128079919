/* eslint-disable react-hooks/exhaustive-deps */
import {
  BellIcon,
  ChevronRightIcon,
  StarIcon,
  TimeIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Progress,
  Skeleton,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import IconBox from "components/icons/IconBox";
import { IconDownload, IconTeam } from "components/icons/Icons";
import { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment-timezone";
import {
  Chart,
  ArcElement,
  Legend,
  Tooltip,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import { useDispatch, useSelector } from "react-redux";
import {
  invoicesSelector,
  isGettingInvoicesSelector,
  userSelector,
} from "store/user/selector";
import { getInvoicesRequest } from "store/user/reducer";

import _, { isEmpty } from "lodash";
import { axiosService } from "network/axios";
import {
  fetchStatusName,
  generateQueryParameters,
  getProjectStatusAsPercent,
  groupByAndCount,
  pad_with_zeroes,
} from "network/helper";
import { useNavigate } from "react-router-dom";
import CustomPaginations from "components/pagination/CustomPaginations";
const pdfMake = require("pdfmake");

Chart.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
);

export default function ManagerDashboard() {
  const boxShadow = "0px 4px 10px rgba(0, 0, 0, 0.1)";
  const [dashboardIndex] = useState(-1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [projectData, setProjectData] = useState(null);
  const [dashboardTile, setDashboardTile] = useState(null);
  const [lastUpdatedOrders, setLastUpdatedOrders] = useState(null);
  const [notifications, setNotifications] = useState(null);
  const user = useSelector(userSelector);
  const [allOrders, setAllOrders] = useState(null);
  const isGettingInvoices = useSelector(isGettingInvoicesSelector);
  const [showMore, setShowMore] = useState(false);

  const months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const invoices = useSelector(invoicesSelector);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(20);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [isFetchingAll, setIsFetchingAll] = useState(false);
  const [totalNotifications, setTotalNotifications] = useState([]);

  const queryParam = useMemo(() => {
    return {
      page: currentPage,
      limit: pageLimit,
    };
  }, [currentPage, pageLimit]);

  useEffect(() => {
    if (!isEmpty(user?.user_id)) {
      dispatch(
        getInvoicesRequest({
          user_id: user?.user_id,
        })
      );
      fetchSummary(Number(user?.user_id));
    }
  }, [user?.user_id]);

  const orderCompletion = useMemo(() => {
    let allPercents = 0;
    if (allOrders?.length > 0) {
      allOrders?.forEach((element: any) => {
        allPercents += getProjectStatusAsPercent(element.documents) ?? 0;
      });
      return allPercents / (allOrders?.length ?? 1);
    } else {
      return 0;
    }
  }, [allOrders]);

  const fetchSummary = async (user_id: number) => {
    try {
      setIsFetching(true);
      const { data }: any = await axiosService.get(
        `/projects/manager/summary/${user.user_id}`
      );

      const allOrderResults = data?.allOrderResults ?? [];
      setAllOrders(allOrderResults);
      const groupLanauges = Object.keys(
        groupByAndCount(data?.languages ?? [])
      )?.join(", ");

      setProjectData({
        labels: ["New", "Active", "Due Soon", "In Reviews", "Completed"],
        datasets: [
          {
            label: "Projects",
            data: [
              data?.newProjects ?? 0,
              data?.activeProjects ?? 0,
              data?.dueSoonProjects ?? 0,
              data?.reviewsProjects ?? 0,
              data?.completedProjects ?? 0,
            ],
            backgroundColor: [
              "rgba(255, 99, 132, 0.6)",
              "rgba(54, 162, 235, 0.6)",
              "rgba(255, 206, 86, 0.6)",
              "rgba(75, 192, 192, 0.6)",
              "rgba(153, 102, 255, 0.6)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
            ],
            borderWidth: 1,
          },
        ],
      });

      setDashboardTile({
        totalOrders: data?.totalOrders ?? 0,
        notifications: data?.notificationsCnt ?? 0,
        lastVisited: "2023-12-11 09:12:15",
        mostAppearances: groupLanauges,
      });

      setLastUpdatedOrders(data?.allOrderResults?.slice(0, 5) ?? []);
      setNotifications(data?.notifications ?? []);
    } catch (err) {
      console.log(err);
    } finally {
      setIsFetching(false);
    }
  };

  const goToOrdersPage = () => {
    navigate("/manager/projects");
  };

  const onClickViewAllMessages = () => {
    onOpen();
  };

  const onChangeViewMode = () => {
    setShowMore(!showMore);
  };

  const openProjectDetails = (order_id: string) => {
    navigate(`/manager/projects#${order_id}`);
  };

  const fullInvoices = useMemo(() => {
    const years =
      invoices?.length > 0
        ? _.uniq(
            invoices?.map((it: any) =>
              moment(new Date(it?.updated_at)).format("YYYY")
            )
          )
        : [];
    let results: any[] = [];
    years?.forEach((year: string) => {
      let yearInvoices = invoices?.filter(
        (it: any) => moment(new Date(it?.updated_at)).format("YYYY") === year
      );
      let availableMonths = _.uniq(
        yearInvoices?.map((it: any) =>
          moment(new Date(it?.updated_at)).format("MM")
        )
      );
      availableMonths?.forEach((month: string) => {
        const matchedInvoices = yearInvoices?.filter(
          (it: any) => moment(new Date(it?.updated_at)).format("MM") === month
        );

        const order_ids = _.uniq(
          matchedInvoices?.map((it: any) => Number(it.order_id))
        );
        const invoiceReports: any[] = [];
        order_ids?.forEach((order_id) => {
          let orderDocuments = (
            matchedInvoices?.filter(
              (it: any) => Number(it.order_id) === Number(order_id)
            ) || []
          )?.map((it: any) => ({
            ...it,
            page_count: Number(it?.page_count || 0),
          }));

          const totalPages = _.sumBy(orderDocuments, "page_count");
          invoiceReports.push({
            order_id: order_id,
            document_count: orderDocuments.length,
            translated_from:
              orderDocuments?.length > 0
                ? orderDocuments[0].translated_from
                : "",
            translated_to:
              orderDocuments?.length > 0 ? orderDocuments[0].translated_to : "",
            page_count: totalPages,
          });
        });
        let monthIndex = months?.indexOf(month);
        results.push({
          year: year,
          month: month,
          month_name: monthNames[monthIndex],
          invoiceReports: invoiceReports,
        });
      });
    });
    return results;
  }, [invoices]);

  const onDownloadInvoice = (
    reports: any[] | null,
    year: string | null,
    month: string | null
  ) => {
    let downloadResults: any[] = [];
    let downloadYear: string = "";
    let downloadMonth: string = "";
    downloadResults = reports;
    downloadYear = year;
    downloadMonth = month;

    const docRows = downloadResults?.map((it: any) => [
      { text: pad_with_zeroes(it.order_id, 6), margin: [0, 10, 0, 10] },
      { text: it.document_count, margin: [0, 10, 0, 10] },
      { text: it.translated_from, margin: [0, 10, 0, 10] },
      { text: it.translated_to, margin: [0, 10, 0, 10] },
      { text: it.page_count, margin: [0, 10, 0, 10] },
      {
        text: `$${Number(it.page_count * 5).toFixed(2)}`,
        margin: [0, 10, 0, 10],
      },
    ]);

    const docDefinition = {
      header: function (
        currentPage: number,
        pageCount: number,
        pageSize: number
      ) {
        return [
          {
            text: `Invoice - (${downloadYear}-${downloadMonth})`,
            alignment: "center",
            margin: [0, 5, 0, 40],
            fontSize: 16,
          },
        ];
      },
      content: [
        {
          layout: "lightHorizontalLines",
          table: {
            headerRows: 1,
            body: [
              [
                { text: "Order ID", margin: [0, 5, 0, 5] },
                { text: "Documents", margin: [0, 5, 0, 5] },
                { text: "Source Language (s)", margin: [0, 5, 0, 5] },
                { text: "Target Language (s)", margin: [0, 5, 0, 5] },
                { text: "Total Pages", margin: [0, 5, 0, 5] },
                { text: "Price", margin: [0, 5, 0, 5] },
              ],
              ...docRows,
            ],
          },
        },
        {
          margin: [0, 15, 0, 0],
          text: `Price Total: $${(
            _.sumBy(downloadResults, "page_count") * 5
          ).toFixed(2)}`,
        },
      ],
    };
    const fileName = `invoice-${downloadYear}-${downloadMonth}.pdf`;
    pdfMake.createPdf(docDefinition).download(fileName);
  };

  const fetchResults = useCallback(async () => {
    setIsFetchingAll(true);
    const params = generateQueryParameters(queryParam);
    try {
      const resp: any = await axiosService.get(
        `/projects/notifications/${user.user_id}?${params}`
      );
      const { results, total_rows } = resp?.data;
      setTotalNotifications(
        results?.map((it: any) => ({
          ...it,
          id: it?.order_id,
        }))
      );

      setTotalPages(
        total_rows / pageLimit > Math.floor(total_rows / pageLimit)
          ? Math.floor(total_rows / pageLimit) + 1
          : Math.floor(total_rows / pageLimit)
      );
      setTotalRows(total_rows);
    } catch (err) {
      setTotalNotifications([]);
    } finally {
      setIsFetchingAll(false);
    }
  }, [queryParam]);

  useEffect(() => {
    fetchResults();
  }, [queryParam]);

  return (
    <Box pt={{ base: "60px", md: "80px", xl: "80px" }}>
      <Box gap="20px" display="flex" flexWrap="wrap">
        <Box
          height="inherit"
          flexBasis={{ md: "unset", "3sm": "calc(50% - 10px)", base: "100%" }}
        >
          <Flex
            flexWrap="wrap"
            alignItems="center"
            boxShadow={boxShadow}
            padding="20px"
            borderRadius="10px"
            bgColor="#FFF"
          >
            <IconBox w="50px" h="50px" icon={<StarIcon w="40px" h="40px" />} />
            <Box ml="10px">
              <Text fontSize="16px">Orders Completion</Text>
              <Text
                fontSize={{ md: "24px", base: "20px" }}
                fontWeight="bold"
                lineHeight="140%"
              >
                {orderCompletion?.toFixed(2) ?? "0.00"}%
              </Text>
            </Box>
          </Flex>
        </Box>
        <Box
          flexBasis={{ md: "unset", "3sm": "calc(50% - 10px)", base: "100%" }}
          height="inherit"
        >
          <Flex
            flexWrap="wrap"
            alignItems="center"
            boxShadow={boxShadow}
            padding="20px"
            borderRadius="10px"
            bgColor={dashboardIndex === 0 ? "#2F80ED" : "#FFF"}
            color={dashboardIndex === 0 ? "#FFF" : "#000"}
            cursor="pointer"
          >
            <IconBox
              w="50px"
              h="50px"
              sx={{
                path: {
                  fill: dashboardIndex === 0 ? "#FFF" : "#000",
                },
              }}
              icon={<IconTeam w="50px" h="50px" />}
            />
            <Box ml="10px">
              <Text fontSize="16px">Total Orders</Text>
              <Text
                fontSize={{ md: "24px", base: "20px" }}
                fontWeight="bold"
                lineHeight="140%"
              >
                {dashboardTile?.totalOrders ?? 0}
              </Text>
            </Box>
          </Flex>
        </Box>
        <Box
          flexBasis={{ md: "unset", "3sm": "calc(50% - 10px)", base: "100%" }}
          height="inherit"
        >
          <Flex
            flexWrap="wrap"
            alignItems="center"
            boxShadow={boxShadow}
            cursor="pointer"
            padding="20px"
            borderRadius="10px"
            bgColor={dashboardIndex === 1 ? "#2F80ED" : "#FFF"}
            color={dashboardIndex === 1 ? "#FFF" : "#000"}
          >
            <IconBox w="50px" h="50px" icon={<TimeIcon w="40px" h="40px" />} />
            <Box ml="10px">
              <Text fontSize="16px">Last Visited</Text>
              <Text
                fontSize={{ md: "24px", base: "20px" }}
                fontWeight="bold"
                lineHeight="140%"
              >
                {dashboardTile?.lastVisited ?? "-"}
              </Text>
            </Box>
          </Flex>
        </Box>

        <Box
          flexBasis={{ md: "unset", "3sm": "calc(50% - 10px)", base: "100%" }}
          height="inherit"
        >
          <Flex
            flexWrap="wrap"
            alignItems="center"
            boxShadow={boxShadow}
            padding="20px"
            borderRadius="10px"
            bgColor="#FFF"
          >
            <IconBox w="50px" h="50px" icon={<BellIcon w="40px" h="40px" />} />
            <Box ml="10px">
              <Text fontSize="16px">Notifications</Text>
              <Text
                fontSize={{ md: "24px", base: "20px" }}
                fontWeight="bold"
                lineHeight="140%"
                color="#F00"
              >
                {dashboardTile?.notifications ?? 0}
              </Text>
            </Box>
          </Flex>
        </Box>
      </Box>
      <Grid
        gap="10px"
        templateColumns={{ lg: "repeat(2, 1fr)", base: "repeat(1, 1fr)" }}
        mt="30px"
      >
        <GridItem w="100%">
          <Box
            boxShadow={boxShadow}
            padding="30px"
            borderRadius="10px"
            height="100%"
          >
            <Text fontSize="20px">Lastest Updates on Orders</Text>
            <Box
              maxW={{ md: "unset", base: "calc(100vw - 100px)" }}
              overflow="auto"
            >
              <Table mt="20px" fontSize="14px">
                <Thead>
                  <Th padding="10px" whiteSpace="nowrap">
                    #Order
                  </Th>
                  <Th padding="10px" whiteSpace="nowrap">
                    Status
                  </Th>
                  <Th padding="10px" whiteSpace="nowrap">
                    % Done
                  </Th>
                </Thead>
                <Tbody>
                  {!isFetching &&
                    lastUpdatedOrders?.length > 0 &&
                    lastUpdatedOrders?.map((it: any, index: number) => (
                      <Tr key={index}>
                        <Td padding="11px" whiteSpace="nowrap">
                          #{pad_with_zeroes(it?.order_id, 6)}
                        </Td>
                        <Td padding="11px" whiteSpace="nowrap">
                          {fetchStatusName(Number(it?.order_status_id))}
                        </Td>

                        <Td padding="11px" whiteSpace="nowrap">
                          <Progress
                            colorScheme="green"
                            size="sm"
                            width="100%"
                            value={getProjectStatusAsPercent(it?.documents)}
                          />
                        </Td>
                      </Tr>
                    ))}
                  {isFetching &&
                    _.range(0, 5).map((it) => (
                      <Tr key={it}>
                        <Td padding="10px">
                          <Skeleton height="22px" width="100%"></Skeleton>
                        </Td>
                        <Td padding="10px">
                          <Skeleton height="22px" width="100%"></Skeleton>
                        </Td>
                        <Td padding="10px">
                          <Skeleton height="22px" width="100%"></Skeleton>
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </Box>
            <Flex>
              <Button
                type="button"
                w="fit-content"
                height="44px"
                padding="13px 26px"
                borderRadius="0px"
                bgColor="#2F80ED"
                color="#FFF"
                fontSize="14px"
                lineHeight="14px"
                fontWeight="bold"
                mt="20px"
                onClick={() => {
                  goToOrdersPage();
                }}
              >
                View All
              </Button>
            </Flex>
          </Box>
        </GridItem>
        <GridItem w="100%">
          <Box
            boxShadow={boxShadow}
            padding="30px"
            borderRadius="10px"
            height="100%"
            w="100%"
          >
            <Text fontSize="20px">Unread Messages</Text>
            <Box
              maxW={{ md: "unset", base: "calc(100vw - 100px)" }}
              overflow="auto"
            >
              <Table mt="20px" fontSize="14px" maxWidth="100%">
                <Thead>
                  <Th padding="10px">#Order</Th>
                  <Th padding="10px">Time</Th>
                  <Th padding="10px">From</Th>
                  <Th padding="10px">Message</Th>
                  <Th></Th>
                </Thead>
                <Tbody>
                  {!isFetching &&
                    notifications?.length > 0 &&
                    notifications.slice(0, 5)?.map((it: any, index: number) => (
                      <Tr key={index}>
                        <Td padding="10px" whiteSpace="nowrap">
                          #{pad_with_zeroes(it.order_id, 6)}
                        </Td>
                        <Td padding="10px" whiteSpace="nowrap">
                          {moment(new Date(it.created_at)).format(
                            "MM/DD/YY HH:mm"
                          )}
                        </Td>
                        <Td padding="10px" whiteSpace="nowrap">
                          {it.sender ?? "Unknown"}
                        </Td>
                        <Td padding="10px" width="100%">
                          <Text
                            whiteSpace="nowrap"
                            textOverflow="ellipsis"
                            overflow="hidden"
                            width="100px"
                          >
                            {it.message}
                          </Text>
                        </Td>
                        <Td>
                          <IconBox
                            cursor="pointer"
                            w="20px"
                            h="10px"
                            onClick={() => {
                              openProjectDetails(it?.order_id);
                            }}
                            icon={<ChevronRightIcon w="20px" h="20px" />}
                          />
                        </Td>
                      </Tr>
                    ))}
                  {isFetching &&
                    _.range(0, 5).map((it) => (
                      <Tr key={it}>
                        <Td padding="10px">
                          <Skeleton height="22px" width="100%"></Skeleton>
                        </Td>
                        <Td padding="10px">
                          <Skeleton height="22px" width="100%"></Skeleton>
                        </Td>
                        <Td padding="10px">
                          <Skeleton height="22px" width="100%"></Skeleton>
                        </Td>
                        <Td padding="10px">
                          <Skeleton height="22px" width="100%"></Skeleton>
                        </Td>
                        <Td padding="10px">
                          <Skeleton height="22px" width="100%"></Skeleton>
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </Box>
            <Flex>
              <Button
                type="button"
                w="fit-content"
                height="44px"
                padding="13px 26px"
                borderRadius="0px"
                bgColor="#2F80ED"
                color="#FFF"
                fontSize="14px"
                lineHeight="14px"
                fontWeight="bold"
                onClick={() => {
                  onClickViewAllMessages();
                }}
                mt="20px"
              >
                View All
              </Button>
            </Flex>
          </Box>
        </GridItem>
      </Grid>
      <Grid
        gap="10px"
        templateColumns={{ md: "repeat(2, 1fr)", base: "repeat(1, 1fr)" }}
        mb="20px"
        mt="30px"
      >
        <GridItem w="100%">
          <Box
            boxShadow={boxShadow}
            padding="30px"
            borderRadius="10px"
            height="100%"
            w="100%"
          >
            <Text fontSize="20px">My Invoices</Text>
            <Box
              maxW={{ md: "unset", base: "calc(100vw - 100px)" }}
              overflow="auto"
              mt="20px"
            >
              {!isGettingInvoices &&
                fullInvoices?.length > 0 &&
                fullInvoices
                  ?.slice(showMore ? 0 : -5)
                  .map((invoice, index: number) => (
                    <Flex
                      key={index}
                      padding="13px 26px"
                      border="1px solid #DCDCDC"
                      borderRadius="10px"
                      marginBottom="10px"
                      height="50px"
                    >
                      <Text
                        fontSize="17px"
                        lineHeight="24px"
                        color="#000"
                        fontWeight="400"
                      >
                        {invoice.month_name}
                      </Text>
                      <IconBox
                        w="24px"
                        h="24px"
                        marginLeft="auto"
                        cursor="pointer"
                        onClick={() => {
                          onDownloadInvoice(
                            invoice.invoiceReports,
                            invoice.year,
                            invoice.month
                          );
                        }}
                        icon={<IconDownload w="24px" h="24px" />}
                      ></IconBox>
                    </Flex>
                  ))}
              {isGettingInvoices &&
                _.range(5).map((index: number) => (
                  <Flex
                    key={index}
                    padding="13px 26px"
                    border="1px solid #DCDCDC"
                    borderRadius="10px"
                    marginBottom="10px"
                    height="50px"
                  >
                    <Text
                      fontSize="17px"
                      lineHeight="24px"
                      color="#000"
                      fontWeight="400"
                      flex={1}
                    >
                      <Skeleton height="22px" width="100%"></Skeleton>
                    </Text>
                    <Skeleton height="22px" width="22px" ml="20px"></Skeleton>
                  </Flex>
                ))}
            </Box>
            {fullInvoices?.length > 5 && (
              <Flex>
                <Button
                  type="button"
                  w="fit-content"
                  height="44px"
                  padding="13px 26px"
                  borderRadius="0px"
                  bgColor="#2F80ED"
                  color="#FFF"
                  fontSize="14px"
                  lineHeight="14px"
                  fontWeight="bold"
                  onClick={() => {
                    onChangeViewMode();
                  }}
                  mt="20px"
                >
                  {showMore ? "Show less" : "Show more"}
                </Button>
              </Flex>
            )}
          </Box>
        </GridItem>
      </Grid>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text textAlign="center">All Unread Messages</Text>
          </ModalHeader>
          <ModalCloseButton onClick={onClose} />
          <ModalBody pb="30px">
            <Table mt="20px" fontSize="14px" maxWidth="100%">
              <Thead>
                <Th padding="10px">#Order</Th>
                <Th padding="10px">Time</Th>
                <Th padding="10px">From</Th>
                <Th padding="10px">Message</Th>
                <Th></Th>
              </Thead>
              <Tbody>
                {!isFetchingAll &&
                  totalNotifications?.length > 0 &&
                  totalNotifications?.map((it: any, index: number) => (
                    <Tr key={index}>
                      <Td padding="10px" whiteSpace="nowrap">
                        #{pad_with_zeroes(it.order_id, 6)}
                      </Td>
                      <Td padding="10px" whiteSpace="nowrap">
                        {moment(new Date(it.created_at)).format(
                          "MM/DD/YY HH:mm"
                        )}
                      </Td>
                      <Td
                        padding="10px"
                        whiteSpace="nowrap"
                        maxW="150px"
                        overflow="hidden"
                      >
                        {it.sender ?? "Unknown"}
                      </Td>
                      <Td padding="10px" width="100%">
                        <Text
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                          overflow="hidden"
                          width="100px"
                        >
                          {it.message}
                        </Text>
                      </Td>
                      <Td>
                        <IconBox
                          cursor="pointer"
                          w="20px"
                          h="10px"
                          onClick={() => {
                            openProjectDetails(it?.order_id);
                          }}
                          icon={<ChevronRightIcon w="20px" h="20px" />}
                        />
                      </Td>
                    </Tr>
                  ))}
                {isFetchingAll &&
                  _.range(0, pageLimit).map((it) => (
                    <Tr key={it}>
                      <Td padding="10px">
                        <Skeleton height="22px" width="100%"></Skeleton>
                      </Td>
                      <Td padding="10px">
                        <Skeleton height="22px" width="100%"></Skeleton>
                      </Td>
                      <Td padding="10px">
                        <Skeleton height="22px" width="100%"></Skeleton>
                      </Td>
                      <Td padding="10px">
                        <Skeleton height="22px" width="100%"></Skeleton>
                      </Td>
                      <Td padding="10px">
                        <Skeleton height="22px" width="100%"></Skeleton>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
            <CustomPaginations
              totalCount={totalRows}
              totalPages={totalPages}
              currentPage={currentPage}
              perPage={pageLimit}
              onSetPageLimit={(e: number) => {
                setPageLimit(e);
                setCurrentPage(1);
              }}
              onChangePage={(e: number) => {
                setCurrentPage(e);
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
