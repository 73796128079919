export const howWorksList: {
  title: string;
  content: string;
}[] = [
  {
    title: "Earn Points",
    content: "Each translated page gives you a point.",
  },
  {
    title: "Check Points",
    content: "See your points grow in your account.",
  },
  {
    title: "Redeem Rewards",
    content:
      "Have 25 points? Use them for free translation, notarization, shipping, or design services.",
  },
  {
    title: "Keep Going",
    content: "More translations mean more rewards.",
  },
];
