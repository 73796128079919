/* eslint-disable */

import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Badge, Box, Flex, Text } from "@chakra-ui/react";
import IconBox from "components/icons/IconBox";
import { IconPencil } from "components/icons/Icons";
import { useDispatch, useSelector } from "react-redux";
import {
  draftsProjectsCntSelector,
  unreadMessageSelector,
} from "store/project/selector";
import { useEffect, useMemo } from "react";
import { userSelector } from "store/user/selector";
import { getUnreadMessagesRequest } from "store/project/reducer";
import { useWindowWidth } from "@react-hook/window-size";

export function SidebarLinks(props: {
  routes: RoutesType[];
  onMenuItemClicked?: Function;
}) {
  const navigate = useNavigate();
  let location = useLocation();
  const unreadMessages = useSelector(unreadMessageSelector);
  const draftsProjectsCnt = useSelector(draftsProjectsCntSelector);

  const dispatch = useDispatch();
  const windowWidth = useWindowWidth();
  const user = useSelector(userSelector);

  const totalOrders = useMemo(() => {
    return localStorage.getItem("total_orders")
      ? Number(localStorage.getItem("total_orders"))
      : 0;
  }, [user?.user_id]);

  useEffect(() => {
    if (!localStorage.getItem("access_token")) {
      navigate("/auth/sign-in/");
    }
  }, [location]);

  const unreadMessagesCnt =
    unreadMessages?.reduce(
      (acc: number, currentItem: any) => acc + Number(currentItem.cnt),
      0
    ) + draftsProjectsCnt;

  const windowLocation = window.location.href;

  useEffect(() => {
    if (user?.user_id) {
      dispatch(getUnreadMessagesRequest({ user_id: Number(user.user_id) }));
    }
  }, [user?.user_id, windowLocation]);

  const { routes, onMenuItemClicked } = props;
  const layout = windowLocation.includes("/admin")
    ? "/admin"
    : windowLocation.includes("/translator")
    ? "/translator"
    : windowLocation.includes("/customer")
    ? "/customer"
    : "/manager";

  const createLinks = (routes: RoutesType[]) => {
    return routes
      .filter((it) => {
        if (Number(user?.user_role_type_id) == 7) {
          return it.path === "/unfinished-users" || it.path === "/projects";
        } else {
          return true;
        }
      })
      .map((route: RoutesType, index: number) => {
        if (route.layout === layout) {
          return (
            !route.hidden && (
              <NavLink key={`nav-${index}`} to={route.layout + route.path}>
                {
                  <Box mb={{ md: "26px", base: "50px" }}>
                    <Flex
                      w="100%"
                      alignItems="center"
                      justifyContent="flex-start"
                      columnGap="17px"
                      onClick={() => {
                        onMenuItemClicked ? onMenuItemClicked() : "";
                      }}
                    >
                      <Box display={{ md: "block", base: "none" }}>
                        {route.icon}
                      </Box>
                      <Text
                        fontSize={{ md: "14px", base: "22px" }}
                        lineHeight="24px"
                        fontWeight="400"
                        color="#000"
                      >
                        {route.name}
                        {unreadMessagesCnt > 0 &&
                          route.path?.includes("/projects") && (
                            <Badge
                              bgColor={"#F02828"}
                              sx={{
                                padding: "9px 6px !important",
                                borderRadius: "1000px",
                                fontWeight: "700",
                                fontSize: "10px",
                                lineHeight: "0px",
                                color: "#FFF",
                                marginLeft: "7px",
                                minWidth: "18px",
                              }}
                            >
                              {unreadMessagesCnt}
                            </Badge>
                          )}
                      </Text>
                    </Flex>
                  </Box>
                }
              </NavLink>
            )
          );
        }
      });
  };
  return (
    <>
      {layout === "/customer" && windowWidth >= 768 && (
        <NavLink to="/customer/main">
          <Flex
            padding="10px 15px"
            alignItems="center"
            border="1px solid #2F80ED"
            borderRadius="50px"
            bgColor="#2F80ED"
            width="fit-content"
            marginBottom="26px"
            cursor="pointer"
          >
            <IconBox
              w="24px"
              h="24px"
              marginRight="5px"
              icon={
                <IconPencil
                  w="24px"
                  h="24px"
                  sx={{
                    path: {
                      fill: "#FFF !important",
                    },
                  }}
                />
              }
            ></IconBox>
            <Text
              fontSize="14px"
              lineHeight="17px"
              color="#FFF !important"
              fontWeight="500"
              textTransform="capitalize"
            >
              {totalOrders > 0 ? "Start an order" : "Start a new order"}
            </Text>
          </Flex>
        </NavLink>
      )}
      {createLinks(routes)}
    </>
  );
}

export default SidebarLinks;
