import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import { Formik, Field } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBusinessInformationRequest,
  updateBusinessInformationRequest,
} from "store/customerProject/reducer";
import {
  businessInformationSelector,
  isUpdatingBusinessDetailsSelector,
} from "store/customerProject/selector";
import { userSelector } from "store/user/selector";

type BusinessFormInputs = {
  company_name: string;
  ein_number: string;
  address: string;
  state: string;
  zip_code: string;
};

const inputStyle = {
  fontSize: "14px",
  lineHeight: "17px",
  color: "black",
  border: "none !important",
  boxShadow: "none !important",
  bgColor: "transparent",
  textAlign: "left",
};

const inputStyleNormal = {
  fontSize: "14px",
  lineHeight: "17px",
  textAlign: "left",
  color: "black",
  border: "1px solid #E9E9E9",
  borderRadius: "50px",
  padding: "15px 30px",
  height: "60px",
  bgColor: "#FFF",
  boxShadow: "none !important",
};

export default function BusinessInformationForm() {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const [initialValues, setInitialValues] = useState<BusinessFormInputs>({
    company_name: user?.company_name,
    ein_number: user?.ein_number,
    address: user?.address,
    state: user?.state,
    zip_code: user?.zip_code,
  });

  const isBusiness = useMemo(() => {
    return user.is_business;
  }, [user]);

  const businessInformation = useSelector(businessInformationSelector);
  const isUpdatingBusinessInformation = useSelector(
    isUpdatingBusinessDetailsSelector
  );
  const business_id = user?.business_id ?? null;
  useEffect(() => {
    if (business_id) {
      dispatch(fetchBusinessInformationRequest({ business_id }));
    }
  }, []);

  useEffect(() => {
    setInitialValues({
      ...businessInformation,
    });
  }, [businessInformation]);
  const [isOpenForm, setIsOpenForm] = useState(true);

  const updateBusinessInformation = (payload: BusinessFormInputs) => {
    dispatch(
      updateBusinessInformationRequest({
        business_id,
        details: { ...payload },
      })
    );
    console.log(payload);
  };
  return (
    <Box>
      {/* {!isOpenForm && (
        <Box>
          <Flex
            alignItems={"flex-start"}
            flexDirection={{ md: "row", base: "column" }}
          >
            <Text
              fontSize="17px"
              lineHeight="24px"
              color="#000"
              fontWeight="700"
              whiteSpace="nowrap"
              mr="50px"
              w="120px"
              minW="120px"
              mb="20px"
            >
              Business
              <br />
              Account
            </Text>
            <Text
              fontSize="14px"
              lineHeight="135%"
              color="#000"
              fontWeight="400"
            >
              This is a description.This is a description.This is a
              description.This is a description.This is a description.This is a
              description.This is a description.
            </Text>
          </Flex>
          <Button
            w="fit-content"
            height="46px"
            padding="15px 50px"
            borderRadius="100px"
            bgColor="#2F80ED"
            color="#FFF"
            fontSize="14px"
            lineHeight="17px"
            fontWeight="bold"
            mt="30px"
            onClick={() => setIsOpenForm(true)}
          >
            Switch to Business Account
          </Button>
        </Box>
      )} */}
      {isOpenForm && (
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            updateBusinessInformation(values);
          }}
        >
          {({ handleSubmit, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <Box>
                <Text
                  fontSize="17px"
                  lineHeight="24px"
                  fontWeight="700"
                  color="#000"
                  mb="20px"
                >
                  Business Information
                </Text>
                <Flex wrap="wrap" columnGap="14px">
                  <FormControl
                    isInvalid={
                      (!!errors.company_name && touched.company_name) ||
                      (!!errors.company_name && touched.company_name)
                    }
                  >
                    <Box flexBasis="100%">
                      <FormLabel
                        fontSize="12px"
                        lineHeight="15px"
                        fontWeight="400"
                        color="#8E8C8C"
                        textTransform="capitalize"
                        padding="5px"
                        bgColor="#FFF"
                        mr="0px"
                        width="fit-content"
                        position="relative"
                        zIndex="10"
                        ml="30px"
                        mb="-13px"
                      >
                        Company Name
                      </FormLabel>
                      <Field
                        as={Input}
                        type="text"
                        id="company_name"
                        name="company_name"
                        placeholder="Company Name"
                        padding="5px 20px"
                        h="full"
                        border="none"
                        disabled={!isBusiness}
                        sx={{
                          ...inputStyleNormal,
                          fontWeight: "500",
                        }}
                        _placeholder={{
                          ...inputStyle,
                          fontWeight: "400",
                        }}
                        validate={(value: any) => {
                          let error;
                          if (value.length < 1) {
                            error = "Company Name is required.";
                          }
                          return error;
                        }}
                      />
                    </Box>
                    <FormErrorMessage
                      mt={1}
                      ml="30px"
                      sx={{
                        color: "#F00",
                        justifyContent: "start",
                      }}
                    >
                      {errors.company_name}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl
                    isInvalid={
                      (!!errors.ein_number && touched.ein_number) ||
                      (!!errors.ein_number && touched.ein_number)
                    }
                    mt="14px"
                  >
                    <Box flexBasis="100%">
                      <FormLabel
                        fontSize="12px"
                        lineHeight="15px"
                        fontWeight="400"
                        color="#8E8C8C"
                        textTransform="capitalize"
                        padding="5px"
                        bgColor="#FFF"
                        mr="0px"
                        width="fit-content"
                        position="relative"
                        zIndex="10"
                        ml="30px"
                        mb="-13px"
                      >
                        EIN
                      </FormLabel>
                      <Field
                        as={Input}
                        type="text"
                        id="ein_number"
                        name="ein_number"
                        placeholder="Employer Identification Number"
                        padding="5px 20px"
                        h="full"
                        border="none"
                        disabled={!isBusiness}
                        sx={{
                          ...inputStyleNormal,
                          fontWeight: "500",
                        }}
                        _placeholder={{
                          ...inputStyle,
                          fontWeight: "400",
                        }}
                        validate={(value: any) => {
                          let error;
                          if (value.length < 1) {
                            error = "EIN number is required.";
                          }
                          return error;
                        }}
                      />
                    </Box>
                    <FormErrorMessage
                      mt={1}
                      ml="30px"
                      sx={{
                        color: "#F00",
                        justifyContent: "start",
                      }}
                    >
                      {errors.ein_number}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl
                    isInvalid={
                      (!!errors.address && touched.address) ||
                      (!!errors.address && touched.address)
                    }
                    mt="14px"
                  >
                    <Box flexBasis="100%">
                      <FormLabel
                        fontSize="12px"
                        lineHeight="15px"
                        fontWeight="400"
                        color="#8E8C8C"
                        textTransform="capitalize"
                        padding="5px"
                        bgColor="#FFF"
                        mr="0px"
                        width="fit-content"
                        position="relative"
                        zIndex="10"
                        ml="30px"
                        mb="-13px"
                      >
                        Address
                      </FormLabel>
                      <Field
                        as={Input}
                        type="text"
                        id="address"
                        name="address"
                        placeholder="Address"
                        padding="5px 20px"
                        h="full"
                        border="none"
                        disabled={!isBusiness}
                        sx={{
                          ...inputStyleNormal,
                          fontWeight: "500",
                        }}
                        _placeholder={{
                          ...inputStyle,
                          fontWeight: "400",
                        }}
                        validate={(value: any) => {
                          let error;
                          if (value.length < 1) {
                            error = "Address is required.";
                          }
                          return error;
                        }}
                      />
                    </Box>
                    <FormErrorMessage
                      mt={1}
                      ml="30px"
                      sx={{
                        color: "#F00",
                        justifyContent: "start",
                      }}
                    >
                      {errors.address}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl
                    isInvalid={
                      (!!errors.state && touched.state) ||
                      (!!errors.state && touched.state)
                    }
                    mt="14px"
                    flexBasis="calc(50% - 7px)"
                  >
                    <Box flexBasis="100%">
                      <FormLabel
                        fontSize="12px"
                        lineHeight="15px"
                        fontWeight="400"
                        color="#8E8C8C"
                        textTransform="capitalize"
                        padding="5px"
                        bgColor="#FFF"
                        mr="0px"
                        width="fit-content"
                        position="relative"
                        zIndex="10"
                        ml="30px"
                        mb="-13px"
                      >
                        State
                      </FormLabel>
                      <Field
                        as={Input}
                        type="text"
                        id="state"
                        name="state"
                        placeholder="State"
                        padding="5px 20px"
                        h="full"
                        border="none"
                        disabled={!isBusiness}
                        sx={{
                          ...inputStyleNormal,
                          fontWeight: "500",
                        }}
                        _placeholder={{
                          ...inputStyle,
                          fontWeight: "400",
                        }}
                        validate={(value: any) => {
                          let error;
                          if (value.length < 1) {
                            error = "State is required.";
                          }
                          return error;
                        }}
                      />
                    </Box>
                    <FormErrorMessage
                      mt={1}
                      ml="30px"
                      sx={{
                        color: "#F00",
                        justifyContent: "start",
                      }}
                    >
                      {errors.state}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl
                    isInvalid={
                      (!!errors.zip_code && touched.zip_code) ||
                      (!!errors.zip_code && touched.zip_code)
                    }
                    mt="14px"
                    flexBasis="calc(50% - 7px)"
                  >
                    <Box flexBasis="100%">
                      <FormLabel
                        fontSize="12px"
                        lineHeight="15px"
                        fontWeight="400"
                        color="#8E8C8C"
                        textTransform="capitalize"
                        padding="5px"
                        bgColor="#FFF"
                        mr="0px"
                        width="fit-content"
                        position="relative"
                        zIndex="10"
                        ml="30px"
                        mb="-13px"
                      >
                        Zipcode
                      </FormLabel>
                      <Field
                        as={Input}
                        type="text"
                        id="zip_code"
                        name="zip_code"
                        placeholder="Zipcode"
                        padding="5px 20px"
                        h="full"
                        disabled={!isBusiness}
                        border="none"
                        sx={{
                          ...inputStyleNormal,
                          fontWeight: "500",
                        }}
                        _placeholder={{
                          ...inputStyle,
                          fontWeight: "400",
                        }}
                        validate={(value: any) => {
                          let error;
                          if (value?.length < 1) {
                            error = "Zipcode is required.";
                          }
                          return error;
                        }}
                      />
                    </Box>
                    <FormErrorMessage
                      mt={1}
                      ml="30px"
                      sx={{
                        color: "#F00",
                        justifyContent: "start",
                      }}
                    >
                      {errors.zip_code}
                    </FormErrorMessage>
                  </FormControl>
                  {isBusiness && (
                    <Box mt="20px" flexBasis="100%" textAlign="right">
                      <Button
                        type="submit"
                        bgColor="#8E8C8C"
                        padding="15px 40px"
                        fontWeight="700"
                        fontSize="14px"
                        lineHeight="17px"
                        color="#FFF"
                        height="48px"
                        width="176px"
                        borderRadius="50px"
                        isLoading={isUpdatingBusinessInformation}
                      >
                        Save Changes
                      </Button>
                    </Box>
                  )}
                </Flex>
              </Box>
            </form>
          )}
        </Formik>
      )}
    </Box>
  );
}
