import { Box, Flex, Image } from "@chakra-ui/react";
import PropTypes from "prop-types";
import AuthBgImage from "assets/img/auth/img_auth_bg.png";

function AuthIllustration(props: { children: JSX.Element | string }) {
  const { children } = props;

  return (
    <Flex
      position="relative"
      bgSize="auto 100%"
      padding="30px"
      height="100vh"
      zIndex="1"
      flexDirection="column"
      _after={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        bgImage: AuthBgImage,
        content: '""',
        zIndex: "-1",
      }}
    >
      <Box mb="20px">
        <a href="http://www.languex.co" className="header__logo">
          <Image
            src={
              "https://www.languex.co/wp-content/themes/languex2/img/svg/logo.svg"
            }
            width="163"
            height="42"
          />
        </a>
      </Box>
      <Flex
        h="100%"
        w="100%"
        mx="auto"
        justifyContent="start"
        direction="column"
      >
        {children}
      </Flex>
    </Flex>
  );
}

AuthIllustration.propTypes = {
  illustrationBackground: PropTypes.string,
  image: PropTypes.any,
};

export default AuthIllustration;
