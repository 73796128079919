import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DocumentType } from "views/customer/order/variables/orderData";

type CustomerProjectsState = {
  isSavingDraftProject: boolean;
  orderId: number;
  uploadProject: {
    orderId?: string | number;
    projectTitle: string;
    text: string;
    documents: DocumentType[];
    projectDescription: string;
    sourceLanguage: string;
    targetLanguage: string[];
    transitionType: "standard" | "certified";
    transitionSpeed: "standard" | "express";
    address: string;
    // is2DayShipping: boolean;
    isMailHardCopy: boolean;
    totalPage: number;
    totalWords: number;
    isNotarized: boolean;
    paymentOption: any;
    saveCardAsDefault: boolean;
  };
  isCompletingOrder: boolean;
  isGettingProjects: boolean;
  isDeletingProject: boolean;
  projects: {
    active_projects: [];
    completed_projects: [];
    draft_projects: [];
    review_projects: [];
  };
  isUpdatingProject: boolean;
  isPaymentCompleted: boolean;

  isFetchingBusinessInformation: boolean;
  isSavingBusinessInformation: boolean;
  businessInformation: {
    company_name: string;
    ein_number: string;
    address: string;
    state: string;
    zip_code: string;
  };
};

const initialState: CustomerProjectsState = {
  isSavingDraftProject: false,
  uploadProject: {
    projectTitle: "",
    documents: [],
    text: "",
    projectDescription: "",
    sourceLanguage: "English",
    targetLanguage: [],
    transitionType: "certified",
    transitionSpeed: "express",
    address: "",
    // is2DayShipping: false,
    isMailHardCopy: false,
    isNotarized: true,
    paymentOption: null,
    saveCardAsDefault: false,
    totalPage: 0,
    totalWords: 0
  },
  isCompletingOrder: false,
  orderId: -1,

  // Get All Projects
  isGettingProjects: false,
  projects: {
    active_projects: [],
    completed_projects: [],
    draft_projects: [],
    review_projects: [],
  },

  isUpdatingProject: false,
  isPaymentCompleted: false,

  isFetchingBusinessInformation: false,
  isSavingBusinessInformation: false,
  businessInformation: {
    company_name: "",
    ein_number: "",
    address: "",
    state: "",
    zip_code: "",
  },
  isDeletingProject: false,
};

const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    updateProjectStatus(state, action: PayloadAction<any>) {
      state.uploadProject = {
        ...state.uploadProject,
        ...action.payload,
      };
    },
    saveDraftProjectStatusRequest(state, action: PayloadAction<any>) {
      state.isSavingDraftProject = true;
    },
    saveDraftProjectStatusResponse(state) {
      state.isSavingDraftProject = false;
    },

    saveOrderRequest(state, action: PayloadAction<any>) {
      state.isPaymentCompleted = false;
      state.isCompletingOrder = true;
    },

    saveOrderRequestSuccess(state, action: PayloadAction<any>) {
      state.isCompletingOrder = false;
      state.orderId = action.payload;
    },

    saveOrderRequestFailed(state) {
      state.isCompletingOrder = false;
      state.orderId = -1;
    },

    paymentFailed(state, action: PayloadAction<any>) {
      state.isCompletingOrder = false;
      state.isPaymentCompleted = false;
    },

    paymentSucced(state) {
      state.isPaymentCompleted = true;
    },

    clearProjectRequest(state) {
      state.isCompletingOrder = false;
      state.orderId = -1;
      state.uploadProject = {
        projectTitle: "",
        documents: [],
        text: "",
        projectDescription: "",
        sourceLanguage: "English",
        targetLanguage: [],
        transitionType: "certified",
        transitionSpeed: "express",
        address: "",
        // is2DayShipping: false,
        isMailHardCopy: false,
        isNotarized: false,
        paymentOption: null,
        saveCardAsDefault: false,
        totalPage: 0,
        totalWords: 0
      };
    },

    clearPaymentCache(state) {
      state.isPaymentCompleted = false;
    },

    gettingProjectRequest(state, action: PayloadAction<any>) {
      state.isGettingProjects = true;
      state.projects = {
        active_projects: [],
        completed_projects: [],
        draft_projects: [],
        review_projects: [],
      };
    },

    gettingProjectRequestSuccess(state, action: PayloadAction<any>) {
      state.isGettingProjects = false;
      state.projects = action.payload;
    },

    gettingProjectRequestFailed(state) {
      state.isGettingProjects = false;
    },

    updateProjectRequest(state, action: PayloadAction<any>) {
      state.isUpdatingProject = true;
    },
    updateProjectRequestSuccess(state, action: PayloadAction<any>) {
      const { orders } = action.payload;
      state.projects = orders;
      state.isUpdatingProject = false;
    },
    updateProjectRequestFailed(state) {
      state.isUpdatingProject = false;
    },

    fetchBusinessInformationRequest(state, action: PayloadAction<any>) {
      state.isFetchingBusinessInformation = true;
    },
    fetchBusinessInformationSuccess(state, action: PayloadAction<any>) {
      state.isFetchingBusinessInformation = false;
      state.businessInformation = action.payload;
    },
    fetchBusinessInformationFailed(state, action: PayloadAction<any>) {
      state.isFetchingBusinessInformation = false;
    },

    updateBusinessInformationRequest(state, action: PayloadAction<any>) {
      state.isSavingBusinessInformation = true;
    },
    updateBusinessInformationSuccess(state, action: PayloadAction<any>) {
      state.isSavingBusinessInformation = false;
    },
    updateBusinessInformationFailed(state, action: PayloadAction<any>) {
      state.isSavingBusinessInformation = false;
    },

    // c(state, action: PayloadAction<any>) {
    //   state.isDeletingProject = true;
    // },
    // deleteProjectRequestSuccess(state, action: PayloadAction<any>) {
    //   state.isDeletingProject = false;
    // },
    // deleteProjectRequestFailed(state, action: PayloadAction<any>) {
    //   state.isDeletingProject = false;
    // },
  },
});

export const {
  updateProjectStatus,
  saveDraftProjectStatusRequest,
  saveDraftProjectStatusResponse,
  saveOrderRequest,
  saveOrderRequestSuccess,
  saveOrderRequestFailed,
  clearProjectRequest,
  clearPaymentCache,
  gettingProjectRequest,
  gettingProjectRequestSuccess,
  gettingProjectRequestFailed,
  updateProjectRequest,
  updateProjectRequestSuccess,
  updateProjectRequestFailed,
  paymentFailed,
  paymentSucced,
  fetchBusinessInformationRequest,
  fetchBusinessInformationSuccess,
  fetchBusinessInformationFailed,
  updateBusinessInformationRequest,
  updateBusinessInformationSuccess,
  updateBusinessInformationFailed,
  // deleteProjectRequest,
  // deleteProjectRequestSuccess,
  // deleteProjectRequestFailed,
} = projectsSlice.actions;

export default projectsSlice.reducer;
