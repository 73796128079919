/* eslint-disable react-hooks/exhaustive-deps */
import {
  Badge,
  Box,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import SortingHandler from "components/sortBox/SortingHandler";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  draftsProjectsCntSelector,
  unreadMessageSelector,
} from "store/project/selector";
import { searchQuerySelector } from "store/ui/selector";
import { userSelector } from "store/user/selector";
import CustomerProjectDetail from "./components/customerProjectDetail";
import CustomerProjectsMobileTable from "./components/customerProjectsMobileTable";
import CustomerProjectsTable from "./components/customerProjectsTable";
import { useWindowWidth } from "@react-hook/window-size";
import CustomPaginations from "components/pagination/CustomPaginations";
import { axiosService } from "network/axios";
import {
  fetchUnreadMessagesByStatus,
  generateQueryParameters,
} from "network/helper";

export default function CustomerProjects() {
  const user = useSelector(userSelector);
  const searchQuery = useSelector(searchQuerySelector);
  const [prevSearchQuery, setPrevSearchQuery] = useState(null);
  const unreadMessages = useSelector(unreadMessageSelector);
  const draftsProjectsCnt = useSelector(draftsProjectsCntSelector);

  const windowWidth = useWindowWidth();
  const [tabIndex, setTabIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(20);
  const [pageLimit, setPageLimit] = useState(10);
  const [isFetching, setIsFetching] = useState(false);
  const [results, setResults] = useState([]);
  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    if (window && user) {
      const tabs = ["Active", "Reviews", "Completed", "Drafts"];
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "project_status_previewed",
        user_id: user.user_id,
        project_status: tabs[tabIndex],
      });
    }
  }, [tabIndex]);

  const [selectedOption, setSelectedOption] = useState<{
    key: string;
    label: string;
    dir: "desc" | "asc";
  }>({
    key: "updated_at",
    label: "Last Updated",
    dir: "desc",
  });

  const sortingOptions = [
    {
      key: "updated_at",
      label: "Last Updated",
    },
    {
      key: "order_due_at",
      label: "Due Date",
    },
    {
      key: "order_status_id",
      label: "Status",
    },
    {
      key: "order_id",
      label: "Project",
    },
  ];

  const { activeMessagesCnt, reviewMessagesCnt, completeMessagesCnt } =
    fetchUnreadMessagesByStatus(unreadMessages);

  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const showDetailsProductDetails = (e: any) => {
    setSelectedProjectId(e);
  };

  const queryParam = useMemo(() => {
    const status =
      tabIndex === 0
        ? "active"
        : tabIndex === 1
        ? "revision"
        : tabIndex === 2
        ? "completed"
        : "drafts";
    if (prevSearchQuery !== searchQuery) {
      setPrevSearchQuery(searchQuery);
      setCurrentPage(1);
      return {
        status: status,
        page: 1,
        limit: pageLimit,
        keyword: searchQuery ?? "",
        order_by: selectedOption?.key,
        dir: selectedOption?.dir,
      };
    }
    return {
      status: status,
      page: currentPage,
      limit: pageLimit,
      keyword: searchQuery ?? "",
      order_by: selectedOption?.key,
      dir: selectedOption?.dir,
    };
  }, [tabIndex, currentPage, pageLimit, searchQuery, selectedOption]);

  const fetchResults = useCallback(async () => {
    setIsFetching(true);
    const params = generateQueryParameters(queryParam);
    try {
      const resp: any = await axiosService.get(
        `/projects/list/${user?.user_id}?${params}`
      );
      const { results, total_rows } = resp?.data;
      setResults(
        results?.map((it: any) => ({
          ...it,
          id: it?.order_id,
        }))
      );

      setTotalPages(
        total_rows / pageLimit > Math.floor(total_rows / pageLimit)
          ? Math.floor(total_rows / pageLimit) + 1
          : Math.floor(total_rows / pageLimit)
      );
      setTotalRows(total_rows);
    } catch (err) {
      console.log(err);
      setResults([]);
    } finally {
      setIsFetching(false);
    }
  }, [user?.user_id, queryParam]);

  useEffect(() => {
    if (user?.user_id) {
      fetchResults();
    }
  }, [user?.user_id, queryParam]);

  // useEffect(() => {
  //   if (
  //     !isFetching &&
  //     (myOrders?.active_projects?.length > 1 ||
  //       myOrders?.completed_projects?.length > 1 ||
  //       myOrders?.draft_projects?.length > 1 ||
  //       myOrders?.review_projects?.length > 1)
  //   ) {
  //     if (!localStorage.getItem("order_status")) {
  //       let statusArray: any[] = [];
  //       myOrders.active_projects?.forEach((it: any) => {
  //         statusArray = [
  //           ...statusArray,
  //           {
  //             order_id: it?.order_id,
  //             order_status_id: it?.order_status_id,
  //             order_status_name: it?.order_status_name,
  //           },
  //         ];
  //       });

  //       myOrders.completed_projects?.forEach((it: any) => {
  //         statusArray = [
  //           ...statusArray,
  //           {
  //             order_id: it?.order_id,
  //             order_status_id: it?.order_status_id,
  //             order_status_name: it?.order_status_name,
  //           },
  //         ];
  //       });

  //       myOrders.draft_projects?.forEach((it: any) => {
  //         statusArray = [
  //           ...statusArray,
  //           {
  //             order_id: it?.order_id,
  //             order_status_id: it?.order_status_id,
  //           },
  //         ];
  //       });

  //       localStorage.setItem("order_status", JSON.stringify(statusArray));
  //     }
  //   }
  // }, [isFetching, myOrders]);

  return (
    <Box pt={{ base: "60px", md: "80px", xl: "80px" }}>
      <Tabs
        variant="unstyled"
        onChange={(index) => {
          setCurrentPage(1);
          setTabIndex(index);
        }}
      >
        <TabList borderBottom={"2px solid #E9E9E9"}>
          <Tab
            padding={{ md: "14px 25px 14px 0px", sm: "10px 10px 10px 0px" }}
            fontSize={{ md: "17px", sm: "14px" }}
            lineHeight={{ md: "24px", sm: "22px" }}
            fontWeight="400"
            alignItems="center"
            _selected={{ color: "blue.500" }}
            _focus={{ boxShadow: "none" }}
          >
            Active
            {activeMessagesCnt > 0 && (
              <Badge
                bgColor={"#F02828"}
                sx={{
                  padding: "9px 6px !important",
                  borderRadius: "1000px",
                  fontWeight: "700",
                  fontSize: "10px",
                  lineHeight: "0px",
                  color: "#FFF",
                  marginLeft: "7px",
                  minWidth: "18px",
                }}
              >
                {activeMessagesCnt}
              </Badge>
            )}
          </Tab>

          <Tab
            padding={{ md: "14px 25px", sm: "10px" }}
            fontSize={{ md: "17px", sm: "14px" }}
            lineHeight={{ md: "24px", sm: "22px" }}
            fontWeight="400"
            alignItems="center"
            _selected={{ color: "blue.500" }}
            _focus={{ boxShadow: "none" }}
          >
            Reviews
            {reviewMessagesCnt > 0 && (
              <Badge
                bgColor={"#F02828"}
                sx={{
                  padding: "9px 6px !important",
                  borderRadius: "1000px",
                  fontWeight: "700",
                  fontSize: "10px",
                  lineHeight: "0px",
                  color: "#FFF",
                  marginLeft: "7px",
                  minWidth: "18px",
                }}
              >
                {reviewMessagesCnt}
              </Badge>
            )}
          </Tab>

          <Tab
            padding={{ md: "14px 25px", sm: "10px" }}
            fontSize={{ md: "17px", sm: "14px" }}
            lineHeight={{ md: "24px", sm: "22px" }}
            fontWeight="400"
            alignItems="center"
            _selected={{ color: "blue.500" }}
            _focus={{ boxShadow: "none" }}
          >
            Completed
            {completeMessagesCnt > 0 && (
              <Badge
                bgColor={"#F02828"}
                sx={{
                  padding: "9px 6px !important",
                  borderRadius: "1000px",
                  fontWeight: "700",
                  fontSize: "10px",
                  lineHeight: "0px",
                  color: "#FFF",
                  marginLeft: "7px",
                  minWidth: "18px",
                }}
              >
                {completeMessagesCnt}
              </Badge>
            )}
          </Tab>
          <Tab
            padding={{ md: "14px 25px", sm: "10px" }}
            fontSize={{ md: "17px", sm: "14px" }}
            lineHeight={{ md: "24px", sm: "22px" }}
            fontWeight="400"
            alignItems="center"
            _selected={{ color: "blue.500" }}
            _focus={{ boxShadow: "none" }}
          >
            Drafts
            {draftsProjectsCnt > 0 && (
              <Badge
                bgColor={"#F02828"}
                sx={{
                  padding: "9px 6px !important",
                  borderRadius: "1000px",
                  fontWeight: "700",
                  fontSize: "10px",
                  lineHeight: "0px",
                  color: "#FFF",
                  marginLeft: "7px",
                  minWidth: "18px",
                }}
              >
                {draftsProjectsCnt}
              </Badge>
            )}
          </Tab>
          <Box
            padding={{ md: "14px 0px", sm: "10px 0px" }}
            position="absolute"
            right="30px"
            display={{
              base: "none",
              md: "block",
            }}
            boxSizing="border-box"
            zIndex={90}
          >
            <SortingHandler
              selectedOption={selectedOption}
              sortingOptions={sortingOptions}
              onChangeSortingOption={(e: any) => {
                setSelectedOption(e);
              }}
            />
          </Box>
        </TabList>
        <TabIndicator mt="-2px" height="2px" bg="blue.500" borderRadius="1px" />

        <TabPanels>
          <TabPanel px="0">
            {(isFetching || results?.length > 0) &&
              (windowWidth >= 768 ? (
                <CustomerProjectsTable
                  pageLimit={pageLimit}
                  isFetching={isFetching}
                  tableData={results}
                  onShowCustomerProjectDetails={showDetailsProductDetails}
                />
              ) : (
                <CustomerProjectsMobileTable
                  isFetching={isFetching}
                  tableData={results}
                  onShowCustomerProjectDetails={showDetailsProductDetails}
                />
              ))}
            {!isFetching && results?.length < 1 && (
              <Box>No Active Projects</Box>
            )}
          </TabPanel>

          <TabPanel px="0">
            {(isFetching || results?.length > 0) &&
              (windowWidth >= 768 ? (
                <CustomerProjectsTable
                  pageLimit={pageLimit}
                  isFetching={isFetching}
                  tableData={results}
                  onShowCustomerProjectDetails={showDetailsProductDetails}
                />
              ) : (
                <CustomerProjectsMobileTable
                  isFetching={isFetching}
                  tableData={results}
                  onShowCustomerProjectDetails={showDetailsProductDetails}
                />
              ))}
            {!isFetching && results?.length < 1 && (
              <Box>No Review Projects</Box>
            )}
          </TabPanel>

          <TabPanel px="0">
            {(isFetching || results?.length > 0) &&
              (windowWidth >= 768 ? (
                <CustomerProjectsTable
                  pageLimit={pageLimit}
                  isFetching={isFetching}
                  tableData={results}
                  onShowCustomerProjectDetails={showDetailsProductDetails}
                />
              ) : (
                <CustomerProjectsMobileTable
                  isFetching={isFetching}
                  tableData={results}
                  onShowCustomerProjectDetails={showDetailsProductDetails}
                />
              ))}
            {!isFetching && results?.length < 1 && (
              <Box>No Completed Projects</Box>
            )}
          </TabPanel>
          <TabPanel px="0">
            {(isFetching || results?.length > 0) &&
              (windowWidth >= 768 ? (
                <CustomerProjectsTable
                  pageLimit={pageLimit}
                  isFetching={isFetching}
                  tableData={results}
                  onShowCustomerProjectDetails={showDetailsProductDetails}
                  onRefresh={fetchResults}
                />
              ) : (
                <CustomerProjectsMobileTable
                  isFetching={isFetching}
                  tableData={results}
                  onShowCustomerProjectDetails={showDetailsProductDetails}
                />
              ))}
            {!isFetching && results?.length < 1 && <Box>No Draft Projects</Box>}
          </TabPanel>
        </TabPanels>
        {!isFetching && totalPages >= 1 && (
          <CustomPaginations
            totalCount={totalRows}
            totalPages={totalPages}
            currentPage={currentPage}
            perPage={pageLimit}
            onSetPageLimit={(e: number) => {
              setPageLimit(e);
              setCurrentPage(1);
            }}
            onChangePage={(e: number) => {
              setCurrentPage(e);
            }}
          />
        )}
      </Tabs>
      <CustomerProjectDetail
        selectedProjectId={selectedProjectId}
        onRefresh={fetchResults}
        onCloseModal={() => setSelectedProjectId(null)}
      />
    </Box>
  );
}
