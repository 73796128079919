import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import IconBox from "components/icons/IconBox";
import { IconArrowDown } from "components/icons/Icons";
import { axiosService } from "network/axios";
import { pad_with_zeroes } from "network/helper";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getManagerProjects,
  getUnreadMessagesRequest,
} from "store/project/reducer";
import { managersSelector } from "store/project/selector";
import { setAlertMessageRequest } from "store/ui/reducer";
import { userSelector } from "store/user/selector";

export default function ManagerDeliverOrder(props: {
  selectedProjectId: string;
  onCloseProjectDetailsPopup: Function;
}) {
  const { selectedProjectId, onCloseProjectDetailsPopup } = props;
  const dispatch = useDispatch();
  const [isDelivering, setIsDelivering] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const shadow = useColorModeValue(
    "0px 4px 50px rgba(0, 0, 0, 0.1)",
    "0px 4px 50px rgba(0, 0, 0, 0.1)"
  );

  const user = useSelector(userSelector);
  const managers = useSelector(managersSelector);
  console.log(managers);
  const deliverableManagers = managers
    .filter((it) => it?.user_id !== user?.user_id)
    ?.map((it) => {
      return {
        user_id: it.user_id,
        name: `${it?.first_name} ${it?.last_name}`,
        email: `${it?.email}`,
        user_status_id: it?.user_status_id,
      };
    });
  const [selectedManager, setSelectedManager] = useState(null);
  const [deliverReason, setDeliverReason] = useState("");
  const onSubmit = async () => {
    setIsDelivering(true);
    await axiosService.post(
      `/projects/deliver/order/${selectedProjectId}/${user.user_id}/${selectedManager.user_id}`,
      {
        reason: deliverReason,
      }
    );
    dispatch(getManagerProjects({ user_id: Number(user.user_id) }));
    dispatch(getUnreadMessagesRequest({ user_id: Number(user.user_id) }));
    dispatch(
      setAlertMessageRequest({
        type: "success",
        message: "Project has been delivered successfully!",
      })
    );
    setIsDelivering(false);
    onClose();
    onCloseProjectDetailsPopup();
  };

  return (
    <Card
      p="20px 16px"
      width="280px"
      height="fit-content"
      marginLeft={{ md: "auto", sm: "0px", base: "0px" }}
      marginTop={{ md: "0px", base: "0px" }}
      background="#FFF"
      boxShadow={{
        md: "0px 4px 50px rgba(0, 0, 0, 0.1)",
        base: "none",
      }}
      border={{
        md: "none",
        base: "1px solid #E9E9E9",
      }}
      borderRadius={{ md: "20px", base: "0px" }}
    >
      <Flex alignItems="center" mb={2}>
        <Text fontSize="14px" fontWeight="bold">
          Assign order to another PM
        </Text>
      </Flex>
      <Flex alignItems="center">
        <Menu>
          <MenuButton
            flex="1"
            padding={"10px 10px 10px 15px"}
            backgroundColor="#FFF"
            borderRadius="0.375rem"
            borderRightRadius="0px"
            border="1px solid #E9E9E9"
            h="40px"
          >
            <Flex alignItems={"center"}>
              <Text
                fontSize={"14px"}
                lineHeight="24px"
                fontWeight={"400"}
                color="black"
                maxW={{ md: "100px", base: "100%" }}
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                overflow="hidden"
              >
                {selectedManager ? selectedManager.name : "Not Selected"}
              </Text>
              <IconBox
                marginLeft="auto"
                icon={<IconArrowDown width="24px" height="24px" />}
              ></IconBox>
            </Flex>
          </MenuButton>

          <MenuList
            width="195px"
            minWidth="195px"
            boxShadow={shadow}
            p="0px"
            mt="10px"
            borderRadius="20px"
            bg={"#FFF"}
            border="none"
          >
            <Flex
              flexDirection="column"
              p="10px 20px"
              fontSize={"14px"}
              lineHeight="24px"
              fontWeight={"400"}
              color="black"
            >
              {deliverableManagers.map((manager, index: number) => (
                <MenuItem
                  key={index}
                  _hover={{ bg: "none" }}
                  _focus={{ bg: "none" }}
                  borderRadius="8px"
                  padding="10px 0px"
                  onClick={() => {
                    setSelectedManager(manager);
                  }}
                  borderBottom={
                    index < deliverableManagers.length - 1
                      ? "1px solid #F3F5F5"
                      : ""
                  }
                >
                  <Text fontSize="sm">{manager.name}</Text>
                </MenuItem>
              ))}
            </Flex>
          </MenuList>
        </Menu>
        <Button
          padding="6px 12px"
          fontSize="12px"
          lineHeight="15px"
          fontWeight="400"
          color="#FFF"
          h="40px"
          borderRadius="5px"
          bgColor="#2F80ED"
          borderTopLeftRadius="0px"
          borderBottomLeftRadius="0px"
          disabled={!selectedManager}
          onClick={() => {
            if (Number(selectedManager?.user_status_id) === 5) {
              dispatch(
                setAlertMessageRequest({
                  type: "error",
                  message:
                    "Failed to assign the project to selected Manager, he is not available for now.",
                })
              );
              return;
            }
            onOpen();
          }}
        >
          <ArrowForwardIcon
            sx={{
              fontSize: "18px",
            }}
          />
        </Button>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            fontSize="17px"
            lineHeight="24px"
            fontWeight="700"
            color="#000"
            textAlign="center"
          >
            Deliver the Order {pad_with_zeroes(selectedProjectId, 6)} to{" "}
            {selectedManager?.name}.
          </ModalHeader>

          <ModalBody pt={0}>
            <Textarea
              placeholder="Please write the reason why you need to deliver this order."
              _placeholder={{
                fontSize: "14px",
              }}
              value={deliverReason}
              onChange={(e) => setDeliverReason(e.target.value)}
            ></Textarea>
          </ModalBody>
          <ModalFooter columnGap="10px" justifyContent="center">
            <Button
              height="35px"
              padding="10px 25px"
              borderRadius="100px"
              bgColor="#2F80ED"
              color="#FFF"
              fontSize="12px"
              lineHeight="17px"
              fontWeight="bold"
              width="100px"
              isLoading={isDelivering}
              disabled={deliverReason?.length < 1}
              _disabled={{
                backgroundColor: "#E9E9E9 !important",
              }}
              onClick={(e) => onSubmit()}
            >
              Submit
            </Button>
            <Button
              height="35px"
              padding="10px 25px"
              borderRadius="100px"
              bgColor="#FFF"
              border="1px solid #2F80ED"
              color="#2F80ED"
              fontSize="12px"
              lineHeight="17px"
              fontWeight="bold"
              width="100px"
              onClick={() => onClose()}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Card>
  );
}
