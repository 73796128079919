import "./assets/css/App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import AuthLayout from "./layouts/auth";
import AdminLayout from "./layouts/admin";
import TranslatorLayout from "./layouts/translator";
import ManagerLayout from "./layouts/manager";
import CustomerLayout from "./layouts/customer";
import { useEffect } from "react";
import { alertSelector } from "store/ui/selector";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

export default function Root() {
  const searchParams = new URLSearchParams(window.location.search);
  const login_id = searchParams.get("login_id");
  if (login_id) {
    localStorage.removeItem("access_token");
  }

  const isLoggedIn = login_id
    ? false
    : localStorage.getItem("access_token")
    ? true
    : false;
  const alert = useSelector(alertSelector);

  useEffect(() => {
    const portalId = 43773541;
    let script = document.createElement("script");
    script.src = `//js-na1.hs-scripts.com/${portalId}.js`;
    script.async = true;

    let hubspotAnalyticScript = document.createElement("script");
    hubspotAnalyticScript.src = `//js.hs-scripts.com/${portalId}.js`;
    hubspotAnalyticScript.async = true;

    document.body.appendChild(hubspotAnalyticScript);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(hubspotAnalyticScript);
      document.body.removeChild(script);
    };
  }, [isLoggedIn]);

  useEffect(() => {
    if (alert && alert.message) {
      if (alert.type === "success") {
        toast.success(alert.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (alert.type === "error") {
        toast.error(alert.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (alert.type === "warning") {
        toast.warn(alert.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  }, [alert]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path={`/auth/*`} element={<AuthLayout />} />
        <Route path={`/admin/*`} element={<AdminLayout />} />
        <Route path={`/translator/*`} element={<TranslatorLayout />} />
        <Route path={`/manager/*`} element={<ManagerLayout />} />
        <Route path={`/customer/*`} element={<CustomerLayout />} />
        <Route path="/" element={<Navigate to="/auth/sign-in" replace />} />
      </Routes>
      {!isLoggedIn && !window.location.href.includes("auth") && (
        <Navigate to="/auth/sign-in" />
      )}
      <ToastContainer />
    </BrowserRouter>
  );
}
