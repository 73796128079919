import { createSelector } from "reselect";
import { RootState } from "../common/reducer";

export const customerProjectState = (rootState: RootState) =>
  rootState.customerProject;

export const uploadProjectSelector = createSelector(
  [customerProjectState],
  (state) => state.uploadProject
);

export const isSavingDraftProjectSelector = createSelector(
  [customerProjectState],
  (state) => state.isSavingDraftProject
);

export const uploadedOrderIdSelector = createSelector(
  [customerProjectState],
  (state) => state.orderId
);

export const isCompletingOrder = createSelector(
  [customerProjectState],
  (state) => state.isCompletingOrder
);

export const myOrdersSelector = createSelector(
  [customerProjectState],
  (state) => state.projects
);

export const isFetchingMyOrdersSelector = createSelector(
  [customerProjectState],
  (state) => state.isGettingProjects
);

export const isUpdatingProjectSelector = createSelector(
  [customerProjectState],
  (state) => state.isUpdatingProject
);

export const isPaymentCompletedSelector = createSelector(
  [customerProjectState],
  (state) => state.isPaymentCompleted
);

export const isFetchingBusinessSelector = createSelector(
  [customerProjectState],
  (state) => state.isFetchingBusinessInformation
);

export const businessInformationSelector = createSelector(
  [customerProjectState],
  (state) => state.businessInformation
);

export const isUpdatingBusinessDetailsSelector = createSelector(
  [customerProjectState],
  (state) => state.isUpdatingProject
);

export const isDeletingProjectSelector = createSelector(
  [customerProjectState],
  (state) => state.isDeletingProject
);
