import { Box, Flex, Text } from "@chakra-ui/react";
import IconBox from "components/icons/IconBox";
import { IconCheckedCircleFill } from "components/icons/Icons";

export default function TranslationTypeButton(props: {
  selected: boolean;
  type: string;
  onSelectTransitionType: Function;
  label: string;
  [x: string]: any;
}) {
  const { selected, type, onSelectTransitionType, label, ...rest } = props;

  return (
    <Flex
      position="relative"
      padding="16px 12px"
      backgroundColor="#FFF"
      borderRadius="16px"
      border={`1px solid ${selected ? "#2F80ED" : "#E0E0E0"}`}
      cursor="pointer"
      transition="all .3s"
      onClick={() => onSelectTransitionType(type)}
      _hover={{
        borderColor: "#2F80EDAA",
      }}
      {...rest}
    >
      <Flex columnGap="10px" alignItems="flex-start">
        {selected ? (
          <IconBox
            w="17px"
            h="17px"
            mt="1px"
            icon={<IconCheckedCircleFill w="18px" h="17px" />}
          />
        ) : (
          <Box
            w="17px"
            minW="17px"
            h="17px"
            mt="1px"
            borderRadius="50px"
            border="1px solid #8E8C8C"
          ></Box>
        )}
        <Box>
          <Text
            fontSize="15px"
            lineHeight="normal"
            fontWeight="500"
            color="#000"
            textTransform="capitalize"
          >
            {type === "certified"
              ? "Certified Translation(For official use/purpose)"
              : "General Translation"}
          </Text>
          <Text
            mt="8px"
            fontSize="12px"
            lineHeight="1.4"
            fontWeight="400"
            color="rgba(0, 0, 0, 0.6)"
          >
            {label}
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
}
