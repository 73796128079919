import { createSelector } from "reselect";
import { RootState } from "../common/reducer";

export const selectUserState = (rootState: RootState) => rootState.user;

export const isLoggedInSelector = createSelector(
  [selectUserState],
  (state) => state.isLoggedIn
);

export const authErrorSelector = createSelector(
  [selectUserState],
  (state) => state.authError
);

export const isLoadingUserSelector = createSelector(
  [selectUserState],
  (state) => state.loading
);

export const isRegisteringSelector = createSelector(
  [selectUserState],
  (state) => state.isRegistering
);

export const userSelector = createSelector(
  [selectUserState],
  (state) =>
    state.user ??
    (localStorage.getItem("user_details")
      ? JSON.parse(localStorage.getItem("user_details"))
      : null)
);

export const isUpdatingUserSelector = createSelector(
  [selectUserState],
  (state) => state.isUpdating
);

export const cardsSelector = createSelector(
  [selectUserState],
  (state) => state.cards
);

export const isGettingCardsSelector = createSelector(
  [selectUserState],
  (state) => state.isGettingCards
);

export const isSavingCardSelector = createSelector(
  [selectUserState],
  (state) => state.isSavingCards
);

export const alertSelector = createSelector(
  [selectUserState],
  (state) => state.alert
);

export const allUsersSelector = createSelector(
  [selectUserState],
  (state) => state.allUsers
);

export const isGettingAllUsersSelector = createSelector(
  [selectUserState],
  (state) => state.isGettingAllUsers
);

export const isRegisteringIDStateSelector = createSelector(
  [selectUserState],
  (state) => state.isRegisteringID
);

export const isGettingIDStateSelector = createSelector(
  [selectUserState],
  (state) => state.isGettingID
);

export const personalIDSelector = createSelector(
  [selectUserState],
  (state) => state.personalID
);

export const isGettingLanguages = createSelector(
  [selectUserState],
  (state) => state.isGettingLanguages
);

export const isUpdatingLanguages = createSelector(
  [selectUserState],
  (state) => state.isUpdatingLanguages
);

export const languagesSelector = createSelector(
  [selectUserState],
  (state) => state.languages
);

export const isAddingBankAccountSelector = createSelector(
  [selectUserState],
  (state) => state.isAddingBankAccount
);

export const isRemovingBankAccountSelector = createSelector(
  [selectUserState],
  (state) => state.isRemovingBankAccount
);

export const isGettingBankAccountsSelector = createSelector(
  [selectUserState],
  (state) => state.isGettingBankAccounts
);

export const bankAccountsSelector = createSelector(
  [selectUserState],
  (state) => state.bankAccounts
);

// export const isGettingAssignedServicesSelector = createSelector(
//   [selectUserState],
//   (state) => state.isGettingAssignedServices
// );

// export const isAssigningServiceSelector = createSelector(
//   [selectUserState],
//   (state) => state.isAssigningService
// );

// export const isUnAssigningServiceSelector = createSelector(
//   [selectUserState],
//   (state) => state.isUnAssigningService
// );

// export const assignedServicesSelector = createSelector(
//   [selectUserState],
//   (state) => state.assignedServices
// );

export const teamUsersSelector = createSelector(
  [selectUserState],
  (state) => state.teamUsers
);

export const isFetchingTeamUsersSelector = createSelector(
  [selectUserState],
  (state) => state.isFetchingTeams
);

export const isSavingTeammemberSelector = createSelector(
  [selectUserState],
  (state) => state.isSavingTeammember
);

export const isRemovingTeammemberSelector = createSelector(
  [selectUserState],
  (state) => state.isRemovingTeammember
);

export const isUploadingInvoiceSelector = createSelector(
  [selectUserState],
  (state) => state.isUploadingInvoice
);

export const invoicesSelector = createSelector(
  [selectUserState],
  (state) => state.invoices
);

export const isGettingInvoicesSelector = createSelector(
  [selectUserState],
  (state) => state.isGettingInvoices
);
