import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
// Custom components
import Card from "components/card/Card";
import IconBox from "components/icons/IconBox";
import {
  IconArrowDown,
  IconDownload,
  IconTranslator,
  IconUpload,
} from "components/icons/Icons";
import _ from "lodash";
import { fetchLanguagesExcerpt } from "network/helper";
import * as React from "react";
import { FileUploader } from "react-drag-drop-files";
import { useSelector } from "react-redux";
import { allUsersSelector } from "store/user/selector";

type RowObj = {
  document_id: string;
  language: string;
  source_lang?: string;
  target_lang?: string;
  document_status_id: string;
  file: null;
  origin_file?: any;
  lastest_file?: any;
  translators: any[];
  managers: any[];
  actionCol: string;
  hasChanged: boolean;
  new_file: any;
};
const documentStatus = [
  {
    id: 1,
    label: "New",
  },
  {
    id: 2,
    label: "In Progress",
  },
  {
    id: 3,
    label: "Ready for Review",
  },
  {
    id: 4,
    label: "Completed",
  },
  {
    id: 5,
    label: "In Revision",
  },
];

const columnHelper = createColumnHelper<RowObj>();

export default function ManagerProjectDetailMobileTable(props: {
  isFetching: boolean;
  tableData: any;
  onUpdateDocument: Function;
}) {
  const { isFetching, tableData, onUpdateDocument } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const textColor = useColorModeValue("black", "black");
  const allUsers = useSelector(allUsersSelector);
  const translators = React.useMemo(() => {
    if (allUsers) {
      return allUsers?.translators || [];
    }
    return [];
  }, [allUsers]);

  const shadow = useColorModeValue(
    "0px 4px 50px rgba(0, 0, 0, 0.1)",
    "0px 4px 50px rgba(0, 0, 0, 0.1)"
  );
  let defaultData = tableData;
  const [data, setData] = React.useState(() => [...defaultData]);

  const onChangeDocumentStatus = (document_id: string, option: any) => {
    let index = data?.findIndex((it) => it.document_id === document_id);
    if (index > -1) {
      let dumpData = _.cloneDeep(data);
      dumpData[index].document_status_id = option.id;
      dumpData[index].hasChanged = true;
      setData([...dumpData]);
    }
  };

  const onAssignTranslator = (document_id: string, translator: any) => {
    let index = data?.findIndex((it) => it.document_id === document_id);
    if (index > -1) {
      let dumpData = _.cloneDeep(data);
      dumpData[index].translators = [translator];
      dumpData[index].hasChanged = true;
      setData([...dumpData]);
    }
  };

  const onClickSaveDocument = (document_id: string) => {
    let index = data?.findIndex((it) => it.document_id === document_id);
    if (index > -1) {
      onUpdateDocument(data[index]);
    }
  };

  const handleChange = (document_id: any, file: any) => {
    let index = data?.findIndex((it) => it.document_id === document_id);
    if (index > -1) {
      let dumpData = _.cloneDeep(data);
      dumpData[index].new_file = file;
      dumpData[index].hasChanged = true;
      setData([...dumpData]);
    }
  };

  const columns = [
    columnHelper.accessor("language", {
      id: "language",
      header: () => (
        <Text
          align="left"
          fontSize="14px"
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Language
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
            whiteSpace="nowrap"
          >
            {info.row.original?.source_lang &&
              info.row.original?.target_lang && (
                <React.Fragment>
                  {fetchLanguagesExcerpt(info.row.original?.source_lang)}
                  <IconTranslator width="15px" height="16px" mx="4px" />
                  {fetchLanguagesExcerpt(info.row.original?.target_lang)}
                </React.Fragment>
              )}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("document_status_id", {
      id: "document_status_id",
      header: () => (
        <Text
          align="left"
          fontSize="14px"
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Status
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Menu>
            <MenuButton
              padding={"5px 5px 5px 10px"}
              backgroundColor="#F3F5F5"
              borderRadius="5px"
            >
              <Flex alignItems={"center"}>
                <Text
                  fontSize={"14px"}
                  lineHeight="24px"
                  fontWeight={"400"}
                  color="black"
                >
                  {info.getValue()
                    ? documentStatus?.find(
                        (it) => it.id === Number(info.getValue())
                      )?.label
                    : "-"}
                </Text>
                <IconBox
                  marginLeft="10px"
                  icon={<IconArrowDown width="24px" height="24px" />}
                ></IconBox>
              </Flex>
            </MenuButton>
            <MenuList
              boxShadow={shadow}
              p="0px"
              mt="10px"
              borderRadius="20px"
              bg={"#FFF"}
              border="none"
            >
              <Flex
                flexDirection="column"
                p="10px 20px"
                fontSize={"14px"}
                lineHeight="24px"
                fontWeight={"400"}
                color="black"
              >
                {documentStatus.map((option, index: number) => (
                  <MenuItem
                    key={index}
                    _hover={{ bg: "none" }}
                    _focus={{ bg: "none" }}
                    borderRadius="8px"
                    padding="15px 0px"
                    onClick={() =>
                      onChangeDocumentStatus(
                        info.row.original.document_id,
                        option
                      )
                    }
                    borderBottom={
                      index < info.getValue().length - 1
                        ? "1px solid #F3F5F5"
                        : ""
                    }
                  >
                    <Text fontSize="sm">{option.label}</Text>
                  </MenuItem>
                ))}
              </Flex>
            </MenuList>
          </Menu>
        </Flex>
      ),
    }),
    columnHelper.accessor("file", {
      id: "file",
      header: () => (
        <Text
          align="left"
          fontSize="14px"
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          File
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Flex
            color={textColor}
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
            alignItems={"center"}
            padding="5px 0px"
            w="100%"
          >
            <Box mr="auto" textOverflow="ellipsis" overflow="hidden">
              {info.row.original.new_file
                ? info.row.original.new_file.name
                : info.row.original.lastest_file
                ? info.row.original.lastest_file.document_original_file_name
                : info.row.original.origin_file?.document_original_file_name ||
                  ""}
            </Box>
            <FileUploader
              multiple={false}
              handleChange={(file: File) => {
                handleChange(info.row.original.document_id, file);
              }}
              name="file"
              height="full"
            >
              <IconBox
                padding="5px 10px"
                icon={<IconUpload width="24px" height="24px" />}
                background="rgba(47, 128, 237, 0.14)"
                borderRadius="5px"
                marginLeft="32px"
                cursor="pointer"
              ></IconBox>
            </FileUploader>

            <IconBox
              padding="5px 10px"
              background="#F3F5F5"
              borderRadius="5px"
              icon={<IconDownload width="24px" height="24px" />}
              marginLeft="8px"
              cursor="pointer"
              onClick={() => {
                const document_file_path = info.row.original.lastest_file
                  ? info.row.original.lastest_file.document_file_path
                  : info.row.original.origin_file?.document_file_path || "";
                const document_original_file_name = info.row.original
                  .lastest_file
                  ? info.row.original.lastest_file.document_original_file_name
                  : info.row.original.origin_file
                      ?.document_original_file_name || "";

                const fullUrl =
                  document_file_path?.includes("http://") ||
                  document_file_path?.includes("https://")
                    ? document_file_path
                    : `${process.env.REACT_APP_API_BASE_URL}/${document_file_path}/${document_original_file_name}`;

                // const fullUrl = `${process.env.REACT_APP_API_BASE_URL}/${document_file_path}/${document_original_file_name}`;
                const link = document.createElement("a");
                link.href = fullUrl;
                link.target = "_blank";
                link.setAttribute("download", `${document_original_file_name}`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
              }}
            ></IconBox>
          </Flex>
        </Flex>
      ),
    }),

    columnHelper.accessor("translators", {
      id: "translators",
      header: () => (
        <Text
          align="left"
          fontSize="14px"
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Translator
        </Text>
      ),
      cell: (info) => (
        <Flex alignItems="center">
          <Menu>
            <MenuButton
              padding={"5px 5px 5px 10px"}
              backgroundColor="#FFF"
              borderRadius="5px"
              border="1px solid #E9E9E9"
            >
              <Flex alignItems={"center"}>
                <Text
                  fontSize={"14px"}
                  lineHeight="24px"
                  fontWeight={"400"}
                  color="black"
                  textOverflow="ellipsis"
                  overflow="hidden"
                >
                  {info.getValue()?.length > 0
                    ? `${info.getValue()[0]?.first_name} ${
                        info.getValue()[0]?.last_name
                      }`
                    : "Not Assigned"}
                </Text>
                <IconBox
                  marginLeft="10px"
                  icon={<IconArrowDown width="24px" height="24px" />}
                ></IconBox>
              </Flex>
            </MenuButton>
            <MenuList
              width="195px"
              minWidth="195px"
              boxShadow={shadow}
              p="0px"
              mt="10px"
              borderRadius="20px"
              bg={"#FFF"}
              border="none"
            >
              <Flex
                flexDirection="column"
                p="10px 20px"
                fontSize={"14px"}
                lineHeight="24px"
                fontWeight={"400"}
                color="black"
              >
                {translators.map((option: any, index: number) => (
                  <MenuItem
                    key={index}
                    _hover={{ bg: "none" }}
                    _focus={{ bg: "none" }}
                    borderRadius="8px"
                    padding="10px 0px"
                    borderBottom={
                      index < translators.length - 1 ? "1px solid #F3F5F5" : ""
                    }
                    onClick={() =>
                      onAssignTranslator(info.row.original.document_id, option)
                    }
                  >
                    <Text fontSize="sm">{`${option?.first_name ?? ""} ${
                      option?.last_name ?? ""
                    }`}</Text>
                    <Button
                      padding="5px 10px"
                      borderRadius="100px"
                      bgColor="#F3F5F5"
                      fontSize={"14px"}
                      lineHeight="24px"
                      fontWeight={"400"}
                      marginLeft="auto"
                    >
                      View
                    </Button>
                  </MenuItem>
                ))}
              </Flex>
            </MenuList>
          </Menu>
        </Flex>
      ),
    }),

    columnHelper.accessor("actionCol", {
      id: "actionCol",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          color="gray.500"
          fontWeight="400"
        ></Text>
      ),
      cell: (info) => (
        <Flex justifyContent={"flex-end"} alignItems="center">
          <Button
            disabled={!info.row.original.hasChanged}
            padding={{ sm: "10px 10px", base: "10px" }}
            border={"2px solid #2F80FD"}
            bgColor="#2F80FD"
            borderRadius="0px"
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
            color="#FFF"
            w="100%"
            bg="#FFF"
            _hover={{
              bgColor: "#FFF",
              color: "#2F80FD",
            }}
            onClick={(e) => onClickSaveDocument(info.row.original.document_id)}
          >
            Save Changes
          </Button>
        </Flex>
      ),
    }),
  ];
  React.useEffect(() => {
    setData([...defaultData]);
  }, [defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Card
      flexDirection="column"
      w="100%"
      p="0px"
      mb="40px"
      overflow="hidden"
      borderRadius="0"
      sx={{
        ".chakra-menu__menu-button + div": {
          position: "fixed !important",
        },
      }}
    >
      <Box w="100%">
        <Table
          w="100%"
          variant="simple"
          color="gray.500"
          pb="2"
          lineHeight="15px"
          borderRadius="0px"
        >
          {!isFetching &&
            table
              .getRowModel()
              .rows.slice(0, 11)
              .map((row: any, index: number) => {
                return (
                  <Table
                    key={index}
                    mt={index === 0 ? "10px" : "15px"}
                    border="1px solid #dcdcdc"
                  >
                    <Tbody>
                      {row
                        .getVisibleCells()
                        .map((cell: any, _index: number) => {
                          return (
                            <Tr key={_index}>
                              {_index < 4 ? (
                                <React.Fragment>
                                  <Td
                                    p={
                                      _index === 2
                                        ? "0px 5px 0px 10px"
                                        : _index === 1 || _index === 3
                                        ? "5px 10px"
                                        : "10px"
                                    }
                                    width="80px"
                                    borderColor="#dcdcdc"
                                    borderRight="1px solid #dcdcdc"
                                    bgColor="#FAFAFA"
                                  >
                                    {flexRender(
                                      table.getHeaderGroups()[0].headers[_index]
                                        .column.columnDef.header,
                                      table
                                        .getHeaderGroups()[0]
                                        .headers[_index].getContext()
                                    )}
                                  </Td>

                                  <Td
                                    p={
                                      _index === 2
                                        ? "0px 5px 0px 10px"
                                        : _index === 1 || _index === 3
                                        ? "5px 10px"
                                        : "10px"
                                    }
                                    position="relative"
                                  >
                                    {flexRender(
                                      cell.column.columnDef.cell,
                                      cell.getContext()
                                    )}
                                  </Td>
                                </React.Fragment>
                              ) : (
                                <Td colSpan={2} padding="0px">
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </Td>
                              )}
                            </Tr>
                          );
                        })}
                    </Tbody>
                  </Table>
                );
              })}
          {isFetching &&
            [1, 2].map((row) => {
              return (
                <Table
                  key={row}
                  mt={row === 0 ? "10px" : "35px"}
                  border="1px solid #dcdcdc"
                >
                  <Tbody>
                    {[1, 2, 3, 4].map((_it) => (
                      <Tr key={_it}>
                        <Td
                          p="10px"
                          width="110px"
                          borderColor="#dcdcdc"
                          borderRight="1px solid #dcdcdc"
                          bgColor="#FAFAFA"
                        >
                          <Skeleton height="24px" width="100%" />
                        </Td>
                        <Td p="10px" borderColor="#dcdcdc">
                          <Skeleton height="24px" width="100%" />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              );
            })}
        </Table>
      </Box>
    </Card>
  );
}
