import {
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Stripe, StripeCardElement } from "@stripe/stripe-js";
import IconBox from "components/icons/IconBox";
import { IconArrowBack, IconPlus } from "components/icons/Icons";
import { axiosService } from "network/axios";
import { fetchExpeditedServicePrice } from "network/helper";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gettingProjectRequest } from "store/customerProject/reducer";
import { uploadProjectSelector } from "store/customerProject/selector";
import { setAlertMessageRequest } from "store/ui/reducer";
import { userSelector } from "store/user/selector";
import { ServiceRowObj } from "../variables/serviceData";
import ActiveProjects from "./ActiveProjects";
import PaymentStep from "./PaymentStep";

export default function ServiceItem(props: {
  allActiveProjects: any[];
  item: ServiceRowObj;
  onRefresh?: Function;
  [x: string]: any;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const { item, onRefresh, allActiveProjects, ...rest } = props;
  const boxShadow = "0px 4px 40px rgba(0, 0, 0, 0.1)";
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [cardInfo, setCardInfo] = useState(null);
  const [processStep, setProcessStep] = useState(0);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const uploadProject = useSelector(uploadProjectSelector);

  const onStartPaymentStep = () => {
    setProcessStep(0);
    onOpen();
  };

  const onPayNow = async () => {
    setIsProcessingPayment(true);
    const stripe: Stripe = cardInfo?.stripe;
    const cardElement: StripeCardElement = cardInfo?.cardElement;
    const cardHolderName = cardInfo?.cardHolderName ?? "";
    const cardZipCode = cardInfo?.cardZipCode ?? "";

    if (uploadProject.saveCardAsDefault === true) {
      // Save Card Information As Default
      if (stripe) {
        stripe
          .createPaymentMethod({
            type: "card",
            card: cardElement,
            billing_details: {
              name: cardInfo.cardHolderName,
            },
          })
          .then(async function (result) {
            const paymentMethodId = result?.paymentMethod?.id;
            await axiosService.post(
              `/shipping/put/payment-method/${user.user_id}`,
              {
                payment_method_id: paymentMethodId,
                is_default: 1,
              }
            );
          });
      }
    }

    const serviceId = item.id;

    let paymentPayload = null;
    if (cardInfo?.customer_id) {
      paymentPayload = {
        amount: 20,
        customer_id: cardInfo?.customer_id,
        payment_method_id: cardInfo?.pm_id,
      };

      await proceedPayment(serviceId, paymentPayload);
    } else {
      stripe
        .createToken(cardElement, {
          name: cardHolderName,
          address_zip: cardZipCode,
        })
        .then(async (res) => {
          const tokenId = res?.token?.id;
          if (tokenId) {
            paymentPayload = {
              amount: 20,
              tokenId: tokenId,
            };
            await proceedPayment(serviceId, paymentPayload);
          } else if (res?.error?.message) {
            dispatch(
              setAlertMessageRequest({
                type: "error",
                message:
                  res?.error?.message ||
                  "Error in processing the payment. Please contact to support team.",
              })
            );
          } else {
            dispatch(
              setAlertMessageRequest({
                type: "error",
                message:
                  "Error in generating token Id. please check the card information.",
              })
            );
          }
        })
        .catch(async (err) => {
          dispatch(
            setAlertMessageRequest({
              type: "error",
              message:
                err?.error?.message ||
                "Error in processing the payment. Please contact to support team.",
            })
          );
        });
    }
  };

  const proceedPayment = async (serviceId: number, payload: any) => {
    try {
      const { order_id } = selectedOrder;
      await axiosService.post(
        `/shipping/process-service-payment/${order_id}/${serviceId}`,
        payload
      );
      dispatch(
        setAlertMessageRequest({
          type: "success",
          message: "Service was added to selected Payment.",
        })
      );
      dispatch(gettingProjectRequest({ user_id: Number(user.user_id) }));
      setIsProcessingPayment(false);
    } catch (err: any) {
      dispatch(
        setAlertMessageRequest({
          type: "error",
          message: err?.data?.messages?.error || "Error in Processing Payment.",
        })
      );
    } finally {
      setIsProcessingPayment(false);
    }
    onRefresh?.();
    onClose();
  };

  return (
    <>
      <Flex
        padding={{
          base: "20px",
          md: "35px 40px",
        }}
        borderRadius="20px"
        bgColor="#FFF"
        boxShadow={boxShadow}
        direction="column"
        {...rest}
      >
        <Image src={item.icon} w="140px" h="130px" objectFit={"contain"} />
        <Text
          fontSize="22px"
          lineHeight="135%"
          color="#000"
          fontWeight="700"
          mt="50px"
        >
          {item.title}
        </Text>
        <Flex direction="column" rowGap="10px" mt="18px" mb="25px">
          {item.description}
        </Flex>
        <Flex mt="auto" alignItems="center" columnGap="10px">
          <Button
            w="120px"
            height="fit-content"
            padding="15px 30px"
            borderRadius="100px"
            bgColor="#2F80ED"
            color="#FFF"
            fontSize="14px"
            lineHeight="17px"
            fontWeight="400"
            onClick={() => {
              onStartPaymentStep();
            }}
          >
            <IconBox
              w="24px"
              h="24px"
              mr="10px"
              icon={<IconPlus w="24px" h="24px" />}
            />
            Add
          </Button>
          <Text
            fontSize="17px"
            lineHeight="135%"
            color="#000"
            fontWeight="700"
            ml="auto"
          >
            {item.id !== 3 && <>${item.price}</>}
            {item.id === 3 && (
              <>
                $10/page <br />
                $0.004/word
              </>
            )}
          </Text>
        </Flex>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            fontSize="20px"
            lineHeight="24px"
            fontWeight="700"
            color="#000"
            textAlign="center"
            pt="25px"
          >
            {processStep === 0 &&
              "Please select the Project to add this service."}
            {processStep === 1 && "Payment for the additional service."}
          </ModalHeader>
          <ModalBody py={4}>
            {processStep === 0 && (
              <ActiveProjects
                tableData={allActiveProjects}
                serviceItem={item}
                selectedOrder={selectedOrder}
                onSelectOrder={(e: any) => {
                  setSelectedOrder(e);
                }}
              />
            )}
            {processStep === 1 && (
              <PaymentStep
                selectedOrder={selectedOrder}
                onPickPaymentInfo={(e: any) => {
                  setCardInfo(e);
                }}
              />
            )}
          </ModalBody>
          <ModalFooter borderTop="1px solid #EEE">
            <Button
              height="fit-content"
              padding="15px 30px"
              borderRadius="100px"
              bgColor="#2F80ED"
              color="#FFF"
              fontSize="14px"
              lineHeight="17px"
              fontWeight="400"
              mr="auto"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
            {processStep === 1 && (
              <Flex
                alignItems="center"
                fontSize="14px"
                lineHeight="17px"
                color="#2F80ED"
                fontWeight="700"
                cursor="pointer"
                padding="10px"
                mr="20px"
                onClick={() => {
                  setProcessStep(0);
                }}
              >
                <IconBox
                  w="25px"
                  h="24px"
                  mr="10px"
                  icon={<IconArrowBack w="25px" h="24px" />}
                />
                Back
              </Flex>
            )}
            {processStep === 0 && (
              <Button
                height="fit-content"
                padding="15px 30px"
                borderRadius="100px"
                bgColor="#2F80ED"
                color="#FFF"
                fontSize="14px"
                lineHeight="17px"
                fontWeight="400"
                disabled={!selectedOrder}
                onClick={() => {
                  setProcessStep(1);
                }}
                _disabled={{
                  backgroundColor: "#E9E9E9 !important",
                }}
              >
                Next: Payment
              </Button>
            )}
            {processStep === 1 && (
              <Button
                height="fit-content"
                padding="15px 30px"
                borderRadius="100px"
                bgColor="#2F80ED"
                color="#FFF"
                fontSize="14px"
                lineHeight="17px"
                fontWeight="400"
                isLoading={isProcessingPayment}
                disabled={!selectedOrder || !cardInfo}
                _disabled={{
                  backgroundColor: "#E9E9E9 !important",
                }}
                onClick={() => onPayNow()}
              >
                {item?.id !== 3 && <>Pay Now (${item?.price.toFixed(2)})</>}
                {item?.id === 3 && (
                  <>Pay Now (${fetchExpeditedServicePrice(selectedOrder)})</>
                )}
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
