export enum ORDER_STEPS {
  SET_UPLOAD_DOCUMENTS_STEP,
  SET_TRANSITION_DETAILS_STEP,
  SET_PAYMENT_STEP,
  SET_THANKS_STEP,
}

export enum MOBILE_ORER_STEPS {
  SET_UPLOAD_DOCUMENTS_STEP,
  SET_LANGUAGE_SELECTTION_STEP,
  SET_TRANSLATION_TYPE_STEP,
  SET_TRANSLATION_SPEED_STEP,
  SET_TRANSLATION_PAYMENT_STEP,
  SET_THANKS_STEP,
}
