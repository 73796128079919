/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Skeleton,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import moment from "moment-timezone";

import {
  IconArrowDown,
  IconClose,
  IconDownloadAll,
  IconDownloadOrigin,
  IconDownloadReceipt,
} from "components/icons/Icons";
import Card from "components/card/Card";
import IconBox from "components/icons/IconBox";
import ProjectDetailTable from "./projectDetailTable";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getProjectDetailRequest,
  requestDownloadOriginFile,
  updateDocumentReqeust,
} from "store/project/reducer";
import {
  isFetchingDetails,
  selectedProjectSelector,
  unreadMessageSelector,
} from "store/project/selector";
import { userSelector } from "store/user/selector";
import {
  fetchUnreadMessagesCnt,
  getProjectStatusAsPercent,
  pad_with_zeroes,
} from "network/helper";
import { axiosService } from "network/axios";
import MessageBox from "components/messagebox/MessageBox";
import _, { isNull, uniqBy } from "lodash";
import UserInfoMenu from "components/menu/UserInfoMenu";
import ProjectDetailMobileTable from "./projectDetailMobileTable";
import { useWindowWidth } from "@react-hook/window-size";

export default function ProjectDetail(props: {
  selectedProjectId: any | null;
  onCloseModal: Function;
  onRefresh: Function;
}) {
  const documentStatus = [
    {
      id: 1,
      label: "New",
    },
    {
      id: 2,
      label: "In Progress",
    },
    {
      id: 3,
      label: "Ready for Review",
    },
    {
      id: 4,
      label: "Completed",
    },
    {
      id: 5,
      label: "In Revision",
    },
  ];
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const { selectedProjectId, onCloseModal, onRefresh } = props;
  const [hasChanged, setHasChanged] = useState(false);
  const [trackingDetails, setTrackingDetails] = useState(null);
  const windowWidth = useWindowWidth();

  const [projectMeta, setProjectMeta] = useState([
    {
      label: "Order Date",
      value: null,
    },
    {
      label: "Pages",
      value: null,
    },
    {
      label: "Words",
      value: null,
    },
    {
      label: "Type",
      value: null,
    },
    {
      label: "Notarized",
      value: null,
    },
    {
      label: "Price",
      value: null,
    },
  ]);

  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const isFetching = useSelector(isFetchingDetails);
  const selectedProject = useSelector(selectedProjectSelector);
  const unreadMessages = useSelector(unreadMessageSelector);
  const projectDetailRef = useRef();
  const [isApplyAll, setIsApplyAll] = React.useState(false);
  const [isUpdatingProjectStatus, setIsUpdatingProjectStatus] = useState(false);
  const [allDocumentStatus, setAllDocumentStatus] = React.useState({
    id: 1,
    label: "New",
  });
  useEffect(() => {
    if (selectedProjectId && dispatch) {
      dispatch(getProjectDetailRequest({ orderId: selectedProjectId }));
    }
  }, [dispatch, selectedProjectId]);

  const unreadMessageCnt = useMemo(() => {
    if (selectedProjectId && unreadMessages) {
      return fetchUnreadMessagesCnt(unreadMessages, Number(selectedProjectId));
    } else {
      return 0;
    }
  }, [unreadMessages, selectedProjectId]);

  useEffect(() => {
    if (selectedProjectId) {
      fetchTrackingNumber();
      setHasChanged(false);
    }
  }, [selectedProjectId]);

  const onClickUpdateDocumentsStatus = async () => {
    setIsUpdatingProjectStatus(true);
    try {
      const admin = user?.user_id;
      await axiosService.get(
        `/documents/all/${allDocumentStatus.id}/${selectedProjectId}/${admin}`
      );
      dispatch(getProjectDetailRequest({ orderId: selectedProjectId }));
    } catch (e) {
      console.log(e);
    } finally {
      setIsUpdatingProjectStatus(false);
    }
  };

  const fetchTrackingNumber = async () => {
    try {
      const resp: any = await axiosService.get(
        `/shipping/tracking/${selectedProjectId}`
      );
      const trackingLink = resp?.data?.tracking_link || null;
      const receiptLink = resp?.data?.receipt_link || null;
      const trackingNumber = resp?.data?.tracking_number || null;

      setTrackingDetails({
        trackingLink,
        receiptLink,
        trackingNumber,
      });
    } catch (err: any) {
      console.log(err);
    }
  };

  useEffect(() => {
    setProjectMeta([
      {
        label: "Order Date",
        value: moment(new Date(selectedProject?.order?.created_at)).format(
          "MM/DD/YY HH:mm"
        ),
      },
      {
        label: "Pages",
        value:
          selectedProject?.order?.translation_type === "standard"
            ? null
            : Number(selectedProject?.order?.page_count || 0),
      },
      {
        label: "Words",
        value:
          selectedProject?.order?.translation_type === "certified"
            ? null
            : Number(selectedProject?.order?.words_count || 0),
      },
      {
        label: "Type",
        value: selectedProject?.order?.translation_type || "Undefined",
      },
      {
        label: "Notarized",
        value:
          selectedProject?.order?.translation_type === "certified"
            ? selectedProject?.order?.include_notarization === "1"
              ? "Yes"
              : "No"
            : null,
      },
      {
        label: "Two-Day Shipping Delivery",
        value:
          selectedProject?.order?.translation_type === "certified"
            ? selectedProject?.order?.include_2_day_shipping === "1" ||
              selectedProject?.order?.include_mail_hard_copy === "1"
              ? "Yes"
              : "No"
            : null,
      },
      {
        label: "Expedite Translation",
        value:
          selectedProject?.order?.include_expedited_turnaround === "1"
            ? "Yes"
            : "No",
      },
      {
        label: "Price",
        value: `$${selectedProject?.order?.order_total || 0}`,
      },
      {
        label: "Address",
        value:
          selectedProject?.order?.translation_type === "certified"
            ? selectedProject?.order?.address_fields?.length > 0
              ? selectedProject?.order?.address_fields
              : null
            : null,
      },
    ]);
  }, [selectedProject]);

  const customer = useMemo(() => {
    if (selectedProject) {
      const { order } = selectedProject;
      if (order) {
        return {
          name: `${order.first_name} ${order.last_name}`,
          email: order.email,
        };
      }
    }

    return {
      name: "",
      email: "",
    };
  }, [selectedProject]);

  const projectManagers = useMemo(() => {
    if (selectedProject) {
      return uniqBy(selectedProject.projectManager, "user_id")?.map(
        (it: any) => {
          return `${it.first_name} ${it.last_name}`;
        }
      );
    }
    return [];
  }, [selectedProject]);

  const documents = useMemo(() => {
    if (selectedProject) {
      let results = selectedProject?.documents || [];
      const translated_from =
        selectedProject?.order?.translated_from || "English";
      const translated_to = selectedProject?.order?.translated_to || "English";
      return results.map((it: any) => {
        return {
          ...it,
          hasChanged: false,
          source_lang: translated_from,
          target_lang: translated_to,
          origin_status: it.document_status_id,
          file: null,
        };
      });
    } else {
      return [];
    }
  }, [selectedProject]);

  const onUpdateDocument = (e: any) => {
    const document_id = e?.document_id;
    const document_status_id = e?.document_status_id;
    const translator_id =
      e?.translators?.length > 0 ? e?.translators[0].user_id : null;
    const manager_id = e?.managers?.length > 0 ? e?.managers[0].user_id : null;
    const payload = {
      order_id: selectedProjectId,
      document_id,
      document_status_id,
      translator_id,
      new_file: e?.new_file || null,
      manager_id,
      user_id: user.user_id,
    };
    setHasChanged(true);
    dispatch(updateDocumentReqeust(payload));
  };

  const onDownloadFiles = (type: string) => {
    dispatch(
      requestDownloadOriginFile({
        order_id: selectedProjectId,
        user_role: "admin",
        type: type,
        file_name: `${selectedProject.order.order_name}-${type}-files.zip`,
      })
    );
  };
  const shadow = useColorModeValue(
    "0px 4px 50px rgba(0, 0, 0, 0.1)",
    "0px 4px 50px rgba(0, 0, 0, 0.1)"
  );

  const onClickViewNotifications = () => {
    if (projectDetailRef.current) {
      const elem: any = projectDetailRef.current;
      elem.scrollTo({
        top: 10000,
        behavior: "smooth",
      });
    }
  };

  return (
    <Box
      w={{
        base: "100%",
        xl: "calc(100vw - 230px)",
        baseWidth: "calc(100vw - (100vw - 1440px) / 2 - 230px)",
      }}
      ref={projectDetailRef}
      transition="transform 0.3s ease-in-out"
      transform={selectedProjectId ? `translateX(0)` : `translateX(100%)`}
      h="100vh"
      position="fixed"
      bgColor="#FFF"
      zIndex="200"
      boxShadow={selectedProjectId ? "0px 4px 40px rgba(0, 0, 0, 0.1)" : "none"}
      boxSizing="border-box"
      padding={{
        md: "50px 40px 60px",
        base: "70px 20px 30px",
        baseWidth: "50px calc((100vw - 1440px) / 2 + 40px) 50px 60px",
      }}
      overflow="auto"
      top="0px"
      right="0px"
    >
      <Flex
        minH="100%"
        h="fit-content"
        overflow={"hidden"}
        pr="20px"
        mr="-20px"
      >
        <IconButton
          onClick={(e) => {
            if (hasChanged) {
              onRefresh?.();
            }
            onCloseModal();
          }}
          aria-label="close"
          minW={"unset"}
          h={{ md: "52px", sm: "32px" }}
          w={{ md: "52px", sm: "32px" }}
          borderRadius="50%"
          bg="inherit"
          marginRight={{ md: "60px", sm: "0px", base: "0px" }}
          position={{ md: "unset", sm: "absolute", base: "absolute" }}
          right={{ md: "unset", sm: "30px", base: "30px" }}
          top={{ md: "unset", sm: "10px", base: "10px" }}
          _active={{
            bg: "inherit",
            transform: "none",
            borderColor: "transparent",
          }}
          _focus={{
            boxShadow: "none",
          }}
          icon={
            <IconClose
              h={{ md: "52px", sm: "32px" }}
              w={{ md: "52px", sm: "32px" }}
            />
          }
        />

        <Flex flex="1" direction="column" width="0">
          <Flex justifyContent="space-between">
            <Flex
              alignItems="center"
              flexWrap="wrap"
              columnGap={{ md: "60px", sm: "20px" }}
            >
              <Heading
                fontSize="25px"
                lineHeight="135%"
                fontWeight="400"
                color="#000"
              >
                {isFetching ? (
                  <Skeleton height="34px" width="100px"></Skeleton>
                ) : (
                  pad_with_zeroes(selectedProject?.order?.order_id, 6) ||
                  "undefined"
                )}
              </Heading>
              <Flex alignItems="center">
                <Text
                  fontSize="14px"
                  lineHeight="135%"
                  fontWeight="400"
                  color="#000"
                >
                  ID:
                </Text>
                {isFetching ? (
                  <Skeleton height="25px" width="70px" />
                ) : (
                  <Text color="#8E8C8C" marginLeft="10px">
                    {pad_with_zeroes(selectedProject?.order?.order_id, 6)}
                  </Text>
                )}
              </Flex>
            </Flex>
            <UserInfoMenu />
          </Flex>

          <SimpleGrid
            columns={{ base: 1, sm: 1, md: 2, xl: 2 }}
            gap="20px"
            marginTop="50px"
            marginBottom={{ md: "70px", base: "30px" }}
          >
            <Box>
              <Flex
                flexWrap="wrap"
                alignItems="flex-start"
                columnGap={{ md: "40px", sm: "15px" }}
                rowGap="20px"
              >
                <Card p="0" width="fit-content">
                  <Text
                    fontWeight="400"
                    fontSize="12px"
                    lineHeight="24px"
                    color="#8E8C8C"
                  >
                    Customer
                  </Text>
                  <Flex alignItems="center" marginTop="5px">
                    <Flex direction="column">
                      <Flex>
                        <Text
                          fontWeight="400"
                          fontSize="12px"
                          lineHeight="24px"
                          color="#8E8C8C"
                          ml="10px"
                        >
                          Name:
                        </Text>
                        <Text
                          fontWeight="400"
                          ml={1}
                          fontSize={{ md: "17px", sm: "14px" }}
                          lineHeight="135%"
                          color="#000"
                        >
                          {customer?.name}
                        </Text>
                      </Flex>
                      <Flex>
                        <Text
                          fontWeight="400"
                          fontSize="12px"
                          lineHeight="24px"
                          color="#8E8C8C"
                          ml="10px"
                        >
                          Email:
                        </Text>
                        <Text
                          fontWeight="400"
                          ml={1}
                          fontSize={{ md: "17px", sm: "14px" }}
                          lineHeight="135%"
                          color="#000"
                        >
                          {customer?.email}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </Card>
                <Card p="0" width="fit-content">
                  <Text
                    fontWeight="400"
                    fontSize="12px"
                    lineHeight="24px"
                    color="#8E8C8C"
                  >
                    Project Manager
                  </Text>
                  <Flex alignItems="center" marginTop="5px">
                    {projectManagers?.length > 0 ? (
                      <Text
                        fontWeight="400"
                        fontSize={{ md: "17px", sm: "14px" }}
                        lineHeight="135%"
                        color="#000"
                      >
                        {projectManagers?.join(", ")}
                      </Text>
                    ) : (
                      <Text
                        fontWeight="400"
                        fontSize={{ md: "17px", sm: "14px" }}
                        lineHeight="135%"
                        color="#000"
                      >
                        Not Assigned
                      </Text>
                    )}
                  </Flex>
                </Card>
                {unreadMessageCnt > 0 && (
                  <Flex
                    h="30px"
                    padding="0px 15px"
                    alignItems="center"
                    borderRadius="100px"
                    bgColor="#F02828"
                  >
                    <Text
                      fontSize="12px"
                      lineHeight="12px"
                      fontWeight="700"
                      color="#FFF"
                    >
                      {unreadMessageCnt} New Messages
                    </Text>
                    <Text
                      fontSize="12px"
                      lineHeight="12px"
                      fontWeight="400"
                      color="#FFF"
                      ml="25px"
                      cursor="pointer"
                      onClick={() => onClickViewNotifications()}
                      _hover={{
                        textDecoration: "underline",
                      }}
                    >
                      View
                    </Text>
                  </Flex>
                )}
              </Flex>
              <Flex
                marginTop={{ md: "36px", base: "20px" }}
                columnGap={{ md: "36px", base: "15px" }}
                wrap="wrap"
                rowGap="20px"
              >
                {projectMeta
                  ?.filter((it: any) => !isNull(it?.value))
                  ?.map((it: any, index: number) => (
                    <Card p="0" w="fit-content" key={index}>
                      <Text
                        fontWeight="400"
                        fontSize="12px"
                        lineHeight="24px"
                        color="#8E8C8C"
                      >
                        {it.label}
                      </Text>

                      {isFetching ? (
                        <Skeleton height="25px" width="70px"></Skeleton>
                      ) : (
                        <Text
                          marginTop="5px"
                          fontWeight="400"
                          fontSize={{ md: "17px", sm: "14px" }}
                          lineHeight="135%"
                          color="#000"
                          textTransform="capitalize"
                          whiteSpace={
                            it?.label === "Address" ? "pre" : "normal"
                          }
                        >
                          {it.value}
                        </Text>
                      )}
                    </Card>
                  ))}
              </Flex>
              {!_.isEmpty(selectedProject?.order?.project_description) && (
                <Box mt="30px">
                  <Text
                    fontWeight="400"
                    fontSize="12px"
                    lineHeight="24px"
                    color="#8E8C8C"
                  >
                    Notes
                  </Text>
                  <Text
                    marginTop="5px"
                    fontWeight="400"
                    fontSize="13px"
                    lineHeight="135%"
                    color="#000"
                    textTransform="capitalize"
                    padding="10px"
                    backgroundColor="#F1F1F1"
                    borderRadius="5px"
                  >
                    {selectedProject?.order?.project_description}
                  </Text>
                </Box>
              )}
              <Flex
                marginTop={{ md: "45px", base: "20px" }}
                columnGap={{ md: "36px", base: "15px" }}
                wrap="wrap"
                rowGap="20px"
              >
                <Card
                  p="0"
                  w="fit-content"
                  cursor="pointer"
                  onClick={() => onDownloadFiles("translation")}
                  color="#2F80ED"
                  _hover={{
                    textDecoration: "underline",
                  }}
                >
                  <IconBox
                    w="40px"
                    h="40px"
                    bg={boxBg}
                    icon={<IconDownloadAll w="24px" h="24px" />}
                  />
                  <Text
                    fontSize="14px"
                    lineHeight="18px"
                    color="#2F80ED"
                    fontWeight="500"
                    marginTop="10px"
                  >
                    Download <br />
                    All Translations
                  </Text>
                </Card>

                <Card
                  p="0"
                  w="fit-content"
                  cursor="pointer"
                  onClick={() => onDownloadFiles("origin")}
                  color="#2F80ED"
                  _hover={{
                    textDecoration: "underline",
                  }}
                >
                  <IconBox
                    w="40px"
                    h="40px"
                    bg={boxBg}
                    icon={<IconDownloadOrigin w="24px" h="24px" />}
                  />
                  <Text
                    fontSize="14px"
                    lineHeight="18px"
                    color="#2F80ED"
                    fontWeight="500"
                    marginTop="10px"
                  >
                    Download <br />
                    Original File(s)
                  </Text>
                </Card>
                {trackingDetails &&
                  trackingDetails?.receiptLink?.length > 0 && (
                    <>
                      {trackingDetails.receiptLink?.length === 1 && (
                        <Card
                          p="0"
                          w="fit-content"
                          color="#2F80ED"
                          cursor="pointer"
                          _hover={{
                            textDecoration: "underline",
                          }}
                        >
                          <a
                            href={trackingDetails.receiptLink[0].receipt_link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <IconBox
                              w="40px"
                              h="40px"
                              bg={boxBg}
                              icon={<IconDownloadReceipt w="24px" h="24px" />}
                            />
                            <Text
                              fontSize="14px"
                              lineHeight="18px"
                              color="#2F80ED"
                              fontWeight="500"
                              marginTop="10px"
                            >
                              Download <br />
                              Receipt
                            </Text>
                          </a>
                        </Card>
                      )}
                      {trackingDetails.receiptLink?.length > 1 && (
                        <Menu>
                          <MenuButton
                            padding={"0px"}
                            backgroundColor="transparent"
                            borderRadius="100px"
                          >
                            <Card
                              p="0"
                              w="fit-content"
                              color="#2F80ED"
                              cursor="pointer"
                              _hover={{
                                textDecoration: "underline",
                              }}
                            >
                              <IconBox
                                w="40px"
                                h="40px"
                                bg={boxBg}
                                icon={<IconDownloadReceipt w="24px" h="24px" />}
                              />
                              <Text
                                fontSize="14px"
                                lineHeight="18px"
                                color="#2F80ED"
                                fontWeight="500"
                                marginTop="10px"
                                textAlign="left"
                              >
                                Download <br />
                                Receipts
                              </Text>
                            </Card>
                          </MenuButton>
                          <MenuList
                            boxShadow={shadow}
                            p="0px"
                            mt="10px"
                            borderRadius="20px"
                            bg={"#FFF"}
                            border="none"
                          >
                            <Flex
                              flexDirection="column"
                              p="10px 20px"
                              fontSize={"14px"}
                              lineHeight="24px"
                              fontWeight={"400"}
                              color="black"
                            >
                              {trackingDetails.receiptLink.map(
                                (it: any, index: number) => (
                                  <MenuItem
                                    key={index}
                                    _hover={{ bg: "none" }}
                                    _focus={{ bg: "none" }}
                                    borderRadius="8px"
                                    padding="15px 0px"
                                    borderBottom={
                                      index <
                                      trackingDetails.receiptLink.length - 1
                                        ? "1px solid #F3F5F5"
                                        : ""
                                    }
                                  >
                                    <Text
                                      fontSize="sm"
                                      color="#2F80ED"
                                      _hover={{
                                        textDecoration: "underline",
                                      }}
                                    >
                                      <a
                                        href={it.receipt_link}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {it.detail?.includes(
                                          "Paid for Notarization Service"
                                        )
                                          ? "Receipt for Notarization Service"
                                          : it.detail?.includes(
                                              "Shipping Delivery"
                                            )
                                          ? "Receipt for Two-Days Shipping Delivery"
                                          : "Receipt for Expedite Translation"}
                                      </a>
                                    </Text>
                                  </MenuItem>
                                )
                              )}
                            </Flex>
                          </MenuList>
                        </Menu>
                      )}
                    </>
                  )}
              </Flex>
            </Box>
            <Flex>
              <Card
                p="20px 16px"
                width="260px"
                height="fit-content"
                marginLeft={{ md: "auto", sm: "0px", base: "0px" }}
                marginTop={{ md: "0px", base: "0px" }}
                background="#FFF"
                boxShadow={{
                  md: "0px 4px 50px rgba(0, 0, 0, 0.1)",
                  base: "none",
                }}
                border={{
                  md: "none",
                  base: "1px solid #E9E9E9",
                }}
                borderRadius={{ md: "20px", base: "0px" }}
              >
                <Flex>
                  <Box>
                    <CircularProgress
                      value={getProjectStatusAsPercent(documents)}
                      color="green.400"
                      size="110px"
                      sx={{
                        "svg > circle:first-of-type": {
                          stroke: "#2F80ED",
                        },
                      }}
                      animation={"ease-in"}
                    >
                      <Flex>
                        <CircularProgressLabel>
                          <Flex justifyContent="center">
                            <Text
                              fontSize="14px"
                              fontWeight="600"
                              color="#2F80ED"
                            >
                              {100 - getProjectStatusAsPercent(documents)}%
                            </Text>
                            <Text
                              fontSize="14px"
                              mx="5px"
                              fontStyle="italic"
                              fontWeight="500"
                            >
                              /
                            </Text>
                            <Text
                              fontSize="14px"
                              fontWeight="600"
                              color="green.400"
                            >
                              {getProjectStatusAsPercent(documents)}%
                            </Text>
                          </Flex>
                        </CircularProgressLabel>
                      </Flex>
                    </CircularProgress>
                  </Box>
                  <Flex flexDir="column" justifyContent="center" ml={2}>
                    <Flex alignItems="center">
                      <Box w="30px" h="10px" bgColor="#2F80ED"></Box>
                      <Text
                        padding="2px 4px"
                        fontSize="10px"
                        lineHeight="135%"
                        fontWeight="400"
                        color="#8E8C8C"
                      >
                        In Progress
                      </Text>
                    </Flex>
                    <Flex alignItems="center">
                      <Box w="30px" h="10px" bgColor="green.400"></Box>
                      <Text
                        padding="2px 4px"
                        fontSize="10px"
                        lineHeight="135%"
                        fontWeight="400"
                        color="#8E8C8C"
                      >
                        Completed
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Card>
            </Flex>
          </SimpleGrid>
          <Box p={3}>
            <Flex alignItems="center" columnGap="10px" flexWrap="wrap" rowGap="20px">
              <Checkbox
                mr="5"
                sx={{
                  ".chakra-checkbox__control": {
                    borderColor: "#2F80ED",
                  },
                }}
                onChange={(e: any) => setIsApplyAll(e.target.checked)}
              >
                Update all documents' status
              </Checkbox>
              {isApplyAll && (
                <Flex flex="1">
                  <Menu>
                    <MenuButton
                      padding={"10px 10px 10px 15px"}
                      backgroundColor="#F3F5F5"
                      borderRadius="10px"
                    >
                      <Flex alignItems={"center"}>
                        <Text
                          fontSize={"14px"}
                          lineHeight="24px"
                          fontWeight={"400"}
                          color="black"
                        >
                          {allDocumentStatus.label}
                        </Text>
                        <IconBox
                          marginLeft="10px"
                          icon={<IconArrowDown width="24px" height="24px" />}
                        ></IconBox>
                      </Flex>
                    </MenuButton>
                    <MenuList
                      boxShadow={shadow}
                      p="0px"
                      mt="10px"
                      borderRadius="20px"
                      bg={"#FFF"}
                      border="none"
                    >
                      <Flex
                        flexDirection="column"
                        p="10px 20px"
                        fontSize={"14px"}
                        lineHeight="24px"
                        fontWeight={"400"}
                        color="black"
                      >
                        {documentStatus.map((option, index: number) => (
                          <MenuItem
                            key={index}
                            _hover={{ bg: "none" }}
                            _focus={{ bg: "none" }}
                            borderRadius="8px"
                            padding="15px 0px"
                            onClick={() => {
                              setAllDocumentStatus(option);
                            }}
                            borderBottom="1px solid #F3F5F5"
                          >
                            <Text fontSize="sm">{option.label}</Text>
                          </MenuItem>
                        ))}
                      </Flex>
                    </MenuList>
                  </Menu>
                  <Button
                    padding="10px 20px"
                    border={"2px solid #2F80FD"}
                    borderRadius="100px"
                    fontSize="14px"
                    lineHeight="24px"
                    fontWeight="400"
                    color="#2F80FD"
                    bg="#FFF"
                    ml="auto"
                    disabled={isUpdatingProjectStatus}
                    isLoading={isUpdatingProjectStatus}
                    onClick={() => {
                      onClickUpdateDocumentsStatus();
                    }}
                  >
                    Save Changes
                  </Button>
                </Flex>
              )}
            </Flex>
          </Box>
          <Box position="relative">
            {windowWidth >= 768 ? (
              <ProjectDetailTable
                isFetching={isFetching}
                tableData={documents}
                onUpdateDocument={onUpdateDocument}
              />
            ) : (
              <ProjectDetailMobileTable
                isFetching={isFetching}
                tableData={documents}
                onUpdateDocument={onUpdateDocument}
              />
            )}
          </Box>
          <MessageBox projectId={selectedProjectId} />
        </Flex>
      </Flex>
    </Box>
  );
}
