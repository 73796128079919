import { Box, Flex, Input } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { uploadProjectSelector } from "store/customerProject/selector";
import { updateProjectStatus } from "store/customerProject/reducer";

export default function TranslationAddressOption(props: { [x: string]: any }) {
  const { ...rest } = props;
  const dispatch = useDispatch();

  const uploadProject = useSelector(uploadProjectSelector);

  const onChangeAddress = (address: string) => {
    dispatch(
      updateProjectStatus({
        address: address,
      })
    );
  };

  return (
    <Flex
      position="relative"
      alignItems="center"
      borderRadius="50px"
      flexDirection="column"
      cursor="pointer"
      transition="all .3s"
      _hover={{
        borderColor: "#2F80EDAA",
      }}
      {...rest}
    >
      {/* Address Input */}
      <Box w="100%" position="relative">
        <Input
          type="text"
          name="address"
          value={uploadProject.address}
          padding="15px 20px !important"
          fontSize="14px !important"
          lineHeight="normal !important"
          color="#000 !important"
          fontWeight="400 !important"
          h="50px !important"
          borderRadius="50px !important"
          border="1px solid #E9E9E9"
          backgroundColor="#FFF"
          placeholder="Address"
          onChange={(e) => {
            onChangeAddress(e.target.value);
          }}
          _placeholder={{
            fontSize: "14px",
            lineHeight: "normal",
            color: "#000",
            fontWeight: "400",
            textTransform: "capitalize",
          }}
        />
      </Box>
      {/* End Address Input */}
    </Flex>
  );
}
