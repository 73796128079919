/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex, Heading, Link, Spinner } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import DefaultAuth from "layouts/auth/Default";
import { axiosService } from "network/axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setAlertMessageRequest } from "store/ui/reducer";

export default function Verify() {
  const bgShadow = useColorModeValue(
    "0px 3.60347px 45.0434px rgba(0, 0, 0, 0.1)",
    "unset"
  );
  const textColor = useColorModeValue("navy.700", "white");
  const [isVerify, setIsverifying] = useState(false);
  const [verificationCode, setVerificationCode] = useState(0);
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const user_id = queryParameters.get("user_id");
  useEffect(() => {
    onCallVerification(Number(user_id));
  }, [user_id]);

  const onCallVerification = async (user_id: Number) => {
    setIsverifying(true);
    setVerificationCode(0);
    try {
      await axiosService.get(`/user/verify/${user_id}`);
      setVerificationCode(1);
    } catch (err: any) {
      dispatch(
        setAlertMessageRequest({
          type: "error",
          message: err.data.messages.error,
        })
      );
      setVerificationCode(-1);
    } finally {
      setIsverifying(false);
    }
  };

  return (
    <DefaultAuth>
      <Flex
        maxW="520px"
        w="100%"
        mx="auto"
        my="auto"
        pt="40px"
        pb="10px"
        px="20px"
        h="fit-content"
        alignItems="start"
        justifyContent="center"
        flexDirection="column"
        borderRadius="18px"
        bgColor={"#FFF"}
        boxShadow={bgShadow}
      >
        <Heading
          w="100%"
          color={textColor}
          fontFamily="Canela Text"
          fontSize="24px"
          lineHeight="135%"
          mb={"30px"}
          textAlign="center"
        >
          Verficiation Email Address
        </Heading>
        <Box mb="20px" w="full">
          {isVerify && (
            <Flex justifyContent="center" w="full" mb="20px">
              <Spinner></Spinner>
            </Flex>
          )}
          {verificationCode === -1 && (
            <Box
              w="full"
              textAlign="center"
              sx={{
                a: {
                  color: "blue.500",
                },
              }}
            >
              Failed to verify your account, please contact to{" "}
              <a href="https://www.languex.co/contact-us/">support team</a>
            </Box>
          )}
          {verificationCode === 1 && (
            <Box
              w="full"
              textAlign="center"
              sx={{
                a: {
                  color: "blue.500",
                },
              }}
            >
              You arround has been verified successfully.{" "}
              <Link href="/auth/sign-in/">Login in Dashboard</Link>
            </Box>
          )}
        </Box>
      </Flex>
    </DefaultAuth>
  );
}