import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { saveAs } from "file-saver";
import { apiService } from "network/api";
import { takeLatest, call, put } from "redux-saga/effects";
import { updateProjectRequestSuccess } from "store/customerProject/reducer";

import {
  changeReadStatusMessageRequest,
  changeReadStatusMessageRequestSuccesss,
  getManagerProjectDetailsRequest,
  getManagerProjectDetailsRequestSuccess,
  getManagerProjects,
  getManagerProjectsSuccess,
  getManagersRequest,
  getManagersRequestSuccess,
  getMessagesRequest,
  getMessagesRequestFailed,
  getMessagesRequestSuccess,
  getProjectDetailRequest,
  getProjectDetailResponse,
  getProjectsRequest,
  getProjectsSuccess,
  getReceiversRequest,
  getReceiversRequestFailed,
  getReceiversRequestSuccess,
  getTranslatorProjectDetailRequest,
  getTranslatorProjectDetailRequestSuccess,
  getTranslatorProjects,
  getTranslatorProjectsFailed,
  getTranslatorProjectsSuccess,
  getUnreadMessagesRequest,
  getUnreadMessagesRequestFailed,
  getUnreadMessagesRequestSuccess,
  requestDownloadOriginFile,
  requestDownloadOriginFileFailed,
  requestDownloadOriginFileSuccess,
  sendMessageRequest,
  sendMessageRequestFailed,
  sendMessageRequestSuccess,
  updateDocumentReqeust,
  updateDocumentRequestSuccess,
} from "./reducer";

function* getProjects({ payload }: PayloadAction<string>) {
  try {
    const res: AxiosResponse<any> = yield call(apiService.fetchProjects);
    yield put(getProjectsSuccess(res.data));
  } catch (err: any) {
    const message = err?.data ? err?.data?.message : "" || err?.message;
    console.log(message);
  }
}

function* getProjectDetailByOrderId({ payload }: PayloadAction<any>) {
  try {
    const { orderId } = payload;
    const res: AxiosResponse<any> = yield call(
      apiService.getProjectDetailByOrderId,
      orderId
    );
    yield put(getProjectDetailResponse(res.data));
  } catch (err: any) {
    const message = err?.data?.message || err.message;
    console.log(message);
  }
}

function* syncUpdatedProjectDetail({ payload }: PayloadAction<any>) {
  const { orderDetails } = payload;
  yield put(getProjectDetailResponse(orderDetails));
}

function* updateDocument({ payload }: PayloadAction<any>) {
  try {
    const {
      document_id,
      order_id,
      new_file,
      document_status_id,
      user_id,
      is_manager,
      is_customer,
      is_translator,
    } = payload;
    if (new_file) {
      const formData = new FormData();
      formData.append(`file`, new_file);
      formData.append("document_status_id", document_status_id);
      yield call(
        apiService.uploadVersionDocument,
        user_id,
        document_id,
        order_id,
        formData
      );
    }

    const res: AxiosResponse<any> = yield call(
      apiService.updateDocumentStatus,
      document_id,
      user_id,
      order_id,
      payload
    );
    if (is_customer) {
      yield put(getProjectDetailRequest({ orderId: order_id }));
    } else {
      if (!is_manager) {
        if (!is_translator) {
          yield put(
            updateDocumentRequestSuccess({ ...res.data, orderId: order_id })
          );
        } else {
          yield put(updateDocumentRequestSuccess(null));
          yield put(
            getTranslatorProjectDetailRequest({
              order_id: order_id,
              user_id: user_id,
            })
          );
        }
      } else {
        yield put(updateDocumentRequestSuccess(null));
        yield put(
          getManagerProjectDetailsRequest({
            order_id: order_id,
            user_id: user_id,
          })
        );
      }
    }

    yield put(
      getReceiversRequest({
        order_id: order_id,
        user_id: user_id,
      })
    );
  } catch (err: any) {
    const message = err?.data?.message || err.message;
    console.log(message);
  }
}

function* getTranslatorProjets({ payload }: PayloadAction<any>) {
  try {
    const { user_id } = payload;
    const res: AxiosResponse<any> = yield call(
      apiService.fetchTranslatorProjects,
      user_id
    );
    yield put(getTranslatorProjectsSuccess(res.data));
  } catch (err: any) {
    const message = err?.data?.message || err.message;
    console.log(message);
    yield put(getTranslatorProjectsFailed());
  }
}

function* getTranslatorProjectDetail({ payload }: PayloadAction<any>) {
  try {
    const { order_id, user_id } = payload;
    const res: AxiosResponse<any> = yield call(
      apiService.getTranslatorProjectDetail,
      Number(order_id),
      Number(user_id)
    );
    yield put(getTranslatorProjectDetailRequestSuccess(res.data));
  } catch (err: any) {
    const message = err?.data?.message || err.message;
    console.log(message);
  }
}

function* fetchManagerProjects({ payload }: PayloadAction<any>) {
  try {
    const { user_id } = payload;
    const res: AxiosResponse<any> = yield call(
      apiService.fetchManagerProjects,
      Number(user_id)
    );

    yield put(getManagerProjectsSuccess(res.data));
  } catch (err: any) {
    const message = err?.data?.message || err.message;
    console.log(message);
  }
}

function* getManagerProjectDetail({ payload }: PayloadAction<any>) {
  try {
    const { order_id, user_id } = payload;
    const res: AxiosResponse<any> = yield call(
      apiService.getManagerProjectDetail,
      Number(order_id),
      Number(user_id)
    );
    yield put(getManagerProjectDetailsRequestSuccess(res.data));
  } catch (err: any) {
    const message = err?.data?.message || err.message;
    console.log(message);
  }
}

function* downloadOriginFiles({ payload }: PayloadAction<any>) {
  try {
    const { order_id, user_role, type, file_name, user_id } = payload;
    const res: AxiosResponse<any> = yield call(
      apiService.getDownloadFileLinks,
      Number(order_id),
      user_role,
      user_id ? Number(user_id) : -1,
      type
    );

    saveAs(new Blob([res.data]), file_name);

    yield put(requestDownloadOriginFileSuccess());
  } catch (err: any) {
    const decodedString = String.fromCharCode.apply(
      null,
      new Uint8Array(err.data) as any
    );
    console.log(decodedString);
    const errorObj = JSON.parse(decodedString) ?? err?.response;

    const message = errorObj?.messages?.error || null;
    yield put(
      requestDownloadOriginFileFailed({
        type: "error",
        message:
          message ||
          "Failed to download files. please contact to support team.",
      })
    );
  }
}

function* fetchRecivers({ payload }: PayloadAction<any>) {
  try {
    const { user_id, order_id } = payload;
    const res: AxiosResponse<any> = yield call(
      apiService.getReceivers,
      Number(user_id),
      Number(order_id)
    );
    yield put(getReceiversRequestSuccess(res?.data?.receivers || []));
  } catch (err: any) {
    yield put(
      getReceiversRequestFailed({
        type: "error",
        message: err?.data?.messages?.error || "Failed to fetch receivers",
      })
    );
  }
}

function* fetchMessages({ payload }: PayloadAction<any>) {
  try {
    const { sender_id, receiver_id, order_id } = payload;
    const res: AxiosResponse<any> = yield call(
      apiService.getMessages,
      Number(sender_id),
      Number(receiver_id),
      Number(order_id)
    );

    yield put(getMessagesRequestSuccess(res?.data));
  } catch (err: any) {
    yield put(
      getMessagesRequestFailed({
        type: "error",
        message: err?.data?.messages?.error || "Failed to fetch messages",
      })
    );
  }
}

function* sendMessage({ payload }: PayloadAction<any>) {
  try {
    const { sender_id, receiver_id, order_id, message } = payload;
    const res: AxiosResponse<any> = yield call(
      apiService.sendMessage,
      Number(sender_id),
      Number(receiver_id),
      Number(order_id),
      message
    );
    yield put(
      sendMessageRequestSuccess({
        sender_id: sender_id,
        receiver_id: receiver_id,
        order_id: order_id,
        type: "success",
        message: "Sent successfully",
      })
    );
  } catch (err: any) {
    yield put(
      sendMessageRequestFailed({
        type: "error",
        message: err?.data?.messages?.error || "Failed to send message",
      })
    );
  }
}

function* getUnreadMessages({ payload }: PayloadAction<any>) {
  try {
    const { user_id } = payload;
    const res: AxiosResponse<any> = yield call(
      apiService.fetchUnreadMessages,
      Number(user_id)
    );

    yield put(
      getUnreadMessagesRequestSuccess({
        drafts: res?.data?.drafts || 0,
        unread: res?.data?.unread || {},
        notificationDetails: res?.data?.notifications || [],
      })
    );
  } catch (err: any) {
    yield put(getUnreadMessagesRequestFailed({}));
    console.log(err);
  }
}

function* readMessage({ payload }: PayloadAction<any>) {
  try {
    const { message_id, user_id } = payload;
    const res: AxiosResponse<any> = yield call(
      apiService.readMessage,
      Number(message_id)
    );

    yield put(
      changeReadStatusMessageRequestSuccesss({
        user_id: user_id,
      })
    );
  } catch (err: any) {
    console.log(err);
  }
}

function* getManagers() {
  try {
    const res: AxiosResponse<any> = yield call(apiService.getManagers);
    console.log(res.data.managers);
    yield put(
      getManagersRequestSuccess({
        managers: res?.data?.managers ?? [],
      })
    );
  } catch (err: any) {
    console.log(err);
  }
}

export function* projectsEffects() {
  yield takeLatest(getProjectsRequest.type, getProjects);
  yield takeLatest(getProjectDetailRequest.type, getProjectDetailByOrderId);
  yield takeLatest(updateProjectRequestSuccess.type, syncUpdatedProjectDetail);
  yield takeLatest(updateDocumentReqeust.type, updateDocument);
  yield takeLatest(
    updateDocumentRequestSuccess.type,
    getProjectDetailByOrderId
  );

  yield takeLatest(getTranslatorProjects.type, getTranslatorProjets);
  yield takeLatest(
    getTranslatorProjectDetailRequest.type,
    getTranslatorProjectDetail
  );

  yield takeLatest(getManagerProjects.type, fetchManagerProjects);
  yield takeLatest(
    getManagerProjectDetailsRequest.type,
    getManagerProjectDetail
  );

  yield takeLatest(requestDownloadOriginFile.type, downloadOriginFiles);

  yield takeLatest(getReceiversRequest.type, fetchRecivers);
  yield takeLatest(getMessagesRequest.type, fetchMessages);
  yield takeLatest(sendMessageRequest.type, sendMessage);

  yield takeLatest(sendMessageRequestSuccess.type, fetchMessages);
  yield takeLatest(getUnreadMessagesRequest.type, getUnreadMessages);

  yield takeLatest(changeReadStatusMessageRequest.type, readMessage);
  yield takeLatest(
    changeReadStatusMessageRequestSuccesss.type,
    getUnreadMessages
  );

  yield takeLatest(getManagersRequest.type, getManagers);
}

const projectsSaga = [call(projectsEffects)];

export default projectsSaga;
