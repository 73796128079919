import { Button, Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";

export function TabWidgetNewUser(props: {
  onChangeRole: Function;
  [x: string]: any;
}) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  useEffect(() => {
    onChangeRole(selectedIndex);
  }, [selectedIndex]);
  const { onChangeRole, ...rest } = props;
  const tabItems = ["Admin", "Project Manager", "Translator"];
  return (
    <Flex
      w="full"
      padding="8px"
      bgColor={"#F3F5F5"}
      h="60px"
      border="1px solid #E9E9E9"
      borderRadius="50px"
      {...rest}
    >
      {tabItems.map((item, index: number) => (
        <Button
          key={index}
          w="full"
          h="full"
          fontSize="14px"
          lineHeight="17px"
          fontWeight="400"
          bgColor={selectedIndex === index ? "blue.500" : "transparent"}
          color={selectedIndex === index ? "white" : "black"}
          borderRadius="50px"
          _hover={{
            bgColor: selectedIndex === index ? "blue.500" : "transparent",
            color: selectedIndex === index ? "white" : "inherit !important",
          }}
          onClick={(e) => setSelectedIndex(index)}
        >
          {item}
        </Button>
      ))}
    </Flex>
  );
}
