/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex } from "@chakra-ui/react";
import { isEmpty } from "lodash";
import { axiosService } from "network/axios";
import { fetchPerPagePrice } from "network/helper";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  clearProjectRequest,
  updateProjectStatus,
} from "store/customerProject/reducer";
import { uploadProjectSelector } from "store/customerProject/selector";
import { getProjectDetailRequest } from "store/project/reducer";
import { selectedProjectSelector } from "store/project/selector";
import { userSelector } from "store/user/selector";
import { ORDER_STEPS } from "types/data-types";
import DataImporter from "../main/components/DataImporter";
import OrderDetails from "./components/OrderDetails";
import MobileOrderDetails from "./components/orders/MobileOrderDetails";
import TranslationPayment from "./components/TranslationPayment";
import TranslationTypeSelector from "./components/TranslationTypeSelector";

export default function OrderIndex() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { orderId } = useParams();
  const uploadProject = useSelector(uploadProjectSelector);
  const selectedProject = useSelector(selectedProjectSelector);
  const user = useSelector(userSelector);

  const hash = window.location.hash ?? null;

  const [orderStep, setOrderStep] = useState(
    ORDER_STEPS.SET_UPLOAD_DOCUMENTS_STEP
  );

  const orderSummary = useMemo(() => {
    let totalPrice = 0;
    const targetLanguage = uploadProject.targetLanguage;
    totalPrice =
      uploadProject.transitionType === "certified"
        ? uploadProject.totalPage * fetchPerPagePrice(targetLanguage)
        : uploadProject.totalWords * 0.1;

    if (
      uploadProject.transitionType === "standard" &&
      uploadProject.totalWords < 250
    ) {
      totalPrice = 20;
    }

    if (
      orderStep === ORDER_STEPS.SET_TRANSITION_DETAILS_STEP ||
      orderStep === ORDER_STEPS.SET_PAYMENT_STEP
    ) {
      if (uploadProject.isNotarized) {
        totalPrice += 19.95;
      }

      if (uploadProject.isMailHardCopy) {
        totalPrice += 15;
      }

      if (uploadProject.transitionSpeed === "express") {
        if (uploadProject.transitionType === "certified") {
          totalPrice += 10 * uploadProject.totalPage * targetLanguage.length;
        } else {
          if (uploadProject.totalWords < 250) {
            totalPrice += 10 * targetLanguage.length;
          } else {
            totalPrice +=
              0.004 * uploadProject.totalWords * targetLanguage.length;
          }
        }
      }
    }

    return {
      words: uploadProject.totalWords,
      pages: uploadProject.totalPage,
      totalPrice: totalPrice,
      langCnt: targetLanguage.length + 1,
    };
  }, [uploadProject, orderStep]);

  useEffect(() => {
    return () => {
      localStorage.removeItem("redeem");
    };
  }, []);

  useEffect(() => {
    if (!orderId) {
      localStorage.removeItem("uploadProject");
      localStorage.removeItem("orderStep");
      dispatch(clearProjectRequest());
      setOrderStep(ORDER_STEPS.SET_UPLOAD_DOCUMENTS_STEP);
    } else {
      const savedProject = JSON.parse(localStorage.getItem("uploadProject"));
      if (Number(savedProject?.orderId) !== Number(orderId)) {
        localStorage.removeItem("uploadProject");
        localStorage.removeItem("orderStep");
        dispatch(getProjectDetailRequest({ orderId: orderId }));
        setOrderStep(ORDER_STEPS.SET_UPLOAD_DOCUMENTS_STEP);
      }
    }
    if (hash === "#order-details") {
      setOrderStep(ORDER_STEPS.SET_TRANSITION_DETAILS_STEP);
    }
  }, [orderId]);

  useEffect(() => {
    if (
      uploadProject?.targetLanguage?.length > 0 ||
      uploadProject.totalPage > 0 ||
      uploadProject.totalWords > 0 ||
      uploadProject.transitionType === "standard" ||
      uploadProject.documents?.length > 0 ||
      uploadProject.projectDescription?.length > 0
    )
      localStorage.setItem(
        "uploadProject",
        JSON.stringify({
          orderId: orderId,
          ...uploadProject,
        })
      );
  }, [uploadProject]);

  useEffect(() => {
    if (localStorage.getItem("uploadProject")) {
      const savedProject = JSON.parse(localStorage.getItem("uploadProject"));
      dispatch(
        updateProjectStatus({
          ...savedProject,
        })
      );
      localStorage.removeItem("uploadProject");
    } else {
      dispatch(clearProjectRequest());
    }
    if (localStorage.getItem("orderStep")) {
      const savedOrderStep = Number(localStorage.getItem("orderStep"));
      setOrderStep(savedOrderStep);
    }

    localStorage.removeItem("orderStep");
  }, []);

  useEffect(() => {
    if (selectedProject && dispatch && orderId) {
      let documents = [];
      if (selectedProject.documents) {
        documents = selectedProject.documents
          ?.filter((it: any) => it.document_type_id === "1")
          .map((it: any) => ({
            id: it.document_id,
            file: {
              name: it?.origin_file?.document_original_file_name,
            },
            type: it?.document_file_type,
            fileUrl: it?.origin_file?.document_file_path,
            pages: it?.page_count ?? 0,
            words: it?.words_count ?? 4,
          }));
      }

      const sourceLanguage = (selectedProject?.order?.translated_from ?? "")
        ?.split(",")
        ?.filter((it: string) => !isEmpty(it));

      const projectDetail = {
        projectTitle: selectedProject?.order?.order_name ?? "",
        text: selectedProject?.order?.order_notes ?? "",
        sourceLanguage:
          sourceLanguage?.length > 0 ? sourceLanguage[0] : sourceLanguage,
        targetLanguage: (selectedProject?.order?.translated_to ?? "")
          ?.split(",")
          ?.filter((it: string) => !isEmpty(it)),
        transitionType: selectedProject?.order?.translation_type ?? "certified",
        transitionSpeed: "standard",
        // is2DayShipping: selectedProject?.order?.include_2_day_shipping === "1",
        isMailHardCopy:
          selectedProject?.order?.include_2_day_shipping === "1" ||
          selectedProject?.order?.include_mail_hard_copy === "1",
        isNotarized: selectedProject?.order?.include_notarization === "1",
        documents: documents,
      };

      dispatch(
        updateProjectStatus({
          ...projectDetail,
        })
      );
    }
  }, [selectedProject, dispatch, orderId]);

  useEffect(() => {
    if (orderStep === ORDER_STEPS.SET_UPLOAD_DOCUMENTS_STEP) {
      navigate("#upload-documents");
    } else if (orderStep === ORDER_STEPS.SET_TRANSITION_DETAILS_STEP) {
      navigate("#order-details");
    } else if (orderStep === ORDER_STEPS.SET_PAYMENT_STEP) {
      navigate("#payment");
    }

    if (orderStep > Number(localStorage.getItem("orderStep"))) {
      // Whenever Change OrderStep, Need to update the Draft Orders
      try {
        const payload = {
          ...uploadProject,
          order_id: orderId,
          document_cnt: uploadProject.documents?.length || 0,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          pageCount: uploadProject.totalPage,
          wordsCount: uploadProject.totalWords,
          price: orderSummary.totalPrice,
          orderStatus: 10,
        };
        axiosService.post(`/projects/${user.user_id}`, payload);
      } catch (err) {
        console.log(err);
      }
    }
    localStorage.setItem("orderStep", orderStep.toString());
  }, [orderStep]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [orderStep]);

  useEffect(() => {
    return () => {
      const unfinishedPayload = localStorage.getItem("unfinished_payload")
        ? JSON.parse(localStorage.getItem("unfinished_payload"))
        : null;
      if (unfinishedPayload) {
        console.log(localStorage.getItem("unfinished_payload"));
        // console.log("Unamount...", orderSummary.totalPrice);
        window._hsq = window._hsq || [];
        window._hsq.push([
          "trackCustomBehavioralEvent",
          {
            id: "Unfinished Users",
            name: "pe43773541_unfinished_users",
            properties: {
              unfinished_username: `${user.first_name} ${user.last_name}`,
              unfinished_emailaddress: `${user.email}`,
              unfinished_event_trigger_id: (Math.random() * 10000).toString(),
              unfinished_event_trigger_timestamp: new Date().getTime(),
              unfinished_order_amount: unfinishedPayload.price,
            },
          },
        ]);
      }
    };
  }, []);

  useEffect(() => {
    const payload = {
      price: orderSummary.totalPrice,
    };

    localStorage.setItem("unfinished_payload", JSON.stringify(payload));
  }, [orderSummary]);

  return (
    <Box
      pt={{ base: "60px", md: "80px", xl: "80px" }}
      display={{ md: "block", base: "flex" }}
      flexDirection="column"
      flex={1}
    >
      <>
        <Box display={{ md: "none", base: "block" }}>
          <MobileOrderDetails step={orderStep} />
        </Box>
        <Box
          height="100%"
          flex="1"
          display={{ md: "block", base: "flex" }}
          flexDirection="column"
        >
          <Box
            display={{ md: "block", base: "flex" }}
            flexDirection="column"
            flex={1}
          >
            <Flex
              columnGap={{ lg: "48px", base: "25px" }}
              alignItems="flex-start"
            >
              <Box position="relative" flex="1" zIndex="1">
                {orderStep === ORDER_STEPS.SET_UPLOAD_DOCUMENTS_STEP && (
                  <DataImporter onChangeOrderStep={setOrderStep} />
                )}
                {orderStep === ORDER_STEPS.SET_TRANSITION_DETAILS_STEP && (
                  <TranslationTypeSelector onChangeOrderStep={setOrderStep} />
                )}
                {orderStep === ORDER_STEPS.SET_PAYMENT_STEP && (
                  <TranslationPayment onChangeOrderStep={setOrderStep}/>
                )}
              </Box>
              <Box
                width={{ lg: "360px", base: "300px" }}
                display={{ md: "block", base: "none" }}
                position="relative"
                zIndex="1"
              >
                <OrderDetails step={orderStep} />
              </Box>
            </Flex>
          </Box>
        </Box>
      </>
    </Box>
  );
}
