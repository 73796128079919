/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Flex,
  // Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { axiosService } from "network/axios";
import moment from "moment-timezone";
import { IconHamburgur } from "components/icons/Icons";
import _ from "lodash";
import { useSelector } from "react-redux";
import { searchQuerySelector } from "store/ui/selector";
import CustomPaginations from "components/pagination/CustomPaginations";

export default function UnfinishedUsers() {
  const [isFetching, setIsFetching] = useState(true);
  // const [unfinishedUsers, setUnfinishedUsers] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [userDetails, setUserDetails] = useState(null);
  const [allInfo, setAllInfo] = useState([]);
  const searchQuery = useSelector(searchQuerySelector);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(20);
  const [pageLimit, setPageLimit] = useState(10);
  const [results, setResults] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  useEffect(() => {
    fetchUnfinishedUsers();
    return () => {
      console.log("Clear Project Request");
    };
  }, []);

  const fetchUnfinishedUsers = async () => {
    setIsFetching(true);
    try {
      const resp: any = await axiosService.get(
        "/shipping/fetch-unfinished-users"
      );
      setAllInfo(resp?.data?.result ?? []);
    } catch (err) {
    } finally {
      setIsFetching(false);
    }
  };

  const unfinishedUsers = useMemo(() => {
    if (allInfo?.length > 0) {
      const uniqEmails = _.uniqBy(allInfo, "email");
      const uniqDetails = uniqEmails
        ?.map((it) => {
          const orderList = allInfo?.filter((_it) => _it.email === it.email);
          let lastedOrder = _.maxBy(orderList, function (o) {
            return new Date(o.created_at).getTime();
          });
          if (it?.email === "admin@gmail.com") {
            console.log(lastedOrder);
            console.log(lastedOrder.created_at);
          }
          return {
            email: it?.email ?? "",
            user_name: it?.user_name ?? "",
            order_list: orderList ?? [],
            latest_submit_time:
              lastedOrder.created_at || lastedOrder?.updated_at || null,
          };
        })
        ?.filter(
          (it) =>
            it?.email
              ?.toLowerCase()
              .includes(searchQuery?.toLowerCase() ?? "") ||
            it?.user_name
              ?.toLowerCase()
              .includes(searchQuery?.toLowerCase() ?? "")
        );
      setTotalRows(uniqDetails.length);
      return uniqDetails;
    } else {
      return [];
    }
  }, [allInfo, searchQuery]);

  const onOpenDetails = (it: any) => {
    setUserDetails(it);
    onOpen();
  };

  useEffect(() => {
    if (pageLimit > 0 && currentPage > 0 && unfinishedUsers?.length > 0) {
      setResults(
        unfinishedUsers.slice(
          (currentPage - 1) * pageLimit,
          currentPage * pageLimit
        )
      );
    }
  }, [currentPage, unfinishedUsers, pageLimit]);

  useEffect(() => {
    setTotalPages(
      totalRows / pageLimit > Math.floor(totalRows / pageLimit)
        ? Math.floor(totalRows / pageLimit) + 1
        : Math.floor(totalRows / pageLimit)
    );
  }, [unfinishedUsers, pageLimit, totalRows]);

  return (
    <Box
      pt={{ base: "60px", md: "80px", xl: "80px" }}
      height="100%"
      flex="1"
      display={{ md: "block", base: "flex" }}
      flexDirection="column"
    >
      <Box my="20px">
        {isFetching && (
          <TableContainer>
            <Table variant="simple">
              <Thead position="sticky" top="0">
                <Tr>
                  <Th isNumeric width="20px" bgColor="#FFF">
                    No
                  </Th>
                  <Th bgColor="#FFF">Email</Th>
                  <Th bgColor="#FFF">UserName</Th>
                  <Th bgColor="#FFF">Order Total</Th>
                  <Th bgColor="#FFF">Order Date</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {_.range(5)?.map((it) => (
                  <Tr key={it}>
                    <Td>
                      <Skeleton height="20px" width="100%" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" width="100%" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" width="100%" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" width="100%" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" width="100%" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" width="100%" />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
        {!isFetching && unfinishedUsers?.length > 0 && (
          <TableContainer>
            <Table variant="simple">
              <Thead position="sticky" top="0">
                <Tr>
                  <Th isNumeric width="20px" bgColor="#FFF">
                    No
                  </Th>
                  <Th bgColor="#FFF">Email</Th>
                  <Th bgColor="#FFF">UserName</Th>
                  <Th bgColor="#FFF">Unfinished Orders</Th>
                  <Th bgColor="#FFF">Last Submit Date</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {results?.map((it: any, index: number) => (
                  <Tr key={index}>
                    <Td isNumeric>
                      {pageLimit * (currentPage - 1) + (index + 1)}
                    </Td>
                    <Td>{it?.email ?? "_"}</Td>
                    <Td>{it?.user_name ?? "_"}</Td>
                    <Td>{it?.order_list?.length}</Td>
                    <Td>
                      {it?.latest_submit_time
                        ? moment(new Date(it?.latest_submit_time)).format(
                            "MM/DD/YY HH:mm"
                          )
                        : "-"}
                    </Td>
                    <Td textAlign="center">
                      <IconButton
                        aria-label="removeFile"
                        bg="inherit"
                        borderRadius="50%"
                        w="23px"
                        minW="23px"
                        h="23px"
                        cursor="pointer"
                        onClick={() => onOpenDetails(it)}
                        _active={{
                          bg: "inherit",
                          transform: "none",
                          borderColor: "transparent",
                        }}
                        _focus={{
                          boxShadow: "none",
                        }}
                        icon={<IconHamburgur />}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
        {!isFetching && unfinishedUsers?.length < 1 && (
          <Box py="30px">
            <Text>There are no unfinished user information.</Text>
          </Box>
        )}
        {!isFetching && unfinishedUsers?.length >= 1 && (
          <CustomPaginations
            totalCount={unfinishedUsers?.length}
            totalPages={totalPages}
            currentPage={currentPage}
            perPage={pageLimit}
            onSetPageLimit={(e: number) => {
              setPageLimit(e);
              setCurrentPage(1);
            }}
            onChangePage={(e: number) => {
              setCurrentPage(e);
            }}
          />
        )}
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            fontSize="17px"
            lineHeight="24px"
            fontWeight="700"
            color="#000"
            textAlign="center"
          >
            Details
          </ModalHeader>
          <ModalBody pt={4}>
            {userDetails && (
              <TableContainer
                sx={{
                  overflow: "auto",
                  maxHeight: "60vh",
                }}
              >
                <Table
                  sx={{
                    th: {
                      padding: "10px",
                    },
                    td: {
                      padding: "10px",
                      "&.label": {
                        backgroundColor: "#F2F2F2",
                      },
                    },
                  }}
                >
                  <Thead>
                    <Th>No</Th>
                    <Th>Documents</Th>
                    <Th>From</Th>
                    <Th>To</Th>
                    <Th>Total Page</Th>
                    <Th>Translation Speed</Th>
                    <Th>Translation Type</Th>
                    <Th>2 Day Shipping</Th>
                    <Th>Mail Hard Copy?</Th>
                    <Th>Is Notarization?</Th>
                    <Th>Created Date</Th>
                  </Thead>
                  <Tbody>
                    {userDetails?.order_list?.map((it: any, index: number) => (
                      <Tr key={index}>
                        <Td>{index + 1}</Td>
                        <Td>{it.documents ?? 0}</Td>
                        <Td>{it.translated_from ?? "-"}</Td>
                        <Td>{it.translated_to ?? "-"}</Td>
                        <Td>{it.page_count ?? 0}</Td>
                        <Td>
                          {Number(it?.include_expedited_turnaround) === 1
                            ? "Express"
                            : "Regular"}
                        </Td>
                        <Td>{it?.translation_type}</Td>
                        <Td>
                          {Number(it?.include_2_day_shipping) === 1
                            ? "Yes"
                            : "No"}
                        </Td>
                        <Td>
                          {Number(it?.include_mail_hard_copy) === 1
                            ? "Yes"
                            : "No"}
                        </Td>
                        <Td>
                          {Number(it?.include_notarization) === 1
                            ? "Yes"
                            : "No"}
                        </Td>
                        <Td>
                          {it?.created_at
                            ? moment(new Date(it?.created_at)).format(
                                "MM/DD/YY HH:mm"
                              )
                            : "-"}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            )}
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="flex-end">
              <Button
                w="full"
                height="46px"
                padding="15px 35px"
                borderRadius="100px"
                bgColor="#2F80ED"
                color="#FFF"
                fontSize="14px"
                lineHeight="17px"
                fontWeight="bold"
                type="button"
                onClick={() => onClose()}
                _hover={{
                  backgroundColor: "#3311DB",
                }}
              >
                Close
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
