/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Heading,
  IconButton,
  SimpleGrid,
  Skeleton,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import moment from "moment-timezone";
import {
  IconClose,
  IconDownloadAll,
  IconDownloadOrigin,
  IconUPS,
} from "components/icons/Icons";
import Card from "components/card/Card";
import IconBox from "components/icons/IconBox";
import ProjectDetailTable from "./customerProjectDetailTable";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  getProjectDetailRequest,
  requestDownloadOriginFile,
} from "store/project/reducer";
import {
  isFetchingDetails,
  selectedProjectSelector,
  unreadMessageSelector,
} from "store/project/selector";
import { isUpdatingProjectSelector } from "store/customerProject/selector";
import { axiosService } from "network/axios";
import MessageBox from "../../../../components/messagebox/MessageBox";
import {
  fetchStatusName,
  fetchUnreadMessagesCnt,
  getProjectStatusAsPercent,
  pad_with_zeroes,
} from "network/helper";
import _, { isEmpty, isNull, uniqBy } from "lodash";
import ProjectAdditionalService from "./customerProjectAdditionalService";
import UserInfoMenu from "components/menu/UserInfoMenu";
import CustomerProjectDetailMobileTable from "./customerProjectDetailMobileTable";
import { useWindowWidth } from "@react-hook/window-size";
import { userSelector } from "store/user/selector";

export default function CustomerProjectDetail(props: {
  selectedProjectId: any | null;
  onCloseModal: Function;
  onRefresh?: Function;
}) {
  const dispatch = useDispatch();
  const [trackingDetails, setTrackingDetails] = useState(null);
  const { selectedProjectId, onCloseModal, onRefresh } = props;
  const selectedProject = useSelector(selectedProjectSelector);
  const isFetching = useSelector(isFetchingDetails);
  const isUpdating = useSelector(isUpdatingProjectSelector);
  const unreadMessages = useSelector(unreadMessageSelector);
  const projectDetailRef = useRef();
  const user = useSelector(userSelector);
  const windowWidth = useWindowWidth();
  const unreadMessageCnt = useMemo(() => {
    if (selectedProjectId && unreadMessages) {
      return fetchUnreadMessagesCnt(unreadMessages, Number(selectedProjectId));
    } else {
      return 0;
    }
  }, [unreadMessages, selectedProjectId]);

  const [projectMeta, setProjectMeta] = useState([
    {
      label: "Order Date",
      value: null,
    },
    {
      label: "Pages",
      value: null,
    },
    {
      label: "Words",
      value: null,
    },
    {
      label: "Type",
      value: null,
    },
    {
      label: "Notarized",
      value: null,
    },
    {
      label: "Price",
      value: null,
    },
  ]);

  useEffect(() => {
    if (selectedProjectId && dispatch) {
      dispatch(getProjectDetailRequest({ orderId: selectedProjectId }));
    }
  }, [dispatch, selectedProjectId]);

  useEffect(() => {
    setProjectMeta([
      {
        label: "Order Date",
        value: moment(new Date(selectedProject?.order?.created_at)).format(
          "MM/DD/YY"
        ),
      },
      {
        label: "Pages",
        value:
          selectedProject?.order?.translation_type === "standard"
            ? null
            : Number(selectedProject?.order?.page_count) || 0,
      },
      {
        label: "Words",
        value:
          selectedProject?.order?.translation_type === "certified"
            ? null
            : Number(selectedProject?.order?.words_count) || 0,
      },
      {
        label: "Type",
        value: selectedProject?.order?.translation_type || "Undefined",
      },
      {
        label: "Notarized",
        value:
          selectedProject?.order?.translation_type === "certified"
            ? selectedProject?.order?.include_notarization === "1"
              ? "Yes"
              : "No"
            : null,
      },
      {
        label: "Two-Day Shipping Delivery",
        value:
          selectedProject?.order?.translation_type === "certified"
            ? selectedProject?.order?.include_2_day_shipping === "1" ||
              selectedProject?.order?.include_mail_hard_copy === "1"
              ? "Yes"
              : "No"
            : null,
      },
      {
        label: "Expedite Translation",
        value:
          selectedProject?.order?.include_expedited_turnaround === "1"
            ? "Yes"
            : "No",
      },
      {
        label: "Price",
        value: `$${selectedProject?.order?.order_total || 0}`,
      },
      {
        label: "Address",
        value: !isEmpty(selectedProject?.order?.address_fields)
          ? selectedProject?.order?.address_fields
          : null,
      },
    ]);

    if (selectedProject) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "project_page_opened",
        project_id: `${pad_with_zeroes(selectedProjectId, 6)}`,
        project_status: fetchStatusName(
          Number(selectedProject?.order?.order_status_id)
        ),
        project_due_date: selectedProject?.order?.order_due_at
          ? moment(new Date(selectedProject?.order?.order_due_at)).format(
              "MM/DD/YY"
            )
          : "-",
        project_price: Number(selectedProject?.order?.order_total),
        user_id: user?.user_id,
      });
    }
  }, [selectedProject]);

  useEffect(() => {
    if (selectedProjectId) {
      fetchTrackingNumber();
    }
  }, [selectedProjectId]);

  const fetchTrackingNumber = async () => {
    try {
      const resp: any = await axiosService.get(
        `/shipping/tracking/${selectedProjectId}`
      );
      const trackingLink = resp?.data?.tracking_link || null;
      const receiptLink = resp?.data?.receipt_link || [];
      const trackingNumber = resp?.data?.tracking_number || null;

      setTrackingDetails({
        trackingLink,
        receiptLink,
        trackingNumber,
      });
    } catch (err: any) {
      console.log(err);
    }
  };

  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const additionalOptions = useMemo(() => {
    const options = [];
    if (selectedProject) {
      const targetLanguages = selectedProject?.order?.translated_to?.split(",")?.filter((it: string) => it?.trim()?.length > 1);
      const pageCount = Number(selectedProject?.order?.page_count ?? 0);
      if (selectedProject?.order?.include_expedited_turnaround !== "1") {
        options.push({
          id: 1,
          lable: "Expedite Translation",
          price: targetLanguages?.length * pageCount * 10,
        });
      }

      if (
        selectedProject?.order?.translation_type === "certified" &&
        selectedProject?.order?.include_notarization !== "1"
      ) {
        options.push({
          id: 3,
          lable: "Notarized",
          price: 19.95,
        });
      }

      if (
        selectedProject?.order?.translation_type === "certified" &&
        selectedProject?.order?.include_mail_hard_copy !== "1"
      ) {
        options.push({
          id: 4,
          lable: "2-Day Shipping",
          price: 15,
        });
      }
    }

    return options;
  }, [selectedProject]);

  const projectManagers = useMemo(() => {
    return (
      uniqBy(selectedProject?.projectManager, "user_id")?.map((it: any) => {
        return `${it.first_name} ${it.last_name}`;
      }) || []
    );
  }, [selectedProject]);

  const documents = useMemo(() => {
    if (selectedProject) {
      const { order, documents } = selectedProject;
      const translated_from = order?.translated_from || "English";
      const translated_to = order?.translated_to || "English";

      return documents?.map((it: any, index: number) => {
        return {
          language: null,
          source_lang: translated_from,
          target_lang: translated_to,
          ...it,
        };
      });
    } else {
      return [];
    }
  }, [selectedProject]);

  const onCollapseDetails = () => {
    if (window.location.hash) {
      window.history.replaceState(
        null,
        null,
        window.location.href.split("#")[0]
      );
    }

    let prev_status = localStorage.getItem("order_status")
      ? JSON.parse(localStorage.getItem("order_status"))
      : [];

    let index = prev_status?.findIndex(
      (it: any) => Number(it.order_id) === Number(selectedProjectId)
    );
    if (index > -1) {
      prev_status?.splice(index, 1);
      prev_status?.push({
        order_id: selectedProjectId,
        order_status_id: selectedProject?.order?.order_status_id,
      });

      localStorage.setItem("order_status", JSON.stringify(prev_status));
    }

    onCloseModal();
  };

  const onDownloadFiles = (type: string) => {
    dispatch(
      requestDownloadOriginFile({
        order_id: selectedProjectId,
        user_role: "customer",
        type: type,
        file_name: `${selectedProject.order.order_name}-${type}-files.zip`,
      })
    );
  };

  const onUpdateDocument = (e: any) => {};
  const onClickViewNotifications = () => {
    if (projectDetailRef.current) {
      const elem: any = projectDetailRef.current;
      elem.scrollTo({
        top: 10000,
        behavior: "smooth",
      });
    }
  };

  const isStatusChanged = (order_id: number, order_status_id: string): any => {
    if (order_id && order_status_id) {
      let prev_status = localStorage.getItem("order_status")
        ? JSON.parse(localStorage.getItem("order_status"))
        : [];

      let matchedItem = prev_status?.find(
        (it: any) => Number(it.order_id) === order_id
      );

      if (
        !matchedItem ||
        Number(matchedItem?.order_status_id) === Number(order_status_id)
      ) {
        return null;
      } else {
        return `Order status has been changed,`;
      }
    } else {
      return null;
    }
  };

  return (
    <Box
      ref={projectDetailRef}
      w={{
        base: "100%",
        xl: "calc(100vw - 230px)",
        baseWidth: "calc(100vw - (100vw - 1440px) / 2 - 230px)",
      }}
      transition="transform 0.3s ease-in-out"
      transform={selectedProjectId ? `translateX(0)` : `translateX(100%)`}
      h="100vh"
      position="fixed"
      bgColor="#FFF"
      zIndex="200"
      boxShadow={selectedProjectId ? "0px 4px 40px rgba(0, 0, 0, 0.1)" : "none"}
      boxSizing="border-box"
      padding={{
        md: "50px 40px 60px",
        base: "70px 20px 30px",
        baseWidth: "50px calc((100vw - 1440px) / 2 + 40px) 50px 60px",
      }}
      top="0px"
      right="0px"
      overflow="auto"
    >
      <Flex minH="100%" h="fit-content" overflow={"auto"} pr="20px" mr="-20px">
        <IconButton
          onClick={(e) => onCollapseDetails()}
          aria-label="close"
          minW={"unset"}
          h={{ md: "52px", sm: "50px" }}
          w={{ md: "52px", sm: "50px" }}
          borderRadius="50%"
          bg="inherit"
          marginRight={{ md: "60px", sm: "0px", base: "0px" }}
          position={{ md: "unset", sm: "absolute", base: "absolute" }}
          right={{ md: "unset", base: "15px" }}
          top={{ md: "unset", sm: "10px", base: "10px" }}
          _active={{
            bg: "inherit",
            transform: "none",
            borderColor: "transparent",
          }}
          _focus={{
            boxShadow: "none",
          }}
          icon={
            <IconClose
              h={{ md: "52px", sm: "32px" }}
              w={{ md: "52px", sm: "32px" }}
            />
          }
        />

        <Flex flex="1" direction="column" width="0">
          <Flex direction={{ md: "row" }} justifyContent="space-between">
            <Flex alignItems="center">
              <Flex
                flexWrap="wrap"
                columnGap={{ md: "60px", sm: "20px" }}
                rowGap="10px"
              >
                <Heading
                  fontFamily="Canela Text"
                  fontSize={{ md: "30px", base: "24px" }}
                  lineHeight="135%"
                  fontWeight="700"
                  color="#000"
                >
                  Project Details
                </Heading>
                <Flex alignItems="center">
                  <Text
                    fontSize="14px"
                    lineHeight="135%"
                    fontWeight="400"
                    color="#000"
                  >
                    ID:
                  </Text>

                  {!isFetching ? (
                    <Text color="#8E8C8C" marginLeft="10px">
                      {pad_with_zeroes(selectedProject?.order?.order_id, 6)}
                    </Text>
                  ) : (
                    <Skeleton
                      height="25px"
                      width="100%"
                      minW="70px"
                      marginLeft="10px"
                    ></Skeleton>
                  )}
                </Flex>
              </Flex>
            </Flex>
            <UserInfoMenu />
          </Flex>

          {selectedProjectId &&
            !_.isNull(
              isStatusChanged(
                Number(selectedProjectId),
                selectedProject?.order?.order_status_id
              )
            ) && (
              <Alert status="success" mt="20px">
                <AlertIcon color={"#222"} />
                <Box>
                  <AlertDescription color={"#222"} fontSize="14px">
                    Project status has been updated.
                  </AlertDescription>
                </Box>
              </Alert>
            )}

          <SimpleGrid
            columns={{ base: 1, sm: 1, md: 2, xl: 2 }}
            gap="20px"
            marginTop={{ md: "50px", base: "30px" }}
            marginBottom={{ md: "70px", base: "30px" }}
          >
            <Box>
              <Flex
                alignItems="flex-end"
                flexWrap="wrap"
                columnGap="20px"
                rowGap="10px"
              >
                <Card p="0" width="fit-content">
                  <Text
                    fontWeight="400"
                    fontSize="12px"
                    lineHeight="24px"
                    color="#8E8C8C"
                  >
                    Your Project Manager
                  </Text>
                  <Flex alignItems="center" marginTop="5px">
                    {!isFetching ? (
                      <Text
                        fontWeight="400"
                        fontSize={{ md: "17px", sm: "14px" }}
                        lineHeight="135%"
                        color="#000"
                      >
                        {projectManagers?.length > 0
                          ? projectManagers?.join(", ")
                          : "Not Assigned"}
                      </Text>
                    ) : (
                      <Skeleton height="25px" width="100%"></Skeleton>
                    )}
                  </Flex>
                </Card>
                {unreadMessageCnt > 0 && (
                  <Flex
                    h="30px"
                    padding="0px 15px"
                    alignItems="center"
                    borderRadius="100px"
                    bgColor="#F02828"
                    mb="-3px"
                    whiteSpace="nowrap"
                  >
                    <Text
                      fontSize="12px"
                      lineHeight="12px"
                      fontWeight="700"
                      color="#FFF"
                    >
                      {unreadMessageCnt} New Messages
                    </Text>
                    <Text
                      fontSize="12px"
                      lineHeight="12px"
                      fontWeight="400"
                      color="#FFF"
                      ml="25px"
                      cursor="pointer"
                      onClick={() => onClickViewNotifications()}
                      _hover={{
                        textDecoration: "underline",
                      }}
                    >
                      View
                    </Text>
                  </Flex>
                )}
              </Flex>
              <Flex marginTop="36px" columnGap="36px" wrap="wrap" rowGap="20px">
                {projectMeta
                  ?.filter((it: any) => {
                    return !isNull(it.value);
                  })
                  .map((it: any, index: number) => (
                    <Card p="0" w="fit-content" key={index}>
                      <Text
                        fontWeight="400"
                        fontSize="12px"
                        lineHeight="24px"
                        color="#8E8C8C"
                      >
                        {it.label}
                      </Text>

                      {isFetching ? (
                        <Skeleton height="25px" width="70px"></Skeleton>
                      ) : (
                        <Text
                          marginTop="5px"
                          fontWeight="400"
                          fontSize={{ md: "17px", sm: "14px" }}
                          lineHeight="135%"
                          color="#000"
                          textTransform="capitalize"
                          whiteSpace={it.label === "Address" ? "pre" : "normal"}
                        >
                          {it.value}
                        </Text>
                      )}
                    </Card>
                  ))}
              </Flex>
              {!_.isEmpty(selectedProject?.order?.project_description) && (
                <Box mt="30px">
                  <Text
                    fontWeight="400"
                    fontSize="12px"
                    lineHeight="24px"
                    color="#8E8C8C"
                  >
                    Notes
                  </Text>
                  <Text
                    marginTop="5px"
                    fontWeight="400"
                    fontSize="13px"
                    lineHeight="135%"
                    color="#000"
                    textTransform="capitalize"
                    padding="10px"
                    backgroundColor="#F1F1F1"
                    borderRadius="5px"
                  >
                    {selectedProject?.order?.project_description}
                  </Text>
                </Box>
              )}
              <Flex
                marginTop={{ md: "45px", sm: "20px" }}
                columnGap={{ md: "36px", sm: "15px" }}
                wrap="wrap"
                rowGap="20px"
              >
                <Card
                  p="0"
                  w="fit-content"
                  cursor="pointer"
                  onClick={() => onDownloadFiles("translation")}
                  color="#2F80ED"
                  _hover={{
                    textDecoration: "underline",
                  }}
                >
                  <IconBox
                    w="40px"
                    h="40px"
                    bg={boxBg}
                    icon={<IconDownloadAll w="24px" h="24px" />}
                  />
                  <Text
                    fontSize="14px"
                    lineHeight="18px"
                    color="#2F80ED"
                    fontWeight="500"
                    marginTop="10px"
                  >
                    Download <br />
                    All Translations
                  </Text>
                </Card>

                <Card
                  p="0"
                  w="fit-content"
                  cursor="pointer"
                  onClick={() => onDownloadFiles("origin")}
                  color="#2F80ED"
                  _hover={{
                    textDecoration: "underline",
                  }}
                >
                  <IconBox
                    w="40px"
                    h="40px"
                    bg={boxBg}
                    icon={<IconDownloadOrigin w="24px" h="24px" />}
                  />
                  <Text
                    fontSize="14px"
                    lineHeight="18px"
                    color="#2F80ED"
                    fontWeight="500"
                    marginTop="10px"
                  >
                    Download <br />
                    Original File(s)
                  </Text>
                </Card>

                {trackingDetails &&
                  trackingDetails?.receiptLink?.length > 0 && (
                    <>
                      {trackingDetails.trackingNumber?.length > 0 && (
                        <Card p="0" w="fit-content">
                          <a
                            href={trackingDetails.trackingLink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <IconBox
                              w="40px"
                              h="40px"
                              bg={boxBg}
                              icon={<IconUPS w="28px" h="32px" />}
                            />
                            <Text
                              fontSize="14px"
                              lineHeight="18px"
                              color="#2F80ED"
                              fontWeight="500"
                              marginTop="10px"
                            >
                              {trackingDetails.trackingNumber}
                            </Text>
                            <Text
                              fontSize="14px"
                              lineHeight="18px"
                              color="#8E8C8C"
                              fontWeight="500"
                            >
                              Tracking Number
                            </Text>
                          </a>
                        </Card>
                      )}
                    </>
                  )}
              </Flex>
            </Box>
            <Flex>
              <Card
                p="20px 16px"
                width="260px"
                height="fit-content"
                marginLeft={{ md: "auto", sm: "0px", base: "0px" }}
                marginTop={{ md: "0px", base: "0px" }}
                background="#FFF"
                boxShadow={{
                  md: "0px 4px 50px rgba(0, 0, 0, 0.1)",
                  base: "none",
                }}
                border={{
                  md: "none",
                  base: "1px solid #E9E9E9",
                }}
                borderRadius={{ md: "20px", base: "0px" }}
              >
                <Flex>
                  <Box>
                    <CircularProgress
                      value={getProjectStatusAsPercent(documents)}
                      color="green.400"
                      size="110px"
                      sx={{
                        "svg > circle:first-of-type": {
                          stroke: "#2F80ED",
                        },
                      }}
                      animation={"ease-in"}
                    >
                      <Flex>
                        <CircularProgressLabel>
                          <Flex justifyContent="center">
                            <Text
                              fontSize="14px"
                              fontWeight="600"
                              color="#2F80ED"
                            >
                              {100 - getProjectStatusAsPercent(documents)}%
                            </Text>
                            <Text
                              fontSize="14px"
                              mx="5px"
                              fontStyle="italic"
                              fontWeight="500"
                            >
                              /
                            </Text>
                            <Text
                              fontSize="14px"
                              fontWeight="600"
                              color="green.400"
                            >
                              {getProjectStatusAsPercent(documents)}%
                            </Text>
                          </Flex>
                        </CircularProgressLabel>
                      </Flex>
                    </CircularProgress>
                  </Box>
                  <Flex flexDir="column" justifyContent="center" ml={2}>
                    <Flex alignItems="center">
                      <Box w="30px" h="10px" bgColor="#2F80ED"></Box>
                      <Text
                        padding="2px 4px"
                        fontSize="10px"
                        lineHeight="135%"
                        fontWeight="400"
                        color="#8E8C8C"
                      >
                        In Progress
                      </Text>
                    </Flex>
                    <Flex alignItems="center">
                      <Box w="30px" h="10px" bgColor="green.400"></Box>
                      <Text
                        padding="2px 4px"
                        fontSize="10px"
                        lineHeight="135%"
                        fontWeight="400"
                        color="#8E8C8C"
                      >
                        Completed
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
                <ProjectAdditionalService
                  options={additionalOptions}
                  selectedProjectId={Number(selectedProjectId)}
                  onRefresh={onRefresh}
                />
              </Card>
            </Flex>
          </SimpleGrid>
          {windowWidth >= 768 ? (
            <ProjectDetailTable
              orderId={selectedProjectId}
              documents={documents}
              isFetching={isFetching}
              onUpdateDocument={onUpdateDocument}
            />
          ) : (
            <CustomerProjectDetailMobileTable
              orderId={selectedProjectId}
              documents={documents}
              isFetching={isFetching}
              onUpdateDocument={onUpdateDocument}
            />
          )}
          <MessageBox projectId={selectedProjectId} />
        </Flex>
      </Flex>

      {isUpdating && (
        <Box
          w="100%"
          h="100%"
          bgColor={"rgba(0, 0, 0, 0.1)"}
          position="absolute"
          top="0"
          left="0"
          zIndex="100"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner color="#2F80ED" width={"50px"} height="50px"></Spinner>
        </Box>
      )}
    </Box>
  );
}
