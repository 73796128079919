/* eslint-disable react-hooks/exhaustive-deps */
import { Box, SimpleGrid } from "@chakra-ui/react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  clearPaymentCache,
  clearProjectRequest,
} from "store/customerProject/reducer";
import { clearSaveCardDataRequest } from "store/ui/reducer";
import TranslationComplete from "./components/TranslationComplete";

export default function OrderCompleted() {
  let { orderId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearPaymentCache());
    dispatch(clearProjectRequest());
    dispatch(clearSaveCardDataRequest());
  }, []);

  return (
    <Box pt={{ base: "60px", md: "80px", xl: "80px" }}>
      <SimpleGrid columns={{ md: 2, base: 1 }} gap="40px" minH="750px">
        <TranslationComplete orderId={orderId} />
      </SimpleGrid>
    </Box>
  );
}
