import { ArrowRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
// Custom components
import Card from "components/card/Card";
import * as React from "react";
import { useSelector } from "react-redux";
import { isGettingAllUsersSelector } from "store/user/selector";
// Assets

type RowObj = {
  id: string;
  name: string;
  email: string;
  phone?: string;
  actionCol?: string;
};

const columnHelper = createColumnHelper<RowObj>();

// const columns = columnsDataCheck;
export default function AdminsMobileTable(props: {
  tableData: any;
  onSelectRow: Function;
}) {
  const { tableData, onSelectRow } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const isGettingAllUsers = useSelector(isGettingAllUsersSelector);

  let defaultData = tableData;
  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      header: () => (
        <Text
          textAlign="left"
          fontSize="14px"
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Name
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text
            fontSize={"14px"}
            lineHeight="24px"
            fontWeight={"400"}
            color="black"
          >
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("email", {
      id: "email",
      header: () => (
        <Text
          textAlign="left"
          fontSize="14px"
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Email
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            fontSize={"14px"}
            lineHeight="24px"
            fontWeight={"400"}
            color="black"
          >
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("phone", {
      id: "phone",
      header: () => (
        <Text
          textAlign="left"
          fontSize="14px"
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Phone
        </Text>
      ),
      cell: (info) => (
        <Text
          fontSize={"14px"}
          lineHeight="24px"
          fontWeight={"400"}
          color="black"
        >
          {info.getValue() ? info.getValue() : ""}
        </Text>
      ),
    }),
    columnHelper.accessor("actionCol", {
      id: "actionCol",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          color="gray.500"
          fontWeight="400"
        ></Text>
      ),
      cell: (info) => (
        <Flex align="center" ml="0">
          <Button
            borderRadius="0px"
            width="100%"
            padding="15px 35px"
            bgColor="#2F80ED"
            color="#FFF"
            fontSize="14px"
            lineHeight="17px"
            fontWeight="bold"
            onClick={(e) => {
              onSelectRow(info.row.original.id);
            }}
          >
            View Details
            <ArrowRightIcon ml="10px" fontSize="8px" />
          </Button>
        </Flex>
      ),
    }),
  ];
  const [data, setData] = React.useState(() => [...defaultData]);
  React.useEffect(() => {
    setData([...defaultData]);
  }, [defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Card
      flexDirection="column"
      p="0px"
      px="10px"
      w="calc(100% + 20px)"
      ml="-10px"
      overflowX="auto"
    >
      <Box>
        <Table variant="simple" color="gray.500" mb="24px">
          <Tbody>
            {!isGettingAllUsers &&
              table
                .getRowModel()
                .rows.slice(0, 11)
                .map((row: any, index: number) => {
                  return (
                    <Table
                      key={index}
                      mt={index === 0 ? "10px" : "35px"}
                      border="1px solid #dcdcdc"
                    >
                      <Tbody>
                        {row
                          .getVisibleCells()
                          .map((cell: any, _index: number) => {
                            return (
                              <Tr key={_index}>
                                {_index < 3 ? (
                                  <React.Fragment>
                                    <Td
                                      p="10px"
                                      width="110px"
                                      borderColor="#dcdcdc"
                                      borderRight="1px solid #dcdcdc"
                                      bgColor="#FAFAFA"
                                    >
                                      {flexRender(
                                        table.getHeaderGroups()[0].headers[
                                          _index
                                        ].column.columnDef.header,
                                        table
                                          .getHeaderGroups()[0]
                                          .headers[_index].getContext()
                                      )}
                                    </Td>
                                    <Td p="10px" position="relative">
                                      {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                      )}
                                    </Td>
                                  </React.Fragment>
                                ) : (
                                  <Td colSpan={2} padding="0px">
                                    {flexRender(
                                      cell.column.columnDef.cell,
                                      cell.getContext()
                                    )}
                                  </Td>
                                )}
                              </Tr>
                            );
                          })}
                      </Tbody>
                    </Table>
                  );
                })}
            {isGettingAllUsers &&
              [1, 2, 3, 4, 5].map((row) => {
                return (
                  <Table
                    key={row}
                    mt={row === 0 ? "10px" : "35px"}
                    border="1px solid #dcdcdc"
                  >
                    <Tbody>
                      {[1, 2, 3, 4, 5].map((_it) => (
                        <Tr key={_it}>
                          <Td
                            p="10px"
                            width="110px"
                            borderColor="#dcdcdc"
                            borderRight="1px solid #dcdcdc"
                            bgColor="#FAFAFA"
                          >
                            <Skeleton height="24px" width="100%" />
                          </Td>
                          <Td p="10px" borderColor="#dcdcdc">
                            <Skeleton height="24px" width="100%" />
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                );
              })}
          </Tbody>
        </Table>
      </Box>
    </Card>
  );
}
