import {
  Box,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useWindowWidth } from "@react-hook/window-size";
import CustomPaginations from "components/pagination/CustomPaginations";
import SortingHandler from "components/sortBox/SortingHandler";
import { isEmpty } from "lodash";
import { axiosService } from "network/axios";
import { generateQueryParameters } from "network/helper";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { searchQuerySelector } from "store/ui/selector";
import RevenueDashboard from "../dashboard/revenue";
import BusinessCustomerDetailsPopup from "./components/businessCustomerDetailsPopup";

import BusinessCustomersMobileTable from "./components/businessCustomersMobileTable";
import BusinessCustomersTable from "./components/businessCustomersTable";
import CustomerDetailsPopup from "./components/customerDetailsPopup";
import IndividualCustomersMobileTable from "./components/IndividualCustomersMobileTable";
import IndividualCustomersTable from "./components/IndividualCustomersTable";

export default function Customers() {
  const [currentPage, setCurrentPage] = useState(1);
  const [tabIndex, setTabIndex] = useState(0);
  const [totalPages, setTotalPages] = useState(20);
  const [pageLimit, setPageLimit] = useState(10);
  const [isFetching, setIsFetching] = useState(false);
  const [results, setResults] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const searchQuery = useSelector(searchQuerySelector);
  const [selectedOption, setSelectedOption] = useState<{
    key: string;
    label: string;
    dir: "desc" | "asc";
  }>({
    key: "first_name",
    label: "Name",
    dir: "desc",
  });
  const sortingOptions = [
    {
      key: "first_name",
      label: "Name",
    },
    {
      key: "email",
      label: "Email",
    },
    {
      key: "phone",
      label: "Phone",
    },
    {
      key: "active_projects",
      label: "Active Projects",
    },
    {
      key: "total",
      label: "Total Price",
    },
  ];
  const queryParam = useMemo(() => {
    return {
      type: tabIndex === 2 ? 1 : tabIndex,
      page: currentPage,
      limit: pageLimit,
      keyword: searchQuery ?? "",
      order_by: selectedOption?.key,
      dir: selectedOption?.dir,
    };
  }, [tabIndex, currentPage, pageLimit, searchQuery, selectedOption]);
  const windowWidth = useWindowWidth();
  const [customerId, setCustomerId] = useState(null);

  const customers = useMemo(() => {
    return results?.map((it: any) => {
      return {
        id: `${it.user_id}`,
        name: isEmpty(it.first_name)
          ? `${it?.last_name ?? ""}`
          : `${it.first_name ?? ""} ${it.last_name}`,
        email: it.email,
        phone: it.phone,
        cityState: "-",
        country: "",
        activeProjects: it.active_projects ?? 0,
        totalPrice: Number(it?.total ?? 0),
        actionCol: "",
      };
    });
  }, [results]);

  const fetchResults = useCallback(async () => {
    setIsFetching(true);
    const params = generateQueryParameters(queryParam);
    try {
      const resp: any = await axiosService.get(`/user/list?${params}`);
      const { results, total_rows } = resp?.data;
      setResults(
        results?.map((it: any) => ({
          ...it,
          id: it?.order_id,
        }))
      );

      setTotalPages(
        total_rows / pageLimit > Math.floor(total_rows / pageLimit)
          ? Math.floor(total_rows / pageLimit) + 1
          : Math.floor(total_rows / pageLimit)
      );
      setTotalRows(total_rows);
    } catch (err) {
      setResults([]);
    } finally {
      setIsFetching(false);
    }
  }, [queryParam]);

  useEffect(() => {
    fetchResults();
  }, [queryParam]);

  const businessCustomers = useMemo(() => {
    if (tabIndex === 2) {
      return results?.map((it: any) => {
        return {
          company_name: `${it?.company_name ?? "Undefined"}`,
          ein_number: `${it?.ein_number ?? "Undefined"}`,
          business_id: `${it.business_id}`,
          id: `${it.user_id}`,
          name: isEmpty(it.first_name)
            ? `${it?.last_name ?? ""}`
            : `${it.first_name ?? ""} ${it.last_name}`,
          email: it.email,
          phone: it.phone,
          cityState: "-",
          country: "",
          activeProjects: it.active_projects,
          totalPrice: Number(it?.total ?? 0),
          teamSize: Number(it?.team_size ?? 0),
          actionCol: "",
        };
      });
    } else {
      return [];
    }
  }, [tabIndex, results]);

  return (
    <Box pt={{ base: "60px", md: "80px", xl: "80px" }}>
      <Tabs
        variant="unstyled"
        onChange={(index) => {
          setCurrentPage(1);
          setTabIndex(index);
        }}
      >
        <TabList borderBottom={"2px solid #E9E9E9"}>
          <Tab
            padding={{ md: "14px 25px 14px 0px", sm: "10px 15px 10px 0px" }}
            fontSize={{ md: "17px", sm: "14px" }}
            lineHeight={{ md: "24px", sm: "22px" }}
            fontWeight="400"
            alignItems="center"
            _selected={{ color: "blue.500" }}
            _focus={{ boxShadow: "none" }}
          >
            Individuals
          </Tab>
          <Tab
            padding={{ md: "14px 25px 14px 0px", sm: "10px 15px 10px 0px" }}
            fontSize={{ md: "17px", sm: "14px" }}
            lineHeight={{ md: "24px", sm: "22px" }}
            fontWeight="400"
            alignItems="center"
            _selected={{ color: "blue.500" }}
            _focus={{ boxShadow: "none" }}
          >
            Individual Revenue Dashboard
          </Tab>
          <Tab
            padding={{ md: "14px 25px", sm: "10px" }}
            fontSize={{ md: "17px", sm: "14px" }}
            lineHeight={{ md: "24px", sm: "22px" }}
            fontWeight="400"
            alignItems="center"
            _selected={{ color: "blue.500" }}
            _focus={{ boxShadow: "none" }}
          >
            Businesses
          </Tab>
          <Tab
            padding={{ md: "14px 25px 14px 0px", sm: "10px 15px 10px 0px" }}
            fontSize={{ md: "17px", sm: "14px" }}
            lineHeight={{ md: "24px", sm: "22px" }}
            fontWeight="400"
            alignItems="center"
            _selected={{ color: "blue.500" }}
            _focus={{ boxShadow: "none" }}
          >
            Business Revenue Dashboard
          </Tab>
          <Box
            padding={{ md: "14px 0px", sm: "10px 0px" }}
            position="absolute"
            right="30px"
            display={{
              base: "none",
              md: "block",
            }}
            boxSizing="border-box"
            zIndex={90}
          >
            <SortingHandler
              selectedOption={selectedOption}
              sortingOptions={sortingOptions}
              onChangeSortingOption={(e: any) => {
                setSelectedOption(e);
              }}
            />
          </Box>
        </TabList>
        <TabIndicator mt="-2px" height="2px" bg="blue.500" borderRadius="1px" />
        <TabPanels>
          <TabPanel px="0">
            {windowWidth >= 768 ? (
              <IndividualCustomersTable
                tableData={customers}
                isFetching={isFetching}
                pageLimit={pageLimit}
                onSelectRow={(e: any) => {
                  setCustomerId(e);
                }}
              />
            ) : (
              <IndividualCustomersMobileTable tableData={customers} />
            )}
          </TabPanel>
          <TabPanel px="0">
            <RevenueDashboard dashboardType="individual" />
          </TabPanel>
          <TabPanel px="0">
            {windowWidth >= 768 ? (
              <BusinessCustomersTable
                tableData={businessCustomers}
                isFetching={isFetching}
                pageLimit={pageLimit}
                onSelectRow={(e: any) => {
                  setCustomerId(e);
                }}
              />
            ) : (
              <BusinessCustomersMobileTable tableData={businessCustomers} />
            )}
          </TabPanel>
          <TabPanel px="0">
            <RevenueDashboard dashboardType="business" />
          </TabPanel>
        </TabPanels>
        {!isFetching && totalPages >= 1 && (tabIndex === 0 || tabIndex === 2) && (
          <CustomPaginations
            totalCount={totalRows}
            totalPages={totalPages}
            currentPage={currentPage}
            perPage={pageLimit}
            onSetPageLimit={(e: number) => {
              setPageLimit(e);
              setCurrentPage(1);
            }}
            onChangePage={(e: number) => {
              setCurrentPage(e);
            }}
          />
        )}
      </Tabs>
      {tabIndex === 0 && (
        <CustomerDetailsPopup
          selectedCustomerId={customerId}
          onCloseModal={() => {
            setCustomerId(null);
          }}
        />
      )}
      {tabIndex === 2 && (
        <BusinessCustomerDetailsPopup
          selectedCustomerId={customerId}
          onCloseModal={() => {
            setCustomerId(null);
          }}
        />
      )}
    </Box>
  );
}
