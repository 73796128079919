import { Box, Flex, Text } from "@chakra-ui/react";
import IconBox from "components/icons/IconBox";
import { IconCheckedCircleFill } from "components/icons/Icons";

export default function TranslationRecommendedOption(props: {
  selected: boolean;
  content: string;
  type: string;
  price: string;
  icon?: JSX.Element | string;
  onSelectTransitionOption: Function;
  isRecommened: boolean;
  [x: string]: any;
}) {
  const {
    selected,
    content,
    type,
    icon,
    price,
    onSelectTransitionOption,
    isRecommened,
    ...rest
  } = props;
  return (
    <Flex
      cursor="pointer"
      columnGap="35px"
      transition="all .3s"
      onClick={() => {
        onSelectTransitionOption(type);
      }}
      _hover={{
        borderColor: "#2F80EDAA",
      }}
      {...rest}
    >
      <Box>
        <Flex paddingRight={{ "3sm": "0px", base: "90px" }}>
          <Text
            fontSize="16px"
            lineHeight="normal"
            fontWeight="500"
            color="#000"
            textTransform="capitalize"
            mr="8px"
            sx={{
              "@media (max-width: 400px)": {
                width: isRecommened ? "100px" : "unset",
              },
            }}
          >
            {type === "Mailed Hard Copy" ? "Two-Day Shipping Delivery" : type}
          </Text>
          {isRecommened && (
            <Box
              className="tag"
              padding="4px 8px"
              borderRadius="8px"
              backgroundColor="#2F80ED"
              fontSize="12px"
              fontWeight="400"
              lineHeight="normal"
              color="#FFF"
              height="fit-content"
            >
              Recommended
            </Box>
          )}
        </Flex>
        <Text
          fontSize="14px"
          lineHeight="normal"
          color="rgba(0, 0, 0, 0.6)"
          fontWeight="400"
          width="100%"
          maxWidth="395px"
          mt="8px"
        >
          {content}
        </Text>
      </Box>
      <Flex
        alignItems="flex-start"
        position={{ "3sm": "unset", base: "absolute" }}
        right="0"
        ml={{ "3sm": "auto", base: 0 }}
      >
        <Text
          fontSize="16px"
          lineHeight="normal"
          fontWeight="700"
          color="#000"
          mr={{ "3sm": "15px", base: "8px" }}
        >
          {price}
        </Text>
        {selected ? (
          <IconBox
            w="20px"
            h="20px"
            icon={<IconCheckedCircleFill w="21px" h="20px" />}
          />
        ) : (
          <Box
            w="20px"
            h="20px"
            borderRadius="50px"
            border="1px solid #8E8C8C"
          ></Box>
        )}
      </Flex>
    </Flex>
  );
}
