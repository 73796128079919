import { ArrowRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

import Card from "components/card/Card";
import { IconTranslator } from "components/icons/Icons";
import moment from "moment-timezone";
import { fetchUnreadMessagesCnt, pad_with_zeroes } from "network/helper";
import * as React from "react";
import { useSelector } from "react-redux";
import { unreadMessageSelector } from "store/project/selector";

type RowObj = {
  id: string;
  order_id: string;
  order_name: string;
  order_due_at: string;
  order_status_name: string;
  languages: string;
  translated_from: string;
  translated_to: string;
  notificationType: 0 | 1 | 2 | 3 | 4 | 5;
  message_cnt: number;
  actionCol: string;
};

const columnHelper = createColumnHelper<RowObj>();

export default function TranslatorProjectsMobileTable(props: {
  tableData: any;
  isFetching?: boolean;
  onShowTranslatorProjectDetails: Function;
}) {
  const { tableData, isFetching, onShowTranslatorProjectDetails } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const textColor = useColorModeValue("black", "black");
  const unreadMessages = useSelector(unreadMessageSelector);

  let defaultData = tableData;

  const columns = [
    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <Text
          align="left"
          fontSize="14px"
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Project #
        </Text>
      ),
      cell: (info: any) => {
        return (
          <Flex align="center">
            <Text
              color={info.row.getValue("id") === "0012" ? "#F00" : textColor}
              fontSize="14px"
              lineHeight="24px"
              fontWeight="400"
              maxW={{ sm: "200px", md: "unset" }}
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
            >
              {pad_with_zeroes(info.getValue(), 6)}
            </Text>
          </Flex>
        );
      },
    }),

    columnHelper.accessor("order_due_at", {
      id: "order_due_at",
      header: () => (
        <Text
          align="left"
          fontSize="14px"
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Due Date
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
          >
            {info.getValue()
              ? moment(new Date(info.getValue())).format("MM/DD/YY")
              : "-"}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("order_status_name", {
      id: "order_status_name",
      header: () => (
        <Text
          align="left"
          fontSize="14px"
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Status
        </Text>
      ),
      cell: (info) => (
        <Text
          display="flex"
          alignItems="center"
          justifyContent="center"
          color={textColor}
          fontSize="14px"
          lineHeight="14px"
          fontWeight="400"
          minWidth="50px"
          width="fit-content"
          height={"24px"}
          borderRadius="5px"
          padding="2px 10px"
          bgColor="#F3F5F5"
          textTransform="capitalize"
        >
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("languages", {
      id: "languages",
      header: () => (
        <Text
          align="left"
          fontSize="14px"
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Languages
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Flex
            color={textColor}
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
            alignItems={"center"}
          >
            {info.row.original?.translated_from &&
              info.row.original?.translated_to && (
                <React.Fragment>
                  {info.row.original?.translated_from}
                  <IconTranslator width="15px" height="16px" mx="4px" />
                  {info.row.original?.translated_to}
                </React.Fragment>
              )}
          </Flex>
        </Flex>
      ),
    }),

    columnHelper.accessor("actionCol", {
      id: "actionCol",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          color="gray.500"
          fontWeight="400"
        ></Text>
      ),
      cell: (info) => (
        <Flex align="center" ml="0">
          <Button
            borderRadius="0px"
            width="100%"
            padding="15px 35px"
            bgColor="#2F80ED"
            color="#FFF"
            fontSize="14px"
            lineHeight="17px"
            fontWeight="bold"
            onClick={(e) => {
              onShowTranslatorProjectDetails(info.row.original.order_id);
            }}
          >
            View Details
            <ArrowRightIcon ml="10px" fontSize="8px" />
          </Button>
        </Flex>
      ),
    }),
  ];
  const data = [...defaultData];
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  return (
    <Card
      flexDirection="column"
      w="calc(100% + 20px)"
      p="0px"
      px="10px"
      ml="-10px"
    >
      <Box>
        <Table
          variant="simple"
          color="gray.500"
          mb="24px"
          pb="2"
          lineHeight="15px"
        >
          <Tbody>
            {!isFetching &&
              table
                .getRowModel()
                .rows.slice(0, 11)
                .map((row: any, index: number) => {
                  return (
                    <Table
                      key={index}
                      mt={index === 0 ? "10px" : "35px"}
                      border="1px solid #dcdcdc"
                    >
                      <Tbody>
                        {row
                          .getVisibleCells()
                          .map((cell: any, _index: number) => {
                            return (
                              <Tr key={_index}>
                                {_index < 4 ? (
                                  <React.Fragment>
                                    <Td
                                      p="10px"
                                      width="110px"
                                      borderColor="#dcdcdc"
                                      borderRight="1px solid #dcdcdc"
                                      bgColor="#FAFAFA"
                                    >
                                      {flexRender(
                                        table.getHeaderGroups()[0].headers[
                                          _index
                                        ].column.columnDef.header,
                                        table
                                          .getHeaderGroups()[0]
                                          .headers[_index].getContext()
                                      )}
                                    </Td>
                                    <Td p="10px" position="relative">
                                      {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                      )}
                                      {_index === 0 &&
                                        fetchUnreadMessagesCnt(
                                          unreadMessages,
                                          row.original.order_id
                                        ) > 0 && (
                                          <Text
                                            color="#FFF"
                                            bgColor="#F02828"
                                            borderRadius="5px"
                                            fontSize="12px"
                                            lineHeight="14px"
                                            fontWeight="400"
                                            padding="5px"
                                            width="fit-content"
                                            whiteSpace="nowrap"
                                            position="absolute"
                                            top="10px"
                                            right="10px"
                                          >
                                            {`${fetchUnreadMessagesCnt(
                                              unreadMessages,
                                              row.original.order_id
                                            )} New Message`}
                                          </Text>
                                        )}
                                    </Td>
                                  </React.Fragment>
                                ) : (
                                  <Td colSpan={2} padding="0px">
                                    {flexRender(
                                      cell.column.columnDef.cell,
                                      cell.getContext()
                                    )}
                                  </Td>
                                )}
                              </Tr>
                            );
                          })}
                      </Tbody>
                    </Table>
                  );
                })}

            {isFetching &&
              [1, 2, 3, 4].map((row) => {
                return (
                  <Table
                    key={row}
                    mt={row === 0 ? "0px" : "15px"}
                    border="1px solid #dcdcdc"
                  >
                    <Tbody>
                      {[1, 2, 3, 4, 5].map((_it) => (
                        <Tr key={_it}>
                          <Td
                            p="10px"
                            width="110px"
                            borderColor="#dcdcdc"
                            borderRight="1px solid #dcdcdc"
                            bgColor="#FAFAFA"
                          >
                            <Skeleton height="24px" width="100%" />
                          </Td>
                          <Td p="10px" borderColor="#dcdcdc">
                            <Skeleton height="24px" width="100%" />
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                );
              })}
          </Tbody>
        </Table>
      </Box>
    </Card>
  );
}
