import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { createInjectorsEnhancer } from "redux-injectors";

import createReducer from "./reducer";
import rootSaga from "./saga";

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];
const { run: runSaga } = sagaMiddleware;

const enhancers = [
  createInjectorsEnhancer({
    createReducer,
    runSaga,
  }),
];

const AllRootSaga = (preloadedState = {}) => {
  const store = configureStore({
    reducer: createReducer(),
    middleware,
    preloadedState,
    enhancers,
    devTools: process.env.REACT_APP_ENV !== "production",
  });
  sagaMiddleware.run(rootSaga);
  return store;
};

export const store = AllRootSaga();
