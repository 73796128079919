import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type UserState = {
  allUsers: any;
  loading: boolean;
  isLoggedIn: boolean;
  user?: any;
  isUpdating: boolean;
  isUpdatingAvatar: boolean;
  authError: string;
  isGettingCards: boolean;
  cards: any[];

  // Saving Cards,
  isSavingCards: boolean;
  isRemovingCards: boolean;
  isGettingAllUsers: boolean;

  //
  isRegistering: boolean;
  alert?: {
    type: "success" | "warning" | "error";
    message: string;
  };

  isRegisteringID: boolean;
  isGettingID: boolean;
  personalID: any;

  isGettingLanguages: boolean;
  isUpdatingLanguages: boolean;
  languages: any[];

  // Bank
  isAddingBankAccount: boolean;
  isRemovingBankAccount: boolean;
  isGettingBankAccounts: boolean;
  bankAccounts: any[];

  // isGettingAssignedServices: boolean;
  // isAssigningService: any;
  // isUnAssigningService: any;
  // assignedServices: any[];

  // Teams
  teamUsers: any[];
  isFetchingTeams: boolean;
  isSavingTeammember: boolean;
  isRemovingTeammember: boolean;
  isChangeTeammemberRole: boolean;

  isUploadingInvoice: boolean;
  invoices: any[];
  isGettingInvoices: boolean;
};

const initialState: UserState = {
  allUsers: null,
  isLoggedIn: !!localStorage.getItem("access_token"),
  loading: false,
  isUpdating: false,
  isUpdatingAvatar: false,
  authError: "",
  isGettingCards: false,
  cards: [],
  isSavingCards: false,
  isRemovingCards: false,
  isGettingAllUsers: false,

  // SignUp
  isRegistering: false,

  isRegisteringID: false,
  isGettingID: false,
  personalID: null,

  isGettingLanguages: false,
  isUpdatingLanguages: false,
  languages: [],

  isAddingBankAccount: false,
  isRemovingBankAccount: false,
  isGettingBankAccounts: false,

  bankAccounts: [],

  // Services
  // isGettingAssignedServices: false,
  // isAssigningService: null,
  // isUnAssigningService: null,
  // assignedServices: [],

  // Team
  teamUsers: [],
  isFetchingTeams: false,
  isSavingTeammember: false,
  isRemovingTeammember: false,
  isChangeTeammemberRole: false,

  isUploadingInvoice: false,
  invoices: [],
  isGettingInvoices: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getUserRequest(state, action: PayloadAction<any>) {
      state.loading = true;
      state.user = null;
    },
    getUserSucess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.user = action.payload;
    },
    loginRequest(state, action: PayloadAction<any>) {
      state.loading = true;
      state.authError = null;
    },
    loginSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.isLoggedIn = true;
      state.user = action.payload;
    },
    loginFailed(state, action: PayloadAction<any>) {
      state.loading = false;
      state.isLoggedIn = false;
      state.authError = action.payload;
    },

    updateUserRequest(state, action: PayloadAction<any>) {
      state.isUpdating = true;
    },

    updateUserSuccess(state, action: PayloadAction<any>) {
      state.isUpdating = false;
    },

    updateUserFailed(state) {
      state.isUpdating = false;
    },

    uploadAvatarRequest(state, action: PayloadAction<any>) {
      state.isUpdatingAvatar = true;
    },
    uploadAvatarSuccess(state, action: PayloadAction<any>) {
      state.isUpdatingAvatar = false;
      state.user = action.payload;
    },
    uploadAvatarFailed(state) {
      state.isUpdatingAvatar = false;
    },

    deleteAvatarRequest(state, action: PayloadAction<any>) {
      state.isUpdatingAvatar = true;
    },

    logoutRequest(state) {
      state.isLoggedIn = false;
      state.user = null;
    },

    getCardsRequest(state, action: PayloadAction<any>) {
      state.isGettingCards = true;
      state.cards = [];
    },

    getCardsRequestSuccess(state, action: PayloadAction<any>) {
      state.isGettingCards = false;
      state.cards = action.payload;
    },

    getCardsRequestFailed(state) {
      state.isGettingCards = false;
      state.cards = [];
    },

    saveCardsRequest(state, action: PayloadAction<any>) {
      state.isSavingCards = true;
    },

    saveCardsRequestSuccess(state, action: PayloadAction<any>) {
      state.isSavingCards = false;
      state.cards = action.payload;
      state.alert = {
        type: "success",
        message: "New Card was added successfully",
      };
    },

    saveCardsRequestFailed(state) {
      state.isSavingCards = false;
      state.alert = {
        type: "error",
        message: "Failed to save the Card Info.",
      };
    },

    removeCardInfoRequest(state, action: PayloadAction<any>) {
      state.isRemovingCards = true;
    },

    removeCardInfoSuccess(state, action: PayloadAction<any>) {
      state.isRemovingCards = false;
      state.cards = action.payload;
      state.alert = {
        type: "success",
        message: "Card was removed successfully",
      };
    },

    removeCardInfoFailed(state) {
      state.isRemovingCards = false;
      state.alert = {
        type: "error",
        message: "Failed to remove the card info.",
      };
    },

    getAllUserRequest(state) {
      state.isGettingAllUsers = true;
      state.allUsers = null;
    },

    getAllUserRequestSuccess(state, action: PayloadAction<any>) {
      state.isGettingAllUsers = false;
      state.allUsers = action.payload;
    },

    getAllUserRequestFailed(state) {
      state.isGettingAllUsers = false;
    },

    signUpRequest(state) {
      state.isRegistering = true;
    },

    signUpRequestSuccess(state, action: PayloadAction<any>) {
      state.isRegistering = false;
    },

    signUpRequestFailed(state, action: PayloadAction<any>) {
      state.isRegistering = false;
    },

    // Put Manager ID information
    putIDRequest(state, action: PayloadAction<any>) {
      state.isRegisteringID = true;
    },

    putIDRequestSuccess(state, action: PayloadAction<any>) {
      state.isRegisteringID = false;
      state.personalID = action.payload;
    },

    putIDRequestFailed(state, action: PayloadAction<any>) {
      state.isRegisteringID = false;
    },

    // Get Manager ID information
    getIDRequest(state, action: PayloadAction<any>) {
      state.isGettingID = true;
    },

    getIDRequestSuccess(state, action: PayloadAction<any>) {
      state.isGettingID = false;
      state.personalID = action.payload;
    },

    getIDRequestFailed(state, action: PayloadAction<any>) {
      state.isGettingID = false;
    },

    // Language Actions
    getLanguagesRequest(state, action: PayloadAction<any>) {
      state.isGettingLanguages = true;
      state.languages = [];
    },

    getLanguagesRequestSuccess(state, action: PayloadAction<any>) {
      state.isGettingLanguages = false;
      state.languages = action.payload;
    },

    getLanguagesRequestFailed(state) {
      state.isGettingAllUsers = false;
    },

    updateLanguagesRequest(state, action: PayloadAction<any>) {
      state.isUpdatingLanguages = true;
    },

    updateLanguagesRequestSuccess(state, action: PayloadAction<any>) {
      state.isUpdatingLanguages = false;
      state.languages = action.payload;
    },

    updateLanguagesRequestFailed(state) {
      state.isUpdatingLanguages = false;
    },

    delLanguagesRequest(state, action: PayloadAction<any>) {
      state.isUpdatingLanguages = true;
    },

    delLanguagesRequestSuccess(state, action: PayloadAction<any>) {
      state.isUpdatingLanguages = false;
      state.languages = action.payload;
    },

    delLanguagesRequestFailed(state) {
      state.isUpdatingLanguages = false;
    },

    // Bank
    getBankRequest(state, action: PayloadAction<any>) {
      state.isGettingBankAccounts = true;
    },

    getBankRequestSuccess(state, action: PayloadAction<any>) {
      state.isGettingBankAccounts = false;
      state.bankAccounts = action.payload;
    },

    getBankRequestFailed(state, action: PayloadAction<any>) {
      state.isGettingBankAccounts = false;
    },

    addBankRequest(state, action: PayloadAction<any>) {
      state.isAddingBankAccount = true;
    },

    addBankRequestSuccess(state, action: PayloadAction<any>) {
      state.isAddingBankAccount = false;
      state.bankAccounts = action.payload;
    },

    addBankRequestFailed(state, action: PayloadAction<any>) {
      state.isAddingBankAccount = false;
    },

    delBankRequest(state, action: PayloadAction<any>) {
      state.isRemovingBankAccount = true;
    },

    delBankRequestSuccess(state, action: PayloadAction<any>) {
      state.isRemovingBankAccount = false;
      state.bankAccounts = action.payload;
    },

    delBankRequestFailed(state, action: PayloadAction<any>) {
      state.isRemovingBankAccount = false;
    },

    // Services
    // assignServiceRequest(state, action: PayloadAction<any>) {
    //   state.isAssigningService = action.payload?.service?.service_id || null;
    // },

    // assignServiceRequestSuccess(state, action: PayloadAction<any>) {
    //   state.isAssigningService = null;
    //   state.assignedServices = action.payload.services;
    // },

    // assignServiceRequestFailed(state, action: PayloadAction<any>) {
    //   state.isAssigningService = null;
    // },

    // getAssignedServiceRequest(state, action: PayloadAction<any>) {
    //   state.isGettingAssignedServices = true;
    // },

    // getAssignedServiceRequestSuccess(state, action: PayloadAction<any>) {
    //   state.isGettingAssignedServices = false;
    //   state.assignedServices = action.payload.services;
    // },

    // getAssignedServiceRequestFailed(state, action: PayloadAction<any>) {
    //   state.isGettingAssignedServices = false;
    // },

    // unAssignedServiceRequest(state, action: PayloadAction<any>) {
    //   state.isUnAssigningService = action?.payload?.service_id || null;
    // },

    // unAssignedServiceRequestSuccess(state, action: PayloadAction<any>) {
    //   state.isUnAssigningService = null;
    //   state.assignedServices = action.payload.services;
    // },

    // unAssignedServiceRequestFailed(state, action: PayloadAction<any>) {
    //   state.isUnAssigningService = null;
    // },

    fetchTeamUsersRequest(state, action: PayloadAction<any>) {
      state.isFetchingTeams = true;
    },
    fetchTeamUsersRequestSuccess(state, action: PayloadAction<any>) {
      state.isFetchingTeams = false;
      state.teamUsers = action.payload;
    },
    fetchTeamUsersRequestFailed(state, action: PayloadAction<any>) {
      state.isFetchingTeams = false;
    },

    addTeammemberRequest(state, action: PayloadAction<any>) {
      state.isSavingTeammember = true;
    },

    addTeammemberRequestSuccess(state, action: PayloadAction<any>) {
      state.isSavingTeammember = false;
      state.teamUsers = action.payload;
    },

    addTeammemberRequestFailed(state, action: PayloadAction<any>) {
      state.isSavingTeammember = false;
    },

    deleteTeammemberRequest(state, action: PayloadAction<any>) {
      state.isRemovingTeammember = true;
    },

    deleteTeammemberRequestSuccess(state, action: PayloadAction<any>) {
      state.isRemovingTeammember = false;
      state.teamUsers = action.payload;
    },

    deleteTeammemberRequestFailed(state, action: PayloadAction<any>) {
      state.isRemovingTeammember = false;
    },

    updateTeammemberRoleRequest(state, action: PayloadAction<any>) {
      state.isChangeTeammemberRole = true;
    },

    updateTeammemberRoleRequestSuccess(state, action: PayloadAction<any>) {
      state.isChangeTeammemberRole = false;
    },

    updateTeammemberRoleRequestFailed(state, action: PayloadAction<any>) {
      state.isChangeTeammemberRole = false;
    },

    // Invoices
    uploadInvoiceRequest(state, action: PayloadAction<any>) {
      state.isUploadingInvoice = true;
    },

    uploadInvoiceRequestSuccess(state, action: PayloadAction<any>) {
      state.isUploadingInvoice = false;
    },

    uploadInvoiceRequestFailed(state, action: PayloadAction<any>) {
      state.isUploadingInvoice = false;
    },

    getInvoicesRequest(state, action: PayloadAction<any>) {
      state.isGettingInvoices = true;
    },
    getInvoicesRequestSuccess(state, action: PayloadAction<any>) {
      state.isGettingInvoices = false;
      state.invoices = action.payload;
    },
    getInvoicesRequestFailed(state, action: PayloadAction<any>) {
      state.isGettingInvoices = false;
    },
  },
});

export const {
  getUserRequest,
  getUserSucess,
  loginRequest,
  loginSuccess,
  loginFailed,
  updateUserRequest,
  updateUserSuccess,
  updateUserFailed,
  uploadAvatarRequest,
  uploadAvatarSuccess,
  uploadAvatarFailed,
  deleteAvatarRequest,
  logoutRequest,
  getCardsRequest,
  getCardsRequestSuccess,
  getCardsRequestFailed,
  saveCardsRequest,
  saveCardsRequestSuccess,
  saveCardsRequestFailed,

  removeCardInfoRequest,
  removeCardInfoSuccess,
  removeCardInfoFailed,

  getAllUserRequest,
  getAllUserRequestSuccess,
  getAllUserRequestFailed,

  signUpRequest,
  signUpRequestSuccess,
  signUpRequestFailed,

  putIDRequest,
  putIDRequestSuccess,
  putIDRequestFailed,
  getIDRequest,
  getIDRequestSuccess,
  getIDRequestFailed,

  getLanguagesRequest,
  getLanguagesRequestSuccess,
  getLanguagesRequestFailed,
  updateLanguagesRequest,
  updateLanguagesRequestSuccess,
  updateLanguagesRequestFailed,
  delLanguagesRequest,
  delLanguagesRequestSuccess,
  delLanguagesRequestFailed,

  getBankRequest,
  getBankRequestSuccess,
  getBankRequestFailed,
  addBankRequest,
  addBankRequestSuccess,
  addBankRequestFailed,
  delBankRequest,
  delBankRequestSuccess,
  delBankRequestFailed,

  // assignServiceRequest,
  // assignServiceRequestSuccess,
  // assignServiceRequestFailed,
  // getAssignedServiceRequest,
  // getAssignedServiceRequestSuccess,
  // getAssignedServiceRequestFailed,
  // unAssignedServiceRequest,
  // unAssignedServiceRequestSuccess,
  // unAssignedServiceRequestFailed,

  fetchTeamUsersRequest,
  fetchTeamUsersRequestSuccess,
  fetchTeamUsersRequestFailed,
  addTeammemberRequest,
  addTeammemberRequestSuccess,
  addTeammemberRequestFailed,

  deleteTeammemberRequest,
  deleteTeammemberRequestSuccess,
  deleteTeammemberRequestFailed,

  updateTeammemberRoleRequest,
  updateTeammemberRoleRequestSuccess,
  updateTeammemberRoleRequestFailed,

  uploadInvoiceRequest,
  uploadInvoiceRequestSuccess,
  uploadInvoiceRequestFailed,
  getInvoicesRequest,
  getInvoicesRequestSuccess,
  getInvoicesRequestFailed,
} = userSlice.actions;

export default userSlice.reducer;
