import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { setSearchQuery } from "store/ui/reducer";
export function SearchBar(props: {
  variant?: string;
  background?: string;
  children?: JSX.Element;
  placeholder?: string;
  borderRadius?: string | number;
  [x: string]: any;
}) {
  const { variant, background, children, placeholder, borderRadius, ...rest } =
    props;
  const dispatch = useDispatch();
  const searchIconColor = useColorModeValue("gray.700", "white");
  const inputBg = useColorModeValue("secondaryGray.300", "navy.900");
  const inputText = useColorModeValue("gray.700", "gray.100");
  const handleChange = _.debounce(function (searchQuery) {
    dispatch(setSearchQuery(searchQuery));
  }, 500);
  return (
    <InputGroup w={{ base: "100%", md: "240px" }} {...rest}>
      <InputLeftElement
        mt="6px"
        children={
          <IconButton
            aria-label="search"
            bg="inherit"
            borderRadius="inherit"
            _active={{
              bg: "inherit",
              transform: "none",
              borderColor: "transparent",
            }}
            pointerEvents="none"
            _focus={{
              boxShadow: "none",
            }}
            icon={<SearchIcon color={searchIconColor} w="18px" h="18px" />}
          />
        }
      />
      <Input
        variant="search"
        fontSize="sm"
        padding="14px 14px 14px 50px"
        height="52px"
        bg={background ? background : inputBg}
        color={inputText}
        fontWeight="500"
        _placeholder={{
          color: "#ABABAB",
          fontSize: "14px",
          lineHeight: "17px",
          fontWeight: 400,
        }}
        onChange={(e) => {
          handleChange(e.target.value);
          // console.log(e.target.value);
        }}
        borderRadius={borderRadius ? borderRadius : "30px"}
        placeholder={placeholder ? placeholder : "Search for a file"}
      />
    </InputGroup>
  );
}
