/* eslint-disable react-hooks/exhaustive-deps */
import {
  Badge,
  Box,
  Skeleton,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useWindowWidth } from "@react-hook/window-size";
import _ from "lodash";
import { filterOrdersByName } from "network/helper";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTranslatorProjects,
  getUnreadMessagesRequest,
} from "store/project/reducer";
import {
  isFetchingTranslatorProjectsSelector,
  translatorProjectsSelector,
  unreadMessageSelector,
} from "store/project/selector";
import { searchQuerySelector } from "store/ui/selector";
import { userSelector } from "store/user/selector";
import TranslatorProjectDetail from "./components/translatorProjectDetail";
import TranslatorProjectsMobileTable from "./components/translatorProjectsMobileTable";
import TranslatorProjectsTable from "./components/translatorProjectsTable";

export default function TranslatorProjects() {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const translatorProjects = useSelector(translatorProjectsSelector);
  const searchQuery = useSelector(searchQuerySelector);
  const unreadMessages = useSelector(unreadMessageSelector);
  const isFetchingTranslatorProjects = useSelector(
    isFetchingTranslatorProjectsSelector
  );
  const windowWidth = useWindowWidth();

  useEffect(() => {
    if (user) {
      dispatch(getTranslatorProjects({ user_id: Number(user.user_id) }));
      dispatch(getUnreadMessagesRequest({ user_id: Number(user.user_id) }));
    }
  }, [user]);

  const categorizedProjects = useMemo(() => {
    let results: any[] = [];
    let languages: string[] = [];
    if (translatorProjects) {
      translatorProjects.forEach((it: any) => {
        if (it.translated_to) {
          languages = [...languages, ...it.translated_to.split(",")];
        }
      });

      languages = _.uniq(["all", ...languages]);

      languages.forEach((lang) => {
        let languageProjects =
          lang === "all"
            ? filterOrdersByName(translatorProjects, searchQuery)
            : filterOrdersByName(translatorProjects, searchQuery)?.filter(
                (it) =>
                  it?.translated_to
                    ?.toLowerCase()
                    .includes(lang?.toLocaleLowerCase())
              );

        let activeProjects = languageProjects
          ?.filter(
            (it) =>
              Number(it.order_status_id) < 7 && Number(it.order_status_id) !== 6
          )
          ?.map((it) => ({
            ...it,
            id: it?.order_id,
          }));

        let activeProjectsCnt = 0;
        activeProjects?.forEach((it: any) => {
          let order_id = it?.order_id;
          const cnt = Number(
            unreadMessages?.find(
              (_it: any) => Number(_it?.order_id) === Number(order_id)
            )?.cnt || 0
          );
          activeProjectsCnt += cnt;
        });

        let completedProjects = languageProjects
          ?.filter((it) => Number(it.order_status_id) === 6)
          ?.map((it) => ({
            ...it,
            id: it?.order_id,
          }));

        let completedProjectsCnt = 0;
        completedProjects?.forEach((it: any) => {
          let order_id = it?.order_id;
          const cnt = Number(
            unreadMessages?.find(
              (_it: any) => Number(_it?.order_id) === Number(order_id)
            )?.cnt || 0
          );
          completedProjectsCnt += cnt;
        });

        let newProjects = languageProjects
          ?.filter(
            (it) =>
              (Number(it.order_status_id) === 1 ||
                new Date().getTime() -
                  new Date(it.order_created_at)?.getTime() <
                  24 * 3600 * 1000) &&
              Number(it.order_status_id) !== 6
          )
          ?.map((it) => ({
            ...it,
            id: it?.order_id,
          }));

        let newProjectsCnt = 0;
        newProjects?.forEach((it: any) => {
          let order_id = it?.order_id;
          const cnt = Number(
            unreadMessages?.find(
              (_it: any) => Number(_it?.order_id) === Number(order_id)
            )?.cnt || 0
          );
          newProjectsCnt += cnt;
        });

        let pastDueProjects = languageProjects
          ?.filter(
            (it) =>
              it.order_due_at &&
              new Date(it.order_due_at).getTime() < new Date().getTime() &&
              Number(it.order_status_id) !== 6
          )
          ?.map((it) => ({
            ...it,
            id: it?.order_id,
            notificationType: 5,
          }));

        let pastDueProjectsCnt = 0;
        pastDueProjects?.forEach((it: any) => {
          let order_id = it?.order_id;
          const cnt = Number(
            unreadMessages?.find(
              (_it: any) => Number(_it?.order_id) === Number(order_id)
            )?.cnt || 0
          );
          pastDueProjectsCnt += cnt;
        });

        let dueSoonProjects = languageProjects
          ?.filter(
            (it) =>
              it.order_due_at &&
              (new Date(it.order_due_at).getTime() - new Date().getTime()) /
                1000 <
                24 * 3600 * 1 &&
              new Date(it.order_due_at).getTime() - new Date().getTime() > 0 &&
              Number(it.order_status_id) !== 6
          )
          ?.map((it) => ({
            ...it,
            id: it?.order_id,
            notificationType: 2,
          }));

        let dueSoonProjectsCnt = 0;
        dueSoonProjects?.forEach((it: any) => {
          let order_id = it?.order_id;
          const cnt = Number(
            unreadMessages?.find(
              (_it: any) => Number(_it?.order_id) === Number(order_id)
            )?.cnt || 0
          );
          dueSoonProjectsCnt += cnt;
        });

        let reviewProjects = languageProjects
          ?.filter((it) => Number(it.order_status_id) === 7)
          ?.map((it) => ({
            ...it,
            id: it?.order_id,
          }));

        let reviewProjectsCnt = 0;
        reviewProjects?.forEach((it: any) => {
          let order_id = it?.order_id;
          const cnt = Number(
            unreadMessages?.find(
              (_it: any) => Number(_it?.order_id) === Number(order_id)
            )?.cnt || 0
          );
          reviewProjectsCnt += cnt;
        });

        results.push({
          lang,
          activeProjects,
          completedProjects,
          newProjects,
          pastDueProjects,
          dueSoonProjects,
          reviewProjects,
          activeProjectsCnt,
          completedProjectsCnt,
          newProjectsCnt,
          pastDueProjectsCnt,
          dueSoonProjectsCnt,
          reviewProjectsCnt,
        });
      });
    }
    return results;
  }, [translatorProjects, searchQuery, unreadMessages]);

  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const showDetailsTranslatorProductDetails = (order_id: string) => {
    setSelectedProjectId(order_id);
  };

  return (
    <Box pt={{ base: "60px", md: "80px", xl: "80px" }}>
      {!isFetchingTranslatorProjects && categorizedProjects?.length > 0 && (
        <Tabs variant="unstyled">
          <TabList flexWrap="wrap">
            {categorizedProjects.map((it: any, index: number) => (
              <Tab
                key={index}
                padding={{
                  md: index === 0 ? "0px 20px 0px 0px" : "0px 20px",
                  sm: index === 0 ? "0px 10px 0px 0px" : "0px 10px",
                }}
                fontSize={{ md: "17px", sm: "12px" }}
                lineHeight={{ md: "24px", sm: "22px" }}
                fontWeight="400"
                alignItems="center"
                textTransform="capitalize"
                _selected={{ color: "blue.500" }}
                _focus={{ boxShadow: "none" }}
              >
                {it.lang}
                {Number(it?.activeProjectsCnt) +
                  Number(it?.completedProjectsCnt) >
                  0 &&
                  it?.lang === "all" && (
                    <Badge
                      bgColor={"#F02828"}
                      sx={{
                        padding: "9px 6px !important",
                        borderRadius: "1000px",
                        fontWeight: "700",
                        fontSize: "10px",
                        lineHeight: "0px",
                        color: "#FFF",
                        marginLeft: "7px",
                        minWidth: "18px",
                      }}
                    >
                      {Number(it?.activeProjectsCnt) +
                        Number(it?.completedProjectsCnt) +
                        Number(it?.reviewProjectsCnt)}
                    </Badge>
                  )}
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {categorizedProjects.map((it: any, index: number) => (
              <TabPanel
                px="0"
                key={index}
                mt={{ md: "26px", base: "15px" }}
                py="0"
              >
                <Tabs variant="unstyled">
                  <TabList borderBottom={"2px solid #E9E9E9"}>
                    <Tab
                      padding={{
                        md: "14px 25px 14px 0px",
                        sm: "5px 5px 5px 0px",
                      }}
                      fontSize={{ md: "17px", sm: "12px" }}
                      lineHeight={{ md: "24px", sm: "22px" }}
                      fontWeight="400"
                      alignItems="center"
                      _selected={{ color: "blue.500" }}
                      _focus={{ boxShadow: "none" }}
                      whiteSpace="nowrap"
                    >
                      New
                      {Number(it?.newProjectsCnt) > 0 && (
                        <Badge
                          bgColor={"#F02828"}
                          sx={{
                            padding: "9px 6px !important",
                            borderRadius: "1000px",
                            fontWeight: "700",
                            fontSize: "10px",
                            lineHeight: "0px",
                            color: "#FFF",
                            marginLeft: "7px",
                            minWidth: "18px",
                          }}
                        >
                          {it?.newProjectsCnt}
                        </Badge>
                      )}
                    </Tab>
                    <Tab
                      padding={{ md: "14px 25px", sm: "5px" }}
                      fontSize={{ md: "17px", sm: "12px" }}
                      lineHeight={{ md: "24px", sm: "22px" }}
                      fontWeight="400"
                      alignItems="center"
                      _selected={{ color: "blue.500" }}
                      _focus={{ boxShadow: "none" }}
                      whiteSpace="nowrap"
                    >
                      Active
                      {Number(it?.activeProjectsCnt) > 0 && (
                        <Badge
                          bgColor={"#F02828"}
                          sx={{
                            padding: "9px 6px !important",
                            borderRadius: "1000px",
                            fontWeight: "700",
                            fontSize: "10px",
                            lineHeight: "0px",
                            color: "#FFF",
                            marginLeft: "7px",
                            minWidth: "18px",
                          }}
                        >
                          {it?.activeProjectsCnt}
                        </Badge>
                      )}
                    </Tab>
                    <Tab
                      padding={{ md: "14px 25px", sm: "5px" }}
                      fontSize={{ md: "17px", sm: "12px" }}
                      lineHeight={{ md: "24px", sm: "22px" }}
                      fontWeight="400"
                      alignItems="center"
                      _selected={{ color: "blue.500" }}
                      _focus={{ boxShadow: "none" }}
                      whiteSpace="nowrap"
                    >
                      Due Soon
                      {Number(it?.dueSoonProjectsCnt) > 0 && (
                        <Badge
                          bgColor={"#F02828"}
                          sx={{
                            padding: "9px 6px !important",
                            borderRadius: "1000px",
                            fontWeight: "700",
                            fontSize: "10px",
                            lineHeight: "0px",
                            color: "#FFF",
                            marginLeft: "7px",
                            minWidth: "18px",
                          }}
                        >
                          {it?.dueSoonProjectsCnt}
                        </Badge>
                      )}
                    </Tab>
                    <Tab
                      padding={{ md: "14px 25px", sm: "5px" }}
                      fontSize={{ md: "17px", sm: "12px" }}
                      lineHeight={{ md: "24px", sm: "22px" }}
                      fontWeight="400"
                      alignItems="center"
                      _selected={{ color: "blue.500" }}
                      _focus={{ boxShadow: "none" }}
                      whiteSpace="nowrap"
                    >
                      Past Due
                      {Number(it?.pastDueProjectsCnt) > 0 && (
                        <Badge
                          bgColor={"#F02828"}
                          sx={{
                            padding: "9px 6px !important",
                            borderRadius: "1000px",
                            fontWeight: "700",
                            fontSize: "10px",
                            lineHeight: "0px",
                            color: "#FFF",
                            marginLeft: "7px",
                            minWidth: "18px",
                          }}
                        >
                          {it?.pastDueProjectsCnt}
                        </Badge>
                      )}
                    </Tab>

                    <Tab
                      padding={{ md: "14px 25px", sm: "5px" }}
                      fontSize={{ md: "17px", sm: "12px" }}
                      lineHeight={{ md: "24px", sm: "22px" }}
                      fontWeight="400"
                      alignItems="center"
                      _selected={{ color: "blue.500" }}
                      _focus={{ boxShadow: "none" }}
                      whiteSpace="nowrap"
                    >
                      Review
                      {Number(it?.reviewProjectsCnt) > 0 && (
                        <Badge
                          bgColor={"#F02828"}
                          sx={{
                            padding: "9px 6px !important",
                            borderRadius: "1000px",
                            fontWeight: "700",
                            fontSize: "10px",
                            lineHeight: "0px",
                            color: "#FFF",
                            marginLeft: "7px",
                            minWidth: "18px",
                          }}
                        >
                          {it?.reviewProjectsCnt}
                        </Badge>
                      )}
                    </Tab>

                    <Tab
                      padding={{ md: "14px 25px", sm: "5px" }}
                      fontSize={{ md: "17px", sm: "12px" }}
                      lineHeight={{ md: "24px", sm: "22px" }}
                      fontWeight="400"
                      alignItems="center"
                      _selected={{ color: "blue.500" }}
                      _focus={{ boxShadow: "none" }}
                      whiteSpace="nowrap"
                    >
                      Completed
                      {Number(it?.completedProjectsCnt) > 0 && (
                        <Badge
                          bgColor={"#F02828"}
                          sx={{
                            padding: "9px 6px !important",
                            borderRadius: "1000px",
                            fontWeight: "700",
                            fontSize: "10px",
                            lineHeight: "0px",
                            color: "#FFF",
                            marginLeft: "7px",
                            minWidth: "18px",
                          }}
                        >
                          {it?.completedProjectsCnt}
                        </Badge>
                      )}
                    </Tab>
                  </TabList>
                  <TabIndicator
                    mt="-2px"
                    height="2px"
                    bg="blue.500"
                    borderRadius="1px"
                  />
                  <TabPanels>
                    <TabPanel px="0">
                      {windowWidth >= 768 ? (
                        <TranslatorProjectsTable
                          tableData={it.newProjects}
                          onShowTranslatorProjectDetails={
                            showDetailsTranslatorProductDetails
                          }
                        />
                      ) : (
                        <TranslatorProjectsMobileTable
                          tableData={it.newProjects}
                          onShowTranslatorProjectDetails={
                            showDetailsTranslatorProductDetails
                          }
                        />
                      )}
                    </TabPanel>
                    <TabPanel px="0">
                      {windowWidth >= 768 ? (
                        <TranslatorProjectsTable
                          tableData={it.activeProjects}
                          onShowTranslatorProjectDetails={
                            showDetailsTranslatorProductDetails
                          }
                        />
                      ) : (
                        <TranslatorProjectsMobileTable
                          tableData={it.activeProjects}
                          onShowTranslatorProjectDetails={
                            showDetailsTranslatorProductDetails
                          }
                        />
                      )}
                    </TabPanel>
                    <TabPanel px="0">
                      {windowWidth >= 768 ? (
                        <TranslatorProjectsTable
                          tableData={it.dueSoonProjects}
                          onShowTranslatorProjectDetails={
                            showDetailsTranslatorProductDetails
                          }
                        />
                      ) : (
                        <TranslatorProjectsMobileTable
                          tableData={it.dueSoonProjects}
                          onShowTranslatorProjectDetails={
                            showDetailsTranslatorProductDetails
                          }
                        />
                      )}
                    </TabPanel>
                    <TabPanel px="0">
                      {windowWidth >= 768 ? (
                        <TranslatorProjectsTable
                          tableData={it.pastDueProjects}
                          onShowTranslatorProjectDetails={
                            showDetailsTranslatorProductDetails
                          }
                        />
                      ) : (
                        <TranslatorProjectsMobileTable
                          tableData={it.pastDueProjects}
                          onShowTranslatorProjectDetails={
                            showDetailsTranslatorProductDetails
                          }
                        />
                      )}
                    </TabPanel>
                    <TabPanel px="0">
                      {windowWidth >= 768 ? (
                        <TranslatorProjectsTable
                          tableData={it.reviewProjects}
                          onShowTranslatorProjectDetails={
                            showDetailsTranslatorProductDetails
                          }
                        />
                      ) : (
                        <TranslatorProjectsMobileTable
                          tableData={it.reviewProjects}
                          onShowTranslatorProjectDetails={
                            showDetailsTranslatorProductDetails
                          }
                        />
                      )}
                    </TabPanel>
                    <TabPanel px="0">
                      {windowWidth >= 768 ? (
                        <TranslatorProjectsTable
                          tableData={it.completedProjects}
                          onShowTranslatorProjectDetails={
                            showDetailsTranslatorProductDetails
                          }
                        />
                      ) : (
                        <TranslatorProjectsMobileTable
                          tableData={it.completedProjects}
                          onShowTranslatorProjectDetails={
                            showDetailsTranslatorProductDetails
                          }
                        />
                      )}
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      )}
      {!isFetchingTranslatorProjects && categorizedProjects?.length < 1 && (
        <Box>No Projects</Box>
      )}
      {isFetchingTranslatorProjects && (
        <Tabs>
          <TabList flexWrap="wrap">
            {_.range(4).map((it: any, index: number) => (
              <Tab
                key={index}
                padding={{
                  md: "10px 10px",
                  sm: "10px",
                }}
                fontSize={{ md: "17px", sm: "14px" }}
                lineHeight={{ md: "24px", sm: "22px" }}
                fontWeight="400"
                alignItems="center"
                textTransform="capitalize"
                _selected={{ color: "blue.500" }}
                _focus={{ boxShadow: "none" }}
              >
                <Skeleton
                  width={{ md: "100px", base: "50px" }}
                  height="20px"
                  colorScheme="#F3F5F5"
                />
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {windowWidth >= 768 &&
              _.range(4).map((it) => (
                <TabPanel px="0" key={it}>
                  <TranslatorProjectsTable
                    isFetching={isFetchingTranslatorProjects}
                    tableData={[]}
                    onShowTranslatorProjectDetails={
                      showDetailsTranslatorProductDetails
                    }
                  />
                </TabPanel>
              ))}
            {windowWidth < 768 &&
              _.range(4).map((it) => (
                <TabPanel px="0" key={it}>
                  <TranslatorProjectsMobileTable
                    isFetching={isFetchingTranslatorProjects}
                    tableData={[]}
                    onShowTranslatorProjectDetails={
                      showDetailsTranslatorProductDetails
                    }
                  />
                </TabPanel>
              ))}
          </TabPanels>
        </Tabs>
      )}
      <TranslatorProjectDetail
        selectedProjectId={selectedProjectId}
        onCloseModal={() => {
          setSelectedProjectId(null);
        }}
      />
    </Box>
  );
}
