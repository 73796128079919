import { Box } from "@chakra-ui/react";
import { VSeparator } from "components/separator/Separator";
import CreditCards from "./CreditCards";
import ExpenseReports from "./ExpenseReports";

export default function Billing() {
  return (
    <Box>
      <CreditCards />
      <VSeparator my="45px" w="full" h="1px" bgColor="#E3E3E3" />
      <ExpenseReports />
    </Box>
  );
}
