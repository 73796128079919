/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Button,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import IconBox from "components/icons/IconBox";
import { IconArrowDown, IconDownload } from "components/icons/Icons";
import { axiosService } from "network/axios";
import { useEffect, useMemo, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useDispatch, useSelector } from "react-redux";
import { setAlertMessageRequest } from "store/ui/reducer";
import { getIDRequest, getUserRequest } from "store/user/reducer";
import { userSelector } from "store/user/selector";
import PersonalDetailsForm from "./PersonalDetailsForm";
import moment from "moment-timezone";
import { DeleteIcon } from "@chakra-ui/icons";

export default function ManagerPersonalDetails() {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);

  useEffect(() => {
    if (user) {
      onGetDisclosureForms();
    }
  }, []);
  const [disclosures, setDisclosures] = useState([]);
  const [isGettingDisclosure, setIsGettingDisclosure] = useState(false);
  const [isDeletingDisclosure, setIsDeletingDisclosure] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );

  const isNotAvailable = useMemo(() => {
    if (user) {
      return Number(user?.user_status_id) === 5;
    }
    return false;
  }, [user]);

  const options = [
    {
      id: 1,
      label: "Available",
    },
    {
      id: 0,
      label: "Not Available",
    },
  ];

  const [selectedAvailabilityOptionId, setSelectedAvailabilityOptionId] =
    useState(0);

  useEffect(() => {
    if (isNotAvailable) {
      setSelectedAvailabilityOptionId(0);
    } else {
      setSelectedAvailabilityOptionId(1);
    }
  }, [isNotAvailable]);

  const onDownloadBasicPDF = async () => {
    setIsDownloading(true);
    const link = document.createElement("a");
    link.href =
      "https://api.languex.co/public/Basic-Non-Disclosure-Agreement.pdf";
    link.target = "_blank";
    link.setAttribute("download", "");
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    setIsDownloading(false);
  };

  const onUploadBasicPDF = async (file: File) => {
    setIsUploading(true);
    try {
      const formData = new FormData();
      formData.append("file", file);

      await axiosService.post(
        `/user/manager/put/agreement/${user.user_id}`,
        formData
      );

      dispatch(
        getIDRequest({
          user_id: user.user_id,
        })
      );
    } catch (err: any) {
      dispatch(
        setAlertMessageRequest({
          type: "warning",
          message:
            err?.data?.messages?.error ?? "Failed to download the basic form",
        })
      );
    } finally {
      setIsUploading(false);
      onGetDisclosureForms();
    }
  };

  const onGetDisclosureForms = async () => {
    setIsGettingDisclosure(true);
    try {
      const resp: any = await axiosService.get(
        `/user/get-agreement/${user.user_id}`
      );
      setDisclosures(resp?.data?.result || []);
    } catch (err: any) {
      setDisclosures([]);
      dispatch(
        setAlertMessageRequest({
          type: "error",
          message:
            err?.data?.messages?.error ?? "Failed to get Disclosure Documents",
        })
      );
    } finally {
      setIsGettingDisclosure(false);
    }
  };

  const onRemoveDisclosure = async (id: string) => {
    setIsDeletingDisclosure(id);
    try {
      await axiosService.delete(`/user/del/all/agreement/${id}`);
    } catch (err: any) {
      dispatch(
        setAlertMessageRequest({
          type: "error",
          message:
            err?.data?.messages?.error ?? "Failed to get Disclosure Documents",
        })
      );
    } finally {
      setIsDeletingDisclosure(null);
      await onGetDisclosureForms();
    }
  };

  const onChangeAvailability = async (option_id: number) => {
    const payload = {
      status_id: option_id,
    };

    setSelectedAvailabilityOptionId(option_id);

    await axiosService.post(`/user/update-status/${user.user_id}`, payload);
    dispatch(
      getUserRequest({
        user_id: user.user_id,
      })
    );
  };

  return (
    <Box>
      <Flex columnGap="120px" pb="35px" borderBottom="1px solid #E3E3E3">
        <Card p="0" width="fit-content">
          <Text
            fontWeight="400"
            fontSize="12px"
            lineHeight="24px"
            color="#8E8C8C"
          >
            Account Type
          </Text>

          <Text
            marginTop="5px"
            fontWeight="400"
            fontSize="17px"
            lineHeight="135%"
            color="#000"
          >
            Project Manager
          </Text>
        </Card>

        <Card p="0" width="fit-content" zIndex="1111">
          <Text
            fontWeight="400"
            fontSize="12px"
            lineHeight="24px"
            color="#8E8C8C"
          >
            Status
          </Text>
          <Menu>
            <MenuButton marginTop="5px">
              <Flex alignItems={"center"}>
                <Text
                  fontWeight="400"
                  fontSize="17px"
                  lineHeight="135%"
                  color="#000"
                >
                  {selectedAvailabilityOptionId === 0
                    ? "Not Available"
                    : "Available"}
                </Text>
                <IconArrowDown ml="auto" w="24px" h="24px" />
              </Flex>
            </MenuButton>
            <MenuList
              boxShadow={shadow}
              p="0px"
              mt="10px"
              borderRadius="20px"
              bg="#FFF"
              border="none"
            >
              <Flex
                flexDirection="column"
                p="10px 20px"
                fontSize={"14px"}
                lineHeight="24px"
                fontWeight={"400"}
                color="black"
              >
                {options.map((option, index: number) => (
                  <MenuItem
                    key={index}
                    _hover={{ bg: "none" }}
                    _focus={{ bg: "none" }}
                    borderRadius="8px"
                    padding="15px 0px"
                    onClick={() => {
                      onChangeAvailability(option.id);
                    }}
                    borderBottom={
                      index < options.length - 1 ? "1px solid #F3F5F5" : ""
                    }
                  >
                    <Text fontSize="sm">{option.label}</Text>
                  </MenuItem>
                ))}
              </Flex>
            </MenuList>
          </Menu>
        </Card>
      </Flex>
      <Box py="35px" borderBottom="1px solid #E3E3E3">
        <PersonalDetailsForm />
      </Box>
      <Box py="35px">
        <Flex
          columnGap="60px"
          width="100%"
          flexDirection={{ md: "row", base: "column" }}
          rowGap="30px"
        >
          <Text
            fontSize="17px"
            lineHeight="24px"
            color="#000"
            fontWeight="700"
            width={{ md: "130px", base: "100%" }}
            minW={{ md: "130px", base: "100%" }}
          >
            Non-Disclosure Agreement
          </Text>
          <Text fontSize="14px" lineHeight="135%" fontWeight="400" color="#000">
            Download the non-disclosure agreement, sign it, and upload it back
            to your profile.
          </Text>
        </Flex>
        <Flex
          alignItems={{ md: "center", base: "flex-start" }}
          marginTop={{ md: "40px", base: "25px" }}
          flexDirection={{ md: "row", base: "column" }}
          rowGap="20px"
          sx={{
            "label:focus-within": {
              outline: "none !important",
            },
            label: {
              marginLeft: { md: "16px", base: "0px" },
            },
          }}
        >
          <Button
            height="46px"
            padding="15px 35px"
            borderRadius="100px"
            bgColor="#2F80ED"
            color="#FFF"
            fontSize="14px"
            lineHeight="17px"
            fontWeight="bold"
            width="175px"
            isLoading={isDownloading}
            onClick={() => onDownloadBasicPDF()}
          >
            Download
          </Button>
          <FileUploader
            multiple={false}
            handleChange={(file: File) => {
              onUploadBasicPDF(file);
            }}
            name="file"
            height="full"
            types={["pdf"]}
            sx={{
              outline: "none",
            }}
          >
            <Button
              height="46px"
              padding="15px 35px"
              borderRadius="100px"
              bgColor="#FFF"
              border="1px solid #E9E9E9"
              color="#000"
              fontSize="14px"
              lineHeight="17px"
              fontWeight="bold"
              width="175px"
              isLoading={isUploading}
            >
              Upload
              {/* {_.isEmpty(personalID?.none_disclousure_agreement)
                ? "Upload"
                : "Replace"} */}
            </Button>
          </FileUploader>
        </Flex>
        <Box mt={8} mb={10}>
          {disclosures?.length > 0 && (
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th isNumeric width="20px">
                      No
                    </Th>
                    <Th>Uploaded Date</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {disclosures?.map((it: any, index: number) => (
                    <Tr key={index}>
                      <Td isNumeric>{index + 1}</Td>
                      <Td>
                        {moment(new Date(it?.created_at)).format(
                          "YYYY-MM-DD HH:mm"
                        )}
                      </Td>
                      <Td>
                        <Flex
                          alignItems="center"
                          justifyContent="flex-end"
                          columnGap="5px"
                        >
                          <IconBox
                            borderRadius="20px"
                            icon={<IconDownload width="24px" height="24px" />}
                            marginRight="0px"
                            cursor="pointer"
                            _hover={{
                              textDecoration: "underline",
                            }}
                            onClick={() => {
                              const fullUrl = `${process.env.REACT_APP_API_BASE_URL}/${it?.file_location}`;
                              const link = document.createElement("a");
                              link.href = fullUrl;
                              link.target = "_blank";
                              link.setAttribute(
                                "download",
                                `none_disclousre_agreement.pdf`
                              );
                              document.body.appendChild(link);
                              link.click();
                              link.parentNode.removeChild(link);
                            }}
                          ></IconBox>

                          <IconButton
                            aria-label="delete"
                            bg="inherit"
                            color="#F02828"
                            _active={{
                              bg: "inherit",
                              transform: "none",
                              borderColor: "transparent",
                            }}
                            _hover={{
                              bg: "inherit",
                            }}
                            _focus={{
                              boxShadow: "none",
                            }}
                            onClick={() => {
                              onRemoveDisclosure(it?.id);
                            }}
                            isLoading={
                              isDeletingDisclosure &&
                              Number(isDeletingDisclosure) === Number(it?.id)
                            }
                            icon={
                              <DeleteIcon w="20px" h="20px" color="#F02828" />
                            }
                          />
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Box>
    </Box>
  );
}
