import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Heading,
  IconButton,
  SimpleGrid,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import { IconClose, IconDownloadOrigin } from "components/icons/Icons";
import Card from "components/card/Card";
import IconBox from "components/icons/IconBox";
import TranslatorProjectDetailTable from "./translatorProjectDetailTable";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "store/user/selector";
import moment from "moment-timezone";
import {
  getTranslatorProjectDetailRequest,
  requestDownloadOriginFile,
  updateDocumentReqeust,
} from "store/project/reducer";
import {
  isFetchingDetails,
  selectedProjectSelector,
  unreadMessageSelector,
} from "store/project/selector";
import {
  fetchUnreadMessagesCnt,
  getProjectStatusAsPercent,
  pad_with_zeroes,
} from "network/helper";
import MessageBox from "components/messagebox/MessageBox";
import _, { uniqBy } from "lodash";
import UserInfoMenu from "components/menu/UserInfoMenu";
import TranslatorProjectDetailMobileTable from "./translatorProjectDetailMobileTable";
import { useWindowWidth } from "@react-hook/window-size";

export default function TranslatorProjectDetail(props: {
  selectedProjectId?: string;
  onCloseModal: Function;
}) {
  const { selectedProjectId, onCloseModal } = props;
  const dispatch = useDispatch();
  const projectDetailRef = useRef();
  const windowWidth = useWindowWidth();
  const user = useSelector(userSelector);
  const unreadMessages = useSelector(unreadMessageSelector);
  const [projectMeta, setProjectMeta] = useState([
    {
      label: "Order Date",
      value: null,
    },
    {
      label: "Pages",
      value: null,
    },
    {
      label: "Words",
      value: null,
    },
    {
      label: "Type",
      value: null,
    },
    {
      label: "Notarized",
      value: null,
    },
  ]);

  const unreadMessageCnt = useMemo(() => {
    if (selectedProjectId && unreadMessages) {
      return fetchUnreadMessagesCnt(unreadMessages, Number(selectedProjectId));
    } else {
      return 0;
    }
  }, [unreadMessages, selectedProjectId]);

  const isFetching = useSelector(isFetchingDetails);
  const selectedProject = useSelector(selectedProjectSelector);

  useEffect(() => {
    if (selectedProjectId && dispatch && user) {
      dispatch(
        getTranslatorProjectDetailRequest({
          order_id: selectedProjectId,
          user_id: user.user_id,
        })
      );
    }
  }, [dispatch, selectedProjectId, user]);

  const projectManagers = useMemo(() => {
    if (selectedProject) {
      return uniqBy(selectedProject.projectManager, "user_id")?.map(
        (it: any) => {
          return `${it.first_name} ${it.last_name}`;
        }
      );
    }
    return [];
  }, [selectedProject]);

  useEffect(() => {
    setProjectMeta([
      {
        label: "Order Date",
        value: moment(new Date(selectedProject?.order?.created_at)).format(
          "MM/DD/YY"
        ),
      },
      {
        label: "Pages",
        value:
          selectedProject?.order?.translation_type === "standard"
            ? null
            : selectedProject?.order?.page_count || 0,
      },
      {
        label: "Words",
        value:
          selectedProject?.order?.translation_type === "certified"
            ? null
            : selectedProject?.order?.words_count || 0,
      },
      {
        label: "Type",
        value: selectedProject?.order?.translation_type || "Undefined",
      },
      {
        label: "Notarized",
        value:
          selectedProject?.order?.include_notarization === "1" ? "Yes" : "No",
      },
      {
        label: "Two-Day Shipping Delivery",
        value:
          selectedProject?.order?.include_2_day_shipping === "1" ||
          selectedProject?.order?.include_mail_hard_copy === "1"
            ? "Yes"
            : "No",
      },
      {
        label: "Expedite Translation",
        value:
          selectedProject?.order?.include_expedited_turnaround === "1"
            ? "Yes"
            : "No",
      },
      {
        label: "Address",
        value: selectedProject?.order?.address_fields,
      },
    ]);
  }, [selectedProject]);
  const documents = useMemo(() => {
    if (selectedProject) {
      let results = selectedProject?.documents || [];
      const translated_from =
        selectedProject?.order?.translated_from || "English";
      const translated_to = selectedProject?.order?.translated_to || "English";
      return results.map((it: any) => {
        return {
          ...it,
          hasChanged: false,
          file: null,
          source_lang: translated_from,
          target_lang: translated_to,
        };
      });
    } else {
      return [];
    }
  }, [selectedProject]);

  const onUpdateDocument = (e: any) => {
    const document_id = e?.document_id;
    const document_status_id = e?.document_status_id;
    const translator_id =
      e?.translators?.length > 0 ? e?.translators[0].user_id : null;
    const manager_id = e?.managers?.length > 0 ? e?.managers[0].user_id : null;
    const payload = {
      order_id: selectedProjectId,
      document_id,
      document_status_id,
      translator_id,
      new_file: e?.new_file || null,
      manager_id,
      user_id: user.user_id,
      is_translator: true,
    };
    dispatch(updateDocumentReqeust(payload));
  };

  const onDownloadFiles = (type: string) => {
    dispatch(
      requestDownloadOriginFile({
        order_id: selectedProjectId,
        user_id: user.user_id,
        user_role: "translator",
        type: type,
        file_name: `${selectedProject.order.order_id}-${type}-files.zip`,
      })
    );
  };
  const onClickViewNotifications = () => {
    if (projectDetailRef.current) {
      const elem: any = projectDetailRef.current;
      elem.scrollTo({
        top: 10000,
        behavior: "smooth",
      });
    }
  };
  return (
    <Box
      w={{
        base: "100%",
        xl: "calc(100vw - 230px)",
        baseWidth: "calc(100vw - (100vw - 1440px) / 2 - 230px)",
      }}
      ref={projectDetailRef}
      transition="transform 0.3s ease-in-out"
      transform={selectedProjectId ? `translateX(0)` : `translateX(100%)`}
      h="100vh"
      position="fixed"
      bgColor="#FFF"
      zIndex="200"
      boxShadow={selectedProjectId ? "0px 4px 40px rgba(0, 0, 0, 0.1)" : "none"}
      boxSizing="border-box"
      padding={{
        md: "50px 40px 60px",
        base: "70px 20px 30px",
        baseWidth: "50px calc((100vw - 1440px) / 2 + 40px) 50px 60px",
      }}
      top="0px"
      right="0px"
      overflow="auto"
    >
      <Flex minH="100%" h="fit-content" overflow={"auto"} pr="20px" mr="-20px">
        <IconButton
          onClick={(e) => onCloseModal()}
          aria-label="close"
          minW={"unset"}
          h={{ md: "52px", sm: "32px" }}
          w={{ md: "52px", sm: "32px" }}
          borderRadius="50%"
          bg="inherit"
          marginRight={{ md: "60px", sm: "0px", base: "0px" }}
          position={{ md: "unset", sm: "absolute", base: "absolute" }}
          right={{ md: "unset", sm: "30px", base: "30px" }}
          top={{ md: "unset", sm: "10px", base: "10px" }}
          _active={{
            bg: "inherit",
            transform: "none",
            borderColor: "transparent",
          }}
          _focus={{
            boxShadow: "none",
          }}
          icon={
            <IconClose
              h={{ md: "52px", sm: "32px" }}
              w={{ md: "52px", sm: "32px" }}
            />
          }
        />

        <Flex flex="1" direction="column" width="0">
          <Flex direction="row" justifyContent="space-between">
            <Flex
              alignItems="center"
              flexWrap="wrap"
              columnGap={{ md: "60px", sm: "20px" }}
            >
              <Heading
                fontSize="25px"
                lineHeight="135%"
                fontWeight="400"
                color="#000"
              >
                {isFetching ? (
                  <Skeleton height="34px" width="100px"></Skeleton>
                ) : (
                  pad_with_zeroes(
                    selectedProject?.order?.order_id || "undefined",
                    6
                  )
                )}
              </Heading>
              <Flex alignItems="center">
                <Text
                  fontSize="14px"
                  lineHeight="135%"
                  fontWeight="400"
                  color="#000"
                >
                  ID:
                </Text>
                {isFetching ? (
                  <Skeleton height="25px" width="70px" />
                ) : (
                  <Text color="#8E8C8C" marginLeft="10px">
                    {pad_with_zeroes(selectedProject?.order?.order_id, 6)}
                  </Text>
                )}
              </Flex>
            </Flex>
            <UserInfoMenu />
          </Flex>
          <SimpleGrid
            columns={{ base: 1, sm: 1, md: 2, xl: 2 }}
            gap="20px"
            marginTop="50px"
            marginBottom={{ md: "70px", base: "20px" }}
          >
            <Box>
              <Flex
                alignItems="flex-end"
                flexWrap="wrap"
                columnGap="15px"
                rowGap="10px"
              >
                <Card p="0" width="fit-content">
                  <Text
                    fontWeight="400"
                    fontSize="12px"
                    lineHeight="24px"
                    color="#8E8C8C"
                  >
                    Project Manager
                  </Text>
                  <Flex alignItems="center" marginTop="5px">
                    {projectManagers?.length > 0 ? (
                      <Text
                        fontWeight="400"
                        fontSize={{ md: "17px", sm: "14px" }}
                        lineHeight="135%"
                        color="#000"
                      >
                        {projectManagers?.join(", ")}
                      </Text>
                    ) : (
                      <Text
                        fontWeight="400"
                        fontSize={{ md: "17px", sm: "14px" }}
                        lineHeight="135%"
                        color="#000"
                      >
                        Not Assigned
                      </Text>
                    )}
                  </Flex>
                </Card>
                {unreadMessageCnt > 0 && (
                  <Flex
                    h="30px"
                    padding="0px 15px"
                    alignItems="center"
                    // ml="20px"
                    borderRadius="100px"
                    bgColor="#F02828"
                    mb="-3px"
                    whiteSpace="nowrap"
                  >
                    <Text
                      fontSize="12px"
                      lineHeight="12px"
                      fontWeight="700"
                      color="#FFF"
                    >
                      {unreadMessageCnt} New Messages
                    </Text>
                    <Text
                      fontSize="12px"
                      lineHeight="12px"
                      fontWeight="400"
                      color="#FFF"
                      ml="25px"
                      cursor="pointer"
                      onClick={() => onClickViewNotifications()}
                      _hover={{
                        textDecoration: "underline",
                      }}
                    >
                      View
                    </Text>
                  </Flex>
                )}
              </Flex>
              <Flex
                marginTop={{ md: "36px", sm: "20px" }}
                columnGap={{ md: "36px", sm: "15px" }}
                wrap="wrap"
                rowGap="20px"
              >
                {projectMeta
                  ?.filter((it: any) => !_.isNull(it?.value))
                  ?.map((it: any, index: number) => (
                    <Card p="0" w="fit-content" key={index}>
                      <Text
                        fontWeight="400"
                        fontSize="12px"
                        lineHeight="24px"
                        color="#8E8C8C"
                      >
                        {it.label}
                      </Text>

                      {isFetching ? (
                        <Skeleton height="25px" width="70px"></Skeleton>
                      ) : (
                        <Text
                          marginTop="5px"
                          fontWeight="400"
                          fontSize={{ md: "17px", sm: "14px" }}
                          lineHeight="135%"
                          color="#000"
                          textTransform="capitalize"
                          whiteSpace={
                            it?.label === "Address" ? "pre" : "normal"
                          }
                        >
                          {it.value}
                        </Text>
                      )}
                    </Card>
                  ))}
              </Flex>
              {!_.isEmpty(selectedProject?.order?.project_description) && (
                <Box mt="30px">
                  <Text
                    fontWeight="400"
                    fontSize="12px"
                    lineHeight="24px"
                    color="#8E8C8C"
                  >
                    Notes
                  </Text>
                  <Text
                    marginTop="5px"
                    fontWeight="400"
                    fontSize="13px"
                    lineHeight="135%"
                    color="#000"
                    textTransform="capitalize"
                    padding="10px"
                    backgroundColor="#F1F1F1"
                    borderRadius="5px"
                  >
                    {selectedProject?.order?.project_description}
                  </Text>
                </Box>
              )}
              <Flex
                marginTop={{ md: "45px", base: "25px" }}
                columnGap="36px"
                wrap="wrap"
                rowGap="20px"
              >
                <Card
                  p="0"
                  w="fit-content"
                  onClick={() => onDownloadFiles("origin")}
                  cursor="pointer"
                  color="#2F80ED"
                  _hover={{
                    textDecoration: "underline",
                  }}
                >
                  {" "}
                  <IconBox
                    w="40px"
                    h="40px"
                    icon={<IconDownloadOrigin w="24px" h="24px" />}
                  />
                  <Text
                    fontSize="14px"
                    lineHeight="18px"
                    color="#2F80ED"
                    fontWeight="500"
                    marginTop="10px"
                  >
                    Download <br />
                    Original File(s)
                  </Text>
                </Card>
              </Flex>
            </Box>
            <Flex>
              <Card
                p="20px 16px"
                width="260px"
                height="fit-content"
                marginLeft={{ md: "auto", sm: "0px", base: "0px" }}
                marginTop={{ md: "0px", base: "0px" }}
                background="#FFF"
                boxShadow={{
                  md: "0px 4px 50px rgba(0, 0, 0, 0.1)",
                  base: "none",
                }}
                border={{
                  md: "none",
                  base: "1px solid #E9E9E9",
                }}
                borderRadius={{ md: "20px", base: "0px" }}
              >
                <Flex>
                  <Box>
                    <CircularProgress
                      value={getProjectStatusAsPercent(documents)}
                      color="green.400"
                      size="110px"
                      sx={{
                        "svg > circle:first-child": {
                          stroke: "#2F80ED",
                        },
                      }}
                      animation={"ease-in"}
                    >
                      <Flex>
                        <CircularProgressLabel>
                          <Flex justifyContent="center">
                            <Text
                              fontSize="14px"
                              fontWeight="600"
                              color="#2F80ED"
                            >
                              {100 - getProjectStatusAsPercent(documents)}%
                            </Text>
                            <Text
                              fontSize="14px"
                              mx="5px"
                              fontStyle="italic"
                              fontWeight="500"
                            >
                              /
                            </Text>
                            <Text
                              fontSize="14px"
                              fontWeight="600"
                              color="green.400"
                            >
                              {getProjectStatusAsPercent(documents)}%
                            </Text>
                          </Flex>
                        </CircularProgressLabel>
                      </Flex>
                    </CircularProgress>
                  </Box>
                  <Flex flexDir="column" justifyContent="center" ml={2}>
                    <Flex alignItems="center">
                      <Box w="30px" h="10px" bgColor="#2F80ED"></Box>
                      <Text
                        padding="2px 4px"
                        fontSize="10px"
                        lineHeight="135%"
                        fontWeight="400"
                        color="#8E8C8C"
                      >
                        In Progress
                      </Text>
                    </Flex>
                    <Flex alignItems="center">
                      <Box w="30px" h="10px" bgColor="green.400"></Box>
                      <Text
                        padding="2px 4px"
                        fontSize="10px"
                        lineHeight="135%"
                        fontWeight="400"
                        color="#8E8C8C"
                      >
                        Completed
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Card>
            </Flex>
          </SimpleGrid>

          {windowWidth >= 768 ? (
            <TranslatorProjectDetailTable
              isFetching={isFetching}
              tableData={documents}
              onUpdateDocument={onUpdateDocument}
            />
          ) : (
            <TranslatorProjectDetailMobileTable
              isFetching={isFetching}
              tableData={documents}
              onUpdateDocument={onUpdateDocument}
            />
          )}

          <MessageBox projectId={selectedProjectId} />
        </Flex>
      </Flex>
    </Box>
  );
}
