/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import IconBox from "components/icons/IconBox";
import { IconArrowDown } from "components/icons/Icons";
import { useCallback, useEffect, useState } from "react";
import { Bar, Doughnut } from "react-chartjs-2";
import {
  Chart,
  ArcElement,
  Legend,
  Tooltip,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import _ from "lodash";
import { axiosService } from "network/axios";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { format, subDays } from "date-fns";
import { getBarChartData } from "network/helper";
Chart.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
);

export default function RevenueDashboard(props: {
  dashboardType: "individual" | "business";
}) {
  const { dashboardType } = props;
  const boxShadow = "0px 4px 10px rgba(0, 0, 0, 0.1)";
  const [isFetching, setIsFetching] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const periods = [
    {
      value: "daily",
      label: "Daily",
    },
    {
      value: "weekly",
      label: "Weekly",
    },
    {
      value: "monthly",
      label: "Monthly",
    },
  ];

  const [displayedRange, setDisplayedRange] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [selectedDateRange, setSelectedDateRange] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [topRowData, setTopRowData] = useState([
    {
      label: "Total Revenue",
      value: "0",
      checkout: "0",
      portal: "0",
    },
    {
      label: "Period Revenue",
      value: "0",
      checkout: "0",
      portal: "0",
    },
    {
      label: "Last 30d",
      value: "0",
      checkout: "0",
      portal: "0",
    },
    {
      label: "Last 7d",
      value: "0",
      checkout: "0",
      portal: "0",
    },
    {
      label: "Total Orders",
      value: "0",
      checkout: "0",
      portal: "0",
    },
    {
      label: "Period Orders",
      value: "0",
      checkout: "0",
      portal: "0",
    },
    {
      label: "Last 30d",
      value: "0",
      checkout: "0",
      portal: "0",
    },
    {
      label: "Last 7d",
      value: "0",
      checkout: "0",
      portal: "0",
    },
  ]);
  const [translationTypeOrders, setTranslationTypeOrders] = useState(null);
  const [twoDayDeliveryOrders, setTwoDayDeliveryOrders] = useState(null);
  const [expeditedOrders, setExpeditedOrders] = useState(null);
  const [notarizatedOrders, setNotarizatedOrders] = useState(null);
  const [portalCheckoutPlaced, setPortalCheckoutPlaced] = useState(null);

  const [priceChart, setPriceChart] = useState(null);

  const [revenueData, setRevenueData] = useState(null);
  useEffect(() => {
    fetchDashboardData();
  }, [selectedDateRange]);

  const fetchDashboardData = useCallback(async () => {
    try {
      const { startDate, endDate } = selectedDateRange[0];
      setIsFetching(true);
      const payload = {
        start_date: format(new Date(startDate), "yyyy-MM-dd"),
        end_date: format(new Date(endDate), "yyyy-MM-dd"),
        type: dashboardType,
      };
      const { data }: any = await axiosService.post(`/revenue/data`, payload);
      setRevenueData(data);
      setTopRowData([
        {
          label: "Total Revenue",
          value: `$${data?.totalRevenue.toLocaleString()}`,
          checkout: `$${data?.totalCheckoutRevenue.toLocaleString()}`,
          portal: `$${data?.totalPortalRevenue.toLocaleString()}`,
        },
        {
          label: "Period Revenue",
          value: `$${(data?.periodtotalRevenue ?? 0).toLocaleString()}`,
          checkout: `$${data?.periodCheckoutRevenue.toLocaleString()}`,
          portal: `$${data?.periodPortallRevenue.toLocaleString()}`,
        },
        {
          label: "Last 30d",
          value: `$${data?.last30dRevenue.toLocaleString()}`,
          checkout: `$${data?.last30CheckoutRevenue.toLocaleString()}`,
          portal: `$${data?.last30PortalRevenue.toLocaleString()}`,
        },
        {
          label: "Last 7d",
          value: `$${data?.last7dRevenue.toLocaleString()}`,
          checkout: `$${data?.last7dCheckoutRevenue.toLocaleString()}`,
          portal: `$${data?.last7dPortalRevenue.toLocaleString()}`,
        },
        {
          label: "Total Orders",
          value: data?.totalOrders,
          checkout: `${data?.totalCheckoutOrders}`,
          portal: `${data?.totalPortalOrders}`,
        },
        {
          label: "Period Orders",
          value: data?.periodOrders,
          checkout: `${data?.periodCheckoutOrders}`,
          portal: `${data?.periodPortalOrders}`,
        },
        {
          label: "Last 30d",
          value: data?.last30dOrders,
          checkout: `${data?.last30dCheckoutOrders}`,
          portal: `${data?.last30dPortalOrders}`,
        },
        {
          label: "Last 7d",
          value: data?.last7dOrders,
          checkout: `${data?.last7dCheckoutOrders}`,
          portal: `${data?.last7dPortalOrders}`,
        },
      ]);

      // const certifiedProject =
      //   (data?.certifiedOrders / (data?.totalOrders ?? 1)) * 100;

      // const _2DayDeliveryProject =
      //   (data?._2dayDeliveryOrders / (data?.totalOrders ?? 1)) * 100;

      // const expeditedOrdersPercent =
      //   (data?.expediteOrders / (data?.totalOrders ?? 1)) * 100;
      // const notarizatedOrdersPercent =
      //   (data?.notarizedOrders / (data?.totalOrders ?? 1)) * 100;

      setTranslationTypeOrders({
        labels: ["Certified", "Total"],
        datasets: [
          {
            label: "Type of Translation",
            data: [data?.certifiedOrders, data?.totalOrders],
            backgroundColor: [
              "rgba(255, 99, 132, 0.6)",
              "rgba(54, 162, 235, 0.6)",
            ],
            borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
            borderWidth: 1,
          },
        ],
      });

      setTwoDayDeliveryOrders({
        labels: ["2 Day Delivery", "Total"],
        datasets: [
          {
            label: "2 Day Delivery",
            data: [data?._2dayDeliveryOrders, data?.totalOrders],
            backgroundColor: [
              "rgba(255, 99, 132, 0.6)",
              "rgba(54, 162, 235, 0.6)",
            ],
            borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
            borderWidth: 1,
          },
        ],
      });

      setExpeditedOrders({
        labels: ["Expedited", "Total"],
        datasets: [
          {
            label: "Expedited Orders",
            data: [data?.expediteOrders, data?.totalOrders],
            backgroundColor: [
              "rgba(255, 99, 132, 0.6)",
              "rgba(54, 162, 235, 0.6)",
            ],
            borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
            borderWidth: 1,
          },
        ],
      });

      setNotarizatedOrders({
        labels: ["Notarization", "Total"],
        datasets: [
          {
            label: "Notarized Orders",
            data: [data?.notarizedOrders, data?.totalOrders],
            backgroundColor: [
              "rgba(255, 99, 132, 0.6)",
              "rgba(54, 162, 235, 0.6)",
            ],
            borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
            borderWidth: 1,
          },
        ],
      });

      setPortalCheckoutPlaced({
        labels: ["Portal", "External"],
        datasets: [
          {
            label: "Portal/External Orders",
            data: [data?.portalOrders ?? 0, data?.checkoutOrders],
            backgroundColor: [
              "rgba(255, 99, 132, 0.6)",
              "rgba(54, 162, 235, 0.6)",
            ],
            borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
            borderWidth: 1,
          },
        ],
      });
    } catch (err) {
      console.log(err);
    } finally {
      setIsFetching(false);
    }
  }, [selectedDateRange]);

  const [selectedPeriod, setSelectedPeriod] = useState("daily");

  const fetchPeriodData = useCallback(async () => {
    const { startDate, endDate } = selectedDateRange[0];
    const payload = {
      start_date: format(new Date(startDate), "yyyy-MM-dd"),
      end_date: format(new Date(endDate), "yyyy-MM-dd"),
      period: selectedPeriod,
      type: dashboardType,
    };

    const chartData: any = await axiosService.post("/revenue/period", payload);
    const barChartData = getBarChartData(
      chartData?.data?.results,
      startDate,
      endDate,
      selectedPeriod
    );

    setPriceChart({
      labels: barChartData?.map((it) => it?.date),
      datasets: [
        {
          label: `Orders By ${
            selectedPeriod === "daily"
              ? "Day"
              : selectedPeriod === "weekly"
              ? "Week"
              : "Month "
          } ($)`,
          data: barChartData?.map((it) => it?.price),
          backgroundColor: ["rgba(54, 162, 235, 1)"],
          borderColor: ["rgba(54, 162, 235, 1)"],
          borderWidth: 1,
        },
      ],
    });
  }, [selectedPeriod, selectedDateRange]);

  useEffect(() => {
    fetchPeriodData();
  }, [selectedPeriod, selectedDateRange]);

  useEffect(() => {
    if (isOpen === true) {
      setDisplayedRange(selectedDateRange);
    }
  }, [isOpen]);

  const onApplyDateRange = () => {
    setSelectedDateRange(displayedRange);
    onClose();
  };

  return (
    <Box minH="500px">
      <Box
        display="flex"
        columnGap="10px"
        alignItems="center"
        justifyContent="flex-end"
        mb="15px"
      >
        <Text fontSize="15px">
          <>
            {format(new Date(selectedDateRange[0].startDate), "yyyy-MM-dd")} -{" "}
            {format(new Date(selectedDateRange[0].endDate), "yyyy-MM-dd")}
          </>
        </Text>
        <Button
          padding={{ sm: "10px 10px", base: "10px" }}
          border={"2px solid #2F80FD"}
          bgColor="#2F80FD"
          boxSizing="border-box"
          borderRadius="10px"
          fontSize="14px"
          lineHeight="24px"
          fontWeight="400"
          color="#FFF"
          bg="#FFF"
          _hover={{
            bgColor: "#FFF",
            color: "#2F80FD",
          }}
          onClick={() => {
            onOpen();
          }}
        >
          Select DateRange
        </Button>
      </Box>
      {isFetching && (
        <Box
          width="100%"
          height="500px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner />
        </Box>
      )}
      {!isFetching && (
        <>
          <Box
            mb="20px"
            display="flex"
            flexWrap="wrap"
            columnGap="3px"
            rowGap="16px"
            padding="10px"
            borderRadius="10px"
            boxShadow={boxShadow}
            sx={{
              ">*": {
                flexBasis: "calc((100% - 15px) / 4)",
                // flexBasis: "calc((100% - 21px) / 8)",
                "@media (max-width: 1280px)": {
                  flexBasis: "calc((100% - 15px) / 4)",
                },
                "@media (max-width: 960px)": {
                  flexBasis: "calc((100% - 6px) / 2)",
                },
                // "@media (max-width: 500px)": {
                //   flexBasis: "calc((100% - 3px) / 2)",
                // },
              },
            }}
          >
            {topRowData?.map((it, index: number) => (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                rowGap="5px"
                key={index}
              >
                <Box
                  padding="2px"
                  width="100%"
                  boxSizing="border-box"
                  textAlign="center"
                  fontSize="14px"
                  bgColor="#F2F2F2"
                >
                  {it.label}
                </Box>
                <Box textAlign="center" fontSize="22px" fontWeight="600">
                  {it?.value}
                </Box>
                <Box display="flex" columnGap="16px">
                  <Box
                    fontSize="12px"
                    sx={{
                      strong: {
                        fontWeight: 400,
                        // textTransform: "uppercase",
                      },
                      span: {
                        fontWeight: 600,
                        fontSize: "18px",
                      },
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <strong>Checkout</strong>
                    <span>{it.checkout}</span>
                  </Box>
                  <Box
                    fontSize="12px"
                    sx={{
                      strong: {
                        fontWeight: 400,
                        // textTransform: "uppercase",
                      },
                      span: {
                        fontWeight: 600,
                        fontSize: "18px",
                      },
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <strong>Portal</strong>
                    <span>{it.portal}</span>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>

          <Grid
            templateColumns={{ lg: "repeat(2, 1fr)", base: "repeat(1, 1fr)" }}
            gap="20px"
          >
            <GridItem w="100%">
              <Box padding="20px" boxShadow={boxShadow} borderRadius="10px">
                <Text fontWeight="600">Top Customers By Value</Text>
                <Table mt="20px" fontSize="14px">
                  <Thead>
                    <Th
                      padding="5px"
                      whiteSpace="nowrap"
                      backgroundColor="#F2F2F2"
                    >
                      Customer
                    </Th>
                    <Th
                      padding="5px"
                      whiteSpace="nowrap"
                      backgroundColor="#F2F2F2"
                    >
                      #Orders
                    </Th>
                    <Th
                      padding="5px"
                      whiteSpace="nowrap"
                      backgroundColor="#F2F2F2"
                    >
                      Value
                    </Th>
                  </Thead>
                  <Tbody>
                    {revenueData?.topCustomersByValue?.map(
                      (it: any, index: number) => (
                        <Tr key={index}>
                          <Td padding="5px">{`${it?.first_name} ${it?.last_name}`}</Td>
                          <Td padding="5px">{it?.cnt}</Td>
                          <Td padding="5px">{`$${it?.total}`}</Td>
                        </Tr>
                      )
                    )}
                  </Tbody>
                </Table>
              </Box>
            </GridItem>
            <GridItem w="100%">
              <Box padding="20px" boxShadow={boxShadow} borderRadius="10px">
                <Text fontWeight="600">Top Customers By Volume</Text>
                <Table mt="20px" fontSize="14px">
                  <Thead>
                    <Th
                      padding="5px"
                      whiteSpace="nowrap"
                      backgroundColor="#F2F2F2"
                    >
                      Customer
                    </Th>
                    <Th
                      padding="5px"
                      whiteSpace="nowrap"
                      backgroundColor="#F2F2F2"
                    >
                      #Orders
                    </Th>
                    <Th
                      padding="5px"
                      whiteSpace="nowrap"
                      backgroundColor="#F2F2F2"
                    >
                      Value
                    </Th>
                  </Thead>
                  <Tbody>
                    {revenueData?.topCustomersByVolume?.map(
                      (it: any, index: number) => (
                        <Tr key={index}>
                          <Td padding="5px">{`${it?.first_name} ${it?.last_name}`}</Td>
                          <Td padding="5px">{it?.cnt}</Td>
                          <Td padding="5px">{`$${it?.total}`}</Td>
                        </Tr>
                      )
                    )}
                  </Tbody>
                </Table>
              </Box>
            </GridItem>
            <GridItem w="100%">
              <Box
                display="flex"
                columnGap="20px"
                flexWrap="wrap"
                padding="20px"
                boxShadow={boxShadow}
                borderRadius="10px"
              >
                <Box
                  sx={{
                    flexBasis: "calc(50% - 10px)",
                    "@media (max-width: 560px)": {
                      flexBasis: "100%",
                    },
                  }}
                  boxSizing="border-box"
                >
                  <Table fontSize="14px">
                    <Thead>
                      <Th
                        colSpan={2}
                        padding="5px"
                        whiteSpace="nowrap"
                        backgroundColor="#F2F2F2"
                        textAlign="center"
                      >
                        Top Languages From
                      </Th>
                    </Thead>
                    <Tbody>
                      {_.map(
                        revenueData?.topFromLanguage,
                        (it: any, key: string) => (
                          <Tr key={key}>
                            <Td
                              padding="5px"
                              sx={{
                                textAlign: "center",
                                "@media (max-width: 560px)": {
                                  textAlign: "left",
                                },
                              }}
                            >
                              {key}
                            </Td>
                            <Td padding="5px" textAlign="center">
                              {it}
                            </Td>
                          </Tr>
                        )
                      )}
                    </Tbody>
                  </Table>
                </Box>
                <Box
                  sx={{
                    flexBasis: "calc(50% - 10px)",
                    "@media (max-width: 560px)": {
                      flexBasis: "100%",
                    },
                  }}
                  boxSizing="border-box"
                >
                  <Table fontSize="14px">
                    <Thead>
                      <Th
                        colSpan={2}
                        padding="5px"
                        whiteSpace="nowrap"
                        backgroundColor="#F2F2F2"
                        textAlign="center"
                      >
                        Top Languages To
                      </Th>
                    </Thead>
                    <Tbody>
                      {_.map(
                        revenueData?.topToLanguages,
                        (it: any, key: string) => (
                          <Tr key={key}>
                            <Td
                              padding="5px"
                              sx={{
                                textAlign: "center",
                                "@media (max-width: 560px)": {
                                  textAlign: "left",
                                },
                              }}
                            >
                              {key}
                            </Td>
                            <Td padding="5px" textAlign="center">
                              {it}
                            </Td>
                          </Tr>
                        )
                      )}
                    </Tbody>
                  </Table>
                </Box>
                <Box
                  sx={{
                    flexBasis: "calc(50% - 10px)",
                    "@media (max-width: 560px)": {
                      flexBasis: "100%",
                    },
                  }}
                  boxSizing="border-box"
                >
                  <Table mt="20px" fontSize="14px">
                    <Thead>
                      <Th
                        colSpan={2}
                        padding="5px"
                        whiteSpace="nowrap"
                        backgroundColor="#F2F2F2"
                        textAlign="center"
                      >
                        Bottom Languages From
                      </Th>
                    </Thead>
                    <Tbody>
                      {_.map(
                        revenueData?.bottomFromLanguage,
                        (it: any, key: string) => (
                          <Tr key={key}>
                            <Td
                              padding="5px"
                              sx={{
                                textAlign: "center",
                                "@media (max-width: 560px)": {
                                  textAlign: "left",
                                },
                              }}
                            >
                              {key}
                            </Td>
                            <Td padding="5px" textAlign="center">
                              {it}
                            </Td>
                          </Tr>
                        )
                      )}
                    </Tbody>
                  </Table>
                </Box>
                <Box
                  sx={{
                    flexBasis: "calc(50% - 10px)",
                    "@media (max-width: 560px)": {
                      flexBasis: "100%",
                    },
                  }}
                  boxSizing="border-box"
                >
                  <Table mt="20px" fontSize="14px">
                    <Thead>
                      <Th
                        colSpan={2}
                        padding="5px"
                        whiteSpace="nowrap"
                        backgroundColor="#F2F2F2"
                        textAlign="center"
                      >
                        Bottom Languages To
                      </Th>
                    </Thead>
                    <Tbody>
                      {_.map(
                        revenueData?.bottomToLanguages,
                        (it: any, key: string) => (
                          <Tr key={key}>
                            <Td
                              padding="5px"
                              sx={{
                                textAlign: "center",
                                "@media (max-width: 560px)": {
                                  textAlign: "left",
                                },
                              }}
                            >
                              {key}
                            </Td>
                            <Td padding="5px" textAlign="center">
                              {it}
                            </Td>
                          </Tr>
                        )
                      )}
                    </Tbody>
                  </Table>
                </Box>
              </Box>
            </GridItem>
            <GridItem w="100%">
              <Box
                padding="20px"
                boxShadow={boxShadow}
                borderRadius="10px"
                height="full"
                display="flex"
                flexDirection="column"
              >
                <Box
                  display="flex"
                  columnGap="10px"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Menu>
                    <MenuButton
                      padding={"10px 20px"}
                      backgroundColor="#FFF"
                      borderRadius="10px"
                      minW="120px"
                      height="40px"
                      border="1px solid #E9E9E9"
                    >
                      <Flex alignItems={"center"}>
                        <Text
                          fontSize="14px"
                          lineHeight="24px"
                          fontWeight="400"
                          color="black"
                          textTransform="capitalize"
                        >
                          {selectedPeriod}
                        </Text>
                        <IconBox
                          marginLeft="auto"
                          icon={<IconArrowDown width="24px" height="24px" />}
                        ></IconBox>
                      </Flex>
                    </MenuButton>
                    <MenuList
                      border="1px solid #E9E9E9"
                      p="0px"
                      borderRadius="10px"
                      bg={"#FFF"}
                      zIndex="22"
                    >
                      <Flex
                        flexDirection="column"
                        p="10px 20px"
                        fontSize={"14px"}
                        lineHeight="24px"
                        fontWeight={"400"}
                        color="black"
                      >
                        {periods.map((period, index: number) => (
                          <MenuItem
                            key={index}
                            _hover={{ bg: "none" }}
                            _focus={{ bg: "none" }}
                            borderRadius="8px"
                            padding="10px 0px"
                            borderBottom={
                              index < periods.length - 1
                                ? "1px solid #F3F5F5"
                                : ""
                            }
                            onClick={() => {
                              setSelectedPeriod(period.value);
                            }}
                          >
                            <Text fontSize="sm">{period.label}</Text>
                          </MenuItem>
                        ))}
                      </Flex>
                    </MenuList>
                  </Menu>
                </Box>
                <Box
                  mt="20px"
                  height="100%"
                  overflow="auto"
                  sx={{
                    canvas: {
                      height: "100% !important",
                      maxWidth: "100% !important",
                      "@media (max-width: 959px)": {
                        minHeight: "450px",
                      },
                    },
                  }}
                >
                  {priceChart && (
                    <Bar
                      data={priceChart}
                      height="100%"
                      options={{
                        responsive: true,
                        maintainAspectRatio: false,
                      }}
                    />
                  )}
                </Box>
              </Box>
            </GridItem>
          </Grid>
          <Box mt="20px" width="100%" position="relative">
            <Flex
              flexWrap="wrap"
              justifyContent="center"
              columnGap="20px"
              rowGap="20px"
              sx={{
                ">*": {
                  flexBasis: "calc((100% - 80px) / 3)",
                  "@media (max-width: 959px)": {
                    flexBasis: "calc((100% - 20px) / 2)",
                  },
                  "@media (max-width: 500px)": {
                    flexBasis: "100%",
                  },
                  padding: "30px 15px",
                  borderRadius: "10px",
                  overflow: "auto",
                  canvas: {
                    width: "100% !important",
                    maxWidth: "100% !important",
                  },
                },
              }}
            >
              <Box
                w="100%"
                display="flex"
                flexDir="column"
                alignItems="center"
                boxShadow={boxShadow}
                p={2}
              >
                <Text
                  textAlign="center"
                  mb="30px"
                  fontSize="14px"
                  textTransform="uppercase"
                  color="#888"
                  fontWeight="700"
                >
                  Portal vs External Orders
                </Text>
                <Box w="100%">
                  {portalCheckoutPlaced && (
                    <Doughnut
                      data={portalCheckoutPlaced}
                      options={{
                        responsive: true,
                        layout: {
                          autoPadding: true,
                        },
                        maintainAspectRatio: false,
                        plugins: {
                          legend: {
                            position: "bottom",
                          },
                        },
                      }}
                    />
                  )}
                </Box>
              </Box>
              <Box
                w="100%"
                display="flex"
                flexDir="column"
                alignItems="center"
                boxShadow={boxShadow}
                p={2}
              >
                <Text
                  textAlign="center"
                  mb="30px"
                  fontSize="14px"
                  textTransform="uppercase"
                  color="#888"
                  fontWeight="700"
                >
                  Type of Translation
                </Text>
                <Box w="100%">
                  {translationTypeOrders && (
                    <Doughnut
                      data={translationTypeOrders}
                      options={{
                        responsive: true,
                        layout: {
                          autoPadding: true,
                        },
                        maintainAspectRatio: false,
                        plugins: {
                          legend: {
                            position: "bottom",
                          },
                        },
                      }}
                    />
                  )}
                </Box>
              </Box>
              <Box
                w="100%"
                display="flex"
                flexDir="column"
                alignItems="center"
                boxShadow={boxShadow}
                p={2}
              >
                <Text
                  textAlign="center"
                  mb="30px"
                  fontSize="14px"
                  textTransform="uppercase"
                  color="#888"
                  fontWeight="700"
                >
                  Two Day Delivery
                </Text>
                <Box w="100%">
                  {twoDayDeliveryOrders && (
                    <Doughnut
                      data={twoDayDeliveryOrders}
                      options={{
                        responsive: true,
                        layout: {
                          autoPadding: true,
                        },
                        maintainAspectRatio: false,
                        plugins: {
                          legend: {
                            position: "bottom",
                          },
                        },
                      }}
                    />
                  )}
                </Box>
              </Box>
              <Box
                w="100%"
                display="flex"
                flexDir="column"
                alignItems="center"
                boxShadow={boxShadow}
                p={2}
              >
                <Text
                  textAlign="center"
                  mb="30px"
                  fontSize="14px"
                  textTransform="uppercase"
                  color="#888"
                  fontWeight="700"
                >
                  Expedited Orders
                </Text>
                <Box w="100%">
                  {expeditedOrders && (
                    <Doughnut
                      data={expeditedOrders}
                      options={{
                        responsive: true,
                        layout: {
                          autoPadding: true,
                        },
                        // aspectRatio: 2,
                        maintainAspectRatio: false,
                        plugins: {
                          legend: {
                            position: "bottom",
                          },
                        },
                      }}
                    />
                  )}
                </Box>
              </Box>
              <Box
                w="100%"
                display="flex"
                flexDir="column"
                alignItems="center"
                boxShadow={boxShadow}
                p={2}
              >
                <Text
                  textAlign="center"
                  mb="30px"
                  fontSize="14px"
                  textTransform="uppercase"
                  color="#888"
                  fontWeight="700"
                >
                  Notarization
                </Text>
                <Box w="100%">
                  {notarizatedOrders && (
                    <Doughnut
                      data={notarizatedOrders}
                      options={{
                        responsive: true,
                        layout: {
                          autoPadding: true,
                        },
                        maintainAspectRatio: false,
                        plugins: {
                          legend: {
                            position: "bottom",
                          },
                        },
                      }}
                    />
                  )}
                </Box>
              </Box>
            </Flex>
          </Box>
          <Modal size="md" isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent
              sx={{
                maxWidth: "600px",
              }}
            >
              <ModalHeader
                fontSize="17px"
                lineHeight="24px"
                fontWeight="700"
                color="#000"
              >
                Select Date Range
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody pb="35px">
                <DateRangePicker
                  onChange={(item: any) => {
                    setDisplayedRange([item?.selection]);
                  }}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={displayedRange}
                  maxDate={new Date()}
                />
              </ModalBody>
              <ModalFooter borderTop="1px solid #EFEFEF">
                <Button
                  padding={{ sm: "10px 10px", base: "10px" }}
                  border={"2px solid #2F80FD"}
                  bgColor="#2F80FD"
                  boxSizing="border-box"
                  borderRadius="5px"
                  fontSize="14px"
                  lineHeight="24px"
                  fontWeight="400"
                  color="#FFF"
                  bg="#FFF"
                  width="100px"
                  onClick={() => {
                    onApplyDateRange();
                  }}
                  _hover={{
                    bgColor: "#FFF",
                    color: "#2F80FD",
                  }}
                >
                  Apply
                </Button>
                <Button
                  padding={{ sm: "10px 10px", base: "10px" }}
                  border={"2px solid #EFEFEF"}
                  bgColor="transparent"
                  boxSizing="border-box"
                  borderRadius="5px"
                  fontSize="14px"
                  lineHeight="24px"
                  ml="10px"
                  fontWeight="400"
                  color="#333"
                  bg="#FFF"
                  width="100px"
                  _hover={{
                    bgColor: "#EFEFEF",
                  }}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      )}
    </Box>
  );
}
