import { Flex } from "@chakra-ui/react";
import { VSeparator } from "components/separator/Separator";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTeamUsersRequest } from "store/user/reducer";
import { userSelector } from "store/user/selector";
import BusinessInformationForm from "./BusinessInformationForm";
import Collaborators from "./Collaborators";

export default function IndividualTeam() {
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      fetchTeamUsersRequest({
        user_id: user.user_id,
      })
    );
  }, []);
  return (
    <Flex direction="column">
      <Collaborators />
      <VSeparator bgColor="#E3E3E3" my="35px" w="full" h="1px" />
      <BusinessInformationForm />
    </Flex>
  );
}
