import { Icon } from "@chakra-ui/react";
import { MdLock } from "react-icons/md";

// Auth Imports
import SignIn from "views/auth/signIn";
import SignUp from "views/auth/signup";
import {
  IconCustomers,
  IconDashboard,
  IconRewards,
  IconServices,
  IconTeam,
  IconUnfollowedUser,
} from "components/icons/Icons";

import Teams from "views/admin/teams";
import Customers from "views/admin/customers";
import Projects from "views/admin/projects";
import TranslatorProjects from "views/translator/projects";
import ManagerProjects from "views/manager/projects";
import OrderIndex from "views/customer/order";
import CustomerProjects from "views/customer/projects";
import Services from "views/customer/services";
import Rewards from "views/customer/rewards";
import OrderCompleted from "views/customer/order/order-completed";
import Verify from "views/auth/verify";
import Forgot from "views/auth/forgot";
import Reset from "views/auth/reset";
import UnfinishedUsers from "views/admin/unfinishedUsers";
import SetPassword from "views/auth/set";
import BusinessProfile from "views/auth/business";
import AdminDashboard from "views/admin/dashboard";
import CustomerDashboard from "views/customer/dashboard";
import ManagerDashboard from "views/manager/dashboard";
// import RevenueDashboard from "views/admin/dashboard/revenue";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/dashboard",
    icon: <IconDashboard h="25px" w="24px" />,
    component: AdminDashboard,
  },
  // {
  //   name: "Revenue Dashboard",
  //   layout: "/admin",
  //   path: "/revenue/dashboard",
  //   icon: <IconDashboard h="25px" w="24px" />,
  //   component: RevenueDashboard,
  // },
  {
    name: "Team",
    layout: "/admin",
    path: "/team",
    icon: <IconTeam h="25px" w="24px" />,
    component: Teams,
  },
  {
    name: "Projects",
    layout: "/admin",
    path: "/projects",
    icon: <IconTeam h="25px" w="24px" />,
    component: Projects,
  },
  {
    name: "Customers",
    layout: "/admin",
    path: "/customers",
    icon: <IconCustomers h="25px" w="24px" />,
    component: Customers,
  },
  {
    name: "Unfinished Users",
    layout: "/admin",
    path: "/unfinished-users",
    icon: <IconUnfollowedUser h="23px" w="24px" />,
    component: UnfinishedUsers,
  },

  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignIn,
  },
  {
    name: "Verify",
    layout: "/auth",
    path: "/verify",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: Verify,
  },
  {
    name: "Forgot",
    layout: "/auth",
    path: "/forgot/",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: Forgot,
  },
  {
    name: "Forgot",
    layout: "/auth",
    path: "/reset-password/",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: Reset,
  },
  {
    name: "BusinessProfile",
    layout: "/auth",
    path: "/create-profile/",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: BusinessProfile,
  },
  {
    name: "Set",
    layout: "/auth",
    path: "/set-password/",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SetPassword,
  },
  {
    name: "Sign Up",
    layout: "/auth",
    path: "/sign-up",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignUp,
  },
  {
    name: "Projects",
    layout: "/translator",
    path: "/projects",
    icon: <IconTeam h="25px" w="24px" />,
    component: TranslatorProjects,
  },
  // Translator Router
  {
    name: "Dashboard",
    layout: "/manager",
    path: "/dashboard",
    icon: <IconDashboard h="25px" w="24px" />,
    component: ManagerDashboard,
  },
  {
    name: "Projects",
    layout: "/manager",
    path: "/projects",
    icon: <IconTeam h="25px" w="24px" />,
    component: ManagerProjects,
  },
  {
    name: "Services",
    layout: "/manager",
    path: "/services",
    icon: <IconServices h="25px" w="24px" />,
    component: Services,
  },
  // Customers Router
  {
    name: "Dashboard",
    layout: "/customer",
    path: "/dashboard",
    icon: <IconDashboard h="25px" w="24px" />,
    component: CustomerDashboard,
  },
  {
    name: "Dashboard",
    layout: "/customer",
    path: "/main",
    hidden: true,
    icon: <IconDashboard h="25px" w="24px" />,
    component: OrderIndex,
  },
  {
    name: "Projects",
    layout: "/customer",
    path: "/projects",
    icon: <IconTeam h="25px" w="24px" />,
    component: CustomerProjects,
  },
  {
    name: "Services",
    layout: "/customer",
    path: "/services",
    icon: <IconServices h="25px" w="24px" />,
    component: Services,
  },
  {
    name: "Rewards",
    layout: "/customer",
    path: "/rewards",
    icon: <IconRewards h="25px" w="24px" />,
    component: Rewards,
  },
  {
    name: "Orders",
    layout: "/customer",
    path: "/order/:orderId",
    icon: <IconRewards h="25px" w="24px" />,
    component: OrderIndex,
    hidden: true,
  },
  {
    name: "OrdersCompleted",
    layout: "/customer",
    path: "/order/completed/:orderId",
    icon: <IconRewards h="25px" w="24px" />,
    component: OrderCompleted,
    hidden: true,
  },
];

export default routes;
