import { TimeIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  IconButton,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
// Custom components
import Card from "components/card/Card";
import {
  IconArrowRight,
  IconChecked,
  IconTranslator,
  IconWarning,
} from "components/icons/Icons";
import moment from "moment-timezone";
import {
  fetchLanguagesExcerpt,
  fetchUnreadMessagesCnt,
  pad_with_zeroes,
} from "network/helper";
import * as React from "react";
import { useSelector } from "react-redux";
import { unreadMessageSelector } from "store/project/selector";

type RowObj = {
  id: string;
  order_id: string;
  order_name: string;
  order_due_at: string;
  order_created_at: string;
  order_status_name: string;
  languages: string;
  translated_from: string;
  translated_to: string;
  translators: any[];
  notificationType: 0 | 1 | 2 | 3 | 4 | 5;
  message_cnt: number;
  actionCol: string;
  include_expedited_turnaround: string;
  order_status_id: string;
};

const columnHelper = createColumnHelper<RowObj>();

export default function ManagerProjectsTable(props: {
  tableData: any;
  isFetching?: boolean;
  onShowProjectDetails: Function;
}) {
  const { tableData, isFetching, onShowProjectDetails } = props;
  React.useEffect(() => {
    const hash = window.location.hash ?? null;
    if (hash) {
      const projectId = hash.replace("#", "");
      onShowProjectDetails(projectId);
    }
  }, []);
  const textColor = useColorModeValue("black", "black");
  const unreadMessages = useSelector(unreadMessageSelector);
  console.log(tableData);
  let defaultData = tableData;
  const mobileColumnWidths: any = {
    id: 100,
    dueDate: 120,
    status: 230,
    languages: 160,
    manager: 60,
    translator: 200,
    actionCol: 80,
  };
  const columns = [
    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Project #
        </Text>
      ),
      cell: (info: any) => {
        return (
          <Flex align="center">
            <Text
              color={info.row.getValue("id") === "0012" ? "#F00" : textColor}
              fontSize="14px"
              lineHeight="24px"
              fontWeight="400"
              maxW={{ sm: "200px", md: "unset" }}
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
            >
              {pad_with_zeroes(info.getValue(), 6)}
            </Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor("order_created_at", {
      id: "order_created_at",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Order Date
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
          >
            {info.getValue()
              ? moment(new Date(info.getValue())).format("MM/DD/YY")
              : "-"}
          </Text>
        </Flex>
      ),
    }),

    columnHelper.accessor("order_due_at", {
      id: "order_due_at",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Due Date
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
          >
            {info.getValue()
              ? moment(new Date(info.getValue())).format("MM/DD/YY")
              : "-"}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("order_status_name", {
      id: "order_status_name",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Status
        </Text>
      ),
      cell: (info) => (
        <Text
          color={textColor}
          fontSize="14px"
          lineHeight="24px"
          fontWeight="400"
          minWidth="50px"
          width="fit-content"
          height={"34px"}
          borderRadius="100px"
          padding="5px 20px"
          bgColor="#F3F5F5"
          textTransform="capitalize"
          whiteSpace="nowrap"
        >
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("languages", {
      id: "languages",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Languages
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Flex
            color={textColor}
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
            alignItems={"center"}
          >
            {info.row.original?.translated_from &&
              info.row.original?.translated_to && (
                <React.Fragment>
                  {fetchLanguagesExcerpt(info.row.original?.translated_from)}
                  <IconTranslator width="15px" height="16px" mx="4px" />
                  {fetchLanguagesExcerpt(info.row.original?.translated_to)}
                </React.Fragment>
              )}
          </Flex>
        </Flex>
      ),
    }),
    columnHelper.accessor("translators", {
      id: "translators",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Translators
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          {info.getValue()?.length > 0 ? (
            <IconChecked width="25px" height="25px" />
          ) : (
            <IconWarning width="20px" height="19px" marginRight="12px" />
          )}
        </Flex>
      ),
    }),
    columnHelper.accessor("message_cnt", {
      id: "message_cnt",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        ></Text>
      ),
      cell: (info) => (
        <Flex align="center">
          {fetchUnreadMessagesCnt(
            unreadMessages,
            Number(info.row.original.id)
          ) > 0 && (
            <Text
              color="#FFF"
              bgColor="#F02828"
              borderRadius="100px"
              fontSize="14px"
              lineHeight="24px"
              fontWeight="400"
              padding="5px 20px"
              whiteSpace="nowrap"
            >
              {`${fetchUnreadMessagesCnt(
                unreadMessages,
                Number(info.row.original.id)
              )} New Message`}
            </Text>
          )}
        </Flex>
      ),
    }),
    columnHelper.accessor("actionCol", {
      id: "actionCol",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          color="gray.500"
          fontWeight="400"
        ></Text>
      ),
      cell: (info) => (
        <Flex align="center" width={"40px"} mr="0">
          <IconButton
            aria-label="search"
            bg="inherit"
            borderRadius="inherit"
            onClick={(e) => {
              onShowProjectDetails(info.row.original.order_id);
            }}
            _active={{
              bg: "inherit",
              transform: "none",
              borderColor: "transparent",
            }}
            _hover={{
              bg: "inherit",
            }}
            _focus={{
              boxShadow: "none",
            }}
            icon={<IconArrowRight w="49px" h="50px" />}
          />

          {Number(info.row.original.include_expedited_turnaround) === 1 &&
            Number(info.row.original.order_status_id) !== 6 &&
            Number(info.row.original.order_status_id) !== 10 && (
              <Tooltip label="Expedited Order">
                <TimeIcon
                  w="20px"
                  h="20px"
                  color="#F02828"
                  sx={{
                    position: "absolute",
                    right: "-8px",
                    top: "-8px",
                    borderRadius: "50%",
                    bgColor: "#FFF",
                  }}
                />
              </Tooltip>
            )}
        </Flex>
      ),
    }),
  ];
  const [data, setData] = React.useState(() => [...defaultData]);
  React.useEffect(() => {
    setData([...defaultData]);
  }, [defaultData]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  return (
    <Card
      flexDirection="column"
      w="calc(100% + 20px)"
      p="0px"
      px="10px"
      ml="-10px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Box>
        <Table
          variant="simple"
          color="gray.500"
          mb="24px"
          pb="2"
          lineHeight="15px"
        >
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      px={{ lg: "20px", md: "10px", base: "10px" }}
                      border={"none"}
                      fontFamily="Inter"
                      cursor="pointer"
                      textTransform={"capitalize"}
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        lineHeight="24px"
                        color="gray.500"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {!isFetching &&
              table.getRowModel().rows.map((row) => {
                return (
                  <React.Fragment key={row.id}>
                    <Tr
                      position="relative"
                      cursor="pointer"
                      zIndex={1}
                      onClick={() => {
                        onShowProjectDetails(row.original.order_id);
                      }}
                      _after={{
                        content: '""',
                        position: "absolute",
                        // border: "1px solid #DCDCDC",
                        border:
                          Number(row.original.include_expedited_turnaround) ===
                            1 &&
                          Number(row.original.order_status_id) !== 6 &&
                          Number(row.original.order_status_id) !== 10
                            ? "1px solid #EE000055"
                            : "1px solid #DCDCDC",
                        bgColor:
                          Number(row.original.include_expedited_turnaround) ===
                            1 &&
                          Number(row.original.order_status_id) !== 6 &&
                          Number(row.original.order_status_id) !== 10
                            ? "#EE000022"
                            : "transparent",
                        borderRadius: "10px",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: "-1",
                        transition: "all .25s ease-in-out",
                      }}
                      _hover={{
                        _after: {
                          backgroundColor:
                            Number(
                              row.original.include_expedited_turnaround
                            ) === 1 &&
                            Number(row.original.order_status_id) !== 6 &&
                            Number(row.original.order_status_id) !== 10
                              ? "#EE000020"
                              : "#F1F1F1",
                          borderColor:
                            Number(
                              row.original.include_expedited_turnaround
                            ) === 1 &&
                            Number(row.original.order_status_id) !== 6 &&
                            Number(row.original.order_status_id) !== 10
                              ? "#EE000055"
                              : "#F1F1F1",
                          boxShadow: "0px 2px 6px 3px rgba(0, 0, 0, 0.1)",
                        },
                      }}
                      _active={{
                        _after: {
                          boxShadow: "none",
                        },
                      }}
                    >
                      {row.getVisibleCells().map((cell, index: number) => {
                        return (
                          <Td
                            padding={{
                              lg: "20px",
                              md: "20px 10px",
                              base: "20px 10px",
                            }}
                            key={cell.id}
                            fontSize={{ sm: "14px" }}
                            minW={{
                              base: `${mobileColumnWidths[cell.column.id]}px`,
                              md: `${mobileColumnWidths[cell.column.id]}px`,
                              lg: "auto",
                            }}
                            border="none"
                            width={
                              cell.column.id === "actionCol" ? "80px" : "auto"
                            }
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Td>
                        );
                      })}
                    </Tr>
                    <Tr>
                      <Td py="2" border="none"></Td>
                    </Tr>
                  </React.Fragment>
                );
              })}
            {isFetching &&
              [1, 2, 3, 4, 5].map((row) => {
                return (
                  <React.Fragment key={`skeleton-row-${row}`}>
                    <Tr
                      zIndex="1"
                      position="relative"
                      _after={{
                        content: '""',
                        position: "absolute",
                        border: "1px solid #DCDCDC",
                        borderRadius: "10px",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: "-1",
                        transition: "all .25s ease-in-out",
                      }}
                    >
                      {columns.map((it: any, colIndex: number) => {
                        return (
                          <Td
                            key={`skeleton-${colIndex}`}
                            padding={{
                              lg: "20px",
                              md: "20px 10px",
                              base: "15px 10px",
                            }}
                            border="none !important"
                          >
                            <Skeleton
                              height="25px"
                              colorScheme="#F3F5F5"
                              borderRadius="5px"
                            ></Skeleton>
                          </Td>
                        );
                      })}
                    </Tr>
                    <Tr>
                      <Td
                        colSpan={7}
                        paddingTop="5px"
                        paddingBottom="10px"
                        border="none !important"
                      ></Td>
                    </Tr>
                  </React.Fragment>
                );
              })}
          </Tbody>
        </Table>
      </Box>
    </Card>
  );
}
