/* eslint-disable react-hooks/exhaustive-deps */
import {
  Badge,
  Box,
  Spinner,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useWindowWidth } from "@react-hook/window-size";
import CustomPaginations from "components/pagination/CustomPaginations";
import SortingHandler from "components/sortBox/SortingHandler";
import { axiosService } from "network/axios";
import {
  fetchUnreadMessagesByStatus,
  generateQueryParameters,
} from "network/helper";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  isUpdatingDocumentSelector,
  unreadMessageSelector,
} from "store/project/selector";
import { searchQuerySelector } from "store/ui/selector";
import { userSelector } from "store/user/selector";
import ProjectDetail from "./components/projectDetail";
import ProjectMobileTable from "./components/projectsMobileTable";
import ProjectsTable from "./components/projectsTable";

export default function Projects() {
  const searchQuery = useSelector(searchQuerySelector);
  const [prevSearchQuery, setPrevSearchQuery] = useState(null);
  const user = useSelector(userSelector);
  const isUpdatingDocuments = useSelector(isUpdatingDocumentSelector);
  const windowWidth = useWindowWidth();
  const unreadMessages = useSelector(unreadMessageSelector);
  const [selectedOption, setSelectedOption] = useState<{
    key: string;
    label: string;
    dir: "desc" | "asc";
  }>({
    key: "updated_at",
    label: "Last Updated",
    dir: "desc",
  });
  const sortingOptions = [
    {
      key: "updated_at",
      label: "Last Updated",
    },
    {
      key: "order_due_at",
      label: "Due Date",
    },
    {
      key: "order_status_id",
      label: "Status",
    },
    {
      key: "order_id",
      label: "Project",
    },
  ];
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(20);
  const [pageLimit, setPageLimit] = useState(10);
  const [isFetching, setIsFetching] = useState(false);
  const [results, setResults] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const queryParam = useMemo(() => {
    const status =
      tabIndex === 0
        ? "new"
        : tabIndex === 1
        ? "active"
        : tabIndex === 2
        ? "due_soon"
        : tabIndex === 3
        ? "review"
        : "completed";
    if (prevSearchQuery !== searchQuery) {
      setPrevSearchQuery(searchQuery);
      setCurrentPage(1);
      return {
        status: status,
        page: 1,
        limit: pageLimit,
        keyword: searchQuery ?? "",
        order_by: selectedOption?.key,
        dir: selectedOption?.dir,
      };
    }
    return {
      status: status,
      page: currentPage,
      limit: pageLimit,
      keyword: searchQuery ?? "",
      order_by: selectedOption?.key,
      dir: selectedOption?.dir,
    };
  }, [tabIndex, currentPage, pageLimit, searchQuery, selectedOption]);

  const fetchResults = useCallback(async () => {
    setIsFetching(true);
    const params = generateQueryParameters(queryParam);
    try {
      const resp: any = await axiosService.get(`/projects/all?${params}`);
      const { results, total_rows } = resp?.data;
      setResults(
        results?.map((it: any) => ({
          ...it,
          id: it?.order_id,
        }))
      );

      setTotalPages(
        total_rows / pageLimit > Math.floor(total_rows / pageLimit)
          ? Math.floor(total_rows / pageLimit) + 1
          : Math.floor(total_rows / pageLimit)
      );
      setTotalRows(total_rows);
    } catch (err) {
      setResults([]);
    } finally {
      setIsFetching(false);
    }
  }, [queryParam]);

  useEffect(() => {
    fetchResults();
  }, [queryParam]);

  const showDetailsProductDetails = (projectId: string) => {
    setSelectedProjectId(projectId);
  };

  const {
    newMessagesCnt,
    activeMessagesCnt,
    dueSoonMessagesCnt,
    reviewMessagesCnt,
    completeMessagesCnt,
  } = fetchUnreadMessagesByStatus(unreadMessages);


  return (
    <Box pt={{ base: "60px", md: "80px", xl: "80px" }}>
      {Number(user?.user_role_type_id) !== 7 ? (
        <Tabs
          variant="unstyled"
          onChange={(index) => {
            setCurrentPage(1);
            setTabIndex(index);
          }}
        >
          <TabList borderBottom={"2px solid #E9E9E9"}>
            <Tab
              padding={{ md: "14px 25px 14px 0px", sm: "10px 10px 10px 0px" }}
              fontSize={{ md: "17px", sm: "14px" }}
              lineHeight={{ md: "24px", sm: "22px" }}
              fontWeight="400"
              alignItems="center"
              _selected={{ color: "blue.500" }}
              _focus={{ boxShadow: "none" }}
            >
              New{" "}
              {newMessagesCnt > 0 && (
                <Badge
                  bgColor={"#F02828"}
                  sx={{
                    padding: "9px 6px !important",
                    borderRadius: "1000px",
                    fontWeight: "700",
                    fontSize: "10px",
                    lineHeight: "0px",
                    color: "#FFF",
                    marginLeft: "7px",
                    minWidth: "18px",
                  }}
                >
                  {newMessagesCnt}
                </Badge>
              )}
            </Tab>
            <Tab
              padding={{ md: "14px 25px", sm: "10px" }}
              fontSize={{ md: "17px", sm: "14px" }}
              lineHeight={{ md: "24px", sm: "22px" }}
              fontWeight="400"
              alignItems="center"
              _selected={{ color: "blue.500" }}
              _focus={{ boxShadow: "none" }}
            >
              Active
              {activeMessagesCnt > 0 && (
                <Badge
                  bgColor={"#F02828"}
                  sx={{
                    padding: "9px 6px !important",
                    borderRadius: "1000px",
                    fontWeight: "700",
                    fontSize: "10px",
                    lineHeight: "0px",
                    color: "#FFF",
                    marginLeft: "7px",
                    minWidth: "18px",
                  }}
                >
                  {activeMessagesCnt}
                </Badge>
              )}
            </Tab>
            <Tab
              padding={{ md: "14px 25px", sm: "10px" }}
              fontSize={{ md: "17px", sm: "14px" }}
              lineHeight={{ md: "24px", sm: "22px" }}
              fontWeight="400"
              alignItems="center"
              _selected={{ color: "blue.500" }}
              _focus={{ boxShadow: "none" }}
            >
              Due Soon{" "}
              {dueSoonMessagesCnt > 0 && (
                <Badge
                  bgColor={"#F02828"}
                  sx={{
                    padding: "9px 6px !important",
                    borderRadius: "1000px",
                    fontWeight: "700",
                    fontSize: "10px",
                    lineHeight: "0px",
                    color: "#FFF",
                    marginLeft: "7px",
                    minWidth: "18px",
                  }}
                >
                  {dueSoonMessagesCnt}
                </Badge>
              )}
            </Tab>

            <Tab
              padding={{ md: "14px 25px", sm: "10px" }}
              fontSize={{ md: "17px", sm: "14px" }}
              lineHeight={{ md: "24px", sm: "22px" }}
              fontWeight="400"
              alignItems="center"
              _selected={{ color: "blue.500" }}
              _focus={{ boxShadow: "none" }}
            >
              Reviews
              {reviewMessagesCnt > 0 && (
                <Badge
                  bgColor={"#F02828"}
                  sx={{
                    padding: "9px 6px !important",
                    borderRadius: "1000px",
                    fontWeight: "700",
                    fontSize: "10px",
                    lineHeight: "0px",
                    color: "#FFF",
                    marginLeft: "7px",
                    minWidth: "18px",
                  }}
                >
                  {reviewMessagesCnt}
                </Badge>
              )}
            </Tab>

            <Tab
              padding={{ md: "14px 25px", sm: "10px" }}
              fontSize={{ md: "17px", sm: "14px" }}
              lineHeight={{ md: "24px", sm: "22px" }}
              fontWeight="400"
              alignItems="center"
              _selected={{ color: "blue.500" }}
              _focus={{ boxShadow: "none" }}
            >
              Completed
              {completeMessagesCnt > 0 && (
                <Badge
                  bgColor={"#F02828"}
                  sx={{
                    padding: "9px 6px !important",
                    borderRadius: "1000px",
                    fontWeight: "700",
                    fontSize: "10px",
                    lineHeight: "0px",
                    color: "#FFF",
                    marginLeft: "7px",
                    minWidth: "18px",
                  }}
                >
                  {completeMessagesCnt}
                </Badge>
              )}
            </Tab>

            <Box
              padding={{ md: "14px 0px", sm: "10px 0px" }}
              position="absolute"
              right="30px"
              display={{
                base: "none",
                md: "block",
              }}
              boxSizing="border-box"
              zIndex={90}
            >
              <SortingHandler
                selectedOption={selectedOption}
                sortingOptions={sortingOptions}
                onChangeSortingOption={(e: any) => {
                  setSelectedOption(e);
                }}
              />
            </Box>
          </TabList>
          <TabIndicator
            mt="-2px"
            height="2px"
            bg="blue.500"
            borderRadius="1px"
          />
          <TabPanels>
            <TabPanel px="0">
              <>
                {(isFetching || results?.length > 0) && (
                  <>
                    {windowWidth >= 768 ? (
                      <ProjectsTable
                        isFetching={isFetching}
                        tableData={results}
                        onShowProjectDetails={showDetailsProductDetails}
                        pageLimit={pageLimit}
                      />
                    ) : (
                      <ProjectMobileTable
                        isFetching={isFetching}
                        tableData={results}
                        onShowProjectDetails={showDetailsProductDetails}
                      />
                    )}
                  </>
                )}
                {!isFetching && results?.length < 1 && <>No New Projects</>}
              </>
            </TabPanel>
            <TabPanel px="0">
              {(isFetching || results?.length > 0) && (
                <>
                  {windowWidth >= 768 ? (
                    <ProjectsTable
                      isFetching={isFetching}
                      tableData={results}
                      onShowProjectDetails={showDetailsProductDetails}
                      pageLimit={pageLimit}
                    />
                  ) : (
                    <ProjectMobileTable
                      isFetching={isFetching}
                      tableData={results}
                      onShowProjectDetails={showDetailsProductDetails}
                    />
                  )}
                </>
              )}
              {!isFetching && results?.length < 1 && <>No Active Projects</>}
            </TabPanel>
            <TabPanel px="0">
              {(isFetching || results?.length > 0) && (
                <>
                  {windowWidth >= 768 ? (
                    <ProjectsTable
                      isFetching={isFetching}
                      tableData={results}
                      onShowProjectDetails={showDetailsProductDetails}
                      pageLimit={pageLimit}
                    />
                  ) : (
                    <ProjectMobileTable
                      isFetching={isFetching}
                      tableData={results}
                      onShowProjectDetails={showDetailsProductDetails}
                    />
                  )}
                </>
              )}
              {!isFetching && results?.length < 1 && <>No Due soon Projects</>}
            </TabPanel>
            <TabPanel px="0">
              {(isFetching || results?.length > 0) && (
                <>
                  {windowWidth >= 768 ? (
                    <ProjectsTable
                      isFetching={isFetching}
                      tableData={results}
                      onShowProjectDetails={showDetailsProductDetails}
                      pageLimit={pageLimit}
                    />
                  ) : (
                    <ProjectMobileTable
                      isFetching={isFetching}
                      tableData={results}
                      onShowProjectDetails={showDetailsProductDetails}
                    />
                  )}
                </>
              )}
              {!isFetching && results?.length < 1 && <>No Reviews Projects</>}
            </TabPanel>

            <TabPanel px="0">
              {(isFetching || results?.length > 0) && (
                <>
                  {windowWidth >= 768 ? (
                    <ProjectsTable
                      isFetching={isFetching}
                      tableData={results}
                      onShowProjectDetails={showDetailsProductDetails}
                      pageLimit={pageLimit}
                    />
                  ) : (
                    <ProjectMobileTable
                      isFetching={isFetching}
                      tableData={results}
                      onShowProjectDetails={showDetailsProductDetails}
                    />
                  )}
                </>
              )}
              {!isFetching && results?.length < 1 && <>No Completed Projects</>}
            </TabPanel>
          </TabPanels>
          {!isFetching && totalPages >= 1 && (
            <CustomPaginations
              totalCount={totalRows}
              totalPages={totalPages}
              currentPage={currentPage}
              perPage={pageLimit}
              onSetPageLimit={(e: number) => {
                setPageLimit(e);
                setCurrentPage(1);
              }}
              onChangePage={(e: number) => {
                setCurrentPage(e);
              }}
            />
          )}
        </Tabs>
      ) : (
        <Tabs variant="unstyled">
          <TabList borderBottom={"2px solid #E9E9E9"}>
            <Tab
              padding={{ md: "14px 25px", sm: "10px" }}
              fontSize={{ md: "17px", sm: "14px" }}
              lineHeight={{ md: "24px", sm: "22px" }}
              fontWeight="400"
              alignItems="center"
              _selected={{ color: "blue.500" }}
              _focus={{ boxShadow: "none" }}
            >
              Completed
              {completeMessagesCnt > 0 && (
                <Badge
                  bgColor={"#F02828"}
                  sx={{
                    padding: "9px 6px !important",
                    borderRadius: "1000px",
                    fontWeight: "700",
                    fontSize: "10px",
                    lineHeight: "0px",
                    color: "#FFF",
                    marginLeft: "7px",
                    minWidth: "18px",
                  }}
                >
                  {completeMessagesCnt}
                </Badge>
              )}
            </Tab>

            <Box
              padding={{ md: "14px 0px", sm: "10px 0px" }}
              position="absolute"
              right="30px"
              display={{
                base: "none",
                md: "block",
              }}
              boxSizing="border-box"
              zIndex={90}
            >
              <SortingHandler
                selectedOption={selectedOption}
                sortingOptions={sortingOptions}
                onChangeSortingOption={(e: any) => {
                  setSelectedOption(e);
                }}
              />
            </Box>
          </TabList>
          <TabIndicator
            mt="-2px"
            height="2px"
            bg="blue.500"
            borderRadius="1px"
          />
          <TabPanels>
            {/* <TabPanel px="0">
              {windowWidth >= 768 ? (
                <ProjectsTable
                  isFetching={isFetching}
                  tableData={completedProjects}
                  onShowProjectDetails={showDetailsProductDetails}
                />
              ) : (
                <ProjectMobileTable
                  isFetching={isFetching}
                  tableData={completedProjects}
                  onShowProjectDetails={showDetailsProductDetails}
                />
              )}
            </TabPanel> */}
          </TabPanels>
        </Tabs>
      )}

      <ProjectDetail
        selectedProjectId={selectedProjectId}
        onCloseModal={() => setSelectedProjectId(null)}
        onRefresh={fetchResults}
      />
      {isUpdatingDocuments && (
        <Box
          w="100%"
          h="100%"
          bgColor={"rgba(0, 0, 0, 0.1)"}
          position="fixed"
          top="0"
          left="0"
          zIndex="100001"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner color="#2F80ED" width={"50px"} height="50px"></Spinner>
        </Box>
      )}
    </Box>
  );
}
