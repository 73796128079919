import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { Field, Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useDispatch, useSelector } from "react-redux";
import { getIDRequest, putIDRequest } from "store/user/reducer";
import {
  isGettingIDStateSelector,
  isRegisteringIDStateSelector,
  personalIDSelector,
  userSelector,
} from "store/user/selector";
const inputStyleNormal = {
  fontSize: "14px",
  lineHeight: "17px",
  color: "black",
  border: "1px solid #E9E9E9",
  borderRadius: "50px",
  padding: "15px 30px",
  base: "15px",
  height: { md: "60px", base: "45px" },
  bgColor: "#FFF",
  boxShadow: "none !important",
};

const inputStyle = {
  fontSize: "14px",
  lineHeight: "17px",
  color: "black",
  border: "none !important",
  boxShadow: "none !important",
  bgColor: "transparent",
};

export default function PersonalDetailsForm() {
  const personalID = useSelector(personalIDSelector);
  const isGettingID = useSelector(isGettingIDStateSelector);
  const isRegisteringID = useSelector(isRegisteringIDStateSelector);
  const user = useSelector(userSelector);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getIDRequest({
        user_id: user.user_id,
      })
    );
  }, []);
  const initialValues = useMemo(() => {
    return {
      first_name: personalID ? (personalID.id_first_name as string) : "",
      last_name: personalID ? (personalID.id_last_name as string) : "",
      id_number: personalID ? (personalID.id_number as string) : "",
      id_expiration_date: personalID
        ? (personalID.id_expiration_date as string)
        : "",
      id_address: personalID ? (personalID.id_address as string) : "",
      id_file_location: personalID
        ? (personalID.id_file_location as string)
        : "",
    };
  }, [personalID]);

  const [idFile, setIdFile] = useState(null);
  const onUpdateID = (values: any) => {
    const formData = new FormData();
    if (idFile) {
      formData.append("file", idFile);
    }
    formData.append("id_first_name", values.first_name);
    formData.append("id_last_name", values.last_name);
    formData.append("id_number", values.id_number);
    formData.append("id_expiration_date", values.id_expiration_date);
    formData.append("id_address", values.id_address);
    formData.append("id_file_location", values.id_file_location);

    dispatch(
      putIDRequest({
        user_id: user.user_id,
        id_details: formData,
      })
    );
  };
  return (
    <>
      <FormControl mt="0px">
        {!isGettingID && (
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              onUpdateID(values);
            }}
          >
            {({ handleSubmit, values, errors, touched }) => (
              <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                <Text
                  fontSize="17px"
                  lineHeight="24px"
                  color="#000"
                  fontWeight="700"
                  mb="17px"
                >
                  Identification
                </Text>
                <SimpleGrid
                  columns={{ base: 1, md: 2 }}
                  columnGap="10px"
                  rowGap="5px"
                  marginTop="15px"
                >
                  <FormControl
                    isInvalid={!!errors.first_name && touched.first_name}
                    mb="12px"
                  >
                    <FormLabel
                      fontSize="12px"
                      lineHeight="15px"
                      fontWeight="400"
                      color="#8E8C8C"
                      textTransform="capitalize"
                      padding="5px"
                      bgColor="#FFF"
                      mr="0px"
                      width="fit-content"
                      position="relative"
                      zIndex="10"
                      ml="30px"
                      mb="-13px"
                    >
                      First Name
                    </FormLabel>
                    <Field
                      as={Input}
                      type="text"
                      id="first_name"
                      name="first_name"
                      placeholder="First Name"
                      value={values.first_name}
                      sx={{
                        ...inputStyleNormal,
                        fontWeight: "500",
                      }}
                      _placeholder={{
                        ...inputStyle,
                        fontWeight: "400",
                      }}
                      validate={(value: any) => {
                        let error;
                        if (!value || value?.length < 1) {
                          error = "First Name is required.";
                        }
                        return error;
                      }}
                    />
                    <FormErrorMessage
                      mt={1}
                      pl={"30px"}
                      sx={{
                        color: "#F00",
                        justifyContent: "flex-start",
                      }}
                    >
                      {errors.first_name}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl
                    isInvalid={!!errors.last_name && touched.last_name}
                    mb="12px"
                  >
                    <FormLabel
                      fontSize="12px"
                      lineHeight="15px"
                      fontWeight="400"
                      color="#8E8C8C"
                      textTransform="capitalize"
                      padding="5px"
                      bgColor="#FFF"
                      mr="0px"
                      width="fit-content"
                      position="relative"
                      zIndex="10"
                      ml="30px"
                      mb="-13px"
                    >
                      Last Name
                    </FormLabel>
                    <Field
                      as={Input}
                      type="text"
                      id="last_name"
                      name="last_name"
                      placeholder="Last Name"
                      sx={{
                        ...inputStyleNormal,
                        fontWeight: "500",
                      }}
                      _placeholder={{
                        ...inputStyle,
                        fontWeight: "400",
                      }}
                      validate={(value: any) => {
                        let error;
                        if (!value || value?.length < 1) {
                          error = "Last Name is required.";
                        }
                        return error;
                      }}
                    />
                    <FormErrorMessage
                      mt={1}
                      pl={"30px"}
                      sx={{
                        color: "#F00",
                        justifyContent: "flex-start",
                      }}
                    >
                      {errors.last_name}
                    </FormErrorMessage>
                  </FormControl>
                </SimpleGrid>
                <SimpleGrid
                  columns={{ base: 1, md: 2 }}
                  columnGap="10px"
                  rowGap="5px"
                  marginTop="15px"
                >
                  <FormControl
                    isInvalid={!!errors.id_number && touched.id_number}
                    mb="12px"
                  >
                    <FormLabel
                      fontSize="12px"
                      lineHeight="15px"
                      fontWeight="400"
                      color="#8E8C8C"
                      textTransform="capitalize"
                      padding="5px"
                      bgColor="#FFF"
                      mr="0px"
                      width="fit-content"
                      position="relative"
                      zIndex="10"
                      ml="30px"
                      mb="-13px"
                    >
                      ID Number
                    </FormLabel>
                    <Field
                      as={Input}
                      type="text"
                      id="id_number"
                      name="id_number"
                      placeholder="ID Number"
                      sx={{
                        ...inputStyleNormal,
                        fontWeight: "500",
                      }}
                      _placeholder={{
                        ...inputStyle,
                        fontWeight: "400",
                      }}
                      validate={(value: any) => {
                        let error;
                        if (!value || value?.length < 1) {
                          error = "ID Number is required.";
                        }
                        return error;
                      }}
                    />
                    <FormErrorMessage
                      mt={1}
                      pl={"30px"}
                      sx={{
                        color: "#F00",
                        justifyContent: "flex-start",
                      }}
                    >
                      {errors.id_number}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl
                    isInvalid={
                      !!errors.id_expiration_date && touched.id_expiration_date
                    }
                    mb="12px"
                  >
                    <FormLabel
                      fontSize="12px"
                      lineHeight="15px"
                      fontWeight="400"
                      color="#8E8C8C"
                      textTransform="capitalize"
                      padding="5px"
                      bgColor="#FFF"
                      mr="0px"
                      width="fit-content"
                      position="relative"
                      zIndex="10"
                      ml="30px"
                      mb="-13px"
                    >
                      Expiration Date
                    </FormLabel>
                    <Field
                      as={Input}
                      type="text"
                      id="id_expiration_date"
                      name="id_expiration_date"
                      placeholder="MO / YEAR"
                      sx={{
                        ...inputStyleNormal,
                        fontWeight: "500",
                      }}
                      _placeholder={{
                        ...inputStyle,
                        fontWeight: "400",
                      }}
                      validate={(value: any) => {
                        let error;
                        if (!value || value?.length < 1) {
                          error = "Expiration Date is required.";
                        }
                        return error;
                      }}
                    />
                    <FormErrorMessage
                      pl={"30px"}
                      mt={1}
                      sx={{
                        color: "#F00",
                        justifyContent: "flex-start",
                      }}
                    >
                      {errors.id_expiration_date}
                    </FormErrorMessage>
                  </FormControl>
                </SimpleGrid>

                <FormControl
                  mb="12px"
                  isInvalid={!!errors.id_address && touched.id_address}
                >
                  <FormLabel
                    fontSize="12px"
                    lineHeight="15px"
                    fontWeight="400"
                    color="#8E8C8C"
                    textTransform="capitalize"
                    padding="5px"
                    bgColor="#FFF"
                    mr="0px"
                    width="fit-content"
                    position="relative"
                    zIndex="10"
                    ml="30px"
                    mb="-13px"
                  >
                    Address
                  </FormLabel>
                  <Field
                    as={Input}
                    type="text"
                    id="id_address"
                    name="id_address"
                    placeholder="Address"
                    sx={{
                      ...inputStyleNormal,
                      fontWeight: "500",
                    }}
                    _placeholder={{
                      ...inputStyle,
                      fontWeight: "400",
                    }}
                    validate={(value: any) => {
                      let error;
                      if (!value || value?.length < 1) {
                        error = "Address is required.";
                      }
                      return error;
                    }}
                  />
                  <FormErrorMessage
                    mt={1}
                    pl={"30px"}
                    sx={{
                      color: "#F00",
                      justifyContent: "flex-start",
                    }}
                  >
                    {errors.id_address}
                  </FormErrorMessage>
                </FormControl>

                <Flex
                  alignItems={{ md: "center", base: "flex-start" }}
                  marginTop="25px"
                  rowGap="20px"
                  flexDirection={{ md: "row", base: "column" }}
                  sx={{
                    "label:focus-within": {
                      outline: "none",
                    },
                  }}
                >
                  {!values.id_file_location ? (
                    <Flex alignItems="center">
                      <FileUploader
                        multiple={false}
                        handleChange={(file: File) => {
                          setIdFile(file);
                        }}
                        name="file"
                        height="full"
                        types={["pdf", "doc", "dox", "png", "jpg"]}
                        sx={{
                          outline: "none",
                        }}
                      >
                        <Button
                          height="46px"
                          padding="15px 35px"
                          borderRadius="100px"
                          bgColor="#2F80ED"
                          color="#FFF"
                          fontSize="14px"
                          lineHeight="17px"
                          fontWeight="bold"
                          width="175px"
                          border="none"
                          _active={{
                            border: "none",
                            outline: "none",
                          }}
                        >
                          {idFile ? "Attached" : "Upload ID"}
                        </Button>
                      </FileUploader>
                      <Text
                        cursor="pointer"
                        ml="20px"
                        fontSize="14px"
                        lineHeight="135%"
                        fontWeight="400"
                        color="#000"
                      >
                        Include front and back
                      </Text>
                    </Flex>
                  ) : (
                    <Flex>
                      <Button
                        height="46px"
                        padding="15px 35px"
                        borderRadius="100px"
                        bgColor="#2F80ED"
                        color="#FFF"
                        fontSize="14px"
                        lineHeight="17px"
                        fontWeight="bold"
                        width="175px"
                        border="none"
                        _active={{
                          border: "none",
                          outline: "none",
                        }}
                        onClick={() => {
                          const fullUrl = `${process.env.REACT_APP_API_BASE_URL}/${values.id_file_location}`;
                          const link = document.createElement("a");
                          link.href = fullUrl;
                          link.target = "_blank";
                          document.body.appendChild(link);
                          link.click();
                          link.parentNode.removeChild(link);
                        }}
                      >
                        View Uploaded ID
                      </Button>
                    </Flex>
                  )}

                  <Button
                    type="submit"
                    bgColor="#8E8C8C"
                    padding="15px 40px"
                    fontWeight="700"
                    fontSize="14px"
                    lineHeight="17px"
                    color="#FFF"
                    height="48px"
                    width="176px"
                    borderRadius="50px"
                    marginLeft={{ md: "auto", base: "0px" }}
                    isLoading={isRegisteringID}
                  >
                    Save Changes
                  </Button>
                </Flex>
                <input
                  type="hidden"
                  name="id_file_location"
                  value={values.id_file_location}
                />
              </form>
            )}
          </Formik>
        )}
      </FormControl>
    </>
  );
}
