/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Skeleton,
  Tab,
  TabIndicator,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { IconClose, IconDownload } from "components/icons/Icons";
import IconBox from "components/icons/IconBox";

import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  invoicesSelector,
  isGettingInvoicesSelector,
} from "store/user/selector";
import { pad_with_zeroes } from "network/helper";
import { axiosService } from "network/axios";
import emptyAvatar from "assets/img/avatars/avatar.png";
import moment from "moment-timezone";
import _ from "lodash";
import { getInvoicesRequest } from "store/user/reducer";
import UserInfoMenu from "components/menu/UserInfoMenu";
const pdfMake = require("pdfmake");

export default function TranslatorDetailPopup(props: {
  selectedTranslatorId: any | null;
  onCloseModal: Function;
}) {
  pdfMake.fonts = {
    Roboto: {
      normal:
        "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
      bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
      italics:
        "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
      bolditalics:
        "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
    },
  };
  const dispatch = useDispatch();
  const { selectedTranslatorId, onCloseModal } = props;
  const [isFetching, setIsFetching] = useState(true);
  const [translatorDetails, setTranslatorDetails] = useState(null);

  const invoices = useSelector(invoicesSelector);
  const isGettingInvoices = useSelector(isGettingInvoicesSelector);
  const months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    if (selectedTranslatorId && dispatch) {
      // Fetch Manager Details
      fetchManagerDetails(selectedTranslatorId);

      dispatch(
        getInvoicesRequest({
          user_id: selectedTranslatorId,
        })
      );
    }
  }, [dispatch, selectedTranslatorId]);

  const fetchManagerDetails = async (manager_id: string) => {
    setIsFetching(true);
    try {
      const resp = await axiosService.get(
        `/user/translator/details/${manager_id}`
      );
      setTranslatorDetails(resp.data);
    } catch (err: any) {
    } finally {
      setIsFetching(false);
    }
  };

  const translatorDetailsData = useMemo(() => {
    if (translatorDetails) {
      const { base, id, orders, invoices, languages } = translatorDetails;
      const fullName = `${base.first_name} ${base.last_name}`;
      const avatar = base.profile_pic_src
        ? base.profile_pic_src?.includes("http")
          ? base.profile_pic_src
          : `${process.env.REACT_APP_API_BASE_URL}/public_html/images/${base.profile_pic_src}`
        : emptyAvatar;
      return {
        full_name: fullName,
        avatar: avatar,
        contact: {
          email: base.email ?? "Undefined",
          phone: base.phone ?? "Undefined",
        },
        identifications: [
          {
            key: "First Name",
            value: id?.id_first_name ?? "",
          },
          {
            key: "Last Name",
            value: id?.id_last_name ?? "",
          },
          {
            key: "Address",
            value: id?.id_address ?? "",
          },
          {
            key: "ID Number",
            value: id?.id_number ?? "",
          },
          {
            key: "ID Expiration",
            value: id?.id_expiration_date ?? "",
          },
          {
            key: "ID File",
            value: id?.id_file_location ?? "",
          },
        ],
        orders: orders?.map((it: any) => ({
          id: it.order_id,
          customer_name: `${it.first_name} ${it.last_name}`,
          translated_from: it.translated_from,
          translated_to: it.translated_to,
          status: it.order_status_name,
          order_total: Number(it.order_total ?? 0),
        })),
        invoices: invoices?.map((it: any) => ({
          invoice_for_date: it.invoice_for_date,
          url: it.invoice_file_path,
          is_paid: it.is_paid,
        })),
        languages: languages?.map((it: any) => ({
          id: it.id,
          language: it.language,
          level:
            Number(it.level) === 0
              ? "Native"
              : Number(it.level) === 1
              ? "Fluent"
              : "Advanced (Certified Level Translations)",
        })),
      };
    } else {
      return null;
    }
  }, [translatorDetails]);
  const fullInvoices = useMemo(() => {
    const years =
      invoices?.length > 0
        ? _.uniq(
            invoices?.map((it: any) =>
              moment(new Date(it?.updated_at)).format("YYYY")
            )
          )
        : [];
    let results: any[] = [];
    years?.forEach((year: string) => {
      let yearInvoices = invoices?.filter(
        (it: any) => moment(new Date(it?.updated_at)).format("YYYY") === year
      );
      let availableMonths = _.uniq(
        yearInvoices?.map((it: any) =>
          moment(new Date(it?.updated_at)).format("MM")
        )
      );
      availableMonths?.forEach((month: string) => {
        const matchedInvoices = yearInvoices?.filter(
          (it: any) => moment(new Date(it?.updated_at)).format("MM") === month
        );

        const order_ids = _.uniq(
          matchedInvoices?.map((it: any) => Number(it.order_id))
        );
        const invoiceReports: any[] = [];
        order_ids?.forEach((order_id) => {
          let orderDocuments = (
            matchedInvoices?.filter(
              (it: any) => Number(it.order_id) === Number(order_id)
            ) || []
          )?.map((it: any) => ({
            ...it,
            page_count: Number(it?.page_count || 0),
          }));

          const totalPages = _.sumBy(orderDocuments, "page_count");
          invoiceReports.push({
            order_id: order_id,
            document_count: orderDocuments.length,
            translated_from:
              orderDocuments?.length > 0
                ? orderDocuments[0].translated_from
                : "",
            translated_to:
              orderDocuments?.length > 0 ? orderDocuments[0].translated_to : "",
            page_count: totalPages,
          });
        });
        let monthIndex = months?.indexOf(month);
        results.push({
          year: year,
          month: month,
          month_name: monthNames[monthIndex],
          invoiceReports: invoiceReports,
        });
      });
    });
    return results;
  }, [invoices]);

  const onDownloadInvoice = (
    reports: any[] | null,
    year: string | null,
    month: string | null
  ) => {
    let downloadResults: any[] = reports;
    let downloadYear: string = year;
    let downloadMonth: string = month;

    const docRows = downloadResults?.map((it: any) => [
      { text: pad_with_zeroes(it.order_id, 6), margin: [0, 10, 0, 10] },
      { text: it.document_count, margin: [0, 10, 0, 10] },
      { text: it.translated_from, margin: [0, 10, 0, 10] },
      { text: it.translated_to, margin: [0, 10, 0, 10] },
      { text: it.page_count, margin: [0, 10, 0, 10] },
      {
        text: `$${Number(it.page_count * 5).toFixed(2)}`,
        margin: [0, 10, 0, 10],
      },
    ]);

    const docDefinition = {
      header: function (
        currentPage: number,
        pageCount: number,
        pageSize: number
      ) {
        return [
          {
            text: `Invoice - (${downloadYear}-${downloadMonth})`,
            alignment: "center",
            margin: [0, 5, 0, 40],
            fontSize: 16,
          },
        ];
      },
      content: [
        {
          layout: "lightHorizontalLines",
          table: {
            headerRows: 1,
            body: [
              [
                { text: "Order ID", margin: [0, 5, 0, 5] },
                { text: "Documents", margin: [0, 5, 0, 5] },
                { text: "Source Language (s)", margin: [0, 5, 0, 5] },
                { text: "Target Language (s)", margin: [0, 5, 0, 5] },
                { text: "Total Pages", margin: [0, 5, 0, 5] },
                { text: "Price", margin: [0, 5, 0, 5] },
              ],
              ...docRows,
            ],
          },
        },
        {
          margin: [0, 15, 0, 0],
          text: `Price Total: $${(
            _.sumBy(downloadResults, "page_count") * 5
          ).toFixed(2)}`,
        },
      ],
    };
    const fileName = `invoice-${downloadYear}-${downloadMonth}.pdf`;
    pdfMake.createPdf(docDefinition).download(fileName);
  };

  return (
    <Box
      w={{
        base: "100%",
        xl: "calc(100vw - 230px)",
        baseWidth: "calc(100vw - (100vw - 1440px) / 2 - 230px)",
      }}
      transition="transform 0.3s ease-in-out"
      transform={selectedTranslatorId ? `translateX(0)` : `translateX(100%)`}
      h="100vh"
      position="fixed"
      bgColor="#FFF"
      zIndex="200"
      boxShadow={
        selectedTranslatorId ? "0px 4px 40px rgba(0, 0, 0, 0.1)" : "none"
      }
      boxSizing="border-box"
      padding={{
        md: "50px 40px 60px",
        sm: "60px 30px 30px",
        base: "60px 30px 30px",
        baseWidth: "50px calc((100vw - 1440px) / 2 + 40px) 50px 60px",
      }}
      overflow="auto"
      top="0px"
      right="0px"
    >
      {/* {translatorDetailsData && !isFetching && ( */}
      <Flex
        minH="100%"
        h="fit-content"
        overflow={"hidden"}
        pr="20px"
        mr="-20px"
      >
        <IconButton
          onClick={(e) => onCloseModal()}
          aria-label="close"
          minW={"unset"}
          h={{ md: "52px", sm: "32px" }}
          w={{ md: "52px", sm: "32px" }}
          borderRadius="50%"
          bg="inherit"
          marginRight={{ md: "60px", sm: "0px", base: "0px" }}
          position={{ md: "unset", sm: "absolute", base: "absolute" }}
          right={{ md: "unset", sm: "30px", base: "30px" }}
          top={{ md: "unset", sm: "10px", base: "10px" }}
          _active={{
            bg: "inherit",
            transform: "none",
            borderColor: "transparent",
          }}
          _focus={{
            boxShadow: "none",
          }}
          icon={
            <IconClose
              h={{ md: "52px", sm: "32px" }}
              w={{ md: "52px", sm: "32px" }}
            />
          }
        />

        <Flex flex="1" direction="column" width="0">
          <Flex
            direction={{ md: "row", sm: "column-reverse" }}
            justifyContent="space-between"
          >
            <Flex alignItems="center">
              {isFetching ? (
                <Skeleton width="200px" height="35px"></Skeleton>
              ) : (
                <Heading
                  fontSize="25px"
                  lineHeight="135%"
                  fontWeight="400"
                  color="#000"
                >
                  {translatorDetailsData
                    ? translatorDetailsData.full_name
                    : "Undefined"}
                </Heading>
              )}
            </Flex>
            <Box ml="auto">
              <UserInfoMenu />
            </Box>
          </Flex>

          <Box mt="50px" mb="50px">
            <Flex flexWrap="wrap" columnGap="30px" alignItems="center">
              {!isFetching ? (
                <Avatar
                  src={translatorDetailsData.avatar}
                  _hover={{ cursor: "pointer" }}
                  color="white"
                  name="Name"
                  bg="transparent"
                  size="sm"
                  w={{ md: "125px", base: "100px" }}
                  h={{ md: "125px", base: "100px" }}
                />
              ) : (
                <Skeleton
                  w={{ md: "125px", base: "100px" }}
                  h={{ md: "125px", base: "100px" }}
                  borderRadius="50%"
                />
              )}

              <Box>
                <TableContainer>
                  <Table variant="simple">
                    <Tbody>
                      <Tr>
                        <Td py={2} px={3}>
                          Full Name
                        </Td>
                        <Td py={2} px={3}>
                          {isFetching ? (
                            <Skeleton
                              w="200px"
                              h="20px"
                              colorScheme="#F3F5F5"
                            />
                          ) : translatorDetailsData ? (
                            translatorDetailsData.full_name
                          ) : (
                            "Undefined"
                          )}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td py={2} px={3}>
                          Email Address
                        </Td>
                        <Td py={2} px={3}>
                          {isFetching ? (
                            <Skeleton
                              w="200px"
                              h="20px"
                              colorScheme="#F3F5F5"
                            />
                          ) : translatorDetailsData ? (
                            translatorDetailsData.contact.email
                          ) : (
                            "Undefined"
                          )}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td py={2} px={3}>
                          Phone Number
                        </Td>
                        <Td py={2} px={3}>
                          {isFetching ? (
                            <Skeleton
                              w="200px"
                              h="20px"
                              colorScheme="#F3F5F5"
                            />
                          ) : translatorDetailsData ? (
                            translatorDetailsData.contact.phone
                          ) : (
                            "Undefined"
                          )}
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
            </Flex>
            {/* Identification Info */}
            <Box mt="40px">
              <Tabs variant="unstyled">
                <TabList borderBottom={"2px solid #E9E9E9"}>
                  <Tab
                    padding={{
                      md: "10px 15px",
                      sm: "10px 15px",
                    }}
                    fontSize={{ md: "17px", sm: "14px" }}
                    lineHeight={{ md: "24px", sm: "22px" }}
                    fontWeight="400"
                    alignItems="center"
                    _selected={{ color: "blue.500" }}
                    _focus={{ boxShadow: "none" }}
                  >
                    Identification
                  </Tab>
                  <Tab
                    padding={{
                      md: "10px 15px",
                      sm: "10px 15px",
                    }}
                    fontSize={{ md: "17px", sm: "14px" }}
                    lineHeight={{ md: "24px", sm: "22px" }}
                    fontWeight="400"
                    alignItems="center"
                    _selected={{ color: "blue.500" }}
                    _focus={{ boxShadow: "none" }}
                  >
                    Languages
                  </Tab>
                  <Tab
                    padding={{
                      md: "10px 15px",
                      sm: "10px 15px",
                    }}
                    fontSize={{ md: "17px", sm: "14px" }}
                    lineHeight={{ md: "24px", sm: "22px" }}
                    fontWeight="400"
                    alignItems="center"
                    _selected={{ color: "blue.500" }}
                    _focus={{ boxShadow: "none" }}
                  >
                    Projects
                  </Tab>
                  <Tab
                    padding={{
                      md: "10px 15px",
                      sm: "10px 15px",
                    }}
                    fontSize={{ md: "17px", sm: "14px" }}
                    lineHeight={{ md: "24px", sm: "22px" }}
                    fontWeight="400"
                    alignItems="center"
                    _selected={{ color: "blue.500" }}
                    _focus={{ boxShadow: "none" }}
                  >
                    Invoices
                  </Tab>
                </TabList>
                <TabIndicator
                  mt="-2px"
                  height="2px"
                  bg="blue.500"
                  borderRadius="1px"
                />
                <TabPanels>
                  <TabPanel px="0">
                    <TableContainer mt={3}>
                      <Table variant="simple">
                        <Tbody>
                          {!isFetching &&
                            translatorDetailsData.identifications?.map(
                              (it: any, index: number) => {
                                return (
                                  <Tr key={index}>
                                    <Td bgColor="#FAFAFA">{it.key}</Td>
                                    {it.key !== "ID File" ? (
                                      <Td>{it.value}</Td>
                                    ) : it.value ? (
                                      <Td>
                                        <Button
                                          height="30px"
                                          padding="5px 10px"
                                          borderRadius="5px"
                                          bgColor="#2F80ED"
                                          color="#FFF"
                                          fontSize="14px"
                                          lineHeight="17px"
                                          fontWeight="bold"
                                          width="fit-content"
                                          border="none"
                                          _active={{
                                            border: "none",
                                            outline: "none",
                                          }}
                                          onClick={() => {
                                            const fullUrl = `${process.env.REACT_APP_API_BASE_URL}/${it.value}`;
                                            const link =
                                              document.createElement("a");
                                            link.href = fullUrl;
                                            link.target = "_blank";
                                            document.body.appendChild(link);
                                            link.click();
                                            link.parentNode.removeChild(link);
                                          }}
                                        >
                                          View Uploaded ID
                                        </Button>
                                      </Td>
                                    ) : (
                                      <Td>Not Uploaded</Td>
                                    )}
                                  </Tr>
                                );
                              }
                            )}
                          {isFetching && (
                            <Tbody>
                              <Tr>
                                <Td bgColor="#FAFAFA">First Name</Td>
                                <Td padding="5px 10px">
                                  <Skeleton
                                    width="200px"
                                    height="20px"
                                    colorScheme="#F3F5F5"
                                  ></Skeleton>
                                </Td>
                              </Tr>
                              <Tr>
                                <Td bgColor="#FAFAFA">Last Name</Td>
                                <Td padding="5px 10px">
                                  <Skeleton
                                    width="200px"
                                    height="20px"
                                    colorScheme="#F3F5F5"
                                  ></Skeleton>
                                </Td>
                              </Tr>
                              <Tr>
                                <Td bgColor="#FAFAFA">Address</Td>
                                <Td padding="5px 10px">
                                  <Skeleton
                                    width="200px"
                                    height="20px"
                                    colorScheme="#F3F5F5"
                                  ></Skeleton>
                                </Td>
                              </Tr>
                              <Tr>
                                <Td bgColor="#FAFAFA">ID Number</Td>
                                <Td padding="5px 10px">
                                  <Skeleton
                                    width="200px"
                                    height="20px"
                                    colorScheme="#F3F5F5"
                                  ></Skeleton>
                                </Td>
                              </Tr>
                              <Tr>
                                <Td bgColor="#FAFAFA">ID Expiration</Td>
                                <Td padding="5px 10px">
                                  <Skeleton
                                    width="200px"
                                    height="20px"
                                    colorScheme="#F3F5F5"
                                  ></Skeleton>
                                </Td>
                              </Tr>
                              <Tr>
                                <Td bgColor="#FAFAFA">ID File</Td>
                                <Td padding="5px 10px">
                                  <Skeleton
                                    width="200px"
                                    height="20px"
                                    colorScheme="#F3F5F5"
                                  ></Skeleton>
                                </Td>
                              </Tr>
                            </Tbody>
                          )}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </TabPanel>
                  <TabPanel px="0">
                    <TableContainer mt={3}>
                      {isFetching && (
                        <Table variant="simple">
                          <Thead textTransform="capitalize">
                            <Tr>
                              <Th textTransform="capitalize">No</Th>
                              <Th textTransform="capitalize">Language</Th>
                              <Th textTransform="capitalize">Level</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {_.range(3).map((it) => (
                              <Tr key={it}>
                                {_.range(4).map((_it) => (
                                  <Td key={_it}>
                                    <Skeleton
                                      height="20px"
                                      colorScheme="#F3F5F5"
                                    ></Skeleton>
                                  </Td>
                                ))}
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      )}
                      {!isFetching &&
                        translatorDetailsData?.languages?.length > 0 && (
                          <Table variant="simple">
                            <Thead textTransform="capitalize">
                              <Tr>
                                <Th textTransform="capitalize">No</Th>
                                <Th textTransform="capitalize">Language</Th>
                                <Th textTransform="capitalize">Level</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {translatorDetailsData.languages.map(
                                (it: any, index: number) => (
                                  <Tr key={index}>
                                    <Td>{index + 1}</Td>
                                    <Td>{it.language}</Td>
                                    <Td>{it.level}</Td>
                                  </Tr>
                                )
                              )}
                              {isFetching &&
                                _.range(3).map((it) => (
                                  <Tr key={it}>
                                    {_.range(4).map((_it) => (
                                      <Td key={_it}>
                                        <Skeleton
                                          height="20px"
                                          colorScheme="#F3F5F5"
                                        ></Skeleton>
                                      </Td>
                                    ))}
                                  </Tr>
                                ))}
                            </Tbody>
                          </Table>
                        )}
                      {!isFetching &&
                        translatorDetailsData?.languages?.length < 1 && (
                          <Box px="3">No Languages</Box>
                        )}
                    </TableContainer>
                  </TabPanel>
                  <TabPanel px="0">
                    <TableContainer mt={3}>
                      {translatorDetailsData?.orders?.length > 0 ? (
                        <Table variant="simple">
                          <Thead textTransform="capitalize">
                            <Tr>
                              <Th textTransform="capitalize">Order ID</Th>
                              <Th textTransform="capitalize">Customer Name</Th>
                              <Th textTransform="capitalize">
                                Source Language(s)
                              </Th>
                              <Th textTransform="capitalize">
                                Target Language(s)
                              </Th>
                              <Th textTransform="capitalize">Order Status</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {translatorDetailsData.orders.map(
                              (it: any, index: number) => (
                                <Tr key={index}>
                                  <Td>{pad_with_zeroes(it.id, 6)}</Td>
                                  <Td>{it.customer_name}</Td>
                                  <Td>{it.translated_from}</Td>
                                  <Td>{it.translated_to}</Td>
                                  <Td>{it.status}</Td>
                                </Tr>
                              )
                            )}
                          </Tbody>
                        </Table>
                      ) : (
                        <Box px="3">No Assigned Projects</Box>
                      )}
                    </TableContainer>
                  </TabPanel>
                  <TabPanel px="0">
                    {!isGettingInvoices && fullInvoices?.length > 0 && (
                      <TableContainer mt={3}>
                        <Table variant="simple">
                          <Thead textTransform="capitalize">
                            <Tr>
                              <Th textTransform="capitalize">No</Th>
                              <Th textTransform="capitalize">Invoice Date</Th>
                              <Th textTransform="capitalize"></Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {fullInvoices.map((it: any, index: number) => (
                              <Tr key={index}>
                                <Td>{index + 1}</Td>
                                <Td>
                                  {it.year} {it?.month_name}
                                </Td>
                                <Td>
                                  <IconBox
                                    w="24px"
                                    h="24px"
                                    marginLeft="auto"
                                    cursor="pointer"
                                    onClick={() => {
                                      onDownloadInvoice(
                                        it?.invoiceReports,
                                        it?.year,
                                        it?.month
                                      );
                                    }}
                                    icon={<IconDownload w="24px" h="24px" />}
                                  ></IconBox>
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </TableContainer>
                    )}
                    {!isGettingInvoices && fullInvoices?.length < 1 && (
                      <Box px="3">No Invoices yet</Box>
                    )}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </Box>
        </Flex>
      </Flex>
      {/* )} */}
    </Box>
  );
}
