import { axiosService } from "./axios";

class ApiService {
  public getUser = async (id: number) => {
    return axiosService.get(`/user/get/${id}`);
  };

  public getAllUsers = async () => {
    return axiosService.get(`/user/all`);
  };

  public updateUser = async (id: number, userDetails: any) => {
    return axiosService.put(`/user/update/${id}`, {
      ...userDetails,
    });
  };

  public uploadAvatar = async (id: number, formData: FormData) => {
    return axiosService.post(`/user/upload-avatar/${id}`, formData);
  };

  public deleteAvatar = async (id: number) => {
    return axiosService.delete(`/user/delete-avatar/${id}`);
  };

  public login = async (payload: any) => axiosService.post("/login", payload);

  public register = async (payload: any) => {
    return axiosService.post("/user/create", payload);
  };

  public fetchProjects = () => {
    return axiosService.get("/projects/all");
  };

  // Customer

  public getProjectDetailByOrderId = (orderId: string) => {
    return axiosService.get(`/projects/get/${orderId}`);
  };

  public getCards = (user_id: number) => {
    return axiosService.get(`/user/cards/${user_id}`);
  };

  public saveCardInfo = async (user_id: number, card_details: any) => {
    return axiosService.post(`/user/cards/${user_id}`, {
      ...card_details,
    });
  };

  public removeCardInfo = async (user_id: number, card_id: number) => {
    return axiosService.delete(`/user/delete-card/${user_id}/${card_id}`);
  };

  public uploadDocuments = async (
    user_id: number,
    order_id: number,
    formData: FormData
  ) => {
    return axiosService.post(
      `/projects/upload-documents/${user_id}/${order_id}`,
      formData
    );
  };

  public uploadVersionDocument = async (
    user_id: number,
    document_id: number,
    order_id: number,
    formData: FormData
  ) => {
    return axiosService.post(
      `/projects/upload-version-document/${user_id}/${document_id}/${order_id}`,
      formData
    );
  };

  public saveOrder = async (user_id: number, order: any) => {
    return axiosService.post(`/projects/${user_id}`, order);
  };

  public getMyProjects = async (user_id: number) => {
    return axiosService.get(`/projects/list/${user_id}`);
  };

  public updateProject = async (
    user_id: number,
    order_id: number,
    details: any
  ) => {
    return axiosService.put(`/projects/update/${user_id}/${order_id}`, {
      ...details,
    });
  };

  public updateDocumentStatus = async (
    document_id: string,
    user_id: string,
    order_id: string,
    details: any
  ) => {
    return axiosService.put(
      `/documents/update-status/${user_id}/${order_id}/${document_id}`,
      details
    );
  };

  public fetchTranslatorProjects = async (user_id: number) => {
    return axiosService.get(`/projects/translators/${user_id}`);
  };

  public getTranslatorProjectDetail = async (
    order_id: number,
    user_id: number
  ) => {
    return axiosService.get(`/projects/translator-get/${order_id}/${user_id}`);
  };

  public fetchManagerProjects = async (user_id: number) => {
    return axiosService.get(`/projects/managers/${user_id}`);
  };

  public getManagerProjectDetail = async (
    order_id: number,
    user_id: number
  ) => {
    return axiosService.get(`/projects/manager-get/${order_id}/${user_id}`);
  };

  public getDownloadFileLinks = async (
    order_id: number,
    user_role: string,
    user_id: number,
    type: string
  ) => {
    return axiosService.get(
      `/projects/download/${order_id}/${user_role}/${user_id}/${type}`,
      {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/zip",
        },
      }
    );
  };
  // Personal ID Stuffs

  public getIDCard = async (user_id: number) => {
    return axiosService.get(`/profile/get/id/${user_id}`);
  };

  public putIDCard = async (user_id: number, formData: FormData) => {
    return axiosService.post(`/profile/put/id/${user_id}`, formData);
  };

  public getLanguages = async (user_id: number) => {
    return axiosService.get(`/profile/get-languages/${user_id}`);
  };

  public updateLanguages = async (user_id: number, payload: any) => {
    return axiosService.post(`/profile/set-languages/${user_id}`, payload);
  };

  public delLanguage = async (id: number, user_id: number) => {
    return axiosService.delete(`/profile/del-language/${id}/${user_id}`);
  };

  // Payment

  public processPayment = async (orderId: number, payload: any) => {
    return axiosService.post(`/shipping/process-payment/${orderId}`, payload);
  };

  public processPaymentWithToken = async (orderId: number, payload: any) => {
    return axiosService.post(`/shipping/process-token-payment/${orderId}`, payload);
  };

  public processPaymentWithPaymentMethod = async (orderId: number, payload: any) => {
    return axiosService.post(`/shipping/process-pm-payment/${orderId}`, payload);
  };

  // Bank
  public addBank = async (user_id: number, bankInfo: any) => {
    return axiosService.post(`/user/add-banks/${user_id}`, bankInfo);
  };

  public getBanks = async (user_id: number) => {
    return axiosService.get(`/user/get-banks/${user_id}`);
  };

  public delBank = async (user_id: number, bankId: number) => {
    return axiosService.delete(`/user/del-banks/${user_id}/${bankId}`);
  };

  // Serivces

  // public getAssignedServices = async (user_id: Number) => {
  //   return axiosService.get(`/user/get-assigned-services/${user_id}`);
  // };

  // public assignService = async (user_id: Number, payload: any) => {
  //   return axiosService.post(`/user/assign-service/${user_id}`, payload);
  // };

  // public unAssignService = async (user_id: Number, service_id: Number) => {
  //   return axiosService.delete(
  //     `/user/unassign-service/${user_id}/${service_id}`
  //   );
  // };

  // Business
  public fetchBusinessInformation = async (business_id: Number) => {
    return axiosService.get(`/business/get/${business_id}`);
  };

  public updateBusinessInformation = async (
    business_id: Number,
    details: any
  ) => {
    return axiosService.post(`/business/update/${business_id}`, details);
  };

  // Team
  public fetchTeamUsers = async (user_id: Number) => {
    return axiosService.get(`/user/team/get/${user_id}`);
  };

  public addTeamUsers = async (business_id: Number, payload: any) => {
    return axiosService.post(`/user/team/add/${business_id}`, payload);
  };

  public deleteTeamUser = async (team_users_id: Number) => {
    return axiosService.delete(`/user/team/delete/${team_users_id}`);
  };

  public updateTeammemberRole = async (team_users_id: Number, role: string) => {
    const payload = {
      role: role,
    };

    return axiosService.post(
      `/user/team/change-role/${team_users_id}`,
      payload
    );
  };

  // Message

  public getReceivers = async (user_id: Number, order_id: Number) => {
    return axiosService.get(`/chat/get/receivers/${user_id}/${order_id}`);
  };

  public getMessages = async (
    sender_id: Number,
    receiver_id: Number,
    order_id: Number
  ) => {
    return axiosService.get(
      `/chat/get/messages/${sender_id}/${receiver_id}/${order_id}`
    );
  };

  public sendMessage = async (
    sender_id: Number,
    receiver_id: Number,
    order_id: Number,
    message: string
  ) => {
    return axiosService.post(
      `/chat/send/${sender_id}/${receiver_id}/${order_id}`,
      {
        message: message,
      }
    );
  };

  public fetchUnreadMessages = async (user_id: Number) => {
    return axiosService.get(`/chat/unreadMessages/${user_id}`);
  };

  public readMessage = async (message_id: Number) => {
    return axiosService.post(`/chat/read/${message_id}`, {});
  };

  // Invoice
  public uploadInvoice = async (
    formData: FormData,
    user_id: Number,
    year: string,
    date: string
  ) => {
    return axiosService.post(
      `/invoices/upload/${user_id}/${year}/${date}`,
      formData
    );
  };

  public getInvoices = async (user_id: Number) => {
    return axiosService.get(`/invoices/${user_id}`);
  };

  public deleteOrder = async (order_id: Number) => {
    return axiosService.delete(`/projects/delete/${order_id}`);
  };

  public getManagers = async () => {
    return axiosService.get("/user/managers");
  };
}

export const apiService = new ApiService();
