import { Box } from "@chakra-ui/react";
import { LanguexLogo } from "components/icons/Icons";

export function SidebarBrand() {
  return (
    <Box display={{ md: "block", base: "none" }}>
      <LanguexLogo h="42px" w="42px" mb="26px" />
    </Box>
  );
}

export default SidebarBrand;
