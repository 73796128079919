import {
  Box,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import _ from "lodash";
import { axiosService } from "network/axios";
import { useEffect, useState } from "react";

export default function Roles() {
  const [isFetching, setIsFetching] = useState(true);
  const [userRoles, setUserRoles] = useState([]);

  useEffect(() => {
    fetchUserRoles();
  }, []);

  const fetchUserRoles = async () => {
    setIsFetching(true);
    const resp: any = await axiosService.get("/get/user-roles");
    setUserRoles(resp?.data?.results || []);
    setIsFetching(false);
  };
  return (
    <Box>
      <Text
        fontSize="17px"
        lineHeight="24px"
        color="#000"
        fontWeight="700"
        whiteSpace="nowrap"
        mr="50px"
        w="142px"
        minW="142px"
        mb="20px"
      >
        Roles
      </Text>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th isNumeric width="20px">
                No
              </Th>
              <Th>User Role</Th>
              <Th>Role Description</Th>
            </Tr>
          </Thead>
          <Tbody>
            {!isFetching &&
              userRoles?.map((it: any, index: number) => (
                <Tr key={index}>
                  <Td isNumeric>{index + 1}</Td>
                  <Td>{it.user_role_type_name}</Td>
                  <Td>{it.user_role_type_description}</Td>
                </Tr>
              ))}
            {isFetching &&
              _.range(1, 5)?.map((it) => (
                <Tr key={it}>
                  <Td>
                    <Skeleton height="20px"></Skeleton>
                  </Td>
                  <Td>
                    <Skeleton height="20px"></Skeleton>
                  </Td>
                  <Td>
                    <Skeleton height="20px"></Skeleton>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}
