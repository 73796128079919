import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  CloseButton,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
} from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import { Field, Formik } from "formik";
import DefaultAuth from "layouts/auth/Default";
import { axiosService } from "network/axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAlertMessageRequest } from "store/ui/reducer";

type ForgotFormInputs = {
  email: string;
};

const inputStyle = {
  fontSize: "14px",
  lineHeight: "17px",
  textAlign: "center",
  color: "black",
  border: "none !important",
  boxShadow: "none !important",
  bgColor: "transparent",
};

const inputStyleNormal = {
  fontSize: "14px",
  lineHeight: "17px",
  textAlign: "center",
  color: "black",
  border: "1px solid #E9E9E9",
  borderRadius: "50px",
  padding: "15px 30px",
  height: "60px",
  bgColor: "#FFF",
  boxShadow: "none !important",
};

export default function Forgot() {
  const initialValues: ForgotFormInputs = {
    email: "",
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const bgShadow = useColorModeValue(
    "0px 3.60347px 45.0434px rgba(0, 0, 0, 0.1)",
    "unset"
  );
  const buttonBg = "blue.500";
  const textColor = useColorModeValue("navy.700", "white");
  const [isProcessing, setIsProcessing] = useState(false);
  const [statusCode, setStatusCode] = useState(null);
  const borderColor = "gray.100";

  const onRequestResetPassword = async (payload: any) => {
    setIsProcessing(true);
    setStatusCode(null);
    try {
      await axiosService.post("/recovery", {
        ...payload,
      });
      setStatusCode("succeed");
    } catch (error: any) {
      setStatusCode(null);
      dispatch(
        setAlertMessageRequest({
          type: "error",
          message:
            error?.data?.messages?.error ||
            "Error occured in requesting password reset.",
        })
      );
      return;
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <DefaultAuth>
      <Flex
        maxW="520px"
        w="100%"
        mx="auto"
        my="auto"
        pt="40px"
        pb="10px"
        px="20px"
        h="fit-content"
        alignItems="start"
        justifyContent="center"
        flexDirection="column"
        borderRadius="18px"
        bgColor={"#FFF"}
        boxShadow={bgShadow}
      >
        <Heading
          w="100%"
          color={textColor}
          fontFamily="Canela Text"
          fontSize="24px"
          lineHeight="135%"
          mb={"30px"}
          textAlign="center"
        >
          Password Recovery
        </Heading>
        {statusCode !== null && (
          <Alert status="success" mb="20px">
            <AlertIcon color={"#222"} />
            <Box>
              <AlertDescription color={"#222"} fontSize="14px">
                Your request was submitted successfully. Please check your email
                box to reset the password.
              </AlertDescription>
            </Box>
            <CloseButton
              ml="auto"
              position="relative"
              color="#222"
              onClick={() => setStatusCode(null)}
            />
          </Alert>
        )}
        <Formik
          initialValues={initialValues}
          onSubmit={(values: any) => {
            onRequestResetPassword(values);
          }}
        >
          {({ handleSubmit, errors, touched }) => (
            <form onSubmit={handleSubmit} style={{ width: "100%" }}>
              <Flex
                zIndex="2"
                direction="column"
                padding={{ md: "10px 40px 30px", base: "0px 0px 20px" }}
                w="full"
              >
                <FormControl isInvalid={!!errors.email && touched.email}>
                  <Field
                    as={Input}
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    sx={{
                      ...inputStyleNormal,
                      fontWeight: "500",
                    }}
                    _placeholder={{
                      ...inputStyle,
                      fontWeight: "400",
                    }}
                    validate={(value: any) => {
                      let error;
                      if (value.length < 1) {
                        error = "Email Address is required.";
                      }
                      return error;
                    }}
                  />
                  <FormErrorMessage
                    mt={1}
                    sx={{
                      color: "#F00",
                      justifyContent: "center",
                    }}
                  >
                    {errors.email}
                  </FormErrorMessage>
                </FormControl>
              </Flex>
              <Flex
                flexDirection="row"
                justifyContent="center"
                alignItems="start"
                w="full"
                columnGap={"12px"}
                borderTop="1px solid"
                borderColor={borderColor}
                padding={{ md: "30px 40px", base: "20px 0px" }}
                // padding={"30px 40px"}
              >
                <Button
                  fontSize="14px"
                  lineHeight="17px"
                  variant="brand"
                  bgColor="white"
                  color="blue.500"
                  fontWeight="700"
                  padding="14px 54px"
                  h="45px"
                  w="100%"
                  borderRadius="90px"
                  onClick={() => {
                    navigate("/auth/sign-in");
                  }}
                  _hover={{
                    bgColor: buttonBg,
                    color: "#FFF",
                  }}
                >
                  Back To Login
                </Button>
                <Button
                  type="submit"
                  fontSize="14px"
                  lineHeight="17px"
                  variant="brand"
                  bgColor={buttonBg}
                  fontWeight="700"
                  padding="14px 54px"
                  h="45px"
                  w="100%"
                  borderRadius={"90px"}
                  isLoading={isProcessing}
                >
                  Submit
                </Button>
              </Flex>
            </form>
          )}
        </Formik>
      </Flex>
    </DefaultAuth>
  );
}
