/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Box,
  Flex,
  Heading,
  IconButton,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
} from "@chakra-ui/react";
import { IconClose } from "components/icons/Icons";

import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { axiosService } from "network/axios";
import emptyAvatar from "assets/img/avatars/avatar.png";
import UserInfoMenu from "components/menu/UserInfoMenu";

export default function AdminDetailPopup(props: {
  selectedAdminId: any | null;
  onCloseModal: Function;
}) {
  const dispatch = useDispatch();
  const { selectedAdminId, onCloseModal } = props;
  const [isFetching, setIsFetching] = useState(true);
  const [adminDetails, setAdminDetails] = useState(null);

  useEffect(() => {
    if (selectedAdminId && dispatch) {
      // Fetch Manager Details
      fetchAdminDetails(selectedAdminId);
    }
  }, [dispatch, selectedAdminId]);

  const fetchAdminDetails = async (adminId: string) => {
    setIsFetching(true);
    try {
      const resp = await axiosService.get(`/user/admin/details/${adminId}`);
      setAdminDetails(resp.data);
    } catch (err: any) {
    } finally {
      setIsFetching(false);
    }
  };

  const adminDetailsdata = useMemo(() => {
    if (adminDetails) {
      const { base } = adminDetails;
      const fullName = `${base.first_name} ${base.last_name}`;
      const avatar = base.profile_pic_src
        ? base.profile_pic_src?.includes("http")
          ? base.profile_pic_src
          : `${process.env.REACT_APP_API_BASE_URL}/public_html/images/${base.profile_pic_src}`
        : emptyAvatar;
      return {
        full_name: fullName,
        avatar: avatar,
        contact: {
          email: base.email ?? "Undefined",
          phone: base.phone ?? "Undefined",
        },
      };
    } else {
      return null;
    }
  }, [adminDetails]);

  return (
    <Box
      w={{
        base: "100%",
        xl: "calc(100vw - 230px)",
        baseWidth: "calc(100vw - (100vw - 1440px) / 2 - 230px)",
      }}
      transition="transform 0.3s ease-in-out"
      transform={selectedAdminId ? `translateX(0)` : `translateX(100%)`}
      h="100vh"
      position="fixed"
      bgColor="#FFF"
      zIndex="200"
      boxShadow={selectedAdminId ? "0px 4px 40px rgba(0, 0, 0, 0.1)" : "none"}
      boxSizing="border-box"
      padding={{
        md: "50px 40px 60px",
        sm: "60px 30px 30px",
        base: "60px 30px 30px",
        baseWidth: "50px calc((100vw - 1440px) / 2 + 40px) 50px 60px",
      }}
      overflow="auto"
      top="0px"
      right="0px"
    >
      {/* {adminDetailsdata && !isFetching && ( */}
      <Flex
        minH="100%"
        h="fit-content"
        overflow={"hidden"}
        pr="20px"
        mr="-20px"
      >
        <IconButton
          onClick={(e) => onCloseModal()}
          aria-label="close"
          minW={"unset"}
          h={{ md: "52px", sm: "32px" }}
          w={{ md: "52px", sm: "32px" }}
          borderRadius="50%"
          bg="inherit"
          marginRight={{ md: "60px", sm: "0px", base: "0px" }}
          position={{ md: "unset", sm: "absolute", base: "absolute" }}
          right={{ md: "unset", sm: "30px", base: "30px" }}
          top={{ md: "unset", sm: "10px", base: "10px" }}
          _active={{
            bg: "inherit",
            transform: "none",
            borderColor: "transparent",
          }}
          _focus={{
            boxShadow: "none",
          }}
          icon={
            <IconClose
              h={{ md: "52px", sm: "32px" }}
              w={{ md: "52px", sm: "32px" }}
            />
          }
        />

        <Flex flex="1" direction="column" width="0">
          <Flex
            direction={{ md: "row", sm: "column-reverse" }}
            justifyContent="space-between"
          >
            <Flex alignItems="center" mt={{ md: "0px", base: "20px" }}>
              {isFetching ? (
                <Skeleton width="200px" height="35px"></Skeleton>
              ) : (
                <Heading
                  fontSize="25px"
                  lineHeight="135%"
                  fontWeight="400"
                  color="#000"
                >
                  {adminDetailsdata ? adminDetailsdata.full_name : "Undefined"}
                </Heading>
              )}
            </Flex>
            <Box ml="auto">
              <UserInfoMenu />
            </Box>
          </Flex>

          <Box mt={{ md: "50px", base: "20px" }} mb="50px">
            <Flex flexWrap="wrap" columnGap="30px" alignItems="center">
              {!isFetching ? (
                <Avatar
                  src={adminDetailsdata.avatar}
                  _hover={{ cursor: "pointer" }}
                  color="white"
                  name="Name"
                  bg="transparent"
                  size="sm"
                  w={{ md: "125px", base: "100px" }}
                  h={{ md: "125px", base: "100px" }}
                />
              ) : (
                <Skeleton
                  w={{ md: "125px", base: "100px" }}
                  h={{ md: "125px", base: "100px" }}
                  borderRadius="50%"
                />
              )}

              <Box>
                <TableContainer>
                  <Table variant="simple">
                    <Tbody>
                      <Tr>
                        <Td py={2} px={3}>
                          Full Name
                        </Td>
                        <Td py={2} px={3}>
                          {isFetching ? (
                            <Skeleton
                              w="200px"
                              h="20px"
                              colorScheme="#F3F5F5"
                            />
                          ) : adminDetailsdata ? (
                            adminDetailsdata.full_name
                          ) : (
                            "Undefined"
                          )}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td py={2} px={3}>
                          Email Address
                        </Td>
                        <Td py={2} px={3}>
                          {isFetching ? (
                            <Skeleton
                              w="200px"
                              h="20px"
                              colorScheme="#F3F5F5"
                            />
                          ) : adminDetailsdata ? (
                            adminDetailsdata.contact.email
                          ) : (
                            "Undefined"
                          )}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td py={2} px={3}>
                          Phone Number
                        </Td>
                        <Td py={2} px={3}>
                          {isFetching ? (
                            <Skeleton
                              w="200px"
                              h="20px"
                              colorScheme="#F3F5F5"
                            />
                          ) : adminDetailsdata ? (
                            adminDetailsdata.contact.phone
                          ) : (
                            "Undefined"
                          )}
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
}
