/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Flex,
  IconButton,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
// Custom components
import Card from "components/card/Card";
import { IconArrowRight, IconInfo } from "components/icons/Icons";
import * as React from "react";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import { fetchUnreadMessagesCnt, pad_with_zeroes } from "network/helper";
import { unreadMessageSelector } from "store/project/selector";
import { useDispatch, useSelector } from "react-redux";
import { DeleteIcon, TimeIcon } from "@chakra-ui/icons";
import { isDeletingProjectSelector } from "store/customerProject/selector";
import _ from "lodash";
import { axiosService } from "network/axios";
import { setAlertMessageSuccess } from "store/ui/reducer";

type RowObj = {
  id: number;
  order_id: string;
  order_name: string;
  order_due_at: string;
  order_status_name: string;
  languages: ["English", "French"];
  manager: boolean;
  translator: string[];
  translation_type: string;
  translated_from: string | undefined;
  translated_to: string | undefined;
  actionCol: string;
  order_total: number;
  message_cnt: number;
  order_status_id: string;
  include_expedited_turnaround: string;
};

const columnHelper = createColumnHelper<RowObj>();

export default function CustomerProjectsTable(props: {
  isFetching: boolean;
  tableData: any;
  onShowCustomerProjectDetails: Function;
  pageLimit: number;
  onRefresh?: Function;
}) {
  const unreadMessages = useSelector(unreadMessageSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    isFetching,
    tableData,
    onShowCustomerProjectDetails,
    pageLimit,
    onRefresh,
  } = props;
  const textColor = useColorModeValue("black", "black");
  let defaultData = tableData;
  const mobileColumnWidths: any = {
    order_name: 200,
    order_status_name: 200,
    languages: 160,
    manager: 60,
    translator: 200,
    order_due_at: 120,
    actionCol: 160,
  };
  const [deletingOrderId, setDeletingOrderId] = React.useState(null);
  const isDeletingProject = useSelector(isDeletingProjectSelector);
  const onClickDeleteDraftOrder = async (order_id: Number) => {
    try {
      setDeletingOrderId(order_id);
      await axiosService.delete(`/projects/delete/${order_id}`);
      dispatch(
        setAlertMessageSuccess({
          type: "success",
          message: "Draft Project has been removed successfully!",
        })
      );
      onRefresh?.();
    } catch (err) {
      dispatch(
        setAlertMessageSuccess({
          type: "error",
          message: "Failed to remove the project.",
        })
      );
    } finally {
    }
  };

  const isStatusChanged = (order_id: number, order_status_id: string) => {
    let prev_status = localStorage.getItem("order_status")
      ? JSON.parse(localStorage.getItem("order_status"))
      : [];

    if (!localStorage.getItem("order_status")) {
      return null;
    }

    let matchedItem = prev_status?.find(
      (it: any) => Number(it.order_id) === order_id
    );
    if (
      !matchedItem ||
      Number(matchedItem?.order_status_id) === Number(order_status_id)
    ) {
      return null;
    } else {
      return `Order status has been changed,`;
    }
  };
  const columns = [
    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Project #
        </Text>
      ),
      cell: (info: any) => {
        return (
          <Flex align="center">
            <Text
              color={info.row.getValue("id") === "0012" ? "#F00" : textColor}
              fontSize="14px"
              lineHeight="24px"
              fontWeight="400"
              maxW={{ sm: "200px", md: "unset" }}
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
            >
              {pad_with_zeroes(info.getValue(), 6)}
            </Text>
          </Flex>
        );
      },
    }),

    columnHelper.accessor("order_status_name", {
      id: "order_status_name",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Status
        </Text>
      ),
      cell: (info) => (
        <Text
          color={textColor}
          fontSize="14px"
          lineHeight="24px"
          fontWeight="400"
          width="fit-content"
          height={"34px"}
          whiteSpace="nowrap"
          borderRadius="100px"
          padding="5px 20px"
          bgColor="#F3F5F5"
          textTransform="capitalize"
          position="relative"
        >
          {info.getValue()}
          {!_.isNull(
            isStatusChanged(
              Number(info.row.original.order_id),
              info.row.original.order_status_id
            )
          ) &&
            Number(info.row.original.order_status_id) !== 10 && (
              <Tooltip label="Order Status has been updated.">
                <IconInfo
                  sx={{
                    fontSize: "20px",
                    position: "absolute",
                    right: "-2px",
                    top: "-7px",
                    bgColor: "#FFF",
                    borderRadius: "50%",
                    path: {
                      fill: "#2F80ED",
                    },
                  }}
                />
              </Tooltip>
            )}
        </Text>
      ),
    }),
    columnHelper.accessor("translation_type", {
      id: "translation_type",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Type
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
            textTransform="capitalize"
          >
            {info.getValue() || "-"}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("order_due_at", {
      id: "order_due_at",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Due Date
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
          >
            {info.getValue()
              ? moment(new Date(info.getValue())).format("MM/DD/YY")
              : "-"}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("order_total", {
      id: "order_total",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Price
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
          >
            ${info.getValue() ? info.getValue() : "0.00"}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("message_cnt", {
      id: "message_cnt",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        ></Text>
      ),
      cell: (info) => (
        <Flex align="center">
          {fetchUnreadMessagesCnt(unreadMessages, info.row.original.id) > 0 && (
            <Text
              color="#FFF"
              bgColor="#F02828"
              borderRadius="100px"
              fontSize="14px"
              lineHeight="24px"
              fontWeight="400"
              padding="5px 20px"
              whiteSpace="nowrap"
            >
              {`${fetchUnreadMessagesCnt(
                unreadMessages,
                info.row.original.id
              )} New Message`}
            </Text>
          )}
        </Flex>
      ),
    }),

    columnHelper.accessor("actionCol", {
      id: "actionCol",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          color="gray.500"
          fontWeight="400"
        ></Text>
      ),
      cell: (info) => (
        <Flex
          align="center"
          width={"40px"}
          ml={Number(info.row.original.order_status_id) !== 10 ? "auto" : "0"}
        >
          {Number(info.row.original.order_status_id) === 10 && (
            <IconButton
              aria-label="delete"
              bg="inherit"
              color="#F02828"
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _hover={{
                bg: "inherit",
              }}
              _focus={{
                boxShadow: "none",
              }}
              onClick={async (e) => {
                e.stopPropagation();
                e.preventDefault();
                await onClickDeleteDraftOrder(
                  Number(info.row.original.order_id)
                );
              }}
              isLoading={
                isDeletingProject &&
                deletingOrderId === Number(info.row.original.order_id)
              }
              icon={<DeleteIcon w="20px" h="20px" color="#F02828" />}
            />
          )}
          <IconButton
            aria-label="search"
            bg="inherit"
            borderRadius="inherit"
            onClick={(e) => {
              if (info.row.original.order_status_name === "Draft") {
                navigate(`/customer/order/${info.row.original.order_id}`);
              } else {
                onShowCustomerProjectDetails(info.row.original.order_id);
              }
            }}
            _active={{
              bg: "inherit",
              transform: "none",
              borderColor: "transparent",
            }}
            _hover={{
              bg: "inherit",
            }}
            _focus={{
              boxShadow: "none",
            }}
            icon={<IconArrowRight w="49px" h="50px" />}
          />

          {Number(info.row.original.include_expedited_turnaround) === 1 &&
            Number(info.row.original.order_status_id) !== 6 &&
            Number(info.row.original.order_status_id) !== 10 && (
              <Tooltip label="Expedited Order">
                <TimeIcon
                  w="20px"
                  h="20px"
                  color="#F02828"
                  sx={{
                    position: "absolute",
                    right: "-8px",
                    top: "-8px",
                    borderRadius: "50%",
                    bgColor: "#FFF",
                  }}
                />
              </Tooltip>
            )}
        </Flex>
      ),
    }),
  ];
  const [data, setData] = React.useState(() => [...defaultData]);
  React.useEffect(() => {
    setData([...defaultData]);
  }, [defaultData]);

  React.useEffect(() => {
    const hash = window.location.hash ?? null;
    if (hash) {
      const projectId = hash.replace("#", "");
      onShowCustomerProjectDetails(projectId);
    }
  }, []);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  return (
    <Card
      flexDirection="column"
      w="calc(100% + 20px)"
      p="0px"
      px="10px"
      ml="-10px"
      overflowX="auto"
    >
      <Box>
        <Table
          variant="simple"
          color="gray.500"
          mb="24px"
          pb="2"
          lineHeight="15px"
        >
          <Thead>
            {table.getHeaderGroups().map((headerGroup, index: number) => (
              <Tr key={`header-${index}`}>
                {headerGroup.headers.map((header, _index: number) => {
                  return (
                    <Th
                      key={`header-col-${_index}`}
                      colSpan={header.colSpan}
                      px={{ lg: "20px", md: "10px", base: "10px" }}
                      border={"none"}
                      fontFamily="Inter"
                      cursor="pointer"
                      textTransform="capitalize"
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        lineHeight="24px"
                        color="gray.500"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {!isFetching &&
              table.getRowModel().rows.map((row: any, index: number) => {
                return (
                  <React.Fragment key={`trow-${index}`}>
                    <Tr
                      position="relative"
                      zIndex="1"
                      cursor="pointer"
                      onClick={() => {
                        if (row.original.order_status_name === "Draft") {
                          localStorage.removeItem("uploadProject");
                          localStorage.removeItem("orderStep");
                          navigate(`/customer/order/${row.original.order_id}`);
                        } else {
                          onShowCustomerProjectDetails(row.original.order_id);
                        }
                      }}
                      _after={{
                        content: '""',
                        position: "absolute",
                        border:
                          Number(row.original.include_expedited_turnaround) ===
                            1 &&
                          Number(row.original.order_status_id) !== 6 &&
                          Number(row.original.order_status_id) !== 10
                            ? "1px solid #EE000055"
                            : "1px solid #DCDCDC",
                        bgColor:
                          Number(row.original.include_expedited_turnaround) ===
                            1 &&
                          Number(row.original.order_status_id) !== 6 &&
                          Number(row.original.order_status_id) !== 10
                            ? "#EE000022"
                            : "transparent",
                        borderRadius: "10px",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: "-1",
                        transition: "all .25s ease-in-out",
                      }}
                      _hover={{
                        _after: {
                          backgroundColor:
                            Number(
                              row.original.include_expedited_turnaround
                            ) === 1 &&
                            Number(row.original.order_status_id) !== 6 &&
                            Number(row.original.order_status_id) !== 10
                              ? "#EE000020"
                              : "#F1F1F1",
                          borderColor:
                            Number(
                              row.original.include_expedited_turnaround
                            ) === 1 &&
                            Number(row.original.order_status_id) !== 6 &&
                            Number(row.original.order_status_id) !== 10
                              ? "#EE000055"
                              : "#F1F1F1",
                          boxShadow: "0px 2px 6px 3px rgba(0, 0, 0, 0.1)",
                        },
                      }}
                      _active={{
                        _after: {
                          boxShadow: "none",
                        },
                      }}
                    >
                      {row
                        .getVisibleCells()
                        .map((cell: any, _index: number) => {
                          return (
                            <Td
                              padding={{
                                lg: "20px",
                                md: "20px 10px",
                                base: "15px 10px",
                              }}
                              key={`trow-${index}-td-${_index}`}
                              fontSize={{ sm: "14px" }}
                              minW={{
                                base: `${mobileColumnWidths[cell.column.id]}px`,
                                md: `${mobileColumnWidths[cell.column.id]}px`,
                                lg: "auto",
                              }}
                              border="none !important"
                              width={
                                cell.column.id === "actionCol"
                                  ? "120px"
                                  : "auto"
                              }
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </Td>
                          );
                        })}
                    </Tr>
                    <Tr>
                      <Td
                        colSpan={7}
                        paddingTop="5px"
                        paddingBottom="10px"
                        border="none !important"
                      ></Td>
                    </Tr>
                  </React.Fragment>
                );
              })}

            {isFetching &&
              _.range(pageLimit).map((row) => {
                return (
                  <React.Fragment key={`skeleton-row-${row}`}>
                    <Tr
                      zIndex="1"
                      position="relative"
                      _after={{
                        content: '""',
                        position: "absolute",
                        border: "1px solid #DCDCDC",
                        borderRadius: "10px",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: "-1",
                        transition: "all .25s ease-in-out",
                      }}
                    >
                      {columns.map((it: any, colIndex: number) => {
                        return (
                          <Td
                            key={`skeleton-${colIndex}`}
                            padding={{
                              lg: "20px",
                              md: "20px 10px",
                              base: "15px 10px",
                            }}
                            border="none !important"
                          >
                            <Skeleton
                              height="25px"
                              colorScheme="#F3F5F5"
                              borderRadius="5px"
                            ></Skeleton>
                          </Td>
                        );
                      })}
                    </Tr>
                    <Tr>
                      <Td
                        colSpan={7}
                        paddingTop="5px"
                        paddingBottom="10px"
                        border="none !important"
                      ></Td>
                    </Tr>
                  </React.Fragment>
                );
              })}
          </Tbody>
        </Table>
      </Box>
    </Card>
  );
}
