/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex, Text } from "@chakra-ui/react";
import IconBox from "components/icons/IconBox";
import { IconCart, IconMobileCaretDown } from "components/icons/Icons";
import { ORDER_STEPS } from "types/data-types";
import { useSelector } from "react-redux";
import { uploadProjectSelector } from "store/customerProject/selector";
import { fetchPerPagePrice } from "network/helper";
import { useMemo, useState } from "react";
import OrderDetails from "../OrderDetails";
// import OrderDetails from "./OrderDetails";

export default function MobileOrderDetails(props: { step?: ORDER_STEPS }) {
  const { step } = props;
  const uploadProject = useSelector(uploadProjectSelector);
  // const isRedeemEnabled = localStorage.getItem("redeem") ? true : false;
  const isRedeemEnabled = true;
  const orderSummary = useMemo(() => {
    let totalPrice = 0;

    const targetLanguage = uploadProject.targetLanguage;
    totalPrice =
      uploadProject.transitionType === "certified"
        ? uploadProject.totalPage * fetchPerPagePrice(targetLanguage)
        : uploadProject.totalWords * 0.1;
    if (isRedeemEnabled && uploadProject.transitionType === "certified") {
      totalPrice =
        (uploadProject.totalPage - 1 >= 0 ? uploadProject.totalPage - 1 : 0) *
        fetchPerPagePrice(targetLanguage);
    }
    if (
      uploadProject.transitionType === "standard" &&
      uploadProject.totalWords < 250 &&
      uploadProject.totalWords > 0
    ) {
      totalPrice = 20;
    }

    if (
      step === ORDER_STEPS.SET_TRANSITION_DETAILS_STEP ||
      step === ORDER_STEPS.SET_PAYMENT_STEP
    ) {
      if (uploadProject.isNotarized) {
        totalPrice += 19.95;
      }

      if (uploadProject.isMailHardCopy) {
        totalPrice += 15;
      }

      if (uploadProject.transitionSpeed === "express") {
        if (uploadProject.transitionType === "certified") {
          if (isRedeemEnabled) {
            totalPrice +=
              10 *
              (uploadProject.totalPage - 1 >= 0
                ? uploadProject.totalPage - 1
                : 0) *
              targetLanguage.length;
          } else {
            totalPrice += 10 * uploadProject.totalPage * targetLanguage.length;
          }
          // totalPrice += 10 * uploadProject.totalPage * targetLanguage.length;
        } else {
          if (uploadProject.totalWords < 250) {
            totalPrice += 10 * targetLanguage.length;
          } else {
            totalPrice +=
              0.004 * uploadProject.totalWords * targetLanguage.length;
          }
        }
      }
    }

    return {
      words: uploadProject.totalWords,
      pages: uploadProject.totalPage,
      totalPrice: totalPrice,
      langCnt: targetLanguage.length + 1,
    };
  }, [uploadProject, step, isRedeemEnabled]);

  const [isShowFull, setIsShowFull] = useState(false);

  return (
    <>
      <Flex
        padding="16px"
        justifyContent="space-between"
        alignItems="center"
        position="fixed"
        width="100%"
        height="fit-content"
        bottom="0"
        left="0"
        borderTop="1px solid #F0F0F0"
        background="#FFF"
        boxShadow="0px 2px 2px 0px rgba(0, 0, 0, 0.05)"
        zIndex="10001"
        onClick={() => {
          setIsShowFull(!isShowFull);
        }}
      >
        <Flex alignItems="center">
          <IconBox w="16px" h="17px" icon={<IconCart w="16px" h="16px" />} />
          <Flex alignItems="center">
            <Text
              mx="8px"
              fontSize="15px"
              fontWeight="400"
              lineHeight="normal"
              color="#000"
            >
              Order Summary
            </Text>
            {isRedeemEnabled &&
              uploadProject.transitionType === "certified" && (
                <Box
                  className="tag"
                  padding="4px 8px"
                  borderRadius="8px"
                  backgroundColor="#00B67A"
                  fontSize="12px"
                  fontWeight="600"
                  lineHeight="normal"
                  color="#FFF"
                  my="10px"
                >
                  Redeem Enabled
                </Box>
              )}
          </Flex>
          <IconBox
            w="16px"
            h="16px"
            ml="5px"
            sx={{
              transition: "transform .25s ease-in-out",
              transform: isShowFull ? "rotate(-180deg)" : "rotate(0)",
            }}
            icon={<IconMobileCaretDown w="16px" h="16px" />}
          />
        </Flex>
        <Text
          textAlign="right"
          fontSize="15px"
          fontWeight="400"
          lineHeight="normal"
          color="#00B67A"
        >
          ${orderSummary.totalPrice.toFixed(2)}
        </Text>
      </Flex>
      <Box
        width="100%"
        height="100vh"
        overflow="auto"
        position="fixed"
        bottom="0px"
        left="0px"
        zIndex="1000"
        padding="25px 15px"
        background="#F7F7F9"
        boxShadow="0px 2px 2px 0px rgba(0, 0, 0, 0.05)"
        transition="transform .25s ease-in-out"
        transform={!isShowFull ? "translateY(calc(100% + 20px))" : ""}
      >
        <OrderDetails step={step} />
      </Box>
    </>
  );
}
