/* eslint-disable react-hooks/exhaustive-deps */
import {
  AddIcon,
  BellIcon,
  ChevronRightIcon,
  StarIcon,
  TimeIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Progress,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import IconBox from "components/icons/IconBox";
import { IconTeam } from "components/icons/Icons";
import { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment-timezone";
import { Doughnut } from "react-chartjs-2";
import {
  Chart,
  ArcElement,
  Legend,
  Tooltip,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import { useSelector } from "react-redux";
import { userSelector } from "store/user/selector";
import _, { isEmpty } from "lodash";
import { axiosService } from "network/axios";
import {
  fetchStatusName,
  generateQueryParameters,
  getProjectStatusAsPercent,
  groupByAndCount,
  pad_with_zeroes,
} from "network/helper";
import { useNavigate } from "react-router-dom";
import CustomPaginations from "components/pagination/CustomPaginations";
Chart.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
);

export default function AdminDashboard() {
  const boxShadow = "0px 4px 10px rgba(0, 0, 0, 0.1)";
  const [dashboardIndex] = useState(-1);
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [userDashboardData, setUserDashboardData] = useState(null);
  const [projectData, setProjectData] = useState(null);
  const [dashboardTile, setDashboardTile] = useState(null);
  const [lastUpdatedOrders, setLastUpdatedOrders] = useState(null);
  const [notifications, setNotifications] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(20);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [isFetchingAll, setIsFetchingAll] = useState(false);
  const [totalNotifications, setTotalNotifications] = useState([]);

  const queryParam = useMemo(() => {
    return {
      page: currentPage,
      limit: pageLimit,
    };
  }, [currentPage, pageLimit]);

  const user = useSelector(userSelector);
  useEffect(() => {
    if (!isEmpty(user?.user_id)) {
      fetchSummary(Number(user?.user_id));
    }
  }, [user?.user_id]);

  const fetchSummary = async (user_id: number) => {
    try {
      setIsFetching(true);
      const { data }: any = await axiosService.get(
        `/projects/summary/${user.user_id}`
      );

      const groupLanauges = Object.keys(
        groupByAndCount(data?.languages ?? [])
      )?.join(", ");

      setUserDashboardData({
        admins: data?.admin ?? 0,
        managers: data?.managers ?? 0,
        translators: data?.translators ?? 0,
        businessAccounts: data?.businessAccounts ?? 0,
        individualAccounts:
          (data?.totalUsers ?? 0) - (data?.businessAccounts ?? 0),
      });

      setProjectData({
        labels: ["New", "Active", "Due Soon", "In Reviews", "Completed"],
        datasets: [
          {
            label: "Projects",
            data: [
              data?.newOrders ?? 0,
              data?.activeOrders ?? 0,
              data?.dueSoonOrders ?? 0,
              data?.reviewOrders ?? 0,
              data?.completedOrders ?? 0,
            ],
            backgroundColor: [
              "rgba(255, 99, 132, 0.6)",
              "rgba(54, 162, 235, 0.6)",
              "rgba(255, 206, 86, 0.6)",
              "rgba(75, 192, 192, 0.6)",
              "rgba(153, 102, 255, 0.6)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
            ],
            borderWidth: 1,
          },
        ],
      });

      setDashboardTile({
        totalOrders: data?.totalOrders ?? 0,
        notifications: data?.notificationsCnt ?? 0,
        lastVisited: "2023-12-11 09:12:15",
        mostAppearances: groupLanauges,
      });

      setLastUpdatedOrders(data?.latestOrders ?? []);
      setNotifications(data?.notifications ?? []);
    } catch (err) {
      console.log(err);
    } finally {
      setIsFetching(false);
    }
  };

  const goToOrdersPage = () => {
    navigate("/admin/projects");
  };

  const onClickViewAllMessages = () => {
    onOpen();
  };

  const openProjectDetails = (order_id: string) => {
    navigate(`/admin/projects#${order_id}`);
  };

  const fetchResults = useCallback(async () => {
    setIsFetchingAll(true);
    const params = generateQueryParameters(queryParam);
    try {
      const resp: any = await axiosService.get(
        `/projects/notifications/${user?.user_id}?${params}`
      );
      const { results, total_rows } = resp?.data;
      setTotalNotifications(
        results?.map((it: any) => ({
          ...it,
          id: it?.order_id,
        }))
      );

      setTotalPages(
        total_rows / pageLimit > Math.floor(total_rows / pageLimit)
          ? Math.floor(total_rows / pageLimit) + 1
          : Math.floor(total_rows / pageLimit)
      );
      setTotalRows(total_rows);
    } catch (err) {
      setTotalNotifications([]);
    } finally {
      setIsFetchingAll(false);
    }
  }, [queryParam]);

  useEffect(() => {
    fetchResults();
  }, [queryParam]);

  return (
    <Box pt={{ base: "60px", md: "80px", xl: "80px" }}>
      <Grid
        gap="10px"
        templateColumns={{ md: "repeat(2, 1fr)", base: "repeat(1, 1fr)" }}
        mb="20px"
      >
        <GridItem w="100%" height="inherit">
          <Box boxShadow={boxShadow} padding="20px" borderRadius="10px">
            <Table>
              <Tbody>
                <Tr>
                  <Td rowSpan={3}>Team: </Td>
                  <Td py="10px">
                    Number of Admins:{" "}
                    <strong>{userDashboardData?.admins ?? 0}</strong>
                  </Td>
                </Tr>
                <Tr>
                  <Td py="10px">
                    Number of Managers:{" "}
                    <strong>{userDashboardData?.managers ?? 0}</strong>
                  </Td>
                </Tr>
                <Tr>
                  <Td py="10px">
                    Number of Translators:{" "}
                    <strong>{userDashboardData?.translators ?? 0}</strong>
                  </Td>
                </Tr>
                <Tr>
                  <Td rowSpan={2}>Customers: </Td>
                  <Td py="10px">
                    Business Accounts:{" "}
                    <strong>{userDashboardData?.businessAccounts ?? 0}</strong>
                  </Td>
                </Tr>
                <Tr>
                  <Td py="10px">
                    Individual Accounts:{" "}
                    <strong>
                      {userDashboardData?.individualAccounts ?? 0}
                    </strong>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
            <Box
              display="flex"
              columnGap="10px"
              rowGap="10px"
              mt="20px"
              justifyContent="flex-end"
              flexDirection={{ "3sm": "row", base: "column" }}
            >
              <Button
                type="button"
                w="100%"
                height="44px"
                padding="13px 26px"
                borderRadius="0px"
                bgColor="#2F80ED"
                color="#FFF"
                fontSize="14px"
                lineHeight="14px"
                fontWeight="bold"
                onClick={() => {
                  navigate("/admin/customers#showModal");
                }}
              >
                <AddIcon mr="10px" />
                Invite customer
              </Button>
              <Button
                type="button"
                w="100%"
                height="44px"
                padding="13px"
                borderRadius="0px"
                bgColor="#2F80ED"
                color="#FFF"
                fontSize="14px"
                lineHeight="14px"
                fontWeight="bold"
                onClick={() => {
                  navigate("/admin/team#showModal");
                }}
              >
                <AddIcon mr="10px" />
                Invite team member
              </Button>
            </Box>
          </Box>
        </GridItem>
        <GridItem
          w="100%"
          display="flex"
          flexDir="column"
          alignItems="center"
          p={5}
        >
          <Text textAlign="left" mb="30px" fontSize="20px">
            Orders By Status
          </Text>
          <Box
            sx={{
              canvas: {
                height: { "3sm": "180px !important", base: "140px !important" },
              },
            }}
          >
            {projectData && (
              <Doughnut
                data={projectData}
                options={{
                  responsive: true,
                  layout: {
                    autoPadding: true,
                  },
                  aspectRatio: 2,
                  plugins: {
                    legend: {
                      position: "right",
                    },
                  },
                }}
              />
            )}
          </Box>
        </GridItem>
      </Grid>
      <Box gap="20px" display="flex" flexWrap="wrap">
        <Box
          height="inherit"
          flexBasis={{ md: "unset", "3sm": "calc(50% - 10px)", base: "100%" }}
        >
          <Flex
            flexWrap="wrap"
            alignItems="center"
            boxShadow={boxShadow}
            padding="20px"
            borderRadius="10px"
            bgColor={dashboardIndex === 0 ? "#2F80ED" : "#FFF"}
            color={dashboardIndex === 0 ? "#FFF" : "#000"}
            cursor="pointer"
          >
            <IconBox
              w="50px"
              h="50px"
              sx={{
                path: {
                  fill: dashboardIndex === 0 ? "#FFF" : "#000",
                },
              }}
              icon={<IconTeam w="50px" h="50px" />}
            />
            <Box ml="10px">
              <Text fontSize="16px">Total Orders</Text>
              <Text
                fontSize={{ md: "24px", base: "20px" }}
                fontWeight="bold"
                lineHeight="140%"
              >
                {dashboardTile?.totalOrders ?? 0}
              </Text>
            </Box>
          </Flex>
        </Box>
        <Box
          height="inherit"
          flexBasis={{ md: "unset", "3sm": "calc(50% - 10px)", base: "100%" }}
        >
          <Flex
            alignItems="center"
            boxShadow={boxShadow}
            cursor="pointer"
            padding="20px"
            borderRadius="10px"
            bgColor={dashboardIndex === 1 ? "#2F80ED" : "#FFF"}
            color={dashboardIndex === 1 ? "#FFF" : "#000"}
          >
            <IconBox w="50px" h="50px" icon={<TimeIcon w="40px" h="40px" />} />
            <Box ml="10px">
              <Text fontSize="16px">Last Visited</Text>
              <Text
                fontSize={{ md: "24px", base: "20px" }}
                fontWeight="bold"
                lineHeight="140%"
              >
                {dashboardTile?.lastVisited ?? "-"}
              </Text>
            </Box>
          </Flex>
        </Box>
        <Box
          height="inherit"
          flexBasis={{ md: "unset", "3sm": "calc(50% - 10px)", base: "100%" }}
        >
          <Flex
            flexWrap="wrap"
            alignItems="center"
            boxShadow={boxShadow}
            padding="20px"
            borderRadius="10px"
            bgColor="#FFF"
          >
            <IconBox w="50px" h="50px" icon={<StarIcon w="40px" h="40px" />} />
            <Box ml="10px">
              <Text fontSize="16px">Most Used Languages</Text>
              <Text
                fontSize={{ md: "24px", base: "20px" }}
                fontWeight="bold"
                lineHeight="140%"
              >
                {dashboardTile?.mostAppearances ?? ""}
              </Text>
            </Box>
          </Flex>
        </Box>
        <Box
          height="inherit"
          flexBasis={{ md: "unset", "3sm": "calc(50% - 10px)", base: "100%" }}
        >
          <Flex
            flexWrap="wrap"
            alignItems="center"
            boxShadow={boxShadow}
            padding="20px"
            borderRadius="10px"
            bgColor="#FFF"
          >
            <IconBox w="50px" h="50px" icon={<BellIcon w="40px" h="40px" />} />
            <Box ml="10px">
              <Text fontSize="16px">Notifications</Text>
              <Text
                fontSize={{ md: "24px", base: "20px" }}
                fontWeight="bold"
                lineHeight="140%"
                color="#F00"
              >
                {dashboardTile?.notifications ?? 0}
              </Text>
            </Box>
          </Flex>
        </Box>
      </Box>
      <Grid
        gap="10px"
        templateColumns={{ lg: "repeat(2, 1fr)", base: "repeat(1, 1fr)" }}
        mt="30px"
      >
        <GridItem w="100%">
          <Box
            boxShadow={boxShadow}
            padding="30px"
            borderRadius="10px"
            height="100%"
          >
            <Text fontSize="20px">Latest Updates on Orders</Text>
            <Box
              maxW={{ md: "unset", base: "calc(100vw - 100px)" }}
              overflow="auto"
            >
              <Table mt="20px" fontSize="14px">
                <Thead>
                  <Th padding="10px" whiteSpace="nowrap">
                    #Order
                  </Th>
                  <Th padding="10px" whiteSpace="nowrap">
                    Status
                  </Th>
                  <Th padding="10px" whiteSpace="nowrap">
                    Project Manager
                  </Th>
                  <Th padding="10px" whiteSpace="nowrap">
                    % Done
                  </Th>
                </Thead>
                <Tbody>
                  {!isFetching &&
                    lastUpdatedOrders?.length > 0 &&
                    lastUpdatedOrders?.map((it: any, index: number) => (
                      <Tr key={index}>
                        <Td padding="11px" whiteSpace="nowrap">
                          #{pad_with_zeroes(it?.order_id, 6)}
                        </Td>
                        <Td padding="11px" whiteSpace="nowrap">
                          {fetchStatusName(Number(it?.order_status_id))}
                        </Td>
                        <Td padding="11px" whiteSpace="nowrap">
                          {it?.managers?.length > 0
                            ? `${[
                                it?.managers[0]?.first_name,
                                it?.managers[0]?.last_name,
                              ].join(" ")}`
                            : "Not Assigned"}
                        </Td>
                        <Td padding="11px" whiteSpace="nowrap">
                          <Progress
                            colorScheme="green"
                            size="sm"
                            width="100%"
                            value={getProjectStatusAsPercent(it?.documents)}
                          />
                        </Td>
                      </Tr>
                    ))}
                  {isFetching &&
                    _.range(0, 5).map((it) => (
                      <Tr key={it}>
                        <Td padding="10px">
                          <Skeleton height="22px" width="100%"></Skeleton>
                        </Td>
                        <Td padding="10px">
                          <Skeleton height="22px" width="100%"></Skeleton>
                        </Td>
                        <Td padding="10px">
                          <Skeleton height="22px" width="100%"></Skeleton>
                        </Td>
                        <Td padding="10px">
                          <Skeleton height="22px" width="100%"></Skeleton>
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </Box>
            <Flex>
              <Button
                type="button"
                w="fit-content"
                height="44px"
                padding="13px 26px"
                borderRadius="0px"
                bgColor="#2F80ED"
                color="#FFF"
                fontSize="14px"
                lineHeight="14px"
                fontWeight="bold"
                mt="20px"
                onClick={() => {
                  goToOrdersPage();
                }}
              >
                View All
              </Button>
            </Flex>
          </Box>
        </GridItem>
        <GridItem w="100%">
          <Box
            boxShadow={boxShadow}
            padding="30px"
            borderRadius="10px"
            height="100%"
            w="100%"
          >
            <Text fontSize="20px">Unread Messages</Text>
            <Box
              maxW={{ md: "unset", base: "calc(100vw - 100px)" }}
              overflow="auto"
            >
              <Table mt="20px" fontSize="14px" maxWidth="100%">
                <Thead>
                  <Th padding="10px">#Order</Th>
                  <Th padding="10px">Time</Th>
                  <Th padding="10px">From</Th>
                  <Th padding="10px">Message</Th>
                  <Th></Th>
                </Thead>
                <Tbody>
                  {!isFetching &&
                    notifications?.length > 0 &&
                    notifications.slice(0, 5)?.map((it: any, index: number) => (
                      <Tr key={index}>
                        <Td padding="10px" whiteSpace="nowrap">
                          #{pad_with_zeroes(it.order_id, 6)}
                        </Td>
                        <Td padding="10px" whiteSpace="nowrap">
                          {moment(new Date(it.created_at)).format(
                            "MM/DD/YY HH:mm"
                          )}
                        </Td>
                        <Td padding="10px" whiteSpace="nowrap">
                          {it.sender ?? "Unknown"}
                        </Td>
                        <Td padding="10px" width="100%">
                          <Text
                            whiteSpace="nowrap"
                            textOverflow="ellipsis"
                            overflow="hidden"
                            width="100px"
                          >
                            {it.message}
                          </Text>
                        </Td>
                        <Td>
                          <IconBox
                            cursor="pointer"
                            w="20px"
                            h="10px"
                            onClick={() => {
                              openProjectDetails(it?.order_id);
                            }}
                            icon={<ChevronRightIcon w="20px" h="20px" />}
                          />
                        </Td>
                      </Tr>
                    ))}
                  {isFetching &&
                    _.range(0, 5).map((it) => (
                      <Tr key={it}>
                        <Td padding="10px">
                          <Skeleton height="22px" width="100%"></Skeleton>
                        </Td>
                        <Td padding="10px">
                          <Skeleton height="22px" width="100%"></Skeleton>
                        </Td>
                        <Td padding="10px">
                          <Skeleton height="22px" width="100%"></Skeleton>
                        </Td>
                        <Td padding="10px">
                          <Skeleton height="22px" width="100%"></Skeleton>
                        </Td>
                        <Td padding="10px">
                          <Skeleton height="22px" width="100%"></Skeleton>
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </Box>

            <Flex>
              <Button
                type="button"
                w="fit-content"
                height="44px"
                padding="13px 26px"
                borderRadius="0px"
                bgColor="#2F80ED"
                color="#FFF"
                fontSize="14px"
                lineHeight="14px"
                fontWeight="bold"
                onClick={() => {
                  onClickViewAllMessages();
                }}
                mt="20px"
              >
                View All
              </Button>
            </Flex>
          </Box>
        </GridItem>
      </Grid>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text textAlign="center">All Unread Messages</Text>
          </ModalHeader>
          <ModalCloseButton onClick={onClose} />
          <ModalBody pb="30px">
            <Table mt="20px" fontSize="14px" maxWidth="100%">
              <Thead>
                <Th padding="10px">#Order</Th>
                <Th padding="10px">Time</Th>
                <Th padding="10px">From</Th>
                <Th padding="10px">Message</Th>
                <Th></Th>
              </Thead>
              <Tbody>
                {!isFetchingAll &&
                  totalNotifications?.length > 0 &&
                  totalNotifications?.map((it: any, index: number) => (
                    <Tr key={index}>
                      <Td padding="10px" whiteSpace="nowrap">
                        #{pad_with_zeroes(it.order_id, 6)}
                      </Td>
                      <Td padding="10px" whiteSpace="nowrap">
                        {moment(new Date(it.created_at)).format(
                          "MM/DD/YY HH:mm"
                        )}
                      </Td>
                      <Td
                        padding="10px"
                        whiteSpace="nowrap"
                        maxW="150px"
                        overflow="hidden"
                      >
                        {it.sender ?? "Unknown"}
                      </Td>
                      <Td padding="10px" width="100%">
                        <Text
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                          overflow="hidden"
                          width="100px"
                        >
                          {it.message}
                        </Text>
                      </Td>
                      <Td>
                        <IconBox
                          cursor="pointer"
                          w="20px"
                          h="10px"
                          onClick={() => {
                            openProjectDetails(it?.order_id);
                          }}
                          icon={<ChevronRightIcon w="20px" h="20px" />}
                        />
                      </Td>
                    </Tr>
                  ))}
                {isFetchingAll &&
                  _.range(0, pageLimit).map((it) => (
                    <Tr key={it}>
                      <Td padding="10px">
                        <Skeleton height="22px" width="100%"></Skeleton>
                      </Td>
                      <Td padding="10px">
                        <Skeleton height="22px" width="100%"></Skeleton>
                      </Td>
                      <Td padding="10px">
                        <Skeleton height="22px" width="100%"></Skeleton>
                      </Td>
                      <Td padding="10px">
                        <Skeleton height="22px" width="100%"></Skeleton>
                      </Td>
                      <Td padding="10px">
                        <Skeleton height="22px" width="100%"></Skeleton>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
            <CustomPaginations
              totalCount={totalRows}
              totalPages={totalPages}
              currentPage={currentPage}
              perPage={pageLimit}
              onSetPageLimit={(e: number) => {
                setPageLimit(e);
                setCurrentPage(1);
              }}
              onChangePage={(e: number) => {
                setCurrentPage(e);
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
