/* eslint-disable react-hooks/exhaustive-deps */
import { Flex, Text } from "@chakra-ui/react";
import IconBox from "components/icons/IconBox";
import { IconRemoveFile } from "components/icons/Icons";
import { DocumentType } from "../../variables/orderData";

export default function TranslationFileRow(props: {
  rowData: DocumentType;
  onRemoveFile: Function;
}) {
  const { rowData, onRemoveFile /*, onChangePageNumber */ } = props;

  return (
    <Flex
      mb="12px"
      alignItems="center"
      padding="10px 16px"
      border="1px solid #E0E0E0"
      borderRadius="100px"
      columnGap="16px"
      justifyContent="space-between"
      cursor="pointer"
    >
      <Text fontSize="14px" lineHeight="135%" fontWeight="400" color="#000">
        {rowData?.file?.name}
      </Text>
      <IconBox
        onClick={() => {
          onRemoveFile(rowData.id.toString());
        }}
        w="32px"
        h="32px"
        sx={{
          borderRadius: "50%",
          transition: "all .25s ease-in-out",
          "&:hover": {
            backgroundColor: "#DEDEDE",
          },
          "&:active": {
            backgroundColor: "#BBB",
          },
        }}
        icon={<IconRemoveFile w="24px" h="24px" />}
      />
    </Flex>
  );
}
