export const translatorProfileMenu = [
  {
    id: 1,
    label: 'Account Details',
    link: 'account-details'
  }, {
    id: 2,
    label: 'Personal Details',
    link: 'personal-details'
  }, {
    id: 3,
    label: 'Invoicing & Payroll',
    link: 'invocing-payroll'
  }
  // , {
    // id: 4,
    // label: 'Team',
    // link: 'team'
  // }
]