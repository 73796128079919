export const customerProfileMenu = [
  {
    id: 1,
    label: "Account Details",
    link: "account-details",
    isRequiredBusiness: false,
  },
  {
    id: 2,
    label: "Billing & Reports",
    link: "billing-reports",
    isRequiredBusiness: false,
  },
  {
    id: 4,
    label: "Team",
    link: "team",
    isRequiredBusiness: false,
  },
];
