/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import IconBox from "components/icons/IconBox";
import {
  IconCardDiscover,
  IconCardExpress,
  IconCardMaster,
  IconCardVisa,
  IconCheckedSmall,
} from "components/icons/Icons";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateProjectStatus } from "store/customerProject/reducer";
import { uploadProjectSelector } from "store/customerProject/selector";
import { removeCardInfoRequest } from "store/user/reducer";
import { cardsSelector, userSelector } from "store/user/selector";

export default function CardRowItem(props: {
  captureSelectedCard?: any | undefined;
  onCaptureSelectedCard?: Function;
  onSelect: Function;
  [x: string]: any;
}) {
  const { captureSelectedCard, onCaptureSelectedCard, onSelect, ...rest } =
    props;

  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const cards = useSelector(cardsSelector);
  const uploadProject = useSelector(uploadProjectSelector);
  const isBusiness = useMemo(() => {
    return user.is_business;
  }, [user]);

  const teamCards = useMemo(() => {
    if (user && cards?.length > 0) {
      return cards?.filter(
        (it) => Number(it?.user_id) !== Number(user.user_id)
      );
    } else {
      return [];
    }
  }, [cards, user]);

  const individualCards = useMemo(() => {
    if (user && cards?.length > 0) {
      return cards?.filter(
        (it) => Number(it?.user_id) === Number(user.user_id)
      );
    } else {
      return [];
    }
  }, [cards, user]);

  const onRemoveCard = (cardId: number) => {
    dispatch(
      removeCardInfoRequest({
        user_id: user.user_id,
        card_id: cardId,
      })
    );
  };

  const onSelectCard = (card: any) => {
    if (onCaptureSelectedCard) {
      onCaptureSelectedCard(card);
    } else {
      onSelect(card);
    }
    dispatch(
      updateProjectStatus({
        paymentOption: card,
      })
    );
  };

  useEffect(() => {
    if (cards?.length > 0) {
      const defaultCard = cards?.find((it) => Number(it.is_default) === 1);
      if (defaultCard) {
        onSelectCard(defaultCard);
      }
    }
  }, [cards]);

  return (
    <Box {...rest}>
      {isBusiness &&
        cards?.length > 0 &&
        cards.map((card: any, index: number) => (
          <Flex
            key={index}
            padding={{ md: "15px 27px", base: "15px 20px" }}
            alignItems="center"
            border={`1px solid ${
              typeof captureSelectedCard === "undefined"
                ? typeof uploadProject.paymentOption === "object" &&
                  uploadProject.paymentOption?.pm_id === card?.pm_id
                  ? "#2F80ED"
                  : "#DCDCDC"
                : captureSelectedCard?.pm_id === card?.pm_id
                ? "#2F80ED"
                : "#DCDCDC"
            }`}
            borderRadius="50px"
            mb="12px"
            transition="all .3s"
            cursor="pointer"
            _hover={{
              borderColor: "#2F80ED",
            }}
            onClick={() => {
              onSelectCard(card);
            }}
          >
            <Box
              w="20px"
              h="20px"
              borderRadius="50%"
              border="1px solid #2F80ED"
              mr="15px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgColor={
                typeof captureSelectedCard === "undefined"
                  ? typeof uploadProject.paymentOption === "object" &&
                    uploadProject.paymentOption?.pm_id === card?.pm_id
                    ? "#2F80ED"
                    : "#FFF"
                  : captureSelectedCard?.pm_id === card?.pm_id
                  ? "#2F80ED"
                  : "#FFF"
              }
            >
              <IconBox
                w="9px"
                h="7px"
                icon={<IconCheckedSmall w="9px" h="7px" />}
              ></IconBox>
            </Box>
            <IconBox
              mr="10px"
              w="30px"
              h="20px"
              icon={
                card?.card_brand === "visa" ? (
                  <IconCardVisa w="30px" h="20px" />
                ) : card?.card_brand === "mastercard" ? (
                  <IconCardMaster w="30px" h="20px" />
                ) : card?.card_brand === "americanexpress" ? (
                  <IconCardExpress w="30px" h="20px" />
                ) : card?.card_brand === "discover" ? (
                  <IconCardDiscover w="30px" h="20px" />
                ) : (
                  ""
                )
              }
            />
            <Text
              fontSize="14px"
              lineHeight="24px"
              fontWeight="400"
              color="#000"
            >
              Ending in {card.card_number.slice(-4)}
            </Text>

            <Button
              padding={"5px 20px"}
              backgroundColor="#F3F5F5"
              borderRadius="100px"
              minW="fit-content"
              height="34px"
              color="#000"
              fontSize="12px"
              lineHeight="24px"
              fontWeight="400"
              ml="auto"
              onClick={() => onRemoveCard(card.id)}
            >
              Remove
            </Button>
          </Flex>
        ))}

      {!isBusiness && teamCards?.length > 0 && (
        <Box ml={{ md: "20px", base: "0px" }}>
          <Text
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
            color="#000"
            mb="5px"
          >
            Team Credit Cards
          </Text>
          {teamCards.map((card: any, index: number) => (
            <Flex
              key={index}
              padding={{ md: "15px 27px", base: "15px 20px" }}
              alignItems="center"
              border={`1px solid ${
                typeof captureSelectedCard === "undefined"
                  ? typeof uploadProject.paymentOption === "object" &&
                    uploadProject.paymentOption?.pm_id === card?.pm_id
                    ? "#2F80ED"
                    : "#DCDCDC"
                  : captureSelectedCard?.pm_id === card?.pm_id
                  ? "#2F80ED"
                  : "#DCDCDC"
              }`}
              borderRadius="50px"
              mb="12px"
              transition="all .3s"
              cursor="pointer"
              _hover={{
                borderColor: "#2F80ED",
              }}
              onClick={() => {
                onSelectCard(card);
              }}
            >
              <Box
                w="20px"
                h="20px"
                borderRadius="50%"
                border="1px solid #2F80ED"
                mr="15px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                bgColor={
                  typeof captureSelectedCard === "undefined"
                    ? typeof uploadProject.paymentOption === "object" &&
                      uploadProject.paymentOption?.pm_id === card?.pm_id
                      ? "#2F80ED"
                      : "#FFF"
                    : captureSelectedCard?.pm_id === card?.pm_id
                    ? "#2F80ED"
                    : "#FFF"
                }
              >
                <IconBox
                  w="9px"
                  h="7px"
                  icon={<IconCheckedSmall w="9px" h="7px" />}
                ></IconBox>
              </Box>
              <IconBox
                mr="10px"
                w="30px"
                h="20px"
                icon={
                  card?.card_brand === "visa" ? (
                    <IconCardVisa w="30px" h="20px" />
                  ) : card?.card_brand === "mastercard" ? (
                    <IconCardMaster w="30px" h="20px" />
                  ) : card?.card_brand === "americanexpress" ? (
                    <IconCardExpress w="30px" h="20px" />
                  ) : card?.card_brand === "discover" ? (
                    <IconCardDiscover w="30px" h="20px" />
                  ) : (
                    ""
                  )
                }
              />
              <Text
                fontSize="14px"
                lineHeight="24px"
                fontWeight="400"
                color="#000"
              >
                Ending in {card.card_number.slice(-4)}
              </Text>
            </Flex>
          ))}
        </Box>
      )}

      {!isBusiness && individualCards?.length > 0 && (
        <Box ml={{ md: "20px", base: "0px" }} mt="20px">
          <Text
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
            color="#000"
            mb="5px"
          >
            My Credit Cards
          </Text>
          {individualCards.map((card: any, index: number) => (
            <Flex
              key={index}
              padding={{ md: "15px 27px", base: "15px 20px" }}
              alignItems="center"
              border={`1px solid ${
                typeof captureSelectedCard === "undefined"
                  ? typeof uploadProject.paymentOption === "object" &&
                    uploadProject.paymentOption?.pm_id === card?.pm_id
                    ? "#2F80ED"
                    : "#DCDCDC"
                  : captureSelectedCard?.pm_id === card?.pm_id
                  ? "#2F80ED"
                  : "#DCDCDC"
              }`}
              borderRadius="50px"
              mb="12px"
              transition="all .3s"
              cursor="pointer"
              _hover={{
                borderColor: "#2F80ED",
              }}
              onClick={() => {
                onSelectCard(card);
              }}
            >
              <Box
                w="20px"
                h="20px"
                borderRadius="50%"
                border="1px solid #2F80ED"
                mr="15px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                bgColor={
                  typeof captureSelectedCard === "undefined"
                    ? typeof uploadProject.paymentOption === "object" &&
                      uploadProject.paymentOption?.pm_id === card?.pm_id
                      ? "#2F80ED"
                      : "#FFF"
                    : captureSelectedCard?.pm_id === card?.pm_id
                    ? "#2F80ED"
                    : "#FFF"
                }
              >
                <IconBox
                  w="9px"
                  h="7px"
                  icon={<IconCheckedSmall w="9px" h="7px" />}
                ></IconBox>
              </Box>
              <IconBox
                mr="10px"
                w="30px"
                h="20px"
                icon={
                  card?.card_brand === "visa" ? (
                    <IconCardVisa w="30px" h="20px" />
                  ) : card?.card_brand === "mastercard" ? (
                    <IconCardMaster w="30px" h="20px" />
                  ) : card?.card_brand === "americanexpress" ? (
                    <IconCardExpress w="30px" h="20px" />
                  ) : card?.card_brand === "discover" ? (
                    <IconCardDiscover w="30px" h="20px" />
                  ) : (
                    ""
                  )
                }
              />
              <Text
                fontSize="14px"
                lineHeight="24px"
                fontWeight="400"
                color="#000"
              >
                Ending in {card.card_number.slice(-4)}
              </Text>
              <Button
                padding={"5px 20px"}
                backgroundColor="#F3F5F5"
                borderRadius="100px"
                minW="fit-content"
                height="34px"
                color="#000"
                fontSize="12px"
                lineHeight="24px"
                fontWeight="400"
                ml="auto"
                onClick={() => onRemoveCard(card.id)}
              >
                Remove
              </Button>
            </Flex>
          ))}
        </Box>
      )}
    </Box>
  );
}
