export const profileMenu = [
  {
    id: 1,
    label: 'Personal Details',
    link: 'personal-details'
  }, {
    id: 2,
    label: 'Role, Languages, & Team',
    link: 'role-langauge-team'
  }
]