/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import IconBox from "components/icons/IconBox";
import { IconAdd } from "components/icons/Icons";
import _, { isEmpty } from "lodash";
import { axiosService } from "network/axios";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateProjectRequest,
  updateProjectStatus,
} from "store/customerProject/reducer";
import { uploadProjectSelector } from "store/customerProject/selector";
import { setAlertMessageRequest } from "store/ui/reducer";
import { getCardsRequest } from "store/user/reducer";
import { cardsSelector, userSelector } from "store/user/selector";
import CardRowItem from "views/customer/order/components/payment/CardRowItem";
import { loadStripe, Stripe, StripeCardElement } from "@stripe/stripe-js";

export default function ProjectAdditionalService(props: {
  options: any;
  selectedProjectId: number;
  onRefresh?: Function;
}) {
  const { options, selectedProjectId, onRefresh } = props;
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const user = useSelector(userSelector);
  const cards = useSelector(cardsSelector);
  const dispatch = useDispatch();
  const [openCardInputForm, setOpenCardInputForm] = useState(false);
  const [selectedPaymentInfo, setSelectedPaymentInfo] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const uploadProject = useSelector(uploadProjectSelector);
  const [stripe, setStripe] = useState<Stripe>(null);
  const [cardElement, setCardElement] = useState<StripeCardElement>(null);
  const [cardHolderName, setCardHolderName] = useState("");
  const [cardZipCode, setCardZipCode] = useState("");

  useEffect(() => {
    if (user) {
      dispatch(getCardsRequest({ user_id: user.user_id }));
    }
  }, []);
  const isBusinessAccount = useMemo(() => {
    return !isEmpty(user?.business_id);
  }, [user]);

  useEffect(() => {
    if (cards?.length < 1) {
      setOpenCardInputForm(true);
    } else {
      setOpenCardInputForm(false);
    }
  }, [cards]);

  const onSelectOption = (option: any) => {
    setSelectedOption(option);
    onOpen();
  };

  const onPayNow = async () => {
    setIsProcessingPayment(true);

    if (uploadProject.saveCardAsDefault === true) {
      // Save Card Information As Default
      stripe
        .createPaymentMethod({
          type: "card",
          card: cardElement,
          billing_details: {
            name: cardHolderName,
          },
        })
        .then(async function (result) {
          const paymentMethodId = result?.paymentMethod?.id;
          await axiosService.post(
            `/shipping/put/payment-method/${user.user_id}`,
            {
              payment_method_id: paymentMethodId,
              is_default: 1,
            }
          );
        });
    }

    const serviceId =
      Number(selectedOption.id) === 1
        ? 3
        : Number(selectedOption.id) === 3
        ? 1
        : Number(selectedOption.id) === 2
        ? 2
        : 4;

    let paymentPayload = null;
    if (selectedPaymentInfo?.customer_id) {
      paymentPayload = {
        amount: selectedOption.price,
        customer_id: selectedPaymentInfo?.customer_id,
        payment_method_id: selectedPaymentInfo?.pm_id,
      };

      await proceedPayment(serviceId, paymentPayload);
    } else {
      stripe
        .createToken(cardElement, {
          name: cardHolderName,
          address_zip: cardZipCode,
        })
        .then(async (res) => {
          const tokenId = res?.token?.id;
          if (tokenId) {
            paymentPayload = {
              amount: 20,
              tokenId: tokenId,
            };
            await proceedPayment(serviceId, paymentPayload);
          } else if (res?.error?.message) {
            dispatch(
              setAlertMessageRequest({
                type: "error",
                message:
                  res?.error?.message ||
                  "Error in processing the payment. Please contact to support team.",
              })
            );
          } else {
            dispatch(
              setAlertMessageRequest({
                type: "error",
                message:
                  "Error in generating token Id. please check the card information.",
              })
            );
          }
        })
        .catch(async (err) => {
          dispatch(
            setAlertMessageRequest({
              type: "error",
              message:
                err?.error?.message ||
                "Error in processing the payment. Please contact to support team.",
            })
          );
        });
    }
  };

  const proceedPayment = async (serviceId: number, payload: any) => {
    try {
      await axiosService.post(
        `/shipping/process-service-payment/${selectedProjectId}/${serviceId}`,
        payload
      );
      dispatch(
        setAlertMessageRequest({
          type: "success",
          message: "Service was added to selected Payment.",
        })
      );
      let _payload = null;
      if (Number(selectedOption?.id === 1)) {
        _payload = {
          translation_speed: "speed",
        };
      } else if (Number(selectedOption?.id === 2)) {
        _payload = {
          include_2_day_shipping: 1,
        };
      } else if (Number(selectedOption?.id === 3)) {
        _payload = {
          include_notarization: 1,
        };
      } else if (Number(selectedOption?.id === 4)) {
        _payload = {
          include_mail_hard_copy: 1,
        };
      }
      onRefresh?.();
      dispatch(
        updateProjectRequest({
          user_id: user.user_id,
          order_id: selectedProjectId,
          details: _payload,
        })
      );
      setIsProcessingPayment(false);
    } catch (err: any) {
      dispatch(
        setAlertMessageRequest({
          type: "error",
          message: err?.data?.messages?.error || "Error in Processing Payment.",
        })
      );
    } finally {
      setIsProcessingPayment(false);
    }
    onClose();
  };

  useEffect(() => {
    if (openCardInputForm && isOpen) {
      setSelectedPaymentInfo(null);

      dispatch(
        updateProjectStatus({
          saveCardAsDefault: false,
          paymentOption: null,
        })
      );
      initStripe();
    }
  }, [openCardInputForm, isOpen]);

  const initStripe = async () => {
    let stripe = await loadStripe(
      // "pk_test_51NAyBnCsYOJWbJy3pNGgDnJ71UNGvBUXLikN2erIFcV6cUZlZxbVyWk8gB4AWlIBHCZFZeEexUyzDtfrkUHYMFKN002ZIoLdVq"
      "pk_live_51IEJJGJhA5ApiXVVzZnFak5wXrk8zGw7E1mDmbntMgUg5ZbERdHPmc5a0FfBqRuN6b55JuIzvnwShsSPs3eY4iQR00mCyIwUgj"
    );
    var elements = stripe.elements();
    let cardElement = elements.create("card", {
      iconStyle: "default",
      hidePostalCode: true,
      style: {
        base: {
          iconColor: "#000",
          color: "#000",
          fontWeight: 400,
          fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
          fontSize: "16px",
          lineHeight: "30px",
          letterSpacing: "normal",
          fontSmoothing: "antialiased",

          "::placeholder": {
            color: "#777",
          },
          ":-webkit-autofill": {
            color: "#777",
          },
        },
        invalid: {
          iconColor: "#F00",
          color: "#F00",
        },
      },
    });
    cardElement.mount("#card-element");
    cardElement.on("change", function (event) {
      if (event.complete) {
        setStripe(stripe);
        setCardElement(cardElement);
      } else if (event.error) {
        setStripe(null);
        setCardElement(null);
      }
    });
  };

  useEffect(() => {
    if (
      stripe &&
      cardElement &&
      !_.isEmpty(cardHolderName) &&
      !_.isEmpty(cardZipCode)
    ) {
      setSelectedPaymentInfo({
        cardHolderName,
        cardZipCode,
        stripe,
        cardElement,
      });
    } else {
      setSelectedPaymentInfo(null);
    }
  }, [cardHolderName, cardZipCode, stripe, cardElement]);

  const onChangeCheckBox = (status: boolean) => {
    dispatch(
      updateProjectStatus({
        paymentOption: null,
        saveCardAsDefault: status,
      })
    );
  };

  return (
    <>
      {options?.length > 0 && (
        <Flex direction="column" mt="20px" rowGap="12px">
          {options.map((it: any, index: number) => (
            <Flex
              key={index}
              padding="9px 12px"
              justifyContent="space-between"
              alignItems="center"
              border="1px solid #D9E4F3"
              borderRadius="90px"
            >
              <Text
                fontWeight="700"
                fontSize="12px"
                lineHeight="15px"
                color="#2F80ED"
              >
                {it.lable}
              </Text>
              <Button
                padding="6px 12px"
                fontSize="12px"
                lineHeight="15px"
                fontWeight="400"
                color="#FFF"
                h="fit-content"
                bgColor="#2F80ED"
                onClick={() => {
                  onSelectOption(it);
                }}
              >
                <Box>Add</Box>
                <Box ml="10px">${it.price?.toFixed(2)}</Box>
              </Button>
            </Flex>
          ))}
        </Flex>
      )}
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            fontSize="20px"
            lineHeight="24px"
            fontWeight="700"
            color="#000"
            textAlign="center"
            pt="25px"
          >
            Pay for Additional Service
          </ModalHeader>
          <ModalBody py={4}>
            <Box w="100%">
              <>
                <CardRowItem
                  onCaptureSelectedCard={(e: any) => {
                    setSelectedPaymentInfo(e);
                  }}
                  onSelect={() => {
                    setOpenCardInputForm(false);
                  }}
                />
                {!openCardInputForm && isBusinessAccount && (
                  <Flex
                    alignItems="center"
                    w="fit-content"
                    cursor="pointer"
                    onClick={(e) => setOpenCardInputForm(true)}
                  >
                    <IconBox
                      w="22px"
                      h="22px"
                      mr="10px"
                      icon={<IconAdd w="22px" h="22px" />}
                    />
                    <Text
                      fontSize="14px"
                      lineHeight="22px"
                      textDecoration="underline"
                    >
                      Add a card or bank
                    </Text>
                  </Flex>
                )}
                {openCardInputForm && isBusinessAccount && (
                  <Box
                    padding={{ md: "25px", base: "20px" }}
                    border="1px solid #DCDCDC"
                    borderRadius="10px"
                  >
                    <Box
                      padding="15px"
                      borderRadius="50px"
                      border="1px solid #E9E9E9"
                    >
                      <Box id="card-element"></Box>
                    </Box>
                    <Flex id="name-on-card" mt="20px" columnGap="20px">
                      <Input
                        name="name-on-card"
                        borderRadius="50px"
                        border="1px solid #E9E9E9"
                        background="#FFF"
                        sx={{
                          width: "65%",
                          padding: "15px 20px !important",
                          height: "60px",
                        }}
                        placeholder="Name On Card"
                        _placeholder={{
                          color: "#777",
                          fontSize: "16px !important",
                        }}
                        value={cardHolderName}
                        onChange={(e) => {
                          setCardHolderName(e.target.value);
                        }}
                      />
                      <Input
                        name="postal"
                        borderRadius="50px"
                        border="1px solid #E9E9E9 !important"
                        sx={{
                          width: "35%",
                          padding: "15px 20px !important",
                          height: "60px",
                        }}
                        placeholder="Zipcode"
                        _placeholder={{
                          color: "#777",
                          fontSize: "16px !important",
                        }}
                        value={cardZipCode}
                        onChange={(e) => {
                          setCardZipCode(e.target.value);
                        }}
                      />
                    </Flex>
                    <Box mt="10px">
                      <Checkbox
                        sx={{
                          ".chakra-checkbox__control": {
                            borderColor: "#2F80ED",
                          },
                        }}
                        onChange={(e: any) =>
                          onChangeCheckBox(e.target.checked)
                        }
                      >
                        Use this card information in future
                      </Checkbox>
                    </Box>
                  </Box>
                )}
              </>
            </Box>
          </ModalBody>
          <ModalFooter borderTop="1px solid #EEE">
            <Flex
              alignItems="center"
              fontSize="14px"
              lineHeight="17px"
              color="#2F80ED"
              fontWeight="700"
              cursor="pointer"
              padding="10px"
              mr="20px"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Flex>
            <Button
              height="fit-content"
              padding="15px 30px"
              borderRadius="100px"
              bgColor="#2F80ED"
              color="#FFF"
              fontSize="14px"
              lineHeight="17px"
              fontWeight="400"
              ml="20px"
              isLoading={isProcessingPayment}
              disabled={!selectedPaymentInfo}
              _disabled={{
                backgroundColor: "#E9E9E9 !important",
              }}
              onClick={() => onPayNow()}
            >
              Pay Now ({selectedOption?.price?.toFixed(2)}$)
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
