import { Box } from "@chakra-ui/react";

export const ScrollPanel = (props: {
  children: JSX.Element | string;
  [x: string]: any;
}) => {
  const { children, ...rest } = props;
  return (
    <Box {...rest} className="scroll-panel">
      {children}
    </Box>
  );
};
