import { Box } from "@chakra-ui/react";
import { profileMenu } from "../variables/profileMenu";

export default function ProfileMenu(props: {
  selectedMenuItem: any;
  onMenuItemClick: Function;
}) {
  const { selectedMenuItem, onMenuItemClick } = props;
  return (
    <Box
      display={{
        md: "block",
        base: "flex",
      }}
      columnGap="5px"
    >
      {profileMenu.map((item, index: number) => (
        <Box
          key={index}
          py={{ md: "17px", base: "14px" }}
          px={{ md: "0px", base: "10px" }}
          fontSize={{ md: "20px", base: "14px" }}
          lineHeight="120%"
          fontWeight="400"
          color={selectedMenuItem.id === item.id ? "#2F80ED" : "#000"}
          cursor="pointer"
          borderBottom={{
            md: index < profileMenu.length - 1 ? "1px solid #E3E3E3" : "none",
            base: "1px solid #E3E3E3",
          }}
          transition="color .25s"
          onClick={() => onMenuItemClick(item)}
          _hover={{
            color: "#2F80ED",
          }}
        >
          {item.label}
        </Box>
      ))}
    </Box>
  );
}
