import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import Card from "components/card/Card";
import * as React from "react";

type RowObj = {
  name: string;
  email: string;
  phone: string;
  cityState: string;
  country: string;
  activeProjects: string[];
  actionCol: "";
};

const columnHelper = createColumnHelper<RowObj>();

export default function IndividualCustomersMobileTable(props: {
  tableData: any;
}) {
  const { tableData } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const textColor = useColorModeValue("black", "black");
  let defaultData = tableData;

  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      header: () => (
        <Text
          textAlign="left"
          fontSize="14px"
          lineHeight="24px"
          color="gray.500"
          fontWeight="400"
        >
          Name
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
          >
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("email", {
      id: "email",
      header: () => (
        <Text
          textAlign="left"
          fontSize="14px"
          lineHeight="24px"
          color="gray.500"
          fontWeight="400"
        >
          Email
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
          >
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("phone", {
      id: "phone",
      header: () => (
        <Text
          textAlign="left"
          fontSize="14px"
          lineHeight="24px"
          color="gray.500"
          fontWeight="400"
        >
          Phone
        </Text>
      ),
      cell: (info) => (
        <Text
          color={textColor}
          fontSize="14px"
          lineHeight="24px"
          fontWeight="400"
        >
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("cityState", {
      id: "cityState",
      header: () => (
        <Text
          textAlign="left"
          fontSize="14px"
          lineHeight="24px"
          color="gray.500"
          fontWeight="400"
        >
          City/State
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
          >
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("country", {
      id: "country",
      header: () => (
        <Text
          textAlign="left"
          fontSize="14px"
          lineHeight="24px"
          color="gray.500"
          fontWeight="400"
        >
          Country
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
          >
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("activeProjects", {
      id: "activeProjects",
      header: () => (
        <Text
          textAlign="left"
          fontSize="14px"
          lineHeight="24px"
          color="gray.500"
          fontWeight="400"
        >
          Active Projects
        </Text>
      ),
      cell: (info) => (
        <Flex align="center" color="#000">
          {info.getValue()}
        </Flex>
      ),
    }),
  ];
  const [data, setData] = React.useState(() => [...defaultData]);
  React.useEffect(() => {
    setData([...defaultData]);
  }, [defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Card flexDirection="column" w="100%" p="0px">
      <Box>
        <Table
          variant="simple"
          color="gray.500"
          mb="24px"
          pb="2"
          lineHeight="15px"
        >
          {table
            .getRowModel()
            .rows.slice(0, 11)
            .map((row: any, index: number) => {
              return (
                <Table
                  key={index}
                  mt={index === 0 ? "10px" : "35px"}
                  border="1px solid #dcdcdc"
                >
                  <Tbody>
                    {row.getVisibleCells().map((cell: any, _index: number) => {
                      return (
                        <Tr key={_index}>
                          {_index < 6 ? (
                            <React.Fragment>
                              <Td
                                p="10px"
                                width="120px"
                                borderColor="#dcdcdc"
                                borderRight="1px solid #dcdcdc"
                                bgColor="#FAFAFA"
                              >
                                {flexRender(
                                  table.getHeaderGroups()[0].headers[_index]
                                    .column.columnDef.header,
                                  table
                                    .getHeaderGroups()[0]
                                    .headers[_index].getContext()
                                )}
                              </Td>
                              <Td p="10px" position="relative">
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </Td>
                            </React.Fragment>
                          ) : (
                            <Td colSpan={2} padding="0px">
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </Td>
                          )}
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              );
            })}
        </Table>
      </Box>
    </Card>
  );
}
