import {
  Box,
  Button,
  Flex,
  FormLabel,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import IconBox from "components/icons/IconBox";
import { IconArrowDown } from "components/icons/Icons";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addTeammemberRequest } from "store/user/reducer";
import { isSavingTeammemberSelector, userSelector } from "store/user/selector";

export default function SendInvitationForm() {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const business_id = user?.business_id;
  const isSavingTeammember = useSelector(isSavingTeammemberSelector);
  const options: string[] = ["admin", "standard"];
  const [selectedOption, setSelectedOption] = useState("admin");
  const [email, setEmail] = useState(null);
  const shadow = useColorModeValue(
    "0px 4px 50px rgba(0, 0, 0, 0.1)",
    "0px 4px 50px rgba(0, 0, 0, 0.1)"
  );

  const onClickSendInvite = () => {
    const payload = {
      business_id: business_id,
      details: {
        email: email,
        role: selectedOption,
      },
    };

    dispatch(addTeammemberRequest(payload));
  };

  return (
    <Box w="100%" position="relative" mt={2}>
      <FormLabel
        fontSize="12px"
        lineHeight="15px"
        fontWeight="400"
        color="#8E8C8C"
        textTransform="capitalize"
        padding="5px"
        bgColor="#FFF"
        mr="0px"
        width="fit-content"
        position="relative"
        zIndex="10"
        ml="30px"
        mb="-13px"
      >
        Email
      </FormLabel>
      <Input
        type="email"
        name="email"
        value={email}
        padding="15px 30px"
        fontSize="14px"
        lineHeight="17px"
        color="#000"
        fontWeight="400"
        h="60px"
        borderRadius="50px"
        placeholder="Email Address"
        onChange={(e) => {
          setEmail(e.target.value);
        }}
        _placeholder={{
          fontSize: "14px",
          lineHeight: "17px",
          color: "#000",
          fontWeight: "400",
          textTransform: "capitalize",
        }}
      />
      <Flex
        alignItems="center"
        position="absolute"
        top="25px"
        right="15px"
        zIndex="10"
      >
        <Menu>
          <MenuButton
            padding={"5px 10px 5px 20px"}
            backgroundColor="#FFF"
            borderRadius="100px"
            minW="fit-content"
            height="34px"
            border="1px solid #E9E9E9"
            ml="auto"
          >
            <Flex alignItems={"center"}>
              <Text
                fontSize="14px"
                lineHeight="24px"
                fontWeight="400"
                color="black"
                textTransform="capitalize"
              >
                {selectedOption}
              </Text>
              <IconBox
                marginLeft="10px"
                icon={<IconArrowDown width="24px" height="24px" />}
              ></IconBox>
            </Flex>
          </MenuButton>
          <MenuList
            boxShadow={shadow}
            p="0px"
            mt="10px"
            borderRadius="20px"
            bg={"#FFF"}
            border="none"
          >
            <Flex
              flexDirection="column"
              p="10px 20px"
              fontSize={"14px"}
              lineHeight="24px"
              fontWeight={"400"}
              color="black"
            >
              {options.map((option, index: number) => (
                <MenuItem
                  key={index}
                  _hover={{ bg: "none" }}
                  _focus={{ bg: "none" }}
                  borderRadius="8px"
                  padding="15px 0px"
                  borderBottom={
                    index < options.length - 1 ? "1px solid #F3F5F5" : ""
                  }
                  onClick={(e) => setSelectedOption(option)}
                >
                  <Text fontSize="sm" textTransform="capitalize">
                    {option}
                  </Text>
                </MenuItem>
              ))}
            </Flex>
          </MenuList>
        </Menu>
        <Button
          padding={"5px 20px"}
          backgroundColor="#2F80ED"
          borderRadius="100px"
          minW="fit-content"
          height="34px"
          color="#FFF"
          fontSize="12px"
          lineHeight="24px"
          fontWeight="400"
          ml="10px"
          isLoading={isSavingTeammember}
          disabled={!email}
          _disabled={{
            backgroundColor: "#E9E9E9 !important",
          }}
          onClick={() => onClickSendInvite()}
        >
          Send Invite
        </Button>
      </Flex>
    </Box>
  );
}
