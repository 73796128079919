import {
  Box,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import _ from "lodash";
import { axiosService } from "network/axios";
import { useEffect, useState } from "react";

export default function Teams() {
  const [isFetching, setIsFetching] = useState(true);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    fetchTeams();
  }, []);

  const fetchTeams = async () => {
    setIsFetching(true);
    const resp: any = await axiosService.get("/get/teams");
    setTeams(resp?.data?.result || []);
    // setUserRoles(resp?.data?.results || []);
    setIsFetching(false);
  };
  return (
    <Box mt="30px">
      <Text
        fontSize="17px"
        lineHeight="24px"
        color="#000"
        fontWeight="700"
        whiteSpace="nowrap"
        mr="50px"
        w="142px"
        minW="142px"
        mb="20px"
      >
        Teams
      </Text>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th isNumeric width="20px">
                No
              </Th>
              <Th>Company Name</Th>
              <Th>EIN Number</Th>
              <Th>Address</Th>
              <Th>Team Users</Th>
            </Tr>
          </Thead>
          <Tbody>
            {!isFetching &&
              teams?.map((it: any, index: number) => (
                <Tr key={index}>
                  <Td isNumeric>{index + 1}</Td>
                  <Td>{it?.team?.company_name}</Td>
                  <Td>{it?.team?.ein_number}</Td>
                  <Td>
                    {it?.team?.address}, {it?.team?.state}, {it?.team?.zip_code}
                  </Td>
                  <Td>{it?.users?.length || 0}</Td>
                </Tr>
              ))}
            {isFetching &&
              _.range(1, 3)?.map((it) => (
                <Tr key={it}>
                  <Td>
                    <Skeleton height="20px"></Skeleton>
                  </Td>
                  <Td>
                    <Skeleton height="20px"></Skeleton>
                  </Td>
                  <Td>
                    <Skeleton height="20px"></Skeleton>
                  </Td>
                  <Td>
                    <Skeleton height="20px"></Skeleton>
                  </Td>
                  <Td>
                    <Skeleton height="20px"></Skeleton>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}
