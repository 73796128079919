/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Flex, SimpleGrid, Spinner, Text } from "@chakra-ui/react";
import IconBox from "components/icons/IconBox";
import { IconAsana, IconCheckedCircle } from "components/icons/Icons";
import { VSeparator } from "components/separator/Separator";
import { axiosService } from "network/axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCardsRequest } from "store/user/reducer";
import { userSelector } from "store/user/selector";
import ServiceItem from "../services/components/ServiceItem";
import { ServiceRowObj, servicesData } from "../services/variables/serviceData";
import { howWorksList } from "./variables/servicesData";

export default function Rewards() {
  const boxShadow = "0px 4px 50px rgba(0, 0, 0, 0.1)";
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const navigate = useNavigate();
  const [rewardsInformation, setRewardsInformation] = useState({
    totalPoints: 0,
    totalRadeem: 0,
  });
  const userId = useMemo(() => {
    return user?.user_id ?? null;
  }, [user]);
  const [allActiveProjects, setAllActiveProjects] = useState<any>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingRewardsData, setIsFetchingRewardsData] = useState(false);
  useEffect(() => {
    dispatch(getCardsRequest({ user_id: user.user_id }));
    fetchResults();
  }, []);
  const fetchResults = async () => {
    setIsFetching(true);
    try {
      const resp: any = await axiosService.get(
        `/projects/list/${user?.user_id}?limit=1000&page=1`
      );
      const { results } = resp?.data;
      setAllActiveProjects(
        results?.map((it: any) => ({
          ...it,
          id: it?.order_id,
        }))
      );
    } catch (err) {
      console.log(err);
      setAllActiveProjects([]);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchRewards();
  }, [userId]);

  const fetchRewards = useCallback(async () => {
    setIsFetchingRewardsData(true);
    try {
      const { data }: any = await axiosService.get(`/user/rewards/${userId}`);
      console.log(data);
      setRewardsInformation({
        totalPoints: data?.totalPoints ?? 0,
        totalRadeem: data?.reemPages ?? 0,
      });
    } catch (err) {
      setRewardsInformation({
        totalPoints: 0,
        totalRadeem: 0,
      });
    } finally {
      setIsFetchingRewardsData(false);
    }
  }, [userId]);

  const onStartRedeemOrder = () => {
    localStorage.setItem(
      "redeem",
      JSON.stringify({
        user_id: userId,
      })
    );
    navigate(`/customer/main/`);
  };

  return (
    <Box pt={{ base: "60px", md: "80px", xl: "80px" }}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing="40px">
        <Box
          padding={{ md: "30px 40px", base: "30px 20px" }}
          bgColor="#2F80ED"
          borderRadius="20px"
          boxShadow={boxShadow}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Flex alignItems="flex-end">
            <IconBox
              w="40px"
              h="40px"
              mb="4px"
              icon={<IconAsana w="40px" h="40px" />}
            />
            <Text
              fontSize="35px"
              lineHeight="135%"
              fontWeight="700"
              color="#FFF"
              ml="10px"
            >
              {rewardsInformation?.totalPoints}
            </Text>
            <Text
              fontSize="14px"
              lineHeight="135%"
              fontWeight="400"
              color="#FFF"
              ml="10px"
              mb="8px"
            >
              Points
            </Text>
          </Flex>
          <VSeparator w="full" h="2px" mt="20px" mb="30px" bgColor="#B0CAEE" />
          <Flex alignItems="center" justifyContent="space-between">
            <Flex alignItems="center">
              <Text
                fontSize="17px"
                lineHeight="135%"
                color="#FFF"
                fontWeight="700"
              >
                {rewardsInformation.totalRadeem}
              </Text>
              <Text
                fontSize="14px"
                lineHeight="135%"
                color="#FFF"
                fontWeight="400"
                ml={{ md: "17px", base: "7px" }}
              >
                {rewardsInformation.totalRadeem > 1
                  ? "Rewards Redeemed"
                  : "Reward Redeemed"}
              </Text>
            </Flex>
            <Button
              sx={{
                padding: "10px 20px",
                backgroundColor: "#FFF",
                color: "#2F80ED",
                borderRadius: "100px",
              }}
              disabled={!(rewardsInformation.totalPoints >= 25)}
              onClick={() => {
                onStartRedeemOrder();
              }}
            >
              Redeem
            </Button>
          </Flex>
        </Box>
        <Box
          padding={{ md: "30px 40px", base: "30px 20px" }}
          boxShadow={boxShadow}
          borderRadius="20px"
          bgColor="#FFF"
        >
          <Text
            fontSize="14px"
            lineHeight="135%"
            fontWeight="700"
            color="300"
            mb="10px"
          >
            Points for Page Program
          </Text>
          <Text
            fontSize="14px"
            lineHeight="135%"
            // fontWeight="700"
            color="300"
            mb="10px"
          >
            Here's How It Works:
          </Text>
          <Box pl={3}>
            {howWorksList.map((it, index: number) => (
              <Flex alignItems="center" key={index} mb="5px">
                <IconBox
                  w="14px"
                  h="14px"
                  mr="6px"
                  icon={<IconCheckedCircle w="14px" h="14px" />}
                />
                <Text
                  fontSize="14px"
                  lineHeight="145%"
                  color="#000"
                  fontWeight="400"
                >
                  <b>{it.title} </b>
                  {it.content}
                </Text>
              </Flex>
            ))}
          </Box>
          <Text
            fontSize="14px"
            lineHeight="135%"
            color="#000"
            fontWeight="400"
            mt="10px"
          >
            Join the "Points for Page Program" and start saving on your
            translation services today!
          </Text>
        </Box>
      </SimpleGrid>
      <Text
        mt="40px"
        mb="30px"
        fontSize="22px"
        lineHeight="135%"
        fontWeight="700"
        color="#000"
      >
        Services Available To Redeem
      </Text>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing="40px">
        {servicesData.map((it: ServiceRowObj, index: number) => (
          <ServiceItem
            w="full"
            item={it}
            key={index}
            allActiveProjects={allActiveProjects}
            onRefresh={fetchResults}
          ></ServiceItem>
        ))}
      </SimpleGrid>
      {(isFetching || isFetchingRewardsData) && (
        <Box
          w="100%"
          h="100%"
          bgColor={"rgba(0, 0, 0, 0.2)"}
          position="fixed"
          top="0"
          left="0"
          zIndex="100001"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner color="#2F80ED" width={"50px"} height="50px"></Spinner>
        </Box>
      )}
    </Box>
  );
}
