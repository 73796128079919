import { createSelector } from "reselect";
import { RootState } from "../common/reducer";

export const uiState = (rootState: RootState) => rootState.ui;

export const alertSelector = createSelector([uiState], (state) => state.alert);
export const searchQuerySelector = createSelector(
  [uiState],
  (state) => state.searchQuery
);

export const savedCardDataSelector = createSelector(
  [uiState],
  (state) => state.cardData
);

export const showProfileDetailsSelector = createSelector(
  [uiState],
  (state) => state.showProfileDetails
);
