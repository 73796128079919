/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import {
  Box,
  Flex,
  Drawer,
  DrawerBody,
  useColorModeValue,
  useDisclosure,
  DrawerContent,
  Avatar,
  Text,
  DrawerOverlay,
  Button,
} from "@chakra-ui/react";
import Content from "components/sidebar/components/Content";
import {
  renderThumb,
  renderTrack,
  renderView,
} from "components/scrollbar/Scrollbar";
import { Scrollbars } from "react-custom-scrollbars-2";
import { IconCloseHamburgur, IconHamburgur } from "components/icons/Icons";
import IconBox from "components/icons/IconBox";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "store/user/selector";
import { useWindowWidth } from "@react-hook/window-size";
import { logoutRequest } from "store/user/reducer";
import { useNavigate } from "react-router-dom";

function Sidebar(props: { routes: RoutesType[]; [x: string]: any }) {
  const { routes } = props;
  let variantChange = "0.2s linear";
  let sidebarBg = useColorModeValue("white", "navy.800");
  let sidebarMargins = "0px";

  return (
    <Box display={{ sm: "none", xl: "block" }} position="fixed" minH="100%">
      <Box
        bg={sidebarBg}
        transition={variantChange}
        w="250px"
        h="100vh"
        m={sidebarMargins}
        minH="100%"
        overflowX="hidden"
        padding={"50px 35px"}
      >
        <Scrollbars
          autoHide
          renderTrackVertical={renderTrack}
          renderThumbVertical={renderThumb}
          renderView={renderView}
        >
          <Content routes={routes} />
        </Scrollbars>
      </Box>
    </Box>
  );
}

// FUNCTIONS
export function SidebarResponsive(props: {
  routes: RoutesType[];
  openProfileDetails?: Function;
}) {
  let sidebarBackgroundColor = useColorModeValue("white", "navy.800");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const user = useSelector(userSelector);
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const windowWidth = useWindowWidth();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userName = useMemo(() => {
    if (user) {
      return `${user.first_name} ${user.last_name}`;
    } else {
      return "";
    }
  }, [user]);
  const userAvatar = useMemo(() => {
    if (user) {
      return user.profile_pic_src
        ? user.profile_pic_src?.includes("http")
          ? user.profile_pic_src
          : `${baseUrl}/public_html/images/${user.profile_pic_src}`
        : null;
    } else {
      return null;
    }
  }, [user]);
  const { routes, openProfileDetails } = props;
  const onLogOut = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("user_details");
    dispatch(logoutRequest());
    navigate("/auth/sign-in/");
  };

  return (
    <Flex display={{ sm: "flex", xl: "none" }} alignItems="center">
      <Flex
        ref={btnRef}
        w="max-content"
        justifyContent="flex-end"
        h="max-content"
        onClick={isOpen ? onClose : onOpen}
      >
        <IconBox
          justifyContent={{ lg: "flex-start", base: "flex-end" }}
          w="34px"
          h="34px"
          icon={
            isOpen ? (
              <IconCloseHamburgur w="25px" h="25px" />
            ) : (
              <IconHamburgur w="25px" h="25px" />
            )
          }
        />
      </Flex>

      <Drawer
        id="mobile-menu-content"
        isOpen={isOpen}
        onClose={onClose}
        placement={windowWidth >= 768 ? "left" : "top"}
        finalFocusRef={btnRef}
      >
        <DrawerContent
          sx={{
            top: { md: "0px !important", base: "80px !important" },
          }}
          w="100%"
          maxW={{ md: "250px", base: "100%" }}
          h={{ md: "100%", base: "calc(100vh - 80px)" }}
          bg={sidebarBackgroundColor}
          padding="50px 30px"
        >
          <Box
            mb="30px"
            pb="30px"
            borderBottom="1px solid #E9E9E9"
            display={{ md: "none", base: "block" }}
          >
            <Flex alignItems="center">
              <Avatar
                src={userAvatar}
                _hover={{ cursor: "pointer" }}
                color="white"
                name={userName}
                bg="#11047A"
                size="sm"
                w="40px"
                h="40px"
              />
              <Text
                fontSize={"14px"}
                lineHeight="24px"
                fontWeight={"400"}
                color="black"
                ml="20px"
              >
                {userName}
              </Text>
            </Flex>
            <Flex mt="20px" columnGap="15px">
              <Button
                borderRadius="0px"
                width="100%"
                padding="15px 35px"
                bgColor="#2F80ED"
                color="#FFF"
                fontSize="14px"
                lineHeight="17px"
                fontWeight="bold"
                onClick={() => {
                  onClose();
                  openProfileDetails
                    ? openProfileDetails()
                    : console.log("HERE");
                }}
              >
                View Profile
              </Button>
              <Button
                borderRadius="0px"
                width="100%"
                padding="15px 35px"
                bgColor="#F02828"
                color="#FFF"
                fontSize="14px"
                lineHeight="17px"
                fontWeight="bold"
                onClick={(e) => {
                  onLogOut();
                }}
              >
                Logout
              </Button>
            </Flex>
          </Box>
          <DrawerBody maxW="100%" px="0rem" pb="0">
            <Scrollbars
              autoHide
              renderTrackVertical={renderTrack}
              renderThumbVertical={renderThumb}
              renderView={renderView}
            >
              <Content
                routes={routes}
                onMenuItemClicked={() => {
                  onClose();
                }}
              />
            </Scrollbars>
          </DrawerBody>
        </DrawerContent>
        <DrawerOverlay display={{ base: "none", md: "block" }} />
      </Drawer>
    </Flex>
  );
}

// PROPS
export default Sidebar;
