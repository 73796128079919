import { Box, Button, Flex, Input, Text } from "@chakra-ui/react";
import IconBox from "components/icons/IconBox";
import {
  IconAdd,
  IconCardDiscover,
  IconCardExpress,
  IconCardMaster,
  IconCardVisa,
} from "components/icons/Icons";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  delBankRequest,
  getCardsRequest,
  removeCardInfoRequest,
} from "store/user/reducer";
import {
  bankAccountsSelector,
  cardsSelector,
  userSelector,
} from "store/user/selector";
import BankInputForm from "views/customer/order/components/payment/BankInputForm";
import { loadStripe, Stripe, StripeCardElement } from "@stripe/stripe-js";
import { axiosService } from "network/axios";

export default function CreditCards() {
  const dispatch = useDispatch();
  const [openCardInput, setOpenCardInput] = useState(false);
  const [openAddressForm, setOepnAddressForm] = useState(false);
  const cards = useSelector(cardsSelector);
  const user = useSelector(userSelector);
  const banks = useSelector(bankAccountsSelector);
  const [stripe, setStripe] = useState<Stripe>(null);
  const [cardElement, setCardElement] = useState<StripeCardElement>(null);
  const [cardHolderName, setCardHolderName] = useState("");
  const [cardZipCode, setCardZipCode] = useState("");
  const [isSavingCardInfo, setIsSavingCardInfo] = useState(false);

  const isBusiness = useMemo(() => {
    return user.is_business;
  }, [user]);

  const teamCards = useMemo(() => {
    if (user && cards?.length > 0) {
      return cards?.filter(
        (it) => Number(it?.user_id) !== Number(user.user_id)
      );
    } else {
      return [];
    }
  }, [cards, user]);

  const individualCards = useMemo(() => {
    if (user && cards?.length > 0) {
      return cards?.filter(
        (it) => Number(it?.user_id) === Number(user.user_id)
      );
    } else {
      return [];
    }
  }, [cards, user]);

  const onRemoveCard = (cardId: number) => {
    dispatch(
      removeCardInfoRequest({
        user_id: user.user_id,
        card_id: cardId,
      })
    );
  };

  const onRemoveBank = (bank: any) => {
    dispatch(
      delBankRequest({
        user_id: user.user_id,
        bank_id: bank.id,
      })
    );
  };

  useEffect(() => {
    setOpenCardInput(false);
  }, [cards]);

  useEffect(() => {
    if (openCardInput) {
      initStripe();
    } else {
      setStripe(null);
      setCardElement(null);
    }
  }, [openCardInput]);

  const initStripe = async () => {
    let stripe = await loadStripe(
      // "pk_test_51NAyBnCsYOJWbJy3pNGgDnJ71UNGvBUXLikN2erIFcV6cUZlZxbVyWk8gB4AWlIBHCZFZeEexUyzDtfrkUHYMFKN002ZIoLdVq"
      "pk_live_51IEJJGJhA5ApiXVVzZnFak5wXrk8zGw7E1mDmbntMgUg5ZbERdHPmc5a0FfBqRuN6b55JuIzvnwShsSPs3eY4iQR00mCyIwUgj"
    );
    var elements = stripe.elements();
    let cardElement = elements.create("card", {
      iconStyle: "default",
      hidePostalCode: true,
      style: {
        base: {
          iconColor: "#000",
          color: "#000",
          fontWeight: 400,
          fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
          fontSize: "16px",
          lineHeight: "30px",
          letterSpacing: "normal",
          fontSmoothing: "antialiased",

          "::placeholder": {
            color: "#777",
          },
          ":-webkit-autofill": {
            color: "#777",
          },
        },
        invalid: {
          iconColor: "#F00",
          color: "#F00",
        },
      },
    });
    cardElement.mount("#card-element");
    cardElement.on("change", function (event) {
      if (event.complete) {
        setStripe(stripe);
        setCardElement(cardElement);
      } else if (event.error) {
        setStripe(null);
        setCardElement(null);
      }
    });
  };

  const saveCardInformation = () => {
    setIsSavingCardInfo(true);
    stripe
      .createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          name: cardHolderName,
        },
      })
      .then(async function (result) {
        const paymentMethodId = result?.paymentMethod?.id;
        await axiosService.post(
          `/shipping/put/payment-method/${user.user_id}`,
          {
            payment_method_id: paymentMethodId,
            is_default: 1,
          }
        );
        dispatch(getCardsRequest({ user_id: user.user_id }));
      })
      .finally(() => {
        setIsSavingCardInfo(false);
      });
  };

  return (
    <Box>
      <Text
        fontSize="17px"
        lineHeight="24px"
        fontWeight="700"
        color="#000"
        mb="20px"
      >
        Credit Cards
      </Text>
      {isBusiness &&
        cards?.length > 0 &&
        cards.map((card: any, index: number) => (
          <Flex
            key={index}
            padding={{md: "15px 27px", base: "15px 20px"}}
            alignItems="center"
            border="1px solid #DCDCDC"
            borderRadius="10px"
            mb={"12px"}
          >
            <IconBox
              mr="10px"
              w="30px"
              h="20px"
              icon={
                card?.card_brand === "visa" ? (
                  <IconCardVisa w="30px" h="20px" />
                ) : card?.card_brand === "mastercard" ? (
                  <IconCardMaster w="30px" h="20px" />
                ) : card?.card_brand === "americanexpress" ? (
                  <IconCardExpress w="30px" h="20px" />
                ) : card?.card_brand === "discover" ? (
                  <IconCardDiscover w="30px" h="20px" />
                ) : (
                  ""
                )
              }
            />
            <Text
              fontSize="14px"
              lineHeight="24px"
              fontWeight="400"
              color="#000"
            >
              Ending in {card.card_number.slice(-4)}
            </Text>
            <Button
              padding={"5px 20px"}
              backgroundColor="#F3F5F5"
              borderRadius="100px"
              minW="fit-content"
              height="34px"
              color="#000"
              fontSize="12px"
              lineHeight="24px"
              fontWeight="400"
              ml="auto"
              onClick={() => onRemoveCard(card.id)}
            >
              Remove
            </Button>
          </Flex>
        ))}

      {!isBusiness && teamCards?.length > 0 && (
        <Box ml="20px">
          <Text
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
            color="#000"
            mb="5px"
          >
            Team Credit Cards
          </Text>
          {teamCards.map((card: any, index: number) => (
            <Flex
              key={index}
              padding={{md: "15px 27px", base: "15px 20px"}}
              alignItems="center"
              border="1px solid #DCDCDC"
              borderRadius="10px"
              mb={"12px"}
            >
              <IconBox
                mr="10px"
                w="30px"
                h="20px"
                icon={
                  card?.card_brand === "visa" ? (
                    <IconCardVisa w="30px" h="20px" />
                  ) : card?.card_brand === "mastercard" ? (
                    <IconCardMaster w="30px" h="20px" />
                  ) : card?.card_brand === "americanexpress" ? (
                    <IconCardExpress w="30px" h="20px" />
                  ) : card?.card_brand === "discover" ? (
                    <IconCardDiscover w="30px" h="20px" />
                  ) : (
                    ""
                  )
                }
              />
              <Text
                fontSize="14px"
                lineHeight="24px"
                fontWeight="400"
                color="#000"
              >
                Ending in {card.card_number.slice(-4)}
              </Text>
              {/* {card.user_id === user?.user_id && (
                <Button
                  padding={"5px 20px"}
                  backgroundColor="#F3F5F5"
                  borderRadius="100px"
                  minW="fit-content"
                  height="34px"
                  color="#000"
                  fontSize="12px"
                  lineHeight="24px"
                  fontWeight="400"
                  ml="auto"
                  onClick={() => onRemoveCard(card.id)}
                >
                  Remove
                </Button>
              )} */}
            </Flex>
          ))}
        </Box>
      )}

      {!isBusiness && individualCards?.length > 0 && (
        <Box ml="20px" mt="20px">
          <Text
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
            color="#000"
            mb="5px"
          >
            My Credit Cards
          </Text>
          {individualCards.map((card: any, index: number) => (
            <Flex
              key={index}
              padding={{md: "15px 27px", base: "15px 20px"}}
              alignItems="center"
              border="1px solid #DCDCDC"
              borderRadius="10px"
              mb={"12px"}
            >
              <IconBox
                mr="10px"
                w="30px"
                h="20px"
                icon={
                  card?.card_brand === "visa" ? (
                    <IconCardVisa w="30px" h="20px" />
                  ) : card?.card_brand === "mastercard" ? (
                    <IconCardMaster w="30px" h="20px" />
                  ) : card?.card_brand === "americanexpress" ? (
                    <IconCardExpress w="30px" h="20px" />
                  ) : card?.card_brand === "discover" ? (
                    <IconCardDiscover w="30px" h="20px" />
                  ) : (
                    ""
                  )
                }
              />
              <Text
                fontSize="14px"
                lineHeight="24px"
                fontWeight="400"
                color="#000"
              >
                Ending in {card.card_number.slice(-4)}
              </Text>
              {card.user_id === user?.user_id && (
                <Button
                  padding={"5px 20px"}
                  backgroundColor="#F3F5F5"
                  borderRadius="100px"
                  minW="fit-content"
                  height="34px"
                  color="#000"
                  fontSize="12px"
                  lineHeight="24px"
                  fontWeight="400"
                  ml="auto"
                  onClick={() => onRemoveCard(card.id)}
                >
                  Remove
                </Button>
              )}
            </Flex>
          ))}
        </Box>
      )}

      {openCardInput && (
        <Box
          padding={{ md: "25px", base: "20px" }}
          border="1px solid #DCDCDC"
          borderRadius="10px"
        >
          <Box padding="15px" borderRadius="50px" border="1px solid #E9E9E9">
            <Box id="card-element"></Box>
          </Box>
          <Flex id="name-on-card" mt="20px" columnGap="20px">
            <Input
              name="name-on-card"
              borderRadius="50px"
              border="1px solid #E9E9E9"
              background="#FFF"
              sx={{
                width: "65%",
                padding: "15px 20px !important",
                height: "60px",
              }}
              placeholder="Name On Card"
              _placeholder={{
                color: "#777",
                fontSize: "16px !important",
              }}
              value={cardHolderName}
              onChange={(e) => {
                // setCardHolderError(null);
                setCardHolderName(e.target.value);
              }}
            />
            <Input
              name="postal"
              borderRadius="50px"
              border="1px solid #E9E9E9 !important"
              sx={{
                width: "35%",
                padding: "15px 20px !important",
                height: "60px",
              }}
              placeholder="Zipcode"
              _placeholder={{
                color: "#777",
                fontSize: "16px !important",
              }}
              value={cardZipCode}
              onChange={(e) => {
                setCardZipCode(e.target.value);
              }}
            />
          </Flex>
          <Flex flexBasis="100%" justifyContent="flex-end" mt="20px">
            <Button
              w="140px"
              height="46px"
              padding="15px 35px"
              borderRadius="100px"
              bgColor="transparent"
              color="#2F80ED"
              fontSize="14px"
              lineHeight="17px"
              fontWeight="bold"
              onClick={() => {
                setOpenCardInput(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type="button"
              ml="20px"
              w="140px"
              height="46px"
              padding="15px 35px"
              borderRadius="100px"
              bgColor="#2F80ED"
              color="#FFF"
              fontSize="14px"
              lineHeight="17px"
              fontWeight="bold"
              disabled={
                !stripe || !cardElement || !cardHolderName || !cardZipCode
              }
              _disabled={{
                backgroundColor: "#E9E9E9 !important",
                "&:hover": {
                  backgroundColor: "#E9E9E9 !important",
                },
              }}
              _hover={{
                bgColor: "#3311DB !important",
              }}
              onClick={() => {
                saveCardInformation();
              }}
              isLoading={isSavingCardInfo}
            >
              Save
            </Button>
          </Flex>
        </Box>
      )}
      <Flex
        columnGap="35px"
        alignItems="center"
        mt="24px"
        cursor="pointer"
        w="fit-content"
        onClick={() => setOpenCardInput(true)}
      >
        <IconBox w="28px" h="28px" icon={<IconAdd w="28px" h="28px" />} />
        <Text
          fontSize="14px"
          lineHeight="135%"
          color="#000"
          fontWeight="400"
          textDecoration="underline"
        >
          {cards?.length > 0 ? "Add another card" : "Add a Credit Card"}
        </Text>
      </Flex>
      <Text
        fontSize="17px"
        lineHeight="24px"
        fontWeight="700"
        color="#000"
        mt="40px"
        mb="20px"
      >
        Bank Account / ACH
      </Text>
      {banks?.length > 0 &&
        banks.map((bank: any, index: number) => (
          <Flex
            key={index}
            padding="15px 27px"
            alignItems="center"
            border="1px solid #DCDCDC"
            borderRadius="10px"
            mb={"12px"}
          >
            <Text
              fontSize="14px"
              lineHeight="24px"
              fontWeight="400"
              color="#000"
            >
              {bank.account_number}
            </Text>
            <Button
              padding={"5px 20px"}
              backgroundColor="#F3F5F5"
              borderRadius="100px"
              minW="fit-content"
              height="34px"
              color="#000"
              fontSize="12px"
              lineHeight="24px"
              fontWeight="400"
              ml="auto"
              onClick={() => onRemoveBank(bank)}
            >
              Remove
            </Button>
          </Flex>
        ))}
      {openAddressForm && (
        <BankInputForm
          mt="20px"
          onCancel={() => {
            setOepnAddressForm(false);
          }}
        />
      )}
      <Flex
        columnGap="35px"
        alignItems="center"
        mt="30px"
        cursor="pointer"
        w="fit-content"
        onClick={() => setOepnAddressForm(true)}
      >
        <IconBox w="28px" h="28px" icon={<IconAdd w="28px" h="28px" />} />
        <Text
          fontSize="14px"
          lineHeight="135%"
          color="#000"
          fontWeight="400"
          textDecoration="underline"
        >
          {banks?.length > 0
            ? "Add another Bank Account"
            : "Add a Bank Account"}
        </Text>
      </Flex>
    </Box>
  );
}
