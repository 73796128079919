import Icon1 from "assets/img/services/img_notarization.png";
import Icon2 from "assets/img/services/img_business-translations.png";

export type ServiceRowObj = {
  id: number;
  icon: string;
  title: string;
  description: string;
  // options: string[];
  price: number;
};

export const servicesData: ServiceRowObj[] = [
  {
    id: 1,
    icon: Icon1,
    title: "Notarization",
    description:
      "A notary public will notarize the translation certificate, enhancing legal validity. Recommended for official documents.",
    price: 19.95,
  },
  {
    id: 2,
    icon: Icon2,
    title: "Two-Day Shipping Delivery",
    description:
      "If the translation is for official use, request original signed, ink-stamped copies of your translation and get it within 2 days.",
    price: 15,
  },
  {
    id: 3,
    icon: Icon2,
    title: "Expedited Turnaround",
    description:
      "Is your translation urgent? Reduce the turnaround time by 50% with an expedited order for $10 per page.",
    price: 20,
  },
];
