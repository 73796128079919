import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import IconBox from "components/icons/IconBox";
import { IconPaypal } from "components/icons/Icons";
import { fetchPerPagePrice } from "network/helper";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { saveOrderRequest } from "store/customerProject/reducer";
import { uploadProjectSelector } from "store/customerProject/selector";
import { userSelector } from "store/user/selector";
import { DocumentType } from "../../variables/orderData";
import PaypalModal from "./PaypalModal";
const pdfMake = require("pdfmake");

export default function PayPalSelector() {
  const { orderId } = useParams();
  const uploadProject = useSelector(uploadProjectSelector);
  const user = useSelector(userSelector);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const connectPaypal = () => {
    onOpen();
  };
  const orderSummary = useMemo(() => {
    let pages = 0;
    let totalPrice = 0;
    uploadProject.documents.forEach((it: DocumentType) => {
      pages += Number(it.pages);
    });

    const targetLanguage = uploadProject.targetLanguage;

    totalPrice = pages * fetchPerPagePrice(uploadProject.targetLanguage) + 0;
    if (
      uploadProject.transitionType === "standard" &&
      uploadProject.totalWords < 250
    ) {
      totalPrice = 20;
    }
    if (uploadProject.isNotarized) {
      totalPrice += 19.95;
    }
    if (uploadProject.isMailHardCopy) {
      totalPrice += 15;
    }

    if (uploadProject.transitionSpeed === "express") {
      if (uploadProject.transitionType === "certified") {
        totalPrice += 10 * uploadProject.totalPage * targetLanguage.length;
      } else {
        // totalPrice += 0.004 * uploadProject.totalWords * targetLanguage.length;
        if (uploadProject.totalWords < 250) {
          totalPrice += 10 * targetLanguage.length;
        } else {
          totalPrice +=
            0.004 * uploadProject.totalWords * targetLanguage.length;
        }
      }
    }

    return {
      pages,
      totalPrice: totalPrice,
      langCnt: targetLanguage.length + 1,
    };
  }, [uploadProject]);
  const completeOrder = () => {
    onClose();
    if (uploadProject.text?.trim().length > 1) {
      pdfMake.fonts = {
        Roboto: {
          normal:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
          bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
          italics:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
          bolditalics:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
        },
      };

      const pdfDocGenerator = pdfMake.createPdf({
        content: [{ text: uploadProject.text }],
      });

      pdfDocGenerator.getBlob((blob: any) => {
        const fileName = `${uploadProject.projectTitle}-source.pdf`;
        const file = new File([blob], fileName, { type: "application/pdf" });

        const payload = {
          ...uploadProject,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          pageCount: orderSummary.pages,
          price: orderSummary.totalPrice,
          order_id: orderId,
          virtualFile: file,
          paymentOption: "paypal",
        };

        dispatch(
          saveOrderRequest({
            user_id: user.user_id,
            order: payload,
            order_id: orderId,
          })
        );
      });
    } else {
      const payload = {
        ...uploadProject,
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        pageCount: orderSummary.pages,
        price: orderSummary.totalPrice,
        order_id: orderId,
        paymentOption: "paypal",
      };
      dispatch(
        saveOrderRequest({
          user_id: user.user_id,
          order: payload,
          order_id: orderId,
        })
      );
    }
  };

  return (
    <Flex
      alignItems="center"
      padding="13.5px 15px"
      border={`1px solid ${
        uploadProject.paymentOption === "paypal" ? "#2F80ED" : "#E9E9E9"
      }`}
      pointerEvents="none"
      borderRadius="50px"
      cursor="pointer"
      _hover={{
        borderColor: "#2F80ED",
      }}
      onClick={(e) => {
        connectPaypal();
      }}
    >
      <Box
        w="20px"
        h="20px"
        borderRadius="50%"
        border="1px solid #2F80ED"
        mr="15px"
      ></Box>
      <IconBox w="70px" h="20px" icon={<IconPaypal w="70px" h="20px" />} />
      <Button
        padding="5px 20px"
        fontSize="12px"
        lineHeight="18px"
        h="35px"
        color="#000"
        fontWeight="400"
        ml="auto"
        bgColor="#F3F5F5"
        borderRadius="100px"
        onClick={(e) => {
          connectPaypal();
        }}
      >
        Connect
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            fontSize="17px"
            lineHeight="24px"
            fontWeight="700"
            color="#000"
            textAlign="center"
          >
            Paypal Connector
          </ModalHeader>
          <ModalBody pt={4}>
            <PaypalModal
              totalPrice={orderSummary.totalPrice}
              completeOrder={completeOrder}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
