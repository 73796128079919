/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearProjectRequest,
  updateProjectStatus,
} from "store/customerProject/reducer";
import _ from "lodash";
import { uploadProjectSelector } from "store/customerProject/selector";
import { ORDER_STEPS } from "types/data-types";
import { supportLanguages } from "../variables/data";
import FileUploadHandler from "components/fileuploader/FileUploadHandler";
import { DocumentType } from "views/customer/order/variables/orderData";
import TranslationTypeButton from "views/customer/order/components/translation/TranslationTypeButton";
import { useNavigate, useParams } from "react-router-dom";
import { axiosService } from "network/axios";
import { userSelector } from "store/user/selector";
import { IconChecked } from "components/icons/Icons";
import IconBox from "components/icons/IconBox";
import { setAlertMessageRequest } from "store/ui/reducer";

export default function DataImporter(props: { onChangeOrderStep?: Function }) {
  const { orderId } = useParams();
  const { onChangeOrderStep } = props;
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const uploadProject = useSelector(uploadProjectSelector);
  const [pageCount, setPageCount] = useState(uploadProject.totalPage);
  const [wordsCount, setWordsCount] = useState(uploadProject.totalWords);
  const [countError, setCountError] = useState(null);
  const [targetLanguageError, setTartgetLangueError] = useState(null);
  const [sourceLangQuery, setSourceLangQuery] = useState("");
  const [targetLangQuery, setTargetLangQuery] = useState("");
  const boxShadow = "0px 3.60347px 45.0434px rgba(0, 0, 0, 0.1)";
  const [isSavingDraft, setIsSavingDraft] = useState(false);

  const documents = useMemo(() => {
    return uploadProject.documents;
  }, [uploadProject]);

  useEffect(() => {
    let totalWords = 0;
    let totalPages = 0;
    if (documents.length > 0) {
      documents?.forEach((it: DocumentType) => {
        totalWords += Number(it?.words ?? 0);
        totalPages += Number(it?.pages);
      });
      setWordsCount(totalWords);
      setPageCount(totalPages);
    } else {
      setWordsCount(uploadProject?.totalWords ?? 0);
      setPageCount(uploadProject?.totalPage ?? 0);
    }
  }, [documents]);

  useEffect(() => {
    dispatch(
      updateProjectStatus({
        totalPage: pageCount,
      })
    );
    if (countError) {
      setCountError(null);
    }
  }, [pageCount]);

  useEffect(() => {
    dispatch(
      updateProjectStatus({
        totalWords: wordsCount,
      })
    );
    if (countError) {
      setCountError(null);
    }
  }, [wordsCount]);

  const onChangeLanguages = (target: string, e: string) => {
    if (target === "source") {
      if (uploadProject.sourceLanguage?.includes(e)) {
        onRemoveLanguage(target, e);
      } else {
        dispatch(
          updateProjectStatus({
            sourceLanguage: e,
          })
        );
      }
    } else {
      if (targetLanguageError && !_.isEmpty(e)) {
        setTartgetLangueError(null);
      }
      if (uploadProject.targetLanguage?.includes(e)) {
        onRemoveLanguage(target, e);
      } else {
        dispatch(
          updateProjectStatus({
            targetLanguage: [...uploadProject.targetLanguage, e],
          })
        );
      }
    }
  };

  const onRemoveLanguage = (target: string, e: string) => {
    if (target === "target") {
      let index = uploadProject.targetLanguage?.findIndex((it) => it === e);
      let targetLanguages = _.cloneDeep(uploadProject.targetLanguage);
      targetLanguages.splice(index, 1);
      dispatch(
        updateProjectStatus({
          targetLanguage: targetLanguages,
        })
      );
    } else {
      dispatch(
        updateProjectStatus({
          sourceLanguage: "",
        })
      );
    }
  };

  const onNextStep = async () => {
    // Start GA Call
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "ga4_event",
      event_name: "checkout_documents_provided",
    });
    // End GA Call

    if (uploadProject.targetLanguage.length < 1) {
      setTartgetLangueError("Please select the target langauge.");
      return;
    }

    if (
      uploadProject.transitionType === "standard" &&
      uploadProject.totalWords < 1
    ) {
      setCountError(
        "Wrong value in total words. Please enter a correct words count."
      );
      return;
    }
    if (
      uploadProject.transitionType === "certified" &&
      uploadProject.totalPage < 1
    ) {
      setCountError(
        "Wrong value in total pages. Please enter a correct page count."
      );
      return;
    }
    if (orderId) {
      onChangeOrderStep(ORDER_STEPS.SET_TRANSITION_DETAILS_STEP);
    } else {
      // Save Draft Project
      const user_id = user.user_id;
      const payload = {
        ...uploadProject,
        document_cnt: uploadProject.documents?.length || 0,
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        pageCount: 0,
        price: 0,
        orderStatus: 10,
      };
      setIsSavingDraft(true);
      try {
        const resp: any = await axiosService.post(
          `/projects/${user_id}`,
          payload
        );
        const { orderId } = resp?.data;
        if (orderId) {
          localStorage.setItem(
            "orderStep",
            ORDER_STEPS.SET_UPLOAD_DOCUMENTS_STEP?.toString()
          );
          navigate(`/customer/order/${orderId}#order-details`);
        }
      } catch (err: any) {
        const error =
          err.data.messages.error ||
          "Error in saving the project as draft. please try to do it again & contact to support team if you see the issue permanently.";
        dispatch(
          setAlertMessageRequest({
            type: "error",
            message: error,
          })
        );
      } finally {
        setIsSavingDraft(false);
      }
    }
  };

  const onRestartTranslation = () => {
    localStorage.removeItem("uploadProject");
    dispatch(clearProjectRequest());
    navigate(`/customer/main`);
  };
  const onSelectTransitionType = (type: string) => {
    setCountError(null);
    if (type === "certified") {
      dispatch(
        updateProjectStatus({
          transitionType: type,
        })
      );
    } else {
      dispatch(
        updateProjectStatus({
          transitionType: type,
          isMailHardCopy: false,
          isNotarized: false,
          // is2DayShipping: false,
          address: "",
        })
      );
    }
  };

  const setProjectDescription = (description: string) => {
    dispatch(
      updateProjectStatus({
        projectDescription: description,
      })
    );
  };
  return (
    <Flex
      position="relative"
      flex={1}
      direction="column"
      height="100%"
      padding={{ lg: "40px", md: "30px", base: "20px 0px" }}
      bgColor="#FFF"
      rowGap="32px"
      boxShadow={{ md: boxShadow, base: "none" }}
      borderRadius="15px"
    >
      <Heading
        fontSize="24px"
        lineHeight="normal"
        fontWeight="700"
        textTransform="capitalize"
        color="#000"
      >
        Documents
      </Heading>

      <Box>
        <Heading
          fontSize="15px"
          lineHeight="normal"
          fontWeight="500"
          textTransform="capitalize"
          color="#000"
          mb="12px"
        >
          Translation Type
        </Heading>
        <Flex
          columnGap="10px"
          rowGap="14px"
          flexDirection={{ md: "row", base: "column" }}
        >
          <TranslationTypeButton
            w="full"
            type="certified"
            onSelectTransitionType={onSelectTransitionType}
            selected={uploadProject.transitionType === "certified"}
            label={
              "Word-for-word translation placed on a company letterhead and issued with a signed and stamped Certificate of Translation Accuracy."
            }
          />
          <TranslationTypeButton
            w="full"
            type="standard"
            onSelectTransitionType={onSelectTransitionType}
            selected={uploadProject.transitionType === "standard"}
            label={
              "Standard translation delivered as an editable document. For business or personal use where certification isn't required."
            }
          />
        </Flex>
      </Box>

      <Box>
        <Heading
          fontSize="15px"
          lineHeight="normal"
          fontWeight="500"
          textTransform="capitalize"
          color="#000"
          mb="12px"
        >
          Language Pair
        </Heading>
        <Flex
          columnGap="10px"
          rowGap="12px"
          flexDirection={{ "3sm": "row", base: "column" }}
        >
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              sx={{
                height: "48px",
                textAlign: "left",
                padding: "15px 16px",
                borderRadius: "100px",
                background: "#FFF",
                border: "1px solid #E0E0E0",
                width: "100%",
                color: _.isEmpty(uploadProject.sourceLanguage)
                  ? "rgba(0,0,0,0.3)"
                  : "#000",
                fontSize: "15px",
                fontWeight: "400",
                lineHeight: "normal",
                fontFamily: "Inter",
                "&[data-active]": {
                  backgroundColor: "#EFEFEF",
                },
              }}
            >
              {!_.isEmpty(uploadProject.sourceLanguage)
                ? uploadProject.sourceLanguage
                : "From"}
            </MenuButton>
            <MenuList maxH="300px" overflow="auto">
              <Box paddingY={2} px={1} boxSizing="border-box" bgColor="#F2F2F2">
                <Input
                  autoFocus={true}
                  tabIndex={1}
                  placeholder="Enter the keyword.."
                  borderRadius="5px"
                  sx={{
                    background: "#FFF !important",
                    padding: "2px 5px !important",
                    height: "36px",
                    fontSize: "14px",
                    lineHeight: "120%",
                  }}
                  _placeholder={{
                    fontSize: "14px",
                  }}
                  value={sourceLangQuery}
                  onChange={(e) => {
                    setSourceLangQuery(e.target.value);
                  }}
                />
              </Box>
              {(sourceLangQuery?.length < 1
                ? supportLanguages
                : supportLanguages?.filter((it) =>
                    it.toLowerCase()?.includes(sourceLangQuery.toLowerCase())
                  )
              ).map((it: string, index: number) => (
                <MenuItem
                  key={index}
                  onClick={() => onChangeLanguages("source", it)}
                  sx={{
                    background:
                      uploadProject.sourceLanguage === it ? "#2F80ED" : "#FFF",
                    color:
                      uploadProject.sourceLanguage === it ? "#FFF" : "#000",
                  }}
                  _hover={{
                    color: "#000",
                  }}
                >
                  {it}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
          <Menu closeOnSelect={false}>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              sx={{
                height: "48px",
                padding: "15px 16px",
                borderRadius: "100px",
                background: "#FFF",
                border: targetLanguageError
                  ? "1px solid #F00"
                  : "1px solid #E0E0E0",
                width: "100%",
                textAlign: "left",
                color:
                  uploadProject.targetLanguage.length > 0
                    ? "#000"
                    : "rgba(0,0,0,0.3)",
                fontSize: "15px",
                fontWeight: "400",
                lineHeight: "normal",
                fontFamily: "Inter",
                "&[data-active]": {
                  backgroundColor: "#EFEFEF",
                },
              }}
            >
              <Box
                width="100%"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {uploadProject.targetLanguage.length > 0
                  ? uploadProject.targetLanguage?.join(", ")
                  : "Select Target Language(s)"}
              </Box>
            </MenuButton>

            <MenuList maxH="300px" overflow="auto" width="100%">
              <Box paddingY={2} px={1} boxSizing="border-box" bgColor="#F2F2F2">
                <Input
                  autoFocus={true}
                  tabIndex={1}
                  placeholder="Enter the keyword.."
                  borderRadius="5px"
                  sx={{
                    background: "#FFF !important",
                    padding: "2px 5px !important",
                    height: "36px",
                    fontSize: "14px",
                    lineHeight: "120%",
                  }}
                  _placeholder={{
                    fontSize: "14px",
                  }}
                  value={targetLangQuery}
                  onChange={(e) => {
                    setTargetLangQuery(e.target.value);
                  }}
                />
              </Box>
              {(targetLangQuery?.length < 1
                ? supportLanguages
                : supportLanguages?.filter((it) =>
                    it.toLowerCase()?.includes(targetLangQuery.toLowerCase())
                  )
              ).map((it: string, index: number) => (
                <MenuItem
                  sx={{
                    px: "5px",
                    background: uploadProject.targetLanguage?.includes(it)
                      ? "#2F80ED"
                      : "#FFF",
                    color: uploadProject.targetLanguage?.includes(it)
                      ? "#FFF"
                      : "#000",
                  }}
                  key={index}
                  onClick={() => onChangeLanguages("target", it)}
                >
                  <IconBox
                    w="20px"
                    h="20px"
                    sx={{
                      opacity: uploadProject.targetLanguage?.includes(it)
                        ? 1
                        : 0,
                    }}
                    icon={
                      <IconChecked
                        sx={{
                          path: {
                            fill: "#FFF",
                          },
                        }}
                      />
                    }
                  />
                  {it}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Flex>
        {targetLanguageError && (
          <Flex mt={2}>
            <Text
              fontSize="14px"
              lineHeight="130%"
              color="#E00"
              ml="auto"
              w="calc(50% - 5px)"
              textAlign="center"
            >
              {targetLanguageError}
            </Text>
          </Flex>
        )}

        <Text
          mt="12px"
          fontSize="14px"
          lineHeight="1.4"
          fontWeight="400"
          color="#666"
          fontFamily="Inter"
          // lineHeight="normal"
        >
          {/* Have multiple languages? After checkout, create an account to handle
          multi-language orders in your dashboard. */}
        </Text>
      </Box>

      {uploadProject.transitionType === "certified" && (
        <Box>
          <Heading
            fontSize="15px"
            lineHeight="normal"
            fontWeight="500"
            textTransform="capitalize"
            color="#000"
            mb="12px"
          >
            Number of Pages
          </Heading>
          <Flex
            flexDirection={{ "3sm": "row", base: "column" }}
            flexWrap={{ "3sm": "wrap", base: "nowrap" }}
            columnGap="20px"
            rowGap="20px"
            alignItems={{ "3sm": "center", base: "flex-start" }}
          >
            <NumberInput
              onChange={(valueString) => {
                setPageCount(Number(valueString));
              }}
              value={pageCount}
              min={0}
              sx={{
                width: "95px",
                borderColor: countError ? "#f00" : "#E0E0E0",
              }}
            >
              <NumberInputField
                sx={{
                  backgroundColor: "#FFF",
                  padding: "0px 16px",
                  borderRadius: "100px !important",
                  border: "1px solid #E0E0E0",
                }}
              />
              <NumberInputStepper right="5px">
                <NumberIncrementStepper
                  border="none"
                  alignItems={"flex-end"}
                  mb="3px"
                  _hover={{
                    backgroundColor: "transparent !important",
                    color: "#2F80ED",
                  }}
                />
                <NumberDecrementStepper
                  border="none"
                  alignItems={"flex-start"}
                  mt="3px"
                  _hover={{
                    backgroundColor: "transparent !important",
                    color: "#2F80ED",
                  }}
                />
              </NumberInputStepper>
            </NumberInput>
            <Text
              fontSize="13px"
              fontWeight="400"
              color="#666"
              lineHeight="1.4"
              flex="1"
              maxW="365px"
            >
              A page is defined as 250 words or fewer including numbers on a
              single sheet.
            </Text>
          </Flex>
          {countError && (
            <Flex mt={2}>
              <Text
                fontSize="14px"
                lineHeight="130%"
                color="#E00"
                textAlign="center"
              >
                {countError}
              </Text>
            </Flex>
          )}
        </Box>
      )}

      {uploadProject.transitionType === "standard" && (
        <Box>
          <Heading
            fontSize="15px"
            lineHeight="normal"
            fontWeight="500"
            textTransform="capitalize"
            color="#000"
            mb="12px"
          >
            Total Word Count
          </Heading>
          <Flex
            flexDirection={{ "3sm": "row", base: "column" }}
            flexWrap={{ "3sm": "wrap", base: "nowrap" }}
            columnGap="20px"
            rowGap="20px"
            alignItems={{ "3sm": "center", base: "flex-start" }}
          >
            <NumberInput
              onChange={(valueString) => {
                setWordsCount(Number(valueString));
              }}
              value={wordsCount}
              min={0}
              sx={{
                width: "95px",
                borderColor: countError ? "#f00" : "#E0E0E0",
              }}
            >
              <NumberInputField
                sx={{
                  backgroundColor: "#FFF",
                  padding: "0px 16px",
                  borderRadius: "100px !important",
                  border: "1px solid #E0E0E0",
                }}
              />
              <NumberInputStepper right="5px">
                <NumberIncrementStepper
                  border="none"
                  alignItems={"flex-end"}
                  mb="3px"
                  _hover={{
                    backgroundColor: "transparent !important",
                    color: "#2F80ED",
                  }}
                />
                <NumberDecrementStepper
                  border="none"
                  alignItems={"flex-start"}
                  mt="3px"
                  _hover={{
                    backgroundColor: "transparent !important",
                    color: "#2F80ED",
                  }}
                />
              </NumberInputStepper>
            </NumberInput>
            <Text
              fontSize="13px"
              fontWeight="400"
              color="#666"
              lineHeight="normal"
              flex="1"
              maxW="365px"
            >
              Enter the total word count from all of your documents.
            </Text>
          </Flex>
          {countError && (
            <Flex mt={2}>
              <Text
                fontSize="14px"
                lineHeight="130%"
                color="#E00"
                textAlign="center"
              >
                {countError}
              </Text>
            </Flex>
          )}
        </Box>
      )}

      <Box
        width="100%"
        flex={{ md: "unset", base: 1 }}
        height="100%"
        display="flex"
        flexDir="column"
        sx={{
          label: {
            height: "100%",
            flex: 1,
            display: { md: "block", base: "flex" },
            flexDirection: "column",
            ">div": {
              flex: 1,
            },
          },
        }}
      >
        <Heading
          fontSize="15px"
          lineHeight="normal"
          fontWeight="500"
          textTransform="capitalize"
          color="#000"
          mb="16px"
        >
          Upload the files you need translated
        </Heading>
        <FileUploadHandler />
        <Text
          fontSize="13px"
          fontWeight="400"
          color="#666"
          lineHeight="1.4"
          fontStyle="italic"
          mt="16px"
        >
          You can upload documents from your Desktop, Direct Link, Facebook,
          Instagram, Flickr, Google Drive, Evernote, Box, One Drive, Dropbox.
        </Text>
      </Box>

      <Box>
        <Heading
          fontSize="15px"
          lineHeight="normal"
          fontWeight="500"
          textTransform="capitalize"
          color="#000"
          mb="12px"
        >
          Additional Details (Optional)
        </Heading>
        <Textarea
          padding="16px"
          borderRadius="16px"
          backgroundColor="#FFF"
          border="1px solid #E0E0E0 !important"
          placeholder="Details (Optional)"
          value={uploadProject.projectDescription}
          onChange={(e) => {
            setProjectDescription(e.target?.value ?? "");
          }}
          _focusWithin={{
            border: "1px solid rgb(47, 128, 237) !important",
          }}
          _placeholder={{
            fontSize: "15px !important",
            fontWeight: "400 !important",
            color: "rgba(0, 0, 0, 0.3) !important",
            textTransform: "capitalize !important",
            lineHeight: "normal !important",
          }}
        />
      </Box>
      <Flex width="100%" flex={{ md: "unset", base: 1 }} direction="column">
        <Flex w="100%" justifyContent="space-between" alignItems="center">
          {orderId && (
            <Flex
              alignItems="center"
              fontSize="15px"
              lineHeight="normal"
              color="#000"
              fontWeight="400"
              cursor="pointer"
              transition="color .25s ease-in-out"
              _hover={{
                color: "#2F80ED",
              }}
              onClick={() => {
                onRestartTranslation();
              }}
            >
              Restart
            </Flex>
          )}

          <Button
            ml="auto"
            type="button"
            w="fit-content"
            height="44px"
            padding="13px 16px"
            borderRadius="100px"
            bgColor="#2F80ED"
            color="#FFF"
            fontSize="14px"
            lineHeight="14px"
            fontWeight="bold"
            _loading={{
              pointerEvents: "none",
            }}
            isLoading={isSavingDraft}
            onClick={() => {
              onNextStep();
            }}
            _disabled={{
              backgroundColor: "#E9E9E9 !important",
            }}
            _hover={{
              bgColor: "#3311DB !important",
            }}
          >
            Continue to Options
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
