import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import _ from "lodash";
import { axiosService } from "network/axios";
import { useEffect, useState } from "react";
import { supportLanguages } from "views/customer/main/variables/data";

export default function Languagues() {
  const [isFetching, setIsFetching] = useState(true);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    fetchTeams();
  }, []);

  const fetchTeams = async () => {
    setIsFetching(true);
    const resp: any = await axiosService.get("/get/teams");
    setTeams(resp?.data?.result || []);
    // setUserRoles(resp?.data?.results || []);
    setIsFetching(false);
  };
  return (
    <Box mt="30px">
      <Text
        fontSize="17px"
        lineHeight="24px"
        color="#000"
        fontWeight="700"
        whiteSpace="nowrap"
        mr="50px"
        w="142px"
        minW="142px"
        mb="20px"
      >
        Languages
      </Text>
      <TableContainer maxHeight="350px" overflowY="auto">
        <Table variant="simple">
          <Thead position="sticky" top="0">
            <Tr>
              <Th isNumeric width="20px" bgColor="#FFF">
                No
              </Th>
              <Th bgColor="#FFF">Languagues</Th>
            </Tr>
          </Thead>
          <Tbody>
            {supportLanguages?.map((it: string, index: number) => (
              <Tr key={index}>
                <Td isNumeric>{index + 1}</Td>
                <Td>{it}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}
