import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  // deleteProjectRequestFailed,
  // deleteProjectRequestSuccess,
  paymentFailed,
} from "store/customerProject/reducer";
import {
  getMessagesRequestFailed,
  getReceiversRequestFailed,
  requestDownloadOriginFileFailed,
  sendMessageRequestFailed,
  sendMessageRequestSuccess,
} from "store/project/reducer";
import {
  addBankRequestFailed,
  addTeammemberRequestFailed,
  deleteTeammemberRequestFailed,
  fetchTeamUsersRequestFailed,
  getIDRequestFailed,
  getInvoicesRequestFailed,
  putIDRequestFailed,
  signUpRequestFailed,
  signUpRequestSuccess,
  updateUserSuccess,
  uploadInvoiceRequestFailed,
  uploadInvoiceRequestSuccess,
} from "store/user/reducer";
import { setAlertMessageRequest, setAlertMessageSuccess } from "./reducer";

function* setAlert({ payload }: PayloadAction<any>) {
  yield put(setAlertMessageSuccess(payload));
}

export function* uiEffects() {
  yield takeLatest(setAlertMessageRequest.type, setAlert);
  yield takeLatest(signUpRequestSuccess.type, setAlert);
  yield takeLatest(signUpRequestFailed.type, setAlert);
  yield takeLatest(updateUserSuccess.type, setAlert);
  yield takeLatest(requestDownloadOriginFileFailed.type, setAlert);
  yield takeLatest(getIDRequestFailed.type, setAlert);
  yield takeLatest(putIDRequestFailed.type, setAlert);
  yield takeLatest(requestDownloadOriginFileFailed.type, setAlert);
  yield takeLatest(paymentFailed.type, setAlert);
  yield takeLatest(addBankRequestFailed.type, setAlert);
  yield takeLatest(addTeammemberRequestFailed.type, setAlert);
  yield takeLatest(fetchTeamUsersRequestFailed.type, setAlert);
  yield takeLatest(deleteTeammemberRequestFailed.type, setAlert);
  yield takeLatest(getReceiversRequestFailed.type, setAlert);
  yield takeLatest(getMessagesRequestFailed.type, setAlert);
  yield takeLatest(sendMessageRequestSuccess.type, setAlert);
  yield takeLatest(sendMessageRequestFailed.type, setAlert);
  yield takeLatest(uploadInvoiceRequestSuccess.type, setAlert);
  yield takeLatest(uploadInvoiceRequestFailed.type, setAlert);
  yield takeLatest(getInvoicesRequestFailed.type, setAlert);
  // yield takeLatest(deleteProjectRequestSuccess.type, setAlert);
  // yield takeLatest(deleteProjectRequestFailed.type, setAlert);
}

const uiSaga = [call(uiEffects)];

export default uiSaga;
