import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/App.css";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme/theme";
import { Provider } from "react-redux";
import { store } from "./store/common/store";
import Root from "root";
import { GoogleOAuthProvider } from "@react-oauth/google";

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <GoogleOAuthProvider clientId="751640935578-414nn31ii22k8ha0f7o9ftf148t16s1l.apps.googleusercontent.com">
      <React.StrictMode>
        <Provider store={store}>
          <Root />
        </Provider>
      </React.StrictMode>
    </GoogleOAuthProvider>
  </ChakraProvider>,
  document.getElementById("root")
);
