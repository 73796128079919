import { Alert, Box, Flex, Heading, IconButton } from "@chakra-ui/react";
import { IconClose } from "components/icons/Icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBankRequest } from "store/user/reducer";
import { userSelector } from "store/user/selector";
import CustomerProfileDetailsForm from "views/customer/profile/components/personal-detail/CustomerProfileDetailsForm";
import ProfilePhotoEditor from "views/customer/profile/components/personal-detail/ProfilePhotoEditor";
import Invoice from "views/manager/profile/components/Invoice";
import PersonalDetails from "./components/PersonalDetails";
import ProfileMenu from "./components/ProfileMenu";
import { translatorProfileMenu } from "./variables/profileMenu";

export default function TranslatorProfileDetails(props: {
  isOpen: boolean;
  onClose: Function;
}) {
  const { isOpen, onClose } = props;
  const [selectMenu, setSelectMenu] = useState(translatorProfileMenu[0]);
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    if (user) {
      dispatch(getBankRequest({ user_id: user.user_id }));
    }
  }, [user]);
  return (
    <Box
      w={{
        base: "100vw",
        lg: "100vw",
        xl: "calc(100vw - 230px)",
        baseWidth: "calc(100vw - (100vw - 1440px) / 2 - 230px)",
      }}
      transition="transform 0.3s ease-in-out"
      transform={isOpen === true ? `translateX(0)` : `translateX(100%)`}
      h="100vh"
      position="fixed"
      overflow="auto"
      bgColor="#FFF"
      zIndex="200"
      boxShadow={isOpen === true ? "0px 4px 40px rgba(0, 0, 0, 0.1)" : "none"}
      boxSizing="border-box"
      padding={{
        md: "50px 40px 60px",
        base: "30px",
        baseWidth: "50px calc((100vw - 1440px) / 2 + 40px) 50px 60px",
      }}
      top="0px"
      right="0px"
    >
      <Flex h="full" wrap="wrap" width="100%">
        <IconButton
          position={{ md: "unset", base: "absolute" }}
          right="30px"
          top="30px"
          onClick={(e) => onClose()}
          aria-label="search"
          h={{ md: "52px", base: "30px" }}
          w={{ md: "52px", base: "30px" }}
          borderRadius="50%"
          bg="inherit"
          marginRight={{ xl: "60px", lg: "30px", base: "0px" }}
          _active={{
            bg: "inherit",
            transform: "none",
            borderColor: "transparent",
          }}
          _focus={{
            boxShadow: "none",
          }}
          icon={
            <IconClose
              w={{ md: "52px", base: "30px" }}
              h={{ md: "52px", base: "30px" }}
            />
          }
        />
        <Flex flex="1" direction="column" width="100%">
          <Heading
            fontFamily="Canela Text"
            fontSize={{ md: "30px", sm: "24px" }}
            lineHeight="135%"
            fontWeight="700"
            color="#000"
            marginTop="6px"
          >
            {selectMenu.label}
          </Heading>
          <Flex
            wrap={{ md: "wrap", base: "nowrap" }}
            flexDirection={{ md: "row", base: "column-reverse" }}
            columnGap={{ md: "60px", base: "30px" }}
            marginTop={{ md: "50px", base: "40px" }}
            marginBottom={{ md: "50px", base: "40px" }}
          >
            <Box flex="1" maxW={{ md: "calc(100% - 350px)", base: "100%" }}>
              {selectMenu.link === "account-details" && (
                <>
                  <ProfilePhotoEditor />
                  <CustomerProfileDetailsForm />
                </>
              )}
              {selectMenu.link === "personal-details" && (
                <>
                  <PersonalDetails />
                </>
              )}
              {selectMenu.link === "invocing-payroll" && (
                <>
                  <Invoice />
                </>
              )}
              {selectMenu.link === "team" && (
                <>
                  <Box>
                    <Alert status="warning">
                      No Design & No details for this feature. Comming soon!
                    </Alert>
                  </Box>
                </>
              )}
            </Box>
            <Box
              width={{ md: "290px", base: "100%" }}
              marginBottom={{ md: "0px", base: "30px" }}
            >
              <ProfileMenu
                selectedMenuItem={selectMenu}
                onMenuItemClick={(e: any) => setSelectMenu(e)}
              />
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}
