import { Box, Flex, Text } from "@chakra-ui/react";
import IconBox from "components/icons/IconBox";
import { IconAdd } from "components/icons/Icons";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  bankAccountsSelector,
  teamUsersSelector,
  userSelector,
} from "store/user/selector";
import { TeamRawObj } from "../../variables/teamMembers";
import CollaboratorRowItem from "./CollaboratorRowItem";
import SendInvitationForm from "./SendInvitationForm";
import emptyAvatar from "assets/img/avatars/avatar.png";
import { setAlertMessageRequest } from "store/ui/reducer";

export default function Collaborators() {
  const teamUsers = useSelector(teamUsersSelector);
  const user = useSelector(userSelector);
  const banks = useSelector(bankAccountsSelector);
  const dispatch = useDispatch();

  const isBusiness = useMemo(() => {
    return user.is_business;
  }, [user]);

  const isAdmin = useMemo(() => {
    return user.is_admin;
  }, [user]);
  const members: TeamRawObj[] = useMemo(() => {
    return teamUsers?.map((it) => {
      return {
        id: it.team_users_id,
        avatar: it.profile_pic_src
          ? it.profile_pic_src?.includes("http")
            ? it.profile_pic_src
            : `${process.env.REACT_APP_API_BASE_URL}/public_html/images/${it.profile_pic_src}`
          : emptyAvatar,
        name:
          it?.first_name || it?.last_name
            ? `${it?.first_name} ${it.last_name}`
            : it.email,
        role: it.role === "0" ? "admin" : "standard",
      };
    });
  }, [teamUsers]);
  const [openInvitationForm, setOpenInvitationForm] = useState(false);
  useEffect(() => {
    setOpenInvitationForm(false);
  }, [teamUsers]);

  const onClickOpenInvitationForm = () => {
    if (banks.length < 1) {
      dispatch(
        setAlertMessageRequest({
          type: "error",
          message:
            "You can't invite team members cuz you don't have any bank & card information.",
        })
      );
    } else {
      setOpenInvitationForm(true);
    }
  };

  return (
    <Box>
      {members.length > 0 &&
        members.map((item, index) => (
          <CollaboratorRowItem data={item} key={index} />
        ))}
      {(isBusiness || isAdmin) && members.length < 1 && (
        <>
          <Flex
            alignItems={"flex-start"}
            flexDirection={{ md: "row", base: "column" }}
          >
            <Text
              fontSize="17px"
              lineHeight="24px"
              color="#000"
              fontWeight="700"
              whiteSpace="nowrap"
              mr="50px"
              w="120px"
              minW="120px"
              mb="20px"
            >
              Collaborators
            </Text>
            <Text
              fontSize="14px"
              lineHeight="135%"
              color="#000"
              fontWeight="400"
            >
              Invite your team members to create and manage translation projects
              directly under your account.
            </Text>
          </Flex>
        </>
      )}
      {!(isBusiness || isAdmin) && members.length < 1 && (
        <Text fontSize="14px" lineHeight="135%" color="#000" fontWeight="400">
          No team members.
        </Text>
      )}

      {openInvitationForm && <SendInvitationForm />}
      {(isBusiness || isAdmin) && (
        <Flex
          columnGap="35px"
          alignItems="center"
          mt="24px"
          cursor="pointer"
          w="fit-content"
          onClick={() => {
            onClickOpenInvitationForm();
          }}
        >
          <IconBox w="28px" h="28px" icon={<IconAdd w="28px" h="28px" />} />
          <Text
            fontSize="14px"
            lineHeight="135%"
            color="#000"
            fontWeight="400"
            textDecoration="underline"
          >
            {members.length < 1
              ? "Add a team member"
              : "Add another team member"}
          </Text>
        </Flex>
      )}
    </Box>
  );
}
