/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  CloseButton,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Icon,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
import { FcGoogle } from "react-icons/fc";
import { Field, Formik } from "formik";
import { useEffect, useState } from "react";
import { setAlertMessageRequest } from "store/ui/reducer";
import { useGoogleLogin, googleLogout } from "@react-oauth/google";
import axios from "axios";
import { axiosService } from "network/axios";
import { getLoggedInUser } from "network/helper";
import { useWindowWidth } from "@react-hook/window-size";

type SignInFormInputs = {
  email: string;
  password: string;
};

const inputStyleNormal = {
  fontSize: "14px",
  lineHeight: "17px",
  textAlign: "center",
  color: "black",
  border: "1px solid #E9E9E9",
  borderRadius: "50px",
  padding: "15px 30px",
  height: "60px",
  bgColor: "#FFF",
  boxShadow: "none !important",
};

const inputStyle = {
  fontSize: "14px",
  lineHeight: "17px",
  textAlign: "center",
  color: "black",
  border: "none !important",
  boxShadow: "none !important",
  bgColor: "transparent",
};

export default function SignIn() {
  const initialValues: SignInFormInputs = {
    email: "",
    password: "",
  };

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const login_id = searchParams.get("login_id");
    if (login_id) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("user_details");
      onLoginWithID(Number(login_id));
    }
  }, []);

  const [isDoingLogin, setIsDoingLogin] = useState(false);
  const [authError, setAuthError] = useState(null);
  const windowWidth = useWindowWidth();

  const [showError, setShowError] = useState(false);
  const [isFetchingProfile, setIsFetchingProfile] = useState(false);

  const textColor = useColorModeValue("navy.700", "white");
  const textColorBrand = useColorModeValue("gray.300", "white");
  const googleBg = useColorModeValue("white", "white");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const bgShadow = useColorModeValue(
    "0px 3.60347px 45.0434px rgba(0, 0, 0, 0.1)",
    "unset"
  );

  const buttonBg = "blue.500";

  const goToSignUp = () => {
    navigate("/auth/sign-up");
  };

  const onLogin = async (payload: any) => {
    setShowError(false);
    setIsDoingLogin(true);
    axiosService
      .post("/login", payload)
      .then((res) => {
        const { access_token, orders, expires_at } = res?.data as any;
        const expires_timestamp = new Date(expires_at).getTime();
        const user = getLoggedInUser(access_token);
        localStorage.setItem("access_token", access_token);
        localStorage.setItem("expired_at", `${expires_timestamp}`);
        localStorage.setItem("user_details", JSON.stringify(user));
        localStorage.setItem("total_orders", `${orders}`);
        localStorage.removeItem("order_status");

        if (user.user_role_type_id === "4") {
          navigate("/admin/dashboard");
        } else if (Number(user?.user_role_type_id) === 7) {
          navigate("/admin/unfinished-users");
        } else if (user.user_role_type_id === "1") {
          if (Number(orders) > 0) {
            navigate("/customer/dashboard");
          } else {
            navigate("/customer/dashboard");
          }
        } else if (user.user_role_type_id === "6") {
          navigate("/translator/projects");
        } else if (user.user_role_type_id === "3") {
          navigate("/manager/dashboard");
        } else {
          navigate("/customer/dashboard");
        }
        // Start GA Call
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "login",
          user_id: user.user_id,
          method: "Email",
        });
        // End GA Call
      })
      .catch((err: any) => {
        const error = err?.data?.messages?.error;
        setAuthError(error);
        setShowError(true);
      })
      .finally(() => {
        setIsDoingLogin(false);
      });
  };

  const onLoginWithID = async (id: number) => {
    setShowError(false);
    setIsDoingLogin(true);
    const payload = {
      user_id: id,
    };
    axiosService
      .post("/autologin", payload)
      .then((res) => {
        const { access_token, orders } = res?.data as any;
        const user = getLoggedInUser(access_token);
        localStorage.setItem("access_token", access_token);
        localStorage.setItem("user_details", JSON.stringify(user));
        localStorage.removeItem("order_status");

        if (user.user_role_type_id === "4") {
          navigate("/admin/dashboard");
        } else if (Number(user?.user_role_type_id) === 7) {
          navigate("/admin/unfinished-users");
        } else if (user.user_role_type_id === "1") {
          if (Number(orders) > 0) {
            navigate("/customer/dashboard");
          } else {
            navigate("/customer/dashboard");
          }
        } else if (user.user_role_type_id === "6") {
          navigate("/translator/projects");
        } else if (user.user_role_type_id === "3") {
          navigate("/manager/dashboard");
        } else {
          navigate("/customer/dashboard");
        }
        // Start GA Call
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "login",
          user_id: user.user_id,
          method: "Email",
        });
        // End GA Call
      })
      .catch((err: any) => {
        const error = err?.data?.messages?.error;
        setAuthError(error);
        setShowError(true);
      })
      .finally(() => {
        setIsDoingLogin(false);
      });
  };

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        setIsFetchingProfile(true);
        const { access_token } = tokenResponse;
        const url = `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${access_token}`;
        const { data } = await axios.get(url);
        if (data) {
          const { email, verified_email } = data;

          if (!verified_email) {
            setAlertMessageRequest({
              type: "error",
              message:
                "Your gmail account does not verified. please verify your gmail account.",
            });
            return;
          } else {
            const payload: any = {
              email: email,
              is_google_auth: true,
            };
            onLogin(payload);
          }
        }
      } catch (err: any) {
        const message = err?.data?.message;
        console.log(message);
      } finally {
        setIsFetchingProfile(false);
      }
    },
  });

  useEffect(() => {
    if (!window?.localStorage.getItem("access_token")) {
      googleLogout();
    } else {
      const user: any = JSON.parse(localStorage.getItem("user_details"));
      if (user.user_role_type_id === "4") {
        navigate("/admin/dashboard");
      } else if (Number(user?.user_role_type_id) === 7) {
        navigate("/admin/unfinished-users");
      } else if (user.user_role_type_id === "1") {
        navigate("/customer/dashboard");
      } else if (user.user_role_type_id === "6") {
        navigate("/translator/projects");
      } else if (user.user_role_type_id === "3") {
        navigate("/manager/dashboard");
      } else {
        navigate("/customer/dashboard");
      }
    }
    return () => {
      console.log("Clear All Subscription");
    };
  }, []);

  return (
    <DefaultAuth>
      <Flex
        maxW="420px"
        w="100%"
        mx="auto"
        my="auto"
        h="fit-content"
        alignItems="start"
        justifyContent="center"
        flexDirection="column"
        borderRadius="18px"
        bgColor={"#FFF"}
        boxShadow={bgShadow}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            await onLogin(values);
          }}
        >
          {({ handleSubmit, errors, touched }) => (
            <form onSubmit={handleSubmit} style={{ width: "100%" }}>
              <Flex
                zIndex="2"
                direction="column"
                padding={{ md: "55px 40px 65px", sm: "40px 30px 16px" }}
                w="full"
              >
                <Heading
                  color={textColor}
                  fontFamily="Canela Text"
                  fontSize="30px"
                  lineHeight="135%"
                  mb={"48px"}
                  textAlign="center"
                >
                  Sign In
                </Heading>
                {showError && (
                  <Alert status="error" mb="20px">
                    <AlertIcon color={"#FFF"} />
                    <Box>
                      <AlertDescription color={"#FFF"} fontSize="14px">
                        {authError}
                      </AlertDescription>
                    </Box>
                    <CloseButton
                      ml="auto"
                      position="relative"
                      color="#FFF"
                      onClick={() => setShowError(false)}
                    />
                  </Alert>
                )}
                <Button
                  py="15px"
                  px="30px"
                  height="60px"
                  fontSize={"14px"}
                  lineHeight="17px"
                  textTransform={"capitalize"}
                  borderRadius="50px"
                  bg={googleBg}
                  color={googleText}
                  fontWeight="500"
                  border={`1px solid #EFEFEF`}
                  onClick={(e) => login()}
                  _hover={googleHover}
                  _active={googleActive}
                  _focus={googleActive}
                  isLoading={isFetchingProfile || isDoingLogin}
                >
                  Sign in with
                  <Icon as={FcGoogle} w="20px" h="20px" me="10px" ml="10px" />
                </Button>
                <Flex align="center" my="9.5px">
                  <HSeparator />
                  <Text
                    fontSize="13px"
                    color="gray.400"
                    p="5px"
                    textTransform={"capitalize"}
                    whiteSpace="nowrap"
                  >
                    or sign in with Email
                  </Text>
                  <HSeparator />
                </Flex>

                <FormControl
                  isInvalid={!!errors.email && touched.email}
                  mb="12px"
                >
                  <Field
                    as={Input}
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email Address"
                    sx={{
                      ...inputStyleNormal,
                      fontWeight: "500",
                    }}
                    _placeholder={{
                      ...inputStyle,
                      fontWeight: "400",
                    }}
                    validate={(value: any) => {
                      let error;
                      if (value.length < 1) {
                        error = "Email Address is required.";
                      }
                      return error;
                    }}
                  />
                  <FormErrorMessage
                    mt={1}
                    sx={{
                      color: "#F00",
                      justifyContent: "center",
                    }}
                  >
                    {errors.email}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  isInvalid={!!errors.password && touched.password}
                  mb="12px"
                >
                  <Field
                    as={Input}
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    placeholder="Password"
                    sx={{
                      ...inputStyleNormal,
                      fontWeight: "500",
                    }}
                    _placeholder={{
                      ...inputStyle,
                      fontWeight: "400",
                    }}
                    validate={(value: any) => {
                      let error;
                      if (value.length < 1) {
                        error = "Password is required.";
                      }
                      return error;
                    }}
                  />
                  <FormErrorMessage
                    mt={1}
                    sx={{
                      color: "#F00",
                      justifyContent: "center",
                    }}
                  >
                    {errors.password}
                  </FormErrorMessage>
                </FormControl>
                <Flex
                  justifyContent="center"
                  align="center"
                  mb="20px"
                  sx={{
                    ".chakra-checkbox__label": {
                      fontSize: "14px",
                    },
                  }}
                >
                  <Checkbox
                    onChange={(e: any) => setShowPassword(e.target.checked)}
                  >
                    Show Password
                  </Checkbox>
                </Flex>
                <Flex justifyContent="center" align="center">
                  <Text
                    color={textColorBrand}
                    fontSize="14px"
                    lineHeight="17px"
                    textTransform="capitalize"
                    w="fit-content"
                    fontWeight="500"
                    cursor="pointer"
                    onClick={(e) => {
                      navigate("/auth/forgot");
                    }}
                  >
                    Forgot your email or password?
                  </Text>
                </Flex>
              </Flex>
              <Flex
                flexDirection={{ md: "row", sm: "column-reverse" }}
                justifyContent="center"
                alignItems="start"
                w={{ md: "full", sm: "calc(100% - 60px)" }}
                columnGap="12px"
                rowGap="16px"
                borderTop="1px solid #E9E9E9"
                margin={{ md: "0px", sm: "0px 30px" }}
                padding={{ md: "30px 40px", sm: "16px 0px" }}
              >
                <Button
                  fontSize="14px"
                  lineHeight="17px"
                  variant="brand"
                  bgColor={"white"}
                  color="blue.500"
                  fontWeight="700"
                  padding="14px 54px"
                  h={{ md: "45px", sm: "55px" }}
                  w="100%"
                  borderRadius="90px"
                  border={{ md: "none", sm: "1px solid" }}
                  borderColor={buttonBg}
                  _hover={{
                    bgColor: buttonBg,
                    color: "#FFF",
                  }}
                  onClick={() => {
                    goToSignUp();
                  }}
                >
                  {windowWidth >= 768 ? "Sign Up" : "No account? Sign Up"}
                </Button>
                <Button
                  type="submit"
                  fontSize="14px"
                  lineHeight="17px"
                  variant="brand"
                  bgColor={buttonBg}
                  fontWeight="700"
                  padding="14px 54px"
                  h={{ md: "45px", sm: "55px" }}
                  w="100%"
                  borderRadius={"90px"}
                  isLoading={isDoingLogin}
                  _loading={{
                    pointerEvents: "none",
                  }}
                >
                  {windowWidth >= 768 ? "Log In" : "Sign In"}
                </Button>
              </Flex>
            </form>
          )}
        </Formik>
      </Flex>
    </DefaultAuth>
  );
}
