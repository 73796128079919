import { combineReducers } from "@reduxjs/toolkit";
import userReducer from "../user/reducer";
import projectsReducer from "../project/reducer";
import customerProjectReducer from "../customerProject/reducer";
import uiReducer from "../ui/reducer";
const reducers = {
  user: userReducer,
  projects: projectsReducer,
  customerProject: customerProjectReducer,
  ui: uiReducer,
};

export let rootReducer = combineReducers({
  ...reducers,
});

export default function createReducer(injectedReducers = {}) {
  rootReducer = combineReducers({
    ...reducers,
    ...injectedReducers,
  });

  return rootReducer;
}

export type RootState = ReturnType<typeof rootReducer>;
