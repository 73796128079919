import {
  Button,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import IconBox from "components/icons/IconBox";
import { IconArrowDown } from "components/icons/Icons";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTeammemberRequest,
  updateTeammemberRoleRequest,
} from "store/user/reducer";
import {
  isRemovingTeammemberSelector,
  userSelector,
} from "store/user/selector";
import { TeamRawObj } from "../../variables/teamMembers";

export default function CollaboratorRowItem(props: { data: TeamRawObj }) {
  const user = useSelector(userSelector);
  const [rowData, setRowData] = useState(props.data);
  const dispatch = useDispatch();
  const isRemoving = useSelector(isRemovingTeammemberSelector);

  const isBusiness = useMemo(() => {
    return user.is_business;
  }, [user]);

  const isAdmin = useMemo(() => {
    return user.is_admin;
  }, [user]);

  const options: string[] = ["admin", "standard"];
  const shadow = useColorModeValue(
    "0px 4px 50px rgba(0, 0, 0, 0.1)",
    "0px 4px 50px rgba(0, 0, 0, 0.1)"
  );

  const [selectedUserId, setSelectedUserId] = useState(null);

  const onRemoveUser = () => {
    const payload = {
      team_user_id: rowData?.id,
    };
    setSelectedUserId(rowData?.id);
    dispatch(deleteTeammemberRequest(payload));
  };

  const onSelectOption = (option: string) => {
    setRowData({
      ...rowData,
      role: option === "admin" ? "admin" : "standard",
    });

    dispatch(
      updateTeammemberRoleRequest({
        team_user_id: rowData?.id,
        role: option,
      })
    );
  };

  return (
    <Flex
      padding="15px 0px"
      alignItems="center"
      borderBottom="1px solid #E3E3E3"
    >
      <Image
        src={rowData.avatar}
        w="47px"
        h="47px"
        mr="25px"
        borderRadius="50%"
      />
      <Text
        fontSize="14px"
        lineHeight="17px"
        color="#000"
        fontWeight="400"
        mr="20px"
      >
        {rowData.name}
      </Text>
      <Menu>
        <MenuButton
          disabled={!(isBusiness || isAdmin)}
          padding={"5px 10px 5px 20px"}
          backgroundColor="#FFF"
          borderRadius="100px"
          minW="fit-content"
          height="34px"
          border="1px solid #E9E9E9"
          ml="auto"
        >
          <Flex alignItems={"center"}>
            <Text
              fontSize="14px"
              lineHeight="24px"
              fontWeight="400"
              color="black"
              textTransform="capitalize"
            >
              {rowData.role}
            </Text>
            <IconBox
              marginLeft="auto"
              icon={<IconArrowDown width="24px" height="24px" />}
            ></IconBox>
          </Flex>
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={"#FFF"}
          border="none"
        >
          <Flex
            flexDirection="column"
            p="10px 20px"
            fontSize={"14px"}
            lineHeight="24px"
            fontWeight={"400"}
            color="black"
          >
            {options.map((option, index: number) => (
              <MenuItem
                key={index}
                _hover={{ bg: "none" }}
                _focus={{ bg: "none" }}
                borderRadius="8px"
                padding="15px 0px"
                textTransform={"capitalize"}
                onClick={() => onSelectOption(option)}
                borderBottom={
                  index < options.length - 1 ? "1px solid #F3F5F5" : ""
                }
              >
                <Text fontSize="sm">{option}</Text>
              </MenuItem>
            ))}
          </Flex>
        </MenuList>
      </Menu>
      {(isBusiness || isAdmin) && (
        <Button
          padding={"5px 20px"}
          backgroundColor="#F3F5F5"
          borderRadius="100px"
          minW="fit-content"
          height="34px"
          color="#000"
          fontSize="12px"
          lineHeight="24px"
          fontWeight="400"
          ml="22px"
          isLoading={isRemoving && selectedUserId === rowData.id}
          onClick={() => onRemoveUser()}
        >
          Remove
        </Button>
      )}
    </Flex>
  );
}
