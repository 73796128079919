export const supportLanguages = [
  "Albanian",
  "Amharic",
  "Arabic",
  "Bengali",
  "Bosnian",
  "Bulgarian",
  "Burmese",
  "Catalan",
  "Chinese (Cantonese)",
  "Chinese (Simplified)",
  "Chinese (Traditional)",
  "Creole",
  "Croatian",
  "Czech",
  "Danish",
  "Dutch",
  "Romanian",
  "Russian",
  "Serbian",
  "Slovak",
  "Slovenian",
  "English",
  "Farsi",
  "Finnish",
  "French",
  "French (Canadian)",
  "Georgian",
  "German",
  "Greek",
  "Gujrati",
  "Hebrew",
  "Hindi",
  "Hungarian",
  "Indonesian",
  "Indian English",
  "Spanish",
  "Swedish",
  "Swahili",
  "Tagalog",
  "Tajik",
  "Italian",
  "Japanese",
  "Korean",
  "Kannada",
  "Latin",
  "Latvian",
  "Lithuanian",
  "Macedonian",
  "Malay",
  "Marathi",
  "Nepali",
  "Norwegian",
  "Pashto",
  "Persian",
  "Polish",
  "Portuguese (Brazil)",
  "Portuguese (Portugal)",
  "Punjabi",
  "Tamil",
  "Telugu",
  "Thai",
  "Turkish",
  "Ukrainian",
  "Urdu",
  "Uzbek",
  "Vietnamese",
  "Kurdish",
  "Kurdish (Sorani)",
  "Kyrgyz",
  "Mongolian",
  "Azerbaijani",
  "Armenian",
  "Haitian Creole",
  "Malayalam",
  "Sinhala (Sinhalese)",
  "Lao",
  "Khmer",
  "Karen",
  "Estonian",
  "Hmong",
  "Kinyarwanda",
  "Oromo",
  "Shona",
  "Somali",
  "Serbian",
  "Maltese",
];

export const prices = [
  {
    price: 24.5,
    languages: [
      "Albanian",
      "Arabic",
      "Bengali",
      "Bosnian",
      "Bulgarian",
      "Chinese (Cantonese)",
      "Chinese (Simplified)",
      "Chinese (Traditional)",
      "Creole",
      "Croatian",
      "Dutch",
      "Romanian",
      "Russian",
      "Serbian",
      "English",
      "Farsi",
      "French",
      "French (Canadian)",
      "Georgian",
      "German",
      "Greek",
      "Hindi",
      "Hungarian",
      "Indonesian",
      "Indian English",
      "Spanish",
      "Swahili",
      "Tagalog",
      "Tajik",
      "Italian",
      "Korean",
      "Kannada",
      "Latin",
      "Lithuanian",
      "Macedonian",
      "Malay",
      "Nepali",
      "Pashto",
      "Persian",
      "Polish",
      "Portuguese (Brazil)",
      "Portuguese (Portugal)",
      "Punjabi",
      "Tamil",
      "Telugu",
      "Turkish",
      "Ukrainian",
      "Urdu",
      "Uzbek",
      "Vietnamese",
      "Kurdish",
      "Kurdish (Sorani)",
      "Malayalam",
      "Sinhala (Sinhalese)",
    ],
  },
  {
    price: 30,
    languages: [
      "Amharic",
      "Burmese",
      "Catalan",
      "Czech",
      "Danish",
      "Slovak",
      "Slovenian",
      "Somali",
      "Finnish",
      "Gujrati",
      "Hebrew",
      "Swedish",
      "Japanese",
      "Latvian",
      "Marathi",
      "Norwegian",
      "Thai",
      "Kyrgyz",
      "Mongolian",
      "Azerbaijani",
      "Armenian",
      "Haitian Creole",
      "Estonian",
      "Kinyarwanda",
      "Oromo",
      "Serbian",
      "Maltese",
    ],
  },
  {
    price: 35,
    languages: ["Lao", "Khmer", "Karen", "Hmong", "Shona"],
  },
];
export const globalFeedbackList = [
  {
    title: "Expert Translators",
    content: "Top-quality translations from experienced professionals.",
  },
  {
    title: "Fast Turnaround",
    content: "Regular and premium delivery options to meet your deadlines.",
  },
  {
    title: "Competitive Pricing",
    content: "Affordable, upfront pricing with no hidden fees.",
  },
  {
    title: "Confidentiality",
    content: "Your privacy and document security are our priorities.",
  },
  {
    title: "Wide Language Selection",
    content: "Multiple language pairs are available.",
  },
  {
    title: "Satisfaction Guarantee",
    content: "We're committed to making it right if you're unsatisfied",
  },
];
