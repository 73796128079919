/* eslint-disable react-hooks/exhaustive-deps */
// Chakra Imports
import {
  Avatar,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Input,
  FormControl,
  FormErrorMessage,
  Button,
  useMediaQuery,
  Image,
  ModalFooter,
} from "@chakra-ui/react";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { SidebarResponsive } from "components/sidebar/Sidebar";
import PropTypes from "prop-types";
import routes from "routes";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isRegisteringSelector, userSelector } from "store/user/selector";
import React, { useMemo, useState } from "react";
import { logoutRequest, signUpRequest } from "store/user/reducer";
import { IconAdd, IconPencil } from "components/icons/Icons";
import IconBox from "components/icons/IconBox";
import { TabWidgetNewUser } from "components/tab/TabWidgetNewUser";
import { Field, Formik } from "formik";
import MobileLogo from "assets/img/layout/logo-mobile.png";
import { InfoIcon } from "@chakra-ui/icons";
import { TabWidget } from "components/tab/TabWidget";
import { isEmpty } from "lodash";
import { setAlertMessageRequest } from "store/ui/reducer";
import { isValidEmail } from "network/helper";
import { axiosService } from "network/axios";

const inputStyle = {
  fontSize: "14px",
  lineHeight: "17px",
  textAlign: "center",
  color: "black",
  border: "none !important",
  boxShadow: "none !important",
  bgColor: "transparent",
};

const inputStyleNormal = {
  fontSize: "14px",
  lineHeight: "17px",
  textAlign: "center",
  color: "black",
  border: "1px solid #E9E9E9",
  borderRadius: "50px",
  padding: "15px 30px",
  height: "60px",
  bgColor: "#FFF",
  boxShadow: "none !important",
};

type SignUpFormInputs = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
};

export default function HeaderLinks(props: {
  brandText: string;
  secondary: boolean;
  openProfileDetails: Function | null;
}) {
  const initialValues: SignUpFormInputs = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  };
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const { secondary, openProfileDetails, brandText } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [invitationEmail, setInvitationEmail] = useState(null);
  const [isSendingInvitation, setIsSendingInvitation] = useState(false);
  const user = useSelector(userSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [type, setType] = useState(0);
  const buttonBg = "blue.500";
  const [isLargerThan991] = useMediaQuery("(min-width: 992px)");
  const userName = useMemo(() => {
    if (user) {
      return `${user.first_name} ${user.last_name}`;
    } else {
      return "";
    }
  }, [user]);

  const isAdmin = useMemo(() => {
    if (user) {
      return user.user_role_type_id === "4";
    } else {
      return false;
    }
  }, [user]);

  const userAvatar = useMemo(() => {
    if (user) {
      return user.profile_pic_src
        ? user.profile_pic_src?.includes("http")
          ? user.profile_pic_src
          : `${baseUrl}/public_html/images/${user.profile_pic_src}`
        : null;
    } else {
      return null;
    }
  }, [user]);

  const isNotAvailable = useMemo(() => {
    if (user) {
      return Number(user?.user_status_id) === 5;
    }
    return false;
  }, [user]);

  const isRegistering = useSelector(isRegisteringSelector);
  const [role, setRole] = useState(0);
  const [customerType, setCustomerType] = useState("individual");
  let menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  const routerLocation = useLocation();
  const onLogOut = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("user_details");
    dispatch(logoutRequest());
    navigate("/auth/sign-in/");
  };
  const onRegister = (values: any) => {
    dispatch(
      signUpRequest({
        ...values,
        role,
      })
    );
  };

  const onChangeRole = (role: number) => {
    setRole(role);
  };

  const onChangeType = (roleType: string) => {
    setCustomerType(roleType);
  };

  const onOpenAddNewUser = (type: number) => {
    setType(type);
    onOpen();
  };

  const onClickSendInvitation = () => {
    if (isEmpty(invitationEmail)) {
      dispatch(
        setAlertMessageRequest({
          type: "warning",
          message:
            "Please enter the company email address to send the invitation.",
        })
      );
    } else {
      if (!isValidEmail(invitationEmail)) {
        dispatch(
          setAlertMessageRequest({
            type: "warning",
            message: "Please enter the valid email address.",
          })
        );
        return;
      }
      setIsSendingInvitation(true);
      axiosService
        .post(`/user/team/send-invite`, {
          email: invitationEmail,
        })
        .then((res: any) => {
          if (res?.data?.result === "success") {
            dispatch(
              setAlertMessageRequest({
                type: "success",
                message: "Sent the invitation to the company.",
              })
            );
            setOpenInviteModal(false);
          }
        })
        .catch((err: any) => {
          const error =
            err.data.messages.error ||
            "Error in proceeding the payment. Please contact to support team.";
          dispatch(
            setAlertMessageRequest({
              type: "error",
              message: error,
            })
          );
        })
        .finally(() => {
          setIsSendingInvitation(false);
        });
      // Api call to send the invitation to company
    }
  };

  React.useEffect(() => {
    const hash = window.location.hash ?? null;
    if (hash && window.location.href?.includes("admin/customers")) {
      onOpenAddNewUser(1);
    } else if (hash && window.location.href?.includes("admin/team")) {
      onOpenAddNewUser(0);
    }
  }, [routerLocation]);

  return (
    <Flex
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
      flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
      p={{ md: "10px", sm: "10px 0px" }}
      borderRadius="30px"
      ml="auto"
      columnGap={{ md: "0px", base: "20px" }}
    >
      <Image
        src={MobileLogo}
        w="34px"
        h="34px"
        objectFit={"contain"}
        display={{ md: "none", base: "flex" }}
      />
      {Number(user?.user_role_type_id) === 1 && (
        <Flex
          padding="10px 20px"
          alignItems="center"
          border="1px solid #E9E9E9"
          borderRadius="50px"
          bgColor="#fff"
          width="fit-content"
          cursor="pointer"
          mx="auto"
          display={{ md: "none", base: "flex" }}
          onClick={() => {
            navigate("/customer/main");
          }}
        >
          <IconBox
            w="24px"
            h="24px"
            marginRight="10px"
            icon={<IconPencil w="24px" h="24px" />}
          ></IconBox>
          <Text
            fontSize="14px"
            lineHeight="17px"
            color="#000"
            textTransform="capitalize"
          >
            Start an order
          </Text>
        </Flex>
      )}
      {Number(user?.user_role_type_id) !== 1 && (
        <Text
          fontSize="24px"
          fontFamily="Canela Text"
          lineHeight="100%"
          color="#000"
          fontWeight="700"
          mx="auto"
          textTransform="capitalize"
          display={{ md: "none", base: "flex" }}
          alignItems="center"
        >
          {brandText}
        </Text>
      )}
      <Box
        display={{
          md: "none",
          sm: "flex",
        }}
        ml="0"
      >
        <SidebarResponsive
          routes={routes}
          openProfileDetails={openProfileDetails}
        />
      </Box>
      <Flex
        mr={{ md: "70px", sm: "auto" }}
        alignItems="center"
        display={{ md: "flex", base: "none" }}
      >
        {routerLocation.pathname.includes("/admin/team") &&
          isLargerThan991 &&
          isAdmin && (
            <Flex
              columnGap="10px"
              alignItems="center"
              cursor="pointer"
              w="fit-content"
              mr="50px"
              onClick={() => {
                onOpenAddNewUser(0);
              }}
            >
              <IconBox w="28px" h="28px" icon={<IconAdd w="28px" h="28px" />} />
              <Text
                fontSize="14px"
                lineHeight="135%"
                color="#000"
                fontWeight="400"
                textDecoration="underline"
              >
                Add a new team member
              </Text>
            </Flex>
          )}
        {routerLocation.pathname.includes("/admin/customers") &&
          isLargerThan991 &&
          isAdmin && (
            <Flex
              columnGap="10px"
              alignItems="center"
              cursor="pointer"
              w="fit-content"
              mr="50px"
              onClick={() => {
                onOpenAddNewUser(1);
              }}
            >
              <IconBox w="28px" h="28px" icon={<IconAdd w="28px" h="28px" />} />
              <Text
                fontSize="14px"
                lineHeight="135%"
                color="#000"
                fontWeight="400"
                textDecoration="underline"
              >
                Add a new customer
              </Text>
            </Flex>
          )}
        {!routerLocation.pathname.includes("/customer/main") &&
          !routerLocation.pathname.includes("/customer/order") && (
            <SearchBar
              background="#F5F5F5"
              borderRadius="10px"
              mb={() => {
                if (secondary) {
                  return { base: "10px", md: "unset" };
                }
                return "unset";
              }}
            />
          )}
      </Flex>
      <Box display={{ md: "block", base: "none" }}>
        <Menu>
          <MenuButton p="0px" bgColor={"transparent"}>
            <Flex alignItems={"center"}>
              <Text
                fontSize={"14px"}
                lineHeight="24px"
                fontWeight={"400"}
                color="black"
                mr="18px"
                display={{ sm: "none", md: "block" }}
              >
                {userName}
              </Text>
              <Avatar
                src={userAvatar}
                _hover={{ cursor: "pointer" }}
                color="white"
                name={userName}
                bg="#11047A"
                size="sm"
                w="50px"
                h="50px"
              />

              {isNotAvailable && (
                <InfoIcon
                  color="#F02828"
                  sx={{
                    position: "absolute",
                    right: "10px",
                    top: "20px",
                    bgColor: "#FFF",
                    borderRadius: "50%",
                  }}
                />
              )}
            </Flex>
          </MenuButton>
          <MenuList
            boxShadow={shadow}
            p="0px"
            mt="10px"
            borderRadius="20px"
            bg={menuBg}
            border="none"
          >
            <Flex w="100%" mb="0px">
              <Text
                ps="20px"
                pt="16px"
                pb="10px"
                w="100%"
                borderBottom="1px solid"
                borderColor={borderColor}
                fontSize="sm"
                fontWeight="700"
                color={textColor}
              >
                👋&nbsp; Hey, {userName}
              </Text>
            </Flex>
            <Flex flexDirection="column" p="10px">
              <MenuItem
                _hover={{ bg: "none" }}
                _focus={{ bg: "none" }}
                borderRadius="8px"
                px="14px"
                onClick={(e) => openProfileDetails()}
              >
                <Text fontSize="sm">Profile Settings</Text>
                {isNotAvailable && <InfoIcon color="#F02828" ml="10px" />}
              </MenuItem>
              {isAdmin &&
                !isLargerThan991 &&
                (routerLocation.pathname.includes("/admin/team") ||
                  routerLocation.pathname.includes("/admin/customers")) && (
                  <MenuItem
                    _hover={{ bg: "none" }}
                    _focus={{ bg: "none" }}
                    borderRadius="8px"
                    px="14px"
                    onClick={() => {
                      routerLocation.pathname.includes("/admin/team") ||
                      routerLocation.pathname.includes("/admin/customers")
                        ? onOpenAddNewUser(0)
                        : onOpenAddNewUser(1);
                    }}
                    // onClick={onOpen}
                  >
                    <Text fontSize="sm">Add New User</Text>
                  </MenuItem>
                )}
              <MenuItem
                _hover={{ bg: "none" }}
                _focus={{ bg: "none" }}
                color="red.400"
                borderRadius="8px"
                px="14px"
                onClick={onLogOut}
              >
                <Text fontSize="sm">Log out</Text>
              </MenuItem>
            </Flex>
          </MenuList>
        </Menu>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} size={"lg"}>
        <ModalOverlay />
        {type === 0 && (
          <ModalContent>
            <ModalHeader
              fontFamily="Canela Text"
              fontSize={{ md: "30px", base: "24px" }}
              lineHeight="135%"
              fontWeight="700"
              color="#000"
            >
              <Flex alignItems="center" mt="6" justifyContent="center">
                <Box>Add a new member</Box>
              </Flex>
            </ModalHeader>
            <ModalCloseButton onClick={onClose} />
            <ModalBody pb="35px">
              <TabWidgetNewUser
                onChangeRole={onChangeRole}
                sx={{
                  mb: "14px",
                }}
              />
              <Formik
                initialValues={initialValues}
                onSubmit={(values) => {
                  onRegister({
                    ...values,
                    password: "123456",
                    source: "admin",
                  });
                }}
              >
                {({ handleSubmit, errors, touched }) => (
                  <form onSubmit={handleSubmit}>
                    <Flex
                      zIndex="2"
                      direction="column"
                      padding={"30px 20px 25px"}
                      w="full"
                    >
                      <FormControl
                        isInvalid={!!errors.first_name && touched.first_name}
                        mb="30px"
                      >
                        <Field
                          as={Input}
                          type="text"
                          id="first_name"
                          name="first_name"
                          placeholder="First Name"
                          padding="5px 20px"
                          h="full"
                          border="none"
                          sx={{
                            ...inputStyleNormal,
                            fontWeight: "500",
                          }}
                          _placeholder={{
                            ...inputStyle,
                            fontWeight: "400",
                          }}
                          validate={(value: any) => {
                            let error;
                            if (value.length < 1) {
                              error = "First Name is required.";
                            }
                            return error;
                          }}
                        />

                        <FormErrorMessage
                          mt={1}
                          sx={{
                            color: "#F00",
                            justifyContent: "center",
                          }}
                        >
                          {errors.first_name}
                        </FormErrorMessage>
                      </FormControl>

                      <FormControl
                        isInvalid={!!errors.last_name && touched.last_name}
                        mb="30px"
                      >
                        <Field
                          as={Input}
                          type="text"
                          id="last_name"
                          name="last_name"
                          placeholder="Last Name"
                          padding="5px 20px"
                          h="full"
                          border="none"
                          sx={{
                            ...inputStyleNormal,
                            fontWeight: "500",
                          }}
                          _placeholder={{
                            ...inputStyle,
                            fontWeight: "400",
                          }}
                          validate={(value: any) => {
                            let error;
                            if (value.length < 1) {
                              error = "Last Name is required.";
                            }
                            return error;
                          }}
                        />

                        <FormErrorMessage
                          mt={1}
                          sx={{
                            color: "#F00",
                            justifyContent: "center",
                          }}
                        >
                          {errors.last_name}
                        </FormErrorMessage>
                      </FormControl>

                      <FormControl isInvalid={!!errors.email && touched.email}>
                        <Field
                          as={Input}
                          type="email"
                          id="email"
                          name="email"
                          placeholder="Email"
                          sx={{
                            ...inputStyleNormal,
                            fontWeight: "500",
                          }}
                          _placeholder={{
                            ...inputStyle,
                            fontWeight: "400",
                          }}
                          validate={(value: any) => {
                            let error;
                            if (value.length < 1) {
                              error = "Email Address is required.";
                            }
                            return error;
                          }}
                        />
                        <FormErrorMessage
                          mt={1}
                          sx={{
                            color: "#F00",
                            justifyContent: "center",
                          }}
                        >
                          {errors.email}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                    <Flex
                      flexDirection="row"
                      justifyContent="center"
                      alignItems="center"
                      w="full"
                      columnGap={"12px"}
                      padding={"30px 30px 0px"}
                      borderTop="1px solid #E9E8E8"
                    >
                      <Button
                        fontSize="14px"
                        lineHeight="17px"
                        variant="brand"
                        bgColor="white"
                        color="blue.500"
                        fontWeight="700"
                        padding="14px 20px"
                        h="45px"
                        w="100%"
                        borderRadius="90px"
                        _hover={{
                          bgColor: buttonBg,
                          color: "#FFF",
                        }}
                        onClick={onClose}
                      >
                        Back
                      </Button>
                      <Button
                        type="submit"
                        fontSize="14px"
                        lineHeight="17px"
                        variant="brand"
                        bgColor={buttonBg}
                        fontWeight="700"
                        padding="14px 20px"
                        h="45px"
                        w="100%"
                        borderRadius={"90px"}
                        isLoading={isRegistering}
                        _loading={{
                          pointerEvents: "none",
                        }}
                      >
                        Create Account
                      </Button>
                    </Flex>
                  </form>
                )}
              </Formik>
            </ModalBody>
          </ModalContent>
        )}

        {type === 1 && (
          <ModalContent>
            <ModalHeader
              fontFamily="Canela Text"
              fontSize={{ md: "30px", base: "24px" }}
              lineHeight="135%"
              fontWeight="700"
              color="#000"
            >
              <Flex alignItems="center" mt="6" justifyContent="center">
                <Box>Add a new customer</Box>
              </Flex>
            </ModalHeader>
            <ModalCloseButton
              onClick={() => {
                if (window.location.hash) {
                  window.history.replaceState(
                    null,
                    null,
                    window.location.href.split("#")[0]
                  );
                }
                onClose();
              }}
            />
            <ModalBody pb="35px">
              <TabWidget
                changeAccounType={(e: number) => {
                  onChangeType(e === 0 ? "individual" : "business");
                }}
                sx={{
                  mb: "14px",
                }}
              />
              <Formik
                initialValues={initialValues}
                onSubmit={(values) => {
                  onRegister({
                    ...values,
                    source: "admin1",
                    password: "123456",
                    type: customerType,
                  });
                }}
              >
                {({ handleSubmit, errors, touched }) => (
                  <form onSubmit={handleSubmit}>
                    <Flex
                      zIndex="2"
                      direction="column"
                      padding={"30px 20px 25px"}
                      w="full"
                    >
                      <FormControl
                        isInvalid={!!errors.first_name && touched.first_name}
                        mb="30px"
                      >
                        <Field
                          as={Input}
                          type="text"
                          id="first_name"
                          name="first_name"
                          placeholder="First Name"
                          padding="5px 20px"
                          h="full"
                          border="none"
                          sx={{
                            ...inputStyleNormal,
                            fontWeight: "500",
                          }}
                          _placeholder={{
                            ...inputStyle,
                            fontWeight: "400",
                          }}
                          validate={(value: any) => {
                            let error;
                            if (value.length < 1) {
                              error = "First Name is required.";
                            }
                            return error;
                          }}
                        />

                        <FormErrorMessage
                          mt={1}
                          sx={{
                            color: "#F00",
                            justifyContent: "center",
                          }}
                        >
                          {errors.first_name}
                        </FormErrorMessage>
                      </FormControl>

                      <FormControl
                        isInvalid={!!errors.last_name && touched.last_name}
                        mb="30px"
                      >
                        <Field
                          as={Input}
                          type="text"
                          id="last_name"
                          name="last_name"
                          placeholder="Last Name"
                          padding="5px 20px"
                          h="full"
                          border="none"
                          sx={{
                            ...inputStyleNormal,
                            fontWeight: "500",
                          }}
                          _placeholder={{
                            ...inputStyle,
                            fontWeight: "400",
                          }}
                          validate={(value: any) => {
                            let error;
                            if (value.length < 1) {
                              error = "Last Name is required.";
                            }
                            return error;
                          }}
                        />

                        <FormErrorMessage
                          mt={1}
                          sx={{
                            color: "#F00",
                            justifyContent: "center",
                          }}
                        >
                          {errors.last_name}
                        </FormErrorMessage>
                      </FormControl>

                      <FormControl isInvalid={!!errors.email && touched.email}>
                        <Field
                          as={Input}
                          type="email"
                          id="email"
                          name="email"
                          placeholder="Email"
                          sx={{
                            ...inputStyleNormal,
                            fontWeight: "500",
                          }}
                          _placeholder={{
                            ...inputStyle,
                            fontWeight: "400",
                          }}
                          validate={(value: any) => {
                            let error;
                            if (value.length < 1) {
                              error = "Email Address is required.";
                            }
                            return error;
                          }}
                        />
                        <FormErrorMessage
                          mt={1}
                          sx={{
                            color: "#F00",
                            justifyContent: "center",
                          }}
                        >
                          {errors.email}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                    <Flex
                      flexDirection="row"
                      justifyContent="center"
                      alignItems="center"
                      w="full"
                      columnGap={"12px"}
                      padding={"30px 20px 0px"}
                      borderTop="1px solid #E9E8E8"
                    >
                      <Button
                        type="button"
                        fontSize="14px"
                        lineHeight="17px"
                        variant="brand"
                        bgColor="transparent"
                        border="1px solid"
                        borderColor={buttonBg}
                        color={buttonBg}
                        fontWeight="700"
                        padding="14px 20px"
                        h="45px"
                        w="100%"
                        borderRadius={"90px"}
                        isLoading={isRegistering}
                        _focusWithin={{
                          color: "#FFF",
                          bgColor: buttonBg,
                        }}
                        _hover={{
                          color: "#FFF",
                          bgColor: buttonBg,
                        }}
                        _loading={{
                          pointerEvents: "none",
                        }}
                        onClick={() => {
                          onClose();
                          setOpenInviteModal(true);
                        }}
                      >
                        Send Invitation to Company
                      </Button>
                      {/* <Button
                        fontSize="14px"
                        lineHeight="17px"
                        variant="brand"
                        bgColor="white"
                        color="blue.500"
                        fontWeight="700"
                        padding="14px 20px"
                        h="45px"
                        w="100%"
                        borderRadius="90px"
                        _hover={{
                          bgColor: buttonBg,
                          color: "#FFF",
                        }}
                        onClick={onClose}
                      >
                        Back
                      </Button> */}
                      <Button
                        type="submit"
                        fontSize="14px"
                        lineHeight="17px"
                        variant="brand"
                        bgColor={buttonBg}
                        fontWeight="700"
                        padding="14px 20px"
                        h="45px"
                        w="80%"
                        borderRadius={"90px"}
                        isLoading={isRegistering}
                        _loading={{
                          pointerEvents: "none",
                        }}
                      >
                        Create Account
                      </Button>
                    </Flex>
                    <Box px="30px" mt="20px"></Box>
                  </form>
                )}
              </Formik>
            </ModalBody>
          </ModalContent>
        )}
      </Modal>
      <Modal
        isOpen={openInviteModal}
        onClose={() => {
          setOpenInviteModal(false);
        }}
        size={"md"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text textAlign="center">
              Send the invitation to create the account to company.
            </Text>
          </ModalHeader>
          <ModalBody>
            <Input
              type="email"
              placeholder="Enter a company email address."
              sx={{ height: "50px" }}
              onChange={(e) => {
                setInvitationEmail(e.target.value);
              }}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              fontSize="14px"
              lineHeight="17px"
              variant="brand"
              bgColor="transparent"
              border="1px solid"
              borderColor={buttonBg}
              color={buttonBg}
              fontWeight="700"
              mr="10px"
              padding="14px 20px"
              h="45px"
              w="50%"
              borderRadius={"0px"}
              isLoading={isSendingInvitation}
              _focusWithin={{
                color: "#FFF",
                bgColor: buttonBg,
              }}
              _hover={{
                color: "#FFF",
                bgColor: buttonBg,
              }}
              _loading={{
                pointerEvents: "none",
              }}
              onClick={() => {
                onClickSendInvitation();
              }}
            >
              Send Invitation
            </Button>
            <Button
              type="button"
              fontSize="14px"
              lineHeight="17px"
              variant="brand"
              bgColor="transparent"
              border="1px solid"
              borderColor={buttonBg}
              color={buttonBg}
              fontWeight="700"
              padding="14px 20px"
              h="45px"
              w="50%"
              ml="10px"
              isDisabled={isSendingInvitation}
              borderRadius={"0px"}
              _focusWithin={{
                color: "#FFF",
                bgColor: buttonBg,
              }}
              _hover={{
                color: "#FFF",
                bgColor: buttonBg,
              }}
              _loading={{
                pointerEvents: "none",
              }}
              onClick={() => {
                setOpenInviteModal(false);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
