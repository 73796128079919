import { Formik, Field } from "formik";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  CloseButton,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { HSeparator, VSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useWindowWidth } from "@react-hook/window-size";
import { axiosService } from "network/axios";
import { setAlertMessageRequest } from "store/ui/reducer";
import { logoutRequest } from "store/user/reducer";

type SignUpFormInputs = {
  first_name: string;
  last_name: string;
  password: string;
  company_name: string;
  address: string;
  state: string;
  zip_code: string;
};

const inputStyle = {
  fontSize: "14px",
  lineHeight: "17px",
  textAlign: "center",
  color: "black",
  border: "none !important",
  boxShadow: "none !important",
  bgColor: "transparent",
};

const inputStyleNormal = {
  fontSize: "14px",
  lineHeight: "17px",
  textAlign: "center",
  color: "black",
  border: "1px solid #E9E9E9",
  borderRadius: "50px",
  padding: "15px 30px",
  height: "60px",
  bgColor: "#FFF",
  boxShadow: "none !important",
};

export default function BusinessProfile() {
  const initialValues: SignUpFormInputs = {
    first_name: "",
    last_name: "",
    password: "",
    company_name: "",
    // ein_number: "",
    address: "",
    state: "",
    zip_code: "",
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const windowWidth = useWindowWidth();
  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get("id");
  const token = queryParameters.get("token");

  const [isRegistering, setIsRegistering] = useState(false);
  // const [isSucceed, setIsSucceed] = useState(false);
  const textColor = useColorModeValue("navy.700", "white");
  const borderColor = "gray.100";
  const bgShadow = useColorModeValue(
    "0px 3.60347px 45.0434px rgba(0, 0, 0, 0.1)",
    "unset"
  );

  const buttonBg = "blue.500";
  useEffect(() => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("user_details");
    dispatch(logoutRequest());
  }, []);

  const goToSignIn = () => {
    navigate("/auth/sign-in");
  };

  const onRegister = async (values: any) => {
    const payload = {
      ...values,
      user_id: id,
      token: token,
    };
    setIsRegistering(true);

    try {
      await axiosService.post(`user/team/complete-profile`, {
        ...payload,
      });
      dispatch(
        setAlertMessageRequest({
          type: "success",
          message: "Created the profile successfully. please login to portal",
        })
      );
      navigate("/auth/sign-in");
    } catch (err: any) {
      const error =
        err.data.messages.error ||
        "Error in proceeding the payment. Please contact to support team.";
      dispatch(
        setAlertMessageRequest({
          type: "error",
          message: error,
        })
      );
    } finally {
      setIsRegistering(false);
    }
  };

  return (
    <DefaultAuth>
      <Flex
        maxW="420px"
        w="100%"
        mx="auto"
        my="auto"
        h="fit-content"
        alignItems="start"
        justifyContent="center"
        flexDirection="column"
        borderRadius="18px"
        bgColor={"#FFF"}
        boxShadow={bgShadow}
        sx={{
          form: {
            width: "100%",
          },
        }}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            onRegister(values);
          }}
        >
          {({ handleSubmit, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <Flex
                zIndex="2"
                direction="column"
                padding={{ md: "55px 40px 65px", sm: "40px 30px 16px" }}
                w="full"
                rowGap={{ md: "0px", sm: "16px" }}
              >
                {/* {isSucceed && (
                  <Alert status="success" mb="20px" bgColor={"#0A0"}>
                    <AlertIcon color={"#FFF"} />
                    <Box>
                      <AlertTitle
                        color="#FFF"
                        fontSize="15px"
                        lineHeight="100%"
                      >
                        A verification link has been sent to your email account.
                      </AlertTitle>
                      <AlertDescription
                        display="block"
                        mt={2}
                        color={"#FFF"}
                        fontSize="14px"
                        lineHeight="120%"
                      >
                        Please click on the link that has just been sent to your
                        email account to verify your email.
                      </AlertDescription>
                    </Box>
                    <CloseButton
                      ml="auto"
                      position="relative"
                      color="#FFF"
                      onClick={() => setIsSucceed(false)}
                    />
                  </Alert>
                )} */}
                {windowWidth >= 768 && (
                  <>
                    <Heading
                      color={textColor}
                      fontFamily="Canela Text"
                      fontSize="30px"
                      lineHeight="135%"
                      mb="15px"
                      textAlign="center"
                    >
                      Create a Profile
                    </Heading>

                    <Flex align="center" my="9.5px">
                      <HSeparator />
                      <Text
                        fontSize="13px"
                        color="gray.400"
                        p="5px"
                        whiteSpace="nowrap"
                        textTransform="capitalize"
                      >
                        Personal Profile Information
                      </Text>
                      <HSeparator />
                    </Flex>
                    <FormControl
                      isInvalid={
                        (!!errors.first_name && touched.first_name) ||
                        (!!errors.last_name && touched.last_name)
                      }
                      // mb="56px"
                    >
                      <Flex
                        padding="15px 30px"
                        height="60px"
                        w="full"
                        borderRadius="50px"
                        border="1px solid #E9E9E9"
                        bgColor={"#FFF"}
                      >
                        <Field
                          as={Input}
                          type="text"
                          id="first_name"
                          name="first_name"
                          placeholder="First Name"
                          padding="5px 20px"
                          h="full"
                          border="none"
                          sx={{
                            ...inputStyle,
                            fontWeight: "500",
                          }}
                          _placeholder={{
                            ...inputStyle,
                            fontWeight: "400",
                          }}
                          validate={(value: any) => {
                            let error;
                            if (value.length < 1) {
                              error = "First Name is required.";
                            }
                            return error;
                          }}
                        />
                        <VSeparator />
                        <Field
                          as={Input}
                          type="text"
                          id="last_name"
                          name="last_name"
                          placeholder="Last Name"
                          padding="5px 20px"
                          h="full"
                          border="none"
                          sx={{
                            ...inputStyle,
                            fontWeight: "500",
                          }}
                          _placeholder={{
                            ...inputStyle,
                            fontWeight: "400",
                          }}
                          validate={(value: any) => {
                            let error;
                            if (value.length < 1) {
                              error = "Last Name is required.";
                            }
                            return error;
                          }}
                        />
                      </Flex>
                      <FormErrorMessage
                        mt={1}
                        sx={{
                          color: "#F00",
                          justifyContent: "center",
                        }}
                      >
                        {errors.first_name}
                      </FormErrorMessage>
                      <FormErrorMessage
                        mt={1}
                        sx={{
                          color: "#F00",
                          justifyContent: "center",
                        }}
                      >
                        {errors.last_name}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      isInvalid={!!errors.password && touched.password}
                      mt="14px"
                    >
                      <Field
                        as={Input}
                        type="password"
                        id="password"
                        name="password"
                        placeholder="Password"
                        sx={{
                          ...inputStyleNormal,
                          fontWeight: "500",
                        }}
                        _placeholder={{
                          ...inputStyle,
                          fontWeight: "400",
                        }}
                        validate={(value: any) => {
                          let error;
                          if (value.length < 1) {
                            error = "Password is required.";
                          } else if (value.length < 6) {
                            error =
                              "Password must contain at least 6 characters.";
                          }
                          return error;
                        }}
                      />
                      <FormErrorMessage
                        mt={1}
                        sx={{
                          color: "#F00",
                          justifyContent: "center",
                        }}
                      >
                        {errors.password}
                      </FormErrorMessage>
                    </FormControl>
                    <Flex align="center" my="9.5px" mt="20px">
                      <HSeparator />
                      <Text
                        fontSize="13px"
                        color="gray.400"
                        p="5px"
                        whiteSpace="nowrap"
                        textTransform="capitalize"
                      >
                        Company Profile Information
                      </Text>
                      <HSeparator />
                    </Flex>
                    <Flex wrap="wrap" columnGap="14px">
                      <FormControl
                        isInvalid={
                          (!!errors.company_name && touched.company_name) ||
                          (!!errors.company_name && touched.company_name)
                        }
                      >
                        <Box flexBasis="100%">
                          <Field
                            as={Input}
                            type="text"
                            id="company_name"
                            name="company_name"
                            placeholder="Company Name"
                            padding="5px 20px"
                            h="full"
                            border="none"
                            sx={{
                              ...inputStyleNormal,
                              fontWeight: "500",
                            }}
                            _placeholder={{
                              ...inputStyle,
                              fontWeight: "400",
                            }}
                            validate={(value: any) => {
                              let error;
                              if (value.length < 1) {
                                error = "Company Name is required.";
                              }
                              return error;
                            }}
                          />
                        </Box>
                        <FormErrorMessage
                          textAlign="center"
                          mt={1}
                          sx={{
                            color: "#F00",
                            justifyContent: "center",
                          }}
                        >
                          {errors.company_name}
                        </FormErrorMessage>
                      </FormControl>

                      <FormControl
                        isInvalid={
                          (!!errors.address && touched.address) ||
                          (!!errors.address && touched.address)
                        }
                        mt="14px"
                      >
                        <Box flexBasis="100%">
                          <Field
                            as={Input}
                            type="text"
                            id="address"
                            name="address"
                            placeholder="Address"
                            padding="5px 20px"
                            h="full"
                            border="none"
                            sx={{
                              ...inputStyleNormal,
                              fontWeight: "500",
                            }}
                            _placeholder={{
                              ...inputStyle,
                              fontWeight: "400",
                            }}
                            validate={(value: any) => {
                              let error;
                              if (value.length < 1) {
                                error = "Address is required.";
                              }
                              return error;
                            }}
                          />
                        </Box>
                        <FormErrorMessage
                          textAlign="center"
                          mt={1}
                          sx={{
                            color: "#F00",
                            justifyContent: "center",
                          }}
                        >
                          {errors.address}
                        </FormErrorMessage>
                      </FormControl>

                      <FormControl
                        isInvalid={
                          (!!errors.state && touched.state) ||
                          (!!errors.state && touched.state)
                        }
                        mt="14px"
                        flexBasis="calc(50% - 7px)"
                      >
                        <Box flexBasis="100%">
                          <Field
                            as={Input}
                            type="text"
                            id="state"
                            name="state"
                            placeholder="State"
                            padding="5px 20px"
                            h="full"
                            border="none"
                            sx={{
                              ...inputStyleNormal,
                              fontWeight: "500",
                            }}
                            _placeholder={{
                              ...inputStyle,
                              fontWeight: "400",
                            }}
                            validate={(value: any) => {
                              let error;
                              if (value.length < 1) {
                                error = "State is required.";
                              }
                              return error;
                            }}
                          />
                        </Box>
                        <FormErrorMessage
                          textAlign="center"
                          mt={1}
                          sx={{
                            color: "#F00",
                            justifyContent: "center",
                          }}
                        >
                          {errors.state}
                        </FormErrorMessage>
                      </FormControl>

                      <FormControl
                        isInvalid={
                          (!!errors.zip_code && touched.zip_code) ||
                          (!!errors.zip_code && touched.zip_code)
                        }
                        mt="14px"
                        flexBasis="calc(50% - 7px)"
                      >
                        <Box flexBasis="100%">
                          <Field
                            as={Input}
                            type="text"
                            id="zip_code"
                            name="zip_code"
                            placeholder="Zipcode"
                            padding="5px 20px"
                            h="full"
                            border="none"
                            sx={{
                              ...inputStyleNormal,
                              fontWeight: "500",
                            }}
                            _placeholder={{
                              ...inputStyle,
                              fontWeight: "400",
                            }}
                            validate={(value: any) => {
                              let error;
                              if (value?.length < 1) {
                                error = "Zipcode is required.";
                              }
                              return error;
                            }}
                          />
                        </Box>
                        <FormErrorMessage
                          textAlign="center"
                          mt={1}
                          sx={{
                            color: "#F00",
                            justifyContent: "center",
                          }}
                        >
                          {errors.zip_code}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                  </>
                )}
              </Flex>
              <Flex
                flexDirection={{ md: "row", sm: "column-reverse" }}
                justifyContent="center"
                alignItems="start"
                w={{ md: "full", sm: "calc(100% - 30px)" }}
                rowGap="16px"
                columnGap={"12px"}
                borderTop="1px solid"
                borderColor={borderColor}
                mx={{ md: "0px", sm: "15px" }}
                padding={{ md: "30px 40px", sm: "16px 15px 30px" }}
              >
                <Button
                  fontSize="14px"
                  lineHeight="17px"
                  variant="brand"
                  bgColor="white"
                  color="blue.500"
                  fontWeight="700"
                  padding="14px 54px"
                  h={{ md: "45px", sm: "55px" }}
                  w="100%"
                  borderRadius="90px"
                  onClick={goToSignIn}
                  border={{ md: "none", sm: "1px solid" }}
                  borderColor={buttonBg}
                  _hover={{
                    bgColor: buttonBg,
                    color: "#FFF",
                  }}
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  fontSize="14px"
                  lineHeight="17px"
                  variant="brand"
                  bgColor={buttonBg}
                  fontWeight="700"
                  padding="14px 54px"
                  h={{ md: "45px", sm: "55px" }}
                  w="100%"
                  borderRadius={"90px"}
                  isLoading={isRegistering}
                >
                  Create Account
                </Button>
              </Flex>
            </form>
          )}
        </Formik>
      </Flex>
    </DefaultAuth>
  );
}
