import { Box, Button, Flex, Heading } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { updateProjectStatus } from "store/customerProject/reducer";
import { uploadProjectSelector } from "store/customerProject/selector";
import { ORDER_STEPS } from "types/data-types";
import TranslationAddressOption from "./translation/TranslationAddressOption";
import TranslationRecommendedOption from "./translation/TranslationRecommendedOption";

export default function TranslationTypeSelector(props: {
  onChangeOrderStep?: Function;
}) {
  const dispatch = useDispatch();
  const { onChangeOrderStep } = props;
  const uploadProject = useSelector(uploadProjectSelector);
  const boxShadow = "0px 3.60347px 45.0434px rgba(0, 0, 0, 0.1)";

  const onSelectTransitionSpeed = (speedType: string) => {
    if (uploadProject.transitionSpeed === "express") {
      dispatch(
        updateProjectStatus({
          transitionSpeed: "standard",
        })
      );
    } else {
      dispatch(
        updateProjectStatus({
          transitionSpeed: "express",
        })
      );
    }
  };

  const onSelectTransitionOption = (option: string) => {
    if (option === "Mailed Hard Copy") {
      dispatch(
        updateProjectStatus({
          isMailHardCopy: !uploadProject.isMailHardCopy,
        })
      );
    } else if (option === "Notarization") {
      dispatch(
        updateProjectStatus({
          isNotarized: !uploadProject.isNotarized,
        })
      );
    }
  };

  const gotoPaymentStep = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "ga4_event",
      event_name: "checkout_products_selected",
    });
    onChangeOrderStep(ORDER_STEPS.SET_PAYMENT_STEP);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      rowGap="32px"
      flex={1}
      height="100%"
      padding={{ lg: "40px 35px", md: "30px 25px", base: "20px 0px" }}
      bgColor="#FFF"
      boxShadow={{ md: boxShadow, base: "none" }}
      borderRadius="15px"
    >
      <Heading
        fontSize="24px"
        lineHeight="normal"
        fontWeight="700"
        textTransform="capitalize"
        color="#000"
      >
        Options
      </Heading>
      {uploadProject.transitionType === "certified" && (
        <>
          <TranslationRecommendedOption
            type="Mailed Hard Copy"
            content="If the translation is for official use, request original signed, ink-stamped copies of your translation and get it within 2 days."
            price="$15.00"
            selected={uploadProject.isMailHardCopy}
            w="full"
            isRecommened={true}
            onSelectTransitionOption={onSelectTransitionOption}
          />
          <TranslationRecommendedOption
            type="Notarization"
            content="Most organizations require notarization of the translation certificate and original copies."
            price="$19.95"
            selected={uploadProject.isNotarized}
            w="full"
            isRecommened={true}
            onSelectTransitionOption={onSelectTransitionOption}
          />
        </>
      )}

      <TranslationRecommendedOption
        type="Expedited Turnaround"
        content="Is your translation urgent? Reduce the turnaround time by 50% with an expedited order for $10 per page."
        price={
          uploadProject.transitionType === "certified"
            ? `$${(
                10 *
                uploadProject.totalPage *
                uploadProject.targetLanguage.length
              ).toFixed(2)}`
            : `$${
                uploadProject.totalWords >= 250
                  ? (
                      0.004 *
                      uploadProject.totalWords *
                      uploadProject.targetLanguage.length
                    ).toFixed(2)
                  : (10 * uploadProject.targetLanguage.length).toFixed(2)
              }`
        }
        selected={uploadProject.transitionSpeed === "express"}
        w="full"
        isRecommened={false}
        onSelectTransitionOption={onSelectTransitionSpeed}
      />

      {uploadProject.isMailHardCopy &&
        uploadProject.transitionType === "certified" && (
          <Box>
            <TranslationAddressOption />
          </Box>
        )}

      <Flex alignItems="center" justifyContent="space-between">
        <Flex
          alignItems="center"
          fontSize="15px"
          lineHeight="normal"
          color="#000"
          fontWeight="400"
          cursor="pointer"
          transition="color .25s ease-in-out"
          _hover={{
            color: "#2F80ED",
          }}
          onClick={() => {
            onChangeOrderStep(ORDER_STEPS.SET_UPLOAD_DOCUMENTS_STEP);
          }}
        >
          Go Back
        </Flex>
        <Button
          type="button"
          w="fit-content"
          height="44px"
          padding="13px 16px"
          borderRadius="100px"
          bgColor="#2F80ED"
          color="#FFF"
          fontSize="14px"
          lineHeight="14px"
          fontWeight="bold"
          onClick={() => {
            gotoPaymentStep();
          }}
          _hover={{
            bgColor: "#3311DB !important",
          }}
        >
          Continue to Payment
        </Button>
      </Flex>
    </Box>
  );
}
