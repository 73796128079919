/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Input,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { IconClose } from "components/icons/Icons";

import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { pad_with_zeroes } from "network/helper";
import { axiosService } from "network/axios";
import emptyAvatar from "assets/img/avatars/avatar.png";
import _ from "lodash";
import UserInfoMenu from "components/menu/UserInfoMenu";
import { setAlertMessageRequest } from "store/ui/reducer";

export default function CustomerDetailsPopup(props: {
  selectedCustomerId: any | null;
  onCloseModal: Function;
}) {
  const dispatch = useDispatch();
  const { selectedCustomerId, onCloseModal } = props;
  const [isFetching, setIsFetching] = useState(true);
  const [customerDetails, setCustomerDetails] = useState(null);
  const [discount, setDiscount] = useState(null);
  const [isSavingDiscount, setIsSavingDiscount] = useState(false);
  const discountRef = useRef<any>();

  useEffect(() => {
    if (selectedCustomerId && dispatch) {
      // Fetch Manager Details
      fetcuCustomerDetails(selectedCustomerId);
    }
  }, [dispatch, selectedCustomerId]);

  const fetcuCustomerDetails = async (customer_id: string) => {
    setIsFetching(true);
    try {
      const resp: any = await axiosService.get(
        `/user/customer/details/${customer_id}`
      );
      setCustomerDetails(resp.data);
      setDiscount(resp?.data?.discount?.discount ?? 0);
    } catch (err: any) {
    } finally {
      setIsFetching(false);
    }
  };

  const customerDetailsData = useMemo(() => {
    if (customerDetails) {
      const { base, orders } = customerDetails;
      const fullName = `${base.first_name} ${base.last_name}`;
      const avatar = base.profile_pic_src
        ? base.profile_pic_src?.includes("http")
          ? base.profile_pic_src
          : `${process.env.REACT_APP_API_BASE_URL}/public_html/images/${base.profile_pic_src}`
        : emptyAvatar;
      return {
        full_name: fullName,
        avatar: avatar,
        contact: {
          email: base.email ?? "Undefined",
          phone: base.phone ?? "Undefined",
        },
        orders: orders?.map((it: any) => ({
          id: it.order_id,
          customer_name: `${it.first_name} ${it.last_name}`,
          translated_from: it.translated_from,
          translated_to: it.translated_to,
          status: it.order_status_name,
          order_total: Number(it.order_total ?? 0),
        })),
      };
    } else {
      return null;
    }
  }, [customerDetails]);

  // const onSaveDiscount = () => {
  //   if (Number(discount) > 100) {
  //     dispatch(
  //       setAlertMessageRequest({
  //         type: "error",
  //         message: "Discount value should be less than 100",
  //       })
  //     );
  //     if (discountRef?.current) {
  //       discountRef.current.focus();
  //     }
  //     return;
  //   }
  //   setIsSavingDiscount(true);
  //   axiosService
  //     .post(`/user/update-discount/${selectedCustomerId}`, {
  //       discount,
  //     })
  //     .then((res: any) => {
  //       setDiscount(res?.data?.discount?.discount);
  //       dispatch(
  //         setAlertMessageRequest({
  //           type: "success",
  //           message: "Discount value saved successfully",
  //         })
  //       );
  //     })
  //     .finally(() => {
  //       setIsSavingDiscount(false);
  //     });
  // };

  return (
    <Box
      w={{
        base: "100%",
        xl: "calc(100vw - 230px)",
        baseWidth: "calc(100vw - (100vw - 1440px) / 2 - 230px)",
      }}
      transition="transform 0.3s ease-in-out"
      transform={selectedCustomerId ? `translateX(0)` : `translateX(100%)`}
      h="100vh"
      position="fixed"
      bgColor="#FFF"
      zIndex="200"
      boxShadow={
        selectedCustomerId ? "0px 4px 40px rgba(0, 0, 0, 0.1)" : "none"
      }
      boxSizing="border-box"
      padding={{
        md: "50px 40px 60px",
        base: "50px 20px 30px",
        baseWidth: "50px calc((100vw - 1440px) / 2 + 40px) 50px 60px",
      }}
      overflow="auto"
      top="0px"
      right="0px"
    >
      <Flex
        minH="100%"
        h="fit-content"
        overflow={"hidden"}
        pr="20px"
        mr="-20px"
      >
        <IconButton
          onClick={(e) => onCloseModal()}
          aria-label="close"
          minW={"unset"}
          h={{ md: "52px", sm: "32px" }}
          w={{ md: "52px", sm: "32px" }}
          borderRadius="50%"
          bg="inherit"
          marginRight={{ md: "60px", sm: "0px", base: "0px" }}
          position={{ md: "unset", sm: "absolute", base: "absolute" }}
          right={{ md: "unset", sm: "30px", base: "30px" }}
          top={{ md: "unset", sm: "10px", base: "10px" }}
          _active={{
            bg: "inherit",
            transform: "none",
            borderColor: "transparent",
          }}
          _focus={{
            boxShadow: "none",
          }}
          icon={
            <IconClose
              h={{ md: "52px", sm: "32px" }}
              w={{ md: "52px", sm: "32px" }}
            />
          }
        />

        <Flex flex="1" direction="column" width="0">
          <Flex justifyContent="space-between">
            <Flex alignItems="center">
              {isFetching ? (
                <Skeleton
                  width="200px"
                  height="35px"
                  colorScheme="#F3F5F5"
                ></Skeleton>
              ) : (
                <Heading
                  fontSize="25px"
                  lineHeight="135%"
                  fontWeight="400"
                  color="#000"
                >
                  {customerDetailsData
                    ? customerDetailsData.full_name
                    : "Undefined"}
                </Heading>
              )}
            </Flex>
            <Box ml="auto">
              <UserInfoMenu />
            </Box>
          </Flex>

          <Box mt={{ md: "50px", base: "20px" }} mb="50px">
            <Flex flexWrap="wrap" columnGap="30px" alignItems="center">
              {!isFetching ? (
                <Avatar
                  src={customerDetailsData?.avatar}
                  _hover={{ cursor: "pointer" }}
                  color="white"
                  name="Name"
                  bg="#FAFAFA"
                  size="sm"
                  w={{ md: "125px", base: "100px" }}
                  h={{ md: "125px", base: "100px" }}
                />
              ) : (
                <Skeleton
                  w={{ md: "125px", base: "100px" }}
                  h={{ md: "125px", base: "100px" }}
                  borderRadius="50%"
                />
              )}

              <Box>
                <TableContainer>
                  <Table variant="simple">
                    <Tbody>
                      <Tr>
                        <Td py={2} px={3}>
                          Full Name
                        </Td>
                        <Td py={2} px={3}>
                          {isFetching ? (
                            <Skeleton
                              w="200px"
                              h="20px"
                              colorScheme="#F3F5F5"
                            />
                          ) : customerDetailsData ? (
                            customerDetailsData.full_name
                          ) : (
                            "Undefined"
                          )}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td py={2} px={3}>
                          Email Address
                        </Td>
                        <Td py={2} px={3}>
                          {isFetching ? (
                            <Skeleton
                              w="200px"
                              h="20px"
                              colorScheme="#F3F5F5"
                            />
                          ) : customerDetailsData ? (
                            customerDetailsData.contact.email
                          ) : (
                            "Undefined"
                          )}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td py={2} px={3}>
                          Phone Number
                        </Td>
                        <Td py={2} px={3}>
                          {isFetching ? (
                            <Skeleton
                              w="200px"
                              h="20px"
                              colorScheme="#F3F5F5"
                            />
                          ) : customerDetailsData ? (
                            customerDetailsData.contact.phone
                          ) : (
                            "Undefined"
                          )}
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
            </Flex>

            <Box mt="40px">
              <Heading
                fontSize="20px"
                lineHeight="135%"
                fontWeight="400"
                color="#000"
              >
                My Orders
              </Heading>
              <TableContainer mt={3}>
                <Table variant="simple">
                  <Thead textTransform="capitalize">
                    <Tr>
                      <Th textTransform="capitalize">Order ID</Th>
                      <Th textTransform="capitalize">Customer Name</Th>
                      <Th textTransform="capitalize">Source Language(s)</Th>
                      <Th textTransform="capitalize">Target Language(s)</Th>
                      <Th textTransform="capitalize">Order Status</Th>
                      <Th textTransform="capitalize">Order Total</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {!isFetching &&
                      customerDetailsData?.orders &&
                      customerDetailsData.orders.map(
                        (it: any, index: number) => (
                          <Tr key={index}>
                            <Td>{pad_with_zeroes(it.id, 6)}</Td>
                            <Td>{it.customer_name}</Td>
                            <Td>{it.translated_from}</Td>
                            <Td>{it.translated_to}</Td>
                            <Td>{it.status}</Td>
                            <Td>${it.order_total.toFixed(2)}</Td>
                          </Tr>
                        )
                      )}
                    {isFetching &&
                      _.range(5).map((it) => (
                        <Tr key={it}>
                          {_.range(6).map((_it) => (
                            <Td key={_it}>
                              <Skeleton
                                height="20px"
                                colorScheme="#F3F5F5"
                              ></Skeleton>
                            </Td>
                          ))}
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>

            {/* <Box mt="30px">
              <Heading
                fontSize="20px"
                lineHeight="135%"
                fontWeight="400"
                color="#000"
              >
                Discount (%)
              </Heading>
              <Flex mt={2}>
                <Input
                  ref={discountRef}
                  type="number"
                  name="discount"
                  max="100"
                  maxW="300px"
                  borderRadius="0"
                  placeholder="Please enter a discount value..."
                  value={discount}
                  onChange={(e) => {
                    setDiscount(e.target.value);
                  }}
                />
                <Button
                  type="button"
                  padding={{ sm: "10px 20px", base: "10px" }}
                  border={"2px solid #2F80FD"}
                  bgColor="#2F80FD"
                  borderRadius="0px"
                  fontSize="14px"
                  lineHeight="24px"
                  fontWeight="400"
                  color="#FFF"
                  _hover={{
                    bgColor: "#2F80FD",
                    opacity: "0.5",
                  }}
                  _active={{
                    bgColor: "#2F80FD",
                    opacity: "0.8",
                  }}
                  onClick={() => {
                    onSaveDiscount();
                  }}
                  isLoading={isSavingDiscount}
                >
                  Save
                </Button>
              </Flex>
            </Box> */}
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
}
