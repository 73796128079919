import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { apiService } from "network/api";
import { getLoggedInUser } from "network/helper";
import { takeLatest, call, put } from "redux-saga/effects";
import {
  addBankRequest,
  addBankRequestFailed,
  addBankRequestSuccess,
  addTeammemberRequest,
  addTeammemberRequestFailed,
  addTeammemberRequestSuccess,
  delBankRequest,
  delBankRequestFailed,
  delBankRequestSuccess,
  deleteAvatarRequest,
  deleteTeammemberRequest,
  deleteTeammemberRequestFailed,
  deleteTeammemberRequestSuccess,
  delLanguagesRequest,
  delLanguagesRequestFailed,
  delLanguagesRequestSuccess,
  fetchTeamUsersRequest,
  fetchTeamUsersRequestFailed,
  fetchTeamUsersRequestSuccess,
  getAllUserRequest,
  getAllUserRequestFailed,
  getAllUserRequestSuccess,
  getBankRequest,
  getBankRequestFailed,
  getBankRequestSuccess,
  getCardsRequest,
  getCardsRequestFailed,
  getCardsRequestSuccess,
  getIDRequest,
  getIDRequestFailed,
  getIDRequestSuccess,
  getInvoicesRequest,
  getInvoicesRequestFailed,
  getInvoicesRequestSuccess,
  getLanguagesRequest,
  getLanguagesRequestFailed,
  getLanguagesRequestSuccess,
  getUserRequest,
  getUserSucess,
  loginFailed,
  loginRequest,
  loginSuccess,
  putIDRequest,
  putIDRequestFailed,
  putIDRequestSuccess,
  removeCardInfoFailed,
  removeCardInfoRequest,
  removeCardInfoSuccess,
  saveCardsRequest,
  saveCardsRequestFailed,
  saveCardsRequestSuccess,
  signUpRequest,
  signUpRequestFailed,
  signUpRequestSuccess,
  // unAssignedServiceRequest,
  // unAssignedServiceRequestFailed,
  // unAssignedServiceRequestSuccess,
  updateLanguagesRequest,
  updateLanguagesRequestFailed,
  updateLanguagesRequestSuccess,
  updateTeammemberRoleRequest,
  updateTeammemberRoleRequestFailed,
  updateTeammemberRoleRequestSuccess,
  updateUserFailed,
  updateUserRequest,
  updateUserSuccess,
  uploadAvatarFailed,
  uploadAvatarRequest,
  uploadAvatarSuccess,
  uploadInvoiceRequest,
  uploadInvoiceRequestFailed,
  uploadInvoiceRequestSuccess,
} from "./reducer";

function* getUser({ payload }: PayloadAction<any>) {
  try {
    if (typeof payload.user_id !== "undefined") {
      const res: AxiosResponse<any> = yield call(
        apiService.getUser,
        payload.user_id
      );

      yield put(getUserSucess(res.data?.user));
    }
  } catch (err: any) {
    const message = err?.data?.message || err.message;
    console.log(message);
  }
}

function* signIn({ payload }: PayloadAction<any>) {
  try {
    const res: AxiosResponse<any> = yield call(apiService.login, payload);

    const access_token = res.data.access_token;
    localStorage.setItem("access_token", access_token);
    const user = getLoggedInUser(access_token);
    localStorage.setItem("user_details", JSON.stringify(user));

    yield put(loginSuccess(user));
  } catch (err: any) {
    yield put(loginFailed(err?.data?.messages?.error));
    return err;
  }
}

function* signUp({ payload }: PayloadAction<any>) {
  try {
    let sourceCustomer = payload?.source === "admin1";
    if (sourceCustomer) {
      delete payload.source;
    }
    yield call(apiService.register, payload);
    if (payload?.source === "admin") {
      yield put(
        signUpRequestSuccess({
          type: "success",
          message: "New user has been added",
        })
      );
      yield put(getAllUserRequest());
    } else {
      yield put(
        signUpRequestSuccess({
          type: "success",
          message: "You have been registered successfully. ",
        })
      );
      if (sourceCustomer) {
        yield put(getAllUserRequest());
      }
    }
  } catch (err: any) {
    yield put(
      signUpRequestFailed({
        type: "error",
        message: err.data.messages.error || "Failed to register new account",
      })
    );
  }
}

function* updateUser({ payload }: PayloadAction<any>) {
  try {
    const { user_id, user_details } = payload;
    const res: AxiosResponse<any> = yield call(
      apiService.updateUser,
      Number(user_id),
      user_details
    );
    yield put(getUserSucess(res.data?.user));
    yield put(
      updateUserSuccess({
        type: "success",
        message: "Profile updated successfully.",
      })
    );
  } catch (err: any) {
    yield put(updateUserFailed());
    console.log(err);
  }
}

function* uploadAvatar({ payload }: PayloadAction<any>) {
  try {
    const { user_id, form_data } = payload;
    const res: AxiosResponse<any> = yield call(
      apiService.uploadAvatar,
      Number(user_id),
      form_data
    );

    yield put(uploadAvatarSuccess(res.data?.user));
  } catch (err: any) {
    yield put(uploadAvatarFailed());
  }
}

function* deleteAvatar({ payload }: PayloadAction<any>) {
  try {
    const { user_id } = payload;
    const res: AxiosResponse<any> = yield call(
      apiService.deleteAvatar,
      Number(user_id)
    );
    yield put(uploadAvatarSuccess(res.data?.user));
  } catch (err: any) {
    yield put(uploadAvatarFailed());
  }
}

function* getCards({ payload }: PayloadAction<any>) {
  try {
    const { user_id } = payload;
    const res: AxiosResponse<any> = yield call(
      apiService.getCards,
      Number(user_id)
    );
    yield put(getCardsRequestSuccess(res?.data?.cards || []));
    // console.log(res.data);
  } catch (err: any) {
    yield put(getCardsRequestFailed());
  }
}

function* saveCardInfo({ payload }: PayloadAction<any>) {
  const { user_id, card_details } = payload;

  try {
    const res: AxiosResponse<any> = yield call(
      apiService.saveCardInfo,
      Number(user_id),
      card_details
    );
    yield put(saveCardsRequestSuccess(res.data.cards));
  } catch (err: any) {
    yield put(saveCardsRequestFailed());
  }
  // console.log(user_id, card_datails);
}

function* removeCardInfo({ payload }: PayloadAction<any>) {
  const { user_id, card_id } = payload;
  try {
    const res: AxiosResponse<any> = yield call(
      apiService.removeCardInfo,
      Number(user_id),
      Number(card_id)
    );
    yield put(removeCardInfoSuccess(res?.data?.cards || []));
  } catch (err: any) {
    yield put(removeCardInfoFailed());
  }
}

function* getAllUsers() {
  try {
    const res: AxiosResponse<any> = yield call(apiService.getAllUsers);
    yield put(getAllUserRequestSuccess(res?.data || []));
  } catch (err: any) {
    yield put(getAllUserRequestFailed());
  }
}

function* getID({ payload }: PayloadAction<any>) {
  const { user_id } = payload;
  try {
    const res: AxiosResponse<any> = yield call(
      apiService.getIDCard,
      Number(user_id)
    );

    yield put(getIDRequestSuccess(res?.data?.result || null));
  } catch (err: any) {
    yield put(
      getIDRequestFailed({
        type: "error",
        message: "Error in getting personal identification information",
      })
    );
  }
}

function* putID({ payload }: PayloadAction<any>) {
  const { user_id, id_details } = payload;
  try {
    const res: AxiosResponse<any> = yield call(
      apiService.putIDCard,
      Number(user_id),
      id_details
    );
    yield put(putIDRequestSuccess(res?.data || null));
    yield put(
      updateUserSuccess({
        type: "success",
        message: "ID was updated successfully.",
      })
    );
  } catch (err: any) {
    yield put(
      putIDRequestFailed({
        type: "error",
        message: "Error in put the ID information",
      })
    );
  }
}

function* getLanguages({ payload }: PayloadAction<any>) {
  const { user_id } = payload;
  try {
    const res: AxiosResponse<any> = yield call(
      apiService.getLanguages,
      Number(user_id)
    );

    yield put(getLanguagesRequestSuccess(res?.data || []));
  } catch (err: any) {
    yield put(getLanguagesRequestFailed());
  }
}

function* setLanguages({ payload }: PayloadAction<any>) {
  const { user_id, details } = payload;
  try {
    const res: AxiosResponse<any> = yield call(
      apiService.updateLanguages,
      Number(user_id),
      details
    );

    yield put(updateLanguagesRequestSuccess(res?.data || []));
  } catch (err: any) {
    yield put(updateLanguagesRequestFailed());
  }
}

function* delLanguage({ payload }: PayloadAction<any>) {
  const { id, user_id } = payload;
  try {
    const res: AxiosResponse<any> = yield call(
      apiService.delLanguage,
      Number(id),
      Number(user_id)
    );

    yield put(delLanguagesRequestSuccess(res?.data || []));
  } catch (err: any) {
    yield put(delLanguagesRequestFailed());
  }
}

function* addBank({ payload }: PayloadAction<any>) {
  const { user_id, bank_info } = payload;
  try {
    const res: AxiosResponse<any> = yield call(
      apiService.addBank,
      Number(user_id),
      bank_info
    );

    yield put(addBankRequestSuccess(res?.data?.banks || []));
  } catch (err: any) {
    yield put(
      addBankRequestFailed({
        type: "error",
        message:
          err?.data?.messages?.error ||
          "Error occured while adding the bank account.",
      })
    );
  }
}

function* getBanks({ payload }: PayloadAction<any>) {
  const { user_id } = payload;
  try {
    const res: AxiosResponse<any> = yield call(
      apiService.getBanks,
      Number(user_id)
    );

    yield put(getBankRequestSuccess(res?.data?.banks || []));
  } catch (err: any) {
    yield put(
      getBankRequestFailed({
        type: "error",
        message:
          err?.data?.messages?.error ||
          "Error occured while getting the bank account.",
      })
    );
  }
}

function* delBank({ payload }: PayloadAction<any>) {
  const { user_id, bank_id } = payload;
  try {
    const res: AxiosResponse<any> = yield call(
      apiService.delBank,
      Number(user_id),
      Number(bank_id)
    );

    yield put(delBankRequestSuccess(res?.data?.banks || []));
  } catch (err: any) {
    yield put(
      delBankRequestFailed({
        type: "error",
        message:
          err?.data?.messages?.error ||
          "Error occured while deleting the bank account.",
      })
    );
  }
}

function* fetchTeamUsers({ payload }: PayloadAction<any>) {
  try {
    const { user_id } = payload;
    const res: AxiosResponse<any> = yield call(
      apiService.fetchTeamUsers,
      Number(user_id)
    );

    yield put(fetchTeamUsersRequestSuccess(res?.data?.team || []));
  } catch (err: any) {
    console.log(err);
    yield put(
      fetchTeamUsersRequestFailed({
        type: "error",
        message:
          err?.data?.messages?.error ||
          "Error occured in fetching users in the team",
      })
    );
  }
}

function* addTeamUser({ payload }: PayloadAction<any>) {
  try {
    const { business_id, details } = payload;
    const res: AxiosResponse<any> = yield call(
      apiService.addTeamUsers,
      Number(business_id),
      details
    );

    yield put(addTeammemberRequestSuccess(res?.data?.team || []));
  } catch (err: any) {
    yield put(
      addTeammemberRequestFailed({
        type: "error",
        message:
          err?.data?.messages?.error || "Error occured in adding user to team",
      })
    );
  }
}

function* deleteTeamUser({ payload }: PayloadAction<any>) {
  try {
    const { team_user_id } = payload;

    const res: AxiosResponse<any> = yield call(
      apiService.deleteTeamUser,
      Number(team_user_id)
    );

    yield put(deleteTeammemberRequestSuccess(res?.data?.team || []));
  } catch (err: any) {
    yield put(
      deleteTeammemberRequestFailed({
        type: "error",
        message:
          err?.data?.messages?.error ||
          "Error occured in deleting user from team.",
      })
    );
  }
}

function* updateTeamUserRole({ payload }: PayloadAction<any>) {
  const { team_user_id, role } = payload;
  try {
    const res: AxiosResponse<any> = yield call(
      apiService.updateTeammemberRole,
      Number(team_user_id),
      role
    );

    yield put(updateTeammemberRoleRequestSuccess({}));
  } catch (err: any) {
    yield put(
      updateTeammemberRoleRequestFailed({
        type: "error",
        message:
          err?.data?.messages?.error || "Error occured in updating user role.",
      })
    );
  }
}

function* uploadInvoice({ payload }: PayloadAction<any>) {
  try {
    const { user_id, invoice_file, year, date } = payload;
    console.log(payload);
    const formData = new FormData();
    formData.append("file", invoice_file);

    const res: AxiosResponse<any> = yield call(
      apiService.uploadInvoice,
      formData,
      user_id,
      year,
      date
    );

    yield put(
      uploadInvoiceRequestSuccess({
        type: "success",
        message: "Upload Invoice successfully!",
      })
    );
  } catch (err: any) {
    yield put(
      uploadInvoiceRequestFailed({
        type: "error",
        message: err?.data?.messages?.error || "Failed to upload invoices",
      })
    );
  }
}

function* getInvoices({ payload }: PayloadAction<any>) {
  try {
    const { user_id } = payload;
    const res: AxiosResponse<any> = yield call(
      apiService.getInvoices,
      Number(user_id)
    );

    yield put(getInvoicesRequestSuccess(res?.data?.results || []));
  } catch (err: any) {
    yield put(
      getInvoicesRequestFailed({
        type: "error",
        message: err?.data?.messages?.error || "Failed to get invoices",
      })
    );
  }
}

export function* userEffects() {
  yield takeLatest(getUserRequest.type, getUser);
  yield takeLatest(loginRequest.type, signIn);
  yield takeLatest(updateUserRequest.type, updateUser);
  yield takeLatest(uploadAvatarRequest.type, uploadAvatar);
  yield takeLatest(deleteAvatarRequest.type, deleteAvatar);
  yield takeLatest(getCardsRequest.type, getCards);
  yield takeLatest(saveCardsRequest.type, saveCardInfo);
  yield takeLatest(removeCardInfoRequest.type, removeCardInfo);
  yield takeLatest(getAllUserRequest.type, getAllUsers);
  yield takeLatest(signUpRequest.type, signUp);

  yield takeLatest(getIDRequest.type, getID);
  yield takeLatest(putIDRequest.type, putID);

  yield takeLatest(getLanguagesRequest.type, getLanguages);
  yield takeLatest(updateLanguagesRequest.type, setLanguages);
  yield takeLatest(delLanguagesRequest.type, delLanguage);

  yield takeLatest(addBankRequest.type, addBank);
  yield takeLatest(getBankRequest.type, getBanks);
  yield takeLatest(delBankRequest.type, delBank);

  // yield takeLatest(assignServiceRequest.type, assignService);
  // yield takeLatest(unAssignedServiceRequest.type, unAssignService);
  // yield takeLatest(getAssignedServiceRequest.type, getAssignedServices);

  yield takeLatest(fetchTeamUsersRequest.type, fetchTeamUsers);
  yield takeLatest(addTeammemberRequest.type, addTeamUser);
  yield takeLatest(deleteTeammemberRequest.type, deleteTeamUser);

  yield takeLatest(updateTeammemberRoleRequest.type, updateTeamUserRole);

  yield takeLatest(uploadInvoiceRequest.type, uploadInvoice);
  yield takeLatest(getInvoicesRequest.type, getInvoices);
}

const userSagas = [call(userEffects)];

export default userSagas;
