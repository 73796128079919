import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { IconArrowRight } from "components/icons/Icons";
import _ from "lodash";
import { useMemo } from "react";

export default function CustomPaginations(props: {
  totalCount: number;
  totalPages: number;
  currentPage: number;
  perPage: number;
  onChangePage: Function;
  onSetPageLimit: Function;
}) {
  const {
    totalCount,
    totalPages,
    currentPage,
    onChangePage,
    perPage,
    onSetPageLimit,
  } = props;
  const shadow = "0px 4px 30px rgba(0, 0, 0, 0.1)";
  const perPageList = useMemo(() => {
    if (totalCount < 10) {
      return [10];
    } else if (totalCount < 20) {
      return [10, 20];
    } else if (totalCount < 30) {
      return [10, 20, 30];
    } else if (totalCount < 50) {
      return [10, 20, 30, 50];
    }
    return [10, 20, 30, 50];
  }, [totalCount]);
  return (
    <Box
      mt={5}
      display="flex"
      justifyContent="center"
      position="relative"
      zIndex={111}
    >
      <Menu>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          sx={{
            marginRight: "10px",
            textAlign: "left",
            padding: "15px 16px",
            borderRadius: "0px",
            background: "#FFF",
            border: "1px solid #E0E0E0",
            fontSize: "15px",
            fontWeight: "400",
            lineHeight: "normal",
            fontFamily: "Inter",
            "&[data-active]": {
              backgroundColor: "#EFEFEF",
            },
          }}
        >
          <Flex alignItems={"center"}>
            <Text
              fontSize="14px"
              lineHeight="24px"
              color="#000"
              boxSizing="border-box"
            >
              {perPage}
            </Text>
          </Flex>
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          minW="80px"
          borderRadius="0px"
          bg={"#FFF"}
          border="none"
        >
          <Flex
            flexDirection="column"
            p="5px 0px"
            fontSize={"14px"}
            lineHeight="24px"
            fontWeight={"400"}
            color="black"
          >
            {perPageList.map((option, index: number) => (
              <MenuItem
                key={index}
                _hover={{ bg: "#EFEFEF" }}
                borderRadius="0px"
                padding="5px 10px"
                onClick={() => onSetPageLimit(option)}
                borderBottom={
                  index < perPageList.length - 1 ? "1px solid #F3F5F5" : ""
                }
              >
                <Text fontSize="sm">{option}</Text>
              </MenuItem>
            ))}
          </Flex>
        </MenuList>
      </Menu>
      <ButtonGroup
        sx={{
          svg: {
            width: "40px !important",
            height: "40px !important",
          },
          button: {
            backgroundColor: "transparent",
            width: "40px",
            height: "40px",
            border: "1px solid #DCDCDC",
            borderRadius: "0px",
            fontWeight: "400",
            "&+button": {
              borderLeft: "none",
              marginLeft: "0px",
            },
            "&.active": {
              backgroundColor: "#2F80ED",
              borderColor: "#2F80ED",
              color: "#FFF",
            },
          },
        }}
      >
        <Button
          onClick={() =>
            onChangePage(currentPage > 1 ? currentPage - 1 : currentPage)
          }
        >
          <IconArrowRight
            sx={{
              transform: "rotate(180deg)",
            }}
          />
        </Button>
        {totalPages <= 5 &&
          _.range(1, totalPages + 1)?.map((it) => (
            <Button
              key={it}
              className={it === currentPage ? "active" : ""}
              onClick={() => onChangePage(it)}
              fontSize="16px"
            >
              {it}
            </Button>
          ))}
        {totalPages > 5 && currentPage > 3 && currentPage <= totalPages - 3 && (
          <>
            <Button
              onClick={() => onChangePage(1)}
              fontSize="16px"
              className={1 === currentPage ? "active" : ""}
            >
              1
            </Button>
            <Button fontSize="16px">...</Button>
            <Button
              onClick={() => onChangePage(currentPage - 1)}
              fontSize="16px"
            >
              {currentPage - 1}
            </Button>
            <Button
              className="active"
              onClick={() => onChangePage(currentPage)}
              fontSize="16px"
            >
              {currentPage}
            </Button>
            <Button
              onClick={() => onChangePage(currentPage + 1)}
              fontSize="16px"
            >
              {currentPage + 1}
            </Button>
            <Button fontSize="16px">...</Button>
            <Button onClick={() => onChangePage(totalPages)} fontSize="16px">
              {totalPages}
            </Button>
          </>
        )}
        {totalPages > 5 && currentPage > totalPages - 3 && (
          <>
            <Button
              onClick={() => onChangePage(1)}
              fontSize="16px"
              className={currentPage === 1 ? "active" : ""}
            >
              1
            </Button>
            <Button fontSize="16px">...</Button>
            {_.range(totalPages - 3, totalPages + 1)?.map((it) => (
              <Button
                className={currentPage === it ? "active" : ""}
                onClick={() => onChangePage(it)}
                key={it}
                fontSize="16px"
              >
                {it}
              </Button>
            ))}
          </>
        )}
        {totalPages > 5 && currentPage <= 3 && (
          <>
            {_.range(1, 4)?.map((it) => (
              <Button
                key={it}
                onClick={() => onChangePage(it)}
                fontSize="16px"
                className={currentPage === it ? "active" : ""}
              >
                {it}
              </Button>
            ))}
            <Button fontSize="16px">...</Button>
            <Button fontSize="16px" onClick={() => onChangePage(totalPages)}>
              {totalPages}
            </Button>
          </>
        )}
        <Button
          onClick={() =>
            onChangePage(
              currentPage < totalPages ? currentPage + 1 : currentPage
            )
          }
        >
          <IconArrowRight />
        </Button>
      </ButtonGroup>
    </Box>
  );
}
