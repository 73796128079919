import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
} from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import { Field, Formik } from "formik";
import DefaultAuth from "layouts/auth/Default";
import { axiosService } from "network/axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setAlertMessageRequest,
  setAlertMessageSuccess,
} from "store/ui/reducer";

type ResetFormInputs = {
  password: string;
  confirmPassword: string;
};

const inputStyle = {
  fontSize: "14px",
  lineHeight: "17px",
  textAlign: "center",
  color: "black",
  border: "none !important",
  boxShadow: "none !important",
  bgColor: "transparent",
};

const inputStyleNormal = {
  fontSize: "14px",
  lineHeight: "17px",
  textAlign: "center",
  color: "black",
  border: "1px solid #E9E9E9",
  borderRadius: "50px",
  padding: "15px 30px",
  height: "60px",
  bgColor: "#FFF",
  boxShadow: "none !important",
};

export default function Reset() {
  const initialValues: ResetFormInputs = {
    password: "",
    confirmPassword: "",
  };
  const queryParameters = new URLSearchParams(window.location.search);
  const email = queryParameters.get("email");
  const token = queryParameters.get("token");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const bgShadow = useColorModeValue(
    "0px 3.60347px 45.0434px rgba(0, 0, 0, 0.1)",
    "unset"
  );
  const buttonBg = "blue.500";
  const textColor = useColorModeValue("navy.700", "white");
  const [isProcessing, setIsProcessing] = useState(false);
  const borderColor = "gray.100";

  const onResetPassword = async (payload: any) => {
    if (!email && !token) {
      dispatch(
        setAlertMessageRequest({
          type: "error",
          message:
            "Password Reset Link is wrong. Please check your email box again to get correct link",
        })
      );
      return;
    }

    const resetPayload = {
      password: payload.password,
      email: email,
      token: token,
    };

    try {
      await axiosService.post("/change", resetPayload);

      dispatch(
        setAlertMessageSuccess({
          type: "success",
          message: "Your password was updated successfully!",
        })
      );
      navigate("/auth/sign-in");
    } catch (error: any) {
      dispatch(
        setAlertMessageRequest({
          type: "error",
          message:
            error?.data?.messages?.error ||
            "Error occured in requesting password reset.",
        })
      );
      return;
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <DefaultAuth>
      <Flex
        maxW="520px"
        w="100%"
        mx="auto"
        my="auto"
        pt="40px"
        pb="10px"
        px="20px"
        h="fit-content"
        alignItems="start"
        justifyContent="center"
        flexDirection="column"
        borderRadius="18px"
        bgColor={"#FFF"}
        boxShadow={bgShadow}
      >
        <Heading
          w="100%"
          color={textColor}
          fontFamily="Canela Text"
          fontSize="24px"
          lineHeight="135%"
          mb={"30px"}
          textAlign="center"
        >
          Reset Password
        </Heading>

        <Formik
          initialValues={initialValues}
          onSubmit={(values: any) => {
            onResetPassword(values);
          }}
        >
          {({ handleSubmit, errors, touched, values }) => (
            <form onSubmit={handleSubmit} style={{ width: "100%" }}>
              <Flex
                zIndex="2"
                direction="column"
                padding={{ md: "10px 40px 30px", base: "0px 0px 20px" }}
                w="full"
              >
                <FormControl isInvalid={!!errors.password && touched.password}>
                  <Field
                    as={Input}
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Password"
                    sx={{
                      ...inputStyleNormal,
                      fontWeight: "500",
                    }}
                    _placeholder={{
                      ...inputStyle,
                      fontWeight: "400",
                    }}
                    validate={(value: any) => {
                      let error;
                      if (value.length < 1) {
                        error = "Password is required.";
                      }
                      return error;
                    }}
                  />
                  <FormErrorMessage
                    mt={1}
                    sx={{
                      color: "#F00",
                      justifyContent: "center",
                    }}
                  >
                    {errors.password}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={
                    !!errors.confirmPassword && touched.confirmPassword
                  }
                  mt="16px"
                >
                  <Field
                    as={Input}
                    type="password"
                    id="confirmPassword"
                    name="confirmPassword"
                    placeholder="confirmPassword"
                    sx={{
                      ...inputStyleNormal,
                      fontWeight: "500",
                    }}
                    _placeholder={{
                      ...inputStyle,
                      fontWeight: "400",
                    }}
                    validate={(value: any) => {
                      let error;
                      if (value.length < 1) {
                        error = "Confirm Password is required.";
                      }
                      if (value.length > 0 && value !== values.password) {
                        error = "Password does not matched.";
                      }

                      return error;
                    }}
                  />
                  <FormErrorMessage
                    mt={1}
                    sx={{
                      color: "#F00",
                      justifyContent: "center",
                    }}
                  >
                    {errors.confirmPassword}
                  </FormErrorMessage>
                </FormControl>
              </Flex>
              <Flex
                flexDirection="row"
                justifyContent="center"
                alignItems="start"
                w="full"
                columnGap={"12px"}
                borderTop="1px solid"
                borderColor={borderColor}
                padding={{ md: "30px 40px", base: "20px 0px" }}
              >
                <Button
                  fontSize="14px"
                  lineHeight="17px"
                  variant="brand"
                  bgColor="white"
                  color="blue.500"
                  fontWeight="700"
                  padding="14px 54px"
                  h="45px"
                  w="100%"
                  borderRadius="90px"
                  onClick={() => {
                    navigate("/auth/sign-in");
                  }}
                  _hover={{
                    bgColor: buttonBg,
                    color: "#FFF",
                  }}
                >
                  Back To Login
                </Button>
                <Button
                  type="submit"
                  fontSize="14px"
                  lineHeight="17px"
                  variant="brand"
                  bgColor={buttonBg}
                  fontWeight="700"
                  padding="14px 54px"
                  h="45px"
                  w="100%"
                  borderRadius={"90px"}
                  isLoading={isProcessing}
                >
                  Submit
                </Button>
              </Flex>
            </form>
          )}
        </Formik>
      </Flex>
    </DefaultAuth>
  );
}
