/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useWindowWidth } from "@react-hook/window-size";
import SortingHandler from "components/sortBox/SortingHandler";
import _ from "lodash";
import { filterUsersByName } from "network/helper";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { searchQuerySelector } from "store/ui/selector";
import { allUsersSelector } from "store/user/selector";
import AdminDetailPopup from "./components/AdminDetailPopup";
import AdminsMobileTable from "./components/AdminsMobileTable";
import AdminsTable from "./components/AdminsTable";
import ProjectManagerDetailPopup from "./components/ProjectManagerDetailPopup";
import ProjectManagersMobileTable from "./components/ProjectManagersMobileTable";
import ProjectManagersTable from "./components/ProjectManagersTable";
import TranslatorDetailPopup from "./components/TranslatorDetailPopup";
import TranslatorsMobileTable from "./components/TranslatorsMobileTable";
import TranslatorsTable from "./components/TranslatorsTable";

export default function Teams() {
  const allUsers = useSelector(allUsersSelector);
  const searchQuery = useSelector(searchQuerySelector);
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedManagerId, setSelectedManagerId] = useState(null);
  const [selectedTranslatorId, setSelectedTranslatorId] = useState(null);
  const [selectedAdminId, setSelectedAdminId] = useState(null);
  const [selectedOption, setSelectedOption] = useState<{
    key: string;
    label: string;
    dir: "desc" | "asc";
  }>({
    key: "first_name",
    label: "Name",
    dir: "desc",
  });
  const windowWidth = useWindowWidth();

  const sortingOptions = [
    {
      key: "first_name",
      label: "Name",
    },
    {
      key: "email",
      label: "Email",
    },
    {
      key: "phone",
      label: "Phone",
    },
  ];
  const projectManagers = useMemo(() => {
    if (allUsers && selectedOption) {
      return _.orderBy(
        filterUsersByName(allUsers.projectManagers, searchQuery),
        selectedOption.key,
        selectedOption.dir
      )?.map((it: any) => {
        console.log(it);
        return {
          id: it.user_id,
          name: `${it.first_name} ${it.last_name}`,
          email: it.email ? it.email : "",
          phone: it.phone ? it.phone : "",
          id_verified: it.id_file_location ? 1 : 0,
          address: it?.id_address ?? "",
        };
      });
    } else {
      return [];
    }
  }, [allUsers, selectedOption, searchQuery]);

  const translators = useMemo(() => {
    if (allUsers) {
      return _.orderBy(
        filterUsersByName(allUsers.translators, searchQuery),
        selectedOption.key,
        selectedOption.dir
      )?.map((it: any) => {
        return {
          id: it.user_id,
          name: `${it.first_name} ${it.last_name}`,
          email: `${it.email ?? "_"}`,
          phone: `${it.phone ?? "_"}`,
          languages: it.languages?.map((_it: any) => _it.language),
        };
      });
    } else {
      return [];
    }
  }, [allUsers, selectedOption, searchQuery]);

  const admins = useMemo(() => {
    if (allUsers) {
      return _.orderBy(
        filterUsersByName(allUsers.admins, searchQuery),
        selectedOption.key,
        selectedOption.dir
      )?.map((it: any) => {
        return {
          id: it.user_id,
          name: `${it.first_name} ${it.last_name}`,
          email: `${it.email ?? "_"}`,
          phone: `${it.phone ?? "_"}`,
          description: `${it.description ? it.description : "-"}`,
          city: `-`,
          country: `-`,
        };
      });
    } else {
      return [];
    }
  }, [allUsers, selectedOption, searchQuery]);

  useEffect(() => {
    const hash = window.location.hash ?? null;
    if (hash) {
      const type = hash.replace("#", "");
      if (type === "manager") {
        setTabIndex(0);
      } else if (type === "translator") {
        setTabIndex(1);
      }
    } else {
      setTabIndex(0);
    }
  }, []);
  return (
    <Box pt={{ base: "60px", md: "80px", xl: "80px" }}>
      <Tabs
        variant="unstyled"
        index={tabIndex}
        onChange={(e) => {
          setTabIndex(e);
        }}
      >
        <TabList borderBottom={"2px solid #E9E9E9"}>
          <Tab
            padding={{ md: "14px 25px 14px 0px", sm: "10px 15px 10px 0px" }}
            fontSize={{ md: "17px", sm: "14px" }}
            lineHeight={{ md: "24px", sm: "22px" }}
            fontWeight="400"
            alignItems="center"
            _selected={{ color: "blue.500" }}
            _focus={{ boxShadow: "none" }}
          >
            Project Managers
          </Tab>
          <Tab
            padding={{ md: "14px 25px", sm: "10px" }}
            fontSize={{ md: "17px", sm: "14px" }}
            lineHeight={{ md: "24px", sm: "22px" }}
            fontWeight="400"
            alignItems="center"
            _selected={{ color: "blue.500" }}
            _focus={{ boxShadow: "none" }}
          >
            Translators
          </Tab>
          <Tab
            padding={{ md: "14px 25px", sm: "10px" }}
            fontSize={{ md: "17px", sm: "14px" }}
            lineHeight={{ md: "24px", sm: "22px" }}
            fontWeight="400"
            alignItems="center"
            _selected={{ color: "blue.500" }}
            _focus={{ boxShadow: "none" }}
          >
            Admins
          </Tab>
          <Box
            padding={{ md: "14px 0px", sm: "10px 0px" }}
            position="absolute"
            right="30px"
            display={{
              base: "none",
              md: "block",
            }}
            boxSizing="border-box"
            zIndex={90}
          >
            <SortingHandler
              selectedOption={selectedOption}
              sortingOptions={sortingOptions}
              onChangeSortingOption={(e: any) => {
                setSelectedOption(e);
              }}
            />
          </Box>
        </TabList>
        <TabIndicator mt="-2px" height="2px" bg="blue.500" borderRadius="1px" />
        <TabPanels>
          <TabPanel px="0">
            {windowWidth >= 768 ? (
              <ProjectManagersTable
                tableData={projectManagers}
                onSelectRow={(id: string) => {
                  setSelectedManagerId(id);
                }}
              />
            ) : (
              <ProjectManagersMobileTable
                tableData={projectManagers}
                onSelectRow={(id: string) => {
                  setSelectedManagerId(id);
                }}
              />
            )}
          </TabPanel>
          <TabPanel px="0">
            {windowWidth >= 768 ? (
              <TranslatorsTable
                tableData={translators}
                onSelectRow={(id: string) => {
                  setSelectedTranslatorId(id);
                }}
              />
            ) : (
              <TranslatorsMobileTable
                tableData={translators}
                onSelectRow={(id: string) => {
                  setSelectedTranslatorId(id);
                }}
              />
            )}
          </TabPanel>
          <TabPanel px="0">
            {windowWidth >= 768 ? (
              <AdminsTable
                tableData={admins}
                onSelectRow={(id: string) => {
                  setSelectedAdminId(id);
                }}
              />
            ) : (
              <AdminsMobileTable
                tableData={admins}
                onSelectRow={(id: string) => {
                  setSelectedAdminId(id);
                }}
              />
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
      <ProjectManagerDetailPopup
        selectedManagerId={selectedManagerId}
        onCloseModal={() => {
          setSelectedManagerId(null);
        }}
      />

      <TranslatorDetailPopup
        selectedTranslatorId={selectedTranslatorId}
        onCloseModal={() => {
          setSelectedTranslatorId(null);
        }}
      />

      <AdminDetailPopup
        selectedAdminId={selectedAdminId}
        onCloseModal={() => {
          setSelectedAdminId(null);
        }}
      />
    </Box>
  );
}
