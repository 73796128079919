import { mode } from '@chakra-ui/theme-tools';
export const globalStyles = {
	colors: {
		black: "#000000",
		// brightBlue: "#2F80ED",
		// lightBlue: "#D9E4F3",
		// grey: {
		// 	main: "#E9E9E9"
		// },
		// darkGrey: "#8E8C8C",
		// green: "#00B67A",
		red: "#F02828",
		brand: {
			100: '#E9E3FF',
			200: '#422AFB',
			300: '#422AFB',
			400: '#7551FF',
			500: '#422AFB',
			600: '#3311DB',
			700: '#02044A',
			800: '#190793',
			900: '#11047A'
		},
		brandScheme: {
			100: '#E9E3FF',
			200: '#7551FF',
			300: '#7551FF',
			400: '#7551FF',
			500: '#422AFB',
			600: '#3311DB',
			700: '#02044A',
			800: '#190793',
			900: '#02044A'
		},
		brandTabs: {
			100: '#E9E3FF',
			200: '#422AFB',
			300: '#422AFB',
			400: '#422AFB',
			500: '#422AFB',
			600: '#3311DB',
			700: '#02044A',
			800: '#190793',
			900: '#02044A'
		},
		secondaryGray: {
			100: '#E0E5F2',
			200: '#E1E9F8',
			300: '#F4F7FE',
			400: '#E9EDF7',
			500: '#8F9BBA',
			600: '#A3AED0',
			700: '#707EAE',
			800: '#707EAE',
			900: '#1B2559'
		},
		// red: {
		// 	100: '#FEEFEE',
		// 	500: '#EE5D50',
		// 	600: '#E31A1A'
		// },
		blue: {
			50: '#D9E4F3',
			500: '#2F80ED'
		},
		orange: {
			100: '#FFF6DA',
			500: '#FFB547'
		},
		green: {
			500: '#00B67A'
		},
		navy: {
			50: '#d0dcfb',
			100: '#aac0fe',
			200: '#a3b9f8',
			300: '#728fea',
			400: '#3652ba',
			500: '#1b3bbb',
			600: '#24388a',
			700: '#1B254B',
			800: '#111c44',
			900: '#0b1437'
		},
		gray: {
			100: '#E9E9E9',
			300: '#A8A8A8',
			500: '#8E8C8C'
		}
	},
	shadows: {
		mainShadow: "0px 3.60347px 45.0434px rgba(0, 0, 0, 0.1);"
	},
	styles: {
		global: (props: any) => ({
			body: {
				overflowX: 'hidden',
				// bg: mode('secondaryGray.300', 'navy.900')(props),
				bg: mode('white', 'white'),
				fontFamily: 'Inter',
				letterSpacing: '-0.5px'
			},
			input: {
				color: 'gray.700'
			},
			html: {
				fontFamily: 'Inter'
			}
		})
	}
};
