import { Formik, Field } from "formik";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  CloseButton,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Icon,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useGoogleLogin } from "@react-oauth/google";
import { HSeparator, VSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
import { FcGoogle } from "react-icons/fc";
import { TabWidget } from "components/tab/TabWidget";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAlertMessageRequest } from "store/ui/reducer";
import axios from "axios";
import { useState } from "react";
import { useWindowWidth } from "@react-hook/window-size";
import { axiosService } from "network/axios";
import { getLoggedInUser } from "network/helper";

type SignUpFormInputs = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  company_name: string;
};

const inputStyle = {
  fontSize: "14px",
  lineHeight: "17px",
  textAlign: "center",
  color: "black",
  border: "none !important",
  boxShadow: "none !important",
  bgColor: "transparent",
};

const inputStyleNormal = {
  fontSize: "14px",
  lineHeight: "17px",
  textAlign: "center",
  color: "black",
  border: "1px solid #E9E9E9",
  borderRadius: "50px",
  padding: "15px 30px",
  height: "60px",
  bgColor: "#FFF",
  boxShadow: "none !important",
};

export default function SignUp() {
  const initialValues: SignUpFormInputs = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    company_name: "",
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const windowWidth = useWindowWidth();
  const [showPassword, setShowPassword] = useState(false);

  const [isFetchingProfile, setIsFetchingProfile] = useState(false);
  const [accountType, setAccountType] = useState("individual");
  const [isRegistering, setIsRegistering] = useState(false);
  const [isSucceed, setIsSucceed] = useState(false);
  const textColor = useColorModeValue("navy.700", "white");
  const googleBg = useColorModeValue("white", "white");
  const googleText = useColorModeValue("navy.700", "white");
  const borderColor = "gray.100";
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const bgShadow = useColorModeValue(
    "0px 3.60347px 45.0434px rgba(0, 0, 0, 0.1)",
    "unset"
  );

  const buttonBg = "blue.500";

  const goToSignIn = () => {
    navigate("/auth/sign-in");
  };

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      setIsFetchingProfile(true);
      try {
        const { access_token } = tokenResponse;
        const url = `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${access_token}`;
        const { data } = await axios.get(url);
        if (data) {
          const { email, family_name, given_name, picture, verified_email } =
            data;

          if (!verified_email) {
            setAlertMessageRequest({
              type: "error",
              message:
                "Your gmail account does not verified. please verify your gmail account.",
            });
            return;
          } else {
            const payload: any = {
              first_name: family_name,
              last_name: given_name,
              email: email,
              password: "",
              picture,
              by_google: true,
              type: accountType,
            };
            try {
              setIsRegistering(true);
              await axiosService.post("/user/create", payload);
              setIsSucceed(true);
            } catch (err: any) {
              dispatch(
                setAlertMessageRequest({
                  type: "error",
                  message:
                    "Error in reigstering you. Please contact to support team.",
                })
              );
            } finally {
              setIsRegistering(false);
            }
            // dispatch(signUpRequest(payload));
          }
        }
      } catch (err: any) {
        const message = err?.data?.message;
        console.log(message);
      } finally {
        setIsFetchingProfile(false);
      }
    },
  });

  const onRegister = async (values: any) => {
    const payload = {
      ...values,
      type: accountType,
    };
    try {
      setIsRegistering(true);
      await axiosService.post("/user/create", payload);
      // Start GA Call
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "sign_up",
        user_id: new Date().getTime(),
        // user_id: user.user_id,
        method: "Email",
      });

      const res = await axiosService.post("/login", payload);
      const { access_token, orders, expires_at } = res?.data as any;

      // .then((res) => {
      const expires_timestamp = new Date(expires_at).getTime();
      const user = getLoggedInUser(access_token);
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("expired_at", `${expires_timestamp}`);
      localStorage.setItem("user_details", JSON.stringify(user));
      localStorage.removeItem("order_status");

      if (user.user_role_type_id === "4") {
        navigate("/admin/dashboard");
      } else if (Number(user?.user_role_type_id) === 7) {
        navigate("/admin/unfinished-users");
      } else if (user.user_role_type_id === "1") {
        if (Number(orders) > 0) {
          navigate("/customer/dashboard");
        } else {
          navigate("/customer/dashboard");
        }
      } else if (user.user_role_type_id === "6") {
        navigate("/translator/projects");
      } else if (user.user_role_type_id === "3") {
        navigate("/manager/dashboard");
      } else {
        navigate("/customer/dashboard");
      }
      // Start GA Call
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "login",
        user_id: user.user_id,
        method: "Email",
      });
      // End GA Call
      // })
      // .catch((err: any) => {
      //   const error = err?.data?.messages?.error;
      //   // setAuthError(error);
      //   // setShowError(true);
      // })
      // .finally(() => {
      //   // setIsDoingLogin(false);
      // });
      // End GA Call
      setIsSucceed(true);
    } catch (err: any) {
      dispatch(
        setAlertMessageRequest({
          type: "error",
          message: "Error in reigstering you. Please contact to support team.",
        })
      );
    } finally {
      setIsRegistering(false);
    }
  };

  const onChangeAccounType = (type: number) => {
    if (type === 0) {
      setAccountType("individual");
    } else {
      setAccountType("business");
    }
  };

  return (
    <DefaultAuth>
      <Flex
        maxW="420px"
        w="100%"
        mx="auto"
        my="auto"
        h="fit-content"
        alignItems="start"
        justifyContent="center"
        flexDirection="column"
        borderRadius="18px"
        bgColor={"#FFF"}
        boxShadow={bgShadow}
        sx={{
          form: {
            width: "100%",
          },
        }}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            onRegister(values);
          }}
        >
          {({ handleSubmit, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <Flex
                zIndex="2"
                direction="column"
                padding={{ md: "55px 40px 65px", sm: "40px 30px 16px" }}
                w="full"
                rowGap={{ md: "0px", sm: "16px" }}
              >
                {isSucceed && (
                  <Alert status="success" mb="20px" bgColor={"#0A0"}>
                    <AlertIcon color={"#FFF"} />
                    <Box>
                      <AlertTitle
                        color="#FFF"
                        fontSize="15px"
                        lineHeight="100%"
                      >
                        New Account has created successfully.
                      </AlertTitle>
                      <AlertDescription
                        display="block"
                        mt={2}
                        color={"#FFF"}
                        fontSize="14px"
                        lineHeight="120%"
                      >
                        Welcome to Languex Translation Platfrom!
                      </AlertDescription>
                    </Box>
                    <CloseButton
                      ml="auto"
                      position="relative"
                      color="#FFF"
                      onClick={() => setIsSucceed(false)}
                    />
                  </Alert>
                )}
                {windowWidth >= 768 && (
                  <>
                    <Heading
                      color={textColor}
                      fontFamily="Canela Text"
                      fontSize="30px"
                      lineHeight="135%"
                      mb={"45px"}
                      textAlign="center"
                    >
                      Create an account
                    </Heading>
                    <TabWidget
                      changeAccounType={(e: number) => onChangeAccounType(e)}
                      sx={{
                        mb: "14px",
                      }}
                    />
                    <Button
                      py="15px"
                      px="30px"
                      height="60px"
                      fontSize={"14px"}
                      lineHeight="17px"
                      textTransform={"capitalize"}
                      borderRadius="50px"
                      bg={googleBg}
                      color={googleText}
                      fontWeight="500"
                      border={`1px solid #EFEFEF`}
                      _hover={googleHover}
                      _active={googleActive}
                      _focus={googleActive}
                      onClick={() => login()}
                      isLoading={isFetchingProfile || isRegistering}
                    >
                      Sign up with
                      <Icon
                        as={FcGoogle}
                        w="20px"
                        h="20px"
                        me="10px"
                        ml="10px"
                      />
                    </Button>

                    <Flex align="center" my="9.5px">
                      <HSeparator />
                      <Text
                        fontSize="13px"
                        color="gray.400"
                        p="5px"
                        whiteSpace="nowrap"
                        textTransform="capitalize"
                      >
                        or Sign up with email
                      </Text>
                      <HSeparator />
                    </Flex>
                    <FormControl
                      isInvalid={
                        (!!errors.first_name && touched.first_name) ||
                        (!!errors.last_name && touched.last_name)
                      }
                    >
                      <Flex
                        padding="15px 30px"
                        height="60px"
                        w="full"
                        borderRadius="50px"
                        border="1px solid #E9E9E9"
                        bgColor={"#FFF"}
                      >
                        <Field
                          as={Input}
                          type="text"
                          id="first_name"
                          name="first_name"
                          placeholder="First Name"
                          padding="5px 20px"
                          h="full"
                          border="none"
                          sx={{
                            ...inputStyle,
                            fontWeight: "500",
                          }}
                          _placeholder={{
                            ...inputStyle,
                            fontWeight: "400",
                          }}
                          validate={(value: any) => {
                            let error;
                            if (value.length < 1) {
                              error = "First Name is required.";
                            }
                            return error;
                          }}
                        />
                        <VSeparator />
                        <Field
                          as={Input}
                          type="text"
                          id="last_name"
                          name="last_name"
                          placeholder="Last Name"
                          padding="5px 20px"
                          h="full"
                          border="none"
                          sx={{
                            ...inputStyle,
                            fontWeight: "500",
                          }}
                          _placeholder={{
                            ...inputStyle,
                            fontWeight: "400",
                          }}
                          validate={(value: any) => {
                            let error;
                            if (value.length < 1) {
                              error = "Last Name is required.";
                            }
                            return error;
                          }}
                        />
                      </Flex>
                      <FormErrorMessage
                        mt={1}
                        sx={{
                          color: "#F00",
                          justifyContent: "center",
                        }}
                      >
                        {errors.first_name}
                      </FormErrorMessage>
                      <FormErrorMessage
                        mt={1}
                        sx={{
                          color: "#F00",
                          justifyContent: "center",
                        }}
                      >
                        {errors.last_name}
                      </FormErrorMessage>
                    </FormControl>

                    {accountType === "business" && (
                      <FormControl
                        isInvalid={
                          !!errors.company_name && touched.company_name
                        }
                        mt="14px"
                      >
                        <Field
                          as={Input}
                          type="text"
                          id="company_name"
                          name="company_name"
                          placeholder="Company Name"
                          sx={{
                            ...inputStyleNormal,
                            fontWeight: "500",
                          }}
                          _placeholder={{
                            ...inputStyle,
                            fontWeight: "400",
                          }}
                          validate={(value: any) => {
                            let error;
                            if (value.length < 1) {
                              error = "Company Name is required.";
                            }
                            return error;
                          }}
                        />
                        <FormErrorMessage
                          mt={1}
                          sx={{
                            color: "#F00",
                            justifyContent: "center",
                          }}
                        >
                          {errors.company_name}
                        </FormErrorMessage>
                      </FormControl>
                    )}

                    <FormControl
                      isInvalid={!!errors.email && touched.email}
                      mt="14px"
                    >
                      <Field
                        as={Input}
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email"
                        sx={{
                          ...inputStyleNormal,
                          fontWeight: "500",
                        }}
                        _placeholder={{
                          ...inputStyle,
                          fontWeight: "400",
                        }}
                        validate={(value: any) => {
                          let error;
                          if (value.length < 1) {
                            error = "Email Address is required.";
                          }
                          return error;
                        }}
                      />
                      <FormErrorMessage
                        mt={1}
                        sx={{
                          color: "#F00",
                          justifyContent: "center",
                        }}
                      >
                        {errors.email}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      isInvalid={!!errors.password && touched.password}
                      mt="14px"
                    >
                      <Field
                        as={Input}
                        type={showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        placeholder="Password"
                        sx={{
                          ...inputStyleNormal,
                          fontWeight: "500",
                        }}
                        _placeholder={{
                          ...inputStyle,
                          fontWeight: "400",
                        }}
                        validate={(value: any) => {
                          let error;
                          if (value.length < 1) {
                            error = "Password is required.";
                          } else if (value.length < 6) {
                            error =
                              "Password must contain at least 6 characters.";
                          }
                          return error;
                        }}
                      />
                      <FormErrorMessage
                        mt={1}
                        sx={{
                          color: "#F00",
                          justifyContent: "center",
                        }}
                      >
                        {errors.password}
                      </FormErrorMessage>
                    </FormControl>
                    <Flex
                      justifyContent="center"
                      align="center"
                      mt="10px"
                      mb="20px"
                      sx={{
                        ".chakra-checkbox__label": {
                          fontSize: "14px",
                        },
                      }}
                    >
                      <Checkbox
                        onChange={(e: any) => setShowPassword(e.target.checked)}
                      >
                        Show Password
                      </Checkbox>
                    </Flex>
                  </>
                )}
                {windowWidth < 768 && (
                  <>
                    <Heading
                      color={textColor}
                      fontFamily="Canela Text"
                      fontSize="26px"
                      lineHeight="135%"
                      mb="0px"
                      textTransform="capitalize"
                      textAlign="center"
                    >
                      Create an account
                    </Heading>
                    <TabWidget
                      changeAccounType={(e: number) => onChangeAccounType(e)}
                    />
                    <FormControl isInvalid={!!errors.email && touched.email}>
                      <Field
                        as={Input}
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email"
                        sx={{
                          ...inputStyleNormal,
                          fontWeight: "500",
                        }}
                        _placeholder={{
                          ...inputStyle,
                          fontWeight: "400",
                        }}
                        validate={(value: any) => {
                          let error;
                          if (value.length < 1) {
                            error = "Email Address is required.";
                          }
                          return error;
                        }}
                      />
                      <FormErrorMessage
                        mt={1}
                        sx={{
                          color: "#F00",
                          justifyContent: "center",
                        }}
                      >
                        {errors.email}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      isInvalid={!!errors.first_name && touched.first_name}
                    >
                      <Field
                        as={Input}
                        type="text"
                        id="first_name"
                        name="first_name"
                        placeholder="First Name"
                        padding="5px 20px"
                        h="full"
                        border="none"
                        sx={{
                          ...inputStyleNormal,
                          fontWeight: "500",
                        }}
                        _placeholder={{
                          ...inputStyle,
                          fontWeight: "400",
                        }}
                        validate={(value: any) => {
                          let error;
                          if (value.length < 1) {
                            error = "First Name is required.";
                          }
                          return error;
                        }}
                      />

                      <FormErrorMessage
                        mt={1}
                        sx={{
                          color: "#F00",
                          justifyContent: "center",
                        }}
                      >
                        {errors.first_name}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      isInvalid={!!errors.last_name && touched.last_name}
                    >
                      <Field
                        as={Input}
                        type="text"
                        id="last_name"
                        name="last_name"
                        placeholder="Last Name"
                        padding="5px 20px"
                        h="full"
                        border="none"
                        sx={{
                          ...inputStyleNormal,
                          fontWeight: "500",
                        }}
                        _placeholder={{
                          ...inputStyle,
                          fontWeight: "400",
                        }}
                        validate={(value: any) => {
                          let error;
                          if (value.length < 1) {
                            error = "Last Name is required.";
                          }
                          return error;
                        }}
                      />

                      <FormErrorMessage
                        mt={1}
                        sx={{
                          color: "#F00",
                          justifyContent: "center",
                        }}
                      >
                        {errors.last_name}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      isInvalid={!!errors.password && touched.password}
                    >
                      <Field
                        as={Input}
                        type={showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        placeholder="Password"
                        sx={{
                          ...inputStyleNormal,
                          fontWeight: "500",
                        }}
                        _placeholder={{
                          ...inputStyle,
                          fontWeight: "400",
                        }}
                        validate={(value: any) => {
                          let error;
                          if (value.length < 1) {
                            error = "Password is required.";
                          } else if (value.length < 6) {
                            error =
                              "Password must contain at least 6 characters.";
                          }
                          return error;
                        }}
                      />
                      <FormErrorMessage
                        mt={1}
                        sx={{
                          color: "#F00",
                          justifyContent: "center",
                        }}
                      >
                        {errors.password}
                      </FormErrorMessage>
                    </FormControl>
                    <Flex
                      justifyContent="center"
                      align="center"
                      mb="20px"
                      sx={{
                        ".chakra-checkbox__label": {
                          fontSize: "14px",
                        },
                      }}
                    >
                      <Checkbox
                        onChange={(e: any) => setShowPassword(e.target.checked)}
                      >
                        Show Password
                      </Checkbox>
                    </Flex>

                    <Text
                      color="gray.400"
                      p="5px"
                      fontSize="12px"
                      textAlign="center"
                      textTransform={"uppercase"}
                    >
                      ------------ or ------------
                    </Text>
                    <Button
                      py="15px"
                      px="30px"
                      height="60px"
                      fontSize={"14px"}
                      lineHeight="17px"
                      textTransform={"capitalize"}
                      borderRadius="50px"
                      bg={googleBg}
                      color={googleText}
                      fontWeight="500"
                      border={`1px solid #EFEFEF`}
                      _hover={googleHover}
                      _active={googleActive}
                      _focus={googleActive}
                      onClick={() => login()}
                      isLoading={isFetchingProfile || isRegistering}
                    >
                      Continue with
                      <Icon
                        as={FcGoogle}
                        w="20px"
                        h="20px"
                        me="10px"
                        ml="10px"
                      />
                    </Button>
                  </>
                )}
              </Flex>
              <Flex
                flexDirection={{ md: "row", sm: "column-reverse" }}
                justifyContent="center"
                alignItems="start"
                w={{ md: "full", sm: "calc(100% - 30px)" }}
                rowGap="16px"
                columnGap={"12px"}
                borderTop="1px solid"
                borderColor={borderColor}
                mx={{ md: "0px", sm: "15px" }}
                padding={{ md: "30px 40px", sm: "16px 15px 30px" }}
              >
                <Button
                  fontSize="14px"
                  lineHeight="17px"
                  variant="brand"
                  bgColor="white"
                  color="blue.500"
                  fontWeight="700"
                  padding="14px 54px"
                  h={{ md: "45px", sm: "55px" }}
                  w="100%"
                  borderRadius="90px"
                  onClick={goToSignIn}
                  border={{ md: "none", sm: "1px solid" }}
                  borderColor={buttonBg}
                  _hover={{
                    bgColor: buttonBg,
                    color: "#FFF",
                  }}
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  fontSize="14px"
                  lineHeight="17px"
                  variant="brand"
                  bgColor={buttonBg}
                  fontWeight="700"
                  padding="14px 54px"
                  h={{ md: "45px", sm: "55px" }}
                  w="100%"
                  borderRadius={"90px"}
                  isLoading={isRegistering}
                >
                  Create Account
                </Button>
              </Flex>
            </form>
          )}
        </Formik>
      </Flex>
    </DefaultAuth>
  );
}
