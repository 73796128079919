/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
// Custom components
import Card from "components/card/Card";
import * as React from "react";
import { pad_with_zeroes } from "network/helper";
import IconBox from "components/icons/IconBox";
import { IconCheckedSmall } from "components/icons/Icons";
import { ServiceRowObj } from "../variables/serviceData";

type RowObj = {
  is_checked: number;
  id: number;
  order_id: string;
  order_name: string;
  order_status_name: string;
  translator: string[];
  translation_type: string;
  order_total: number;
  order_status_id: string;
  include_2_day_shipping: string;
  include_expedited_turnaround: string;
  include_mail_hard_copy: string;
  include_notarization: string;
};

const columnHelper = createColumnHelper<RowObj>();

export default function ActiveProjects(props: {
  selectedOrder: any;
  tableData: any;
  serviceItem: ServiceRowObj;
  onSelectOrder: Function;
}) {
  const { tableData, serviceItem, selectedOrder, onSelectOrder } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const textColor = useColorModeValue("black", "black");
  const isAvailableRow = (order: any) => {
    if (serviceItem.id === 1) {
      return Number(order.include_notarization) === 0;
    } else if (serviceItem.id === 2) {
      return (
        Number(order.include_mail_hard_copy || order.include_2_day_shipping) ===
        0
      );
    } else if (serviceItem.id === 3) {
      return Number(order.include_expedited_turnaround) === 0;
    }
  };

  // let defaultData = tableData;
  const defaultData = React.useMemo(() => {
    if (serviceItem.id === 1 || serviceItem.id === 2) {
      return tableData?.filter(
        (it: any) => it.translation_type === "certified"
      );
    }
    return tableData;
  }, [tableData]);

  const columns = [
    columnHelper.accessor("is_checked", {
      id: "is_checked",
      header: () => <></>,
      cell: (info: any) => {
        return (
          <Flex align="center">
            <Box
              w="17px"
              h="17px"
              borderRadius="50%"
              border="1px solid #2F80ED"
              mr="15px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgColor={
                selectedOrder?.order_id === info.row.original.order_id
                  ? "#2F80ED"
                  : "#FFF"
              }
            >
              <IconBox
                w="7px"
                h="5px"
                icon={<IconCheckedSmall w="7px" h="5px" />}
              ></IconBox>
            </Box>
          </Flex>
        );
      },
    }),
    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Project #
        </Text>
      ),
      cell: (info: any) => {
        return (
          <Flex align="center">
            <Text
              color={info.row.getValue("id") === "0012" ? "#F00" : textColor}
              fontSize="14px"
              lineHeight="24px"
              fontWeight="400"
              maxW={{ sm: "200px", md: "unset" }}
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
            >
              {pad_with_zeroes(info.getValue(), 6)}
            </Text>
          </Flex>
        );
      },
    }),

    columnHelper.accessor("order_status_name", {
      id: "order_status_name",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Status
        </Text>
      ),
      cell: (info) => (
        <Text
          color={textColor}
          fontSize="14px"
          lineHeight="24px"
          fontWeight="400"
          width="fit-content"
          height={"34px"}
          whiteSpace="nowrap"
          borderRadius="100px"
          padding="5px 20px"
          bgColor="#F3F5F5"
          textTransform="capitalize"
        >
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("translation_type", {
      id: "translation_type",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Type
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
            textTransform="capitalize"
          >
            {info.getValue() || "-"}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("order_total", {
      id: "order_total",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Price
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
          >
            ${info.getValue() ? info.getValue() : "0.00"}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("include_mail_hard_copy", {
      id: "include_mail_hard_copy",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Two-Day Shipping Delivery
        </Text>
      ),
      cell: (info) => {
        return (
          <Flex align="center">
            <Text
              color={textColor}
              fontSize="14px"
              lineHeight="24px"
              fontWeight="400"
            >
              {Number(info.row.original.include_mail_hard_copy) === 1 ||
              Number(info.row.original.include_2_day_shipping) === 1
                ? "Yes"
                : "No"}
            </Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor("include_expedited_turnaround", {
      id: "include_expedited_turnaround",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Expedite Translation
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
          >
            {Number(info.getValue()) === 1 ? "Yes" : "No"}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("include_notarization", {
      id: "include_notarization",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Notarization
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
          >
            {Number(info.getValue()) === 1 ? "Yes" : "No"}
          </Text>
        </Flex>
      ),
    }),
  ];
  const [data, setData] = React.useState(() => [...defaultData]);
  React.useEffect(() => {
    setData([...defaultData]);
  }, [defaultData]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  return (
    <Card
      flexDirection="column"
      w="calc(100% + 20px)"
      p="0px"
      px="10px"
      ml="-10px"
      overflowX="auto"
    >
      <Box>
        <Table variant="simple" color="gray.500" pb="2" lineHeight="15px">
          <Thead>
            {table.getHeaderGroups().map((headerGroup, index: number) => (
              <Tr key={`header-${index}`}>
                {headerGroup.headers.map((header, _index: number) => {
                  return (
                    <Th
                      key={`header-col-${_index}`}
                      colSpan={header.colSpan}
                      padding="10px"
                      border={"none"}
                      fontFamily="Inter"
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}
                      textTransform={"capitalize"}
                      whiteSpace="nowrap"
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        lineHeight="24px"
                        color="gray.500"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table
              .getRowModel()
              .rows.slice(0, 11)
              .map((row: any, index: number) => {
                return (
                  <React.Fragment key={`trow-${index}`}>
                    <Tr
                      mb={"10px"}
                      position="relative"
                      zIndex={1}
                      cursor={
                        isAvailableRow(row.original) === true
                          ? "pointer"
                          : "not-allowed"
                      }
                      opacity={isAvailableRow(row.original) === true ? 1 : 0.4}
                      onClick={() => {
                        isAvailableRow(row.original) === true
                          ? onSelectOrder(row.original)
                          : console.log("test");
                      }}
                      _after={{
                        content: '""',
                        position: "absolute",
                        border: `1px solid ${
                          selectedOrder?.order_id === row.original.order_id
                            ? "#2F80ED"
                            : "#DCDCDC"
                        }`,
                        borderRadius: "10px",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: "-1",
                        transition: "all .25s ease-in-out",
                      }}
                      _hover={{
                        _after:
                          isAvailableRow(row.original) === true
                            ? {
                                backgroundColor: "#F1F1F1",
                                borderColor: "#F1F1F1",
                                boxShadow: "0px 2px 6px 3px rgba(0, 0, 0, 0.1)",
                              }
                            : "",
                      }}
                      _active={{
                        _after: {
                          boxShadow: "none",
                        },
                      }}
                    >
                      {row
                        .getVisibleCells()
                        .map((cell: any, _index: number) => {
                          return (
                            <Td
                              padding="15px 10px"
                              key={`trow-${index}-td-${_index}`}
                              fontSize={{ sm: "14px" }}
                              border="none"
                              width={
                                cell.column.id === "actionCol"
                                  ? "120px"
                                  : "auto"
                              }
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </Td>
                          );
                        })}
                    </Tr>
                    <Tr>
                      <Td py="7px" border="none"></Td>
                    </Tr>
                  </React.Fragment>
                );
              })}
          </Tbody>
        </Table>
      </Box>
    </Card>
  );
}
