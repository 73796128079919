/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Checkbox, Flex, Input, Text } from "@chakra-ui/react";
import IconBox from "components/icons/IconBox";
import { IconAdd } from "components/icons/Icons";
import _, { isEmpty } from "lodash";
import { pad_with_zeroes } from "network/helper";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cardsSelector, userSelector } from "store/user/selector";
import CardRowItem from "views/customer/order/components/payment/CardRowItem";
import moment from "moment-timezone";
import { loadStripe, Stripe, StripeCardElement } from "@stripe/stripe-js";
import { updateProjectStatus } from "store/customerProject/reducer";

export default function PaymentStep(props: {
  selectedOrder: any;
  onPickPaymentInfo: Function;
}) {
  const { selectedOrder, onPickPaymentInfo } = props;
  const user = useSelector(userSelector);
  const cards = useSelector(cardsSelector);
  const [openCardInputForm, setOpenCardInputForm] = useState(false);
  const [selectedPaymentInfo, setSelectedPaymentInfo] = useState(null);
  const [stripe, setStripe] = useState<Stripe>(null);
  const [cardElement, setCardElement] = useState<StripeCardElement>(null);
  const [cardHolderName, setCardHolderName] = useState("");
  const [cardZipCode, setCardZipCode] = useState("");
  const dispatch = useDispatch();
  const isBusinessAccount = useMemo(() => {
    return !isEmpty(user?.business_id);
  }, [user]);

  useEffect(() => {
    if (cards?.length < 1) {
      setOpenCardInputForm(true);
    } else {
      setOpenCardInputForm(false);
    }
  }, [cards]);

  useEffect(() => {
    onPickPaymentInfo(selectedPaymentInfo);
  }, [selectedPaymentInfo]);

  useEffect(() => {
    if (openCardInputForm) {
      setSelectedPaymentInfo(null);

      dispatch(
        updateProjectStatus({
          saveCardAsDefault: false,
          paymentOption: null,
        })
      );
      initStripe();
    }
  }, [openCardInputForm]);

  const initStripe = async () => {
    let stripe = await loadStripe(
      // "pk_test_51NAyBnCsYOJWbJy3pNGgDnJ71UNGvBUXLikN2erIFcV6cUZlZxbVyWk8gB4AWlIBHCZFZeEexUyzDtfrkUHYMFKN002ZIoLdVq"
      "pk_live_51IEJJGJhA5ApiXVVzZnFak5wXrk8zGw7E1mDmbntMgUg5ZbERdHPmc5a0FfBqRuN6b55JuIzvnwShsSPs3eY4iQR00mCyIwUgj"
    );
    var elements = stripe.elements();
    let cardElement = elements.create("card", {
      iconStyle: "default",
      hidePostalCode: true,
      style: {
        base: {
          iconColor: "#000",
          color: "#000",
          fontWeight: 400,
          fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
          fontSize: "16px",
          lineHeight: "30px",
          letterSpacing: "normal",
          fontSmoothing: "antialiased",

          "::placeholder": {
            color: "#777",
          },
          ":-webkit-autofill": {
            color: "#777",
          },
        },
        invalid: {
          iconColor: "#F00",
          color: "#F00",
        },
      },
    });
    cardElement.mount("#card-element");
    cardElement.on("change", function (event) {
      if (event.complete) {
        setStripe(stripe);
        setCardElement(cardElement);
      } else if (event.error) {
        setStripe(null);
        setCardElement(null);
      }
    });
  };

  useEffect(() => {
    if (
      stripe &&
      cardElement &&
      !_.isEmpty(cardHolderName) &&
      !_.isEmpty(cardZipCode)
    ) {
      setSelectedPaymentInfo({
        cardHolderName,
        cardZipCode,
        stripe,
        cardElement,
      });
    } else {
      setSelectedPaymentInfo(null);
    }
  }, [cardHolderName, cardZipCode, stripe, cardElement]);

  const onChangeCheckBox = (status: boolean) => {
    dispatch(
      updateProjectStatus({
        paymentOption: null,
        saveCardAsDefault: status,
      })
    );
  };
  return (
    <>
      <Flex>
        <Box w="60%" pr="25px">
          <>
            <CardRowItem
              captureSelectedCard={selectedPaymentInfo}
              onCaptureSelectedCard={(e: any) => {
                setOpenCardInputForm(false);
                setSelectedPaymentInfo(e);
              }}
              onSelect={() => {}}
            />
            {!openCardInputForm && isBusinessAccount && (
              <Flex
                alignItems="center"
                w="fit-content"
                cursor="pointer"
                onClick={(e) => {
                  setSelectedPaymentInfo(null);
                  setOpenCardInputForm(true);
                }}
              >
                <IconBox
                  w="22px"
                  h="22px"
                  mr="10px"
                  icon={<IconAdd w="22px" h="22px" />}
                />
                <Text
                  fontSize="14px"
                  lineHeight="22px"
                  textDecoration="underline"
                >
                  Add a card or bank
                </Text>
              </Flex>
            )}
            {openCardInputForm && isBusinessAccount && (
              <Box
                padding={{ md: "25px", base: "20px" }}
                border="1px solid #DCDCDC"
                borderRadius="10px"
              >
                <Box
                  padding="15px"
                  borderRadius="50px"
                  border="1px solid #E9E9E9"
                >
                  <Box id="card-element"></Box>
                </Box>
                <Flex id="name-on-card" mt="20px" columnGap="20px">
                  <Input
                    name="name-on-card"
                    borderRadius="50px"
                    border="1px solid #E9E9E9"
                    background="#FFF"
                    sx={{
                      width: "65%",
                      padding: "15px 20px !important",
                      height: "60px",
                    }}
                    placeholder="Name On Card"
                    _placeholder={{
                      color: "#777",
                      fontSize: "16px !important",
                    }}
                    value={cardHolderName}
                    onChange={(e) => {
                      setCardHolderName(e.target.value);
                    }}
                  />
                  <Input
                    name="postal"
                    borderRadius="50px"
                    border="1px solid #E9E9E9 !important"
                    sx={{
                      width: "35%",
                      padding: "15px 20px !important",
                      height: "60px",
                    }}
                    placeholder="Zipcode"
                    _placeholder={{
                      color: "#777",
                      fontSize: "16px !important",
                    }}
                    value={cardZipCode}
                    onChange={(e) => {
                      setCardZipCode(e.target.value);
                    }}
                  />
                </Flex>
                <Box mt="10px">
                  <Checkbox
                    sx={{
                      ".chakra-checkbox__control": {
                        borderColor: "#2F80ED",
                      },
                    }}
                    onChange={(e: any) => onChangeCheckBox(e.target.checked)}
                  >
                    Use this card information in future
                  </Checkbox>
                </Box>
              </Box>
            )}
          </>
        </Box>
        <Box w="40%">
          <Flex flexDir="column">
            <Flex
              justifyContent="space-between"
              alignItems="center"
              w="100%"
              py="7px"
            >
              <Text
                fontSize="12px"
                lineHeight="14px"
                fontWeight="400"
                color="#8e8c8c"
              >
                Order ID
              </Text>
              <Text
                fontSize="14px"
                lineHeight="17px"
                fontWeight="400"
                color="#000"
                ml="auto"
              >
                {pad_with_zeroes(selectedOrder.order_id, 6)}
              </Text>
            </Flex>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              w="100%"
              py="7px"
            >
              <Text
                fontSize="12px"
                lineHeight="14px"
                fontWeight="400"
                color="#8e8c8c"
              >
                Translation Type
              </Text>
              <Text
                fontSize="14px"
                lineHeight="17px"
                fontWeight="400"
                color="#000"
                ml="auto"
                textTransform="capitalize"
              >
                {selectedOrder.translation_type}
              </Text>
            </Flex>
            {selectedOrder.translation_type === "certified" && (
              <Flex
                justifyContent="space-between"
                alignItems="center"
                w="100%"
                py="7px"
              >
                <Text
                  fontSize="12px"
                  lineHeight="14px"
                  fontWeight="400"
                  color="#8e8c8c"
                >
                  Total Page Count
                </Text>
                <Text
                  fontSize="14px"
                  lineHeight="17px"
                  fontWeight="400"
                  color="#000"
                  ml="auto"
                  textTransform="capitalize"
                >
                  {selectedOrder.page_count}
                </Text>
              </Flex>
            )}
            {selectedOrder.translation_type === "standard" && (
              <Flex
                justifyContent="space-between"
                alignItems="center"
                w="100%"
                py="7px"
              >
                <Text
                  fontSize="12px"
                  lineHeight="14px"
                  fontWeight="400"
                  color="#8e8c8c"
                >
                  Total Word Count
                </Text>
                <Text
                  fontSize="14px"
                  lineHeight="17px"
                  fontWeight="400"
                  color="#000"
                  ml="auto"
                  textTransform="capitalize"
                >
                  {selectedOrder.words_count}
                </Text>
              </Flex>
            )}
            <Flex
              justifyContent="space-between"
              alignItems="center"
              w="100%"
              py="7px"
            >
              <Text
                fontSize="12px"
                lineHeight="14px"
                fontWeight="400"
                color="#8e8c8c"
              >
                Source Languages
              </Text>
              <Text
                fontSize="14px"
                lineHeight="17px"
                fontWeight="400"
                color="#000"
                ml="auto"
              >
                {selectedOrder.translated_from}
              </Text>
            </Flex>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              w="100%"
              py="7px"
            >
              <Text
                fontSize="12px"
                lineHeight="14px"
                fontWeight="400"
                color="#8e8c8c"
              >
                Target Languages
              </Text>
              <Text
                fontSize="14px"
                lineHeight="17px"
                fontWeight="400"
                color="#000"
                ml="auto"
              >
                {selectedOrder.translated_to}
              </Text>
            </Flex>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              w="100%"
              py="7px"
            >
              <Text
                fontSize="12px"
                lineHeight="14px"
                fontWeight="400"
                color="#8e8c8c"
              >
                Order Status
              </Text>
              <Text
                fontSize="14px"
                lineHeight="17px"
                fontWeight="400"
                color="#000"
                ml="auto"
              >
                {selectedOrder.order_status_name}
              </Text>
            </Flex>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              w="100%"
              py="7px"
            >
              <Text
                fontSize="12px"
                lineHeight="14px"
                fontWeight="400"
                color="#8e8c8c"
              >
                Order Due at
              </Text>
              <Text
                fontSize="14px"
                lineHeight="17px"
                fontWeight="400"
                color="#000"
                ml="auto"
              >
                {moment(new Date(selectedOrder.order_due_at)).format(
                  "MMM. DD - hh:mm a"
                )}
              </Text>
            </Flex>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              w="100%"
              py="7px"
            >
              <Text
                fontSize="12px"
                lineHeight="14px"
                fontWeight="400"
                color="#8e8c8c"
              >
                Order Total
              </Text>
              <Text
                fontSize="14px"
                lineHeight="17px"
                fontWeight="400"
                color="#000"
                ml="auto"
              >
                ${selectedOrder.order_total}
              </Text>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </>
  );
}
