/* eslint-disable react-hooks/exhaustive-deps */
import {
  BellIcon,
  ChevronRightIcon,
  InfoOutlineIcon,
  StarIcon,
  TimeIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Progress,
  Skeleton,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import IconBox from "components/icons/IconBox";
import { IconTeam } from "components/icons/Icons";
import { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment-timezone";
import { Doughnut } from "react-chartjs-2";
import {
  Chart,
  ArcElement,
  Legend,
  Tooltip,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "store/user/selector";
import _, { isEmpty } from "lodash";
import { axiosService } from "network/axios";
import {
  fetchStatusName,
  generateQueryParameters,
  getProjectStatusAsPercent,
  groupByAndCount,
  pad_with_zeroes,
} from "network/helper";
import { useNavigate } from "react-router-dom";
import { setShowProfileDetailsStatus } from "store/ui/reducer";
import { fetchTeamUsersRequest } from "store/user/reducer";
import Collaborators from "../profile/components/team/Collaborators";
import CustomPaginations from "components/pagination/CustomPaginations";
Chart.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
);

export default function CustomerDashboard() {
  const boxShadow = "0px 4px 10px rgba(0, 0, 0, 0.1)";
  const [dashboardIndex] = useState(-1);
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const [modalOption, setModalOption] = useState(1); // 1: Unread Message, 2: Team Management

  const [projectData, setProjectData] = useState(null);
  const [dashboardTile, setDashboardTile] = useState(null);
  const [lastUpdatedOrders, setLastUpdatedOrders] = useState(null);
  const [notifications, setNotifications] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(20);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [isFetchingAll, setIsFetchingAll] = useState(false);
  const [totalNotifications, setTotalNotifications] = useState([]);

  const queryParam = useMemo(() => {
    return {
      page: currentPage,
      limit: pageLimit,
    };
  }, [currentPage, pageLimit]);

  const user = useSelector(userSelector);
  const isBusiness = useMemo(() => {
    dispatch(
      fetchTeamUsersRequest({
        user_id: user?.user_id,
      })
    );
    return user?.is_business;
  }, [user]);

  const isAdmin = useMemo(() => {
    return user?.is_admin;
  }, [user]);

  useEffect(() => {
    if (!isEmpty(user?.user_id)) {
      fetchSummary(Number(user?.user_id));
    }
  }, [user?.user_id]);

  const fetchSummary = async (user_id: number) => {
    try {
      setIsFetching(true);
      const { data }: any = await axiosService.get(
        `/projects/customer/summary/${user.user_id}`
      );

      // const processedResult = [];
      const weekResults = data?.last1WeekData ?? [];
      // Get the current date
      const currentDate = new Date();
      const labels = [];
      const values = [];
      // Loop through the last 7 days
      for (let i = 6; i >= 0; i--) {
        // Calculate the date for the current iteration
        const date = new Date(currentDate);
        date.setDate(currentDate.getDate() - i);

        // Format the date to 'yyyy-mm-dd'
        const formattedDate = date.toISOString().split("T")[0];

        // Find the corresponding result in the fetched data
        const matchingResult = weekResults.find(
          (item: any) => item.date_only === formattedDate
        );

        const count_of_records = matchingResult
          ? matchingResult.count_of_records
          : 0;

        labels.push(formattedDate);
        values.push(count_of_records);
      }

      const groupLanauges = Object.keys(
        groupByAndCount(data?.languages ?? [])
      )?.join(", ");

      setProjectData({
        labels: ["Drafts", "Active", "In Reviews", "Completed"],
        datasets: [
          {
            label: "Projects",
            data: [
              data?.draftProjects ?? 0,
              data?.activeProjects ?? 0,
              data?.reviewsProjects ?? 0,
              data?.completedProjects ?? 0,
            ],
            backgroundColor: [
              "rgba(255, 99, 132, 0.6)",
              "rgba(54, 162, 235, 0.6)",
              "rgba(255, 206, 86, 0.6)",
              "rgba(75, 192, 192, 0.6)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
            ],
            borderWidth: 1,
          },
        ],
      });

      setDashboardTile({
        totalOrders: data?.totalOrders ?? 0,
        notifications: data?.notificationsCnt ?? 0,
        lastVisited: "2023-12-11 09:12:15",
        mostAppearances: groupLanauges,
      });

      setLastUpdatedOrders(data?.latestOrders ?? []);
      setNotifications(data?.notifications ?? []);
    } catch (err) {
      console.log(err);
    } finally {
      setIsFetching(false);
    }
  };

  const goToOrdersPage = () => {
    navigate("/customer/projects");
  };

  const onClickViewAllMessages = () => {
    setModalOption(1);
    onOpen();
  };

  const onClickAddTeamMember = () => {
    setModalOption(2);
    onOpen();
  };

  const openProjectDetails = (order_id: string) => {
    navigate(`/customer/projects#${order_id}`);
  };

  const openTeamInformation = () => {
    localStorage.setItem("view_team", "1");
    dispatch(
      setShowProfileDetailsStatus({
        status: true,
      })
    );
  };

  const fetchResults = useCallback(async () => {
    setIsFetchingAll(true);
    const params = generateQueryParameters(queryParam);
    try {
      const resp: any = await axiosService.get(
        `/projects/notifications/${user.user_id}?${params}`
      );
      const { results, total_rows } = resp?.data;
      setTotalNotifications(
        results?.map((it: any) => ({
          ...it,
          id: it?.order_id,
        }))
      );

      setTotalPages(
        total_rows / pageLimit > Math.floor(total_rows / pageLimit)
          ? Math.floor(total_rows / pageLimit) + 1
          : Math.floor(total_rows / pageLimit)
      );
      setTotalRows(total_rows);
    } catch (err) {
      setTotalNotifications([]);
    } finally {
      setIsFetchingAll(false);
    }
  }, [queryParam]);

  useEffect(() => {
    fetchResults();
  }, [queryParam]);

  return (
    <Box pt={{ base: "60px", md: "80px", xl: "80px" }}>
      {(isBusiness || isAdmin) && (
        <Box height="inherit" mb="20px">
          <Flex
            flexWrap="wrap"
            alignItems="center"
            w="fit-content"
            boxShadow={boxShadow}
            padding="20px"
            borderRadius="10px"
            bgColor={dashboardIndex === 0 ? "#2F80ED" : "#FFF"}
            color={dashboardIndex === 0 ? "#FFF" : "#000"}
            cursor="pointer"
          >
            <IconBox
              w="50px"
              h="50px"
              sx={{
                path: {
                  fill: dashboardIndex === 0 ? "#FFF" : "#000",
                },
              }}
              icon={<InfoOutlineIcon w="50px" h="50px" />}
            />
            <Box ml="10px">
              <Text fontSize="16px">
                Account Type: <b>{isBusiness ? "Business" : "Individual"}</b>
              </Text>
              {isAdmin && (
                <Text fontSize="16px" mt="4px">
                  Role: <b>Admin</b>
                </Text>
              )}
              <Flex mt="10px" columnGap="10px" rowGap="5px">
                <Button
                  type="button"
                  w="fit-content"
                  h="fit-content"
                  padding="10px"
                  borderRadius="0px"
                  bgColor="#2F80ED"
                  color="#FFF"
                  fontSize="12px"
                  lineHeight="12px"
                  fontWeight="bold"
                  onClick={() => {
                    onClickAddTeamMember();
                  }}
                >
                  Add New Team Member
                </Button>
                <Button
                  type="button"
                  w="fit-content"
                  h="fit-content"
                  padding="10px"
                  borderRadius="0px"
                  bgColor="#2F80ED"
                  color="#FFF"
                  fontSize="12px"
                  lineHeight="12px"
                  fontWeight="bold"
                  onClick={() => {
                    openTeamInformation();
                  }}
                >
                  View Team Details
                </Button>
              </Flex>
            </Box>
          </Flex>
        </Box>
      )}
      {!isBusiness && !isAdmin && (
        <Box height="inherit" mb="20px">
          <Flex
            flexWrap="wrap"
            alignItems="center"
            w="fit-content"
            boxShadow={boxShadow}
            padding="20px"
            borderRadius="10px"
            bgColor={dashboardIndex === 0 ? "#2F80ED" : "#FFF"}
            color={dashboardIndex === 0 ? "#FFF" : "#000"}
            cursor="pointer"
          >
            <IconBox
              w="50px"
              h="50px"
              sx={{
                path: {
                  fill: dashboardIndex === 0 ? "#FFF" : "#000",
                },
              }}
              icon={<InfoOutlineIcon w="50px" h="50px" />}
            />
            <Box ml="10px">
              <Text fontSize="16px">
                Account Type: <b>Individual</b>
              </Text>
              <Text fontSize="16px" mt="4px">
                Role: <b>Standard</b>
              </Text>
              <Flex mt="10px" columnGap="10px" rowGap="5px">
                <Button
                  type="button"
                  w="fit-content"
                  h="fit-content"
                  padding="10px"
                  borderRadius="0px"
                  bgColor="#2F80ED"
                  color="#FFF"
                  fontSize="12px"
                  lineHeight="12px"
                  fontWeight="bold"
                  onClick={() => {
                    openTeamInformation();
                  }}
                >
                  View Team Details
                </Button>
              </Flex>
            </Box>
          </Flex>
        </Box>
      )}
      <Box gap="20px" display="flex" flexWrap="wrap">
        <Box
          height="inherit"
          flexBasis={{ md: "unset", "3sm": "calc(50% - 10px)", base: "100%" }}
        >
          <Flex
            flexWrap="wrap"
            alignItems="center"
            boxShadow={boxShadow}
            padding="20px"
            borderRadius="10px"
            bgColor={dashboardIndex === 0 ? "#2F80ED" : "#FFF"}
            color={dashboardIndex === 0 ? "#FFF" : "#000"}
            cursor="pointer"
          >
            <IconBox
              w="50px"
              h="50px"
              sx={{
                path: {
                  fill: dashboardIndex === 0 ? "#FFF" : "#000",
                },
              }}
              icon={<IconTeam w="50px" h="50px" />}
            />
            <Box ml="10px">
              <Text fontSize="16px">Total Orders</Text>
              <Text
                fontSize={{ md: "24px", base: "20px" }}
                fontWeight="bold"
                lineHeight="140%"
              >
                {dashboardTile?.totalOrders ?? 0}
              </Text>
            </Box>
          </Flex>
        </Box>
        <Box
          height="inherit"
          flexBasis={{ md: "unset", "3sm": "calc(50% - 10px)", base: "100%" }}
        >
          <Flex
            flexWrap="wrap"
            alignItems="center"
            boxShadow={boxShadow}
            cursor="pointer"
            padding="20px"
            borderRadius="10px"
            bgColor={dashboardIndex === 1 ? "#2F80ED" : "#FFF"}
            color={dashboardIndex === 1 ? "#FFF" : "#000"}
          >
            <IconBox w="50px" h="50px" icon={<TimeIcon w="40px" h="40px" />} />
            <Box ml="10px">
              <Text fontSize="16px">Last Visited</Text>
              <Text
                fontSize={{ md: "24px", base: "20px" }}
                fontWeight="bold"
                lineHeight="140%"
              >
                {dashboardTile?.lastVisited ?? "-"}
              </Text>
            </Box>
          </Flex>
        </Box>
        <Box
          height="inherit"
          flexBasis={{ md: "unset", "3sm": "calc(50% - 10px)", base: "100%" }}
        >
          <Flex
            flexWrap="wrap"
            alignItems="center"
            boxShadow={boxShadow}
            padding="20px"
            borderRadius="10px"
            bgColor="#FFF"
          >
            <IconBox w="50px" h="50px" icon={<StarIcon w="40px" h="40px" />} />
            <Box ml="10px">
              <Text fontSize="16px">Most Used Languages</Text>
              <Text
                fontSize={{ md: "24px", base: "20px" }}
                fontWeight="bold"
                lineHeight="140%"
              >
                {dashboardTile?.mostAppearances ?? ""}
              </Text>
            </Box>
          </Flex>
        </Box>
        <Box
          height="inherit"
          flexBasis={{ md: "unset", "3sm": "calc(50% - 10px)", base: "100%" }}
        >
          <Flex
            flexWrap="wrap"
            alignItems="center"
            boxShadow={boxShadow}
            padding="20px"
            borderRadius="10px"
            bgColor="#FFF"
          >
            <IconBox w="50px" h="50px" icon={<BellIcon w="40px" h="40px" />} />
            <Box ml="10px">
              <Text fontSize="16px">Notifications</Text>
              <Text
                fontSize={{ md: "24px", base: "20px" }}
                fontWeight="bold"
                lineHeight="140%"
                color="#F00"
              >
                {dashboardTile?.notifications ?? 0}
              </Text>
            </Box>
          </Flex>
        </Box>
      </Box>

      <Grid
        gap="10px"
        templateColumns={{ lg: "repeat(2, 1fr)", base: "repeat(1, 1fr)" }}
        mt="30px"
      >
        <GridItem w="100%">
          <Box
            boxShadow={boxShadow}
            padding="30px"
            borderRadius="10px"
            height="100%"
          >
            <Text fontSize="20px">Latest Updates on Orders</Text>
            <Box
              maxW={{ md: "unset", base: "calc(100vw - 100px)" }}
              overflow="auto"
            >
              <Table mt="20px" fontSize="14px">
                <Thead>
                  <Th padding="10px" whiteSpace="nowrap">
                    #Order
                  </Th>
                  <Th padding="10px" whiteSpace="nowrap">
                    Status
                  </Th>
                  <Th padding="10px" whiteSpace="nowrap">
                    Project Manager
                  </Th>
                  <Th padding="10px" whiteSpace="nowrap">
                    % Done
                  </Th>
                </Thead>
                <Tbody>
                  {!isFetching &&
                    lastUpdatedOrders?.length > 0 &&
                    lastUpdatedOrders?.map((it: any, index: number) => (
                      <Tr key={index}>
                        <Td padding="11px" whiteSpace="nowrap">
                          #{pad_with_zeroes(it?.order_id, 6)}
                        </Td>
                        <Td padding="11px" whiteSpace="nowrap">
                          {fetchStatusName(Number(it?.order_status_id))}
                        </Td>
                        <Td padding="11px" whiteSpace="nowrap">
                          {it?.managers?.length > 0
                            ? `${[
                                it?.managers[0]?.first_name,
                                it?.managers[0]?.last_name,
                              ].join(" ")}`
                            : "Not Assigned"}
                        </Td>
                        <Td padding="11px" whiteSpace="nowrap">
                          <Progress
                            colorScheme="green"
                            size="sm"
                            width="100%"
                            value={getProjectStatusAsPercent(it?.documents)}
                          />
                        </Td>
                      </Tr>
                    ))}
                  {isFetching &&
                    _.range(0, 5).map((it) => (
                      <Tr key={it}>
                        <Td padding="10px">
                          <Skeleton height="22px" width="100%"></Skeleton>
                        </Td>
                        <Td padding="10px">
                          <Skeleton height="22px" width="100%"></Skeleton>
                        </Td>
                        <Td padding="10px">
                          <Skeleton height="22px" width="100%"></Skeleton>
                        </Td>
                        <Td padding="10px">
                          <Skeleton height="22px" width="100%"></Skeleton>
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </Box>
            <Flex>
              <Button
                type="button"
                w="fit-content"
                height="44px"
                padding="13px 26px"
                borderRadius="0px"
                bgColor="#2F80ED"
                color="#FFF"
                fontSize="14px"
                lineHeight="14px"
                fontWeight="bold"
                mt="20px"
                onClick={() => {
                  goToOrdersPage();
                }}
              >
                View All
              </Button>
            </Flex>
          </Box>
        </GridItem>
        <GridItem w="100%">
          <Box
            boxShadow={boxShadow}
            padding="30px"
            borderRadius="10px"
            height="100%"
            w="100%"
          >
            <Text fontSize="20px">Unread Messages</Text>
            <Box
              maxW={{ md: "unset", base: "calc(100vw - 100px)" }}
              overflow="auto"
            >
              <Table mt="20px" fontSize="14px" maxWidth="100%">
                <Thead>
                  <Th padding="10px">#Order</Th>
                  <Th padding="10px">Time</Th>
                  <Th padding="10px">From</Th>
                  <Th padding="10px">Message</Th>
                  <Th></Th>
                </Thead>
                <Tbody>
                  {!isFetching &&
                    notifications?.length > 0 &&
                    notifications.slice(0, 5)?.map((it: any, index: number) => (
                      <Tr key={index}>
                        <Td padding="10px" whiteSpace="nowrap">
                          #{pad_with_zeroes(it.order_id, 6)}
                        </Td>
                        <Td padding="10px" whiteSpace="nowrap">
                          {moment(new Date(it.created_at)).format(
                            "MM/DD/YY HH:mm"
                          )}
                        </Td>
                        <Td padding="10px" whiteSpace="nowrap">
                          {it.sender ?? "Unknown"}
                        </Td>
                        <Td padding="10px" width="100%">
                          <Text
                            whiteSpace="nowrap"
                            textOverflow="ellipsis"
                            overflow="hidden"
                            width="100px"
                          >
                            {it.message}
                          </Text>
                        </Td>
                        <Td>
                          <IconBox
                            cursor="pointer"
                            w="20px"
                            h="10px"
                            onClick={() => {
                              openProjectDetails(it?.order_id);
                            }}
                            icon={<ChevronRightIcon w="20px" h="20px" />}
                          />
                        </Td>
                      </Tr>
                    ))}
                  {isFetching &&
                    _.range(0, 5).map((it) => (
                      <Tr key={it}>
                        <Td padding="10px">
                          <Skeleton height="22px" width="100%"></Skeleton>
                        </Td>
                        <Td padding="10px">
                          <Skeleton height="22px" width="100%"></Skeleton>
                        </Td>
                        <Td padding="10px">
                          <Skeleton height="22px" width="100%"></Skeleton>
                        </Td>
                        <Td padding="10px">
                          <Skeleton height="22px" width="100%"></Skeleton>
                        </Td>
                        <Td padding="10px">
                          <Skeleton height="22px" width="100%"></Skeleton>
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </Box>

            <Flex>
              <Button
                type="button"
                w="fit-content"
                height="44px"
                padding="13px 26px"
                borderRadius="0px"
                bgColor="#2F80ED"
                color="#FFF"
                fontSize="14px"
                lineHeight="14px"
                fontWeight="bold"
                onClick={() => {
                  onClickViewAllMessages();
                }}
                mt="20px"
              >
                View All
              </Button>
            </Flex>
          </Box>
        </GridItem>
      </Grid>
      <Grid
        gap="10px"
        templateColumns={{ md: "repeat(1, 1fr)", base: "repeat(1, 1fr)" }}
        mb="20px"
      >
        <GridItem w="100%" display="flex" flexDir="column" mt="30px">
          <Box
            borderRadius="10px"
            w="fit-content"
            minW={{ md: 500, base: "100%" }}
          >
            {(isFetching ||
              (!isFetching &&
                projectData &&
                dashboardTile?.totalOrders > 0)) && (
              <Text
                textAlign="left"
                mb="30px"
                fontSize="20px"
                pl={{ md: "120px", base: "0px" }}
              >
                Orders By Status
              </Text>
            )}

            {isFetching && (
              <Spinner color="#2F80ED" width={"50px"} height="50px"></Spinner>
            )}
            {!isFetching && projectData && dashboardTile?.totalOrders > 0 && (
              <Box
                sx={{
                  maxW: { md: 500, base: "100%" },
                  minW: { md: 500, base: "100%" },
                }}
              >
                <Doughnut
                  data={projectData}
                  options={{
                    responsive: true,
                    layout: {
                      autoPadding: true,
                    },
                    aspectRatio: 2,
                    plugins: {
                      legend: {
                        position: "right",
                      },
                    },
                  }}
                />
              </Box>
            )}
          </Box>
        </GridItem>
      </Grid>

      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent>
          {modalOption === 1 && (
            <>
              <ModalHeader>
                <Text textAlign="center">All Unread Messages</Text>
              </ModalHeader>
              <ModalCloseButton onClick={onClose} />
              <ModalBody pb="30px">
                <Table mt="20px" fontSize="14px" maxWidth="100%">
                  <Thead>
                    <Th padding="10px">#Order</Th>
                    <Th padding="10px">Time</Th>
                    <Th padding="10px">From</Th>
                    <Th padding="10px">Message</Th>
                    <Th></Th>
                  </Thead>
                  <Tbody>
                    {!isFetchingAll &&
                      totalNotifications?.length > 0 &&
                      totalNotifications?.map((it: any, index: number) => (
                        <Tr key={index}>
                          <Td padding="10px" whiteSpace="nowrap">
                            #{pad_with_zeroes(it.order_id, 6)}
                          </Td>
                          <Td padding="10px" whiteSpace="nowrap">
                            {moment(new Date(it.created_at)).format(
                              "MM/DD/YY HH:mm"
                            )}
                          </Td>
                          <Td
                            padding="10px"
                            whiteSpace="nowrap"
                            maxW="150px"
                            overflow="hidden"
                          >
                            {it.sender ?? "Unknown"}
                          </Td>
                          <Td padding="10px" width="100%">
                            <Text
                              whiteSpace="nowrap"
                              textOverflow="ellipsis"
                              overflow="hidden"
                              width="100px"
                            >
                              {it.message}
                            </Text>
                          </Td>
                          <Td>
                            <IconBox
                              cursor="pointer"
                              w="20px"
                              h="10px"
                              onClick={() => {
                                openProjectDetails(it?.order_id);
                              }}
                              icon={<ChevronRightIcon w="20px" h="20px" />}
                            />
                          </Td>
                        </Tr>
                      ))}
                    {isFetchingAll &&
                      _.range(0, pageLimit).map((it) => (
                        <Tr key={it}>
                          <Td padding="10px">
                            <Skeleton height="22px" width="100%"></Skeleton>
                          </Td>
                          <Td padding="10px">
                            <Skeleton height="22px" width="100%"></Skeleton>
                          </Td>
                          <Td padding="10px">
                            <Skeleton height="22px" width="100%"></Skeleton>
                          </Td>
                          <Td padding="10px">
                            <Skeleton height="22px" width="100%"></Skeleton>
                          </Td>
                          <Td padding="10px">
                            <Skeleton height="22px" width="100%"></Skeleton>
                          </Td>
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
                <CustomPaginations
                  totalCount={totalRows}
                  totalPages={totalPages}
                  currentPage={currentPage}
                  perPage={pageLimit}
                  onSetPageLimit={(e: number) => {
                    setPageLimit(e);
                    setCurrentPage(1);
                  }}
                  onChangePage={(e: number) => {
                    setCurrentPage(e);
                  }}
                />
              </ModalBody>
            </>
          )}
          {modalOption === 2 && (
            <>
              <ModalHeader>
                <Text textAlign="center">Team Members</Text>
              </ModalHeader>
              <ModalCloseButton onClick={onClose} />
              <ModalBody pb="30px">
                <Collaborators />
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </Box>
  );
}
