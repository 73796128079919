import { addDays, format, getMonth, getWeek, getYear } from "date-fns";
import jwt_decode from "jwt-decode";
import moment from "moment-timezone";
import { prices } from "views/customer/main/variables/data";

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getLoggedInUser(access_token: string = null) {
  try {
    if (!access_token) {
      const jwtToken = localStorage.getItem("access_token");
      const decoded: any = jwt_decode(jwtToken);
      return decoded.user;
    } else {
      const decoded: any = jwt_decode(access_token);
      return decoded.user;
    }
  } catch (e: any) {
    return null;
  }
}

function AmexCardnumber(cardNumber: string) {
  var cardno = /^(?:3[47][0-9]{13})$/;
  return cardno.test(cardNumber);
}

function VisaCardnumber(cardNumber: string) {
  var cardno = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
  return cardno.test(cardNumber);
}

function MasterCardnumber(cardNumber: string) {
  var cardno = /^(?:5[1-5][0-9]{14})$/;
  return cardno.test(cardNumber);
}

function DiscoverCardnumber(cardNumber: string) {
  var cardno = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
  return cardno.test(cardNumber);
}

export function IsValidCreditCardNumber(cardNumber: string) {
  var cardType = null;
  let number = cardNumber.replaceAll("-", "");
  if (VisaCardnumber(number)) {
    cardType = "visa";
  } else if (MasterCardnumber(number)) {
    cardType = "mastercard";
  } else if (AmexCardnumber(number)) {
    cardType = "americanexpress";
  } else if (DiscoverCardnumber(number)) {
    cardType = "discover";
  }
  return cardType;
}

export function filterOrdersByName(orders: any[], searchQuery: string) {
  if (orders?.length < 1) {
    return [];
  }

  return searchQuery?.trim().length > 0
    ? orders?.filter(
        (it) =>
          it?.order_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          pad_with_zeroes(it?.order_id, 6)?.includes(
            searchQuery.toLowerCase()
          ) ||
          it?.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          `${it?.first_name} ${it?.last_name}`
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase())
      )
    : orders;
}

export function filterUsersByName(users: any[], searchQuery: string) {
  return searchQuery?.trim().length > 0
    ? users?.filter(
        (it) =>
          it?.first_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          it?.last_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          it?.email?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : users;
}

export function pad_with_zeroes(number: string, length: number) {
  var my_string = "" + number;
  while (my_string.length < length) {
    my_string = "0" + my_string;
  }

  return my_string;
}

export function fetchUnreadMessagesCnt(
  unreadReports: any[],
  projectId: number
) {
  const item = unreadReports?.find(
    (it) => Number(it?.order_id) === Number(projectId)
  );
  return Number(item?.cnt || 0);
}

export function fetchLanguagesExcerpt(language: string) {
  let languageList = language?.split(",");
  if (languageList?.length > 1) {
    return `${languageList[0]} +${languageList.length - 1}`;
  } else {
    return language;
  }
}

export const fetchStatusName = (status_id: number) => {
  if (status_id === 2) {
    return "Paid";
  } else if (status_id === 3) {
    return "Assigned";
  } else if (status_id === 4) {
    return "In Progress";
  } else if (status_id === 5) {
    return "Particially Completed";
  } else if (status_id === 6) {
    return "Completed";
  } else if (status_id === 7) {
    return "Pending Customer Review";
  } else {
    return "Unknown";
  }
};

export const fetchUserRoles = (user_role_type_id: number) => {
  if (user_role_type_id === 1) {
    return "Customer";
  } else if (user_role_type_id === 3) {
    return "Project Manager";
  } else if (user_role_type_id === 4) {
    return "Admin";
  } else if (user_role_type_id === 6) {
    return "Translator";
  }
};

export const getProjectStatusAsPercent = (documents: any[]) => {
  /**
   * Logic to calc the project completed percent
   *
   * In Progress: 0.2
   * Ready for Review: 0.6
   * Revision: 0.3
   * Completed: 1
   */

  const InProgressScore = 0.2;
  const ReadyReviewSccore = 0.6;
  const RevisionScore = 0.3;
  const CompletedScore = 1;

  let completedDocuments =
    documents?.filter((it: any) => Number(it.document_status_id) === 4) ?? [];
  let inProgressDocuments =
    documents?.filter((it: any) => Number(it.document_status_id) === 2) ?? [];
  let readyForReviewDocuments =
    documents?.filter((it: any) => Number(it.document_status_id) === 3) ?? [];
  let inRevisionDocuments =
    documents?.filter((it: any) => Number(it.document_status_id) === 5) ?? [];

  let totalScore =
    completedDocuments.length * CompletedScore +
    inProgressDocuments?.length * InProgressScore +
    readyForReviewDocuments?.length * ReadyReviewSccore +
    inRevisionDocuments.length * RevisionScore;

  return documents?.length > 0
    ? Math.abs((totalScore / documents?.length) * 100)
    : 0;
};

export const fetchPerPagePrice = (languages: string[]) => {
  let price = 0;
  languages.forEach((it: string) => {
    let priceItem = prices.find((_it) => {
      let index = _it.languages?.findIndex(
        (__it) => __it.trim().toLowerCase() === it.trim().toLowerCase()
      );
      return index > -1;
    });
    price += priceItem?.price ?? 0;
  });
  return price;
};

export const fetchLanguagePrice = (language: string) => {
  let priceItem = prices.find((_it) => _it.languages?.includes(language));
  return priceItem?.price || 24.5;
};

export const googleAnalytics = (
  uploadProject: any,
  orderSummary: any,
  user: any,
  orderId: any
) => {
  let items = [
    {
      item_name: "Translation Service",
      item_id: `ID_${user.user_id}`,
      affiliation: "Languex",
      price: fetchPerPagePrice(uploadProject.targetLanguage),
      item_brand: "Languex",
      quantity: orderSummary.pages,
      item_category:
        uploadProject.transitionType === "certified"
          ? "Certified Translation" // Added this field to send the order creation to checkout
          : "Standard Translation",
      item_category2: uploadProject.sourceLanguage,
      item_category3: uploadProject.targetLanguage?.join(", "),
    },
  ];

  // Translation Speed
  items.push({
    item_name:
      uploadProject.transitionSpeed === "express"
        ? "Expedited Turnaround"
        : "Regular Turnaround",
    item_id: `ID_${user.user_id}`,
    affiliation: "Languex",
    price:
      uploadProject.transitionSpeed === "express"
        ? 10 * (uploadProject.totalPage ?? 1)
        : 0.004 * (uploadProject.totalWords ?? 1),
    item_brand: "Languex",
    quantity: orderSummary.pages,
    item_category:
      uploadProject.transitionType === "certified"
        ? "Certified Translation" // Added this field to send the order creation to checkout
        : "Standard Translation",
    item_category2: uploadProject.sourceLanguage,
    item_category3: uploadProject.targetLanguage?.join(", "),
  });

  // Recommendted Options
  if (uploadProject.isNotarized) {
    items.push({
      item_name:
        uploadProject.transitionType === "certified"
          ? "Certification with notarization"
          : "Standard with notarization",
      item_id: `ID_${user.user_id}`,
      affiliation: "Languex",
      price: 20,
      item_brand: "Languex",
      quantity: 1,
      item_category:
        uploadProject.transitionType === "certified"
          ? "Certified Translation" // Added this field to send the order creation to checkout
          : "Standard Translation",
      item_category2: uploadProject.sourceLanguage,
      item_category3: uploadProject.targetLanguage?.join(", "),
    });
  }

  if (uploadProject.isMailHardCopy) {
    items.push({
      item_name:
        uploadProject.transitionType === "certified"
          ? "Certification with Hard Copies"
          : "Standard with Hard Copies",
      item_id: `ID_${user.user_id}`,
      affiliation: "Languex",
      price: 20,
      item_brand: "Languex",
      quantity: 1,
      item_category:
        uploadProject.transitionType === "certified"
          ? "Certified Translation" // Added this field to send the order creation to checkout
          : "Standard Translation",
      item_category2: uploadProject.sourceLanguage,
      item_category3: uploadProject.targetLanguage?.join(", "),
    });
  }

  // if (uploadProject.is2DayShipping) {
  //   items.push({
  //     item_name:
  //       uploadProject.transitionType === "certified"
  //         ? "Certification with Overnight shipping"
  //         : "Standard with Overnight shipping",
  //     item_id: `ID_${user.user_id}`,
  //     affiliation: "Languex",
  //     price: 20,
  //     item_brand: "Languex",
  //     quantity: 1,
  //     item_category:
  //       uploadProject.transitionType === "certified"
  //         ? "Certified Translation" // Added this field to send the order creation to checkout
  //         : "Standard Translation",
  //     item_category2: uploadProject.sourceLanguage,
  //     item_category3: uploadProject.targetLanguage?.join(", "),
  //   });
  // }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "purchase",
    user_id: user.user_id,
    payment_provider: uploadProject.paymentOption?.card_number
      ? "Credit Card"
      : "Paypal",
    ecommerce: {
      transaction_id: `${orderId}`,
      value: orderSummary.totalPrice,
      tax: 0,
      discount: Number(user?.discount?.discount || 0),
      shipping: 0,
      currency: "USD",
      coupon: "",
      items: items,
    },
  });

  window.dataLayer.push({
    event: "login",
    user_id: user.user_id,
    method: "Transaction",
  });
};

export const calculateDueDate = (uploadProject: any) => {
  let dueDate = moment();
  if (uploadProject.transitionType === "certified") {
    if (uploadProject.totalPage >= 1 && uploadProject.totalPage <= 4) {
      dueDate = dueDate.add(24, "hours");
      if (uploadProject.transitionSpeed === "express") {
        dueDate = dueDate.add(12, "hours");
      }
    } else if (uploadProject.totalPage >= 5 && uploadProject.totalPage <= 10) {
      dueDate = dueDate.add(3, "days");
      if (uploadProject.transitionSpeed === "express") {
        dueDate = dueDate.add(2, "days");
      }
    } else if (uploadProject.totalPage >= 11 && uploadProject.totalPage <= 30) {
      dueDate = dueDate.add(4, "days");
      if (uploadProject.transitionSpeed === "express") {
        dueDate = dueDate.add(2, "days");
      }
    } else if (uploadProject.totalPage >= 31 && uploadProject.totalPage <= 50) {
      dueDate = dueDate.add(5, "days");
      if (uploadProject.transitionSpeed === "express") {
        dueDate = dueDate.add(3, "days");
      }
    } else if (
      uploadProject.totalPage >= 51 &&
      uploadProject.totalPage <= 100
    ) {
      dueDate = dueDate.add(10, "days");
      if (uploadProject.transitionSpeed === "express") {
        dueDate = dueDate.add(5, "days");
      }
    } else if (uploadProject.totalPage >= 101) {
      dueDate = null;
    }
  } else if (uploadProject.transitionType === "standard") {
    if (uploadProject.totalWords > 0 && uploadProject.totalWords <= 2000) {
      dueDate = dueDate.add(2, "days");
      if (uploadProject.transitionSpeed === "express") {
        dueDate = dueDate.add(24, "hours");
      }
    } else if (
      uploadProject.totalWords >= 2001 &&
      uploadProject.totalWords <= 5000
    ) {
      dueDate = dueDate.add(4, "days");
      if (uploadProject.transitionSpeed === "express") {
        dueDate = dueDate.add(2, "days");
      }
    } else if (
      uploadProject.totalWords >= 5001 &&
      uploadProject.totalWords <= 12500
    ) {
      dueDate = dueDate.add(6, "days");
      if (uploadProject.transitionSpeed === "express") {
        dueDate = dueDate.add(3, "days");
      }
    } else if (
      uploadProject.totalWords >= 12501 &&
      uploadProject.totalWords <= 25000
    ) {
      dueDate = dueDate.add(10, "days");
      if (uploadProject.transitionSpeed === "express") {
        dueDate = dueDate.add(5, "days");
      }
    } else if (uploadProject.totalWords >= 25001) {
      dueDate = null;
    }
  }

  return dueDate;
};

export const isValidEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

function flattenJSON(json: any, parentKey = "", separator = "_") {
  let result: any = {};

  for (const key in json) {
    if (json.hasOwnProperty(key)) {
      const newKey = parentKey ? `${parentKey}${separator}${key}` : key;
      if (typeof json[key] === "object" && json[key] !== null) {
        // Recursively flatten nested objects
        Object.assign(result, flattenJSON(json[key], newKey, separator));
      } else {
        result[newKey] = json[key];
      }
    }
  }

  return result;
}

export const generateQueryParameters = (jsonData: any) => {
  const flattenedData = flattenJSON(jsonData);
  const queryParams = new URLSearchParams(flattenedData);
  return queryParams.toString();
};

export const fetchUnreadMessagesByStatus = (unreadMessages: any[]) => {
  const activeMessagesCnt = unreadMessages
    ?.filter(
      (it: any) =>
        it?.order_status_id &&
        Number(it?.order_status_id) < 7 &&
        Number(it?.order_status_id) !== -1 &&
        Number(it?.order_status_id) !== 6
    )
    ?.reduce(
      (acc: number, currentItem: any) => acc + Number(currentItem.cnt),
      0
    );

  const reviewMessagesCnt = unreadMessages
    ?.filter(
      (it: any) => it?.order_status_id && Number(it?.order_status_id) === 7
    )
    ?.reduce(
      (acc: number, currentItem: any) => acc + Number(currentItem.cnt),
      0
    );

  const completeMessagesCnt = unreadMessages
    ?.filter(
      (it: any) => it?.order_status_id && Number(it?.order_status_id) === 6
    )
    ?.reduce(
      (acc: number, currentItem: any) => acc + Number(currentItem.cnt),
      0
    );

  const newMessagesCnt = unreadMessages
    ?.filter(
      (it: any) =>
        Number(it?.order_status_id) === 1 ||
        ((new Date().getTime() - new Date(it.created_at).getTime()) / 1000 <=
          24 * 3600 * 1 &&
          Number(it?.order_status_id) !== 6)
    )
    ?.reduce(
      (acc: number, currentItem: any) => acc + Number(currentItem.cnt),
      0
    );

  const dueSoonMessagesCnt = unreadMessages
    ?.filter(
      (it: any) =>
        // Number(it?.order_status_id) === 1 ||
        (new Date(it.order_due_at).getTime() - new Date().getTime()) / 1000 <=
          24 * 3600 * 1 &&
        Number(it?.order_status_id) !== 6 &&
        new Date(it.order_due_at).getTime() - new Date().getTime() > 0
    )
    ?.reduce(
      (acc: number, currentItem: any) => acc + Number(currentItem.cnt),
      0
    );
  return {
    activeMessagesCnt,
    reviewMessagesCnt,
    completeMessagesCnt,
    newMessagesCnt,
    dueSoonMessagesCnt,
  };
};

export const fetchExpeditedServicePrice = (order: any) => {
  const targetLanguageString = order?.translated_to ?? "";
  const targetLanguages = targetLanguageString?.split(",");
  const pageCount = order?.page_count ?? 1;
  const wordCount = order?.words_count ?? 1;
  if (order?.translation_type === "certified") {
    return 10 * pageCount * targetLanguages?.length;
  } else {
    return wordCount >= 250
      ? 0.004 * wordCount * targetLanguages?.length
      : 10 * targetLanguages.length;
  }
};

export const groupByAndCount = (array: any[]) => {
  const langGroup = array.reduce(function (acc, current) {
    acc[current] = (acc[current] || 0) + 1;
    return acc;
  }, {});

  const sortedGroup = Object.entries(langGroup)
    .sort((a: any, b: any) => b[1] - a[1])
    .reduce((acc: any, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});
  return Object.fromEntries(Object.entries(sortedGroup).slice(0, 2));
};

export const getBarChartData = (
  data_arr: any[],
  start_date: any,
  end_date: any,
  period: any
) => {
  let results = [];
  if (period === "daily") {
    const endDate = new Date(end_date);
    let calcDate = new Date(start_date);
    while (endDate >= calcDate) {
      let formattedDate = format(calcDate, "yyyy-MM-dd");
      let totalPrice = Number(
        data_arr?.find((it) => it?.order_date === formattedDate)?.total_price ??
          0
      );
      results?.push({
        date: formattedDate,
        price: totalPrice,
      });
      calcDate = addDays(calcDate, 1);
    }
  } else if (period === "weekly") {
    const endDate = new Date(end_date);
    let calcDate = new Date(start_date);
    while (endDate >= calcDate) {
      let year = getYear(calcDate);
      let week = getWeek(calcDate);
      let totalPrice = Number(
        data_arr?.find(
          (it) =>
            `${it?.order_year}` === `${year}` &&
            `${it?.order_week}` === `${week}`
        )?.total_price ?? 0
      );
      results?.push({
        date: `${year}-${week < 10 ? "0" + week : week}`,
        price: totalPrice,
      });
      calcDate = addDays(calcDate, 7);
    }
  } else if (period === "monthly") {
    const endDate = new Date(end_date);
    let calcDate = new Date(start_date);
    while (endDate >= calcDate) {
      let year = getYear(calcDate);
      let month = getMonth(calcDate);
      let totalPrice = Number(
        data_arr?.find(
          (it) =>
            `${it?.order_year}` === `${year}` &&
            `${it?.order_month}` === `${month}`
        )?.total_price ?? 0
      );
      results?.push({
        date: `${year}-${month < 10 ? "0" + month : month}`,
        price: totalPrice,
      });
      calcDate = addDays(calcDate, 30);
    }
  }

  return results;
};
