import { Box, Button, Flex, Heading, Image, Text } from "@chakra-ui/react";
import TranslationDone from "assets/img/customer/img_translation_done.png";
import { pad_with_zeroes } from "network/helper";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearProjectRequest } from "store/customerProject/reducer";

export default function TranslationComplete(props: { orderId?: string }) {
  const navigate = useNavigate();
  const { orderId } = props;
  const boxShadow = "0px 3.60347px 45.0434px rgba(0, 0, 0, 0.1)";
  const dispatch = useDispatch();

  const onCreateNewProject = () => {
    dispatch(clearProjectRequest());
    navigate("/customer/main");
  };

  const onClickTrackView = () => {
    navigate(`/customer/projects#${orderId}`);
  };

  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="center"
      padding={{ md: "30px 35px", base: "30px 20px" }}
      bgColor="#FFF"
      borderRadius="18px"
      boxShadow={{ md: boxShadow, base: "none" }}
    >
      <Image
        w="100%"
        h="100%"
        maxH="250px"
        maxW="250px"
        src={TranslationDone}
        borderRadius="50%"
        mb="25px"
      />
      <Heading
        fontFamily="Canela Text"
        fontSize="30px"
        lineHeight="135%"
        color="#000"
        fontWeight="700"
        textAlign="center"
        mb="15px"
      >
        We’re on it!
      </Heading>
      <Text
        fontSize="14px"
        lineHeight="135%"
        color="#000"
        fontWeight="400"
        textAlign="center"
        mb="15px"
      >
        Order Number: {pad_with_zeroes(orderId, 6)}
      </Text>
      <Box
        fontSize="14px"
        lineHeight="135%"
        color="#2F80ED"
        fontWeight="400"
        textAlign="center"
        cursor="pointer"
        mb="25px"
        _hover={{
          textDecoration: "underline",
        }}
        onClick={() => onClickTrackView()}
      >
        Track & View Order
      </Box>
      <Button
        ml="20px"
        w="fit-content"
        height="46px"
        padding="15px 45px"
        borderRadius="100px"
        bgColor="#2F80ED"
        color="#FFF"
        fontSize="14px"
        lineHeight="17px"
        fontWeight="bold"
        onClick={onCreateNewProject}
      >
        Translate another project
      </Button>
    </Flex>
  );
}
