import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import IconBox from "components/icons/IconBox";
import { IconArrowDown } from "components/icons/Icons";
import { axiosService } from "network/axios";
import { fetchStatusName, pad_with_zeroes } from "network/helper";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAlertMessageRequest } from "store/ui/reducer";
import { userSelector } from "store/user/selector";

const pdfMake = require("pdfmake");

export default function ExpenseReports() {
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  const months = [
    "January",
    "Februray",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dispatch = useDispatch();

  const user = useSelector(userSelector);
  const years = [2021, 2022, 2023];
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const onDownloadExpenseReport = async () => {
    if (!selectedYear || !selectedMonth) {
      dispatch(
        setAlertMessageRequest({
          type: "error",
          message: "You need to select year & month to get the expense report",
        })
      );
      return;
    }
    let index = months.findIndex((it) => it === selectedMonth);
    if (index > -1) {
      const apiUrl = `/user/reports/${selectedYear}/${index + 1}/${
        user.user_id
      }`;
      try {
        const resp: any = await axiosService.get(apiUrl);
        const orders = resp?.data?.results ?? [];

        if (orders?.length > 0) {
          pdfMake.fonts = {
            Roboto: {
              normal:
                "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
              bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
              italics:
                "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
              bolditalics:
                "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
            },
          };

          const docRows = orders?.map((it: any) => [
            { text: pad_with_zeroes(it.order_id, 6), margin: [0, 10, 0, 10] },
            { text: it.documents, margin: [0, 10, 0, 10] },
            {
              text: Number(it.include_2_day_shipping) === 1 ? "Yes" : "No",
              margin: [0, 10, 0, 10],
            },
            {
              text: Number(it.include_notarization) === 1 ? "Yes" : "No",
              margin: [0, 10, 0, 10],
            },
            {
              text:
                Number(it.include_expedited_turnaround) === 1 ? "Yes" : "No",
              margin: [0, 10, 0, 10],
            },
            { text: it.translation_type, margin: [0, 10, 0, 10] },
            { text: it.translated_from, margin: [0, 10, 0, 10] },
            { text: it.translated_to, margin: [0, 10, 0, 10] },
            {
              text: fetchStatusName(Number(it?.order_status_id)),
              margin: [0, 10, 0, 10],
            },
            {
              text: `$${Number(it.order_total).toFixed(2)}`,
              margin: [0, 10, 0, 10],
            },
          ]);

          const docDefinition = {
            header: function (
              currentPage: number,
              pageCount: number,
              pageSize: number
            ) {
              return [
                {
                  text: `Expense Report - (${selectedMonth}, ${selectedYear})`,
                  alignment: "center",
                  margin: [0, 5, 0, 40],
                  fontSize: 16,
                },
              ];
            },

            pageSize: {
              width: 1000,
              height: 500,
            },
            pageOrientation: "landscape",
            content: [
              {
                layout: "lightHorizontalLines",
                table: {
                  headerRows: 1,
                  body: [
                    [
                      { text: "Order ID", margin: [0, 5, 0, 5] },
                      { text: "Documents", margin: [0, 5, 0, 5] },
                      { text: "Overnight Shipping", margin: [0, 5, 0, 5] },
                      { text: "Notarized", margin: [0, 5, 0, 5] },
                      { text: "Expedite Translation", margin: [0, 5, 0, 5] },
                      { text: "Translation Type", margin: [0, 5, 0, 5] },
                      { text: "Source Language (s)", margin: [0, 5, 0, 5] },
                      { text: "Target Language (s)", margin: [0, 5, 0, 5] },
                      { text: "Order Status", margin: [0, 5, 0, 5] },
                      { text: "Order Total", margin: [0, 5, 0, 5] },
                    ],
                    ...docRows,
                  ],
                },
              },
            ],
          };
          const fileName = `${selectedYear}-${selectedMonth}.pdf`;
          pdfMake.createPdf(docDefinition).download(fileName);
        } else {
          dispatch(
            setAlertMessageRequest({
              type: "warning",
              message: "No Orders on selected year & month",
            })
          );
          return;
        }
      } catch (err: any) {
        dispatch(
          setAlertMessageRequest({
            type: "error",
            message: "Failed to generate the expense Report",
          })
        );
        return;
      }
    }
  };

  return (
    <Box>
      <Flex
        alignItems={"flex-start"}
        flexDirection={{ md: "row", base: "column" }}
      >
        <Text
          fontSize="17px"
          lineHeight="24px"
          color="#000"
          fontWeight="700"
          whiteSpace="nowrap"
          mr="50px"
          w="142px"
          minW="142px"
          mb="20px"
        >
          Expense Reports
        </Text>
        <Text fontSize="14px" lineHeight="135%" color="#000" fontWeight="400">
          This is a description.This is a description.This is a description.This
          is a description.This is a description.This is a description.This is a
          description.
        </Text>
      </Flex>
      <Flex alignItems="center" mt="40px" flexWrap="wrap" rowGap={"20px"}>
        <Menu>
          <MenuButton
            padding={"10px 10px 10px 15px"}
            backgroundColor="#FFF"
            borderRadius="100px"
            border="1px solid #E9E9E9"
            disabled={!selectedYear}
            _disabled={{
              opacity: ".4",
              cursor: "not-allowed",
            }}
          >
            <Flex alignItems={"center"}>
              <Text
                fontSize={"14px"}
                lineHeight="24px"
                fontWeight={"400"}
                color="black"
              >
                {selectedMonth ? selectedMonth : "Month"}
              </Text>
              <IconBox
                marginLeft="10px"
                icon={<IconArrowDown width="24px" height="24px" />}
              ></IconBox>
            </Flex>
          </MenuButton>
          <MenuList
            boxShadow={shadow}
            p="0px"
            mt="10px"
            borderRadius="20px"
            bg={"#FFF"}
            border="none"
            maxH="400px"
            overflow="auto"
          >
            <Flex
              flexDirection="column"
              p="10px 20px"
              fontSize={"14px"}
              lineHeight="24px"
              fontWeight={"400"}
              color="black"
            >
              {months
                .filter(
                  (it, index) =>
                    (selectedYear === 2023 && index < 7) ||
                    selectedYear !== 2023
                )
                .map((month, index: number) => (
                  <MenuItem
                    key={index}
                    _hover={{ bg: "none" }}
                    _focus={{ bg: "none" }}
                    borderRadius="8px"
                    padding="15px 0px"
                    borderBottom={
                      index < months.length - 1 ? "1px solid #F3F5F5" : ""
                    }
                    onClick={() => setSelectedMonth(month)}
                  >
                    <Text fontSize="sm">{month}</Text>
                  </MenuItem>
                ))}
            </Flex>
          </MenuList>
        </Menu>
        <Menu>
          <MenuButton
            padding={"10px 10px 10px 15px"}
            backgroundColor="#FFF"
            borderRadius="100px"
            border="1px solid #E9E9E9"
            ml="15px"
            mr="15px"
          >
            <Flex alignItems={"center"}>
              <Text
                fontSize={"14px"}
                lineHeight="24px"
                fontWeight={"400"}
                color="black"
              >
                {selectedYear ? selectedYear : "Year"}
              </Text>
              <IconBox
                marginLeft="10px"
                icon={<IconArrowDown width="24px" height="24px" />}
              ></IconBox>
            </Flex>
          </MenuButton>
          <MenuList
            boxShadow={shadow}
            p="0px"
            mt="10px"
            borderRadius="20px"
            bg={"#FFF"}
            border="none"
          >
            <Flex
              flexDirection="column"
              p="10px 20px"
              fontSize={"14px"}
              lineHeight="24px"
              fontWeight={"400"}
              color="black"
            >
              {years.map((year, index: number) => (
                <MenuItem
                  key={index}
                  _hover={{ bg: "none" }}
                  _focus={{ bg: "none" }}
                  borderRadius="8px"
                  padding="15px 0px"
                  borderBottom={
                    index < years.length - 1 ? "1px solid #F3F5F5" : ""
                  }
                  onClick={() => setSelectedYear(year)}
                >
                  <Text fontSize="sm">{year}</Text>
                </MenuItem>
              ))}
            </Flex>
          </MenuList>
        </Menu>
        <Button
          w="fit-content"
          height="46px"
          padding="15px 35px"
          borderRadius="100px"
          bgColor="#2F80ED"
          color="#FFF"
          fontSize="14px"
          lineHeight="17px"
          fontWeight="bold"
          disabled={!selectedYear || !selectedMonth}
          onClick={() => {
            onDownloadExpenseReport();
          }}
          ml={{ md: "auto", base: "0px" }}
        >
          Download
        </Button>
      </Flex>
    </Box>
  );
}
