import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import IconBox from "components/icons/IconBox";
import { IconSortArrow } from "components/icons/Icons";

export default function SortingHandler(props: {
  sortingOptions: {
    label: string;
    key: string;
  }[];
  selectedOption: {
    label: string;
    key: string;
    dir: string;
  };
  onChangeSortingOption: Function;
  [x: string]: any;
}) {
  const { sortingOptions, onChangeSortingOption, selectedOption, ...rest } =
    props;
  const shadow = "0px 4px 30px rgba(0, 0, 0, 0.1)";
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        ".chakra-menu__menu-button + div": {
          position: "absolute !important",
        },
      }}
      {...rest}
    >
      <Text
        fontSize="14px"
        lineHeight="24px"
        color="#8E8C8C"
        boxSizing="border-box"
      >
        Sort By:
      </Text>
      <Menu>
        <MenuButton ml={1}>
          <Flex alignItems={"center"}>
            <Text
              fontSize="14px"
              lineHeight="24px"
              color="#8E8C8C"
              boxSizing="border-box"
              textDecoration="underline"
            >
              {selectedOption.label}
            </Text>
          </Flex>
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={"#FFF"}
          border="none"
        >
          <Flex
            flexDirection="column"
            p="10px 20px"
            fontSize={"14px"}
            lineHeight="24px"
            fontWeight={"400"}
            color="black"
          >
            {sortingOptions.map((option, index: number) => (
              <MenuItem
                key={index}
                _hover={{ bg: "none" }}
                _focus={{ bg: "none" }}
                borderRadius="8px"
                padding="15px 0px"
                onClick={() =>
                  onChangeSortingOption({
                    ...option,
                    dir: selectedOption.dir,
                  })
                }
                borderBottom={
                  index < sortingOptions.length - 1 ? "1px solid #F3F5F5" : ""
                }
              >
                <Text fontSize="sm">{option.label}</Text>
              </MenuItem>
            ))}
          </Flex>
        </MenuList>
      </Menu>
      <IconBox
        ml={2}
        cursor="pointer"
        className={selectedOption.dir}
        sx={{
          "&.desc svg g > path:nth-of-type(1)": {
            fill: "rgb(142, 140, 140)",
          },
          "&.desc svg g > path:nth-of-type(2)": {
            fill: "rgb(220, 220, 200)",
          },
          "&.asc svg g > path:nth-of-type(1)": {
            fill: "rgb(220, 220, 200)",
          },
          "&.asc svg g > path:nth-of-type(2)": {
            fill: "rgb(142, 140, 140)",
          },
        }}
        icon={<IconSortArrow w="15px" h="17px" />}
        onClick={() => {
          onChangeSortingOption({
            ...selectedOption,
            dir: selectedOption.dir === "desc" ? "asc" : "desc",
          });
        }}
      />
    </Box>
  );
}
