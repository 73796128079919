import { createSelector } from "reselect";
import { RootState } from "../common/reducer";

export const projectState = (rootState: RootState) => rootState.projects;

export const isFetchingProjects = createSelector(
  [projectState],
  (state) => state.loading
);

export const projectsSelector = createSelector(
  [projectState],
  (state) => state.projects
);

export const isFetchingDetails = createSelector(
  [projectState],
  (state) => state.isFetchingProjectDetail
);

export const selectedProjectSelector = createSelector(
  [projectState],
  (state) => state.selectedProject
);

export const isUpdatingDocumentSelector = createSelector(
  [projectState],
  (state) => state.isUpdatingDocuments
);

export const isFetchingTranslatorProjectsSelector = createSelector(
  [projectState],
  (state) => state.isGettingTranslatorProjects
);

export const translatorProjectsSelector = createSelector(
  [projectState],
  (state) => state.translatorProjects
);

// manager Projects
export const isFetchingManagerProjectsSelector = createSelector(
  [projectState],
  (state) => state.isGettingManagerProjects
);

export const managerProjectsSelector = createSelector(
  [projectState],
  (state) => state.managerProjects
);

// Message

export const isFetchingReceiversSelector = createSelector(
  [projectState],
  (state) => state.isFetchingReceivers
);

export const isFetchingMessagesSelector = createSelector(
  [projectState],
  (state) => state.isFetchingMessages
);

export const isSedningMessagesSelector = createSelector(
  [projectState],
  (state) => state.isSendingMessage
);

export const receiversSelector = createSelector(
  [projectState],
  (state) => state.receivers
);

export const messagesSelector = createSelector(
  [projectState],
  (state) => state.messages
);

export const unreadMessageSelector = createSelector(
  [projectState],
  (state) => state.unreadMessages
);

export const draftsProjectsCntSelector = createSelector(
  [projectState],
  (state) => state.draftProjectsCnt
);

export const notificationDetailsSelector = createSelector(
  [projectState],
  (state) => state.notificationDetails
);

export const managersSelector = createSelector(
  [projectState],
  (state) => state.managers
);
