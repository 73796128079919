/* eslint-disable react-hooks/exhaustive-deps */
import { Portal, Box, useDisclosure } from "@chakra-ui/react";
import Navbar from "components/navbar/NavbarAdmin";
import Sidebar from "components/sidebar/Sidebar";
import { SidebarContext } from "contexts/SidebarContext";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import routes from "routes";
import { logoutRequest } from "store/user/reducer";
import { userSelector } from "store/user/selector";
import { setAlertMessageRequest } from "store/ui/reducer";
import { uploadProjectSelector } from "store/customerProject/selector";
import { IntercomProvider } from "react-use-intercom";

export default function Dashboard(props: { [x: string]: any }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(userSelector);
  const uploadProject = useSelector(uploadProjectSelector);
  const INTERCOM_APP_ID = process.env.INTERCOM_APP_ID ?? "c596pjrx";
  const location = useLocation();

  useEffect(() => {
    // console.log(location);
    if (
      !location?.pathname?.includes("/customer/main") &&
      !location?.pathname?.includes("/customer/order")
    ) {
      localStorage.removeItem("redeem");
    }
    if (user) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        user_id: user.user_id,
      });
    }
  }, [location]);

  useEffect(() => {
    if (user && user.user_role_type_id !== "1") {
      dispatch(
        setAlertMessageRequest({
          type: "error",
          message: "You don't have access this page",
        })
      );

      localStorage.removeItem("access_token");
      localStorage.removeItem("user_details");
      dispatch(logoutRequest());
      navigate("/auth/sign-in/");
    }
  }, [user]);

  const { ...rest } = props;
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };

  const getActiveRoute = (routes: RoutesType[]): string => {
    let activeRoute = "Default Brand Text";
    if (window.location.pathname?.includes("/customer/order")) {
      activeRoute = uploadProject.projectTitle;
    }
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].name;
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes: RoutesType[]): boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };

  const getActiveNavbarText = (routes: RoutesType[]): string | boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].name;
      }
    }
    return activeNavbar;
  };

  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((route: RoutesType, key: any) => {
      if (route.layout === "/customer") {
        return (
          <Route key={key} path={route.path} Component={route.component} />
        );
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = "ltr";
  const { onOpen } = useDisclosure();

  return (
    <Box>
      <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={false}>
        <SidebarContext.Provider
          value={{
            toggleSidebar,
            setToggleSidebar,
          }}
        >
          <Sidebar
            routes={routes.filter((it) => it.layout === "/customer")}
            display="none"
            {...rest}
          />
          <Box
            float="right"
            minHeight="100vh"
            position="relative"
            w={{ base: "100%", xl: "calc( 100% - 230px )" }}
            maxWidth={{ base: "100%", xl: "calc( 100% - 230px )" }}
            transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
            transitionDuration=".2s, .2s, .35s"
            transitionProperty="top, bottom, width"
            transitionTimingFunction="linear, linear, ease"
          >
            <Portal>
              <Box>
                <Navbar
                  onOpen={onOpen}
                  logoText={"LanguexApp"}
                  brandText={getActiveRoute(
                    routes.filter((it) => it.layout === "/customer")
                  )}
                  secondary={getActiveNavbar(
                    routes.filter((it) => it.layout === "/customer")
                  )}
                  message={getActiveNavbarText(
                    routes.filter((it) => it.layout === "/customer")
                  )}
                  fixed={fixed}
                  {...rest}
                />
              </Box>
            </Portal>

            {getRoute() ? (
              <Box
                mx="auto"
                p={{ base: "20px", md: "30px" }}
                pe="20px"
                minH="100vh"
                pt="50px"
                pb={{ base: "60px" }}
                display={{ md: "block", base: "flex" }}
                flexDirection="column"
              >
                <Routes>
                  {getRoutes(routes.filter((it) => it.layout === "/customer"))}
                  <Route
                    path="/"
                    element={<Navigate to="/admin/default" replace />}
                  />
                </Routes>
              </Box>
            ) : null}
          </Box>
        </SidebarContext.Provider>
      </IntercomProvider>
    </Box>
  );
}
