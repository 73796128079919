import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import Card from "components/card/Card";
import IconBox from "components/icons/IconBox";
import {
  IconArrowDown,
  IconDownload,
  IconTranslator,
  IconUpload,
} from "components/icons/Icons";
import _ from "lodash";
import { fetchLanguagesExcerpt } from "network/helper";
import * as React from "react";
import { FileUploader } from "react-drag-drop-files";

type RowObj = {
  document_id: string;
  language: string;
  source_lang?: string;
  target_lang?: string;
  document_status_id: string;
  document_original_file_name: string;
  translators: any[];
  managers: any[];
  actionCol: string;
  hasChanged: boolean;
  document_file_path: string;
  file: null;
  new_file: any;
  lastest_file?: any;
  origin_file?: any;
};

const documentStatus = [
  {
    id: 1,
    label: "New",
  },
  {
    id: 2,
    label: "In Progress",
  },
  {
    id: 3,
    label: "Ready for Review",
  },
  {
    id: 4,
    label: "Completed",
  },
  {
    id: 5,
    label: "In Revision",
  },
];

const columnHelper = createColumnHelper<RowObj>();

export default function TranslatorProjectDetailTable(props: {
  isFetching: boolean;
  tableData: any;
  onUpdateDocument: Function;
}) {
  const { isFetching, tableData, onUpdateDocument } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const textColor = useColorModeValue("black", "black");

  const shadow = useColorModeValue(
    "0px 4px 50px rgba(0, 0, 0, 0.1)",
    "0px 4px 50px rgba(0, 0, 0, 0.1)"
  );

  const onChangeDocumentStatus = (document_id: string, option: any) => {
    let index = data?.findIndex((it) => it.document_id === document_id);
    if (index > -1) {
      let dumpData = _.cloneDeep(data);
      dumpData[index].document_status_id = option.id;
      dumpData[index].hasChanged = true;
      setData([...dumpData]);
    }
  };

  const onClickSaveDocument = (document_id: string) => {
    let index = data?.findIndex((it) => it.document_id === document_id);
    if (index > -1) {
      onUpdateDocument(data[index]);
    }
  };

  const handleChange = (document_id: any, file: any) => {
    let index = data?.findIndex((it) => it.document_id === document_id);
    if (index > -1) {
      let dumpData = _.cloneDeep(data);
      dumpData[index].new_file = file;
      dumpData[index].hasChanged = true;
      setData([...dumpData]);
    }
  };

  let defaultData = tableData;
  const columns = [
    columnHelper.accessor("language", {
      id: "language",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Language
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
            whiteSpace="nowrap"
          >
            {info.row.original?.source_lang &&
              info.row.original?.target_lang && (
                <React.Fragment>
                  {fetchLanguagesExcerpt(info.row.original?.source_lang)}
                  <IconTranslator width="15px" height="16px" mx="4px" />
                  {fetchLanguagesExcerpt(info.row.original?.target_lang)}
                </React.Fragment>
              )}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("document_status_id", {
      id: "document_status_id",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Status
        </Text>
      ),
      cell: (info) => (
        <Flex align={"center"}>
          <Menu>
            <MenuButton
              padding={"10px 10px 10px 15px"}
              backgroundColor="#F3F5F5"
              borderRadius="100px"
            >
              <Flex alignItems={"center"}>
                <Text
                  fontSize={"14px"}
                  lineHeight="24px"
                  fontWeight={"400"}
                  color="black"
                >
                  {info.getValue()
                    ? documentStatus?.find(
                        (it) => it.id === Number(info.getValue())
                      )?.label
                    : "-"}
                </Text>
                <IconBox
                  marginLeft="10px"
                  icon={<IconArrowDown width="24px" height="24px" />}
                ></IconBox>
              </Flex>
            </MenuButton>
            <MenuList
              boxShadow={shadow}
              p="0px"
              mt="10px"
              borderRadius="20px"
              bg={"#FFF"}
              border="none"
            >
              <Flex
                flexDirection="column"
                p="10px 20px"
                fontSize={"14px"}
                lineHeight="24px"
                fontWeight={"400"}
                color="black"
              >
                {documentStatus.map((option, index: number) => (
                  <MenuItem
                    key={index}
                    _hover={{ bg: "none" }}
                    _focus={{ bg: "none" }}
                    borderRadius="8px"
                    padding="15px 0px"
                    onClick={() =>
                      onChangeDocumentStatus(
                        info.row.original.document_id,
                        option
                      )
                    }
                    borderBottom={
                      index < info.getValue().length - 1
                        ? "1px solid #F3F5F5"
                        : ""
                    }
                  >
                    <Text fontSize="sm">{option.label}</Text>
                  </MenuItem>
                ))}
              </Flex>
            </MenuList>
          </Menu>
        </Flex>
      ),
    }),
    columnHelper.accessor("file", {
      id: "file",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          File
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Flex
            color={textColor}
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
            alignItems={"center"}
            padding="5px 5px 5px 10px"
            borderRadius="100px"
            border="1px solid #E9E9E9"
          >
            <Box
              maxW="100px"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
            >
              {info.row.original.new_file
                ? info.row.original.new_file.name
                : info.row.original.lastest_file
                ? info.row.original.lastest_file.document_original_file_name
                : info.row.original.origin_file?.document_original_file_name ||
                  ""}
            </Box>

            <FileUploader
              multiple={false}
              handleChange={(file: File) => {
                handleChange(info.row.original.document_id, file);
              }}
              name="file"
              height="full"
              sx={{
                marginLeft: "32px",
              }}
            >
              <Flex
                padding="5px 15px"
                background="rgba(47, 128, 237, 0.14)"
                borderRadius="20px"
                marginLeft="32px"
                cursor="pointer"
                _hover={{
                  color: "#2F80ED",
                  textDecoration: "underline",
                }}
              >
                <IconBox
                  icon={<IconUpload width="24px" height="24px" />}
                ></IconBox>
                <Text
                  fontSize={{ sm: "12px", lg: "14px" }}
                  lineHeight="24px"
                  fontWeight="400"
                  color="#2F80ED"
                  marginLeft="4px"
                >
                  Upload
                </Text>
              </Flex>
            </FileUploader>

            <Flex
              padding="5px 15px"
              background="#F3F5F5"
              borderRadius="20px"
              marginLeft="8px"
              cursor="pointer"
              onClick={() => {
                const document_file_path = info.row.original.lastest_file
                  ? info.row.original.lastest_file.document_file_path
                  : info.row.original.origin_file?.document_file_path || "";
                const document_original_file_name = info.row.original
                  .lastest_file
                  ? info.row.original.lastest_file.document_original_file_name
                  : info.row.original.origin_file
                      ?.document_original_file_name || "";

                // const fullUrl = `${process.env.REACT_APP_API_BASE_URL}/${document_file_path}/${document_original_file_name}`;
                const fullUrl =
                  document_file_path?.includes("http://") ||
                  document_file_path?.includes("https://")
                    ? document_file_path
                    : `${process.env.REACT_APP_API_BASE_URL}/${document_file_path}/${document_original_file_name}`;
                const link = document.createElement("a");
                link.href = fullUrl;
                link.target = "_blank";
                link.setAttribute("download", `${document_original_file_name}`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
              }}
              _hover={{
                color: "#2F80ED",
                textDecoration: "underline",
              }}
            >
              <IconBox
                icon={<IconDownload width="24px" height="24px" />}
              ></IconBox>
              <Text
                fontSize={{ sm: "12px", lg: "14px" }}
                lineHeight="24px"
                fontWeight="400"
                color="#2F80ED"
                marginLeft="4px"
              >
                Download
              </Text>
            </Flex>
          </Flex>
        </Flex>
      ),
    }),

    columnHelper.accessor("actionCol", {
      id: "actionCol",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          color="gray.500"
          fontWeight="400"
        ></Text>
      ),
      cell: (info) => (
        <Flex
          justifyContent={{ md: "flex-end", base: "flex-start" }}
          alignItems="center"
          paddingLeft={{ md: "0px", base: "50px" }}
        >
          <Button
            padding="10px 20px"
            border={"2px solid #2F80FD"}
            borderRadius="100px"
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
            color="#2F80FD"
            bg="#FFF"
            disabled={!info.row.original.hasChanged}
            onClick={(e) => onClickSaveDocument(info.row.original.document_id)}
          >
            Send
          </Button>
        </Flex>
      ),
    }),
  ];
  const [data, setData] = React.useState(() => [...defaultData]);
  React.useEffect(() => {
    setData([...tableData]);
  }, [tableData]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const columnMinWidth: any = {
    language: 100,
    status: 200,
    file: 260,
    translator: 150,
    manager: 150,
    actionCol: 100,
  };

  return (
    <Card
      flexDirection="column"
      w="100%"
      p="0px"
      borderRadius="0"
      overflowX="auto"
      sx={{
        ".chakra-menu__menu-button + div": {
          position: "fixed !important",
        },
      }}
    >
      <Box w="100%">
        <Table
          variant="simple"
          color="gray.500"
          pb="2"
          lineHeight="15px"
          borderRadius="0px"
        >
          <Thead display={{ md: "table-header-group", base: "none" }}>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header, index: number) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      border={"none"}
                      fontFamily="Inter"
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}
                      textTransform={"capitalize"}
                      position={
                        index === headerGroup.headers.length - 1
                          ? "sticky"
                          : "unset"
                      }
                      right={
                        index === headerGroup.headers.length - 1 ? "0" : "unset"
                      }
                      p="10px"
                      bgColor="#F1F1F1"
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        lineHeight="24px"
                        sx={{
                          p: {
                            "font-size": "14px",
                            color: "#000",
                          },
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody
            w="100%"
            display={{ md: "table-row-group", base: "flex" }}
            flexDirection="column"
          >
            {!isFetching &&
              table
                .getRowModel()
                .rows.slice(0, 11)
                .map((row) => {
                  return (
                    <Tr
                      display={{ md: "table-row", base: "flex" }}
                      flexDirection="column"
                      key={row.id}
                      position="relative"
                      zIndex={1}
                      borderBottom="1px solid #DCDCDC"
                    >
                      {row.getVisibleCells().map((cell, index: number) => {
                        return (
                          <Td
                            padding="15px 10px"
                            key={cell.id}
                            fontSize={{ sm: "14px" }}
                            minW={{
                              base: "100%",
                              md: `${columnMinWidth[cell.column.id]}px`,
                              lg: `${columnMinWidth[cell.column.id]}px`,
                            }}
                            position={
                              index === row.getVisibleCells().length - 1
                                ? "sticky"
                                : "unset"
                            }
                            right={
                              index === row.getVisibleCells().length - 1
                                ? "0"
                                : "unset"
                            }
                            bgGradient={
                              index === row.getVisibleCells().length - 1
                                ? "linear-gradient(90.91deg, rgba(255, 255, 255, 0.8) 0.73%, rgba(255, 255, 255, 0.77) 53.73%, rgba(255, 255, 255, 1) 98.58%);"
                                : "unset"
                            }
                            border="none"
                            width={
                              cell.column.id === "actionCol" ? "80px" : "auto"
                            }
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}

            {isFetching &&
              [1, 2].map((row) => {
                return (
                  <React.Fragment key={`skeleton-row-${row}`}>
                    <Tr zIndex="1" position="relative">
                      {columns.map((it: any, colIndex: number) => {
                        return (
                          <Td key={`skeleton-${colIndex}`} padding="20px 10px">
                            <Skeleton
                              height="25px"
                              colorScheme="#F3F5F5"
                              borderRadius="5px"
                            ></Skeleton>
                          </Td>
                        );
                      })}
                    </Tr>
                  </React.Fragment>
                );
              })}
          </Tbody>
        </Table>
      </Box>
    </Card>
  );
}
