import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Textarea,
  Tr,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
// Custom components
import Card from "components/card/Card";
import IconBox from "components/icons/IconBox";
import {
  IconChecked,
  IconDownload,
  IconTranslator,
} from "components/icons/Icons";
import { axiosService } from "network/axios";
import { fetchLanguagesExcerpt } from "network/helper";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  sendMessageRequest,
  updateDocumentReqeust,
} from "store/project/reducer";
import { userSelector } from "store/user/selector";

type RowObj = {
  language: string;
  document_id: string;
  document_status_id: string;
  file: string;
  actionCol: string;
  origin_file?: any;
  lastest_file?: any;
  source_lang?: string;
  target_lang?: string;
};

const columnHelper = createColumnHelper<RowObj>();

export default function CustomerProjectDetailMobileTable(props: {
  orderId: string;
  documents: any;
  isFetching: boolean;
  onUpdateDocument: Function;
}) {
  const { orderId, documents, isFetching } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const textColor = useColorModeValue("black", "black");
  const [defaultData, setDefaultData] = React.useState(documents);
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [revisionMessage, setRevisionMessage] = React.useState("");
  const [revisionDocument, setRevisionDocument] = React.useState(null);
  React.useEffect(() => {
    setDefaultData(documents);
  }, [documents]);

  const onClickSaveDocument = (
    document_id: string,
    document_status_id: string
  ) => {
    const payload = {
      order_id: orderId,
      document_id,
      document_status_id,
      user_id: user.user_id,
      is_customer: true,
    };

    dispatch(updateDocumentReqeust(payload));
  };

  const onClickRequestRevision = (doc_id: Number) => {
    setRevisionDocument(doc_id);
    setRevisionMessage("");
    onOpen();
  };

  const onSubmitRevisionRequest = () => {
    let selectedDocument = documents?.find(
      (it: any) => Number(it.document_id) === revisionDocument
    );
    if (selectedDocument) {
      const { managers, translators } = selectedDocument;
      const manager_id = managers?.length > 0 ? managers[0]?.user_id : null;
      const translator_id =
        translators?.length > 0 ? translators[0]?.user_id : null;

      // Needs to send both manager & translator
      if (manager_id) {
        const payload = {
          sender_id: user.user_id,
          receiver_id: manager_id,
          order_id: orderId,
          message: revisionMessage,
        };
        dispatch(sendMessageRequest(payload));
      }

      if (translator_id) {
        const payload = {
          sender_id: user.user_id,
          receiver_id: translator_id,
          order_id: orderId,
          message: revisionMessage,
        };
        dispatch(sendMessageRequest(payload));
      }
      const payload = {
        order_id: orderId,
        document_id: revisionDocument,
        document_status_id: 5,
        user_id: user.user_id,
        is_customer: true,
        translator_id,
        manager_id,
      };

      dispatch(updateDocumentReqeust(payload));
      axiosService
        .post(
          `/order/request-revision/${translator_id}/${manager_id}/${orderId}`,
          {
            feedback: revisionMessage,
          }
        )
        .catch((err) => {
          console.log(err);
        });
    }

    onClose();
  };

  const columns = [
    columnHelper.accessor("language", {
      id: "language",
      header: () => (
        <Text
          align="left"
          fontSize="14px"
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Language
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
          >
            {info.row.original?.source_lang &&
              info.row.original?.target_lang && (
                <React.Fragment>
                  {fetchLanguagesExcerpt(info.row.original?.source_lang)}
                  <IconTranslator width="15px" height="16px" mx="4px" />
                  {fetchLanguagesExcerpt(info.row.original?.target_lang)}
                </React.Fragment>
              )}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("document_status_id", {
      id: "document_status_id",
      header: () => (
        <Text
          align="left"
          fontSize="14px"
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Status
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            fontSize="14px"
            lineHeight="14px"
            fontWeight={"400"}
            color={info.getValue() === "4" ? "#2F80ED" : "black"}
            padding="5px 10px"
            w="fit-content"
            h="fit-content"
            borderRadius="5px"
            bgColor={
              info.getValue() === "4" ? "rgba(47, 128, 237, 0.14)" : "#F3F5F5"
            }
            display="flex"
            alignItems="center"
          >
            {info.getValue() === "4" && (
              <IconBox
                w="11px"
                h="9px"
                icon={<IconChecked w="20px" h="20px" />}
                mr="10px"
              />
            )}
            {info.getValue() === "1"
              ? "New"
              : info.getValue() === "3"
              ? "Ready for Review"
              : info.getValue() === "2"
              ? "In Progress"
              : info.getValue() === "4"
              ? "Complete"
              : info.getValue() === "5"
              ? "Revision"
              : ""}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("file", {
      id: "file",
      header: () => (
        <Text
          align="left"
          fontSize="14px"
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          File
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Flex
            alignItems="center"
            opacity={info.row.original.document_status_id === "4" ? 0.4 : 1}
            cursor="pointer"
            onClick={() => {
              const fullUrl =
                info.row.original.origin_file.document_file_path?.includes(
                  "http://"
                ) ||
                info.row.original.origin_file.document_file_path?.includes(
                  "https://"
                )
                  ? info.row.original.origin_file.document_file_path
                  : `${process.env.REACT_APP_API_BASE_URL}/${info.row.original.origin_file.document_file_path}/${info.row.original.origin_file.document_original_file_name}`;
              const link = document.createElement("a");
              link.href = fullUrl;
              link.setAttribute("target", "_blank");
              link.setAttribute(
                "download",
                `${info.row.original.origin_file.document_original_file_name}`
              );
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
            }}
          >
            <IconBox
              width="20x"
              height="20px"
              icon={<IconDownload width="20x" height="20px" />}
            ></IconBox>
            <Text
              fontSize="12px"
              lineHeight="14px"
              fontWeight="400"
              color="#2F80ED"
            >
              Download
            </Text>
          </Flex>
        </Flex>
      ),
    }),
    columnHelper.accessor("actionCol", {
      id: "actionCol",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          color="gray.500"
          fontWeight="400"
        ></Text>
      ),
      cell: (info) => (
        <Flex
          justifyContent={{ md: "flex-end", base: "flex-start" }}
          alignItems="center"
        >
          {info.row.original.document_status_id !== "4" &&
            info.row.original.document_status_id !== "1" &&
            info.row.original.document_status_id !== "5" && (
              <Button
                padding={{ sm: "10px 10px", base: "10px" }}
                border={"2px solid #2F80FD"}
                bgColor="#2F80FD"
                borderRadius="0px"
                fontSize="14px"
                lineHeight="24px"
                fontWeight="400"
                color="#FFF"
                w="100%"
                bg="#FFF"
                _hover={{
                  bgColor: "#FFF",
                  color: "#2F80FD",
                }}
                onClick={() => {
                  onClickRequestRevision(Number(info.row.original.document_id));
                }}
              >
                Request Revision
              </Button>
            )}
          {(info.row.original.document_status_id === "3" ||
            info.row.original.document_status_id === "2") && (
            <Button
              padding="10px 20px"
              border={"2px solid #2F80FD"}
              bgColor="#2F80FD"
              borderRadius="0px"
              fontSize="14px"
              lineHeight="24px"
              fontWeight="400"
              color="#FFF"
              bg="#FFF"
              _hover={{
                bgColor: "#FFF",
                color: "#2F80FD",
              }}
              ml="5px"
              w="100%"
              onClick={(e) => {
                onClickSaveDocument(info.row.original.document_id, "4");
              }}
            >
              Mark As Complete
            </Button>
          )}
        </Flex>
      ),
    }),
  ];

  const [data, setData] = React.useState(() => [...defaultData]);

  React.useEffect(() => {
    if (defaultData) {
      setData([...defaultData]);
    }
  }, [defaultData]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Card
      flexDirection="column"
      w="100%"
      p="0px"
      borderRadius="0"
      overflowX="auto"
      mb={{ md: "50px", base: "0px" }}
    >
      <Box w="100%">
        <Table
          variant="simple"
          color="gray.500"
          pb="2"
          lineHeight="15px"
          borderRadius="0px"
        >
          {!isFetching &&
            table
              .getRowModel()
              .rows.slice(0, 11)
              .map((row: any, index: number) => {
                return (
                  <Table
                    key={index}
                    mt={index === 0 ? "10px" : "15px"}
                    border="1px solid #dcdcdc"
                  >
                    <Tbody>
                      {row
                        .getVisibleCells()
                        .map((cell: any, _index: number) => {
                          return (
                            <Tr key={_index}>
                              {_index < 3 ? (
                                <React.Fragment>
                                  <Td
                                    p="10px"
                                    width="110px"
                                    borderColor="#dcdcdc"
                                    borderRight="1px solid #dcdcdc"
                                    bgColor="#FAFAFA"
                                  >
                                    {flexRender(
                                      table.getHeaderGroups()[0].headers[_index]
                                        .column.columnDef.header,
                                      table
                                        .getHeaderGroups()[0]
                                        .headers[_index].getContext()
                                    )}
                                  </Td>
                                  <Td p="10px" position="relative">
                                    {flexRender(
                                      cell.column.columnDef.cell,
                                      cell.getContext()
                                    )}
                                  </Td>
                                </React.Fragment>
                              ) : (
                                <Td colSpan={2} padding="0px">
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </Td>
                              )}
                            </Tr>
                          );
                        })}
                    </Tbody>
                  </Table>
                );
              })}

          {isFetching &&
            [1, 2].map((row) => {
              return (
                <Table
                  key={row}
                  mt={row === 0 ? "10px" : "35px"}
                  border="1px solid #dcdcdc"
                >
                  <Tbody>
                    {[1, 2, 3, 4].map((_it) => (
                      <Tr key={_it}>
                        <Td
                          p="10px"
                          width="110px"
                          borderColor="#dcdcdc"
                          borderRight="1px solid #dcdcdc"
                          bgColor="#FAFAFA"
                        >
                          <Skeleton height="24px" width="100%" />
                        </Td>
                        <Td p="10px" borderColor="#dcdcdc">
                          <Skeleton height="24px" width="100%" />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              );
            })}
        </Table>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            fontSize="17px"
            lineHeight="24px"
            fontWeight="700"
            color="#000"
            textAlign="center"
          >
            Request the Revision
          </ModalHeader>

          <ModalBody pt={0}>
            <Textarea
              placeholder="Please write the reason why you need to request a revision."
              value={revisionMessage}
              onChange={(e) => setRevisionMessage(e.target.value)}
            ></Textarea>
          </ModalBody>
          <ModalFooter columnGap="10px" justifyContent="center">
            <Button
              height="35px"
              padding="10px 25px"
              borderRadius="100px"
              bgColor="#2F80ED"
              color="#FFF"
              fontSize="12px"
              lineHeight="17px"
              fontWeight="bold"
              width="100px"
              _disabled={{
                backgroundColor: "#E9E9E9 !important",
              }}
              disabled={revisionMessage?.length < 1}
              onClick={(e) => onSubmitRevisionRequest()}
            >
              Submit
            </Button>
            <Button
              height="35px"
              padding="10px 25px"
              borderRadius="100px"
              bgColor="#FFF"
              border="1px solid #2F80ED"
              color="#2F80ED"
              fontSize="12px"
              lineHeight="17px"
              fontWeight="bold"
              width="100px"
              onClick={() => onClose()}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Card>
  );
}
