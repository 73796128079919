import { Box } from "@chakra-ui/react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { userSelector } from "store/user/selector";
import { customerProfileMenu } from "../variables/profileMenu";

export default function CustomerProfileMenu(props: {
  selectedMenuItem: any;
  onMenuItemClick: Function;
}) {
  const user = useSelector(userSelector);

  const isBusinessOrAdminAccount = useMemo(() => {
    if (user) {
      return user.is_business || user.is_admin;
    }
  }, [user]);

  const { selectedMenuItem, onMenuItemClick } = props;
  return (
    <Box
      display={{
        md: "block",
        base: "flex",
      }}
      columnGap="5px"
    >
      {customerProfileMenu
        ?.filter((it: any) => {
          if (isBusinessOrAdminAccount) {
            return true;
          } else {
            return it.isRequiredBusiness === false;
          }
        })
        .map((item, index: number) => (
          <Box
            key={index}
            py={{ md: "17px", base: "14px" }}
            px={{ md: "0px", base: "10px" }}
            fontSize={{ md: "20px", base: "14px" }}
            lineHeight="120%"
            fontWeight="400"
            color={selectedMenuItem.id === item.id ? "#2F80ED" : "#000"}
            cursor="pointer"
            borderBottom={{
              md:
                index < customerProfileMenu.length - 1
                  ? "1px solid #E3E3E3"
                  : "none",
              base: "1px solid #E3E3E3",
            }}
            transition="color .25s"
            onClick={() => onMenuItemClick(item)}
            _hover={{
              color: "#2F80ED",
            }}
          >
            {item.label}
          </Box>
        ))}
    </Box>
  );
}
