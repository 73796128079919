/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex, Spinner } from "@chakra-ui/react";
import { axiosService } from "network/axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCardsRequest } from "store/user/reducer";
import { userSelector } from "store/user/selector";
import ServiceItem from "./components/ServiceItem";
import { servicesData } from "./variables/serviceData";

export default function Services() {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const [allActiveProjects, setAllActiveProjects] = useState<any>([]);
  const [isFetching, setIsFetching] = useState(false);
  useEffect(() => {
    dispatch(getCardsRequest({ user_id: user.user_id }));
    fetchResults();
  }, []);
  const fetchResults = async () => {
    setIsFetching(true);
    try {
      const resp: any = await axiosService.get(
        `/projects/list/${user?.user_id}?limit=1000&page=1`
      );
      const { results } = resp?.data;
      setAllActiveProjects(
        results?.map((it: any) => ({
          ...it,
          id: it?.order_id,
        }))
      );
    } catch (err) {
      console.log(err);
      setAllActiveProjects([]);
    } finally {
      setIsFetching(false);
    }
  };
  return (
    <Box pt={{ base: "60px", md: "80px", xl: "80px" }}>
      <Flex
        columnGap={{ xl: "40px", md: "20px" }}
        rowGap="30px"
        w="full"
        flexWrap="wrap"
      >
        {servicesData.map((it, index: number) => (
          <ServiceItem
            flexBasis={{
              base: "100%",
              xl: "calc((100% - 80px) / 3)",
              md: "calc((100% - 20px) / 2)",
            }}
            w="full"
            item={it}
            allActiveProjects={allActiveProjects}
            key={index}
            onRefresh={fetchResults}
          ></ServiceItem>
        ))}
      </Flex>
      {isFetching && (
        <Box
          w="100%"
          h="100%"
          bgColor={"rgba(0, 0, 0, 0.1)"}
          position="fixed"
          top="0"
          left="0"
          zIndex="100001"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner color="#2F80ED" width={"50px"} height="50px"></Spinner>
        </Box>
      )}
    </Box>
  );
}
