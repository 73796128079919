import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { apiService } from "network/api";
import { sleep } from "network/helper";
import { takeLatest, call, put } from "redux-saga/effects";
// import { DocumentType } from "views/customer/order/variables/orderData";
import {
  // deleteProjectRequest,
  // deleteProjectRequestFailed,
  // deleteProjectRequestSuccess,
  fetchBusinessInformationFailed,
  fetchBusinessInformationRequest,
  fetchBusinessInformationSuccess,
  gettingProjectRequest,
  gettingProjectRequestFailed,
  gettingProjectRequestSuccess,
  // paymentFailed,
  // paymentSucced,
  saveDraftProjectStatusRequest,
  saveDraftProjectStatusResponse,
  saveOrderRequest,
  // saveOrderRequestFailed,
  // saveOrderRequestSuccess,
  updateBusinessInformationFailed,
  updateBusinessInformationRequest,
  updateBusinessInformationSuccess,
  updateProjectRequest,
  updateProjectRequestFailed,
  updateProjectRequestSuccess,
} from "./reducer";

function* saveDraftProjectStatus({ payload }: PayloadAction<any>) {
  try {
    localStorage.setItem("draftProject", JSON.stringify(payload));
    sleep(5000);
    yield put(saveDraftProjectStatusResponse);
  } catch (err: any) {
    const message = err?.data?.message || err.message;
    console.log(message);
  }
}

function* saveOrder({ payload }: PayloadAction<any>) {
  // if (payload?.order_id && payload?.orderStatus !== 10) {
  //   try {
  //     const { order } = payload;
  //     if (order?.paymentOption === "paypal") {
  //       yield put(paymentSucced());
  //     } else {
  //       let paymentResp: AxiosResponse<any> = null;
  //       if (payload?.token_id !== null) {
  //         let paymentPayload = {
  //           amount: order?.price,
  //           token: payload?.token_id,
  //         };

  //         paymentResp = yield call(
  //           apiService.processPaymentWithToken,
  //           Number(payload?.order_id),
  //           paymentPayload
  //         );
  //       } else {
  //         if (order?.paymentOption?.pm_id) {
  //           let paymentPayload = {
  //             amount: order?.price,
  //             pm_id: order?.paymentOption?.pm_id,
  //             customer_id: order?.paymentOption?.customer_id,
  //           };
  //           paymentResp = yield call(
  //             apiService.processPaymentWithPaymentMethod,
  //             Number(payload?.order_id),
  //             paymentPayload
  //           );
  //         }
  //       }

  //       if (paymentResp?.data?.payment_status === "succeeded") {
  //         yield put(paymentSucced());
  //       } else {
  //         yield put(
  //           paymentFailed({
  //             type: "error",
  //             message:
  //               "Failed to proceed the payment, please try again later or contact support team.",
  //           })
  //         );
  //         return;
  //       }
  //     }
  //   } catch (err: any) {
  //     yield put(
  //       paymentFailed({
  //         type: "error",
  //         message:
  //           err?.data?.messages?.error ||
  //           "Failed to proceed the payment, please try again later or contact support team.",
  //       })
  //     );
  //     return;
  //   }
  // }

  // try {
  //   const { user_id, order } = payload;
  //   // Create Order
  //   if ("paymentOption" in order) {
  //     delete order.paymentOption;
  //   }
  //   const saveOrderRes: AxiosResponse<any> = yield call(
  //     apiService.saveOrder,
  //     user_id,
  //     {
  //       ...order,
  //       documents: order?.documents?.length || 0,
  //     }
  //   );

  //   const orderId = saveOrderRes.data.orderId;

  //   const documents = order.documents;
  //   const formData = new FormData();
  //   let pageCounts: any[] = [];
  //   let wordCounts: any[] = [];

  //   documents.forEach((document: DocumentType) => {
  //     if (!(document?.file.type === "uploaded")) {
  //       formData.append(`file[]`, document.file);
  //       pageCounts.push(document.pages);
  //       wordCounts.push(document.words);
  //     }
  //   });

  //   formData.append("pages", JSON.stringify(pageCounts));
  //   formData.append("words", JSON.stringify(wordCounts));

  //   if (order.virtualFile) {
  //     formData.append(`virtualFile`, order.virtualFile);
  //   }

  //   let saved_document = documents
  //     ?.filter((it: any) => it.file.type === "uploaded")
  //     .map((it: any) => ({
  //       id: it.id,
  //       pages: it.pages,
  //       words: it.words,
  //     }));

  //   formData.append("saved_document", JSON.stringify(saved_document));

  //   yield call(
  //     apiService.uploadDocuments,
  //     Number(user_id),
  //     Number(orderId),
  //     formData
  //   );

  //   yield put(saveOrderRequestSuccess(saveOrderRes.data.orderId));
  // } catch (err: any) {
  //   yield put(saveOrderRequestFailed());
  // }

  // console.log("err");
}

function* getMyProjects({ payload }: PayloadAction<any>) {
  try {
    const { user_id } = payload;
    const res: AxiosResponse<any> = yield call(
      apiService.getMyProjects,
      user_id
    );
    yield put(gettingProjectRequestSuccess(res.data));
  } catch (err: any) {
    yield put(gettingProjectRequestFailed());
  }
}

function* updateMyProject({ payload }: PayloadAction<any>) {
  try {
    const { order_id, user_id, details } = payload;
    const res: AxiosResponse<any> = yield call(
      apiService.updateProject,
      user_id,
      order_id,
      details
    );
    yield put(updateProjectRequestSuccess(res.data));
  } catch (err: any) {
    yield put(updateProjectRequestFailed());
  }
}

function* fetchBusinessInformation({ payload }: PayloadAction<any>) {
  try {
    const { business_id } = payload;
    const res: AxiosResponse<any> = yield call(
      apiService.fetchBusinessInformation,
      business_id
    );

    yield put(
      fetchBusinessInformationSuccess({
        company_name: res?.data?.business_details?.company_name,
        ein_number: res?.data?.business_details?.ein_number,
        address: res?.data?.business_details?.address,
        state: res?.data?.business_details?.state,
        zip_code: res?.data?.business_details?.zip_code,
      })
    );
  } catch (err: any) {
    yield put(
      fetchBusinessInformationFailed({
        type: "error",
        message:
          err?.data?.messages?.error || "Failed to fetch Business information",
      })
    );
    console.log(err);
  }
}

function* updateBusinessInformation({ payload }: PayloadAction<any>) {
  try {
    console.log(payload);
    const { business_id, details } = payload;
    const res: AxiosResponse<any> = yield call(
      apiService.updateBusinessInformation,
      business_id,
      details
    );
    yield put(updateBusinessInformationSuccess(res.data));
  } catch (err: any) {
    yield put(
      updateBusinessInformationFailed({
        type: "error",
        message:
          err?.data?.messages?.error || "Failed to fetch Business information",
      })
    );
  }
}

// function* deleteProject({ payload }: PayloadAction<any>) {
//   try {
//     const { order_id, user_id } = payload;
//     yield call(apiService.deleteOrder, Number(order_id));

//     yield put(
//       deleteProjectRequestSuccess({
//         user_id: Number(user_id),
//         type: "success",
//         message: "Draft Project has been removed successfully.",
//       })
//     );
//   } catch (err: any) {
//     yield put(
//       deleteProjectRequestFailed({
//         type: "error",
//         message: err?.data?.messages?.error || "Failed to delete project.",
//       })
//     );
//   }
// }

export function* customerProjectsEffects() {
  yield takeLatest(saveDraftProjectStatusRequest.type, saveDraftProjectStatus);
  yield takeLatest(saveOrderRequest.type, saveOrder);
  yield takeLatest(gettingProjectRequest.type, getMyProjects);
  // yield takeLatest(deleteProjectRequestSuccess.type, getMyProjects);
  yield takeLatest(updateProjectRequest.type, updateMyProject);

  yield takeLatest(
    fetchBusinessInformationRequest.type,
    fetchBusinessInformation
  );
  yield takeLatest(
    updateBusinessInformationRequest.type,
    updateBusinessInformation
  );

  // yield takeLatest(deleteProjectRequest.type, deleteProject);
}

const customerProjectsSaga = [call(customerProjectsEffects)];

export default customerProjectsSaga;
