/* eslint-disable react-hooks/exhaustive-deps */
import { DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import IconBox from "components/icons/IconBox";
import { IconAdd, IconArrowDown, IconDownload } from "components/icons/Icons";
import { ScrollPanel } from "components/scrollpanel";
import _ from "lodash";
import moment from "moment-timezone";
import { axiosService } from "network/axios";
import { useEffect, useMemo, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useDispatch, useSelector } from "react-redux";
import { setAlertMessageRequest } from "store/ui/reducer";
import {
  delLanguagesRequest,
  getIDRequest,
  getLanguagesRequest,
  updateLanguagesRequest,
} from "store/user/reducer";
import { languagesSelector, userSelector } from "store/user/selector";
import { supportLanguages } from "views/customer/main/variables/data";
import PersonalDetailsForm from "views/manager/profile/components/PersonalDetailsForm";

export default function PersonalDetails() {
  const buttonBg = "blue.500";

  const options = [
    "Native",
    "Fluent",
    "Advanced (Certified Level Translations)",
  ];
  const shadow = useColorModeValue(
    "0px 4px 50px rgba(0, 0, 0, 0.1)",
    "0px 4px 50px rgba(0, 0, 0, 0.1)"
  );
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const user = useSelector(userSelector);
  useEffect(() => {
    dispatch(
      getLanguagesRequest({
        user_id: user.user_id,
      })
    );
    if (user) {
      onGetDisclosureForms();
    }
  }, []);
  const [disclosures, setDisclosures] = useState([]);
  const [isGettingDisclosure, setIsGettingDisclosure] = useState(false);
  const [isDeletingDisclosure, setIsDeletingDisclosure] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const [languages, setLanguages] = useState([]);
  const userLanguages = useSelector(languagesSelector);
  const addedLanguages = useMemo(() => {
    return userLanguages.map((it) => it.language);
  }, [userLanguages]);
  useEffect(() => {
    setLanguages(userLanguages);
  }, [userLanguages]);
  const [newLanguage, setNewLanguage] = useState(null);
  const [langLevel, setLangLevel] = useState(0);
  const onClickAddNewLang = () => {
    const payload = {
      language: newLanguage,
      level: langLevel,
    };
    dispatch(
      updateLanguagesRequest({
        user_id: user.user_id,
        details: payload,
      })
    );
    onClose();
  };

  const onChangeLevel = (langIndex: any, index: Number) => {
    let dump = _.cloneDeep(languages);
    dump[langIndex] = {
      ...languages[langIndex],
      level: index,
    };
    setLanguages([...dump]);
    dispatch(
      updateLanguagesRequest({
        user_id: user.user_id,
        details: {
          language: dump[langIndex].language,
          level: index,
        },
      })
    );
  };

  const onRemoveLang = (index: Number) => {
    dispatch(
      delLanguagesRequest({
        id: index,
        user_id: user.user_id,
      })
    );
  };

  const onDownloadBasicPDF = async () => {
    const link = document.createElement("a");
    link.href =
      "https://api.languex.co/public/Basic-Non-Disclosure-Agreement.pdf";
    link.target = "_blank";
    link.setAttribute("download", "");
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const onUploadBasicPDF = async (file: File) => {
    setIsUploading(true);
    try {
      const formData = new FormData();
      formData.append("file", file);

      await axiosService.post(
        `/user/manager/put/agreement/${user.user_id}`,
        formData
      );

      dispatch(
        getIDRequest({
          user_id: user.user_id,
        })
      );
    } catch (err: any) {
      dispatch(
        setAlertMessageRequest({
          type: "warning",
          message:
            err?.data?.messages?.error ?? "Failed to download the basic form",
        })
      );
    } finally {
      setIsUploading(false);
      onGetDisclosureForms();
    }
  };

  const onGetDisclosureForms = async () => {
    setIsGettingDisclosure(true);
    try {
      const resp: any = await axiosService.get(
        `/user/get-agreement/${user.user_id}`
      );
      setDisclosures(resp?.data?.result || []);
    } catch (err: any) {
      setDisclosures([]);
      dispatch(
        setAlertMessageRequest({
          type: "error",
          message:
            err?.data?.messages?.error ?? "Failed to get Disclosure Documents",
        })
      );
    } finally {
      setIsGettingDisclosure(false);
    }
  };

  const onRemoveDisclosure = async (id: string) => {
    setIsDeletingDisclosure(id);
    try {
      await axiosService.delete(`/user/del/all/agreement/${id}`);
    } catch (err: any) {
      dispatch(
        setAlertMessageRequest({
          type: "error",
          message:
            err?.data?.messages?.error ?? "Failed to get Disclosure Documents",
        })
      );
    } finally {
      setIsDeletingDisclosure(null);
      await onGetDisclosureForms();
    }
  };

  return (
    <Box>
      <Flex columnGap={{ md: "120px", base: "40px" }}>
        <Card p="0" width="fit-content">
          <Text
            fontWeight="400"
            fontSize="12px"
            lineHeight="24px"
            color="#8E8C8C"
          >
            Role
          </Text>

          <Text
            marginTop="5px"
            fontWeight="400"
            fontSize="17px"
            lineHeight="135%"
            color="#000"
          >
            Translator
          </Text>
        </Card>
      </Flex>
      <Flex marginTop="50px" direction="column">
        <Text
          fontWeight="400"
          fontSize="12px"
          lineHeight="24px"
          color="#8E8C8C"
        >
          Languages
        </Text>
        <Box marginTop="10px">
          {languages?.length > 0 &&
            languages.map((it: any, langIndex: number) => (
              <Flex
                key={langIndex}
                borderBottom="1px solid #E9E9E9"
                padding="15px 0px"
                alignItems="center"
              >
                <Text
                  fontWeight="400"
                  fontSize="17px"
                  lineHeight="21px"
                  color="#000"
                  textTransform="capitalize"
                >
                  {it.language}
                </Text>
                <Menu>
                  <MenuButton
                    marginLeft="auto"
                    padding={"5px 10px 5px 20px"}
                    backgroundColor="#F3F5F5"
                    borderRadius="100px"
                    minW="140px"
                    height="34px"
                  >
                    <Flex alignItems={"center"}>
                      <Text
                        fontSize="12px"
                        lineHeight="24px"
                        fontWeight="400"
                        color="black"
                      >
                        {Number(it.level) === 0
                          ? "Native"
                          : Number(it.level) === 1
                          ? "Fluent"
                          : "Advanced (Certified Level Translations)"}
                      </Text>
                      <IconBox
                        marginLeft="auto"
                        icon={<IconArrowDown width="24px" height="24px" />}
                      ></IconBox>
                    </Flex>
                  </MenuButton>
                  <MenuList
                    boxShadow={shadow}
                    p="0px"
                    mt="10px"
                    borderRadius="20px"
                    bg={"#FFF"}
                    border="none"
                  >
                    <Flex
                      flexDirection="column"
                      p="10px 20px"
                      fontSize={"14px"}
                      lineHeight="24px"
                      fontWeight={"400"}
                      color="black"
                    >
                      {options.map((option, index: number) => (
                        <MenuItem
                          key={index}
                          _hover={{ bg: "none" }}
                          _focus={{ bg: "none" }}
                          borderRadius="8px"
                          padding="15px 0px"
                          borderBottom={
                            index < options.length - 1
                              ? "1px solid #F3F5F5"
                              : ""
                          }
                          onClick={(e) => {
                            onChangeLevel(langIndex, index);
                          }}
                        >
                          <Text fontSize="sm">{option}</Text>
                        </MenuItem>
                      ))}
                    </Flex>
                  </MenuList>
                </Menu>
                <Button
                  padding="5px 20px"
                  fontSize="12px"
                  lineHeight="24px"
                  color="#000"
                  bgColor="#F3F5F5"
                  borderRadius="100px"
                  fontWeight="400"
                  marginLeft="15px"
                  height="34px"
                  onClick={(e) => {
                    onRemoveLang(it.id);
                  }}
                >
                  Remove
                </Button>
              </Flex>
            ))}
          {languages.length < 1 && <Box>No Language</Box>}

          <Flex
            alignItems="center"
            marginTop="25px"
            width="fit-content"
            cursor="pointer"
            color="#000"
            transition="all .3s"
            onClick={() => {
              onOpen();
            }}
            _hover={{
              color: "#2F80ED",
            }}
          >
            <IconBox w="22px" h="22px" icon={<IconAdd w="22px" h="22px" />} />
            <Text
              fontWeight="400"
              fontSize="14px"
              lineHeight="22px"
              marginLeft="10px"
              textDecoration="underline"
            >
              {userLanguages?.length > 0
                ? "Add another language"
                : "Add a language"}
            </Text>
          </Flex>
        </Box>
      </Flex>

      <Box
        py="35px"
        mt="35px"
        borderBottom="1px solid #E3E3E3"
        borderTop="1px solid #E3E3E3"
      >
        <PersonalDetailsForm />
      </Box>
      <Box py="35px">
        <Flex
          columnGap="60px"
          width="100%"
          flexDirection={{ md: "row", base: "column" }}
          rowGap="30px"
        >
          <Text
            fontSize="17px"
            lineHeight="24px"
            color="#000"
            fontWeight="700"
            width={{ md: "130px", base: "100%" }}
            minW={{ md: "130px", base: "100%" }}
          >
            Non-Disclosure Agreement
          </Text>
          <Text fontSize="14px" lineHeight="135%" fontWeight="400" color="#000">
            Download the non-disclosure agreement, sign it, and upload it back
            to your profile.
          </Text>
        </Flex>
        <Flex
          alignItems={{ md: "center", base: "flex-start" }}
          marginTop={{ md: "40px", base: "25px" }}
          flexDirection={{ md: "row", base: "column" }}
          rowGap="20px"
          sx={{
            "label:focus-within": {
              outline: "none !important",
            },
            label: {
              marginLeft: { md: "16px", base: "0px" },
            },
          }}
        >
          <Button
            height="46px"
            padding="15px 35px"
            borderRadius="100px"
            bgColor="#2F80ED"
            color="#FFF"
            fontSize="14px"
            lineHeight="17px"
            fontWeight="bold"
            width="175px"
            isLoading={isDownloading}
            onClick={() => onDownloadBasicPDF()}
          >
            Download
          </Button>
          <FileUploader
            multiple={false}
            handleChange={(file: File) => {
              onUploadBasicPDF(file);
            }}
            name="file"
            height="full"
            types={["pdf"]}
            sx={{
              outline: "none",
            }}
          >
            <Button
              height="46px"
              padding="15px 35px"
              borderRadius="100px"
              bgColor="#FFF"
              border="1px solid #E9E9E9"
              color="#000"
              fontSize="14px"
              lineHeight="17px"
              fontWeight="bold"
              width="175px"
              isLoading={isUploading}
            >
              Upload
              {/* {_.isEmpty(personalID?.none_disclousure_agreement)
                ? "Upload"
                : "Replace"} */}
            </Button>
          </FileUploader>
        </Flex>
        <Box mt={8} mb={10}>
          {disclosures?.length > 0 && (
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th isNumeric width="20px">
                      No
                    </Th>
                    <Th>Uploaded Date</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {disclosures?.map((it: any, index: number) => (
                    <Tr key={index}>
                      <Td isNumeric>{index + 1}</Td>
                      <Td>
                        {moment(new Date(it?.created_at)).format(
                          "YYYY-MM-DD HH:mm"
                        )}
                      </Td>
                      <Td>
                        <Flex
                          alignItems="center"
                          justifyContent="flex-end"
                          columnGap="5px"
                        >
                          <IconBox
                            borderRadius="20px"
                            icon={<IconDownload width="24px" height="24px" />}
                            marginRight="0px"
                            cursor="pointer"
                            _hover={{
                              textDecoration: "underline",
                            }}
                            onClick={() => {
                              const fullUrl = `${process.env.REACT_APP_API_BASE_URL}/${it?.file_location}`;
                              const link = document.createElement("a");
                              link.href = fullUrl;
                              link.target = "_blank";
                              link.setAttribute(
                                "download",
                                `none_disclousre_agreement.pdf`
                              );
                              document.body.appendChild(link);
                              link.click();
                              link.parentNode.removeChild(link);
                            }}
                          ></IconBox>

                          <IconButton
                            aria-label="delete"
                            bg="inherit"
                            color="#F02828"
                            _active={{
                              bg: "inherit",
                              transform: "none",
                              borderColor: "transparent",
                            }}
                            _hover={{
                              bg: "inherit",
                            }}
                            _focus={{
                              boxShadow: "none",
                            }}
                            onClick={() => {
                              onRemoveDisclosure(it?.id);
                            }}
                            isLoading={
                              isDeletingDisclosure &&
                              Number(isDeletingDisclosure) === Number(it?.id)
                            }
                            icon={
                              <DeleteIcon w="20px" h="20px" color="#F02828" />
                            }
                          />
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            fontSize="17px"
            lineHeight="24px"
            fontWeight="700"
            color="#000"
          >
            <Flex alignItems="center" mt="6">
              <Box>Select your Language</Box>
            </Flex>
          </ModalHeader>
          <ModalCloseButton onClick={onClose} />
          <ModalBody pb="35px">
            {addedLanguages?.length > 0 && (
              <Box mb="25px">
                <Text
                  fontSize="14px"
                  lineHeight="24px"
                  color="#8E8C8C"
                  mb="10px"
                >
                  Added the Languages
                </Text>

                <Text
                  fontSize="14px"
                  lineHeight="17px"
                  fontWeight="500"
                  color="#000"
                >
                  {addedLanguages?.join(", ")}
                </Text>
              </Box>
            )}
            <Text fontSize="14px" lineHeight="24px" color="#8E8C8C" mb="20px">
              All Languages
            </Text>
            <ScrollPanel
              height="250px"
              w="full"
              paddingRight="10px"
              sx={{
                overflowX: "hidden",
              }}
            >
              <SimpleGrid
                columns={{ base: 2, md: 3, xl: 3 }}
                columnGap="20px"
                rowGap="15px"
                height="100%"
              >
                {supportLanguages.map((it: string, index: number) => (
                  <Button
                    key={index}
                    padding="15px 30px"
                    fontSize="14px"
                    lineHeight="17px"
                    fontWeight="400"
                    color="#000"
                    bgColor="#FFF"
                    borderRadius="50px"
                    border={
                      newLanguage === it
                        ? "1px solid #2F80ED"
                        : "1px solid #E9E9E9"
                    }
                    width="100%"
                    disabled={addedLanguages.includes(it)}
                    onClick={() => setNewLanguage(it)}
                  >
                    {it}
                  </Button>
                ))}
              </SimpleGrid>
            </ScrollPanel>
            <Text
              fontSize="14px"
              lineHeight="24px"
              color="#8E8C8C"
              mb="10px"
              mt="20px"
            >
              Language Level
            </Text>
            <Menu>
              <MenuButton
                marginLeft="auto"
                padding={"5px 10px 5px 20px"}
                border="1px solid #E9E9E9"
                borderRadius="100px"
                minW="140px"
                height="47px"
              >
                <Flex alignItems={"center"}>
                  <Text
                    fontSize="14px"
                    lineHeight="17px"
                    fontWeight="400"
                    color="black"
                  >
                    {langLevel === 0
                      ? "Native"
                      : langLevel === 1
                      ? "Fluent"
                      : "Advanced (Certified Level Translations)"}
                  </Text>
                  <IconBox
                    marginLeft="auto"
                    icon={<IconArrowDown width="24px" height="24px" />}
                  ></IconBox>
                </Flex>
              </MenuButton>
              <MenuList
                boxShadow={shadow}
                p="0px"
                mt="10px"
                borderRadius="20px"
                bg={"#FFF"}
                border="none"
              >
                <Flex
                  flexDirection="column"
                  p="10px 20px"
                  fontSize={"14px"}
                  lineHeight="24px"
                  fontWeight={"400"}
                  color="black"
                >
                  {options.map((option, index: number) => (
                    <MenuItem
                      key={index}
                      _hover={{ bg: "none" }}
                      _focus={{ bg: "none" }}
                      borderRadius="8px"
                      padding="15px 0px"
                      borderBottom={
                        index < options.length - 1 ? "1px solid #F3F5F5" : ""
                      }
                      onClick={(e) => setLangLevel(index)}
                    >
                      <Text fontSize="sm">{option}</Text>
                    </MenuItem>
                  ))}
                </Flex>
              </MenuList>
            </Menu>
          </ModalBody>
          <Flex
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            w="full"
            columnGap={"12px"}
            padding={"0px 30px 30px"}
          >
            <Button
              type="submit"
              fontSize="14px"
              lineHeight="17px"
              variant="brand"
              bgColor={buttonBg}
              fontWeight="700"
              padding="14px 54px"
              h="45px"
              w="fit-content"
              ml="30px"
              borderRadius={"90px"}
              _loading={{
                pointerEvents: "none",
              }}
              onClick={() => {
                onClickAddNewLang();
              }}
            >
              Add
            </Button>
          </Flex>
        </ModalContent>
      </Modal>
    </Box>
  );
}
