import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type UIState = {
  alert: {
    type: "warning" | "success" | "error";
    message: string;
  };
  searchQuery: string;
  cardData: {
    card_number: string;
    card_holder: string;
    cvv: string;
    zip_code: string;
  };
  showProfileDetails: boolean;
};

const initialState: UIState = {
  alert: null,
  searchQuery: null,
  cardData: {
    card_number: "",
    card_holder: "",
    cvv: "",
    zip_code: "",
  },
  showProfileDetails: false
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setAlertMessageRequest(state, action: PayloadAction<any>) {
      // state.alert = { ...action.payload };
    },
    setAlertMessageSuccess(state, action: PayloadAction<any>) {
      state.alert = action.payload;
    },

    setSearchQuery(state, action: PayloadAction<any>) {
      state.searchQuery = action.payload;
    },

    setSaveCardDataRequest(state, action: PayloadAction<any>) {
      state.cardData = action.payload;
    },

    clearSaveCardDataRequest(state) {
      state.cardData = {
        card_number: "",
        card_holder: "",
        cvv: "",
        zip_code: "",
      };
    },

    setShowProfileDetailsStatus(state, action: PayloadAction<any>) {
      state.showProfileDetails = action.payload.status;
    }
  },
});

export const {
  setAlertMessageRequest,
  setAlertMessageSuccess,
  setSearchQuery,
  setSaveCardDataRequest,
  clearSaveCardDataRequest,
  setShowProfileDetailsStatus
} = uiSlice.actions;

export default uiSlice.reducer;
