/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Badge,
  Box,
  Button,
  Flex,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { IconArrowRight, IconSendMsg } from "components/icons/Icons";
import MessageItem from "components/messagebox/MessageItem";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "store/user/selector";
import { useEffect, useMemo, useRef, useState } from "react";
import moment from "moment-timezone";

import {
  isSedningMessagesSelector,
  messagesSelector,
  notificationDetailsSelector,
  receiversSelector,
} from "store/project/selector";
import {
  getMessagesRequest,
  getReceiversRequest,
  sendMessageRequest,
} from "store/project/reducer";
import emptyAvatar from "assets/img/avatars/avatar.png";
import _ from "lodash";
import { fetchUserRoles } from "network/helper";

export default function MessageBox(props: { projectId: string }) {
  const { projectId } = props;
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const receivers = useSelector(receiversSelector);
  const notificationDetails = useSelector(notificationDetailsSelector);
  const isSendingMessage = useSelector(isSedningMessagesSelector);
  const [selectedReceiver, setSelectedReceiver] = useState(null);
  const messages = useSelector(messagesSelector);
  const containerRef = useRef(null);
  const [pageNumber, setPageNumber] = useState(1);
  useEffect(() => {
    if (containerRef && projectId) {
      setTimeout(() => {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }, 500);
    }

    return () => {};
  }, [containerRef, projectId, messages]);

  const notificationCountBySenderId = (sender_id: Number) => {
    const filteredItems = notificationDetails?.filter(
      (it) =>
        Number(it.sender_id) === sender_id &&
        Number(it.order_id) === Number(projectId)
    );
    return filteredItems?.length || 0;
  };

  const [message, setMessage] = useState("");
  const receiverList = useMemo(() => {
    return receivers
      ?.map((it) => ({
        id: it?.user_id,
        name: `${it?.first_name} ${it?.last_name}`,
        user_role: it.user_role_type_id,
        profile_pic_src: it.profile_pic_src
          ? it.profile_pic_src?.includes("http")
            ? it.profile_pic_src
            : `${process.env.REACT_APP_API_BASE_URL}/public_html/images/${it.profile_pic_src}`
          : emptyAvatar,
      }))
      ?.slice((pageNumber - 1) * 10, (pageNumber - 1) * 10 + 10);
  }, [receivers, pageNumber]);

  const totalPageNumber = useMemo(() => {
    return receivers.length % 10 === 0
      ? receivers.length / 10
      : Math.floor(receivers.length / 10) + 1;
  }, [receivers]);

  const totalCnt = useMemo(() => {
    let total = 0;
    for (let i = 0; i < receivers.length; i++) {
      const filteredItems = notificationDetails?.filter(
        (it) =>
          Number(it.sender_id) === Number(receivers[i].user_id) &&
          Number(projectId) === Number(it.order_id)
      );
      total += filteredItems?.length || 0;
    }
    return total;
  }, [receivers, notificationDetails, projectId]);

  const chatLogs = useMemo(() => {
    if (Number(user?.user_role_type_id) !== 1) {
      if (selectedReceiver) {
        const { received, sent } = messages;
        const receivedMessage =
          received
            ?.filter(
              (it: any) => Number(it.sender_id) === Number(selectedReceiver.id)
            )
            ?.map((it: any) => ({
              id: it.message_id,
              type: "sender",
              name: `${it?.first_name} ${it?.last_name}`,
              message: it.message,
              date: moment(new Date(it?.send_at)).format("MMM. DD - hh:mm a"),
              dateStamp: new Date(it?.send_at).getTime(),
              profile_pic_src: it.profile_pic_src
                ? it.profile_pic_src?.includes("http")
                  ? it.profile_pic_src
                  : `${process.env.REACT_APP_API_BASE_URL}/public_html/images/${it.profile_pic_src}`
                : emptyAvatar,
              read_status: it?.read_status,
            })) || [];

        const sendMessage =
          sent
            ?.filter(
              (it: any) =>
                Number(it.receiver_id) === Number(selectedReceiver.id)
            )
            ?.map((it: any) => ({
              id: it.message_id,
              type: "receiver",
              name: `You`,
              message: it.message,
              date: moment(new Date(it?.send_at)).format("MMM. DD - hh:mm a"),
              dateStamp: new Date(it?.send_at).getTime(),
              profile_pic_src: it.profile_pic_src
                ? it.profile_pic_src?.includes("http")
                  ? it.profile_pic_src
                  : `${process.env.REACT_APP_API_BASE_URL}/public_html/images/${it.profile_pic_src}`
                : emptyAvatar,
              read_status: it?.read_status,
            })) || [];

        const totalMessages = receivedMessage.concat(sendMessage);
        totalMessages.sort(function compare(a: any, b: any) {
          let dateA = new Date(a.dateStamp).getTime();
          let dateB = new Date(b.dateStamp).getTime();
          return dateA - dateB;
        });

        return totalMessages;
      } else {
        return [];
      }
    } else {
      const { received, sent } = messages;
      const receivedMessage =
        received?.map((it: any) => ({
          id: it.message_id,
          type: "sender",
          name: `${it?.first_name} ${it?.last_name}`,
          message: it.message,
          date: moment(new Date(it?.send_at)).format("MMM. DD - hh:mm a"),
          dateStamp: new Date(it?.send_at).getTime(),
          sender_role: fetchUserRoles(Number(it?.user_role_type_id)),
          profile_pic_src: it.profile_pic_src
            ? it.profile_pic_src?.includes("http")
              ? it.profile_pic_src
              : `${process.env.REACT_APP_API_BASE_URL}/public_html/images/${it.profile_pic_src}`
            : emptyAvatar,
          read_status: it?.read_status,
        })) || [];
      const sendMessage =
        _.uniqBy(sent, "send_at")?.map((it: any) => ({
          id: it.message_id,
          type: "receiver",
          name: `You`,
          message: it.message,
          date: moment(new Date(it?.send_at)).format("MMM. DD - hh:mm a"),
          dateStamp: new Date(it?.send_at).getTime(),
          profile_pic_src: it.profile_pic_src
            ? it.profile_pic_src?.includes("http")
              ? it.profile_pic_src
              : `${process.env.REACT_APP_API_BASE_URL}/public_html/images/${it.profile_pic_src}`
            : emptyAvatar,
          read_status: it?.read_status,
        })) || [];
      const totalMessages = receivedMessage.concat(sendMessage);
      totalMessages.sort(function compare(a: any, b: any) {
        let dateA = new Date(a.dateStamp).getTime();
        let dateB = new Date(b.dateStamp).getTime();
        return dateA - dateB;
      });

      return totalMessages;
    }
  }, [messages, selectedReceiver]);

  useEffect(() => {
    if (receiverList?.length > 0 && !selectedReceiver) {
      // notificationCountBySenderId
      const receiverListHasNotifications = receiverList.filter(
        (it) => notificationCountBySenderId(Number(it.id)) > 0
      );
      if (receiverListHasNotifications.length > 0) {
        setSelectedReceiver(receiverListHasNotifications[0]);
      } else {
        setSelectedReceiver(receiverList[0]);
      }
    }
  }, [receiverList, notificationDetails]);

  useEffect(() => {
    if (projectId) {
      dispatch(
        getReceiversRequest({
          user_id: user.user_id,
          order_id: projectId,
        })
      );
    }
  }, [projectId]);

  useEffect(() => {
    if (projectId && selectedReceiver) {
      dispatch(
        getMessagesRequest({
          sender_id: user.user_id,
          receiver_id: selectedReceiver.id,
          order_id: projectId,
        })
      );
    }
  }, [projectId, selectedReceiver]);

  const shadow = useColorModeValue(
    "0px 4px 50px rgba(0, 0, 0, 0.1)",
    "0px 4px 50px rgba(0, 0, 0, 0.1)"
  );

  const onSendMessage = () => {
    if (Number(user.user_role_type_id) === 1) {
      // Translators
      const translators = receiverList?.filter(
        (it) => Number(it.user_role) === 6
      );
      const managers = receiverList?.filter((it) => Number(it.user_role) === 3);
      const admins = receiverList?.filter((it) => Number(it.user_role) === 4);

      if (translators?.length > 0 || managers?.length > 0) {
        translators.forEach((it) => {
          const payload = {
            sender_id: user.user_id,
            receiver_id: it.id,
            order_id: projectId,
            message: message,
          };
          dispatch(sendMessageRequest(payload));
        });
        managers.forEach((it) => {
          const payload = {
            sender_id: user.user_id,
            receiver_id: it.id,
            order_id: projectId,
            message: message,
          };
          dispatch(sendMessageRequest(payload));
        });
      } else {
        admins.forEach((admin) => {
          const payload = {
            sender_id: user.user_id,
            receiver_id: admin.id,
            order_id: projectId,
            message: message,
          };
          dispatch(sendMessageRequest(payload));
        });
      }
      setMessage("");
    } else {
      const payload = {
        sender_id: user.user_id,
        receiver_id: selectedReceiver.id,
        order_id: projectId,
        message: message,
      };

      setMessage("");

      dispatch(sendMessageRequest(payload));
    }
  };

  const onSubmitMessage = () => {
    if (message?.trim()?.length > 0) {
      const payload = {
        sender_id: user.user_id,
        receiver_id: selectedReceiver.id,
        order_id: projectId,
        message: message,
      };

      setMessage("");

      dispatch(sendMessageRequest(payload));
    }
  };

  return (
    <Box padding="15px 0px" marginTop="auto" w="full">
      <Box
        className="scroll-panel"
        padding="30px 20px 30px"
        mt="20px"
        maxHeight="230px"
        ref={containerRef}
      >
        <Flex columnGap="35px" flexDir="column" rowGap="10px">
          {chatLogs?.map((it: any, index: number) => (
            <MessageItem
              key={index}
              details={it}
              type={it.type}
              title={it.name}
              message={it.message}
              date={it.date}
              avatar={it.profile_pic_src}
              readStatus={it.read_status}
              id={it?.id}
              containerRef={containerRef}
              sx={{
                marginLeft: it?.type === "receiver" ? "auto" : "0",
                marginRight: it?.type === "sender" ? "auto" : "0",
              }}
            />
          ))}
        </Flex>
      </Box>
      <Flex
        padding="10px"
        borderRadius="5px"
        rowGap="10px"
        columnGap="15px"
        flexDir={{ md: "row", base: "column" }}
      >
        <Box flex="1">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmitMessage();
            }}
          >
            <Input
              fontSize="17px"
              lineHeight="24px"
              placeholder="Write your message here..."
              padding={"15px"}
              type="text"
              border="none"
              backgroundColor="#F8F8F8"
              h="full"
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              _focus={{
                border: "none",
              }}
              _placeholder={{
                fontSize: "17px",
                lineHeight: "24px",
                textAlign: "left",
                fontWeight: "400",
                color: "black",
              }}
            />
          </form>
        </Box>
        <Flex
          alignItems="center"
          columnGap="15px"
          rowGap="10px"
          flexDirection={{
            "3sm": "row",
            base: "column-reverse",
          }}
        >
          <Button
            padding="10px 30px"
            bgColor="#FFF"
            border="1px solid #2F80ED"
            color="#2F80ED"
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
            borderRadius="50px"
            w={{ "3sm": "fit-content", base: "100%" }}
            h={{ md: "fit-content" }}
            minW="fit-content"
            minH="fit-content"
            disabled={message?.trim().length < 1 || isSendingMessage}
            onClick={() => {
              onSendMessage();
            }}
            _disabled={{
              color: "#2F80ED66",
              borderColor: "#2F80ED66",
              cursor: "not-allowed",
              bgColor: "white !important",
              "svg path": {
                fill: "#2F80ED66 !important",
              },
            }}
          >
            {isSendingMessage ? "Sending..." : "Send"}
            <IconSendMsg width="24px" height="25px" marginLeft="15px" />
          </Button>
          {Number(user?.user_role_type_id) !== 1 && (
            <Menu>
              {selectedReceiver && (
                <MenuButton
                  marginLeft={{ "3sm": "auto", base: "0px" }}
                  marginRight={{ "3sm": "0px", base: "auto" }}
                  backgroundColor="transparent"
                >
                  <Flex
                    alignItems={"center"}
                    flexDirection={{ "3sm": "row", base: "row-reverse" }}
                    columnGap="9px"
                  >
                    <Box>
                      <Text
                        fontSize="14px"
                        lineHeight="24px"
                        textAlign={{ "3sm": "right", base: "left" }}
                        fontWeight="700"
                        color="#000"
                      >
                        {selectedReceiver.name}
                      </Text>
                      <Text
                        fontSize="14px"
                        lineHeight="24px"
                        textAlign={{ "3sm": "right", base: "left" }}
                        fontWeight="400"
                        color="#000"
                        textTransform="capitalize"
                        marginTop="-10px"
                      >
                        online
                      </Text>
                    </Box>
                    <Box position="relative">
                      <Avatar
                        src={selectedReceiver?.profile_pic_src}
                        _hover={{ cursor: "pointer" }}
                        w="45px"
                        h="45px"
                        bgColor={"transparent"}
                      />
                      {totalCnt > 0 && (
                        <Badge
                          bgColor={"#F02828"}
                          sx={{
                            padding: "9px 6px !important",
                            borderRadius: "1000px",
                            fontWeight: "700",
                            fontSize: "10px",
                            lineHeight: "0px",
                            color: "#FFF",
                            minWidth: "18px",
                            position: "absolute",
                            transform: "translateX(-50%)",
                          }}
                        >
                          {totalCnt}
                        </Badge>
                      )}
                    </Box>
                  </Flex>
                </MenuButton>
              )}
              <MenuList
                width="195px"
                minWidth="195px"
                boxShadow={shadow}
                p="0px"
                mt="10px"
                borderRadius="20px"
                bg={"#FFF"}
                border="none"
              >
                <Flex
                  flexDirection="column"
                  p="10px 20px"
                  fontSize={"14px"}
                  lineHeight="24px"
                  fontWeight={"400"}
                  color="black"
                >
                  {receiverList.map((receiver, index: number) => (
                    <MenuItem
                      key={index}
                      _hover={{ bg: "none" }}
                      _focus={{ bg: "none" }}
                      borderRadius="8px"
                      padding="10px 0px"
                      onClick={() => {
                        setSelectedReceiver(receiver);
                      }}
                      borderBottom={
                        index < receiverList.length - 1
                          ? "1px solid #F3F5F5"
                          : ""
                      }
                    >
                      <Text fontSize="sm">{receiver.name}</Text>
                      {notificationCountBySenderId(Number(receiver.id)) > 0 && (
                        <Badge
                          bgColor={"#F02828"}
                          sx={{
                            padding: "9px 6px !important",
                            borderRadius: "1000px",
                            fontWeight: "700",
                            fontSize: "10px",
                            lineHeight: "0px",
                            color: "#FFF",
                            marginLeft: "auto",
                            minWidth: "18px",
                          }}
                        >
                          {notificationCountBySenderId(Number(receiver.id))}
                        </Badge>
                      )}
                    </MenuItem>
                  ))}

                  {totalPageNumber > 1 && (
                    <Flex align="center" mt="10px">
                      <Button
                        sx={{
                          minWidth: "24px",
                          height: "24px",
                          padding: "0px",
                          borderRadius: "0px",
                        }}
                        disabled={pageNumber === 1}
                        onClick={() => {
                          setPageNumber(
                            pageNumber > 1 ? pageNumber - 1 : pageNumber
                          );
                        }}
                      >
                        <IconArrowRight
                          fontSize="22px"
                          sx={{
                            transform: "rotate(180deg)",
                          }}
                        />
                      </Button>
                      <Text
                        sx={{
                          width: "32px",
                        }}
                        textAlign="center"
                      >
                        {pageNumber}
                      </Text>
                      <Button
                        sx={{
                          minWidth: "24px",
                          height: "24px",
                          padding: "0px",
                          borderRadius: "0px",
                        }}
                        disabled={pageNumber === totalPageNumber}
                        onClick={() => {
                          setPageNumber(
                            pageNumber < totalPageNumber
                              ? pageNumber + 1
                              : pageNumber
                          );
                        }}
                      >
                        <IconArrowRight
                          fontSize="22px"
                          sx={{
                            transform: "rotate(0deg)",
                          }}
                        />
                      </Button>
                    </Flex>
                  )}
                </Flex>
              </MenuList>
            </Menu>
          )}
        </Flex>
      </Flex>
    </Box>
  );
}
