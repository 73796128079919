import { Box, Flex, Image, Tag, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeReadStatusMessageRequest } from "store/project/reducer";
import { userSelector } from "store/user/selector";

export default function MessageItem(props: {
  type: "sender" | "receiver";
  id: string;
  date: string;
  title: string;
  message: string;
  avatar: string;
  containerRef: any;
  readStatus: string;
  details: any;
  [x: string]: any;
}) {
  const {
    type,
    date,
    title,
    message,
    avatar,
    containerRef,
    readStatus,
    id,
    details,
    ...rest
  } = props;
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const boxShadow = "0px 4px 30px rgba(0, 0, 0, 0.1)";
  const [messageReadStatus, setMessageReadStatus] = useState(
    Number(readStatus)
  );
  useEffect(() => {
    setMessageReadStatus(Number(readStatus));
  }, [readStatus]);

  const onMarkAsRead = () => {
    dispatch(
      changeReadStatusMessageRequest({
        message_id: id,
        user_id: user?.user_id || null,
      })
    );
    setMessageReadStatus(1);
  };

  return (
    <Box {...rest}>
      <Flex mb="6px">
        <Text
          fontSize="12px"
          lineHeight="24px"
          color="#8E8C8C"
          fontWeight="400"
        >
          {date}
        </Text>
        {Number(messageReadStatus) === 0 && type === "sender" && (
          <Text
            ml={1}
            fontSize="12px"
            lineHeight="24px"
            color="#F00"
            fontWeight="400"
          >
            ~ Unread Message ~
          </Text>
        )}
      </Flex>
      <Flex
        flexWrap="wrap"
        flexDirection={type === "receiver" ? "row-reverse" : "row"}
        columnGap={{ md: "30px", base: "10px" }}
        padding={{ md: "16px 22px", base: "12px" }}
        bgColor="#FFF"
        boxShadow={boxShadow}
        borderRadius="10px"
      >
        <Image src={avatar} w="46px" h="46px" borderRadius="50%" />
        <Flex direction="column" flex="1">
          <Text fontSize="14px" lineHeight="24px" fontWeight="700" color="#000">
            {title}

            {type === "sender" && Number(user.user_role_type_id) === 1 && (
              <Tag ml={2} colorScheme="blue" fontSize="12px">
                {details?.sender_role}
              </Tag>
            )}
          </Text>
          <Text
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
            color="#000"
            sx={{
              span: {
                "&.highlights": {
                  padding: "5px",
                  background: "#EFEFEF",
                  display: "block",
                  borderRadius: "5px",
                  marginTop: "5px",
                },
              },
            }}
            dangerouslySetInnerHTML={{ __html: message }}
          ></Text>
          {Number(messageReadStatus) === 0 && type === "sender" && (
            <Box
              fontSize="10px"
              fontWeight="600"
              textTransform="uppercase"
              mt="5px"
              cursor="pointer"
              textDecor="underline"
              color="#2F80ED"
              width="fit-content"
              transition="color .25s"
              _hover={{
                color: "#2F80ED",
              }}
              onClick={() => {
                onMarkAsRead();
              }}
            >
              Mark as Read
            </Box>
          )}
        </Flex>
      </Flex>
    </Box>
  );
}
