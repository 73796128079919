import { Box, Button, Flex } from "@chakra-ui/react";
import IconBox from "components/icons/IconBox";
import { IconPaypal } from "components/icons/Icons";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateProjectStatus } from "store/customerProject/reducer";
import { uploadProjectSelector } from "store/customerProject/selector";

export default function PaypalModal(props: {
  totalPrice: number;
  completeOrder: Function;
}) {
  const { totalPrice, completeOrder } = props;
  const uploadProject = useSelector(uploadProjectSelector);
  const paypalRef = useRef();
  const paypalClientId =
    "Af6I6CYcmbuTa7JXq_e8jN28fTimYsTDyNJEaz-QxUYiBuQHJJIDDjEXDOG-BG1qDEmyZGz95cvRieyG";
  const dispatch = useDispatch();
  // const connectPaypal = () => {
  //   dispatch(
  //     updateProjectStatus({
  //       paymentOption: "paypal",
  //     })
  //   );
  // };

  useEffect(() => {
    window &&
      (window as any).paypal
        .Buttons({
          createOrder: function (data: any, actions: any) {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: totalPrice,
                  },
                },
              ],
            });
          },
          onApprove: function (data: any, actions: any) {
            return actions.order.capture().then(function (orderData: any) {
              // Successful capture! For demo purposes:
              var transaction =
                orderData.purchase_units[0].payments.captures[0];
              console.log(transaction);
              completeOrder();
            });
          },
        })
        .render(paypalRef.current);
  }, []);

  return <Box ref={paypalRef}></Box>;
}
