import { Avatar, Button, Flex, Input } from "@chakra-ui/react";
import emptyAvatar from "assets/img/avatars/avatar.png";
import { ChangeEvent, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteAvatarRequest, uploadAvatarRequest } from "store/user/reducer";
import { userSelector } from "store/user/selector";

export default function ProfilePhotoEditor() {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const avatarRef = useRef();
  const userAvatar = useMemo(() => {
    if (user) {
      return user.profile_pic_src
        ? user.profile_pic_src?.includes("http")
          ? user.profile_pic_src
          : `${process.env.REACT_APP_API_BASE_URL}/public_html/images/${user.profile_pic_src}`
        : emptyAvatar;
    } else {
      return emptyAvatar;
    }
  }, [user]);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    dispatch(
      uploadAvatarRequest({
        user_id: user.user_id,
        form_data: formData,
      })
    );
  };

  const onDeleteAvatar = () => {
    dispatch(
      deleteAvatarRequest({
        user_id: user.user_id,
      })
    );
  };

  return (
    <Flex>
      <Avatar
        src={userAvatar}
        _hover={{ cursor: "pointer" }}
        color="white"
        name="Name"
        bg="transparent"
        size="sm"
        w={{ md: "125px", base: "100px" }}
        h={{ md: "125px", base: "100px" }}
      />
      <Flex direction="column" marginLeft="13px" justifyContent="center">
        <Input
          type="file"
          display="none"
          ref={avatarRef}
          id="file-selector"
          accept="image/png, image/gif, image/jpeg"
          onChange={handleFileChange}
        />
        <Button
          padding="15px 30px"
          fontSize="14px"
          lineHeight="17px"
          fontWeight="400"
          color="#000"
          bgColor="#FFF"
          borderRadius="50px"
          border="1px solid #E9E9E9"
          width="fit-content"
          onClick={(e) => {
            document.getElementById("file-selector").click();
          }}
        >
          Update Profile Picture
        </Button>
        <Button
          marginTop="13px"
          padding="15px 30px"
          fontSize="14px"
          lineHeight="17px"
          fontWeight="400"
          color="#000"
          bgColor="#FFF"
          borderRadius="50px"
          border="1px solid #E9E9E9"
          width="fit-content"
          onClick={(e) => {
            onDeleteAvatar();
          }}
        >
          Delete Image
        </Button>
      </Flex>
    </Flex>
  );
}
