import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { Field, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { updateUserRequest } from "store/user/reducer";
import { isUpdatingUserSelector, userSelector } from "store/user/selector";

type CustomerProfileFormInputs = {
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  password: string;
};
const inputStyleNormal = {
  fontSize: "14px",
  lineHeight: "17px",
  color: "black",
  border: "1px solid #E9E9E9",
  borderRadius: "50px",
  padding: "15px 30px",
  base: "15px",
  height: { md: "60px", base: "45px" },
  bgColor: "#FFF",
  boxShadow: "none !important",
};

const inputStyle = {
  fontSize: "14px",
  lineHeight: "17px",
  color: "black",
  border: "none !important",
  boxShadow: "none !important",
  bgColor: "transparent",
};

export default function CustomerProfileDetailsForm() {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const isUpdating = useSelector(isUpdatingUserSelector);
  const initialValues: CustomerProfileFormInputs = {
    first_name: user?.first_name || "",
    last_name: user?.last_name || "",
    phone: user?.phone || "",
    email: user?.email || "",
    password: "",
  };

  const onUpdateUser = (values: any) => {
    dispatch(
      updateUserRequest({
        user_id: user.user_id,
        user_details: values,
      })
    );
  };

  return (
    <FormControl mt="40px">
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          console.log(values);
          onUpdateUser(values);
        }}
      >
        {({ handleSubmit, errors, touched }) => (
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <FormControl
              isInvalid={!!errors.first_name && touched.first_name}
              mb="12px"
            >
              <FormLabel
                fontSize="12px"
                lineHeight="15px"
                fontWeight="400"
                color="#8E8C8C"
                textTransform="capitalize"
                padding="5px"
                bgColor="#FFF"
                mr="0px"
                width="fit-content"
                position="relative"
                zIndex="10"
                ml="30px"
                mb="-13px"
              >
                First Name
              </FormLabel>
              <Field
                as={Input}
                type="text"
                id="first_name"
                name="first_name"
                placeholder="First Name"
                sx={{
                  ...inputStyleNormal,
                  fontWeight: "500",
                }}
                _placeholder={{
                  ...inputStyle,
                  fontWeight: "400",
                }}
                validate={(value: any) => {
                  let error;
                  if (value.length < 1) {
                    error = "First Name is required.";
                  }
                  return error;
                }}
              />
              <FormErrorMessage
                mt={1}
                pl={"30px"}
                sx={{
                  color: "#F00",
                  justifyContent: "flex-start",
                }}
              >
                {errors.first_name}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={!!errors.last_name && touched.last_name}
              mb="12px"
            >
              <FormLabel
                fontSize="12px"
                lineHeight="15px"
                fontWeight="400"
                color="#8E8C8C"
                textTransform="capitalize"
                padding="5px"
                bgColor="#FFF"
                mr="0px"
                width="fit-content"
                position="relative"
                zIndex="10"
                ml="30px"
                mb="-13px"
              >
                Last Name
              </FormLabel>
              <Field
                as={Input}
                type="text"
                id="last_name"
                name="last_name"
                placeholder="Last Name"
                sx={{
                  ...inputStyleNormal,
                  fontWeight: "500",
                }}
                _placeholder={{
                  ...inputStyle,
                  fontWeight: "400",
                }}
                validate={(value: any) => {
                  let error;
                  if (value.length < 1) {
                    error = "Last Name is required.";
                  }
                  return error;
                }}
              />
              <FormErrorMessage
                mt={1}
                pl={"30px"}
                sx={{
                  color: "#F00",
                  justifyContent: "flex-start",
                }}
              >
                {errors.last_name}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.phone && touched.phone} mb="12px">
              <FormLabel
                fontSize="12px"
                lineHeight="15px"
                fontWeight="400"
                color="#8E8C8C"
                textTransform="capitalize"
                padding="5px"
                bgColor="#FFF"
                mr="0px"
                width="fit-content"
                position="relative"
                zIndex="10"
                ml="30px"
                mb="-13px"
              >
                Phone Number
              </FormLabel>
              <Field
                as={Input}
                type="tel"
                id="phone"
                name="phone"
                placeholder="Phone Number"
                sx={{
                  ...inputStyleNormal,
                  fontWeight: "500",
                }}
                _placeholder={{
                  ...inputStyle,
                  fontWeight: "400",
                }}
                validate={(value: any) => {
                  let error;
                  if (value.length < 1) {
                    error = "Phone Number is required.";
                  }
                  return error;
                }}
              />
              <FormErrorMessage
                mt={1}
                pl={"30px"}
                sx={{
                  color: "#F00",
                  justifyContent: "flex-start",
                }}
              >
                {errors.phone}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.email && touched.email} mb="12px">
              <FormLabel
                fontSize="12px"
                lineHeight="15px"
                fontWeight="400"
                color="#8E8C8C"
                textTransform="capitalize"
                padding="5px"
                bgColor="#FFF"
                mr="0px"
                width="fit-content"
                position="relative"
                zIndex="10"
                ml="30px"
                mb="-13px"
              >
                Email
              </FormLabel>
              <Field
                as={Input}
                type="email"
                id="email"
                name="email"
                placeholder="Email Address"
                sx={{
                  ...inputStyleNormal,
                  fontWeight: "500",
                }}
                _placeholder={{
                  ...inputStyle,
                  fontWeight: "400",
                }}
                validate={(value: any) => {
                  let error;
                  if (value.length < 1) {
                    error = "Email Address is required.";
                  }
                  return error;
                }}
              />
              <FormErrorMessage
                pl={"30px"}
                mt={1}
                sx={{
                  color: "#F00",
                  justifyContent: "flex-start",
                }}
              >
                {errors.email}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              // isInvalid={!!errors.password && touched.password}
              mb="12px"
            >
              <FormLabel
                fontSize="12px"
                lineHeight="15px"
                fontWeight="400"
                color="#8E8C8C"
                textTransform="capitalize"
                padding="5px"
                bgColor="#FFF"
                mr="0px"
                width="fit-content"
                position="relative"
                zIndex="10"
                ml="30px"
                mb="-13px"
              >
                Password
              </FormLabel>
              <Field
                as={Input}
                type="password"
                id="password"
                name="password"
                placeholder="Change Password"
                sx={{
                  ...inputStyleNormal,
                  fontWeight: "500",
                }}
                _placeholder={{
                  ...inputStyle,
                  fontWeight: "400",
                }}
              />
            </FormControl>

            <Box mt="20px" textAlign="right">
              <Button
                type="submit"
                bgColor="#8E8C8C"
                padding="15px 40px"
                fontWeight="700"
                fontSize="14px"
                lineHeight="17px"
                color="#FFF"
                height="48px"
                width="176px"
                borderRadius="50px"
                isLoading={isUpdating}
              >
                Save Changes
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </FormControl>
  );
}
