/* eslint-disable react-hooks/exhaustive-deps */
// Chakra Imports
import {
  Avatar,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "store/user/selector";
import { useMemo } from "react";
import { logoutRequest } from "store/user/reducer";
import { setShowProfileDetailsStatus } from "store/ui/reducer";

export default function UserInfoMenu() {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const user = useSelector(userSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userName = useMemo(() => {
    if (user) {
      return `${user.first_name} ${user.last_name}`;
    } else {
      return "";
    }
  }, [user]);

  const userAvatar = useMemo(() => {
    if (user) {
      return user.profile_pic_src
        ? user.profile_pic_src?.includes("http")
          ? user.profile_pic_src
          : `${baseUrl}/public_html/images/${user.profile_pic_src}`
        : null;
    } else {
      return null;
    }
  }, [user]);
  let menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );

  const onLogOut = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("user_details");
    dispatch(logoutRequest());
    navigate("/auth/sign-in/");
  };

  const openProfileDetails = () => {
    dispatch(
      setShowProfileDetailsStatus({
        status: true,
      })
    );
  };

  return (
    <Menu>
      <MenuButton p="0px" bgColor={"transparent"}>
        <Flex alignItems={"center"}>
          <Text
            fontSize={"14px"}
            lineHeight="24px"
            fontWeight={"400"}
            color="black"
            mr="18px"
            display={{ sm: "none", md: "block" }}
          >
            {userName}
          </Text>
          <Avatar
            src={userAvatar}
            _hover={{ cursor: "pointer" }}
            color="white"
            name={userName}
            bg="#11047A"
            size="sm"
            w="50px"
            h="50px"
          />
        </Flex>
      </MenuButton>
      <MenuList
        boxShadow={shadow}
        p="0px"
        mt="10px"
        borderRadius="20px"
        bg={menuBg}
        border="none"
      >
        <Flex w="100%" mb="0px">
          <Text
            ps="20px"
            pt="16px"
            pb="10px"
            w="100%"
            borderBottom="1px solid"
            borderColor={borderColor}
            fontSize="sm"
            fontWeight="700"
            color={textColor}
          >
            👋&nbsp; Hey, {userName}
          </Text>
        </Flex>
        <Flex flexDirection="column" p="10px">
          <MenuItem
            _hover={{ bg: "none" }}
            _focus={{ bg: "none" }}
            borderRadius="8px"
            px="14px"
            onClick={(e) => openProfileDetails()}
          >
            <Text fontSize="sm">Profile Settings</Text>
          </MenuItem>

          <MenuItem
            _hover={{ bg: "none" }}
            _focus={{ bg: "none" }}
            color="red.400"
            borderRadius="8px"
            px="14px"
            onClick={onLogOut}
          >
            <Text fontSize="sm">Log out</Text>
          </MenuItem>
        </Flex>
      </MenuList>
    </Menu>
  );
}
