/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Flex, Link, useColorModeValue } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import AdminNavbarLinks from "components/navbar/NavbarLinksAdmin";
import ProfileDetails from "views/admin/profile";
import TranslatorProfileDetails from "views/translator/profile";
import ManagerProfileDetails from "views/manager/profile";
import { useLocation, useNavigate } from "react-router-dom";
import CustomerProfileDetails from "views/customer/profile";
import { SidebarResponsive } from "components/sidebar/Sidebar";
import routes from "routes";
import { useDispatch, useSelector } from "react-redux";
import { showProfileDetailsSelector } from "store/ui/selector";
import {
  setAlertMessageRequest,
  setShowProfileDetailsStatus,
} from "store/ui/reducer";
import { logoutRequest } from "store/user/reducer";

export default function AdminNavbar(props: {
  secondary: boolean;
  message: string | boolean;
  brandText: string;
  logoText: string;
  fixed: boolean;
  onOpen: (...args: any[]) => any;
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [scrolled, setScrolled] = useState(false);
  const showProfileDetails = useSelector(showProfileDetailsSelector);
  const location = window.location.href;
  const routerLocation = useLocation();

  useEffect(() => {
    const expires_at = localStorage.getItem("expired_at")
      ? Number(localStorage.getItem("expired_at"))
      : null;
    if (expires_at == null || expires_at < new Date().getTime()) {
      // Session has been expired.
      dispatch(
        setAlertMessageRequest({
          type: "error",
          message: "Session expired! Please sign in again...",
        })
      );
      localStorage.removeItem("access_token");
      localStorage.removeItem("user_details");
      dispatch(logoutRequest());
      navigate("/auth/sign-in/");
    }
  }, [routerLocation]);

  useEffect(() => {
    window.addEventListener("scroll", changeNavbar);

    return () => {
      window.removeEventListener("scroll", changeNavbar);
    };
  });

  const { secondary, brandText } = props;
  // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
  let mainText = useColorModeValue("black", "white");
  let navbarPosition = "absolute" as const;
  let navbarFilter = "none";
  let navbarBackdrop = "none";
  let navbarBg = "transparent";
  let navbarBorder = "transparent";
  let secondaryMargin = "0px";
  let gap = "0px";
  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const openProfileDetails = () => {
    dispatch(
      setShowProfileDetailsStatus({
        status: true,
      })
    );
  };

  const closeProfileDetails = () => {
    dispatch(
      setShowProfileDetailsStatus({
        status: false,
      })
    );
  };

  return (
    <>
      <Box
        position={navbarPosition}
        zIndex={100}
        bg={navbarBg}
        borderColor={navbarBorder}
        filter={navbarFilter}
        backdropFilter={navbarBackdrop}
        backgroundPosition="center"
        backgroundSize="cover"
        borderRadius="16px"
        borderWidth="1.5px"
        borderStyle="solid"
        transitionDelay="0s, 0s, 0s, 0s"
        transitionDuration=" 0s, 0s, 0.25s, 0s"
        transition-property="box-shadow, background-color, filter, border"
        transitionTimingFunction="linear, linear, linear, linear"
        alignItems={{ xl: "center" }}
        display={secondary ? "block" : "flex"}
        minH="75px"
        justifyContent={{ xl: "center" }}
        lineHeight="25.6px"
        mx="auto"
        mt={secondaryMargin}
        pb="8px"
        right={{
          base: "20px",
          md: "30px",
          lg: "30px",
          xl: "30px",
          // baseWidth: "calc((100vw - 1440px) / 2 + 30px)",
        }}
        pt="8px"
        top={{ base: "12px", md: "16px", xl: "18px" }}
        w={{
          base: "calc(100vw - 40px)",
          md: "calc(100vw - 60px)",
          lg: "calc(100vw - 60px)",
          xl: "calc(100vw - 290px)",
          baseWidth: "calc(1440px - 290px)",
        }}
      >
        <Flex
          w="100%"
          flexDirection={{
            sm: "column",
            md: "row",
          }}
          alignItems="center"
          mb={gap}
        >
          <Box
            display={{
              md: "flex",
              sm: "none",
            }}
          >
            <SidebarResponsive routes={routes} openProfileDetails={null} />
          </Box>

          <Box
            mb={{ sm: "8px", md: "0px" }}
            ml={{ lg: "0px", md: "15px" }}
            display={{ md: "block", base: "none" }}
          >
            <Link
              fontFamily="Canela Text"
              color={mainText}
              href="#"
              bg="inherit"
              borderRadius="inherit"
              fontWeight="700"
              fontSize={{ md: "30px", sm: "24px" }}
              lineHeight="134.9%"
              _hover={{ color: { mainText } }}
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
            >
              {routerLocation.pathname === "/customer/main" ||
              routerLocation.pathname?.includes("order")
                ? "Let’s start translating..."
                : brandText}
            </Link>
          </Box>
          <Box ms="auto" w={{ sm: "100%", md: "unset" }}>
            <AdminNavbarLinks
              brandText={brandText}
              onOpen={props.onOpen}
              secondary={props.secondary}
              openProfileDetails={openProfileDetails}
              fixed={props.fixed}
            />
          </Box>
        </Flex>
      </Box>
      {location.includes("/admin") && (
        <ProfileDetails
          isOpen={showProfileDetails}
          onClose={() => closeProfileDetails()}
        />
      )}
      {location.includes("/translator") && (
        <TranslatorProfileDetails
          isOpen={showProfileDetails}
          onClose={() => closeProfileDetails()}
        />
      )}
      {location.includes("/manager") && (
        <ManagerProfileDetails
          isOpen={showProfileDetails}
          onClose={() => closeProfileDetails()}
        />
      )}
      {location.includes("/customer") && (
        <CustomerProfileDetails
          isOpen={showProfileDetails}
          onClose={() => closeProfileDetails()}
        />
      )}
    </>
  );
}
