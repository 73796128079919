/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Flex,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import Card from "components/card/Card";
import * as React from "react";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import { fetchUnreadMessagesCnt, pad_with_zeroes } from "network/helper";
import { unreadMessageSelector } from "store/project/selector";
import { useDispatch, useSelector } from "react-redux";
import { ArrowRightIcon, DeleteIcon } from "@chakra-ui/icons";
import { isDeletingProjectSelector } from "store/customerProject/selector";
import { axiosService } from "network/axios";
import { setAlertMessageSuccess } from "store/ui/reducer";

type RowObj = {
  id: number;
  order_id: string;
  order_name: string;
  order_due_at: string;
  order_status_name: string;
  languages: ["English", "French"];
  manager: boolean;
  translator: string[];
  translation_type: string;
  translated_from: string | undefined;
  translated_to: string | undefined;
  actionCol: string;
  order_total: number;
  message_cnt: number;
  order_status_id: string;
};

const columnHelper = createColumnHelper<RowObj>();

export default function CustomerProjectsMobileTable(props: {
  isFetching: boolean;
  tableData: any;
  onShowCustomerProjectDetails: Function;
  onRefresh?: Function;
}) {
  const unreadMessages = useSelector(unreadMessageSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isFetching, tableData, onShowCustomerProjectDetails, onRefresh } =
    props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const textColor = useColorModeValue("black", "black");
  let defaultData = tableData;
  const [deletingOrderId, setDeletingOrderId] = React.useState(null);
  const isDeletingProject = useSelector(isDeletingProjectSelector);
  const onClickDeleteDraftOrder = async (order_id: Number) => {
    try {
      setDeletingOrderId(order_id);
      await axiosService.delete(`/projects/delete/${order_id}`);
      dispatch(
        setAlertMessageSuccess({
          type: "success",
          message: "Draft Project has been removed successfully!",
        })
      );
      onRefresh?.();
    } catch (err) {
      dispatch(
        setAlertMessageSuccess({
          type: "error",
          message: "Failed to remove the project.",
        })
      );
    } finally {
    }
  };
  const columns = [
    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <Text
          align="left"
          fontSize="14px"
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Project #
        </Text>
      ),
      cell: (info: any) => {
        return (
          <Flex align="center">
            <Text
              color={info.row.getValue("id") === "0012" ? "#F00" : textColor}
              fontSize="14px"
              lineHeight="24px"
              fontWeight="400"
              maxW={{ sm: "200px", md: "unset" }}
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
            >
              {pad_with_zeroes(info.getValue(), 6)}
            </Text>
          </Flex>
        );
      },
    }),

    columnHelper.accessor("order_status_name", {
      id: "order_status_name",
      header: () => (
        <Text
          align="left"
          fontSize="14px"
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Status
        </Text>
      ),
      cell: (info) => (
        <Text
          color={textColor}
          fontSize="14px"
          lineHeight="14px"
          fontWeight="400"
          width="fit-content"
          height={"24px"}
          whiteSpace="nowrap"
          borderRadius="5px"
          padding="5px 20px"
          bgColor="#F3F5F5"
          textTransform="capitalize"
        >
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("translation_type", {
      id: "translation_type",
      header: () => (
        <Text
          align="left"
          fontSize="14px"
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Type
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
            textTransform="capitalize"
          >
            {info.getValue() || "-"}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("order_due_at", {
      id: "order_due_at",
      header: () => (
        <Text
          align="left"
          fontSize="14px"
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Due Date
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
          >
            {info.getValue()
              ? moment(new Date(info.getValue())).format("MM/DD/YY")
              : "-"}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("order_total", {
      id: "order_total",
      header: () => (
        <Text
          align="left"
          fontSize="14px"
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Price
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
          >
            ${info.getValue() ? info.getValue() : "0.00"}
          </Text>
        </Flex>
      ),
    }),

    columnHelper.accessor("actionCol", {
      id: "actionCol",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          color="gray.500"
          fontWeight="400"
        ></Text>
      ),
      cell: (info) => (
        <Flex
          align="center"
          ml={Number(info.row.original.order_status_id) !== 10 ? "auto" : "0"}
        >
          <Button
            borderRadius="0px"
            width="100%"
            padding="15px 35px"
            bgColor="#2F80ED"
            color="#FFF"
            fontSize="14px"
            lineHeight="17px"
            fontWeight="bold"
            onClick={(e) => {
              if (info.row.original.order_status_name === "Draft") {
                navigate(`/customer/order/${info.row.original.order_id}`);
              } else {
                onShowCustomerProjectDetails(info.row.original.order_id);
              }
            }}
          >
            {info.row.original.order_status_name === "Draft"
              ? "Continue Order"
              : "View Details"}
            <ArrowRightIcon ml="10px" fontSize="8px" />
          </Button>
          {Number(info.row.original.order_status_id) === 10 && (
            <Button
              borderRadius="0px"
              width="fit-content"
              padding="15px 35px"
              bgColor="#F02828"
              color="#FFF"
              fontSize="14px"
              lineHeight="17px"
              fontWeight="bold"
              isLoading={
                isDeletingProject &&
                deletingOrderId === Number(info.row.original.order_id)
              }
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onClickDeleteDraftOrder(Number(info.row.original.order_id));
              }}
            >
              <DeleteIcon w="20px" h="20px" color="#FFF" />
            </Button>
          )}
        </Flex>
      ),
    }),
  ];
  const [data, setData] = React.useState(() => [...defaultData]);
  React.useEffect(() => {
    setData([...defaultData]);
  }, [defaultData]);

  React.useEffect(() => {
    const hash = window.location.hash ?? null;
    if (hash) {
      const projectId = hash.replace("#", "");
      onShowCustomerProjectDetails(projectId);
    }
  }, []);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  return (
    <Card
      flexDirection="column"
      w="calc(100% + 20px)"
      p="0px"
      px="10px"
      ml="-10px"
      overflowX="auto"
    >
      <Box>
        <Table
          variant="simple"
          color="gray.500"
          mb="24px"
          pb="2"
          lineHeight="15px"
        >
          {!isFetching &&
            table
              .getRowModel()
              .rows.slice(0, 11)
              .map((row: any, index: number) => {
                return (
                  <Table
                    key={index}
                    mt={index === 0 ? "10px" : "35px"}
                    border="1px solid #dcdcdc"
                  >
                    <Tbody>
                      {row
                        .getVisibleCells()
                        .map((cell: any, _index: number) => {
                          return (
                            <Tr key={_index}>
                              {_index < 5 ? (
                                <React.Fragment>
                                  <Td
                                    p="10px"
                                    width="110px"
                                    borderColor="#dcdcdc"
                                    borderRight="1px solid #dcdcdc"
                                    bgColor="#FAFAFA"
                                  >
                                    {flexRender(
                                      table.getHeaderGroups()[0].headers[_index]
                                        .column.columnDef.header,
                                      table
                                        .getHeaderGroups()[0]
                                        .headers[_index].getContext()
                                    )}
                                  </Td>
                                  <Td p="10px" position="relative">
                                    {flexRender(
                                      cell.column.columnDef.cell,
                                      cell.getContext()
                                    )}
                                    {_index === 0 &&
                                      fetchUnreadMessagesCnt(
                                        unreadMessages,
                                        row.original.order_id
                                      ) > 0 && (
                                        <Text
                                          color="#FFF"
                                          bgColor="#F02828"
                                          borderRadius="5px"
                                          fontSize="12px"
                                          lineHeight="14px"
                                          fontWeight="400"
                                          padding="5px"
                                          width="fit-content"
                                          whiteSpace="nowrap"
                                          position="absolute"
                                          top="10px"
                                          right="10px"
                                        >
                                          {`${fetchUnreadMessagesCnt(
                                            unreadMessages,
                                            row.original.order_id
                                          )} New Message`}
                                        </Text>
                                      )}
                                  </Td>
                                </React.Fragment>
                              ) : (
                                <Td colSpan={2} padding="0px">
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </Td>
                              )}
                            </Tr>
                          );
                        })}
                    </Tbody>
                  </Table>
                );
              })}

          {isFetching &&
            [1, 2, 3, 4, 5].map((row) => {
              return (
                <Table
                  key={row}
                  mt={row === 0 ? "10px" : "35px"}
                  border="1px solid #dcdcdc"
                >
                  <Tbody>
                    {[1, 2, 3, 4, 5].map((_it) => (
                      <Tr key={_it}>
                        <Td
                          p="10px"
                          width="110px"
                          borderColor="#dcdcdc"
                          borderRight="1px solid #dcdcdc"
                          bgColor="#FAFAFA"
                        >
                          <Skeleton height="24px" width="100%" />
                        </Td>
                        <Td p="10px" borderColor="#dcdcdc">
                          <Skeleton height="24px" width="100%" />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              );
            })}
        </Table>
      </Box>
    </Card>
  );
}
