import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
} from "@chakra-ui/react";
import { Field, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { addBankRequest } from "store/user/reducer";
import { isAddingBankAccountSelector, userSelector } from "store/user/selector";

type BankInputFormTypes = {
  account_number: string;
  router_number: string;
  account_holder: string;
};

export default function BankInputForm(props: {
  onCancel?: Function;
  [x: string]: any;
}) {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const isAddingBank = useSelector(isAddingBankAccountSelector);

  const initialValues: BankInputFormTypes = {
    account_number: "",
    router_number: "",
    account_holder: "",
  };

  const onSaveCardInfo = (values: any) => {
    dispatch(addBankRequest({ user_id: user.user_id, bank_info: values }));
  };

  const { onCancel, ...rest } = props;
  return (
    <Box
      padding="25px"
      border="1px solid #DCDCDC"
      borderRadius="10px"
      {...rest}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          onSaveCardInfo(values);
        }}
      >
        {({ handleSubmit, errors, touched, values }) => (
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <Flex flexWrap="wrap" columnGap="10px" rowGap="20px">
              <FormControl
                isInvalid={!!errors.account_number && touched.account_number}
              >
                <Box id="card-number" position="relative" w="full">
                  <Field
                    as={Input}
                    type="text"
                    id="account_number"
                    name="account_number"
                    padding="15px 30px"
                    placeholder="Account Number"
                    fontSize="14px"
                    lineHeight="17px"
                    color="#000"
                    h="55px"
                    w="full"
                    borderRadius="50px"
                    validate={(value: any) => {
                      let error;
                      if (value.length < 1) {
                        error = "Account Number is required.";
                      }
                      return error;
                    }}
                    _placeholder={{
                      fontSize: "14px",
                      lineHeight: "17px",
                      color: "#000",
                    }}
                  />
                  <FormErrorMessage
                    mt={1}
                    pl={"30px"}
                    sx={{
                      color: "#F00",
                      justifyContent: "flex-start",
                    }}
                  >
                    {errors.account_number}
                  </FormErrorMessage>
                </Box>
              </FormControl>
              <FormControl
                isInvalid={!!errors.router_number && touched.router_number}
                flexBasis="calc(50% - 5px)"
              >
                <Box id="name-on-card">
                  <Field
                    as={Input}
                    type="text"
                    id="router_number"
                    name="router_number"
                    padding="15px 30px"
                    placeholder="Router Number"
                    fontSize="14px"
                    lineHeight="17px"
                    color="#000"
                    h="55px"
                    w="full"
                    borderRadius="50px"
                    validate={(value: any) => {
                      let error;
                      if (value.length < 1) {
                        error = "Router Number is required.";
                      }
                      return error;
                    }}
                    _placeholder={{
                      fontSize: "14px",
                      lineHeight: "17px",
                      color: "#000",
                    }}
                  />
                </Box>
                <FormErrorMessage
                  mt={1}
                  pl={"30px"}
                  sx={{
                    color: "#F00",
                    justifyContent: "flex-start",
                  }}
                >
                  {errors.router_number}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={!!errors.account_holder && touched.account_holder}
                flexBasis="calc(50% - 5px)"
              >
                <Box id="name-on-card">
                  <Field
                    as={Input}
                    type="text"
                    id="account_holder"
                    name="account_holder"
                    padding="15px 30px"
                    placeholder="Account Holder"
                    fontSize="14px"
                    lineHeight="17px"
                    color="#000"
                    h="55px"
                    w="full"
                    borderRadius="50px"
                    validate={(value: any) => {
                      let error;
                      if (value.length < 1) {
                        error = "Account holder is required.";
                      }
                      return error;
                    }}
                    _placeholder={{
                      fontSize: "14px",
                      lineHeight: "17px",
                      color: "#000",
                    }}
                  />
                </Box>
                <FormErrorMessage
                  mt={1}
                  pl={"30px"}
                  sx={{
                    color: "#F00",
                    justifyContent: "flex-start",
                  }}
                >
                  {errors.account_holder}
                </FormErrorMessage>
              </FormControl>

              <Flex flexBasis="100%" justifyContent="flex-end">
                <Button
                  w="140px"
                  height="46px"
                  padding="15px 35px"
                  borderRadius="100px"
                  bgColor="transparent"
                  color="#2F80ED"
                  fontSize="14px"
                  lineHeight="17px"
                  fontWeight="bold"
                  onClick={() => (onCancel ? onCancel() : "")}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  ml="20px"
                  w="140px"
                  height="46px"
                  padding="15px 35px"
                  borderRadius="100px"
                  bgColor="#2F80ED"
                  color="#FFF"
                  fontSize="14px"
                  lineHeight="17px"
                  fontWeight="bold"
                  isLoading={isAddingBank}
                >
                  Save
                </Button>
              </Flex>
            </Flex>
          </form>
        )}
      </Formik>
    </Box>
  );
}
