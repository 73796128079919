import {
  Box,
  Flex,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

import Card from "components/card/Card";
import _ from "lodash";
import * as React from "react";

type RowObj = {
  id: string;
  company_name: string;
  ein_number: string;
  name: string;
  email: string;
  phone: string;
  cityState: string;
  country: string;
  activeProjects: number;
  teamSize: number;
  totalPrice: number;
};

const columnHelper = createColumnHelper<RowObj>();

export default function BusinessCustomersTable(props: {
  tableData: any;
  onSelectRow: Function;
  isFetching?: boolean;
  pageLimit: number;
}) {
  const { tableData, onSelectRow, isFetching, pageLimit } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const textColor = useColorModeValue("black", "black");
  let defaultData = tableData;
  const columns = [
    columnHelper.accessor("name", {
      id: "first",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Name
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
          >
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("email", {
      id: "email",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Email
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
          >
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("company_name", {
      id: "company_name",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Company Name
        </Text>
      ),
      cell: (info) => (
        <Text
          color={textColor}
          fontSize="14px"
          lineHeight="24px"
          fontWeight="400"
        >
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("ein_number", {
      id: "ein_number",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          EIN Number
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
          >
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("teamSize", {
      id: "teamSize",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Team Users
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
          >
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("activeProjects", {
      id: "activeProjects",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          color="gray.500"
          fontWeight="400"
        >
          Active Projects
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
            minWidth="50px"
            height={"34px"}
            borderRadius="100px"
            padding="5px 20px"
            bgColor="#F3F5F5"
          >
            {info.getValue() ?? 0}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("totalPrice", {
      id: "totalPrice",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          color="gray.500"
          fontWeight="400"
        >
          Total Price
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
            width="fit-content"
          >
            {`$${Number(info.getValue()).toFixed(2)}`}
          </Text>
        </Flex>
      ),
    }),
  ];
  const [data, setData] = React.useState(() => [...defaultData]);
  React.useEffect(() => {
    setData([...defaultData]);
  }, [defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  return (
    <Card
      flexDirection="column"
      w="100%"
      p="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Box>
        <Table
          variant="simple"
          color="gray.500"
          mb="24px"
          pb="2"
          lineHeight="15px"
        >
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      px="20px"
                      border={"none"}
                      fontFamily="Inter"
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}
                      textTransform={"capitalize"}
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        lineHeight="24px"
                        color="gray.500"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {!isFetching &&
              table.getRowModel().rows.map((row) => {
                return (
                  <>
                    <Tr
                      key={row.id}
                      mb={"10px"}
                      position="relative"
                      zIndex={1}
                      cursor="pointer"
                      onClick={() => {
                        onSelectRow(row.original.id);
                      }}
                      _after={{
                        content: '""',
                        position: "absolute",
                        border: "1px solid #DCDCDC",
                        borderRadius: "10px",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: "-1",
                        transition: "all .25s ease-in-out",
                      }}
                      _hover={{
                        _after: {
                          backgroundColor: "#F1F1F1",
                          borderColor: "#F1F1F1",
                          boxShadow: "0px 2px 6px 3px rgba(0, 0, 0, 0.1)",
                        },
                      }}
                      _active={{
                        _after: {
                          boxShadow: "none",
                        },
                      }}
                    >
                      {row.getVisibleCells().map((cell, index: number) => {
                        return (
                          <Td
                            padding="20px"
                            key={cell.id}
                            fontSize={{ sm: "14px" }}
                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                            border="none"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Td>
                        );
                      })}
                    </Tr>
                    <br />
                  </>
                );
              })}
            {isFetching &&
              _.range(pageLimit).map((row) => {
                return (
                  <React.Fragment key={`skeleton-row-${row}`}>
                    <Tr
                      zIndex="1"
                      position="relative"
                      _after={{
                        content: '""',
                        position: "absolute",
                        border: "1px solid #DCDCDC",
                        borderRadius: "10px",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: "-1",
                        transition: "all .25s ease-in-out",
                      }}
                    >
                      {columns.map((it: any, colIndex: number) => {
                        return (
                          <Td
                            key={`skeleton-${colIndex}`}
                            padding={{
                              lg: "20px",
                              md: "20px 10px",
                              base: "15px 10px",
                            }}
                            border="none !important"
                          >
                            <Skeleton
                              height="25px"
                              colorScheme="#F3F5F5"
                              borderRadius="5px"
                            ></Skeleton>
                          </Td>
                        );
                      })}
                    </Tr>
                    <Tr>
                      <Td
                        colSpan={7}
                        paddingTop="10px"
                        paddingBottom="10px"
                        border="none !important"
                      ></Td>
                    </Tr>
                  </React.Fragment>
                );
              })}
          </Tbody>
        </Table>
      </Box>
    </Card>
  );
}
