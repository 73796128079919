/* eslint-disable react-hooks/exhaustive-deps */
import {
  Badge,
  Box,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useWindowWidth } from "@react-hook/window-size";
import CustomPaginations from "components/pagination/CustomPaginations";
import SortingHandler from "components/sortBox/SortingHandler";
import { axiosService } from "network/axios";
import {
  fetchUnreadMessagesByStatus,
  generateQueryParameters,
} from "network/helper";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getManagersRequest } from "store/project/reducer";
import { unreadMessageSelector } from "store/project/selector";
import { searchQuerySelector } from "store/ui/selector";
import { userSelector } from "store/user/selector";
import ManagerProjectDetail from "./components/managerProjectDetail";
import ManagerProjectsMobileTable from "./components/managerProjectsMobileTable";
import ManagerProjectsTable from "./components/managerProjectsTable";

export default function ManagerProjects() {
  const user = useSelector(userSelector);
  const unreadMessages = useSelector(unreadMessageSelector);
  const [prevSearchQuery, setPrevSearchQuery] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(20);
  const [pageLimit, setPageLimit] = useState(10);
  const [isFetching, setIsFetching] = useState(false);
  const [results, setResults] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  const dispatch = useDispatch();

  const [selectedOption, setSelectedOption] = useState<{
    key: string;
    label: string;
    dir: "desc" | "asc";
  }>({
    key: "updated_at",
    label: "Last Updated",
    dir: "desc",
  });
  const sortingOptions = [
    {
      key: "updated_at",
      label: "Last Updated",
    },
    {
      key: "order_due_at",
      label: "Due Date",
    },
    {
      key: "order_status_id",
      label: "Status",
    },
    {
      key: "order_id",
      label: "Project",
    },
  ];

  const searchQuery = useSelector(searchQuerySelector);
  const windowWidth = useWindowWidth();

  const queryParam = useMemo(() => {
    const status =
      tabIndex === 0
        ? "new"
        : tabIndex === 1
        ? "active"
        : tabIndex === 2
        ? "due_soon"
        : tabIndex === 3
        ? "review"
        : "completed";
    if (prevSearchQuery !== searchQuery) {
      setPrevSearchQuery(searchQuery);
      setCurrentPage(1);
      return {
        status: status,
        page: 1,
        limit: pageLimit,
        keyword: searchQuery ?? "",
        order_by: selectedOption?.key,
        dir: selectedOption?.dir,
      };
    }
    return {
      status: status,
      page: currentPage,
      limit: pageLimit,
      keyword: searchQuery ?? "",
      order_by: selectedOption?.key,
      dir: selectedOption?.dir,
    };
  }, [tabIndex, currentPage, pageLimit, searchQuery, selectedOption]);

  const fetchResults = useCallback(async () => {
    setIsFetching(true);
    const params = generateQueryParameters(queryParam);
    try {
      const resp: any = await axiosService.get(
        `/projects/managers/${user?.user_id}?${params}`
      );
      const { results, total_rows } = resp?.data;
      setResults(
        results?.map((it: any) => ({
          ...it,
          id: it?.order_id,
        }))
      );

      setTotalPages(
        total_rows / pageLimit > Math.floor(total_rows / pageLimit)
          ? Math.floor(total_rows / pageLimit) + 1
          : Math.floor(total_rows / pageLimit)
      );
      setTotalRows(total_rows);
    } catch (err) {
      console.log(err);
      setResults([]);
    } finally {
      setIsFetching(false);
    }
  }, [queryParam, user?.user_id]);

  const {
    newMessagesCnt,
    activeMessagesCnt,
    dueSoonMessagesCnt,
    reviewMessagesCnt,
    completeMessagesCnt,
  } = fetchUnreadMessagesByStatus(unreadMessages);

  useEffect(() => {
    if (user?.user_id) {
      fetchResults();
    }
  }, [queryParam, user?.user_id]);

  useEffect(() => {
    dispatch(getManagersRequest());
  }, []);

  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const showDetailsProductDetails = (e: any) => {
    setSelectedProjectId(e);
  };
  return (
    <Box pt={{ base: "60px", md: "80px", xl: "80px" }}>
      <Tabs
        variant="unstyled"
        onChange={(index) => {
          setCurrentPage(1);
          setTabIndex(index);
        }}
      >
        <TabList borderBottom={"2px solid #E9E9E9"}>
          <Tab
            padding={{ md: "14px 25px 14px 0px", sm: "10px 10px 10px 0px" }}
            fontSize={{ md: "17px", sm: "14px" }}
            lineHeight={{ md: "24px", sm: "22px" }}
            fontWeight="400"
            alignItems="center"
            _selected={{ color: "blue.500" }}
            _focus={{ boxShadow: "none" }}
          >
            New
            {newMessagesCnt > 0 && (
              <Badge
                bgColor={"#F02828"}
                sx={{
                  padding: "9px 6px !important",
                  borderRadius: "1000px",
                  fontWeight: "700",
                  fontSize: "10px",
                  lineHeight: "0px",
                  color: "#FFF",
                  marginLeft: "7px",
                  minWidth: "18px",
                }}
              >
                {newMessagesCnt}
              </Badge>
            )}
          </Tab>
          <Tab
            padding={{ md: "14px 25px", sm: "10px" }}
            fontSize={{ md: "17px", sm: "14px" }}
            lineHeight={{ md: "24px", sm: "22px" }}
            fontWeight="400"
            alignItems="center"
            _selected={{ color: "blue.500" }}
            _focus={{ boxShadow: "none" }}
          >
            Active
            {activeMessagesCnt > 0 && (
              <Badge
                bgColor={"#F02828"}
                sx={{
                  padding: "9px 6px !important",
                  borderRadius: "1000px",
                  fontWeight: "700",
                  fontSize: "10px",
                  lineHeight: "0px",
                  color: "#FFF",
                  marginLeft: "7px",
                  minWidth: "18px",
                }}
              >
                {activeMessagesCnt}
              </Badge>
            )}
          </Tab>
          <Tab
            padding={{ md: "14px 25px", sm: "10px" }}
            fontSize={{ md: "17px", sm: "14px" }}
            lineHeight={{ md: "24px", sm: "22px" }}
            fontWeight="400"
            alignItems="center"
            _selected={{ color: "blue.500" }}
            _focus={{ boxShadow: "none" }}
          >
            Due Soon
            {dueSoonMessagesCnt > 0 && (
              <Badge
                bgColor={"#F02828"}
                sx={{
                  padding: "9px 6px !important",
                  borderRadius: "1000px",
                  fontWeight: "700",
                  fontSize: "10px",
                  lineHeight: "0px",
                  color: "#FFF",
                  marginLeft: "7px",
                  minWidth: "18px",
                }}
              >
                {dueSoonMessagesCnt}
              </Badge>
            )}
          </Tab>

          <Tab
            padding={{ md: "14px 25px", sm: "10px" }}
            fontSize={{ md: "17px", sm: "14px" }}
            lineHeight={{ md: "24px", sm: "22px" }}
            fontWeight="400"
            alignItems="center"
            _selected={{ color: "blue.500" }}
            _focus={{ boxShadow: "none" }}
          >
            Review
            {reviewMessagesCnt > 0 && (
              <Badge
                bgColor={"#F02828"}
                sx={{
                  padding: "9px 6px !important",
                  borderRadius: "1000px",
                  fontWeight: "700",
                  fontSize: "10px",
                  lineHeight: "0px",
                  color: "#FFF",
                  marginLeft: "7px",
                  minWidth: "18px",
                }}
              >
                {reviewMessagesCnt}
              </Badge>
            )}
          </Tab>

          <Tab
            padding={{ md: "14px 25px", sm: "10px" }}
            fontSize={{ md: "17px", sm: "14px" }}
            lineHeight={{ md: "24px", sm: "22px" }}
            fontWeight="400"
            alignItems="center"
            _selected={{ color: "blue.500" }}
            _focus={{ boxShadow: "none" }}
          >
            Completed
            {completeMessagesCnt > 0 && (
              <Badge
                bgColor={"#F02828"}
                sx={{
                  padding: "9px 6px !important",
                  borderRadius: "1000px",
                  fontWeight: "700",
                  fontSize: "10px",
                  lineHeight: "0px",
                  color: "#FFF",
                  marginLeft: "7px",
                  minWidth: "18px",
                }}
              >
                {completeMessagesCnt}
              </Badge>
            )}
          </Tab>
          <Box
            padding={{ md: "14px 0px", sm: "10px 0px" }}
            position="absolute"
            right="30px"
            display={{
              base: "none",
              md: "block",
            }}
            boxSizing="border-box"
            zIndex={90}
          >
            <SortingHandler
              selectedOption={selectedOption}
              sortingOptions={sortingOptions}
              onChangeSortingOption={(e: any) => {
                setSelectedOption(e);
              }}
            />
          </Box>
        </TabList>
        <TabIndicator mt="-2px" height="2px" bg="blue.500" borderRadius="1px" />
        <TabPanels>
          <TabPanel px="0">
            {(isFetching || results?.length > 0) &&
              (windowWidth >= 768 ? (
                <ManagerProjectsTable
                  isFetching={isFetching}
                  tableData={results}
                  onShowProjectDetails={showDetailsProductDetails}
                />
              ) : (
                <ManagerProjectsMobileTable
                  isFetching={isFetching}
                  tableData={results}
                  onShowProjectDetails={showDetailsProductDetails}
                />
              ))}
            {!isFetching && results?.length < 1 && <Box>No Projects</Box>}
          </TabPanel>
          <TabPanel px="0">
            {(isFetching || results?.length > 0) &&
              (windowWidth >= 768 ? (
                <ManagerProjectsTable
                  isFetching={isFetching}
                  tableData={results}
                  onShowProjectDetails={showDetailsProductDetails}
                />
              ) : (
                <ManagerProjectsMobileTable
                  isFetching={isFetching}
                  tableData={results}
                  onShowProjectDetails={showDetailsProductDetails}
                ></ManagerProjectsMobileTable>
              ))}
            {!isFetching && results?.length < 1 && <Box>No Projects</Box>}
          </TabPanel>
          <TabPanel px="0">
            {(isFetching || results?.length > 0) &&
              (windowWidth >= 768 ? (
                <ManagerProjectsTable
                  tableData={results}
                  isFetching={isFetching}
                  onShowProjectDetails={showDetailsProductDetails}
                />
              ) : (
                <ManagerProjectsMobileTable
                  tableData={results}
                  isFetching={isFetching}
                  onShowProjectDetails={showDetailsProductDetails}
                />
              ))}
            {!isFetching && results?.length < 1 && <Box>No Projects</Box>}
          </TabPanel>
          <TabPanel px="0">
            {isFetching ||
              (results?.length > 0 &&
                (windowWidth >= 768 ? (
                  <ManagerProjectsTable
                    isFetching={isFetching}
                    tableData={results}
                    onShowProjectDetails={showDetailsProductDetails}
                  />
                ) : (
                  <ManagerProjectsMobileTable
                    isFetching={isFetching}
                    tableData={results}
                    onShowProjectDetails={showDetailsProductDetails}
                  />
                )))}
            {!isFetching && results?.length < 1 && <Box>No Projects</Box>}
          </TabPanel>
          <TabPanel px="0">
            {isFetching ||
              (results?.length > 0 &&
                (windowWidth >= 768 ? (
                  <ManagerProjectsTable
                    isFetching={isFetching}
                    tableData={results}
                    onShowProjectDetails={showDetailsProductDetails}
                  />
                ) : (
                  <ManagerProjectsMobileTable
                    isFetching={isFetching}
                    tableData={results}
                    onShowProjectDetails={showDetailsProductDetails}
                  />
                )))}
            {!isFetching && results?.length < 1 && <Box>No Projects</Box>}
          </TabPanel>
        </TabPanels>
        {!isFetching && totalPages >= 1 && (
          <CustomPaginations
            totalCount={totalRows}
            totalPages={totalPages}
            currentPage={currentPage}
            perPage={pageLimit}
            onSetPageLimit={(e: number) => {
              setPageLimit(e);
              setCurrentPage(1);
            }}
            onChangePage={(e: number) => {
              setCurrentPage(e);
            }}
          />
        )}
      </Tabs>
      <ManagerProjectDetail
        selectedProjectId={selectedProjectId}
        onCloseModal={() => {
          setSelectedProjectId(null);
          fetchResults();
        }}
      />
    </Box>
  );
}
