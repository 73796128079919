import { Box, Button } from "@chakra-ui/react";
import { cloneDeep } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { updateProjectStatus } from "store/customerProject/reducer";
import { uploadProjectSelector } from "store/customerProject/selector";
import TranslationFileRow from "views/customer/order/components/translation/TranslationFileRow";
import { DocumentType } from "views/customer/order/variables/orderData";
import { axiosService } from "network/axios";
import { client } from "filestack-react";
import { useState } from "react";
import IconBox from "components/icons/IconBox";
import { IconUpload } from "components/icons/Icons";

export default function FileUploadHandler() {
  const uploadProject = useSelector(uploadProjectSelector);
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = useState(false);

  const removeFile = (rowId: string) => {
    let clonedDocumnets = cloneDeep(uploadProject.documents);
    let index = clonedDocumnets?.findIndex((it) => String(it.id) === rowId);
    if (index > -1) {
      clonedDocumnets.splice(index, 1);
    }

    dispatch(
      updateProjectStatus({
        documents: [...clonedDocumnets],
      })
    );
  };

  const handleFileUpload = async (event: any) => {
    const { filesUploaded } = event;
    setIsUploading(true);
    const payloads = filesUploaded?.map((it: any) => {
      return {
        uploadId: it.uploadId,
        fileUrl: it.url,
        type: it.mimetype,
        fileName: it.filename,
      };
    });

    try {
      const { data }: any = await axiosService.post(
        `/document/upload-cdn-documents`,
        {
          payload: payloads,
        }
      );

      const uploadResult = data?.result ?? [];
      const uploadedDocuments: DocumentType[] = uploadResult?.map((it: any) => {
        const { uploadId, pageCount, wordCount } = it;
        const matchedFile = payloads?.find(
          (_it: any) => _it?.uploadId === uploadId
        );
        return {
          id: uploadId,
          pages: pageCount,
          words: wordCount,
          type: matchedFile?.type,
          fileUrl: matchedFile?.fileUrl || "",
          file: {
            name: matchedFile?.fileName,
          },
        };
      });

      dispatch(
        updateProjectStatus({
          documents: [...uploadProject.documents, ...uploadedDocuments],
        })
      );
    } catch (err: any) {
      console.log(err);
    } finally {
      setIsUploading(false);
    }
  };

  const filePickerOptions = {
    maxSize: 1024 * 1024 * 1024 * 20,
    maxFiles: 100,
    onUploadDone: handleFileUpload,
  };
  const openPicker = () => {
    const filestack = client.init("AbQ8zu1t0SpWgREObJOI1z");
    filestack.picker(filePickerOptions).open();
  };

  return (
    <>
      {uploadProject.documents?.length > 0 && (
        <Box maxH="168px" overflowY="auto" mb="16px">
          {uploadProject.documents?.map((it: DocumentType) => (
            <TranslationFileRow
              key={it?.id}
              rowData={it}
              onRemoveFile={(rowId: string) => {
                removeFile(rowId);
              }}
            />
          ))}
        </Box>
      )}

      <Button
        type="button"
        w="fit-content"
        height="44px"
        padding="13px 26px"
        borderRadius="100px"
        bgColor="#2F80ED"
        color="#FFF"
        fontSize="14px"
        lineHeight="17px"
        fontWeight="bold"
        isLoading={isUploading}
        _disabled={{
          bgColor: "#E9E9E9 !important",
        }}
        _loading={{
          bgColor: "#3311DB !important",
        }}
        _hover={{
          bgColor: "#3311DB !important",
        }}
        onClick={openPicker}
      >
        {uploadProject.documents?.length > 0
          ? "Upload More Files"
          : "Upload File"}
        <IconBox
          w="24px"
          h="24px"
          ml="8px"
          icon={
            <IconUpload
              w="24px"
              h="24px"
              sx={{
                path: {
                  fill: "#FFF",
                },
              }}
            />
          }
        />
      </Button>
    </>
  );
}
