import {
  Box,
  Flex,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
// Custom components
import Card from "components/card/Card";
import * as React from "react";
import { useSelector } from "react-redux";
import { isGettingAllUsersSelector } from "store/user/selector";
// Assets

type RowObj = {
  id: string;
  name: string;
  email: string;
  phone?: string;
};

const columnHelper = createColumnHelper<RowObj>();

// const columns = columnsDataCheck;
export default function AdminsTable(props: {
  tableData: any;
  onSelectRow: Function;
}) {
  const { tableData, onSelectRow } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const isGettingAllUsers = useSelector(isGettingAllUsersSelector);

  let defaultData = tableData;
  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Name
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text
            fontSize={"14px"}
            lineHeight="24px"
            fontWeight={"400"}
            color="black"
          >
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("email", {
      id: "email",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Email
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            fontSize={"14px"}
            lineHeight="24px"
            fontWeight={"400"}
            color="black"
          >
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("phone", {
      id: "phone",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          lineHeight="24px"
          fontWeight="400"
          color="gray.500"
        >
          Phone
        </Text>
      ),
      cell: (info) => (
        <Text
          fontSize={"14px"}
          lineHeight="24px"
          fontWeight={"400"}
          color="black"
        >
          {info.getValue() ? info.getValue() : ""}
        </Text>
      ),
    }),
  ];
  const [data, setData] = React.useState(() => [...defaultData]);
  React.useEffect(() => {
    setData([...defaultData]);
  }, [defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const mobileColumnWidths: any = {
    name: 120,
    email: 120,
    phone: 150,
    description: 250,
    city: 80,
    country: 80,
  };
  return (
    <Card
      flexDirection="column"
      p="0px"
      px="10px"
      w="calc(100% + 20px)"
      ml="-10px"
      overflowX="auto"
    >
      <Box>
        <Table variant="simple" color="gray.500" mb="24px">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      px={{ lg: "20px", md: "10px", base: "10px" }}
                      border={"none"}
                      fontFamily="Inter"
                      cursor="pointer"
                      textTransform={"capitalize"}
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        lineHeight="24px"
                        color="gray.500"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {!isGettingAllUsers &&
              table
                .getRowModel()
                .rows.slice(0, 11)
                .map((row, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <Tr
                        mb={"10px"}
                        position="relative"
                        zIndex={1}
                        cursor="pointer"
                        onClick={() => {
                          onSelectRow(row.original.id);
                        }}
                        _after={{
                          content: '""',
                          position: "absolute",
                          border: "1px solid #DCDCDC",
                          borderRadius: "10px",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          zIndex: "-1",
                          transition: "all .25s ease-in-out",
                        }}
                        _hover={{
                          _after: {
                            backgroundColor: "#F1F1F1",
                            borderColor: "#F1F1F1",
                            boxShadow: "0px 2px 6px 3px rgba(0, 0, 0, 0.1)",
                          },
                        }}
                        _active={{
                          _after: {
                            boxShadow: "none",
                          },
                        }}
                      >
                        {row.getVisibleCells().map((cell, index: number) => {
                          return (
                            <Td
                              key={index}
                              padding={{
                                lg: "20px",
                                md: "20px 10px",
                                base: "15px 10px",
                              }}
                              fontSize={{ sm: "14px" }}
                              minW={{
                                base: `${mobileColumnWidths[cell.column.id]}px`,
                                md: `${mobileColumnWidths[cell.column.id]}px`,
                                lg: "auto",
                              }}
                              border="none"
                              width={
                                cell.column.id === "actionCol" ? "80px" : "auto"
                              }
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </Td>
                          );
                        })}
                      </Tr>
                      <Tr>
                        <Td py={1} borderBottom="none"></Td>
                      </Tr>
                    </React.Fragment>
                  );
                })}
            {isGettingAllUsers &&
              [1, 2, 3, 4, 5].map((row) => {
                return (
                  <React.Fragment key={`skeleton-row-${row}`}>
                    <Tr
                      zIndex="1"
                      position="relative"
                      _after={{
                        content: '""',
                        position: "absolute",
                        border: "1px solid #DCDCDC",
                        borderRadius: "10px",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: "-1",
                        transition: "all .25s ease-in-out",
                      }}
                    >
                      {columns.map((it: any, colIndex: number) => {
                        return (
                          <Td
                            key={`skeleton-${colIndex}`}
                            padding={{
                              lg: "20px",
                              md: "20px 10px",
                              base: "15px 10px",
                            }}
                            border="none !important"
                          >
                            <Skeleton
                              height="24px"
                              colorScheme="#F3F5F5"
                              borderRadius="5px"
                            ></Skeleton>
                          </Td>
                        );
                      })}
                    </Tr>
                    <Tr>
                      <Td
                        colSpan={7}
                        paddingTop="5px"
                        paddingBottom="10px"
                        border="none !important"
                      ></Td>
                    </Tr>
                  </React.Fragment>
                );
              })}{" "}
          </Tbody>
        </Table>
      </Box>
    </Card>
  );
}
