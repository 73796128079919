import { all } from 'redux-saga/effects';
import customerProjectsSaga from 'store/customerProject/saga';
import uiSaga from 'store/ui/saga';
import projectSaga from '../project/saga';
import userSaga from '../user/saga';

export default function* rootSaga() {
  yield all([
    ...userSaga,
    ...projectSaga,
    ...customerProjectsSaga,
    ...uiSaga
  ]);
}
